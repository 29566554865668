import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { isMobile } from "react-device-detect";
import { Alert } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../../app/hooks";
import {
  selectShowUserManualModal,
  setShowUserManualModal,
} from "../../../features/common";

export default function BookingSignatureModal() {
  const dispatch = useDispatch();

  const handleClose = () => dispatch(setShowUserManualModal(false));

  const show = useAppSelector(selectShowUserManualModal);

  useEffect(() => {}, []);

  return (
    <>
      <Modal
        fullscreen={isMobile ? true : undefined}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>공지</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>시스템 장애, 긴급 문의</h6>
          <p>화면 아래 왼편 채널톡으로 접수해 주세요.</p>
          <hr />
          <h6>2022/09</h6>
          <ul>
            <li>
              '고객 피드백' 기능이 생겼어요. 매장에서 해당 기능을 사용하는 경우
              예약 상태가 완료로 변경되면 30분 이내에 피드백 작성을 요청하는
              알림톡이 발송됩니다.
              <br />
              화면 오른쪽 위 <Icon.MenuButtonWide /> 버튼을 눌러 '피드백'으로
              이동하면 됩니다.
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
