import { Box, Input } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Tab,
  Tabs,
  Form,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import BackendAPI from "../../api/backend-api";
import ServiceOptionAPI from "../../api/service-option";
import { useAppSelector } from "../../app/hooks";
import {
  selectCurrentShop,
  selectCurrentShopId,
  selectUserAttributes,
} from "../../features/common";
import { ServiceOptionCategory } from "../../models";

export default function ServiceOption() {
  const { enqueueSnackbar } = useSnackbar();
  const currentShopId = useAppSelector(selectCurrentShopId);
  const currentShop = useAppSelector(selectCurrentShop);
  const userAttributes = useAppSelector(selectUserAttributes);
  const [serviceOption, setServiceOption] = useState<any>();
  const [serviceOptionsOnEdit, setServiceOptionsOnEdit] =
    useState<ServiceOptionCategory[]>();
  const [activeTabKey, setActiveTabKey] = useState(0);
  const [syncing, setSyncing] = useState(false);
  const serviceOptionAPI = new ServiceOptionAPI();

  useEffect(() => {
    initializeServiceOption();
  }, [currentShopId]);

  useEffect(() => {
    if (!userAttributes) {
      return;
    }
    initializeDesigner();
  }, [userAttributes]);

  useEffect(() => {
    if (!activeTabKey && serviceOptionsOnEdit) {
      setActiveTabKey(0);
    }
  }, [serviceOptionsOnEdit]);

  async function initializeDesigner() {}

  async function initializeServiceOption() {
    if (!currentShopId) {
      return;
    }

    const existingData = await serviceOptionAPI.get(currentShopId);

    if (existingData) {
      setServiceOption(existingData);

      const categories = existingData.categories;

      setServiceOptionsOnEdit(categories);
    } else {
      setServiceOption(undefined);

      const defaultServiceOptions: Array<ServiceOptionCategory> = [
        { name: "컷", options: [] },
        { name: "펌", options: [] },
        { name: "컬러", options: [] },
        { name: "클리닉", options: [] },
        { name: "드라이", options: [] },
      ];
      setServiceOptionsOnEdit(defaultServiceOptions);
    }
  }

  function validate() {
    if (!serviceOptionsOnEdit) {
      return false;
    }

    const errors: string[] = [];

    serviceOptionsOnEdit.forEach((serviceOptionCategory) => {
      serviceOptionCategory.options.forEach((optionItem) => {
        if (!optionItem.name) {
          errors.push(
            `[${serviceOptionCategory.name}] 메뉴 이름은 필수 항목입니다.`
          );
        }
      });
    });

    errors.forEach((error) => {
      toast.error(error);
    });

    return errors.length === 0;
  }

  async function handleUpdateServiceOption() {
    if (!serviceOptionsOnEdit) {
      return;
    }

    if (!validate()) {
      return;
    }

    const categories = [...serviceOptionsOnEdit].map((category) => {
      return {
        ...category,
        options: category.options.map((option) => {
          return { ...option, price: option.price || 0 };
        }),
      };
    });

    if (serviceOption) {
      await serviceOptionAPI.update({
        shopId: currentShopId!,
        categories,
        _version: serviceOption._version,
      });
    } else {
      await serviceOptionAPI.create({
        shopId: currentShopId!,
        categories,
      });
    }
    enqueueSnackbar("시술 메뉴를 저장했습니다.", { variant: "success" });

    await initializeServiceOption();
  }

  function updateOptionName(
    categoryIndex: number,
    optionIndex: number,
    optionName: string
  ) {
    if (!serviceOptionsOnEdit) {
      return;
    }

    const newServiceOptions = [...serviceOptionsOnEdit];

    newServiceOptions[categoryIndex].options[optionIndex] = {
      ...newServiceOptions[categoryIndex].options[optionIndex],
      name: optionName,
    };

    setServiceOptionsOnEdit(newServiceOptions);
  }

  function updateOptionPrice(
    categoryIndex: number,
    optionIndex: number,
    optionPrice: string
  ) {
    if (!serviceOptionsOnEdit) {
      return;
    }

    if (isNaN(Number(optionPrice))) {
      return;
    }

    const newServiceOptions = [...serviceOptionsOnEdit];

    newServiceOptions[categoryIndex].options[optionIndex] = {
      ...newServiceOptions[categoryIndex].options[optionIndex],
      price: Number(optionPrice),
    };

    setServiceOptionsOnEdit(newServiceOptions);
  }

  function appendOption(categoryIndex: number) {
    if (!serviceOptionsOnEdit) {
      return;
    }

    const newServiceOptions = [...serviceOptionsOnEdit];

    newServiceOptions[categoryIndex].options.push({
      name: "",
      price: 0,
    });

    setServiceOptionsOnEdit(newServiceOptions);
  }

  function spliceCategory(categoryIndex: number) {
    if (!serviceOptionsOnEdit) {
      return;
    }

    if (!confirm("삭제하시겠습니까?\n저장을 눌러야 반영됩니다.")) {
      return;
    }

    let newServiceOptions = [...serviceOptionsOnEdit];

    newServiceOptions.splice(categoryIndex, 1);

    setServiceOptionsOnEdit(newServiceOptions);
  }

  function appendCategory() {
    if (!serviceOptionsOnEdit) {
      return;
    }

    const newServiceOptions = [...serviceOptionsOnEdit];

    let name = "새 카테고리";

    while (newServiceOptions.find((category) => category.name === name)) {
      name = name + "-1";
    }

    newServiceOptions.push({
      name,
      options: [],
    });

    setServiceOptionsOnEdit(newServiceOptions);

    setActiveTabKey(newServiceOptions.length - 1);
  }

  function spliceOption(categoryIndex: number, optionIndex: number) {
    if (!serviceOptionsOnEdit) {
      return;
    }

    if (!confirm("삭제하시겠습니까?\n저장을 눌러야 반영됩니다.")) {
      return;
    }

    let newServiceOptions = [...serviceOptionsOnEdit];

    newServiceOptions[categoryIndex].options.splice(optionIndex, 1);

    setServiceOptionsOnEdit(newServiceOptions);
  }

  async function syncNaverOptions() {
    const shopId = currentShopId;
    const designerId = userAttributes
      ? userAttributes["custom:designer_id"]
      : undefined;
    const bizItemId = userAttributes
      ? userAttributes["custom:biz_item_id"]
      : undefined;

    if (!currentShop?.naverBusinessId) {
      toast.warn(
        "네이버 시술 메뉴 동기화를 위해 [매장 설정 - 네이버 예약 업체 아이디] 입력이 필요합니다.\n점주 계정으로 로그인 후 [매장 설정]에서 입력해주세요."
      );
      return;
    }

    if (!bizItemId) {
      toast.warn(
        "네이버 시술 메뉴 동기화를 위해 [설정 - 네이버 예약 상품 아이디] 입력이 필요합니다."
      );
      return;
    }

    setSyncing(true);

    toast.info("네이버 시술 메뉴 동기화를 시작합니다.");

    try {
      await new BackendAPI().syncNaverOptions(shopId!, designerId);

      await initializeServiceOption();
    } catch (error) {}

    setSyncing(false);

    toast.success("네이버 시술 메뉴를 동기화했습니다.");
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Tabs
              defaultActiveKey={serviceOptionsOnEdit?.length ? 0 : undefined}
              activeKey={activeTabKey}
              onSelect={(k) => setActiveTabKey(Number(k) ?? 0)}
              className="mb-3"
            >
              {serviceOptionsOnEdit?.map(
                (serviceOptionCategory, categoryIndex) => {
                  return (
                    <Tab
                      eventKey={categoryIndex}
                      title={serviceOptionCategory.name}
                      key={categoryIndex}
                    >
                      <div>
                        <Box sx={{ marginBottom: 1 }}>
                          <Input
                            value={serviceOptionCategory.name}
                            onChange={(e) => {
                              const name = e.target.value;
                              const newServiceOptions =
                                serviceOptionsOnEdit.map((item, index) => {
                                  if (index === categoryIndex) {
                                    return {
                                      ...item,
                                      name,
                                    };
                                  } else {
                                    return item;
                                  }
                                });

                              setServiceOptionsOnEdit(newServiceOptions);
                            }}
                            sx={{ marginRight: 1 }}
                          />
                          <Button
                            size="sm"
                            variant="outline-warning"
                            onClick={() => {
                              spliceCategory(categoryIndex);
                            }}
                          >
                            카테고리 삭제
                          </Button>
                        </Box>
                        {serviceOptionCategory.options?.map(
                          (option, optionIndex) => {
                            return (
                              <div key={optionIndex.toString()}>
                                <Row>
                                  <Col>
                                    <Form.Group className="mb-3">
                                      <Form.Control
                                        size="sm"
                                        value={option.name ?? ""}
                                        onChange={(event) => {
                                          updateOptionName(
                                            categoryIndex,
                                            optionIndex,
                                            event.target.value
                                          );
                                        }}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col>
                                    <Form.Group className="mb-3">
                                      <Form.Control
                                        size="sm"
                                        value={option.price?.toString() ?? ""}
                                        onChange={(event) => {
                                          updateOptionPrice(
                                            categoryIndex,
                                            optionIndex,
                                            event.target.value
                                          );
                                        }}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col>
                                    <Button
                                      size="sm"
                                      variant="outline-warning"
                                      onClick={() => {
                                        spliceOption(
                                          categoryIndex,
                                          optionIndex
                                        );
                                      }}
                                    >
                                      삭제
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            );
                          }
                        )}
                        <div>
                          <Button
                            size="sm"
                            variant="outline-primary"
                            onClick={() => {
                              appendOption(categoryIndex);
                            }}
                          >
                            메뉴 추가
                          </Button>
                        </div>
                      </div>
                    </Tab>
                  );
                }
              )}
            </Tabs>
            <hr />
            <Box sx={{ marginBottom: 1 }}>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={appendCategory}
              >
                카테고리 추가
              </Button>
            </Box>
            <Row>
              <Col xs={3}></Col>
              <Col xs={6} className="text-center">
                <Button size="sm" onClick={handleUpdateServiceOption}>
                  저장
                </Button>
              </Col>

              <Col xs={3} className="text-end"></Col>
            </Row>
          </Col>
        </Row>
        <Box sx={{ marginTop: 2 }}>
          <Alert variant="info">
            <ul style={{ marginBottom: 0 }}>
              <li>
                시술 메뉴 등록 후 예약 정보에 입력하면 예약 고객님께 알림톡을
                발송할 때 함께 표시됩니다.
                <ul>
                  <li>
                    금액은 알림톡에 포함되지 않으므로 공란으로 두셔도 됩니다.
                  </li>
                </ul>
              </li>
              <li>
                매장 설정에 6자리 네이버 업체 아이디를 입력하고{" "}
                <Link to="/settings">사용자 설정</Link>에 7자리 네이버 예약상품
                아이디를 입력하면 시술 메뉴를 쉽게 불러올 수 있어요.
                <ul>
                  <li>
                    6자리 업체 아이디는{" "}
                    <a href="https://owner.nanalogapp.co" target={"_blank"}>
                      점주 사이트
                    </a>
                    에서 입력할 수 있어요.
                  </li>
                </ul>
              </li>
            </ul>
          </Alert>
        </Box>
        <Box sx={{ marginBottom: 1, textAlign: "right" }}>
          <Button
            size="sm"
            disabled={syncing}
            variant="outline-success"
            onClick={syncNaverOptions}
          >
            네이버 예약상품 불러오기
          </Button>
        </Box>
      </Container>
    </>
  );
}
