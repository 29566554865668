import { Row, Col, Button } from "react-bootstrap";
import styles from "./index.module.scss";
import { useAppSelector } from "../../../../../app/hooks";
import { selectStudyMode } from "../../../../../features/consultation";
import VideoButton from "../../VideoButton";
import RecommendationItemName from "../../RecommendationItemName";

export default function Default({
  constants,
  inputValue,
  inputFieldName,
  onClick,
  gender,
  recommendations,
}: {
  constants: {
    name: string;
    items: string[];
    video?: { [key: string]: string };
  }[];
  inputValue: string;
  inputFieldName: string;
  onClick: any;
  gender?: "female" | "male" | null;
  recommendations?: string[];
}) {
  const studyMode = useAppSelector(selectStudyMode);

  let xs = 4;

  if (constants.length === 2 || constants.length === 3) {
    xs = 6;
  }

  let md = 3;

  if (inputFieldName === "hairLength") {
    md = 6;
  } else if (inputFieldName === "hairFirm") {
    md = 4;
  }

  return (
    <div>
      {constants.map((category, index) => {
        return (
          <Row key={"empty-name-" + index}>
            {category.items.map((item) => {
              const key = category.name ? category.name + "_" + item : item;

              const active = inputValue === key;

              return (
                <Col key={key} xs={xs} md={md} className="d-grid">
                  <Button
                    variant={active ? "active" : "inactive"}
                    onClick={() => {
                      const selectedValue = category.name
                        ? category.name + "_" + item
                        : item;
                      onClick(selectedValue, undefined, inputFieldName);
                    }}
                  >
                    <RecommendationItemName
                      recommendations={recommendations}
                      item={item}
                    />
                  </Button>
                  {studyMode && category.video && category.video[item] && (
                    <VideoButton category={category} item={item} />
                  )}
                </Col>
              );
            })}
          </Row>
        );
      })}
    </div>
  );
}
