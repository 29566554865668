import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./app/store";
import RouteList from "./app/RouteList";
import { SnackbarProvider } from "notistack";
import { createTheme, ThemeProvider } from "@mui/material";

if (location.hostname === "designer.mysalondiary.co") {
  location.href = location.href.replace(
    "designer.mysalondiary.co",
    "nanalogapp.co"
  );
}

const environment = process.env.REACT_APP_STAGE;

if (environment) {
  Sentry.init({
    dsn: "https://dd95c866e08445a08e08c4c7061d4b02@o1104725.ingest.sentry.io/6735756",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment,
  });
}

const theme = createTheme({
  typography: {
    fontFamily: "Pretendard, -apple-system, sans-serif",
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
        <Provider store={store}>
          <BrowserRouter>
            <RouteList />
          </BrowserRouter>
        </Provider>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
