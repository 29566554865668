import { Box, Button, Fab, Grid, IconButton } from "@mui/material";
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useAppSelector } from "../../app/hooks";
import {
  selectCurrentShopId,
  setShowUpdateConsultationModal,
  setShowUpdateCustomerModal,
} from "../../features/common";
import {
  fetchConsultationById,
  fetchConsultationsByShopId,
  selectConsultations,
  setYearMonth,
} from "../../features/consultation";
import { Consultation, ConsultationGender } from "../../graphql/API";
import styles from "./index.module.scss";
import { useSnackbar } from "notistack";
import { fetchCustomerById } from "../../features/customer";
import dayjs from "dayjs";

export default function ConsultationList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const currentShopId = useAppSelector(selectCurrentShopId);
  const consultations = useAppSelector(selectConsultations);
  const yearMonth = useAppSelector((state) => state.consultation.yearMonth);

  useEffect(() => {
    if (!currentShopId) {
      return;
    }

    initialize(currentShopId);
  }, [currentShopId]);

  async function initialize(shopId: string) {
    dispatch(fetchConsultationsByShopId(shopId) as any);
  }

  const columns: GridColumns<Consultation> = [
    {
      field: "name",
      headerName: "고객",
      width: 70,
      cellClassName: "super-app-theme--no-padding-cell",
      renderCell(params) {
        return (
          <Button
            sx={{ minWidth: 40 }}
            onClick={() => {
              dispatch(fetchCustomerById(params.row.customerId) as any);
              dispatch(setShowUpdateCustomerModal(true));
            }}
          >
            {params.value ?? params.row.customer?.name}
          </Button>
        );
      },
    },
    {
      field: "date",
      headerName: "날짜",
    },
    {
      field: "designerName",
      headerName: "디자이너",
      width: 60,
    },
    {
      field: "gender",
      headerName: "성별",
      width: 40,
      valueGetter(params) {
        return params.value === ConsultationGender.FEMALE ? "여" : "남";
      },
    },
    {
      field: "faceShapes",
      headerName: "얼굴형",
      flex: 1,
      valueGetter(params) {
        return params.value?.join(", ");
      },
    },
    {
      field: "hairRecommendation",
      headerName: "커트",
      flex: 1,
      valueGetter(params) {
        return params.value?.haircuts?.join(", ");
      },
    },
    {
      field: "hasPhotos",
      headerName: "사진",
      type: "boolean",
      width: 40,
    },
    {
      field: "solutionSentAt",
      headerName: "전송",
      type: "boolean",
      width: 40,
      valueGetter(params) {
        return !!params.value;
      },
    },
    {
      field: "edit",
      headerName: "수정",
      width: 60,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Button
          sx={{ minWidth: 40 }}
          onClick={() => {
            dispatch(fetchConsultationById(params.row.id) as any);
            dispatch(setShowUpdateConsultationModal(true) as any);
          }}
        >
          수정
        </Button>
      ),
    },
    {
      field: "id",
      headerName: "미리보기",
      width: 70,
      renderCell: (params: GridRenderCellParams<string>) => {
        return params.row.solutionSentAt ? (
          <Button
            sx={{ minWidth: 50 }}
            onClick={() => {
              const hostName =
                process.env.REACT_APP_STAGE === "prod"
                  ? "app.nanalogapp.co"
                  : "dev-app.nanalogapp.co";

              const url = `https://${hostName}/solution/${params.value}`;
              window.open(url);
            }}
          >
            결과지
          </Button>
        ) : (
          <></>
        );
      },
    },
  ];

  if (!currentShopId) {
    return <></>;
  }

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Box sx={{ textAlign: "center", paddingBottom: 1 }}>
              <Grid container alignItems={"center"} justifyContent="center">
                <Grid item>
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => {
                      dispatch(
                        setYearMonth(
                          dayjs(yearMonth)
                            .subtract(1, "month")
                            .format("YYYY-MM")
                        ) as any
                      );
                      dispatch(
                        fetchConsultationsByShopId(currentShopId) as any
                      );
                    }}
                  >
                    <NavigateBeforeIcon fontSize="small" />
                  </IconButton>
                </Grid>
                <Grid item>{yearMonth}</Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => {
                      dispatch(
                        setYearMonth(
                          dayjs(yearMonth).add(1, "month").format("YYYY-MM")
                        ) as any
                      );
                      dispatch(
                        fetchConsultationsByShopId(currentShopId) as any
                      );
                    }}
                  >
                    <NavigateNextIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                "& .super-app-theme--no-padding-cell": {
                  padding: "0 !important",
                },
              }}
            >
              <DataGridPro
                columns={columns}
                rows={consultations ?? []}
                autoHeight={true}
                initialState={{
                  pinnedColumns: { left: ["name"], right: ["id"] },
                }}
              />
            </Box>
          </Col>
        </Row>
      </Container>
      <div id="action-button-create">
        <Fab
          color="info"
          aria-label="add"
          variant="extended"
          onClick={() => {
            enqueueSnackbar(
              "예약 상세 창 또는 고객 상세 창에서 컨설테이션을 시작할 수 있어요. 화면 하단의 탭으로 이동해 보세요.",
              {
                variant: "info",
                autoHideDuration: 5000,
                anchorOrigin: { vertical: "top", horizontal: "center" },
              }
            );
          }}
        >
          <AddIcon />
          등록
        </Fab>
      </div>
    </div>
  );
}
