import { Box, Button, Stack } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../app/hooks";

import { setShowUpdateConsultationModal } from "../../../features/common";
import {
  fetchConsultationById,
  fetchConsultationsByCustomerId,
  selectRecentConsultations,
} from "../../../features/consultation";
import { Consultation } from "../../../graphql/API";

export default function RecentConsultationList({ customer }: any) {
  const dispatch = useDispatch();

  const recentConsultations = useAppSelector(selectRecentConsultations);

  useEffect(() => {
    fetchConsultationsByCustomer();
  }, [customer]);

  async function fetchConsultationsByCustomer() {
    dispatch(fetchConsultationsByCustomerId(customer.id) as any);
  }

  function updateConsultation(id: string) {
    dispatch(fetchConsultationById(id) as any);
    dispatch(setShowUpdateConsultationModal(true) as any);
  }
  const columns: GridColumns<Consultation> = [
    {
      field: "date",
      headerName: "날짜",
    },
    {
      field: "designerName",
      headerName: "담당",
      maxWidth: 60,
    },
    {
      field: "mode",
      headerName: "모드",
      maxWidth: 60,
    },
    {
      field: "solutionSentAt",
      headerName: "발송",
      maxWidth: 60,
      renderCell(params) {
        return params.value ? "Y" : "";
      },
    },
    {
      field: "id",
      headerName: "열기",
      renderCell: (params) => {
        return (
          <Button
            size="small"
            sx={{ minWidth: 30 }}
            onClick={() => {
              updateConsultation(params.value);
            }}
          >
            열기
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <h5>최근 컨설테이션</h5>
      <Box>
        <DataGridPro
          columns={columns}
          rows={recentConsultations}
          autoHeight={true}
          hideFooter={true}
          components={{
            NoRowsOverlay: () => (
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center"
                sx={{ textAlign: "center" }}
              >
                컨설테이션이 없습니다.
              </Stack>
            ),
          }}
        />
      </Box>
    </>
  );
}
