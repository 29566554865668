import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  setConsultationTermsId,
  setShowConsultationTermsModal,
} from "../../../features/common";
import ConsultationTermsModal from "../TermsModal";
import styles from "./index.module.scss";

export default function Footer() {
  const dispatch = useDispatch();

  function onClickShowConsultationTermsModal(index: number) {
    dispatch(setConsultationTermsId(index));
    dispatch(setShowConsultationTermsModal(true));
  }

  return (
    <Container fluid className={styles.FooterContainer}>
      <Row>
        <Col>
          <div className={styles.Footer}>
            <small>
              나나로그 솔루션 | 세상 모든 미용실을 스마트하게
              <br />
              Copyright © 2022 Future Beauty Inc. 모든 권리 보유.
              <br />
              <p>
                (주)퓨처뷰티 대표이사 박제희
                <br />
                주소: 서울특별시 강남구 역삼로 165, 7층 이, 에프호
                <br />
                사업자등록번호: 527-88-01574
                <br />
                통신판매업신고번호: 제 2022-서울강남-02708호
              </p>
              <p style={{ marginTop: 10 }}>
                <a
                  onClick={() => onClickShowConsultationTermsModal(0)}
                  style={{ textDecoration: "underline" }}
                >
                  서비스 이용 약관
                </a>{" "}
                <a
                  onClick={() => onClickShowConsultationTermsModal(2)}
                  style={{ textDecoration: "underline" }}
                >
                  개인정보 처리방침
                </a>
              </p>
            </small>
          </div>
          <ConsultationTermsModal />
        </Col>
      </Row>
    </Container>
  );
}
