import { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Button, IconButton } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import {
  selectCurrentShopId,
  selectShowCreateBookingModal,
  selectShowPaymentModal,
  setShowCreateBookingModal,
  setShowUpdateBookingModal,
  setShowUpdateCustomerModal,
} from "../../features/common";
import { Booking } from "../../graphql/API";
import {
  addDay,
  fetchBookingById,
  fetchBookingsByShopIdAndDate,
  refreshBookings,
  selectBookings,
  selectDate,
  selectRefreshing,
  setDate,
  subtractDay,
} from "../../features/booking";
import { useDispatch } from "react-redux";

import { fetchDiscountPresetsByShopId } from "../../features/discount-preset";
import PaymentModal from "./PaymentModal";
import BookingCreateModal from "./CreateModal";
import ConsultationCreateModal from "../Consultation/CreateModal";
import UserManualModal from "./UserManualModal";
import { useNavigate } from "react-router-dom";
import SelfDiagnosisModal from "./SelfDiagnosisModal";
import dayjs from "dayjs";
import { BookingStatus } from "../../models";
import styles from "./index.module.scss";
import RegularCustomerNoteModal from "./RegularCustomerNoteModal";
import { useSnackbar } from "notistack";
import SelfDiagnosisV2Modal from "./SelfDiagnosisV2Modal";
import APIGateway from "../../api/api-gateway";

import BrowserService from "../../services/browser";
import BookingGridBox from "./GridBox";
import { fetchCustomerById } from "../../features/customer";

const SUGGEST_BOOKING_CREATION_MESSAGE_KEY =
  "SUGGEST_BOOKING_CREATION_MESSAGE_KEY";

export default function BookingList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const apiGateway = new APIGateway();

  const bookings = useAppSelector(selectBookings);
  const date = useAppSelector(selectDate);
  const currentShopId = useAppSelector(selectCurrentShopId);
  const refreshing = useAppSelector(selectRefreshing);
  const showCreateBookingModal = useAppSelector(selectShowCreateBookingModal);
  const showPaymentModal = useAppSelector(selectShowPaymentModal);

  const [booking, setBooking] = useState<Booking>();
  const [showSelfDiagnosisModal, setShowSelfDiagnosisModal] = useState(false);
  const [showSelfDiagnosisV2Modal, setShowSelfDiagnosisV2Modal] =
    useState(false);
  const [showRegularCustomerNoteModal, setShowRegularCustomerNoteModal] =
    useState(false);
  const [currentDesignerName, setCurrentDesignerName] = useState("");
  const [displayCanceledBookings, setDisplayCanceledBookings] = useState(true);

  const handleFocus = () => {
    if (!currentShopId) {
      return;
    }

    refreshViewData();
  };

  function refreshViewData() {
    dispatch(refreshBookings() as any);
  }

  useEffect(() => {
    checkRecentVisitTimestamp();
    suggestBookingCreation();

    const handleBlur = () => {};

    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  useEffect(() => {
    if (!currentShopId) {
      return;
    }

    fetchBookings(currentShopId);
  }, [currentShopId, date]);

  useEffect(() => {
    if (!currentShopId) {
      return;
    }

    setCurrentDesignerName("");

    dispatch(fetchDiscountPresetsByShopId(currentShopId) as any);
  }, [currentShopId]);

  useEffect(() => {
    if (bookings && booking) {
      const showing = bookings.find((item) => item.id === booking.id);

      if (showing) {
        setBooking(showing);
      }
    }
  }, [bookings]);

  useEffect(() => {
    if (!currentShopId) {
      return;
    }

    const subscriptionOnCreateBooking = apiGateway.booking
      .composeOnCreateBookingSubscription(currentShopId)
      .subscribe({
        next: ({ provider, value }: any) => {
          const newBooking = value.data.onCreateBooking;

          if (newBooking.shopId !== currentShopId) {
            return;
          }

          if (bookings.find((item) => item.id === newBooking.id)) {
            return;
          }

          refreshViewData();
        },
        error: (error: any) => console.warn(error),
      });

    const subscriptionOnUpdateBooking = apiGateway.booking
      .composeOnUpdateBookingSubscription(currentShopId)
      .subscribe({
        next: ({ provider, value }: any) => {
          refreshViewData();
        },
        error: (error: any) => console.warn(error),
      });

    return () => {
      subscriptionOnCreateBooking.unsubscribe();
      subscriptionOnUpdateBooking.unsubscribe();
    };
  }, [currentShopId]);

  function checkRecentVisitTimestamp() {
    const recentVisitTimestampString = BrowserService.getRecentVisitTimestamp();

    if (recentVisitTimestampString) {
      const recentVisitTimestamp = Number(recentVisitTimestampString);

      if (dayjs().diff(dayjs(recentVisitTimestamp), "hour") > 23) {
        location.reload();
      }
    }
  }

  function suggestBookingCreation() {
    if (BrowserService.getJustSignedUp()) {
      enqueueSnackbar(
        "나나로그 셀프 진단 발송 기능을 사용하려면 먼저 예약을 등록해야 해요.",
        {
          variant: "success",
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          autoHideDuration: 10000,
        }
      );
      setTimeout(() => {
        enqueueSnackbar(
          "연습 삼아 본인의 이름과 전화번호로 예약을 등록해 보세요. 등록 버튼은 우측 하단에 있어요.",
          {
            key: SUGGEST_BOOKING_CREATION_MESSAGE_KEY,
            variant: "success",
            preventDuplicate: true,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            autoHideDuration: 30000,
            persist: true,
            action: () => {
              return (
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    closeSnackbar(SUGGEST_BOOKING_CREATION_MESSAGE_KEY);
                  }}
                >
                  Ok
                </Button>
              );
            },
          }
        );
      }, 10000);
    }
  }

  async function fetchBookings(shopId: string) {
    dispatch(
      fetchBookingsByShopIdAndDate({
        shopId,
        date,
        dateDurationByDays: 1,
      }) as any
    );
  }

  function onClickShowBookingModal(booking: Booking) {
    dispatch(fetchBookingById(booking.id) as any);
    dispatch(setShowUpdateBookingModal(true));
  }

  function onClickShowCustomerModal(booking: Booking) {
    dispatch(
      fetchCustomerById(booking?.customerId ?? booking?.customer?.id) as any
    );
    dispatch(setShowUpdateCustomerModal(true));
  }

  function onClickShowSelfDiagnosisV2Modal(booking: Booking) {
    dispatch(fetchBookingById(booking.id) as any);
    setTimeout(() => {
      setShowSelfDiagnosisV2Modal(true);
    }, 800);
  }

  function onClickShowRegularCustomerNoteModal(booking: Booking) {
    dispatch(fetchBookingById(booking.id) as any);
    setTimeout(() => {
      setShowRegularCustomerNoteModal(true);
    }, 800);
  }

  if (!currentShopId) {
    return <></>;
  }

  const bookingsForDisplay = bookings
    .filter((booking) => {
      if (displayCanceledBookings) {
        return true;
      } else {
        return booking.bookingStatus !== BookingStatus.CANCELED;
      }
    })
    .filter((booking) => {
      if (currentDesignerName === "") {
        return true;
      } else {
        return booking.designerName === currentDesignerName;
      }
    });

  return (
    <>
      <Container className="body">
        <Row>
          <Col xs={3}>
            <Button
              size="small"
              color="secondary"
              onClick={() => {
                setDisplayCanceledBookings(!displayCanceledBookings);
              }}
            >
              {displayCanceledBookings ? "취소포함" : "취소제외"}
            </Button>
          </Col>
          <Col xs={6} className={styles.DatePickerCell}>
            <IconButton
              size="small"
              color="secondary"
              onClick={() => {
                dispatch(subtractDay() as any);
              }}
            >
              <NavigateBeforeIcon fontSize="small" />
            </IconButton>
            <DesktopDatePicker
              value={date ? dayjs(date) : undefined}
              inputFormat="MM/DD"
              toolbarFormat="MM월 DD일"
              onChange={(newValue) => {
                if (!newValue) {
                  return;
                }
                dispatch(setDate(newValue.format("YYYY-MM-DD")));
              }}
              onAccept={(newValue) => {
                if (!newValue) {
                  return;
                }
                dispatch(setDate(newValue.format("YYYY-MM-DD")));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  style={{ width: "96px", margin: "0 3px" }}
                />
              )}
            />
            <IconButton
              size="small"
              color="secondary"
              onClick={() => {
                dispatch(addDay() as any);
              }}
            >
              <NavigateNextIcon fontSize="small" />
            </IconButton>
          </Col>
          <Col xs={3} className="text-end">
            {refreshing ? (
              <Spinner
                animation="grow"
                variant="info"
                size="sm"
                style={{ verticalAlign: "-webkit-baseline-middle" }}
              />
            ) : (
              <Button
                size="small"
                color="secondary"
                onClick={() => {
                  refreshViewData();
                }}
              >
                새로고침
              </Button>
            )}
          </Col>
        </Row>
        {bookings.length > 0 && (
          <div className={styles.DesignerButtonList}>
            <Button
              variant={currentDesignerName === "" ? "contained" : "outlined"}
              size="small"
              color="secondary"
              sx={{ minWidth: 48 }}
              onClick={() => {
                setCurrentDesignerName("");
              }}
            >
              ALL
            </Button>
            {Array.from(
              new Set(
                bookings.map((booking) => {
                  return booking.designerName;
                })
              )
            ).map((designerName) => {
              return (
                <Button
                  key={designerName}
                  variant={
                    currentDesignerName === designerName
                      ? "contained"
                      : "outlined"
                  }
                  color="secondary"
                  size="small"
                  sx={{ minWidth: 48 }}
                  onClick={() => {
                    setCurrentDesignerName(designerName ?? "");
                  }}
                >
                  {designerName}
                </Button>
              );
            })}
          </div>
        )}
        <BookingGridBox
          bookingsForDisplay={bookingsForDisplay}
          onClickShowBookingModal={onClickShowBookingModal}
          onClickShowCustomerModal={onClickShowCustomerModal}
          onClickShowSelfDiagnosisV2Modal={onClickShowSelfDiagnosisV2Modal}
          onClickShowRegularCustomerNoteModal={
            onClickShowRegularCustomerNoteModal
          }
        />
        {showSelfDiagnosisModal && (
          <SelfDiagnosisModal
            show={showSelfDiagnosisModal}
            setShow={setShowSelfDiagnosisModal}
          />
        )}
        {showSelfDiagnosisV2Modal && (
          <SelfDiagnosisV2Modal
            show={showSelfDiagnosisV2Modal}
            setShow={setShowSelfDiagnosisV2Modal}
          />
        )}
        {showRegularCustomerNoteModal && (
          <RegularCustomerNoteModal
            show={showRegularCustomerNoteModal}
            setShow={setShowRegularCustomerNoteModal}
          />
        )}
        {showPaymentModal && <PaymentModal />}
        {showCreateBookingModal && <BookingCreateModal />}
        <ConsultationCreateModal />
        <UserManualModal />
      </Container>
      <div id="action-button-create">
        <Fab
          color="info"
          aria-label="add"
          variant="extended"
          onClick={() => {
            dispatch(setShowCreateBookingModal(true));
          }}
        >
          <AddIcon />
          등록
        </Fab>
      </div>
    </>
  );
}
