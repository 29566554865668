import React, { useEffect, useRef, useState } from "react";
import { Badge, Form, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { isMobile } from "react-device-detect";
import { useAppSelector } from "../../../app/hooks";
import { Customer } from "../../../graphql/API";
import SpeechToText from "../../SpeechToText";
import SignatureModal from "../SignatureModal";
import { toast } from "react-toastify";
import RecentBookingList from "../RecentBookingList";
import PrepaidPass from "../PrepaidPass";
import RecentConsultationList from "../RecentConsultationList";
import { useDispatch } from "react-redux";
import { fetchCustomerById, selectCustomer } from "../../../features/customer";
import {
  selectShowUpdateCustomerModal,
  setShowCreateConsultationWithoutBookingModal,
  setShowUpdateCustomerModal,
} from "../../../features/common";
import CustomerAPI from "../../../api/customer";
import { Box, Button } from "@mui/material";
import { useSnackbar } from "notistack";

export default function CustomerModal() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const show = useAppSelector(selectShowUpdateCustomerModal);
  const handleClose = () => dispatch(setShowUpdateCustomerModal(false));
  const customer = useAppSelector(selectCustomer);

  const [showSpeechToText, setShowSpeechToText] = useState(false);
  const [customerOnEdit, setCustomerOnEdit] = useState<Customer>();
  const [showSignatureModal, setShowSignatureModal] = useState(false);

  const customerAPI = new CustomerAPI();

  useEffect(() => {
    if (!show) {
      setCustomerOnEdit(undefined);
    }
  }, [show]);

  useEffect(() => {
    if (!customer) {
      return;
    }
    setCustomerOnEdit(customer);
    setShowSignatureModal(false);
  }, [customer, show]);

  async function handleSaveChanges() {
    if (!customer) {
      return;
    }
    if (!customerOnEdit) {
      return;
    }

    const { occupation, drink, memo, _version } = customerOnEdit;

    await customerAPI.updateCustomer({
      id: customer.id,
      occupation,
      drink,
      memo,
      _version,
    });

    enqueueSnackbar(`${customerOnEdit.name} 고객님의 프로필을 저장했습니다.`, {
      variant: "success",
    });

    dispatch(fetchCustomerById(customer?.id!) as any);
  }

  function showCreateConsultationModal() {
    dispatch(fetchCustomerById(customer?.id!) as any);

    dispatch(setShowCreateConsultationWithoutBookingModal(true) as any);
  }

  if (!customerOnEdit) {
    return <></>;
  }

  return (
    <>
      <Modal show={show} scrollable onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>고객 정보</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <colgroup>
              <col width={"20%"} />
              <col width={"30%"} />
              <col width={"20%"} />
              <col width={"30%"} />
            </colgroup>
            <tbody>
              <tr>
                <th>이름</th>
                <td colSpan={3}>{customerOnEdit.name}</td>
              </tr>
              <tr>
                <th>전화번호</th>
                <td>{customerOnEdit.phoneNumber}</td>
                <th>서명</th>
                <td>
                  {customerOnEdit.signature ? (
                    <img
                      src={customerOnEdit.signature}
                      style={{ height: 40 }}
                    />
                  ) : (
                    <>
                      <Button
                        size="small"
                        color="info"
                        onClick={() => {
                          setShowSignatureModal(true);
                        }}
                      >
                        서명 필요
                      </Button>
                      <SignatureModal
                        show={showSignatureModal}
                        setShow={setShowSignatureModal}
                      ></SignatureModal>
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <th>직업</th>
                <td>
                  <Form.Control
                    value={customerOnEdit.occupation ?? ""}
                    onChange={(event) => {
                      setCustomerOnEdit({
                        ...customerOnEdit,
                        occupation: event.target.value,
                      });
                    }}
                  />
                </td>
                <th>음료</th>
                <td>
                  <Form.Control
                    value={customerOnEdit.drink ?? ""}
                    onChange={(event) => {
                      setCustomerOnEdit({
                        ...customerOnEdit,
                        drink: event.target.value,
                      });
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
          <Form>
            <Form.Group className="mb-3" controlId="modalForm.memo">
              <Form.Label>기타 메모</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={customerOnEdit.memo ?? ""}
                style={{ fontSize: 12 }}
                onChange={(event) => {
                  setCustomerOnEdit({
                    ...customerOnEdit,
                    memo: event.target.value,
                  });
                }}
              />
            </Form.Group>
            <Box sx={{ textAlign: "right" }}>
              <Button variant="contained" onClick={handleSaveChanges}>
                저장
              </Button>
            </Box>
          </Form>
          <RecentConsultationList customer={customerOnEdit} />
          <Box sx={{ height: 15 }}></Box>
          <RecentBookingList customer={customerOnEdit} />
          {/* <PrepaidPass
            customer={customerOnEdit}
            fetchCustomer={fetchCustomer}
          /> */}
          {/* {showSpeechToText ? (
            <SpeechToText />
          ) : (
            <Button
              size={"sm"}
              onClick={() => {
                setShowSpeechToText(true);
              }}
            >
              음성 텍스트 변환
            </Button>
          )} */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            onClick={showCreateConsultationModal}
            sx={{ marginRight: 1 }}
          >
            컨설테이션 시작
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
