import TextField from "@mui/material/TextField";
import { DesktopDatePicker, DesktopTimePicker } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import APIGateway from "../../../../api/api-gateway";
import { useAppSelector } from "../../../../app/hooks";
import {
  selectBooking,
  fetchBookingById,
  refreshBookings,
} from "../../../../features/booking";
import { BookingStatus } from "../../../../models";
import { BookingOrigin } from "../../../../models";
import BookingService from "../../../../services/booking";

export default function DateTimeRow() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const booking = useAppSelector(selectBooking);

  const apiGateway = new APIGateway();

  const bookingService = new BookingService(apiGateway.booking);

  const [dateValue, setDateValue] = useState<Dayjs | null>(dayjs());
  const [timeValue, setTimeValue] = useState<Dayjs | null>(dayjs());

  useEffect(() => {
    if (!booking) {
      return;
    }

    setDateValue(dayjs(`${booking.date} ${booking.time}`));
    setTimeValue(dayjs(`${booking.date} ${booking.time}`));
  }, [booking]);

  const handleDateChange = (newValue: Dayjs | null) => {
    if (!newValue) {
      return;
    }

    setDateValue(newValue);
  };
  const handleTimeChange = (newValue: Dayjs | null) => {
    if (!newValue) {
      return;
    }

    setTimeValue(newValue);
  };

  async function updateBookingDate(date: string) {
    if (!booking) {
      return;
    }

    await bookingService.updateBookingDate(booking.id, date, booking._version);

    enqueueSnackbar("날짜를 변경했어요.", { variant: "success" });

    dispatch(fetchBookingById(booking.id) as any);
    dispatch(refreshBookings() as any);
  }

  async function updateBookingTime(time: string) {
    if (!booking) {
      return;
    }

    await bookingService.updateBookingTime(booking.id, time, booking._version);

    enqueueSnackbar(`시간을 ${time}(으)로 변경했어요.`, { variant: "success" });

    dispatch(fetchBookingById(booking.id) as any);
    dispatch(refreshBookings() as any);
  }

  if (!booking) {
    return <></>;
  }

  return (
    <tr>
      <td colSpan={4}>
        {booking.origin === BookingOrigin.NAVER && (
          <>
            <strong>
              {booking.date} <span style={{ color: "#c4c4c4" }}>|</span>{" "}
              {booking.time}
            </strong>
          </>
        )}
        {booking.origin === BookingOrigin.MANUAL && (
          <Row>
            <Col>
              <DesktopDatePicker
                label="예약일"
                value={dateValue}
                readOnly={booking.bookingStatus === BookingStatus.COMPLETED}
                inputFormat="YYYY-MM-DD"
                toolbarFormat="MM월 DD일"
                onChange={handleDateChange}
                onAccept={(newValue) => {
                  if (!newValue) {
                    return;
                  }

                  const date = newValue.format("YYYY-MM-DD");
                  updateBookingDate(date);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        const date = dateValue?.format("YYYY-MM-DD");
                        if (date) {
                          updateBookingDate(date);
                        }
                      }
                    }}
                    size="small"
                    style={{
                      width: "150px",
                      marginRight: "15px",
                    }}
                  />
                )}
              />
              <DesktopTimePicker
                label="시간"
                value={timeValue}
                readOnly={booking.bookingStatus === BookingStatus.COMPLETED}
                ampm
                inputFormat="HH:mm"
                minutesStep={15}
                onChange={handleTimeChange}
                onAccept={(newValue) => {
                  if (!newValue) {
                    return;
                  }

                  const time = newValue?.format("HH:mm");
                  updateBookingTime(time);
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          const time = timeValue?.format("HH:mm");
                          if (time) {
                            updateBookingTime(time);
                          }
                        }
                      }}
                      size="small"
                      style={{ width: "110px" }}
                    />
                  );
                }}
              />
            </Col>
          </Row>
        )}
      </td>
    </tr>
  );
}
