import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "aws-amplify";
import { Storage } from "aws-amplify";
import { RootState } from "../../app/store";
import {
  SelfDiagnosis,
  ModelSortDirection,
  GetSelfDiagnosisQueryVariables,
} from "../../graphql/API";
import { getSelfDiagnosis } from "../../graphql/queries";

export interface SelfDiagnosisState {
  selfDiagnosis?: SelfDiagnosis;
  photoUrls?: string[];
}

const initialState: SelfDiagnosisState = {
  selfDiagnosis: undefined,
  photoUrls: undefined,
};

const fetchSelfDiagnosisById = createAsyncThunk(
  "selfDiagnosises/fetchSelfDiagnosisById",
  async (bookingId: string) => {
    const variables: GetSelfDiagnosisQueryVariables = {
      bookingId,
    };

    const response: any = await API.graphql(
      graphqlOperation(getSelfDiagnosis, variables)
    );

    let selfDiagnosis = response.data.getSelfDiagnosis;
    let photoUrls: string[] = [];

    if (selfDiagnosis.photos && selfDiagnosis.photos.length) {
      const urls = [];

      for (let i = 0; i < selfDiagnosis.photos.length; i += 1) {
        const signedURL = await Storage.get(selfDiagnosis.photos[i].s3Key);

        urls.push(signedURL);
      }

      photoUrls = urls;
    }

    return {
      selfDiagnosis,
      photoUrls,
    };
  }
);

export const selfDiagnosisSlice = createSlice({
  name: "selfDiagnosis",
  initialState,
  reducers: {
    resetSelfDiagnosis: (state) => {
      state.selfDiagnosis = undefined;
      state.photoUrls = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSelfDiagnosisById.fulfilled, (state, { payload }) => {
      state.selfDiagnosis = payload.selfDiagnosis;
      state.photoUrls = payload.photoUrls;
    });
  },
});

export const selectSelfDiagnosis = (state: RootState) =>
  state.selfDiagnosis.selfDiagnosis;

export const selectSelfDiagnosisPhotoUrls = (state: RootState) =>
  state.selfDiagnosis.photoUrls;

export { fetchSelfDiagnosisById };

export const { resetSelfDiagnosis } = selfDiagnosisSlice.actions;

export default selfDiagnosisSlice.reducer;
