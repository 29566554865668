import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FaceIcon from "@mui/icons-material/Face";
import TodayIcon from "@mui/icons-material/Today";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { SxProps, Theme } from "@mui/material";

function getDefaultValue(): number {
  const pathname = location.pathname;

  if (pathname === "/") {
    return 1;
  } else if (pathname === "/consultation") {
    return 2;
  } else if (pathname === "/dashboard") {
    return 3;
  } else if (pathname === "/settings") {
    return 4;
  }

  return 0;
}

export default function Bottom() {
  const navigate = useNavigate();

  const [value, setValue] = useState(getDefaultValue());

  const paperSx: SxProps<Theme> = {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  };

  if (window.innerWidth < 400) {
    paperSx.height = 80;
  }

  return (
    <Paper sx={paperSx} elevation={3}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          if (newValue === 0) {
            navigate("/customer");
          } else if (newValue === 1) {
            navigate("/");
          } else if (newValue === 2) {
            navigate("/consultation");
          } else if (newValue === 3) {
            navigate("/dashboard");
          } else if (newValue === 4) {
            navigate("/settings");
          }
        }}
      >
        <BottomNavigationAction label="고객" icon={<FaceIcon />} />
        <BottomNavigationAction label="예약" icon={<TodayIcon />} />
        <BottomNavigationAction label="컨설테이션" icon={<ChatBubbleIcon />} />
        <BottomNavigationAction label="대시보드" icon={<DashboardIcon />} />
        <BottomNavigationAction label="설정" icon={<SettingsIcon />} />
      </BottomNavigation>
    </Paper>
  );
}
