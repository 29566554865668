import axios from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import BookingAPI from "../../api/booking";
import {
  Booking,
  BookingServiceOption,
  BookingStatus,
} from "../../graphql/API";
import { ServiceStatus } from "../../models";

export default class BookingService {
  constructor(private bookingAPI: BookingAPI) {}

  async updateBookingDate(id: string, date: string, _version: number) {
    return this.bookingAPI.updateBooking({
      id,
      date,
      _version,
    });
  }

  async updateBookingTime(id: string, time: string, _version: number) {
    return this.bookingAPI.updateBooking({
      id,
      time,
      _version,
    });
  }

  async cancelBooking(id: string): Promise<boolean> {
    const booking = await this.bookingAPI.get(id);

    if (BookingStatus.CONFIRMED !== booking.bookingStatus) {
      toast.warn("확정 상태일 때만 취소할 수 있습니다.");
      return false;
    }

    await this.bookingAPI.updateBooking({
      id,
      bookingStatus: BookingStatus.CANCELED,
      _version: booking._version,
    });

    return true;
  }

  async completeBooking(id: string) {
    const booking = await this.bookingAPI.get(id);

    if (BookingStatus.CONFIRMED !== booking.bookingStatus) {
      toast.warn("확정 상태일 때만 취소할 수 있습니다.");
      return false;
    }

    await this.bookingAPI.updateBooking({
      id,
      bookingStatus: BookingStatus.COMPLETED,
      _version: booking._version,
    });

    return true;
  }

  async updateBookingAsSigned(id: string, signature: string, _version: number) {
    return this.bookingAPI.updateBooking({
      id,
      serviceStatus: ServiceStatus.IN_PROGRESS,
      signature,
      signedAt: dayjs().toISOString(),
      _version,
    });
  }

  async updateBookingAsPortraitRightsSigned(
    id: string,
    signatureOfPortraitRights: string,
    _version: number
  ) {
    return this.bookingAPI.updateBooking({
      id,
      signatureOfPortraitRights,
      signedAtOfPortraitRights: dayjs().toISOString(),
      _version,
    });
  }

  async updateServiceOptions(
    serviceOptions: BookingServiceOption[],
    booking: Booking
  ) {
    const options = serviceOptions.map((option) => {
      const {
        name,
        price,
        originalPrice,
        discountPreset,
        discount,
        discountRate,
      } = option;

      return {
        name,
        price: price ?? 0,
        categoryName: "",
        originalPrice: originalPrice ?? price ?? 0,
        discountPreset,
        discount: discount ?? 0,
        discountRate: discountRate ?? 0,
      };
    });

    const totalServiceSales = serviceOptions
      .map((option) => option.price)
      .reduce((a, b) => a + b, 0);

    const finalPrice = totalServiceSales + (booking.totalProductSales ?? 0);

    await this.bookingAPI.updateBooking({
      id: booking.id,
      options,
      totalServiceSales,
      finalPrice,
      _version: booking._version,
    });
  }
}
