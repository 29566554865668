import { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentShopId } from "../../features/common";
import {
  addMonth,
  fetchBookingsByShopIdAndMonth,
  selectBookingsByMonth,
  selectDate,
  subtractMonth,
} from "../../features/booking";
import numeral from "numeral";
import dayjs from "dayjs";
import SalesStatisticsTable from "./SalesStatisticsTable";

export default function SalesStatus() {
  const dispatch = useDispatch();

  const bookings = useAppSelector(selectBookingsByMonth);
  const date = useAppSelector(selectDate);
  const currentShopId = useAppSelector(selectCurrentShopId);
  const [categoryNames, setCategoryNames] = useState<string[]>([]);

  useEffect(() => {
    if (!currentShopId) {
      return;
    }

    fetchBookings(currentShopId);
  }, [currentShopId, date]);

  async function fetchBookings(shopId: string) {
    const month = dayjs(date).format("YYYY-MM");

    dispatch(fetchBookingsByShopIdAndMonth({ shopId, month }) as any);
  }

  useEffect(() => {
    const list = Array.from(
      new Set(
        bookings
          .map((booking) => {
            return (
              booking.options?.map((option) => option.categoryName ?? "") ?? []
            );
          })
          .flat()
      )
    );

    setCategoryNames(list);
  }, [bookings]);

  let totalServiceSalesQuantity = 0;
  let totalProductSalesQuantity = 0;
  let totalServiceSales = 0;
  let totalProductSales = 0;
  let totalSales = 0;

  bookings.forEach((item) => {
    item.options?.forEach((option) => {
      totalServiceSalesQuantity += 1;
      totalServiceSales += option.price;
    });

    item.productOptions?.forEach((option) => {
      totalProductSalesQuantity += 1;
    });

    totalProductSales += item.totalProductSales ?? 0;

    totalSales += item.finalPrice ?? 0;
  });

  return (
    <Container>
      <Row>
        <Col>
          <h4>매출 현황</h4>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-center" style={{ marginBottom: 10 }}>
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={() => {
                dispatch(subtractMonth() as any);
              }}
            >
              이전
            </button>{" "}
            <strong>{dayjs(date).format("YYYY-MM")}</strong>{" "}
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={() => {
                dispatch(addMonth() as any);
              }}
            >
              다음
            </button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <SalesStatisticsTable bookings={bookings} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Table responsive>
            <thead>
              <tr>
                <th>구분</th>
                <th>건수</th>
                <th>총액</th>
                <th>소계 비율</th>
                <th>총매출 비율</th>
              </tr>
            </thead>
            <tbody>
              {categoryNames.map((categoryName) => {
                let count = 0;
                let amount = 0;

                bookings.forEach((item) => {
                  item.options?.forEach((option) => {
                    if (option.categoryName === categoryName) {
                      count += 1;
                      amount += option.price;
                    }
                  });
                });

                return (
                  <tr key={categoryName}>
                    <td>{categoryName}</td>
                    <td className="text-center">{count}</td>
                    <td className="text-end">
                      {numeral(amount).format("0,0")}
                    </td>
                    <td className="text-end">
                      {Math.floor((amount / totalServiceSales) * 100)}%
                    </td>
                    <td></td>
                  </tr>
                );
              })}
              <tr>
                <td>시술 소계</td>
                <td className="text-center" style={{ color: "#2b2b2b" }}>
                  {numeral(totalServiceSalesQuantity).format("0,0")}
                </td>
                <td className="text-end" style={{ color: "#2b2b2b" }}>
                  {numeral(totalServiceSales).format("0,0")}
                </td>
                <td></td>
                <td></td>
              </tr>
              {/* <tr>
                <td>미용 소계</td>
                <td className="text-center" style={{ color: "red" }}>
                  {0}
                </td>
                <td className="text-end" style={{ color: "red" }}>
                  {numeral(0).format("0,0")}
                </td>
                <td></td>
                <td></td>
              </tr> */}
              <tr>
                <td>제품 소계</td>
                <td className="text-center">
                  {numeral(totalProductSalesQuantity).format("0,0")}
                </td>
                <td className="text-end">
                  {numeral(totalProductSales).format("0,0")}
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>총매출 합계</td>
                <td className="text-center">{totalServiceSalesQuantity}</td>
                <td className="text-end">
                  <strong>{numeral(totalSales).format("0,0")}</strong>
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
