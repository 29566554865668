import { Box, Button, IconButton, Stack } from "@mui/material";
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import APIGateway from "../../../api/api-gateway";
import {
  Booking,
  BookingOrigin,
  BookingServiceOption,
  BookingStatus,
  ConsultationStatus,
  NestedConsultation,
  NestedCustomer,
} from "../../../graphql/API";
import { useAppSelector } from "../../../app/hooks";
import {
  fetchBookingById,
  selectDate,
  selectRefreshing,
} from "../../../features/booking";
import {
  setShowCreateConsultationModal,
  setShowUpdateConsultationModal,
} from "../../../features/common";
import { fetchConsultationById } from "../../../features/consultation";
import { bookingStatusKorean } from "../../../app/translation/enum-to-korean";

type Props = {
  bookingsForDisplay: Booking[];
  onClickShowBookingModal: (booking: Booking) => void;
  onClickShowCustomerModal: (booking: Booking) => void;
  onClickShowSelfDiagnosisV2Modal: (booking: Booking) => void;
  onClickShowRegularCustomerNoteModal: (booking: Booking) => void;
};

export default function BookingGridBox({
  bookingsForDisplay,
  onClickShowBookingModal,
  onClickShowCustomerModal,
  onClickShowSelfDiagnosisV2Modal,
  onClickShowRegularCustomerNoteModal,
}: Props) {
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const refreshing = useAppSelector(selectRefreshing);
  const date = useAppSelector(selectDate);

  const apiGateway = new APIGateway();

  const processRowUpdate = useCallback(
    async (newRow: Booking, oldRow: Booking) => {
      if (
        newRow.time === oldRow.time ||
        newRow.origin !== BookingOrigin.MANUAL
      ) {
        enqueueSnackbar("직접 예약 건만 변경할 수 있어요.", {
          variant: "warning",
        });

        return Promise.resolve(oldRow);
      }

      await apiGateway.booking.updateBooking({
        id: newRow.id,
        time: newRow.time,
        _version: newRow._version,
      });

      enqueueSnackbar("예약 시간이 변경되었습니다.", {
        variant: "success",
      });

      return apiGateway.booking.get(newRow.id);
    },
    []
  );

  const columns: GridColumns<Booking> = [
    {
      field: "customer",
      headerName: "고객",
      maxWidth: 60,
      cellClassName: "super-app-theme--no-padding-cell",
      renderCell: (params: GridRenderCellParams<NestedCustomer>) => (
        <Button
          color="primary"
          sx={{ justifyContent: "start", minWidth: 48 }}
          onClick={() => {
            onClickShowCustomerModal(params.row);
          }}
        >
          {params.value?.name}
        </Button>
      ),
    },
    {
      field: "designerName",
      headerName: "담당",
      maxWidth: 60,
    },
    {
      field: "time",
      headerName: "시간",
      maxWidth: 60,
      editable: true,
      preProcessEditCellProps(params) {
        const hasError = params.props.value.length < 5;
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "bookingStatus",
      headerName: "상태",
      maxWidth: 50,
      renderCell: (params: GridRenderCellParams<BookingStatus>) => (
        <>{params.value && bookingStatusKorean[params.value]}</>
      ),
    },
    {
      field: "self",
      headerName: "셀프",
      maxWidth: 50,
      cellClassName: "super-app-theme--no-padding-cell",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <>
          {params.row?.hasRegularCustomerNote && (
            <div>
              <Button
                color="primary"
                onClick={() => {
                  onClickShowRegularCustomerNoteModal(params.row);
                }}
              >
                재방
              </Button>
            </div>
          )}
          {params.row?.hasSelfDiagnosis && (
            <div>
              <Button
                color="primary"
                onClick={() => {
                  onClickShowSelfDiagnosisV2Modal(params.row);
                }}
              >
                셀프
              </Button>
            </div>
          )}
        </>
      ),
    },
    {
      field: "options",
      headerName: "시술",
      flex: 1,
      renderCell: (params: GridRenderCellParams<BookingServiceOption[]>) => {
        return <>{params.value?.map((item) => item.name).join(", ")}</>;
      },
    },
    {
      field: "requestMessage",
      headerName: "예약메시지",
      flex: 1,
    },
    {
      field: "drink",
      headerName: "체크인요청",
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => {
        const list = [];
        if (params.row?.drink) {
          list.push(params.row?.drink);
        }
        params.row?.checkInRequests?.forEach((item: string) => list.push(item));
        return <>{list.join(", ")}</>;
      },
    },
    {
      field: "consultation",
      headerName: "컨설테이션",
      cellClassName: "super-app-theme--no-padding-cell",
      align: "center",
      renderCell: (params: GridRenderCellParams<NestedConsultation>) => {
        function showCreateConsultationModal() {
          dispatch(fetchBookingById(params.row?.id!) as any);
          dispatch(setShowCreateConsultationModal(true) as any);
        }
        function showUpdateConsultationModal() {
          dispatch(fetchConsultationById(params.row?.consultationId!) as any);
          dispatch(setShowUpdateConsultationModal(true) as any);
        }
        return (
          <>
            {params.row?.hasSelfDiagnosis && !params.row?.consultation && (
              <div>
                <Button
                  color="primary"
                  onClick={() => {
                    showCreateConsultationModal();
                  }}
                >
                  시작
                </Button>
              </div>
            )}
            {params.row?.consultation?.status ===
              ConsultationStatus.NOT_STARTED && (
              <div>
                <Button
                  color="primary"
                  onClick={() => {
                    showUpdateConsultationModal();
                  }}
                >
                  시작
                </Button>
              </div>
            )}
            {params.row?.consultation?.status ===
              ConsultationStatus.IN_PROGRESS && (
              <div>
                <Button
                  color="secondary"
                  onClick={() => {
                    showUpdateConsultationModal();
                  }}
                >
                  입력
                </Button>
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "report",
      headerName: "전송",
      maxWidth: 80,
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <>
            {params.row?.consultation?.solutionSentAt
              ? "전송됨"
              : params.row?.consultation
              ? "미전송"
              : null}
          </>
        );
      },
    },
    {
      field: "write",
      headerName: "입력",
      maxWidth: 50,
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <IconButton
          size="small"
          disabled={params.row.bookingStatus === BookingStatus.CANCELED}
          onClick={() => {
            onClickShowBookingModal(params.row);
          }}
        >
          <ModeEditIcon color="primary" />
        </IconButton>
      ),
    },
  ];

  return (
    <Box
      sx={{
        minHeight: 300,
        width: "100%",
        marginTop: 1,
        "& .super-app-theme--CANCELED": {
          color: (theme) => "gray",
        },
        "& .super-app-theme--no-padding-cell": {
          padding: "0 !important",
        },
      }}
    >
      <DataGridPro
        columns={columns}
        rows={bookingsForDisplay}
        loading={refreshing}
        autoHeight={true}
        initialState={{
          pinnedColumns: { left: ["customer"], right: ["write"] },
        }}
        getRowClassName={(params) =>
          `super-app-theme--${params.row.bookingStatus}`
        }
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          NoRowsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center"
              sx={{ textAlign: "center" }}
            >
              {date}
              <br />
              예약이 없습니다.
            </Stack>
          ),
        }}
      />
    </Box>
  );
}
