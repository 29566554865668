import dayjs from "dayjs";
import numeral from "numeral";
import { Button } from "react-bootstrap";
import { PrepaidPassPreset } from "../../../graphql/API";

type Props = {
  item: PrepaidPassPreset;
  deletePass: any;
};

export default function PrepaidPassPresetRow({ item, deletePass }: Props) {
  return (
    <tr>
      <td>{item.name}</td>
      <td>{numeral(item.price).format("0,0")}</td>
      <td>{numeral(item.availableAmount).format("0,0")}</td>
      <td>{dayjs(item.createdAt).format("YYYY-MM-DD")}</td>
      <td>
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={() => {
            deletePass(item.id, item._version);
          }}
        >
          삭제
        </Button>
      </td>
    </tr>
  );
}
