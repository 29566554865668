import { Box, Button, Chip, Tab, Tabs } from "@mui/material";
import { useSnackbar } from "notistack";
import { SyntheticEvent, useEffect, useLayoutEffect, useState } from "react";
import { Row, Col, Badge } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import APIGateway from "../../../../api/api-gateway";
import { useAppSelector, useWindowSize } from "../../../../app/hooks";
import {
  fetchBookingById,
  refreshBookings,
  selectBooking,
} from "../../../../features/booking";
import {
  BookingServiceOption,
  ServiceOptionCategory,
  ServiceStatus,
} from "../../../../graphql/API";
import BookingService from "../../../../services/booking";

export default function ServiceOptionRow() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { width = window.innerWidth } = useWindowSize();

  const booking = useAppSelector(selectBooking);
  const [bookingOnEdit, setBookingOnEdit] = useState(booking);
  const [serviceOptionCategories, setServiceOptionCategories] = useState<
    ServiceOptionCategory[]
  >([]);
  const [serviceOptions, setServiceOptions] = useState<BookingServiceOption[]>(
    []
  );
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const apiGateway = new APIGateway();

  const bookingService = new BookingService(apiGateway.booking);

  useEffect(() => {
    if (!booking) {
      return;
    }

    setBookingOnEdit(booking);

    let options: BookingServiceOption[] = [];

    if (booking.options) {
      options = booking.options;
    }

    setServiceOptions(
      options.map((option) => {
        return {
          ...option,
          originalPrice: option.originalPrice ?? option.price,
        };
      })
    );

    initialize(booking.shopId);
  }, [booking]);

  async function initialize(shopId: string) {
    const list = await apiGateway.serviceOption.listShopServiceOptions(shopId);

    setServiceOptionCategories(list);
  }

  async function handleSaveChanges() {
    if (!bookingOnEdit) {
      return;
    }

    if (!booking) {
      return;
    }

    await bookingService.updateServiceOptions(serviceOptions, bookingOnEdit);

    enqueueSnackbar("서비스 항목을 저장했습니다.", { variant: "success" });

    dispatch(fetchBookingById(booking.id) as any);

    dispatch(refreshBookings() as any);
  }

  function spliceServiceOption(index: number) {
    const existingServiceOptions = [...serviceOptions];

    existingServiceOptions.splice(index, 1);

    setServiceOptions(existingServiceOptions);
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  if (!booking || !bookingOnEdit) {
    return <></>;
  }

  return (
    <>
      <tr>
        <th>시술</th>
        <td colSpan={3}>
          <Row>
            <Col>
              {serviceOptionCategories.length === 0 && (
                <Button
                  size="small"
                  className="me-auto"
                  variant="outlined"
                  onClick={() => {
                    navigate("/service-option");
                  }}
                >
                  시술 메뉴 관리
                </Button>
              )}
              {serviceOptions?.map((item, index) => {
                return (
                  <Chip
                    variant="outlined"
                    color="info"
                    size="small"
                    label={item.name}
                    key={`${item.name}${index}`}
                    onDelete={() => {
                      spliceServiceOption(index);
                    }}
                    style={{ marginRight: 5, marginBottom: 5 }}
                  ></Chip>
                );
              })}
              {booking.serviceStatus === ServiceStatus.IN_PROGRESS ? (
                <Badge bg="success">시술 중</Badge>
              ) : null}
              {booking.signature ? (
                <img
                  src={booking.signature}
                  style={{ marginTop: 10, maxHeight: 40 }}
                />
              ) : null}
            </Col>
            <Col xs="auto">
              <Button
                size="small"
                variant="contained"
                color="info"
                disabled={serviceOptionCategories.length === 0}
                onClick={handleSaveChanges}
              >
                저장
              </Button>
            </Col>
          </Row>
        </td>
      </tr>
      <tr>
        <td colSpan={4}>
          <Box
            sx={{
              maxWidth: isMobile
                ? {
                    xs: width - 40,
                    sm: width - 40,
                  }
                : 400,
            }}
          >
            <Tabs
              value={value}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {serviceOptionCategories.map((item, index) => (
                <Tab label={item.name} wrapped {...a11yProps(index)} />
              ))}
            </Tabs>

            {serviceOptionCategories.map((item, index) => (
              <TabPanel value={value} index={index}>
                {item.options?.map((option, index) => {
                  return (
                    <Chip
                      key={`${option.name}${index}`}
                      style={{ marginRight: 5, marginBottom: 5 }}
                      variant="outlined"
                      label={option.name}
                      size="small"
                      onClick={() => {
                        const existingServiceOptions = [...serviceOptions];

                        const { name, price } = option;

                        existingServiceOptions.push({
                          name,
                          price: price ?? 0,
                          categoryName: "",
                          originalPrice: price ?? 0,
                        } as any);

                        setServiceOptions(existingServiceOptions);
                      }}
                    ></Chip>
                  );
                })}
              </TabPanel>
            ))}
          </Box>
        </td>
      </tr>
    </>
  );
}
