import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import BrandAPI from "../../api/brand";
import { RootState } from "../../app/store";
import { Brand } from "../../graphql/API";

export interface BrandState {
  brands: Brand[];
  shopId?: string;
}

const initialState: BrandState = {
  brands: [],
  shopId: undefined,
};

const brandAPI = new BrandAPI();

const fetchBrandsByShopId = createAsyncThunk(
  "brands/fetchBrandsByShopId",
  async (shopId: string) => {
    return brandAPI.listBrand(shopId);
  }
);

export const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchBrandsByShopId.fulfilled,
      (state, { payload }: { payload: Brand[] }) => {
        state.brands = payload.filter((item) => !item._deleted);

        if (payload.length > 0) {
          state.shopId = payload[0].shopId;
        }
      }
    );
  },
});

export const selectBrands = (state: RootState) => state.brand.brands;

export const selectShopId = (state: RootState) => state.brand.shopId;

export { fetchBrandsByShopId };

export default brandSlice.reducer;
