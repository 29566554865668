import { API, graphqlOperation } from "aws-amplify";
import {
  CreateDesignerInput,
  Designer,
  UpdateDesignerInput,
} from "../../graphql/API";
import { createDesigner, updateDesigner } from "../../graphql/mutations";
import { designerByShopId, getDesigner } from "../../graphql/queries";

export default class DesignerAPI {
  async listByShopId(shopId: string): Promise<Designer[]> {
    const response: any = await API.graphql(
      graphqlOperation(designerByShopId, {
        shopId,
      })
    );
    return response.data.designerByShopId.items;
  }

  async get(id: string): Promise<Designer> {
    const response: any = await API.graphql({
      query: getDesigner,
      variables: { id },
    });
    return response.data.getDesigner;
  }

  async create(input: CreateDesignerInput) {
    return API.graphql({
      query: createDesigner,
      variables: {
        input,
      },
    });
  }

  async update(input: UpdateDesignerInput) {
    return API.graphql({
      query: updateDesigner,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }
}
