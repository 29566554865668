import { Container, Row, Col } from "react-bootstrap";

export default function NotFound404() {
  return (
    <Container>
      <Row>
        <Col>
          <h4>페이지를 찾을 수 없습니다.</h4>
        </Col>
      </Row>
    </Container>
  );
}
