import { API, graphqlOperation } from "aws-amplify";
import {
  CreateServiceOptionInput,
  UpdateServiceOptionInput,
} from "../../graphql/API";
import {
  createServiceOption,
  updateServiceOption,
} from "../../graphql/mutations";
import { getServiceOption } from "../../graphql/queries";
import { ServiceOption } from "../../graphql/API";

export default class ServiceOptionAPI {
  async listShopServiceOptions(shopId: string) {
    const result: any = await API.graphql(
      graphqlOperation(getServiceOption, {
        shopId,
      })
    );

    const data: ServiceOption = result.data.getServiceOption;

    return data?.categories ?? [];
  }
  async get(shopId: string) {
    const result: any = await API.graphql(
      graphqlOperation(getServiceOption, {
        shopId,
      })
    );

    const data: ServiceOption = result.data.getServiceOption;

    return data;
  }
  async create(input: CreateServiceOptionInput) {
    return API.graphql(
      graphqlOperation(createServiceOption, {
        input,
      })
    );
  }
  async update(input: UpdateServiceOptionInput) {
    if (!input._version) {
      throw new Error("Missing _version");
    }
    return API.graphql(
      graphqlOperation(updateServiceOption, {
        input,
      })
    );
  }
}
