import { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import consultationConstants from "../../../../app/constants/consultation";
import { useAppSelector } from "../../../../app/hooks";
import { selectStudyMode } from "../../../../features/consultation";
import { ConsultationMode } from "../../../../models";
import MultiSelectionFromCategorizedStringArray from "../MultiSelectionFromCategorizedStringArray";
import QuestionTitle from "../QuestionTitle";
import SingleSelectionFromCategorizedStringArray from "../SingleSelectionFromCategorizedStringArray";
import SingleSelectionFromStringArray from "../SingleSelectionFromStringArray";
import SubTitle from "../SubTitle";
import styles from "./StepTwo.module.scss";

export default function StepTwo({
  input,
  setInput,
  toggleSelection,
}: {
  input: any;
  setInput: any;
  toggleSelection: any;
}) {
  const studyMode = useAppSelector(selectStudyMode);

  const gender: "female" | "male" = input.gender.toLowerCase() ?? "female";

  const fullMode = input.mode === ConsultationMode.FULL || !input.mode;
  const cutMode = input.mode === ConsultationMode.CUT;
  const colorMode = input.mode === ConsultationMode.COLOR;
  const permMode = input.mode === ConsultationMode.PERM;

  let title = "얼굴형/두상, 목/어깨 밸런스 및 모발 파악";

  if (!fullMode) {
    title = "모발 상태 및 유형 파악";
  }

  return (
    <Container className={styles.StepContainer}>
      <Row className="bg-white sticky-header">
        <Col>
          <SubTitle prefix="STEP 2" title={title} />
        </Col>
      </Row>

      <Row
        className={
          fullMode || cutMode || permMode
            ? styles.QuestionBoxMobilePadding
            : "d-none"
        }
      >
        <Col>
          <Form.Group as={Row} className={styles.FirstQuestionBox}>
            <QuestionTitle title="얼굴형" />
            <Col className={styles.ButtonBox}>
              <Row>
                {consultationConstants.faceShape.map((faceShape) => {
                  return (
                    <Col key={faceShape.name} md={3} xs={6}>
                      <button
                        className={`${styles.FaceShapeButton} ${
                          input.faceShapes?.includes(faceShape.name)
                            ? styles.HeadImageButtonActive
                            : ""
                        }`}
                        onClick={(e) => {
                          e.preventDefault();

                          const previousState: string[] =
                            input.faceShapes ?? [];

                          let newState = previousState;

                          if (previousState.includes(faceShape.name)) {
                            newState = previousState.filter(
                              (item) => item !== faceShape.name
                            );
                          } else {
                            newState = [...previousState, faceShape.name];
                          }

                          setInput({
                            ...input,
                            faceShapes: newState,
                          });
                        }}
                      >
                        <div className="text-center">
                          <img
                            src={`../../images/consultation/face-shape/${faceShape.imageFilePath}.png`}
                          />
                        </div>
                        <div className={styles.Title}>{faceShape.title}</div>
                        {studyMode ? (
                          <div className={styles.FaceShapeTextBox}>
                            <div className={"fs-6 " + styles.FaceShapeText}>
                              <Col className="text-start">이마</Col>
                              <div className="text-end text-info">
                                <span>{faceShape.forehead}</span>
                              </div>
                            </div>

                            <div className={"fs-6 " + styles.FaceShapeText}>
                              <Col className="text-start">광대</Col>
                              <div className="text-end text-info">
                                <span>{faceShape.cheekbone}</span>
                              </div>
                            </div>

                            <div className={"fs-6 " + styles.FaceShapeText}>
                              <Col className="text-start">턱</Col>
                              <div className="text-end text-info">
                                <span>{faceShape.jaw}</span>
                              </div>
                            </div>

                            <div className={"fs-6 " + styles.FaceShapeText}>
                              <Col className="text-start">길이/넓이</Col>
                              <div className="text-end text-info">
                                <span>{faceShape.size}</span>
                              </div>
                            </div>
                            <div className={styles.StudyText}>
                              <p>{faceShape.description}</p>

                              {faceShape.solutions && (
                                <ul style={{ textAlign: "left" }}>
                                  {faceShape.cons && (
                                    <li>Cons - {faceShape.cons}</li>
                                  )}
                                  {faceShape.solutions.map((item) => (
                                    <li>
                                      {item.feature}
                                      <ul>
                                        <li>{item.solution}</li>
                                      </ul>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </button>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Form.Group>
        </Col>
      </Row>

      <Row
        className={
          fullMode || cutMode || permMode
            ? styles.QuestionBoxMobilePadding
            : "d-none"
        }
      >
        <Col>
          <Form.Group as={Row} className={styles.QuestionBox}>
            <QuestionTitle title="두상" />
            <Col className={styles.ButtonBox}>
              <Row className={styles.HeadShapeRow}>
                {consultationConstants.frontHeadShape.map((frontHeadShape) => {
                  return (
                    <Col key={frontHeadShape.name} md={3} xs={6}>
                      <button
                        className={`${styles.FrontHeadShapeButton} ${
                          input.frontHeadShape === frontHeadShape.name
                            ? styles.HeadImageButtonActive
                            : ""
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          setInput({
                            ...input,
                            frontHeadShape: frontHeadShape.name,
                          });
                        }}
                      >
                        <div className="text-center">
                          <img
                            src={`../../images/consultation/front-head-shape/${frontHeadShape.imageFilePath}.png`}
                          />
                        </div>
                        {studyMode && (
                          <div className={styles.FrontHeadShapeTextContainer}>
                            <div className={styles.FrontHeadShapeTextBox}>
                              <Col
                                className={
                                  "text-start me-2 " +
                                  styles.FrontHeadShapeTextTitle
                                }
                              >
                                앞면
                              </Col>
                              <Col className="text-end">
                                <span className="blue">
                                  {frontHeadShape.front}
                                </span>
                              </Col>
                            </div>
                            <div className={styles.FrontHeadShapeTextBox}>
                              <Col
                                className={
                                  "text-start me-2 " +
                                  styles.FrontHeadShapeTextTitle
                                }
                              >
                                뒷면
                              </Col>
                              <Col className="text-end">
                                <span className="pink">
                                  {frontHeadShape.back}
                                </span>
                              </Col>
                            </div>
                          </div>
                        )}
                      </button>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Form.Group>
        </Col>
      </Row>

      <Row
        className={
          fullMode || cutMode || permMode
            ? styles.QuestionBoxMobilePadding
            : "d-none"
        }
      >
        <Col>
          <Form.Group as={Row} className={styles.QuestionBox}>
            <QuestionTitle title="사이드" />
            <Col className={styles.ButtonBox}>
              <Row className={styles.HeadShapeRow}>
                {consultationConstants.sideHeadShape.map((sideHeadShape) => {
                  return (
                    <Col key={sideHeadShape.name} md={3} xs={6}>
                      <button
                        className={`${styles.SideHeadShapeButton} ${
                          input.sideHeadShape === sideHeadShape.name
                            ? styles.HeadImageButtonActive
                            : ""
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          setInput({
                            ...input,
                            sideHeadShape: sideHeadShape.name,
                          });
                        }}
                      >
                        <div className="text-center">
                          <img
                            src={`../../images/consultation/side-head-shape/${sideHeadShape.imageFilePath}.png`}
                          />
                        </div>
                        {studyMode && (
                          <div className={styles.SideHeadShapeTextContainer}>
                            <div className={styles.SideHeadShapeTextBox}>
                              <Col
                                className={
                                  "text-start me-2 " +
                                  styles.SideHeadShapeTextTitle
                                }
                              >
                                사이드
                              </Col>
                              <Col className="text-end">
                                <span>{sideHeadShape.side}</span>
                              </Col>
                            </div>
                            <div className={styles.SideHeadShapeTextBox}>
                              <Col
                                className={
                                  "text-start me-2 " +
                                  styles.SideHeadShapeTextTitle
                                }
                              >
                                윗면
                              </Col>
                              <Col className="text-end">
                                <span>{sideHeadShape.top}</span>
                              </Col>
                            </div>
                          </div>
                        )}
                      </button>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Form.Group>
        </Col>
      </Row>

      <Row className={fullMode ? styles.QuestionBoxMobilePadding : "d-none"}>
        <Col>
          <Form.Group as={Row} className={styles.QuestionBox}>
            <QuestionTitle title="목 길이" />
            <Col className={styles.ButtonBox}>
              <SingleSelectionFromStringArray
                constants={consultationConstants.neckLength}
                inputValue={input.neckLength}
                inputFieldName="neckLength"
                onClick={toggleSelection}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>

      <Row className={fullMode ? styles.QuestionBoxMobilePadding : "d-none"}>
        <Col>
          <Form.Group as={Row} className={styles.QuestionBox}>
            <QuestionTitle title="목 두께" />
            <Col className={styles.ButtonBox}>
              <SingleSelectionFromStringArray
                constants={consultationConstants.neckThickness}
                inputValue={input.neckThickness}
                inputFieldName="neckThickness"
                onClick={toggleSelection}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>

      <Row className={fullMode ? styles.QuestionBoxMobilePadding : "d-none"}>
        <Col>
          <Form.Group as={Row} className={styles.QuestionBox}>
            <QuestionTitle title="어깨 너비" />
            <Col className={styles.ButtonBox}>
              <SingleSelectionFromStringArray
                constants={consultationConstants.shoulderWidth}
                inputValue={input.shoulderWidth}
                inputFieldName="shoulderWidth"
                onClick={toggleSelection}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>

      <Row className={fullMode ? styles.QuestionBoxMobilePadding : "d-none"}>
        <Col>
          <Form.Group as={Row} className={styles.QuestionBox}>
            <QuestionTitle title="어깨 모양" />
            <Col className={styles.ButtonBox}>
              <SingleSelectionFromStringArray
                constants={consultationConstants.shoulderShape}
                inputValue={input.shoulderShape}
                inputFieldName="shoulderShape"
                onClick={toggleSelection}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>

      <Row
        className={
          fullMode || colorMode ? styles.QuestionBoxMobilePadding : "d-none"
        }
      >
        <Col>
          <Form.Group
            as={Row}
            className={colorMode ? styles.FirstQuestionBox : styles.QuestionBox}
          >
            <QuestionTitle title="현재 모발 상태" />
            <Col className={styles.ButtonBox}>
              <SingleSelectionFromCategorizedStringArray
                constants={consultationConstants.virginHairColor || []}
                inputValue={input.virginHairColor}
                inputFieldName="virginHairColor"
                onClick={toggleSelection}
              />
              <MultiSelectionFromCategorizedStringArray
                constants={consultationConstants.colorfulnessOfHair || []}
                inputArray={input.colorfulnessesOfHair}
                inputFieldName="colorfulnessesOfHair"
                onClick={toggleSelection}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>

      <Row className={styles.QuestionBoxMobilePadding}>
        <Col>
          <Form.Group
            as={Row}
            className={cutMode ? styles.FirstQuestionBox : styles.QuestionBox}
          >
            <QuestionTitle title="모발 손상도" />
            {studyMode && (
              <small>
                * 물을 적시고 빗질 시 기준
                <ul>
                  <li>건강 - 빗질 시 걸림이 없는 상태</li>
                  <li>보통 - 천천히 하면 끝까지 빗질할 수 있는 상태</li>
                  <li>손상 - 빗질 시 중간중간 걸리면서 잘 안되는 상태</li>
                  <li>극손상 - 빗질 불가</li>
                </ul>
              </small>
            )}
            <Col className={styles.ButtonBox}>
              <SingleSelectionFromStringArray
                constants={consultationConstants.damageDegreeOfHair}
                inputValue={input.damageDegreeOfHair}
                inputFieldName="damageDegreeOfHair"
                onClick={toggleSelection}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>

      <Row className={styles.QuestionBoxMobilePadding}>
        <Col>
          <Form.Group as={Row} className={styles.QuestionBox}>
            <QuestionTitle title="모량" />
            <Col className={styles.ButtonBox}>
              <SingleSelectionFromStringArray
                constants={consultationConstants.amountOfHair}
                inputValue={input.amountOfHair}
                inputFieldName="amountOfHair"
                onClick={toggleSelection}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>

      <Row className={styles.QuestionBoxMobilePadding}>
        <Col>
          <Form.Group as={Row} className={styles.QuestionBox}>
            <QuestionTitle title="모질" />
            <Col className={styles.ButtonBox}>
              <SingleSelectionFromStringArray
                constants={consultationConstants.curlDegreeOfHair}
                inputValue={input.curlDegreeOfHair}
                inputFieldName="curlDegreeOfHair"
                onClick={toggleSelection}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>

      <Row className={styles.QuestionBoxMobilePadding}>
        <Col>
          <Form.Group as={Row} className={styles.QuestionBox}>
            <QuestionTitle title="모발 굵기" />
            <Col className={styles.ButtonBox}>
              <SingleSelectionFromStringArray
                constants={consultationConstants.thicknessOfHair}
                inputValue={input.thicknessOfHair}
                inputFieldName="thicknessOfHair"
                onClick={toggleSelection}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
}
