import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "aws-amplify";
import dayjs, { Dayjs } from "dayjs";
import { RootState } from "../../app/store";
import { Booking } from "../../graphql/API";
import { PaymentStatus } from "../../models";
import { BookingStatus } from "../../models";

const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      date
      time
      customer {
        phoneNumber
        name
        id
      }
      customerId
      designerName
      designerId
      optionName
      shopId
      naverBookingNumber
      serviceStatus
      paymentStatus
      bookingStatus
      requestMessage
      designerMemo
      naverSnapshot {
        price
        bizItemPrice
        onsitePrice
        isOnsitePayment
      }
      naverOptions {
        name
      }
      naverPayments {
        moment
        provider
        paymentId
        bookingId
        amount
        providerDiscountAmount
        regDateTime
        status
        statusHistories {
          status
          dateTime
        }
      }
      initialPrice
      finalPrice
      totalServiceSales
      totalProductSales
      initialDeposit
      paidByCardAmount
      paidInCashAmount
      paidByPrepaidPassAmount
      paidByNaverPayAmount
      paidAmount
      paymentMemo
      checkInRequests
      drink
      checkedInAt
      origin
      options {
        categoryName
        name
        discount
        discountRate
        originalPrice
        price
        discountPreset {
          id
          shopId
          name
          amount
          rate
          type
        }
      }
      productOptions {
        name
        price
        quantity
        product {
          id
          name
          shopId
          brandId
          brandName
          costPrice
          sellingPrice
        }
      }
      signature
      signatureOfPortraitRights
      consultationId
      consultation {
        status
        solutionSentAt
        hasPhotos
      }
      hasSelfDiagnosis
      hasRegularCustomerNote
      hasPhotos
      hasDesignCompletionPhotos
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

const bookingByShopId = /* GraphQL */ `
  query BookingByShopId(
    $shopId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByShopId(
      shopId: $shopId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        time
        customer {
          phoneNumber
          name
          id
        }
        customerId
        designerName
        designerId
        optionName
        shopId
        naverBookingNumber
        serviceStatus
        paymentStatus
        bookingStatus
        requestMessage
        designerMemo
        initialPrice
        totalServiceSales
        totalProductSales
        finalPrice
        initialDeposit
        paidAmount
        paymentMemo
        checkInRequests
        drink
        checkedInAt
        origin
        options {
          categoryName
          name
        }
        productOptions {
          name
          price
          quantity
          product {
            id
            name
            shopId
            brandId
            brandName
            costPrice
            sellingPrice
          }
        }
        signature
        consultationId
        consultation {
          status
          solutionSentAt
          selfDiagnosisStatus
          inflowChannel
          hasPhotos
        }
        hasSelfDiagnosis
        hasRegularCustomerNote
        createdAt
        updatedAt
        recentNaverDataReflection
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export interface BookingState {
  bookings: Booking[];
  booking?: Booking;
  bookingByConsultationId?: Booking;
  date: string;
  shopId?: string;
  bookingsByMonth: Booking[];
  bookingsForDailyClosing: Booking[];
  dateDurationByDays: number;
  refreshing: boolean;
}

const initialState: BookingState = {
  bookings: [],
  booking: undefined,
  bookingByConsultationId: undefined,
  date: dayjs().format("YYYY-MM-DD"),
  shopId: undefined,
  bookingsByMonth: [],
  bookingsForDailyClosing: [],
  dateDurationByDays: 1,
  refreshing: false,
};

const fetchBookingsByShopIdAndDate = createAsyncThunk(
  "bookings/fetchBookingsByShopIdAndDate",
  async ({
    shopId,
    date,
    dateDurationByDays,
  }: {
    shopId: string;
    date: string;
    dateDurationByDays: number;
  }) => {
    const dateFilter =
      dateDurationByDays === 1
        ? {
            eq: date,
          }
        : {
            between: [date, dayjs(date).add(6, "day").format("YYYY-MM-DD")],
          };

    const response: any = await API.graphql(
      graphqlOperation(bookingByShopId, {
        shopId,
        date: dateFilter,
        limit: 100,
      })
    );
    return response.data.bookingByShopId.items;
  }
);

const refreshBookings = createAsyncThunk(
  "bookings/refreshBookings",
  async (payload, { getState }) => {
    const rootState = getState() as RootState;
    const { currentShopId } = rootState.common;
    const { date, dateDurationByDays } = rootState.booking;

    const dateFilter =
      dateDurationByDays === 1
        ? {
            eq: date,
          }
        : {
            between: [date, dayjs(date).add(6, "day").format("YYYY-MM-DD")],
          };

    const response: any = await API.graphql(
      graphqlOperation(bookingByShopId, {
        shopId: currentShopId,
        date: dateFilter,
        limit: 100,
      })
    );
    return response.data.bookingByShopId.items;
  }
);

const fetchBookingsForDailyClosing = createAsyncThunk(
  "bookings/fetchBookingsForDailyClosing",
  async ({ shopId, date }: { shopId: string; date: string }) => {
    const dateFilter = {
      eq: date,
    };

    const response: any = await API.graphql(
      graphqlOperation(bookingByShopId, {
        shopId,
        date: dateFilter,
        filter: {
          bookingStatus: {
            ne: BookingStatus.CANCELED,
          },
          finalPrice: {
            gt: 0,
          },
          paymentStatus: {
            eq: PaymentStatus.PAID,
          },
        },
        limit: 100,
      })
    );
    return response.data.bookingByShopId.items;
  }
);

const fetchBookingsByShopIdAndMonth = createAsyncThunk(
  "bookings/fetchBookingsByShopIdAndMonth",
  async ({ shopId, month }: { shopId: string; month: string }) => {
    const variables = {
      shopId,
      date: {
        beginsWith: month,
      },
      filter: {
        bookingStatus: {
          ne: BookingStatus.CANCELED,
        },
        finalPrice: {
          gt: 0,
        },
        paymentStatus: {
          eq: PaymentStatus.PAID,
        },
      },
      limit: 1000,
    };

    const response: any = await API.graphql(
      graphqlOperation(bookingByShopId, variables)
    );

    let items: Booking[] = response.data.bookingByShopId.items;

    const { nextToken } = response.data.bookingByShopId;

    if (nextToken) {
      const nextVariables = {
        ...variables,
        nextToken,
      };

      const nextResponse: any = await API.graphql(
        graphqlOperation(bookingByShopId, nextVariables)
      );
      const nextItems = nextResponse.data.bookingByShopId.items;

      items = items.concat(nextItems);
    }

    return items;
  }
);

const fetchBookingById = createAsyncThunk(
  "bookings/fetchBookingById",
  async (id: string) => {
    const response: any = await API.graphql(
      graphqlOperation(getBooking, {
        id,
      })
    );
    return response.data.getBooking;
  }
);

const fetchBookingByConsultationId = createAsyncThunk(
  "bookings/fetchBookingByConsultationId",
  async (id: string) => {
    const response: any = await API.graphql(
      graphqlOperation(getBooking, {
        id,
      })
    );
    return response.data.getBooking;
  }
);

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    addDay: (state) => {
      state.date = dayjs(state.date).add(1, "day").format("YYYY-MM-DD");
    },
    subtractDay: (state) => {
      state.date = dayjs(state.date).subtract(1, "day").format("YYYY-MM-DD");
    },
    addMonth: (state) => {
      state.date = dayjs(state.date).add(1, "month").format("YYYY-MM-DD");
    },
    subtractMonth: (state) => {
      state.date = dayjs(state.date).subtract(1, "month").format("YYYY-MM-DD");
    },
    emptyBooking: (state) => {
      state.booking = undefined;
    },
    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    setDateDurationByDaysAsOne: (state) => {
      state.dateDurationByDays = 1;
    },
    setDateDurationByDaysAsSeven: (state) => {
      state.dateDurationByDays = 7;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchBookingsByShopIdAndDate.fulfilled,
      (state, { payload }) => {
        payload.sort((a: Booking, b: Booking) => {
          return `${a.date}${a.time}`.localeCompare(`${b.date}${b.time}`);
        });

        state.bookings = payload;

        if (payload.length > 0) {
          state.shopId = payload[0].shopId;
        }
      }
    );

    builder.addCase(refreshBookings.pending, (state) => {
      state.refreshing = true;
    });

    builder.addCase(refreshBookings.rejected, (state) => {
      state.refreshing = false;
    });

    builder.addCase(refreshBookings.fulfilled, (state, { payload }) => {
      payload.sort((a: Booking, b: Booking) => {
        return `${a.date}${a.time}`.localeCompare(`${b.date}${b.time}`);
      });

      state.bookings = payload;

      if (payload.length > 0) {
        state.shopId = payload[0].shopId;
      }

      state.refreshing = false;
    });

    builder.addCase(
      fetchBookingsForDailyClosing.fulfilled,
      (state, { payload }) => {
        payload.sort((a: Booking, b: Booking) => {
          return `${a.date}${a.time}`.localeCompare(`${b.date}${b.time}`);
        });

        state.bookingsForDailyClosing = payload;

        if (payload.length > 0) {
          state.shopId = payload[0].shopId;
        }
      }
    );

    builder.addCase(
      fetchBookingsByShopIdAndMonth.fulfilled,
      (state, { payload }) => {
        state.bookingsByMonth = payload;

        if (payload.length > 0) {
          state.shopId = payload[0].shopId;
        }
      }
    );

    builder.addCase(fetchBookingById.fulfilled, (state, { payload }) => {
      state.booking = payload;
    });

    builder.addCase(
      fetchBookingByConsultationId.fulfilled,
      (state, { payload }) => {
        state.bookingByConsultationId = payload;
      }
    );
  },
});

export const selectBookings = (state: RootState) => state.booking.bookings;

export const selectBookingsByMonth = (state: RootState) =>
  state.booking.bookingsByMonth;

export const selectBookingsForDailyClosing = (state: RootState) =>
  state.booking.bookingsForDailyClosing;

export const selectBooking = (state: RootState) => state.booking.booking;

export const selectBookingByConsultationId = (state: RootState) =>
  state.booking.bookingByConsultationId;

export const selectDate = (state: RootState) => state.booking.date;

export const selectDateDurationByDays = (state: RootState) =>
  state.booking.dateDurationByDays;

export const selectShopId = (state: RootState) => state.booking.shopId;

export const selectRefreshing = (state: RootState) => state.booking.refreshing;

export const {
  addDay,
  subtractDay,
  addMonth,
  subtractMonth,
  emptyBooking,
  setDateDurationByDaysAsOne,
  setDateDurationByDaysAsSeven,
  setDate,
} = bookingSlice.actions;

export {
  fetchBookingsByShopIdAndDate,
  fetchBookingById,
  fetchBookingByConsultationId,
  fetchBookingsByShopIdAndMonth,
  fetchBookingsForDailyClosing,
  refreshBookings,
};

export default bookingSlice.reducer;
