import { Button, Col, Row } from "react-bootstrap";
import styles from "./index.module.scss";

export default function MultiSelectionFromStringArray({
  constants,
  inputArray,
  inputFieldName,
  onClick,
}: {
  constants: string[];
  inputArray: string[];
  inputFieldName: string;
  onClick: any;
}) {
  let md = 4;

  if (constants.length === 4) {
    md = 3;
  }

  const xs = 6;

  return (
    <Row>
      {constants.map((item: any) => (
        <Col xs={xs} md={md} key={item} className="d-grid">
          <Button
            variant={inputArray.includes(item) ? "active" : "inactive"}
            onClick={() => {
              onClick(item, inputArray, inputFieldName);
            }}
          >
            {item}
          </Button>
        </Col>
      ))}
    </Row>
  );
}
