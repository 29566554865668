import { useState } from "react";
import { Badge, Button, Offcanvas } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { CognitoUserAmplify } from "@aws-amplify/ui";
import { useAppSelector } from "../../app/hooks";
import { selectUserGroups } from "../../features/common";
import BackendAPI from "../../api/backend-api";
import { Box } from "@mui/material";

export default function Navigation({
  showNavigation,
  setShowNavigation,
  user,
  signOut,
}: {
  showNavigation: boolean;
  setShowNavigation: (showNavigation: boolean) => void;
  user: CognitoUserAmplify;
  signOut: () => void;
}) {
  let navigate = useNavigate();

  const groups = useAppSelector(selectUserGroups);

  const [activeKey, setActiveKey] = useState("/");

  const handleClose = () => setShowNavigation(false);
  const handleShow = () => setShowNavigation(true);

  const backendAPI = new BackendAPI();

  const isOwner = groups.find((group) => group === "Owner");

  const attributes = user?.attributes ?? ({} as any);

  return (
    <Offcanvas show={showNavigation} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>메뉴</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Nav
          className="flex-column"
          activeKey={activeKey}
          onSelect={(selectedKey) => {
            const newKey = selectedKey ?? "";
            setActiveKey(newKey);
            navigate(newKey);
            handleClose();
          }}
        >
          <div className="text-center">
            <h6>
              {attributes["custom:designer_name"] ?? attributes.email ?? ""}
            </h6>
          </div>
          <div className="text-center" style={{ marginTop: 5 }}>
            {isOwner && (
              <Badge bg="secondary" style={{ marginRight: 5 }}>
                점주
              </Badge>
            )}
            {groups.find((group) => group === "Designer") && (
              <Badge bg="success">디자이너</Badge>
            )}
          </div>

          <div
            className="text-center"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                navigate("/settings");
                handleClose();
              }}
            >
              사용자 설정
            </Button>
            {isOwner && (
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  backendAPI.openOwnerWebsite();
                }}
                style={{ marginLeft: 5 }}
              >
                매장 설정
              </Button>
            )}
          </div>

          <Nav.Item>
            <Nav.Link eventKey="/">예약</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="/customer">고객</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="/feedback">피드백</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="/schedule">예약판</Nav.Link>
          </Nav.Item>
          <Nav.Item className="d-none">
            <Nav.Link eventKey="/daily-closing">일 마감</Nav.Link>
          </Nav.Item>
          <Nav.Item className="d-none">
            <Nav.Link eventKey="/sales-status">매출 현황</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="/service-option">시술 메뉴</Nav.Link>
          </Nav.Item>
          <Nav.Item className="d-none">
            <Nav.Link eventKey="/discount-preset">할인 설정</Nav.Link>
          </Nav.Item>
          <Nav.Item className="d-none">
            <Nav.Link eventKey="/prepaid-pass-preset">정액권 설정</Nav.Link>
          </Nav.Item>
          <Nav.Item className="d-none">
            <Nav.Link eventKey="/brand">브랜드 설정</Nav.Link>
          </Nav.Item>
          <Nav.Item className="d-none">
            <Nav.Link eventKey="/product">상품 설정</Nav.Link>
          </Nav.Item>
        </Nav>
        <Box sx={{ textAlign: "center", marginTop: 2, marginBottom: 4 }}>
          <Link to="/faq">자주 묻는 질문</Link>
        </Box>
        <div className="text-center">
          {user ? (
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                if (confirm("로그아웃하시겠습니까?")) {
                  signOut();
                }
              }}
            >
              로그아웃
            </Button>
          ) : null}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
