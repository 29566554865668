export const prepaidPassTypeKorean = {
  EARN: "충전",
  SPEND: "사용",
};

export const bookingStatusKorean = {
  CONFIRMED: "확정",
  COMPLETED: "완료",
  CANCELED: "취소",
};

export const consultationStatusKorean = {
  NOT_STARTED: "시작되지 않음",
  IN_PROGRESS: "진행중",
  DONE: "완료",
};
