import { Form } from "react-bootstrap";
import styles from "./index.module.scss";

function MultiSelectionIsPossibleNotice() {
  return (
    <div
      style={{
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "14px",
        marginTop: "10px",
        marginLeft: "30px",
      }}
    >
      복수응답 가능
    </div>
  );
}

export default function QuestionTitle({
  title,
  multiSelection,
  displayQuestionIcon,
}: {
  title: string;
  multiSelection?: boolean;
  displayQuestionIcon?: boolean;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: 5,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {displayQuestionIcon ? (
          <div className={styles.QuestionMark}>Q.</div>
        ) : null}
        <span className={styles.QuestionTitle}>{title}</span>
      </div>
      {multiSelection ? <MultiSelectionIsPossibleNotice /> : null}
    </div>
  );
}
