import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchBookingById } from "../../features/booking";
import RegularCustomerNoteModal from "../Booking/RegularCustomerNoteModal";

export default function SelfRegular() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const [showRegularCustomerNoteModal, setShowRegularCustomerNoteModal] =
    useState(false);

  useEffect(() => {
    if (!id) {
      return;
    }

    initialize(id);
  }, [id]);

  async function initialize(id: string) {
    dispatch(fetchBookingById(id) as any);
    setShowRegularCustomerNoteModal(true);
  }

  return (
    <Container>
      {showRegularCustomerNoteModal && (
        <RegularCustomerNoteModal
          show={showRegularCustomerNoteModal}
          setShow={setShowRegularCustomerNoteModal}
        />
      )}
    </Container>
  );
}
