import { Table } from "react-bootstrap";

export default function Terms() {
  return (
    <div style={{ wordBreak: "keep-all" }}>
      <h5>제1조 (목적)</h5>
      <p>
        본 약관은 주식회사 퓨처뷰티(이하 ‘회사’라 함)가 운영하는 컨설테이션
        솔루션, CRM, ERP 등을 제공하는 인터넷 관련 서비스(이하 “서비스”라 함)를
        이용함에 있어 인터넷 플랫폼과 이용자의 권리, 의무 및 책임사항, 서비스
        이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.
      </p>
      <h5>제2조 (용어의 정의)</h5>
      <h6>① 이 약관에서 사용하는 용어의 정의는 아래와 같습니다.</h6>
      <ol>
        <li>
          “서비스”란 “고객”과 “사업자”에게 회사가 제공하는 플랫폼 및 플랫폼 관련
          아래 각 솔루션 및 기능을 포함한 서비스를 말합니다.
        </li>
        <li>
          “고객”이란 본 약관에 동의하고 “사업자”가 제공하는 용역이나 상품 및
          “회사”가 제공하는 컨설테이션 솔루션을 이용하거나 이용하려는 자를
          말합니다.
        </li>
        <li>
          “사업자”란 회사와 가맹점 계약을 체결하여 본 플랫폼에 접속하여 이
          약관에 따라 회사가 제공하는 서비스를 이용하는 회원을 말합니다.
        </li>
        <li>
          “회원”이란 서비스에 회원가입을 한 “고객”과 “사업자”로서, 이 약관에
          따라 회사와 서비스 이용계약을 체결하고 회사에서 제공하는 서비스를
          이용하는 자를 말합니다.
        </li>
        <li>
          “아이디(ID)”란 회원의 식별과 서비스 이용을 위하여 회원이 설정하고
          회사가 승인하여 회사에 등록된 영문, 숫자, 영문과 숫자의 조합, 또는
          이메일을 말합니다.
        </li>
        <li>
          “비밀번호(Password)”란 회원의 동일성 확인과 회원의 권익 및 비밀 보호를
          위하여 회원 스스로가 설정하여 회사에 등록한 문자 또는 문자와 숫자의
          조합을 말합니다.
        </li>
        <li>
          “게시물”이란 회원이 서비스를 이용함에 있어 플랫폼상에 게시한
          부호∙문자∙음성∙음향∙화상∙동영상 등의 정보 형태의 글, 사진, 동영상 및
          각종 파일과 링크 등을 말합니다.
        </li>
        <li>
          “콘텐츠”란 게시물을 포함하여, 플랫폼상에 게재된 각종 사진, 동영상 등을
          말합니다.
        </li>
      </ol>
      <h6>
        ② 제1항에서 정의되지 않은 용어의 의미는 관련법령 및 일반적인 거래관행을
        따릅니다.
      </h6>
      <h5>제3조 (약관 등의 명시와 설명 및 개정)</h5>
      <ol>
        <li>
          회사는 약관의 내용과 상호 및 대표자의 성명, 영업소 소재지
          주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호,
          전자우편주소, 사업자등록번호, 통신판매업 신고번호 등을 회원이 알 수
          있도록 서비스 초기화면에 게시합니다. 다만, 약관의 구체적 내용은
          이용자가 연결화면을 통하여 볼 수 있도록 합니다.
        </li>
        <li>
          회사는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한
          법률, 전자문서 및 전자거래기본법, 전자금융거래법, 전자서명법,
          정보통신망 이용촉진 및 정보보호 등에 관한 법률, 소비자기본법 등
          관련법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
        </li>
        <li>
          회사는 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
          현행약관과 함께 개정약관의 시행일자 7일 이전부터 적용일자 전일까지
          공지합니다. 다만, 회원의 권리, 의무에 중대한 영향을 주는 변경의
          경우에는 적용일자 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우
          회사는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기
          쉽도록 표시합니다. 기존회원에게는 개정 후 약관을 전자우편주소로
          발송합니다.
        </li>
        <li>
          변경된 약관에 동의하지 않는 회원은 회원 탈퇴(이용계약 해지)를 요청할
          수 있습니다. 회사가 회원에게 “약관 변경 적용일까지 거부의사를 표시하지
          않으면 약관의 변경에 동의한 것으로 간주한다”는 내용을 공지하였음에도
          이용자가 명시적으로 약관변경에 대한 거부의사를 표시하지 않는 경우,
          회사는 이용자가 변경된 약관에 동의한 것으로 간주합니다.
        </li>
        <li>
          회원이 회사와 개별계약을 체결하여 서비스를 이용하는 경우, 회사는 개별
          서비스에 대한 이용약관 및 운영정책 등(이하 ’개별이용약관 등’이라 함)을
          둘 수 있으며, 해당 내용이 본 약관과 상충되는 경우 개별이용약관 등이
          우선적용 됩니다.
        </li>
        <li>
          이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래
          등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률,
          공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및
          관련법령 또는 상관례에 따릅니다.
        </li>
      </ol>
      <h5>제4조 (이용계약의 성립)</h5>
      <ol>
        <li>
          이용계약은 이용자가 이 약관에 동의하면서 제5조에 따른 이용신청을 하고,
          회사가 이를 승낙함으로써 성립합니다. 회사는 승낙의 의사표시를 해당
          서비스 화면에 게시하거나, 이메일 또는 기타 방법으로 통지합니다.
        </li>
        <li>
          이용계약의 성립시기는 회사의 승낙이 이용자에게 도달한 시점으로 하며,
          이는 특별한 사정이 없는 한 플랫폼상의 ‘신청절차’에 가입완료 문구가
          표시된 시점을 의미합니다.
        </li>
      </ol>
      <h5>제5조 (이용신청)</h5>
      <ol>
        <li>
          회원은 회사가 정한 가입 양식에 따라 회원정보를 기재하고 플랫폼상에서
          아이디 및 비밀번호를 설정하여 회원가입을 신청함으로써, 이용신청을
          합니다.
        </li>
        <li>
          회원은 실명이 아닌 이름을 제공하거나 타인의 정보를 도용하는 경우
          서비스 이용이 제한되며, 관련법령에 따라 처벌받을 수 있습니다. 타인의
          명의를 도용하여 이용신청을 한 이용자의 아이디는 삭제됩니다.
        </li>
        <li>
          회원은 이용신청 시 허위 정보를 제공하여서는 아니되며, 허위 정보로 인해
          발생하는 문제에 대하여 회사는 책임을 지지 않습니다.
        </li>
        <li>
          만 14세 미만의 아동은 부모 또는 법정대리인의 동의 없이 회원 가입을
          통한 서비스를 이용할 수 없습니다.
        </li>
      </ol>
      <h5>제6조 (이용신청의 승낙과 제한)</h5>
      <ol>
        <li>
          회사는 제5조에 따른 이용신청을 한 이용자에 대하여 업무 수행상 또는
          기술상 지장이 없는 경우에 원칙적으로 접수 순서에 따라 서비스 이용을
          승낙합니다.
        </li>
        <li>
          회사는 다음 각 호의 어느 하나에 해당하는 경우, 이용신청의 승낙을 하지
          아니하거나, 해당 사유가 해소될 때까지 그 승낙을 유보할 수 있습니다.
          <ol>
            <li>타인의 명의를 이용하여 신청한 경우</li>
            <li>
              신청서의 내용을 허위로 기재한 경우 또는 그러하다고 의심할만한
              합리적인 이유가 있는 경우
            </li>
            <li>
              만 14세 미만의 아동이 법정대리인(부모 등)의 동의를 얻지 아니한
              경우
            </li>
            <li>사회의 안녕과 질서, 미풍양속을 저해할 목적으로 신청한 경우</li>
            <li>부정한 용도로 본 서비스를 이용하고자 하는 경우</li>
            <li>
              음란물을 배포 또는 전시하거나, 타인의 저작권을 침해 또는 침해할
              우려가 있는 경우
            </li>
            <li>기타 회사에서 규정한 제반사항을 위반하며 신청한 경우</li>
            <li>
              서비스 관련 제반 용량이 부족하거나, 기술상 또는 업무상 지장이 있는
              등 회사의 사정으로 승낙이 곤란한 경우
            </li>
          </ol>
        </li>
        <li>
          회사는 이용자가 관련법령에서 규정하는 미성년자일 경우에 서비스별
          안내에서 정하는 바에 따라 승낙을 유보할 수 있습니다.
        </li>
      </ol>
      <h5>제7조 (회원 아이디 및 비밀번호의 관리)</h5>
      <ol>
        <li>
          회원은 서비스 이용과 관련하여 아이디 및 비밀번호를 포함하는 본인의
          계정정보에 대한 관리의 책임이 있습니다. 따라서, 회원이 이를 소홀히하여
          발생하는 서비스 이용상의 손해 또는 제3자에 의한 부정이용 등에 대한
          책임은 회원에게 있으며, 회사는 이에 대하여 어떠한 책임도 부담하지
          않습니다.
        </li>
        <li>
          회사는 회원의 계정정보를 회원 식별 수단으로 하여 당해 회원의 서비스
          이용가능 여부 등 회원 관리와 관련된 제반 업무를 수행합니다.
        </li>
        <li>
          회사는 회원 아이디의 내용이 반사회적이거나, 미풍양속을 해치거나,
          운영자로 오인할 우려가 있는 등의 경우에는 해당 아이디의 사용을
          승낙하지 않거나 제한할 수 있습니다.
        </li>
        <li>
          회사는 보안 등을 이유로 회원에게 정기적 또는 비정기적으로 비밀번호의
          변경을 권고할 수 있습니다.
        </li>
        <li>
          회원은 계정정보 외에도 이용신청 시 기재한 회원정보에 변경이 있는 경우,
          회사 정책에 따라 변경사실을 알려야 하며, 회원정보의 미변경으로 인해
          발생되는 문제의 책임은 회원에게 있습니다.
        </li>
      </ol>
      <h5>제8조 (개인정보의 보호)</h5>
      <ol>
        <li>
          회사는 관련법령이 정하는 바에 따라 회원의 계정정보(아이디, 비밀번호
          등)를 포함한 회원의 개인정보를 보호하기 위하여 노력합니다. 회원
          개인정보의 보호 및 사용에 대해서는 관련법령 및 플랫폼상에 게시된
          회사의 개인정보처리방침에 따릅니다.
        </li>
        <li>
          회사는 회원의 귀책사유로 인해 노출된 회원의 계정정보를 포함한 모든
          개인정보에 대해서 일체의 책임을 지지 않습니다.
        </li>
      </ol>
      <h5>제9조 (서비스의 내용)</h5>
      <ol>
        <li>
          회사가 플랫폼에서 제공하는 서비스의 내용은 다음과 같으며, 이에 관한
          세부 이용규칙은 플랫폼에 게시 또는 게재된 사항에 따릅니다.
          <ol>
            <li>컨설테이션 솔루션</li>
            <li>CRM기능(고객관리프로그램)</li>
            <li>ERP기능(재고관리프로그램)</li>
          </ol>
        </li>
        <li>
          “고객”과 “사업자”는 전문적인 헤어상담과 스타일 추천을 위하여
          컨설테이션 솔루션을 이용할 수 있고, “사업자”는 CRM
          기능(고객관리프로그램)과 ERP 기능(재고관리프로그램)을 이용할 수
          있습니다.
        </li>
        <li>
          회사는 위 서비스의 원할한 진행을 위하여 필수적인 개인정보를 수집할 수
          있고, “고객”이나 “사업자”가 동의를 하지 않는 경우에는 서비스 이용이
          제한됩니다.
        </li>
      </ol>
      <h5>제10조 (서비스의 제공 및 변경)</h5>
      <ol>
        <li>
          회사는 플랫폼상에서 다음과 같은 업무를 수행합니다.
          <ol>
            <li>제9조 제1항의 서비스에 관한 업무</li>
            <li>기타 회사 및 플랫폼이 정하는 업무</li>
          </ol>
        </li>
        <li>
          서비스는 회사가 이용자의 이용신청을 승낙한 때부터 개시되며, 회사의
          업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간
          제공됩니다. 다만, 회사는 제1항의 서비스 제공에 관하여 이 약관 외의
          별도의 규정을 둘 수 있습니다.
        </li>
        <li>
          회사는 서비스 정책 결정을 통해서 서비스의 전부 또는 일부를 변경하거나
          새로운 서비스를 추가하여 제공할 수 있습니다.
        </li>
        <li>
          회원은 플랫폼이 제공하는 서비스를 이 약관, 운영정책 등 플랫폼이 정한
          규칙에 따라 이용할 수 있습니다.
        </li>
        <li>
          회사가 회원에게 제공하는 서비스에 대하여 회사는 제작, 변경, 유지 및
          보수에 관한 포괄적인 권한을 가집니다.
        </li>
        <li>
          회사는 시스템 정기점검 및 긴급점검, 기기∙설비의 증설 및 교체, 각종
          버그 패치, 새로운 서비스로의 교체 등 운영상 필요한 경우 일정 기간 동안
          서비스를 일시 중지시킬 수 있으며, 이러한 서비스의 일시 중단은 플랫폼을
          통해 사전에 공지합니다.
        </li>
        <li>
          회사는 새로운 서비스 내용, 각종 버그 패치 등 서비스의 운영상 또는
          기술상 필요한 경우, 제공하고 있는 서비스의 전부 또는 일부를 상시적으로
          수정, 추가, 폐지 등 변경할 수 있습니다. 변경될 서비스의 내용 및
          제공일자 등에 대해서는 회사가 운영하는 홈페이지 및 해당 서비스 화면에
          게시함으로써 회원에 대한 통지에 갈음합니다. 다만, 회사가 통제할 수
          없는 사유로 인한 서비스의 중단으로 인하여 사전 통지가 불가능한
          경우에는 그러하지 아니합니다.
        </li>
        <li>
          회사는 전시, 사변, 천재지변, 국가비상사태, 정전, 통신장애 등의 경우
          서비스의 전부 또는 일부를 제한하거나 중지할 수 있으며, 이 경우 해당
          사유 및 기간 등을 회원에게 사전 또는 사후에 공지합니다.
        </li>
        <li>
          회사는 무료로 제공되는 서비스의 전부 또는 일부를 회사의 정책 기획이나
          운영상의 사정 또는 회사의 긴박한 상황 등 회사의 필요에 의해 수정,
          중단, 변경할 수 있으며, 이에 대하여 관련 법령상 특별한 규정이 없는 한
          회원에게 별도의 배상을 하지 않습니다.
        </li>
        <li>
          회사는 서비스가 변경되거나 중지된 원인이 회사의 고의 또는 과실로 인한
          경우를 제외하고는 서비스의 변경 및 중지로 발생하는 문제에 대해서
          책임을 부담하지 않습니다.
        </li>
      </ol>
      <h5>제11조 (회원에 대한 통지)</h5>
      <ol>
        <li>
          회사가 회원에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한
          회원이 지정한 이메일, 플랫폼상의 알림 메시지 또는 전자 게시물 등을
          통하여 통지할 수 있습니다.
        </li>
        <li>
          회사는 회원 전체 혹은 이용자에 대한 통지의 경우 7일 이상 플랫폼 또는
          공지사항 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.
        </li>
      </ol>
      <h5>제12조 (정보의 제공 및 광고의 게재)</h5>
      <ol>
        <li>
          회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 플랫폼
          또는 애플리케이션(App)의 공지사항에 게시하거나 이메일 등을 이용하여
          회원에게 제공할 수 있습니다. 다만, 회원은 관련 법령에 따른 거래 관련
          정보, 고객센터 답변 등을 제외한 정보의 전송에 대하여 언제든지 이메일
          등을 통하여 수신거절의 의사표시를 할 수 있습니다.
        </li>
        <li>
          회사는 서비스 등을 유지하기 위하여 서비스 이용 시 노출되는 공간 또는
          타 광고 사업자가 운영하는 매체에 광고를 게재할 수 있으며, 회원은 이로
          인한 광고게재에 대하여 동의합니다.
        </li>
        <li>
          회사가 제공하는, 제3자가 주체인, 제2항의 광고에 회원이 참여하거나 교신
          또는 거래를 함으로써 발생하는 손실과 손해에 대해서 회사는 어떠한
          책임도 부담하지 않습니다.
        </li>
        <li>
          회사는 적법하게 수집한 회원의 개인정보를 활용하여 제2항의 광고 등을
          제공하는 경우 SMS(LMS), 스마트폰 알림(Push 알림), 메일주소 등을
          활용하여 발송할 수 있으며, 회원이 원하지 않는 경우에는 언제든지 이에
          대한 수신을 거부할 수 있습니다.
        </li>
        <li>
          회사는 상기 정보제공과 광고와 관련해서는 정보를 회사에 제공하는
          플랫폼사업자, 앱스토어 사업자의 약관 및 회사의 약관에 준거하며 관련
          법령 및 시행령의 규정을 준수합니다.
        </li>
      </ol>
      <h5>제13조 (저작권 보호)</h5>
      <ol>
        <li>
          서비스를 통해 제공되는 콘텐츠의 저작권은 그 적법한 저작자 및/또는 관련
          권리자(이하 ‘권리자’라 함)에게 귀속됩니다. 회원은 권리자의 허락, 회원
          여부, 서비스의 종류 등에 따라 허용되는 목적과 기간의 범위 내에서만
          사용 허락을 받는 것이며, 회사가 콘텐츠의 저작권 자체를 판매하는 것은
          아닙니다.
        </li>
        <li>
          회원은 회사와 사전 협의없이 서비스를 통해 제공되는 콘텐츠를 무단으로
          복제, 배포, 출판, 방송, 공연, 전시 및 전송해서는 안됩니다.
        </li>
        <li>
          제1항에도 불구하고 회사는 회원이 서비스 내에 게시하는 콘텐츠를
          검색결과, 서비스 소개, 관련 프로모션 등에 노출할 수 있으며, 해당
          노출을 위해 필요한 범위 내에서는 일부 수정, 복제 및 편집하여 게시할 수
          있습니다.
        </li>
        <li>
          회사는 서비스를 통해 제공되는 콘텐츠 및 이에 포함된 피사체(지형∙지물,
          인물, 로고, 캐릭터 등)에 대하여 제3자(이하, 본 서비스의 다른 이용자를
          포함함)의 재산권, 초상권, 저작권, 상표권, 특허권 및 디자인권(이하
          ‘저작권 등’이라 함)을 침해하지 않음을 보증하지 않습니다. 따라서,
          회원은 해당 콘텐츠를 사용함에 있어서 제3자의 저작권 등에 대한 권리의
          침해가 의심되는 경우, 사전에 회사에 문의하여야 합니다.
        </li>
        <li>
          서비스를 통해 제공되는 콘텐츠에 저작권 등을 이유로 문제가 발생하는
          경우, 회사는 회원에게 이를 적절한 방법(플랫폼 공지 또는 이메일
          통지)으로 통지하여야 합니다. 회사가 위의 내용을 통지한 이후에는, 해당
          콘텐츠의 사용으로 인한 모든 법적 책임은 회사가 아닌, 이를 사용한
          회원에게 있습니다.
        </li>
        <li>
          회원이 본조의 규정을 위반하여 서비스를 통해 제공되는 콘텐츠를
          사용함으로써 제3자에게 손해가 발생하는 경우, 회원은 해당 손해를
          배상하여야 하며, 이로 인하여 제3자가 회사에게 손해배상의 청구나 각종
          이의제기를 하는 경우, 회원은 본인의 책임과 비용으로 회사를 면책시켜야
          합니다.
        </li>
      </ol>
      <h5>제14조 (게시물의 관리)</h5>
      <ol>
        <li>
          회원의 게시물이 정보통신망 이용촉진 및 정보보호에 관한 법률, 저작권법,
          개인정보호법, 위치정보의 보호 및 이용 등에 관한 법률 등 관련 법령에
          위반되는 내용을 포함하는 경우, 해당 게시물의 권리자는 관련 법령이 정한
          절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며,
          회사와 회원은 관련 법령에 따른 조치를 취하여야 합니다.
        </li>
        <li>
          회사는 제1항에 따른 권리자의 요청이 없는 경우라도 특정 게시물에
          제3자에 대한 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및
          관련 법령에 위반되는 경우에는 관련 법령에 따라 해당 게시물에 대해
          임시조치 등을 취할 수 있습니다.
        </li>
        <li>
          회사는 국방∙보안상의 이유로 촬영이 금지된 시설 및 정보를 포함하는
          이미지, 허가 없이 촬영된 사진 등의 게시물에 대하여 발견 즉시 해당
          게시물을 비공개로 전환할 수 있으며, 해당 게시물의 게시중단 및 삭제
          등의 조치를 취할 수 있습니다.
        </li>
        <li>
          회사는 회사와의 사전 협의 없이 광고∙홍보용으로 게시된 게시물이나,
          사회미풍양속을 저해하는 용도의 게시물에 대하여 해당 게시물의 게시중단
          및 삭제 등의 조치를 취할 수 있습니다.
        </li>
        <li>
          회사는 서비스를 통해 제공되는 콘텐츠로 인해 관련 법령의 위반, 제3자에
          대한 권리 침해, 사회미풍양속 저해 등의 사유가 발생하지 않도록, 해당
          콘텐츠의 전부 또는 일부에 대하여 해상도를 낮추거나, 모자이크 처리할 수
          있습니다.
        </li>
        <li>
          회사 관리자는 게시물의 추가∙삭제∙편집 및 접근권한 변경 등을 할 수 있는
          전반적인 관리 권한을 가지며, 관리자의 귀책사유로 게시물이 삭제될 경우
          회사는 이에 대하여 어떠한 책임도 지지 않습니다.
        </li>
        <li>
          회사는 서비스를 통해 제공되는 콘텐츠 중 일부를 회사와 제휴한 외부
          콘텐츠 제공자(content provider, CP)로부터 제공받을 수 있습니다.
        </li>
        <li>
          회사가 제작한 콘텐츠 및 제7항의 외부 콘텐츠 제공자로부터 제공받은
          콘텐츠는, 회사의 독자적 판단에 따라 임의로 삭제 및 변경될 수 있습니다.
        </li>
      </ol>
      <h5>제15조 (권리의 귀속 및 양도 금지)</h5>
      <ol>
        <li>
          서비스를 통해 제공되는 회사가 제작한 콘텐츠에 대한 저작권 및 기타
          지적재산권은 회사에 귀속됩니다. 단, 회원의 게시물 및 제14조 제7항에
          따라 제공받은 콘텐츠 등에 대한 저작권 및 기타 지적재산권은 해당
          권리자에게 귀속됩니다.
        </li>
        <li>
          제1항에도 불구하고, 회사는 회원이 본 플랫폼에 게시하는 콘텐츠와 게시물
          및 제14조 제7항에 따라 제공받은 콘텐츠에 대하여, 해당 권리자의 사전
          동의를 얻은 것으로 간주하여 본 서비스 목적을 위해 사용할 수 있습니다.
        </li>
        <li>
          회사는 회사가 정한 이용조건에 따라 서비스와 관련하여 회원에게
          계정(아이디, 비밀번호 등), 게시물 등을 이용할 수 있는 이용권만을
          부여하며, “회원”은 위 이용권을 타인에게 양도, 판매, 증여, 담보
          제공하는 등의 일체의 처분행위를 할 수 없습니다.
        </li>
      </ol>
      <h5>제16조 (회사의 의무)</h5>
      <ol>
        <li>
          회사는 이 약관 및 관련법령에서 금지하는 행위 및 미풍양속에 반하는
          행위를 하지 않으며, 지속적이고 안정적으로 서비스를 제공하기 위하여
          노력할 의무가 있습니다.
        </li>
        <li>
          회사는 이용자의 개인 신상 정보를 본인의 승낙 없이 제3자에게 누설,
          배포하지 않습니다. 다만, 관계법령에 의해 국가기관의 요구나 법률의
          규정에 따른 적법한 절차에 의한 경우에는 그러하지 아니합니다.
        </li>
        <li>
          회사는 계속적이고 안정적인 서비스의 제공을 위하여 설비에 장애가
          생기거나 멸실되거나 네트워크 장애 등의 문제가 발생한 때에는 부득이한
          사유가 없는 한 지체없이 이를 수리 또는 복구합니다.
        </li>
        <li>
          회사는 회원이 사용하는 계정 등을 통한 바이러스 프로그램 또는 외부의
          불법적인 침입으로부터 고객의 정보 및 시스템을 보호하기 위하여
          노력합니다.
        </li>
        <li>
          회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 인정할
          경우에는 신속히 처리하여야 합니다. 다만, 신속한 처리가 곤란한 경우에는
          회원에게 그 사유와 처리일정을 통보하여야 합니다.
        </li>
        <li>
          회사는 관련법령이 정하는 바에 따라서 회원 등록정보를 포함한 회원의
          개인정보를 보호하기 위하여 노력합니다. 회원의 개인정보보호에 관해서는
          관련법령을 준수합니다.
        </li>
      </ol>
      <h5>제17조 (회원의 의무)</h5>
      <ol>
        <li>
          회원은 회사에서 제공하는 서비스를 본래의 이용 목적 이외의 용도로
          사용하거나 다음 각 호에 해당하는 행위를 해서는 안됩니다.
          <ol>
            <li>
              가입신청 또는 정보 변경을 목적으로 회사에 개인정보 등록시 다른
              사람의 정보를 사용하거나 허위 사실을 기재하는 행위
            </li>
            <li>
              타인으로 가장하거나 타인과의 관계를 허위로 명시하는 행위, 다른
              회원의 계정 및 비밀번호를 도용, 부정하게 사용하거나, 타인의
              신용카드, 전화번호 등을 무단으로 도용하여 서비스 등을 이용하는
              행위
            </li>
            <li>
              회사의 서비스 및 제공된 플랫폼을 이용하여 얻은 정보를 회사의 사전
              승낙없이 복제, 유통, 조장하거나 상업적으로 이용하는 행위
            </li>
            <li>
              회사의 서비스 및 제공된 플랫폼을 이용하여 자기 또는 타인에게
              재산상의 이익을 발생시키는 행위
            </li>
            <li>
              서비스를 변경하거나 이에 의거한 2차적 저작물 내지 결과물을
              생성하는 행위
            </li>
            <li>버그를 악용하여 서비스를 이용하는 행위</li>
            <li>
              회사 및 제3자의 명예를 훼손하거나 업무를 방해하는 행위 또는 회사
              및 제3자에게 손해를 가하는 행위
            </li>
            <li>회사 또는 제3자의 지적재산권을 침해하는 행위</li>
            <li>
              제3자를 기망하여 이득을 취하거나 회사가 제공하는 서비스를
              불건전하게 이용하여 제3자에게 피해를 주는 행위
            </li>
            <li>
              음란, 저속한 정보를 교류, 게재하거나 그에 관한 연결(링크)하거나
              사회통념상 타인에게 불쾌감을 줄 수 있는 내용을 담은 광고 및
              홍보물을 게재하는 행위
            </li>
            <li>도박이나 사해행위를 유도하거나 참여하는 행위</li>
            <li>
              수치심이나 혐오감 또는 공포심을 일으키는 말이나 음향, 글, 화상
              또는 영상을 게재하거나 상대방에게 전송, 도달, 유포하는 행위
            </li>
            <li>
              관련법령에 의하여 전송 또는 게시가 금지되는 정보 또는 컴퓨터
              소프트웨어, 하드웨어, 전기통신장비의 정상적인 가동을 방해하는
              소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을
              포함하고 있는 자료를 전송, 게시, 유포, 사용하는 행위
            </li>
            <li>
              회사로부터 특별한 권리를 부여받지 않고 플랫폼을 변경하거나
              플랫폼에 다른 프로그램을 추가 또는 삽입하거나 서버를 해킹, 역설계,
              소스코드나 플랫폼 데이터의 유출 및 변경, 별도의 서버를 구축하거나
              플랫폼의 일부분을 임의로 변경 또는 도용하여 회사를 사칭하는 행위
            </li>
            <li>서비스와 플랫폼을 기타 영리 목적으로 부당하게 이용하는 행위</li>
            <li>
              기타 공공질서 및 미풍양속을 위반하거나 불법적, 부당한 행위 및
              법령에 위배되는 행위
            </li>
          </ol>
        </li>
        <li>
          회원은 회사와의 정당한 계약 또는 허락 없이 서비스를 재판매할 수 없고,
          이를 이용하여 영업활동을 할 수 없으며, 이러한 영업활동의 결과(기타
          회원이 약관에 위반한 영업활동을 한 결과 포함)에 대하여 회사는 어떠한
          책임도 부담하지 않습니다. 회원은 이러한 영업활동에 대하여 회사에게
          손해를 배상할 의무가 있습니다.
        </li>
        <li>
          회원은 회사 홈페이지 상의 공지사항 및 이용약관의 수정사항 등을
          확인하고 이를 준수할 의무가 있으며 기타 회사의 업무에 방해되는 행위를
          하여서는 안 됩니다.
        </li>
        <li>
          회원의 계정에 관한 관리 책임은 회원에게 있으며, 이를 제3자가
          이용하도록 하여서는 안 됩니다.
        </li>
        <li>
          회원은 회원정보에 변경이 발생하는 경우, 즉시 그 내용을 회사에
          통보하여야 하며, 통보 지연으로 발생한 문제에 대하여 회사는 어떠한
          책임도 부담하지 않습니다.
        </li>
        <li>
          회사는 제1항 내지 제5항 및 다음 각 호의 어느 하나에 해당하는 행위의
          구체적인 유형을 회사의 운영정책에서 정할 수 있으며, 회원은 이를 준수할
          의무가 있습니다.
          <ol>
            <li>회원의 ID, 비밀번호의 정함에 대한 제한</li>
            <li>콘텐츠 등록 및 게시에 대한 제한</li>
            <li>서비스 이용 등에 대한 제한</li>
            <li>
              기타 회원의 서비스 이용에 대한 본질적인 권리를 침해하지 않는 범위
              내에서 회사가 운영상 필요하다고 인정되는 사항
            </li>
          </ol>
        </li>
      </ol>
      <h5>제18조 (회원탈퇴 및 계약해지)</h5>
      <ol>
        <li>
          회원은 언제든지 회원 탈퇴 신청을 할 수 있으며, 회사는 회원탈퇴에 관한
          규정에 따라 이를 처리합니다.
        </li>
        <li>
          회원이 서비스 이용계약을 해지할 경우, 관련법령 및 회사의
          개인정보보호정책에서 정한 바에 따라 회사가 회원정보를 보유하는 경우를
          제외하고는, 해지 즉시 회원의 정보는 소멸됩니다. 다만, 회원이 서비스
          이용 중 게시한 콘텐츠나 다른 회원에게 제공(또는 공유)하거나 콘텐츠의
          경우 다른 회원이 보유하는 콘텐츠는 소멸되지 않습니다.
        </li>
      </ol>
      <h5>제19조 (이용제한 등)</h5>
      <ol>
        <li>
          회사는 회원이 이 약관의 의무 또는 관련법령을 위반하거나 서비스의
          정상적인 운영을 방해한 경우, 서비스 이용을 ‘경고’, ‘일시정지’ 및
          ‘계약해지’로 단계적으로 제한할 수 있습니다.
        </li>
        <li>
          회사는 제1항의 규정에도 불구하고, 명의도용 및 저작권법을 위반한
          불법프로그램의 제공 및 운영방해, 정보통신망 이용촉진 및 정보보호 등에
          관한 법률을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한
          초과행위 등과 같이 관련법령을 위반한 경우에는 즉시 계약을 해지할 수
          있습니다. 이에 따른 계약해지시 회원이 서비스 이용을 통해 획득한 혜택
          등은 모두 소멸되며, 회사는 이에 대해 별도로 보상하지 않습니다.
        </li>
        <li>
          회사가 제1항에 따라 회원의 서비스 이용을 제한하거나 계약을 해지하는
          경우, 제한의 조건 및 세부내용은 플랫폼상에 게시된 이용제한정책 등에서
          정한 바에 따르며, 회사는 회원에게 본 약관에서 정한 방법으로
          통지합니다.
        </li>
        <li>
          회원은 회사의 이용제한 조치 등에 대하여 회사가 정한 절차에 따라
          이의신청을 할 수 있습니다. 회원의 이의 사유가 정당하다고 인정되는 경우
          회사는 즉시 회원의 서비스 이용을 재개하여야 합니다.
        </li>
      </ol>
      <h5>제20조 (손해배상)</h5>
      <ol>
        <li>
          회사는 회사의 고의 또는 과실 없이 회원에게 발생한 손해에 대하여는
          책임을 지지 아니합니다. 회사는 해당 회원에게 손해를 배상하는 경우, 그
          방식과 절차 등에 관해 관련 규정 및 기타 상관례를 따릅니다.
        </li>
        <li>
          회원이 이 약관의 의무를 위반함으로 인하여 회사에 손해를 입힌 경우 또는
          회원이 서비스의 이용과 관련하여 회사에 손해를 입힌 경우 회원은 회사에
          대하여 당해 손해를 배상하여야 합니다.
        </li>
        <li>
          회원이 서비스를 이용함에 있어 행한 불법행위 또는 본 약관을 위반한
          행위로 회사가 당해 회원 외의 제3자로부터 손해배상청구 또는 소송 등
          각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 회사를
          면책시켜야 하며, 회사가 면책되지 못한 경우 당해 회원은 그로 인하여
          회사에 발생한 모든 손해를 배상할 책임이 있습니다.
        </li>
      </ol>
      <h5>제21조 (책임의 한계)</h5>
      <ol>
        <li>
          회사는 전시, 사변, 천재지변, 국가비상사태 또는 이에 준하는
          불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에
          관한 책임이 면제됩니다.
        </li>
        <li>
          회사는 회원의 귀책사유로 인한 이용 불가 또는 이용 장애에 대하여는
          책임을 지지 아니하며, 기간통신사업자가 전기통신서비스를 중지하거나
          정상적으로 제공하지 아니하여 회원에게 손해가 발생한 경우에는 책임이
          면제됩니다.
        </li>
        <li>
          회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로
          발생한 손해에 대한 책임이 면제됩니다.
        </li>
        <li>
          회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도,
          정확성 등의 내용에 관하여는 책임을 지지 않습니다.
        </li>
        <li>
          회사는 회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래
          등을 한 경우에는 책임이 면제됩니다.
        </li>
      </ol>
      <h5>제22조 (준거법 및 재판관할)</h5>
      <ol>
        <li>
          이 약관의 해석 및 회원 간의 분쟁에 대하여는 대한민국의 법령을
          준거법으로 합니다.
        </li>
        <li>
          회사와 회원 간 발생한 분쟁에 관한 소송은 민사소송법이 정하는
          관할법원에서 합니다.
        </li>
      </ol>
      <h5>부칙</h5>
      <p>이 약관은 2022년 11월 1일부터 적용합니다.</p>
    </div>
  );
}
