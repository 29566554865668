import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import APIGateway from "../../../../api/api-gateway";
import { useAppSelector } from "../../../../app/hooks";
import {
  emptyBooking,
  fetchBookingById,
  refreshBookings,
  selectBooking,
} from "../../../../features/booking";
import { BookingStatus } from "../../../../models";
import BookingService from "../../../../services/booking";
import BookingStatusBadge from "../../StatusBadge";

export default function BookingStatusRow() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const booking = useAppSelector(selectBooking);

  const apiGateway = new APIGateway();

  const bookingService = new BookingService(apiGateway.booking);

  async function onCancelClick() {
    if (!booking) {
      return;
    }

    if (!confirm("이 예약을 취소할까요?")) {
      return;
    }

    const result = await bookingService.cancelBooking(booking.id);

    if (result) {
      enqueueSnackbar("예약을 취소했어요.", { variant: "success" });

      dispatch(emptyBooking() as any);
      dispatch(refreshBookings() as any);
    }
  }

  async function onCompleteClick() {
    if (!booking) {
      return;
    }

    if (!confirm("이 예약을 완료할까요?")) {
      return;
    }

    const result = await bookingService.completeBooking(booking.id);

    if (result) {
      enqueueSnackbar("예약을 완료했어요.", { variant: "success" });

      dispatch(fetchBookingById(booking.id) as any);
      dispatch(refreshBookings() as any);
    }
  }

  if (!booking) return <></>;

  return (
    <tr>
      <th>예약 상태</th>
      <td>
        <BookingStatusBadge bookingStatus={booking.bookingStatus} />
      </td>
      <th>예약 변경</th>
      <td>
        {booking.bookingStatus === BookingStatus.CONFIRMED && (
          <>
            <Button
              variant="contained"
              size="small"
              onClick={onCompleteClick}
              sx={{ minWidth: 50 }}
            >
              완료
            </Button>
            <Button
              size="small"
              color="warning"
              onClick={onCancelClick}
              sx={{ ml: 1, minWidth: 30 }}
            >
              취소
            </Button>
          </>
        )}
      </td>
    </tr>
  );
}
