import { CognitoUserAmplify } from "@aws-amplify/ui";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Badge, Button, Col, Container, Form, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import BackendAPI from "../../api/backend-api";
import ShopUserApplicationAPI from "../../api/shop-user-application";
import { useAppSelector } from "../../app/hooks";
import { fetchShops, selectShops } from "../../features/shop";
import { Shop } from "../../graphql/API";

export default function SubmitShopUserApplication() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState<CognitoUserAmplify>();

  const shops = useAppSelector(selectShops);
  const [selectedShops, setSelectedShops] = useState<Shop[]>([]);
  const [name, setName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  useEffect(() => {
    fetchUser();

    dispatch(fetchShops() as any);
  }, []);

  useEffect(() => {
    // TODO: refresh application status
  }, [selectedShops]);

  async function fetchUser() {
    const user = await Auth.currentAuthenticatedUser();

    setUser(user);
  }

  async function onJoiningShopClick() {
    const shop = selectedShops[0];

    if (!shop) {
      return;
    }

    if (!user?.attributes) {
      return;
    }

    if (!name) {
      toast.warn("이름을 입력해주세요.");
      return;
    }

    if (!phoneNumber) {
      toast.warn("전화번호를 입력해주세요.");
      return;
    }

    const userId = user.attributes["sub"];

    const shopUserApplicationAPI = new ShopUserApplicationAPI();

    const existingApplication =
      await shopUserApplicationAPI.listByShopIdWithUserId(shop.id, userId);

    if (existingApplication) {
      toast.warn(`이미 ${shop.name}에 가입 신청을 하셨습니다.`);

      navigate("/waiting-for-approval");

      return;
    }

    const newApplication = await shopUserApplicationAPI.create({
      shopId: shop.id,
      userId,
      name: name.trim(),
      phoneNumber: phoneNumber.replace("-", "").trim(),
      email: user.attributes.email,
      completed: false,
    });

    await new BackendAPI()
      .newShopUserApplication(newApplication.id)
      .catch((error) => {
        console.log(error);
      });

    toast.success("가입 신청이 완료되었습니다.");

    navigate("/waiting-for-approval");
  }

  return (
    <Container style={{ paddingLeft: 30, paddingRight: 30, marginTop: 80 }}>
      <Row>
        <Col>
          <h2 className="question-title">헤어 디자이너 가입</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>현재 매장에서 나나로그를 사용하고 있습니다.</p>
          <div>
            <Typeahead
              id="typeahead-shop-finder"
              labelKey={"name"}
              selected={selectedShops}
              options={shops}
              onChange={(shops) => {
                setSelectedShops(shops as any);
              }}
              placeholder="매장 이름으로 검색하세요."
            />
          </div>
          <div style={{ paddingTop: 20 }}>
            {selectedShops.length > 0 ? (
              <>
                <Badge>{selectedShops[0].name}</Badge>(으)로 가입
                신청하시겠습니까?
                <div style={{ paddingTop: 20 }}>
                  <div style={{ marginBottom: 20 }}>
                    <Form.Control
                      placeholder="디자이너 이름"
                      value={name}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: 20 }}>
                    <Form.Control
                      placeholder="디자이너 전화번호"
                      value={phoneNumber}
                      onChange={(event) => {
                        setPhoneNumber(event.target.value);
                      }}
                    />
                  </div>
                  <div className="text-center d-grid">
                    <Button onClick={onJoiningShopClick}>가입 신청</Button>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <ToastContainer position="top-center" />
        </Col>
      </Row>
    </Container>
  );
}
