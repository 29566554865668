import { toast } from "react-toastify";
import BackendAPI from "../../api/backend-api";
import ConsultationAPI from "../../api/consultation";
import GA from "../../api/ga";
import {
  Consultation,
  ConsultationStatus,
  UpdateConsultationInput,
} from "../../graphql/API";

export default class ConsultationService {
  constructor(
    private consultationAPI: ConsultationAPI,
    private backendAPI: BackendAPI
  ) {}

  async sendSolution(consultation: Consultation, force = false) {
    await this.backendAPI.sendConsultationSolution(consultation.id, force);

    toast.success(
      `${
        consultation?.customer?.name || ""
      } 고객님의 컨설테이션 결과지를 발송했습니다.`
    );

    GA.event("컨설테이션", "결과지 전송", consultation.shopName ?? "");
  }

  async update(consultation: Consultation, input: UpdateConsultationInput) {
    const {
      mode,
      gender,
      productsForShampooing,
      dry,
      outfitStyles,
      concerns,
      productsForStyling,
      treatmentsInterestedIn,
      frequencyOfVisits,
      neckLength,
      neckThickness,
      shoulderWidth,
      shoulderShape,
      virginHairColor,
      hairColor,
      colorfulnessesOfHair,
      recentDyeing,
      recentFirm,
      damageDegreeOfHair,
      amountOfHair,
      thicknessOfHair,
      curlDegreeOfHair,
      faceShapes,
      frontHeadShape,
      sideHeadShape,
      solution,
      hairRecommendation,
    } = input;

    const updateInput: UpdateConsultationInput = {
      id: consultation.id,
      _version: consultation._version,
      mode,
      gender,
      productsForShampooing,
      dry,
      outfitStyles,
      concerns,
      productsForStyling,
      treatmentsInterestedIn,
      frequencyOfVisits,
      neckLength,
      neckThickness,
      shoulderWidth,
      shoulderShape,
      virginHairColor,
      hairColor,
      colorfulnessesOfHair,
      recentDyeing,
      recentFirm,
      damageDegreeOfHair,
      amountOfHair,
      thicknessOfHair,
      curlDegreeOfHair,
      faceShapes,
      frontHeadShape,
      sideHeadShape,
      solution,
      hairRecommendation,
    };

    if (consultation.status === ConsultationStatus.NOT_STARTED) {
      updateInput.status = ConsultationStatus.IN_PROGRESS;
    }

    await this.consultationAPI.update(updateInput);

    GA.event("컨설테이션", "컨설테이션 입력", consultation.shopName ?? "");
  }
}
