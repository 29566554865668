import { CognitoUserAmplify } from "@aws-amplify/ui";
import { Box, Button, FormControlLabel, Switch } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import APIGateway from "../../../api/api-gateway";
import { useAppSelector } from "../../../app/hooks";
import {
  selectCurrentShop,
  selectCurrentShopId,
  selectUserGroups,
  setCurrentShop as setCurrentShopLegacy,
  setCurrentShopId,
} from "../../../features/common";
import {
  selectShops,
  setCurrentShop,
  updateShop,
} from "../../../features/shop";
import { Shop } from "../../../graphql/API";
import Navigation from "../../Navigation";
import styles from "./index.module.scss";

export default function AppBar({
  user,
  signOut,
}: {
  user: CognitoUserAmplify;
  signOut: () => void;
}) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const currentShopId = useAppSelector(selectCurrentShopId);
  const shops = useAppSelector(selectShops);
  const currentShop = useAppSelector(selectCurrentShop);
  const groups = useAppSelector(selectUserGroups);
  const apiGateway = new APIGateway();

  const [shopsToShow, setShopsToShow] = useState<Shop[]>([]);
  const [showShopSelectionModal, setShowShopSelectionModal] = useState(false);
  const [showNavigation, setShowNavigation] = useState(false);

  const isAdmin = (groups ?? []).includes("Admin");
  const isOwner = (groups ?? []).includes("Owner");
  const userAccountShopId = (user.attributes ?? { "custom:shop_id": "" })[
    "custom:shop_id"
  ];

  useEffect(() => {
    const shopsToShow = isAdmin
      ? shops
      : shops.filter((item) => item.id === userAccountShopId);

    setShopsToShow(shopsToShow);
  }, [shops, user]);

  useEffect(() => {
    if (shopsToShow.length === 0) {
      return;
    }

    if (shopsToShow.length === 1) {
      onCurrentShopIdChange(shopsToShow[0].id);
    } else {
      if (!currentShopId && shopsToShow.length > 0) {
        initializeCurrentShopId(shopsToShow);
      }
    }
  }, [shopsToShow]);

  function initializeCurrentShopId(shops: Shop[]) {
    const storedCurrentShopId = localStorage.getItem("currentShopId");

    if (storedCurrentShopId) {
      onCurrentShopIdChange(storedCurrentShopId);
    } else if (shops.length > 0) {
      onCurrentShopIdChange(shops[0].id);
    }
  }

  function onCurrentShopIdChange(shopId: string) {
    dispatch(setCurrentShopId(shopId));

    localStorage.setItem("currentShopId", shopId);

    if (shopsToShow.length > 0) {
      const shop = shopsToShow.find((item) => item.id === shopId);

      if (shop) {
        localStorage.setItem("currentShop", JSON.stringify(shop));

        dispatch(setCurrentShopLegacy(shop));
        dispatch(setCurrentShop(shop));
      }
    }
  }

  const isProduction = process.env.REACT_APP_STAGE === "prod";

  return (
    <>
      {!isProduction && (
        <div
          style={{
            textAlign: "center",
            marginBottom: 5,
            background: "#e5dfd9",
            color: "#2b2b2b",
          }}
        >
          테스트 사이트
        </div>
      )}
      <Container className={styles.AppBar}>
        <Row>
          <Col xs={3}>
            <a href="/">
              <img
                src="/images/icons/device-app-icon.png"
                alt="나나로그 로고"
              />
            </a>
          </Col>
          <Col
            xs={6}
            className={styles.Title}
            onClick={() => {
              setShowShopSelectionModal(true);
            }}
          >
            {currentShop?.name}
          </Col>
          <Col xs={3} className="text-end align-middle">
            <Button
              sx={{ padding: "5px" }}
              variant="outlined"
              onClick={() => {
                setShowNavigation(true);
              }}
            >
              MENU
            </Button>
          </Col>
        </Row>
        {isOwner && currentShop?.id === userAccountShopId && (
          <Box sx={{ textAlign: "center" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={!!currentShop?.usePreSelfDiagnosis}
                  onChange={(event) => {
                    const usePreSelfDiagnosis = event.target.checked;

                    if (!currentShop) {
                      return;
                    }

                    dispatch(
                      updateShop({
                        id: currentShop.id,
                        usePreSelfDiagnosis,
                        _version: currentShop._version,
                      }) as any
                    ).then(({ payload }: { payload: Shop }) => {
                      if (payload.usePreSelfDiagnosis) {
                        enqueueSnackbar(
                          "셀프 진단을 활성화했어요. 예약 고객에게 셀프 진단 알림톡을 발송합니다.",
                          {
                            variant: "success",
                          }
                        );
                      } else {
                        enqueueSnackbar("셀프 진단을 비활성화했어요.", {
                          variant: "info",
                        });
                      }
                    });
                  }}
                />
              }
              label="셀프 진단"
            ></FormControlLabel>{" "}
            <FormControlLabel
              control={
                <Switch
                  checked={!!currentShop?.useFeedbackRequest}
                  onChange={(event) => {
                    const useFeedbackRequest = event.target.checked;

                    if (!currentShop) {
                      return;
                    }

                    dispatch(
                      updateShop({
                        id: currentShop.id,
                        useFeedbackRequest,
                        _version: currentShop._version,
                      }) as any
                    ).then(({ payload }: { payload: Shop }) => {
                      if (payload.useFeedbackRequest) {
                        enqueueSnackbar(
                          "피드백 요청을 활성화했어요. 시술 완료 고객에게 피드백 요청 알림톡을 발송합니다.",
                          {
                            variant: "success",
                          }
                        );
                      } else {
                        enqueueSnackbar("피드백 요청을 비활성화했어요.", {
                          variant: "info",
                        });
                      }
                    });
                  }}
                />
              }
              label="피드백 요청"
            ></FormControlLabel>
          </Box>
        )}
      </Container>

      <Navigation
        user={user}
        signOut={signOut}
        showNavigation={showNavigation}
        setShowNavigation={setShowNavigation}
      />
      <Modal
        show={showShopSelectionModal}
        onHide={() => {
          setShowShopSelectionModal(false);
        }}
        size="sm"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>매장 선택</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {shopsToShow.map((shop) => (
            <div key={shop.id} className="d-grid" style={{ marginBottom: 5 }}>
              <Button
                size="small"
                variant={shop.id === currentShopId ? "contained" : "outlined"}
                onClick={() => {
                  onCurrentShopIdChange(shop.id);
                  setShowShopSelectionModal(false);
                }}
              >
                {shop.name}
              </Button>
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
}
