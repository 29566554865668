import { Storage } from "aws-amplify";
import { selectCurrentShop, selectUserGroups } from "../../features/common";
import { useAppSelector } from "../../app/hooks";
import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Container, Form } from "react-bootstrap";
import { CognitoUserAmplify } from "@aws-amplify/ui";
import { toast } from "react-toastify";
import Avatar from "@mui/material/Avatar";
import BackendAPI from "../../api/backend-api";
import DesignerApi from "../../api/designer";
import { UpdateDesignerInput } from "../../graphql/API";
import { Box, Button, Chip, Grid, Stack } from "@mui/material";
import { v4 as uuid } from "uuid";
import styles from "./index.module.scss";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";

export default function Settings() {
  const { enqueueSnackbar } = useSnackbar();
  const groups = useAppSelector(selectUserGroups);
  const currentShop = useAppSelector(selectCurrentShop);
  const [user, setUser] = useState<CognitoUserAmplify>();
  const [input, setInput] = useState({ designerName: "", bizItemId: "" });
  const [designerInput, setDesignerInput] = useState<UpdateDesignerInput>();
  const [profileImageUrl, setProfileImageUrl] = useState<string>();

  const backendAPI = new BackendAPI();
  const designerAPI = new DesignerApi();

  const isOwner = groups.find((group) => group === "Owner");

  useEffect(() => {
    initialize();
  }, []);

  async function initialize() {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });

    setUser(user);

    setInput({
      designerName: user.attributes["custom:designer_name"] ?? "",
      bizItemId: user.attributes["custom:biz_item_id"] ?? "",
    });

    const designerId = user.attributes["custom:designer_id"];

    if (!designerId) {
      await backendAPI.syncDesignerRecord(user.attributes.sub);

      initialize();
    } else {
      const designer = await designerAPI.get(designerId);

      if (designer) {
        setDesignerInput({
          id: designer.id,
          phoneNumber: designer.phoneNumber,
          instagramUrl: designer.instagramUrl,
          youtubeUrl: designer.youtubeUrl,
          _version: designer._version,
        });

        if (designer.s3Key) {
          const url = await Storage.get(designer.s3Key);

          setProfileImageUrl(url);
        } else if (designer.imageUrl) {
          setProfileImageUrl(designer.imageUrl);
        }
      }
    }
  }

  async function onSaveClick() {
    if (!user) {
      return;
    }

    try {
      await Auth.updateUserAttributes(user, {
        "custom:designer_name": input.designerName,
        "custom:biz_item_id": input.bizItemId,
      });
    } catch (error: any) {
      if (error?.message) {
        toast.error(error?.message);
      } else {
        toast.error("Failed to update user attributes");
      }
      return;
    }

    if (user.attributes?.sub) {
      await backendAPI.syncDesignerRecord(user.attributes.sub);
    }

    enqueueSnackbar("저장했어요.", { variant: "success" });
  }

  function isValidHttpUrl(string: string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  async function onSaveExtraClick() {
    if (!user || !designerInput) {
      return;
    }

    if (
      designerInput.instagramUrl &&
      !isValidHttpUrl(designerInput.instagramUrl)
    ) {
      toast.error("Instagram URL이 올바르지 않습니다.");
      return;
    }

    if (designerInput.youtubeUrl && !isValidHttpUrl(designerInput.youtubeUrl)) {
      toast.error("YouTube URL이 올바르지 않습니다.");
      return;
    }

    try {
      await designerAPI.update(designerInput!);
    } catch (error: any) {
      if (error?.message) {
        toast.error(error?.message);
      } else {
        toast.error("디자이너 부가 정보를 저장하는 중 오류가 발생했습니다.");
      }
      return;
    }

    enqueueSnackbar("저장했어요.", { variant: "success" });
  }

  async function uploadFile(file: any) {
    try {
      const fileNameSlices = file.name.split(".");

      const fileNameExtension = fileNameSlices[fileNameSlices.length - 1];

      const fileName =
        dayjs().format("YYYYMMDD") + "/" + uuid() + "." + fileNameExtension;

      const result = await Storage.put(fileName, file);

      const s3Key = result.key;

      await designerAPI.update({
        id: designerInput?.id!,
        s3Key,
        _version: designerInput?._version!,
      });

      initialize();
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  async function onFileInputChange(event: any) {
    const targetLength = event?.target?.files.length ?? 0;

    try {
      let files = [];
      for (var i = 0; i < targetLength; i++) {
        files.push(event.target.files.item(i));
        break;
      }
      await Promise.all(files.map((f, index) => uploadFile(f)));
    } catch (error: any) {
      toast.warn(error.message);
    } finally {
    }
  }

  const attributes = user?.attributes ?? ({} as any);
  const designerName = attributes["custom:designer_name"];

  return (
    <Container>
      <div className={styles.ProfileHeading}>
        <Stack alignItems="center">
          <Avatar
            alt={designerName}
            src={profileImageUrl}
            onClick={(e) => {
              e.preventDefault();
              document.getElementById("add-image-file-input")?.click();
            }}
          />
        </Stack>
        <h6>{designerName ?? attributes.email ?? ""}</h6>
        <small>{attributes.email}</small>
        <div className={styles.BadgeArea}>
          {isOwner && (
            <Chip
              color="secondary"
              label="점주"
              variant="outlined"
              size="small"
              style={{ marginRight: 5 }}
            />
          )}
          {groups.find((group) => group === "Designer") && (
            <Chip
              color="success"
              variant="outlined"
              label="디자이너"
              size="small"
            />
          )}
        </div>
      </div>
      <input
        id="add-image-file-input"
        type="file"
        accept="image/*"
        multiple={false}
        onChange={onFileInputChange}
        style={{ display: "none" }}
      />

      <hr />
      <h6>매장 정보</h6>
      <Grid container>
        <Grid item xs={8}>
          <Chip
            color="success"
            variant={currentShop?.usePreSelfDiagnosis ? "filled" : "outlined"}
            label={`셀프 진단 
          ${currentShop?.usePreSelfDiagnosis ? "사용 중" : "비활성"}`}
            size="small"
          />{" "}
          <Chip
            color="success"
            variant={currentShop?.useFeedbackRequest ? "filled" : "outlined"}
            label={`피드백 요청
          ${currentShop?.useFeedbackRequest ? "사용 중" : "비활성"}`}
            size="small"
          />
        </Grid>
        <Grid item xs={4} sx={{ textAlign: "right" }}>
          {isOwner && (
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                backendAPI.openOwnerWebsite();
              }}
            >
              매장 설정
            </Button>
          )}
        </Grid>
      </Grid>
      <hr />
      <h6>디자이너 기본 정보</h6>
      <Form>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Form.Group className="mb-3">
              <Form.Label>디자이너 이름</Form.Label>
              <Form.Control
                type="text"
                autoComplete="off"
                value={input.designerName}
                onChange={(event) => {
                  setInput({ ...input, designerName: event.target.value });
                }}
                maxLength={12}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </Grid>
          <Grid item xs={6}>
            <Form.Group className="mb-3">
              <Form.Label>네이버 예약상품 아이디</Form.Label>
              <Form.Control
                type="text"
                autoComplete="off"
                value={input.bizItemId}
                onChange={(event) => {
                  setInput({ ...input, bizItemId: event.target.value });
                }}
                maxLength={7}
              />
            </Form.Group>
          </Grid>
        </Grid>
        <Form.Text className="text-muted">
          - 네이버 예약상품 아이디는 네이버 예약에서{" "}
          <i>
            https://partner.booking.naver.com/bizes/[6자리
            숫자]/biz-items/[7자리 숫자]/detail
          </i>{" "}
          경로에 있는 7자리 숫자를 뜻합니다. 네이버 SmartPlace [예약] -
          [예약상품] 화면에서 주소창을 보면 알 수 있어요.
          <br />- 고객에게 바로 다음 예약을 진행할 수 있는 빠른 링크를 제공할
          때와 모두에게 공개된 예약상품 목록을 수집할 때만 사용합니다.
        </Form.Text>
      </Form>
      <Box sx={{ textAlign: "center", marginTop: 1 }}>
        <Button variant="contained" onClick={onSaveClick}>
          기본 정보 저장
        </Button>
      </Box>
      {designerInput && (
        <>
          <hr />
          <h6>디자이너 부가 정보</h6>
          <p>만족도 피드백 수집 후 고객 화면에 홍보해 드려요.</p>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>알림톡 수신 전화번호</Form.Label>
              <Form.Control
                type="text"
                autoComplete="off"
                value={designerInput?.phoneNumber ?? ""}
                onChange={(event) => {
                  setDesignerInput({
                    ...designerInput,
                    phoneNumber: event.target.value ?? "",
                  });
                }}
                maxLength={12}
              />
              <Form.Text className="text-muted">
                `-` 없이 숫자만 입력해 주세요.
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>인스타그램 URL</Form.Label>
              <Form.Control
                type="text"
                autoComplete="off"
                value={designerInput?.instagramUrl ?? ""}
                onChange={(event) => {
                  setDesignerInput({
                    ...designerInput,
                    instagramUrl: event.target.value ?? "",
                  });
                }}
                maxLength={128}
              />
              <Form.Text className="text-muted">
                `https://`부터 전체 URL을 입력해주세요.
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>유튜브 URL</Form.Label>
              <Form.Control
                type="text"
                autoComplete="off"
                value={designerInput?.youtubeUrl ?? ""}
                onChange={(event) => {
                  setDesignerInput({
                    ...designerInput,
                    youtubeUrl: event.target.value ?? "",
                  });
                }}
                maxLength={128}
              />
              <Form.Text className="text-muted">
                `https://`부터 전체 URL을 입력해주세요.
              </Form.Text>
            </Form.Group>
          </Form>
          <div className="text-center">
            <Button variant="contained" onClick={onSaveExtraClick}>
              부가 정보 저장
            </Button>
          </div>
        </>
      )}
    </Container>
  );
}
