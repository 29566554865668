import { API, Auth, graphqlOperation } from "aws-amplify";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ConsultationAPI from "../../../api/consultation";
import consultationConstants from "../../../app/constants/consultation";
import { useAppSelector } from "../../../app/hooks";
import {
  selectCurrentShopId,
  selectShowCreateConsultationWithoutBookingModal,
  setShowCreateConsultationWithoutBookingModal,
} from "../../../features/common";
import { fetchConsultationsByCustomerId } from "../../../features/consultation";
import { selectCustomer } from "../../../features/customer";

import {
  ConsultationGender,
  ConsultationSelfDiagnosisStatus,
  ConsultationStatus,
  CreateConsultationInput,
} from "../../../graphql/API";
import { ConsultationMode } from "../../../models";
import StepDivider from "../Shared/StepDivider";
import StepOne from "../Shared/StepOne";
import StepThree from "../Shared/StepThree";
import StepTwo from "../Shared/StepTwo";
import { selectStudyMode, setStudyMode } from "../../../features/consultation";
import { Switch } from "@mui/material";
import ShopAPI from "../../../api/shop";

export default function ConsultationCreateWithoutBookingModal() {
  const dispatch = useDispatch();

  const show = useAppSelector(selectShowCreateConsultationWithoutBookingModal);

  const stepOneRef = useRef<HTMLDivElement>(null);
  const stepTwoRef = useRef<HTMLDivElement>(null);
  const stepThreeRef = useRef<HTMLDivElement>(null);

  const handleClose = () =>
    dispatch(setShowCreateConsultationWithoutBookingModal(false));

  const currentShopId = useAppSelector(selectCurrentShopId);

  const studyMode = useAppSelector(selectStudyMode);

  const customer = useAppSelector(selectCustomer);

  const [creating, setCreating] = useState(false);

  const consultationAPI = new ConsultationAPI();
  const shopAPI = new ShopAPI();

  useEffect(() => {
    if (!show) {
      setInput(defaultInput);
    }
  }, [show]);

  useEffect(() => {
    if (!currentShopId) {
      return;
    }
  }, [currentShopId]);

  const defaultInput: CreateConsultationInput = {
    gender: ConsultationGender.FEMALE,
    customer: {},
    customerId: "",
    status: ConsultationStatus.IN_PROGRESS,
    selfDiagnosisStatus: ConsultationSelfDiagnosisStatus.NOT_SENT,
    shopId: currentShopId ?? "",
    mode: ConsultationMode.FULL,
  };

  const [input, setInput] = useState<CreateConsultationInput>(defaultInput);

  async function handleSaveChanges() {
    if (!customer) {
      return;
    }

    try {
      setCreating(true);
      const shop: any = await shopAPI.get(currentShopId!);

      const { name, id } = shop;

      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });

      await consultationAPI.create({
        ...input,
        customerId: customer.id,
        customer: {
          id: customer.id,
          name: customer.name,
          phoneNumber: customer.phoneNumber,
        },
        name: customer.name,
        shopId: id,
        shopName: name,
        designerName: user.attributes["custom:designer_name"],
        designerId: user.attributes["custom:designer_id"],
      });

      toast.success(`${customer?.name} 고객님의 컨설테이션을 등록했습니다.`);

      dispatch(fetchConsultationsByCustomerId(customer.id) as any);

      handleClose();
    } catch (error: any) {
      if (error.errors?.length > 0) {
        toast.warn("오류가 발생했습니다.");
        toast.warn(error.errors[0].message);
        return;
      } else {
        toast.warn(error.message);
      }
    } finally {
      setCreating(false);
    }
  }

  function toggleSelection(
    selected: string,
    inputArray: any,
    inputFieldName: string
  ) {
    if (!input) {
      return;
    }

    if (consultationConstants.singleValueFieldNames.includes(inputFieldName)) {
      setInput({
        ...input,
        [inputFieldName]: selected,
      });
      return;
    }

    const existingIndex =
      inputArray?.findIndex((item: any) => item === selected) ?? -1;

    const existingArray = [...(inputArray ?? [])];

    if (existingIndex > -1) {
      existingArray.splice(existingIndex, 1);
    } else {
      existingArray.push(selected);
    }
    setInput({
      ...input,
      [inputFieldName]: existingArray,
    });
  }

  function onStudyModeChange(event: any) {
    dispatch(setStudyMode(event.currentTarget.checked));
  }

  return (
    <>
      <Modal fullscreen={true} show={show} onHide={handleClose}>
        <Modal.Body style={{ padding: 0 }}>
          <Form>
            <div ref={stepOneRef}>
              <StepOne
                input={input}
                setInput={setInput}
                toggleSelection={toggleSelection}
              />
            </div>
            <StepDivider />
            <div ref={stepTwoRef} className="modal-padding-top">
              <StepTwo
                input={input}
                setInput={setInput}
                toggleSelection={toggleSelection}
              />
            </div>
            <StepDivider />
            <div ref={stepThreeRef} className="modal-padding-top">
              <StepThree input={input} setInput={setInput} />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="me-auto">
            스터디 모드
            <Switch
              inputProps={{ "aria-label": "스터디 모드" }}
              checked={studyMode}
              onChange={onStudyModeChange}
            />
          </div>
          <Button
            variant="outline-secondary"
            onClick={() => {
              if (stepOneRef.current) {
                stepOneRef.current.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            S1
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => {
              if (stepTwoRef.current) {
                stepTwoRef.current.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            S2
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => {
              if (stepThreeRef.current) {
                stepThreeRef.current.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            S3
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            닫기
          </Button>
          <Button
            variant="primary"
            disabled={creating}
            onClick={handleSaveChanges}
          >
            등록
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
