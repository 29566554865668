import { API, Auth, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { feedbackByDesignerName } from "../../graphql/queries";
import {
  Feedback,
  FeedbackByDesignerNameQueryVariables,
  ModelSortDirection,
} from "../../graphql/API";
import { Badge, Col, Container, Row, Table } from "react-bootstrap";
import dayjs from "dayjs";
import BackendAPI from "../../api/backend-api";
import APIGateway from "../../api/api-gateway";

export default function FeedbackList() {
  const [feedbacks, setFeedbacks] = useState<Feedback[]>();

  const backendAPI = new BackendAPI();
  const apiGateway = new APIGateway();

  useEffect(() => {
    initialize();
  }, []);

  async function initialize() {
    const user = await Auth.currentAuthenticatedUser();

    const designerName = user.attributes["custom:designer_name"];
    const designerId = user.attributes["custom:designer_id"];

    if (designerId) {
      const data = await apiGateway.feedback.listByDesignerId(designerId);
      setFeedbacks(data);
    } else if (designerName) {
      const variables: FeedbackByDesignerNameQueryVariables = {
        designerName,
        sortDirection: ModelSortDirection.DESC,
      };
      const response: any = await API.graphql(
        graphqlOperation(feedbackByDesignerName, variables)
      );

      const data = response.data.feedbackByDesignerName.items;
      setFeedbacks(data);

      backendAPI.syncDesignerRecord(user.attributes.sub);
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h4>피드백 기록</h4>
          <Table responsive>
            <colgroup>
              <col width="20%" />
              <col width="15%" />
              <col width="25%" />
              <col width="25%" />
              <col width="15%" />
            </colgroup>
            <thead>
              <tr>
                <th>디자이너</th>
                <th>만족도</th>
                <th>피드백</th>
                <th>응원 메시지</th>
                <th>일시</th>
              </tr>
            </thead>
            <tbody>
              {feedbacks?.map((feedback) => {
                return (
                  <tr key={feedback.bookingId}>
                    <td>{feedback.designerName}</td>
                    <td>
                      {feedback.satisfaction === 1 && (
                        <Badge bg="warning">아쉬움</Badge>
                      )}
                      {feedback.satisfaction === 2 && (
                        <Badge bg="primary">만족</Badge>
                      )}
                      {feedback.satisfaction === 3 && (
                        <Badge bg="success">매우만족</Badge>
                      )}
                    </td>
                    <td>{feedback.comment}</td>
                    <td>{feedback.supportMessage}</td>
                    <td>
                      {dayjs(feedback.createdAt).format("YYYY-MM-DD HH:mm")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
