import { Chip } from "@mui/material";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import APIGateway from "../../api/api-gateway";
import { useAppSelector } from "../../app/hooks";
import {
  selectCurrentShop,
  selectUserAttributes,
  selectUserGroups,
  setUserGroups,
} from "../../features/common";
import {
  fetchDesignersByShopId,
  selectDesigners,
} from "../../features/designer";
import { ConsultationSelfDiagnosisStatus } from "../../models";
import { BookingStatus } from "../../models";

export default function Dashboard() {
  const dispatch = useDispatch();
  const currentShop = useAppSelector(selectCurrentShop);
  const userAttributes = useAppSelector(selectUserAttributes);
  const [designerId, setDesignerId] = useState<string>();
  const [designerName, setDesignerName] = useState<string>();
  const designers = useAppSelector(selectDesigners);
  const groups = useAppSelector(selectUserGroups);
  const [bookingSummary, setBookingSummary] = useState({
    count: 0,
    countCanceled: 0,
  });
  const [bookingSummaryDesigner, setBookingSummaryDesigner] = useState({
    count: 0,
    countCanceled: 0,
  });
  const [consultationSummaryDesigner, setConsultationSummaryDesigner] =
    useState({
      count: 0,
      countSelfDiagnosis: 0,
      countSent: 0,
    });
  const [feedbackSummaryDesigner, setFeedbackSummaryDesigner] = useState({
    count: 0,
    countPerfectScore: 0,
  });
  const [countByInflowChannel, setCountByInflowChannel] = useState<
    {
      name: string;
      count: number;
    }[]
  >([]);

  const apiGateway = new APIGateway();

  useEffect(() => {
    if (!userAttributes) {
      return;
    }
    const designerId = userAttributes["custom:designer_id"];

    setDesignerId(designerId);
    setDesignerName(userAttributes["custom:designer_name"]);
  }, [userAttributes]);

  useEffect(() => {
    if (!designerId || !currentShop) {
      return;
    }
    initialize();
  }, [designerId, currentShop]);

  async function initialize() {
    if (!designerId) {
      return;
    }

    if (!currentShop) {
      return;
    }

    const user = await Auth.currentAuthenticatedUser();

    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];

    const param = groups ?? [];

    dispatch(setUserGroups(param));

    dispatch(fetchDesignersByShopId(currentShop.id) as any);

    generateBookingSummary();
    generateBookingSummaryDesigner(designerId);
    generateConsultationSummaryDesigner(designerId);
    generateFeedbackSummaryDesigner(designerId);
  }

  async function generateBookingSummary() {
    const shopId = currentShop?.id!;

    const bookings = await apiGateway.booking.listByShopIdThisMonth(shopId);

    const count = bookings.length;
    const countCanceled = bookings.filter(
      (b) => b.bookingStatus === BookingStatus.CANCELED
    ).length;

    setBookingSummary({
      count,
      countCanceled,
    });
  }

  async function generateBookingSummaryDesigner(designerId: string) {
    const bookings = await apiGateway.booking.listByDesignerIdThisMonth(
      designerId
    );

    const count = bookings.length;
    const countCanceled = bookings.filter(
      (b) => b.bookingStatus === BookingStatus.CANCELED
    ).length;

    setBookingSummaryDesigner({
      count,
      countCanceled,
    });
  }

  async function generateConsultationSummaryDesigner(designerId: string) {
    const consultations =
      await apiGateway.consultation.listByDesignerIdThisMonth(designerId);

    const count = consultations.length;
    const countSent = consultations.filter((b) => b.solutionSentAt).length;
    const countSelfDiagnosis = consultations.filter(
      (c) => c.selfDiagnosisStatus === ConsultationSelfDiagnosisStatus.DONE
    ).length;

    const countByInflowChannelHash: any = {};

    consultations.forEach((c) => {
      if (c.inflowChannel) {
        if (!countByInflowChannelHash[c.inflowChannel]) {
          countByInflowChannelHash[c.inflowChannel] = 0;
        }
        countByInflowChannelHash[c.inflowChannel] += 1;
      }
    });

    const countByInflowChannel: {
      name: string;
      count: number;
    }[] = [];

    Object.keys(countByInflowChannelHash).forEach((key) => {
      countByInflowChannel.push({
        name: key,
        count: countByInflowChannelHash[key],
      });
    });

    setCountByInflowChannel(
      countByInflowChannel.sort((a, b) => b.count - a.count)
    );

    setConsultationSummaryDesigner({
      count,
      countSelfDiagnosis,
      countSent,
    });
  }

  async function generateFeedbackSummaryDesigner(designerId: string) {
    const feedbacks = await apiGateway.feedback.listByDesignerIdThisMonth(
      designerId
    );

    const count = feedbacks.length;
    const countPerfectScore = feedbacks.filter(
      (b) => b.satisfaction === 3
    ).length;

    setFeedbackSummaryDesigner({
      count,
      countPerfectScore,
    });
  }

  if (!userAttributes) {
    <Container>...</Container>;
  }

  if (!userAttributes["custom:designer_id"]) {
    <Container>...</Container>;
  }

  const isAdmin = (groups ?? []).includes("Admin");

  return (
    <Container>
      <Row>
        <Col>
          <h6>이번 달 요약</h6>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <ul>
            <li>
              우리 매장 이번 달 예약은 총 <code>{bookingSummary.count}</code>
              건이며, 그중 취소된 예약은{" "}
              <code>{bookingSummary.countCanceled}</code>건입니다.
            </li>
          </ul>
        </Col>
        <Col xs={12} sm={6}>
          {isAdmin && (
            <div style={{ marginBottom: 10 }}>
              {designers.map((designer) => {
                return (
                  <Button
                    key={designer.id}
                    size="sm"
                    variant={
                      designer.id === designerId ? "primary" : "outline-primary"
                    }
                    onClick={() => {
                      setDesignerId(designer.id);
                      setDesignerName(designer.name);
                    }}
                    style={{ marginRight: 5 }}
                  >
                    {designer.name}
                  </Button>
                );
              })}
              <hr />
            </div>
          )}
          <div style={{ marginBottom: 10 }}>
            <h6 style={{ marginBottom: 5 }}>종합</h6>
            <Chip
              label={`피드백 매우 만족 ${
                feedbackSummaryDesigner.count > 0
                  ? Math.round(
                      feedbackSummaryDesigner.countPerfectScore /
                        feedbackSummaryDesigner.count
                    ) * 100
                  : 0
              }%`}
              color="success"
              variant="outlined"
              style={{ marginBottom: 10, marginRight: 10 }}
            />
            <Chip
              label={`셀프 진단 수신 ${consultationSummaryDesigner.countSelfDiagnosis}회`}
              color="success"
              variant="outlined"
              style={{ marginBottom: 10, marginRight: 10 }}
            />
            <Chip
              label={`컨설테이션 솔루션 발송 ${consultationSummaryDesigner.countSent}회`}
              color="success"
              variant="outlined"
              style={{ marginBottom: 10 }}
            />
          </div>
          <div style={{ marginBottom: 10 }}>
            <h6 style={{ marginBottom: 5 }}>유입 채널</h6>
            {countByInflowChannel.map((c) => {
              return (
                <Chip
                  label={`${c.name} ${c.count}회`}
                  color="success"
                  variant="outlined"
                  style={{ marginBottom: 10, marginRight: 10 }}
                />
              );
            })}
          </div>
          <ul>
            <li>
              {designerName} 님의 이번 달 예약은 총{" "}
              <code>{bookingSummaryDesigner.count}</code>건이며, 그중 취소된
              예약은 <code>{bookingSummaryDesigner.countCanceled}</code>
              건입니다.
            </li>
            <li>
              {designerName} 님이 이번 달 등록한 컨설테이션(셀프 진단 포함)은 총{" "}
              <code>{consultationSummaryDesigner.count}</code>건이며, 솔루션
              발송은 총 <code>{consultationSummaryDesigner.countSent}</code>회
              진행되었습니다.
            </li>
            <li>
              {designerName} 님의 이번 달 고객 피드백은 총{" "}
              <code>{feedbackSummaryDesigner.count}</code>건이며, 매우 만족은{" "}
              <code>{feedbackSummaryDesigner.countPerfectScore}</code>건입니다.
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}
