import { API, graphqlOperation } from "aws-amplify";
import dayjs from "dayjs";
import {
  Booking,
  BookingByDesignerIdQueryVariables,
  BookingByShopIdQueryVariables,
  CreateBookingInput,
  OnCreateBookingSubscriptionVariables,
  OnUpdateBookingSubscriptionVariables,
  UpdateBookingInput,
} from "../../graphql/API";
import { createBooking } from "../../graphql/mutations";
import {
  bookingByDesignerId,
  bookingByShopId,
  getBooking,
} from "../../graphql/queries";

const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
    }
  }
`;

export default class BookingAPI {
  async get(id: string): Promise<Booking> {
    const response: any = await API.graphql(
      graphqlOperation(getBooking, {
        id,
      })
    );
    return response.data.getBooking;
  }

  async create(input: CreateBookingInput) {
    return API.graphql({
      query: createBooking,
      variables: {
        input: {
          ...input,
          yearMonth: dayjs(input.date).format("YYYYMM"),
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }
  async updateBooking(input: UpdateBookingInput) {
    if (!input._version) {
      throw new Error("Missing _version");
    }

    return API.graphql({
      query: updateBooking,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }

  async listByDesignerIdThisMonth(designerId: string): Promise<Booking[]> {
    const from = dayjs().startOf("month").format("YYYY-MM-DD");
    const to = dayjs().endOf("month").format("YYYY-MM-DD");

    const variables: BookingByDesignerIdQueryVariables = {
      designerId,
      date: {
        between: [from, to],
      },
      limit: 1000,
    };

    const response: any = await API.graphql(
      graphqlOperation(bookingByDesignerId, variables)
    );
    return response.data.bookingByDesignerId.items.filter(
      (item: Booking) => !item._deleted
    );
  }

  async listByShopIdThisMonth(shopId: string): Promise<Booking[]> {
    const from = dayjs().startOf("month").format("YYYY-MM-DD");
    const to = dayjs().endOf("month").format("YYYY-MM-DD");

    const variables: BookingByShopIdQueryVariables = {
      shopId,
      date: {
        between: [from, to],
      },
      limit: 1000,
    };

    const response: any = await API.graphql(
      graphqlOperation(bookingByShopId, variables)
    );
    return response.data.bookingByShopId.items.filter(
      (item: Booking) => !item._deleted
    );
  }

  composeOnCreateBookingSubscription(shopId: string) {
    const onCreateBooking = /* GraphQL */ `
      subscription OnCreateBooking(
        $filter: ModelSubscriptionBookingFilterInput
      ) {
        onCreateBooking(filter: $filter) {
          id
          shopId
        }
      }
    `;

    const variables: OnCreateBookingSubscriptionVariables = {
      filter: {
        shopId: {
          eq: shopId,
        },
      },
    };

    return API.graphql({
      query: onCreateBooking,
      variables,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }) as any;
  }

  composeOnUpdateBookingSubscription(shopId: string) {
    const onUpdateBooking = /* GraphQL */ `
      subscription OnUpdateBooking(
        $filter: ModelSubscriptionBookingFilterInput
      ) {
        onUpdateBooking(filter: $filter) {
          id
          shopId
        }
      }
    `;

    const variables: OnUpdateBookingSubscriptionVariables = {
      filter: {
        shopId: {
          eq: shopId,
        },
      },
    };

    return API.graphql({
      query: onUpdateBooking,
      variables,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }) as any;
  }
}
