export default function RecommendationItemName({ recommendations, item }: any) {
  return (
    <>
      {item}
      <div style={{ position: "absolute", top: "6px" }}>
        {recommendations?.includes(item) && "✨"}
      </div>
    </>
  );
}
