import dayjs from "dayjs";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import CustomerAPI from "../../api/customer";
import { useAppSelector } from "../../app/hooks";
import {
  selectCurrentShopId,
  selectShowCreateCustomerModal,
  setShowCreateCustomerModal,
  setShowUpdateCustomerModal,
} from "../../features/common";
import { Customer } from "../../graphql/API";
import CustomerModal from "./Modal";
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { Box, Button, Stack } from "@mui/material";
import { fetchCustomerById } from "../../features/customer";

export default function CustomerList() {
  const dispatch = useDispatch();
  const customerAPI = new CustomerAPI();

  const currentShopId = useAppSelector(selectCurrentShopId);
  const showCreateCustomerModal = useAppSelector(selectShowCreateCustomerModal);

  const [customers, setCustomers] = useState<Customer[]>([]);
  const [name, setName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  useEffect(() => {
    fetchCustomers();
  }, [currentShopId]);

  useEffect(() => {
    if (showCreateCustomerModal === false) {
      fetchCustomers();
    }
  }, [showCreateCustomerModal]);

  async function fetchCustomers() {
    if (!currentShopId) {
      return;
    }

    const customers = await customerAPI.listCustomersByShopId(
      currentShopId,
      name,
      phoneNumber
    );

    setCustomers(customers);
  }

  function onClickShowCustomerModal(customer: Customer) {
    dispatch(fetchCustomerById(customer.id) as any);
    dispatch(setShowUpdateCustomerModal(true));
  }

  const columns: GridColumns<Customer> = [
    {
      field: "name",
      headerName: "성명",
      minWidth: 80,
      cellClassName: "super-app-theme--no-padding-cell",
      renderCell: (params: GridRenderCellParams<string>) => (
        <Button
          sx={{ justifyContent: "start", minWidth: 48 }}
          onClick={() => {
            onClickShowCustomerModal(params.row);
          }}
        >
          {params.value}
        </Button>
      ),
    },
    {
      field: "sign",
      headerName: "서명",
      type: "boolean",
      valueGetter(params) {
        return !!params.row?.signature;
      },
      maxWidth: 40,
    },
    {
      field: "phoneNumber",
      headerName: "전화번호",
      minWidth: 110,
    },
    {
      field: "drink",
      headerName: "음료",
      minWidth: 60,
    },
    {
      field: "createdAt",
      headerName: "등록일",
      minWidth: 80,
      valueGetter(params) {
        return dayjs(params.value).format("YYYY-MM-DD");
      },
    },
  ];

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="성명"
                aria-label="성명"
                aria-describedby="basic-addon2"
                onChange={(event) => {
                  setName(event.target.value);
                }}
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    fetchCustomers();
                  }
                }}
              />
              <Form.Control
                placeholder="전화번호"
                aria-label="전화번호"
                aria-describedby="basic-addon2"
                onChange={(event) => {
                  setPhoneNumber(event.target.value);
                }}
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    fetchCustomers();
                  }
                }}
              />
              <Button
                variant="outlined"
                id="button-addon2"
                onClick={() => {
                  fetchCustomers();
                }}
              >
                검색
              </Button>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Box
              sx={{
                "& .super-app-theme--no-padding-cell": {
                  padding: "0 !important",
                },
              }}
            >
              <DataGridPro
                columns={columns}
                rows={customers}
                autoHeight={true}
                components={{
                  NoRowsOverlay: () => (
                    <Stack
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ textAlign: "center" }}
                    >
                      고객 정보를 등록하세요.
                    </Stack>
                  ),
                }}
              />
            </Box>
          </Col>
        </Row>
      </Container>
      <div id="action-button-create">
        <Fab
          color="info"
          aria-label="add"
          variant="extended"
          onClick={() => {
            dispatch(setShowCreateCustomerModal(true) as any);
          }}
        >
          <AddIcon />
          등록
        </Fab>
      </div>
    </div>
  );
}
