import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Shop } from "../../graphql/API";

export interface CommonState {
  currentShopId?: string;
  currentShop?: Shop;
  consultationTermsId?: number;
  showPaymentModal: boolean;
  showCreateBookingModal: boolean;
  showCreateCustomerModal: boolean;
  showCreateConsultationModal: boolean;
  showCreateConsultationWithoutBookingModal: boolean;
  showUpdateConsultationModal: boolean;
  showUpdateBookingModal: boolean;
  showUpdateCustomerModal: boolean;
  showConsultationTermsModal: boolean;
  showUserManualModal: boolean;
  showUserModal: boolean;
  userAttributes: any;
  userGroups: string[];
  showImageDetailModal: boolean;
}

const initialState: CommonState = {
  currentShopId: undefined,
  currentShop: undefined,
  consultationTermsId: undefined,
  showPaymentModal: false,
  showCreateBookingModal: false,
  showCreateCustomerModal: false,
  showCreateConsultationModal: false,
  showCreateConsultationWithoutBookingModal: false,
  showUpdateConsultationModal: false,
  showUpdateBookingModal: false,
  showUpdateCustomerModal: false,
  showConsultationTermsModal: false,
  showUserManualModal: false,
  showUserModal: false,
  userAttributes: {},
  userGroups: [],
  showImageDetailModal: false,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setCurrentShopId: (state, action: PayloadAction<string>) => {
      state.currentShopId = action.payload;
    },
    setCurrentShop: (state, action: PayloadAction<Shop>) => {
      state.currentShop = action.payload;
    },
    setConsultationTermsId: (state, action: PayloadAction<number>) => {
      state.consultationTermsId = action.payload;
    },
    setShowPaymentModal: (state, action: PayloadAction<boolean>) => {
      state.showPaymentModal = action.payload;
    },
    setShowCreateBookingModal: (state, action: PayloadAction<boolean>) => {
      state.showCreateBookingModal = action.payload;
    },
    setShowCreateCustomerModal: (state, action: PayloadAction<boolean>) => {
      state.showCreateCustomerModal = action.payload;
    },
    setShowUpdateCustomerModal: (state, action: PayloadAction<boolean>) => {
      state.showUpdateCustomerModal = action.payload;
    },
    setShowCreateConsultationModal: (state, action: PayloadAction<boolean>) => {
      state.showCreateConsultationModal = action.payload;
    },
    setShowCreateConsultationWithoutBookingModal: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showCreateConsultationWithoutBookingModal = action.payload;
    },
    setShowUpdateConsultationModal: (state, action: PayloadAction<boolean>) => {
      state.showUpdateConsultationModal = action.payload;
    },
    setShowUpdateBookingModal: (state, action: PayloadAction<boolean>) => {
      state.showUpdateBookingModal = action.payload;
    },
    setShowConsultationTermsModal: (state, action: PayloadAction<boolean>) => {
      state.showConsultationTermsModal = action.payload;
    },
    setShowUserManualModal: (state, action: PayloadAction<boolean>) => {
      state.showUserManualModal = action.payload;
    },
    setShowUserModal: (state, action: PayloadAction<boolean>) => {
      state.showUserModal = action.payload;
    },
    setUserGroups: (state, action: PayloadAction<string[]>) => {
      state.userGroups = action.payload;
    },
    setUserAttributes: (state, action: PayloadAction<any>) => {
      state.userAttributes = action.payload;
    },
    setImageDetailModal: (state, action: PayloadAction<boolean>) => {
      state.showImageDetailModal = action.payload;
    },
  },
});

export const selectCurrentShopId = (state: RootState) =>
  state.common.currentShopId;

export const selectCurrentShop = (state: RootState) => state.shop.currentShop;

export const selectConsultationTermsId = (state: RootState) =>
  state.common.consultationTermsId;

export const selectShowPaymentModal = (state: RootState) =>
  state.common.showPaymentModal;

export const selectShowCreateBookingModal = (state: RootState) =>
  state.common.showCreateBookingModal;

export const selectShowCreateCustomerModal = (state: RootState) =>
  state.common.showCreateCustomerModal;

export const selectShowUpdateCustomerModal = (state: RootState) =>
  state.common.showUpdateCustomerModal;

export const selectShowCreateConsultationModal = (state: RootState) =>
  state.common.showCreateConsultationModal;

export const selectShowCreateConsultationWithoutBookingModal = (
  state: RootState
) => state.common.showCreateConsultationWithoutBookingModal;

export const selectShowUpdateConsultationModal = (state: RootState) =>
  state.common.showUpdateConsultationModal;

export const selectShowUpdateBookingModal = (state: RootState) =>
  state.common.showUpdateBookingModal;

export const selectShowConsultationTermsModal = (state: RootState) =>
  state.common.showConsultationTermsModal;

export const selectShowUserManualModal = (state: RootState) =>
  state.common.showUserManualModal;

export const selectShowUserModal = (state: RootState) =>
  state.common.showUserModal;

export const selectUserAttributes = (state: RootState) =>
  state.common.userAttributes;

export const selectUserGroups = (state: RootState) => state.common.userGroups;

export const selectShowImageDetailModal = (state: RootState) =>
  state.common.showImageDetailModal;

export const {
  setCurrentShopId,
  setCurrentShop,
  setConsultationTermsId,
  setShowPaymentModal,
  setShowCreateBookingModal,
  setShowCreateCustomerModal,
  setShowUpdateCustomerModal,
  setShowCreateConsultationModal,
  setShowCreateConsultationWithoutBookingModal,
  setShowUpdateConsultationModal,
  setShowUpdateBookingModal,
  setShowConsultationTermsModal,
  setShowUserManualModal,
  setShowUserModal,
  setUserAttributes,
  setUserGroups,
  setImageDetailModal,
} = commonSlice.actions;

export default commonSlice.reducer;
