import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Typeahead, withAsync } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker, DesktopTimePicker } from "@mui/x-date-pickers-pro";

import BookingAPI from "../../../api/booking";
import CustomerAPI from "../../../api/customer";
import { useAppSelector } from "../../../app/hooks";
import { refreshBookings, selectDate } from "../../../features/booking";
import {
  selectCurrentShop,
  selectCurrentShopId,
  selectShowCreateBookingModal,
  selectUserAttributes,
  setShowCreateBookingModal,
  setShowCreateCustomerModal,
} from "../../../features/common";
import {
  selectDesigners,
  fetchDesignersByShopId,
} from "../../../features/designer";

import { CreateBookingInput } from "../../../graphql/API";
import { BookingStatus, Customer } from "../../../models";
import { PaymentStatus } from "../../../models";
import { ServiceStatus } from "../../../models";
import { BookingOrigin } from "../../../models";
import {
  emptyNewCustomer,
  selectNewCustomer,
  setCreateCustomerInput,
} from "../../../features/customer";
import GA from "../../../api/ga";
import { useSnackbar } from "notistack";
import BrowserService from "../../../services/browser";
import { Button } from "@mui/material";

const AsyncTypeahead = withAsync(Typeahead as any);

export default function BookingCreateModal() {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const CREATE_CONSULTATION_MESSAGE_KEY = "CREATE_CONSULTATION_MESSAGE_KEY";

  const show = useAppSelector(selectShowCreateBookingModal);
  const newCustomer = useAppSelector(selectNewCustomer);

  const handleClose = () => {
    dispatch(emptyNewCustomer());

    dispatch(setShowCreateBookingModal(false));
  };

  const currentShopId = useAppSelector(selectCurrentShopId);
  const currentShop = useAppSelector(selectCurrentShop);
  const userAttributes = useAppSelector(selectUserAttributes);

  const dateOnBookingList = useAppSelector(selectDate);

  const designers = useAppSelector(selectDesigners);

  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [inputText, setInputText] = useState("");

  const defaultDateTime = dayjs(dateOnBookingList)
    .set("hour", dayjs().get("hour") + 1)
    .set("minute", 0);
  const [dateTimeValue, setDateTimeValue] = useState<Dayjs | null>(
    defaultDateTime
  );
  const defaultInput = (defaultDateTime: Dayjs): CreateBookingInput => {
    return {
      date: defaultDateTime.format("YYYY-MM-DD"),
      time: defaultDateTime.format("HH:mm"),
      customerId: "",
      shopId: "",
      designerId: userAttributes["custom:designer_id"] ?? "",
      designerName: userAttributes["custom:designer_name"] ?? "",
    };
  };
  const [input, setInput] = useState<CreateBookingInput>(
    defaultInput(defaultDateTime)
  );

  const handleDateTimeChange = (newValue: Dayjs | null) => {
    if (!newValue) {
      return;
    }

    setDateTimeValue(newValue);

    setInput({
      ...input,
      date: newValue?.format("YYYY-MM-DD"),
      time: newValue?.format("HH:mm"),
    });
  };

  const bookingAPI = new BookingAPI();
  const customerAPI = new CustomerAPI();

  useEffect(() => {
    if (!currentShopId) {
      return;
    }

    dispatch(fetchDesignersByShopId(currentShopId) as any);
  }, [currentShopId]);

  useEffect(() => {
    if (show && newCustomer) {
      const { name, phoneNumber } = newCustomer;
      setInput({
        ...input,
        customer: {
          name,
          phoneNumber,
        },
      });
    }
  }, [newCustomer]);

  async function onChangeDesignerId(designerId: string) {
    const designer = designers.find((item) => item.id === designerId)!;

    setInput({
      ...input,
      designerId,
      designerName: designer.name,
    } as any);
  }

  async function handleSaveChanges() {
    const { date, time, designerId, customerId, customer } = input;

    if (!dayjs(date + " " + time, "YYYY-MM-DD HH:mm").isValid()) {
      toast.warn("예약 시간이 HH:mm(14:00) 형식인지 확인하세요.");
      return;
    }

    if (!designerId) {
      toast.warn("디자이너를 선택하세요.");
      return;
    }

    if (!customer) {
      toast.warn("고객을 선택하세요.");
      return;
    }

    const result = await customerAPI.listCustomersByNameAndPhone(
      customer.name!,
      customer.phoneNumber!,
      currentShopId!
    );

    let customerSnapshot = undefined;

    if (result.length > 0) {
      customerSnapshot = result[0];
    }

    if (!customerSnapshot) {
      toast.warn("고객을 선택하세요.");
      return;
    }

    const createInput: CreateBookingInput = {
      ...input,
      customerId: customerSnapshot.id,
      customer: {
        name: customerSnapshot.name,
        phoneNumber: customerSnapshot.phoneNumber,
        id: customerSnapshot.id,
      },
      name: customerSnapshot.name,
      shopId: currentShopId!,
      shopName: currentShop?.name,
      origin: BookingOrigin.MANUAL,
      serviceStatus: ServiceStatus.NOT_STARTED,
      paymentStatus: PaymentStatus.NOT_PAID,
      bookingStatus: BookingStatus.CONFIRMED,
      phoneNumber: customerSnapshot.phoneNumber,

      options: [],
    };

    await bookingAPI.create(createInput);

    GA.event("예약", "예약 등록", currentShop?.name);

    dispatch(refreshBookings() as any);

    enqueueSnackbar("예약 등록했습니다.", { variant: "success" });

    if (BrowserService.getJustSignedUp()) {
      setTimeout(() => {
        enqueueSnackbar("첫 예약 등록을 축하합니다. 👏🏻", {
          variant: "success",
          autoHideDuration: 5000,
        });
      }, 1000);

      setTimeout(() => {
        enqueueSnackbar(
          "연필 모양을 눌러서 예약 창을 열면 컨설테이션 시작 버튼이 있어요. 고객과 상담을 진행하고 결과지를 전송해 보세요.",
          {
            variant: "info",
            autoHideDuration: 10000,
            key: CREATE_CONSULTATION_MESSAGE_KEY,
            action: () => {
              return (
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    closeSnackbar(CREATE_CONSULTATION_MESSAGE_KEY);
                  }}
                >
                  Ok
                </Button>
              );
            },
          }
        );
      }, 3000);
      BrowserService.removeJustSignedUp();
    }

    handleClose();
  }

  async function searchCustomer(keyword: string) {
    setLoading(true);

    const result = await customerAPI.listCustomersByShopId(
      currentShopId!,
      keyword,
      keyword
    );

    setCustomers(result);

    setLoading(false);
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>예약 등록</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row style={{ marginBottom: 15 }}>
              <Col xs="auto">
                <DesktopDatePicker
                  label="예약일"
                  value={dateTimeValue}
                  inputFormat="YYYY-MM-DD"
                  toolbarFormat="MM월 DD일"
                  onChange={handleDateTimeChange}
                  onAccept={(newValue) => {
                    if (!newValue) {
                      return;
                    }
                    setInput({
                      ...input,
                      date: newValue.format("YYYY-MM-DD"),
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      style={{ width: "150px" }}
                    />
                  )}
                />
              </Col>
              <Col>
                <DesktopTimePicker
                  label="시간"
                  value={dateTimeValue}
                  ampm
                  inputFormat="HH:mm"
                  minutesStep={15}
                  onChange={handleDateTimeChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      style={{ width: "110px" }}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    디자이너
                  </Form.Label>
                  <Col sm="10">
                    {designers.map((designer) => {
                      return (
                        <Button
                          key={designer.id}
                          variant={
                            designer.id === input.designerId
                              ? "contained"
                              : "outlined"
                          }
                          sx={{ marginRight: 1, marginBottom: 1, minWidth: 40 }}
                          onClick={() => {
                            onChangeDesignerId(designer.id);
                          }}
                        >
                          {designer.name}
                        </Button>
                      );
                    })}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  style={{ paddingBottom: 100 }}
                >
                  <Form.Label column sm="2">
                    고객
                  </Form.Label>
                  <Col sm="10">
                    <Row>
                      <Col xs={8}>
                        <AsyncTypeahead
                          id="typeahead-customer-finder"
                          isLoading={loading}
                          labelKey={(option: any) =>
                            `${option.name} ${option.phoneNumber}`
                          }
                          onSearch={(query) => {
                            searchCustomer(query);
                            setInputText(query);
                          }}
                          options={customers}
                          onChange={(customer) => {
                            const { name, phoneNumber } = customer[0] as any;
                            setInput({
                              ...input,
                              customer: { name, phoneNumber },
                            });
                          }}
                          placeholder="이름 또는 전화번호 앞자리"
                        />
                        {input.customer && (
                          <div style={{ marginTop: 5 }}>
                            <CheckCircleOutlineSharpIcon
                              fontSize="small"
                              color="success"
                            />{" "}
                            <strong>{input.customer?.name}</strong>{" "}
                            {input.customer?.phoneNumber}
                          </div>
                        )}
                      </Col>
                      <Col xs={4} className="text-end">
                        <Button
                          variant="outlined"
                          onClick={() => {
                            dispatch(setShowCreateCustomerModal(true));

                            if (inputText.startsWith("0")) {
                              dispatch(
                                setCreateCustomerInput({
                                  name: "",
                                  phoneNumber: inputText,
                                  shopId: "",
                                })
                              );
                            } else {
                              dispatch(
                                setCreateCustomerInput({
                                  name: inputText,
                                  phoneNumber: "",
                                  shopId: "",
                                })
                              );
                            }
                          }}
                        >
                          고객 등록
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col></Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outlined"
            sx={{ marginRight: 1 }}
            onClick={handleClose}
          >
            닫기
          </Button>
          <Button variant="contained" onClick={handleSaveChanges}>
            예약 등록
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
