// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const BookingOrigin = {
  "NAVER": "NAVER",
  "MANUAL": "MANUAL"
};

const DiscountMode = {
  "AMOUNT": "AMOUNT",
  "RATE": "RATE"
};

const ServiceStatus = {
  "NOT_STARTED": "NOT_STARTED",
  "IN_PROGRESS": "IN_PROGRESS",
  "DONE": "DONE"
};

const PaymentStatus = {
  "NOT_PAID": "NOT_PAID",
  "PAID": "PAID"
};

const BookingStatus = {
  "CONFIRMED": "CONFIRMED",
  "COMPLETED": "COMPLETED",
  "CANCELED": "CANCELED"
};

const NaverPaymentMoment = {
  "PRE": "PRE",
  "POST": "POST"
};

const NaverPaymentMethod = {
  "CREDIT_CARD": "CREDIT_CARD",
  "POINT": "POINT",
  "BANK_TRANSFER": "BANK_TRANSFER",
  "BANK_DEPOSIT": "BANK_DEPOSIT",
  "ETC": "ETC",
  "NAVER_PAY_MONEY": "NAVER_PAY_MONEY",
  "NAVER_PAY_BANK": "NAVER_PAY_BANK",
  "NAVER_PAY_POINT": "NAVER_PAY_POINT"
};

const NaverPaymentProvider = {
  "NAVER_PAY": "NAVER_PAY"
};

const NaverPaymentStatus = {
  "REGISTERED": "REGISTERED",
  "REQUESTED": "REQUESTED",
  "DEPOSIT_WAITING": "DEPOSIT_WAITING",
  "CHARGED": "CHARGED",
  "PAID": "PAID",
  "REFUNDED": "REFUNDED",
  "COMPLETED": "COMPLETED",
  "CANCELED": "CANCELED"
};

const ConsultationGender = {
  "MALE": "MALE",
  "FEMALE": "FEMALE"
};

const ConsultationStatus = {
  "NOT_STARTED": "NOT_STARTED",
  "IN_PROGRESS": "IN_PROGRESS",
  "DONE": "DONE"
};

const ConsultationSelfDiagnosisStatus = {
  "NOT_SENT": "NOT_SENT",
  "SENT": "SENT",
  "IN_PROGRESS": "IN_PROGRESS",
  "DONE": "DONE"
};

const ConsultationMode = {
  "FULL": "FULL",
  "CUT": "CUT",
  "COLOR": "COLOR",
  "PERM": "PERM"
};

const FeedbackCategory = {
  "SERVICE": "SERVICE"
};

const PhotoCategory = {
  "TREATMENT_PROCESS": "TREATMENT_PROCESS",
  "TREATMENT_COMPLETION": "TREATMENT_COMPLETION"
};

const AnonymousPhotoCategory = {
  "TREATMENT_COMPLETION": "TREATMENT_COMPLETION",
  "STYLE_INTERESTED_IN": "STYLE_INTERESTED_IN"
};

const PrepaidPassRecordType = {
  "EARN": "EARN",
  "SPEND": "SPEND"
};

const { DiscountPreset, Designer, Feedback, RegularCustomerNote, UserPushTokenRequest, UserPushToken, CustomerV2, Caring, SelfDiagnosisRequest, SelfDiagnosisContent, RegularCustomerQuestionnaireRequest, RegularCustomerQuestionnaireContent, UserActivitySnapshot, DailyLog, SelfDiagnosis, Consultation, AnonymousPhoto, Photo, CheckIn, Booking, Customer, Shop, ShopServiceDrink, ShopUserApplication, ShopUserRemovalRequest, ServiceOption, PrepaidPassPreset, PrepaidPassHistory, Brand, Product, ConsultationSolutionPreset, CoupangProduct, GeneralProduct, Event, MutationResponse, DiscountPresetSnapshot, BookingServiceOption, ProductSnapshot, BookingProductOption, NaverServiceOption, NaverPaymentStatusHistory, NaverPayment, NaverSnapshot, NestedCustomer, Solution, HairRecommendation, SolutionProduct, NestedConsultation, NestedPhoto, ServiceOptionItem, ServiceOptionCategory } = initSchema(schema);

export {
  DiscountPreset,
  Designer,
  Feedback,
  RegularCustomerNote,
  UserPushTokenRequest,
  UserPushToken,
  CustomerV2,
  Caring,
  SelfDiagnosisRequest,
  SelfDiagnosisContent,
  RegularCustomerQuestionnaireRequest,
  RegularCustomerQuestionnaireContent,
  UserActivitySnapshot,
  DailyLog,
  SelfDiagnosis,
  Consultation,
  AnonymousPhoto,
  Photo,
  CheckIn,
  Booking,
  Customer,
  Shop,
  ShopServiceDrink,
  ShopUserApplication,
  ShopUserRemovalRequest,
  ServiceOption,
  PrepaidPassPreset,
  PrepaidPassHistory,
  Brand,
  Product,
  ConsultationSolutionPreset,
  CoupangProduct,
  GeneralProduct,
  Event,
  MutationResponse,
  BookingOrigin,
  DiscountMode,
  ServiceStatus,
  PaymentStatus,
  BookingStatus,
  NaverPaymentMoment,
  NaverPaymentMethod,
  NaverPaymentProvider,
  NaverPaymentStatus,
  ConsultationGender,
  ConsultationStatus,
  ConsultationSelfDiagnosisStatus,
  ConsultationMode,
  FeedbackCategory,
  PhotoCategory,
  AnonymousPhotoCategory,
  PrepaidPassRecordType,
  DiscountPresetSnapshot,
  BookingServiceOption,
  ProductSnapshot,
  BookingProductOption,
  NaverServiceOption,
  NaverPaymentStatusHistory,
  NaverPayment,
  NaverSnapshot,
  NestedCustomer,
  Solution,
  HairRecommendation,
  SolutionProduct,
  NestedConsultation,
  NestedPhoto,
  ServiceOptionItem,
  ServiceOptionCategory
};