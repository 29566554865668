import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";
import CustomerAPI from "../../api/customer";
import { RootState } from "../../app/store";
import { CreateCustomerInput, Customer } from "../../graphql/API";

export interface CustomerState {
  customer?: Customer;
  newCustomer?: Customer;
  createCustomerInput: CreateCustomerInput;
}

const defaultCreateCustomerInput = {
  shopId: "",
  name: "",
  phoneNumber: "010",
};

const initialState: CustomerState = {
  customer: undefined,
  newCustomer: undefined,
  createCustomerInput: defaultCreateCustomerInput,
};

const customerAPI = new CustomerAPI();

const fetchCustomerById = createAsyncThunk(
  "customers/fetchCustomerById",
  async (id: string) => {
    return customerAPI.get(id);
  }
);

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setNewCustomer: (state, action: PayloadAction<Customer>) => {
      state.newCustomer = action.payload;
    },
    emptyNewCustomer: (state) => {
      state.newCustomer = undefined;
    },
    setCreateCustomerInput: (
      state,
      action: PayloadAction<CreateCustomerInput>
    ) => {
      state.createCustomerInput = action.payload;
    },
    emptyCreateCustomerInput: (state) => {
      state.createCustomerInput = defaultCreateCustomerInput;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomerById.fulfilled, (state, { payload }) => {
      state.customer = payload;
    });
  },
});

export const selectCustomer = (state: RootState) => state.customer.customer;

export const selectNewCustomer = (state: RootState) =>
  state.customer.newCustomer;

export const selectCreateCustomerInput = (state: RootState) =>
  state.customer.createCustomerInput;

export const {
  setNewCustomer,
  emptyNewCustomer,
  setCreateCustomerInput,
  emptyCreateCustomerInput,
} = customerSlice.actions;

export { fetchCustomerById };

export default customerSlice.reducer;
