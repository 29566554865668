import axios from "axios";

export default class BackendAPI {
  private readonly isProduction = process.env.REACT_APP_STAGE === "prod";

  private readonly baseUrl = this.isProduction
    ? "https://n3oq1x0ylf.execute-api.ap-northeast-2.amazonaws.com/prod"
    : "https://qxw2yc8z0f.execute-api.ap-northeast-2.amazonaws.com/staging";

  async syncDesignerRecord(userId: string) {
    try {
      const url = this.baseUrl + "/sync-designer-record";

      await axios.post(url, {
        userId,
      });
    } catch (error) {}
  }

  async syncNaverOptions(shopId: string, designerId?: string) {
    const url = this.baseUrl + "/sync-naver-options";

    await axios.post(url, {
      shopId,
      designerId,
    });
  }

  async newShopUserApplication(id: string) {
    const url = this.baseUrl + "/new-shop-user-application";

    await axios.post(url, {
      id,
    });
  }

  async notifyNewShopRegistration(id: string) {
    const url = this.baseUrl + "/notify-new-shop-registration";

    await axios.post(url, {
      id,
    });
  }

  async sendConsultationSolution(consultationId: string, force = false) {
    const url = this.baseUrl + "/send-consultation-solution";

    await axios.post(url, {
      consultationId,
      force,
    });
  }

  openOwnerWebsite() {
    const hostName = this.isProduction
      ? "owner.nanalogapp.co"
      : "dev-owner.nanalogapp.co";

    window.open("https://" + hostName, "_blank");
  }
}
