import { Row, Col, Button } from "react-bootstrap";
import consultationConstants from "../../../../app/constants/consultation";
import CategorySubTitle from "../CategorySubTitle";
import ActiveIcon from "../ActiveIcon";
import styles from "./index.module.scss";
import { useAppSelector } from "../../../../app/hooks";
import { selectStudyMode } from "../../../../features/consultation";
import VideoButton from "../VideoButton";
import RecommendationItemName from "../RecommendationItemName";

export default function MultiSelectionFromCategorizedStringArray({
  constants,
  inputArray,
  inputFieldName,
  onClick,
  recommendations,
}: {
  constants: {
    name: string;
    items: string[];
    video?: { [key: string]: string };
  }[];
  inputArray: string[];
  inputFieldName: string;
  onClick: any;
  recommendations?: string[];
}) {
  const studyMode = useAppSelector(selectStudyMode);

  const alreadyShownCategory = new Set();

  const isHairColor = inputFieldName.includes("color");

  return (
    <Row>
      {constants.map((category, index) => {
        const categoryName = alreadyShownCategory.has(category.name)
          ? ""
          : category.name;
        alreadyShownCategory.add(category.name);

        let finalCategoryName = categoryName;
        if (categoryName === "버진헤어 채도") {
          finalCategoryName = "(버진)헤어 채도";
        }

        if (isHairColor) {
          let xs = 6;
          let md = 4;

          return (
            <Col
              key={category.name + index}
              xs={xs}
              md={md}
              className={styles.Box}
            >
              {alreadyShownCategory.size === 1 &&
              alreadyShownCategory.has("") ? (
                <></>
              ) : (
                <CategorySubTitle title={finalCategoryName} />
              )}
              {category.items.map((item) => {
                let backgroundColor = "";

                const key = category.name ? category.name + "_" + item : item;

                backgroundColor = (consultationConstants.hairColorHash as any)[
                  key
                ];

                const active = inputArray?.includes(key);

                return (
                  <div className="d-grid position-relative" key={item}>
                    {active && <ActiveIcon />}
                    <Button
                      variant={
                        active
                          ? "active-of-custom-color"
                          : "inactive-of-custom-color"
                      }
                      style={{
                        ...(backgroundColor && {
                          background: backgroundColor,
                        }),
                        border: active ? "2px solid #000000" : "",
                      }}
                      onClick={() => {
                        const selectedValue = category.name
                          ? category.name + "_" + item
                          : item;
                        onClick(selectedValue, inputArray, inputFieldName);
                      }}
                    >
                      {item}
                    </Button>
                  </div>
                );
              })}
            </Col>
          );
        } else {
          let xs = 12;
          let md = 6;
          return (
            <Col
              key={category.name + index}
              xs={xs}
              md={md}
              className={styles.Box}
            >
              {alreadyShownCategory.size === 1 &&
              alreadyShownCategory.has("") ? (
                <></>
              ) : (
                <CategorySubTitle title={finalCategoryName} />
              )}
              <Row>
                {category.items.map((item) => {
                  return (
                    <Col key={item} xs={6} className="d-grid">
                      <Button
                        variant={
                          inputArray?.includes(item) ? "active" : "inactive"
                        }
                        onClick={() => {
                          onClick(item, inputArray, inputFieldName);
                        }}
                      >
                        <RecommendationItemName
                          recommendations={recommendations}
                          item={item}
                        />
                      </Button>
                      {studyMode && category.video && category.video[item] && (
                        <VideoButton category={category} item={item} />
                      )}
                    </Col>
                  );
                })}
              </Row>
            </Col>
          );
        }
      })}
    </Row>
  );
}
