import { useState } from "react";
import { CloseButton } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import {
  selectShowImageDetailModal,
  setImageDetailModal,
} from "../../../features/common";
import styles from "./index.module.scss";

export default function ImageDetailModal() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const dispatch = useDispatch();
  const show = useAppSelector(selectShowImageDetailModal);
  const imageList = [
    "https://cdn.pixabay.com/photo/2016/12/27/17/10/pendulum-1934311_1280.jpg",
    "https://cdn.pixabay.com/photo/2022/08/26/13/15/man-7412527_1280.png",
  ];
  const handleClose = () => dispatch(setImageDetailModal(false));
  return (
    // show -> 퍼블리싱 끝 난 뒤 수정 예정
    <Modal fullscreen={"xxl-down"} size="xl" show={false} onHide={handleClose}>
      <Modal.Header className={styles.ModalHeader}>
        <span>고객님께서 이번 시술에서 원하는 스타일</span>
        <button onClick={handleClose} className={styles.ModalCloseButton}>
          닫기
        </button>
      </Modal.Header>
      <Modal.Body style={{ padding: 0 }}>
        <Carousel
          className={styles.CarouselBody}
          interval={null}
          indicators={false}
          nextIcon={null}
          prevIcon={null}
          prevLabel={null}
          nextLabel={null}
          controls={false}
          onSelect={handleSelect}
        >
          {imageList &&
            imageList.length !== 0 &&
            imageList.map((imageURL: string) => (
              <Carousel.Item>
                <img className="d-block w-100 img-fluid" src={imageURL} />
              </Carousel.Item>
            ))}
        </Carousel>
        <div className={styles.CarouselFooter}>
          <svg
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 6.96165C0 7.22911 0.102262 7.45723 0.314651 7.66175L6.45034 13.6637C6.61554 13.8368 6.83579 13.9233 7.08751 13.9233C7.59882 13.9233 8 13.53 8 13.0108C8 12.7591 7.89774 12.531 7.72468 12.3579L2.19469 6.96165L7.72468 1.56539C7.89774 1.38446 8 1.15634 8 0.904621C8 0.393314 7.59882 0 7.08751 0C6.83579 0 6.61554 0.086529 6.45034 0.259587L0.314651 6.26155C0.102262 6.46608 0.00786627 6.6942 0 6.96165Z"
              fill="black"
            />
          </svg>
          <div className={styles.CarouselActiveNumber}>
            <span>{index + 1}</span>
            <span>/{imageList.length}</span>
          </div>
          <svg
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 6.9612C8 6.69375 7.89774 6.46562 7.68535 6.2611L1.54966 0.259134C1.38447 0.0860762 1.16421 -0.000452639 0.912489 -0.000452661C0.401181 -0.000452706 1.18283e-06 0.392861 1.13744e-06 0.912034C1.11544e-06 1.16376 0.102263 1.39188 0.275321 1.56494L5.80531 6.9612L0.27532 12.3575C0.102262 12.5384 1.01091e-07 12.7665 7.90845e-08 13.0182C3.43846e-08 13.5295 0.40118 13.9229 0.912488 13.9229C1.16421 13.9229 1.38446 13.8363 1.54966 13.6633L7.68535 7.6613C7.89774 7.45677 7.99213 7.22865 8 6.9612Z"
              fill="black"
            />
          </svg>
        </div>
      </Modal.Body>
    </Modal>
  );
}
