import { API, graphqlOperation } from "aws-amplify";
import dayjs from "dayjs";
import {
  Consultation,
  ConsultationByBookingIdQuery,
  ConsultationByDesignerIdQueryVariables,
  ConsultationByShopIdQueryVariables,
  ConsultationStatus,
  CreateConsultationInput,
  CreateConsultationMutationVariables,
  InvokeNaverOpenAPIInput,
  ModelSortDirection,
  UpdateConsultationInput,
} from "../../graphql/API";
import {
  createConsultation,
  updateConsultation,
} from "../../graphql/mutations";
import {
  consultationByBookingId,
  consultationByDesignerId,
  consultationByShopId,
  getConsultation,
} from "../../graphql/queries";

export default class ConsultationAPI {
  async create(input: CreateConsultationInput) {
    const variables: CreateConsultationMutationVariables = {
      input: {
        ...input,
        date: dayjs().format("YYYY-MM-DD"),
        yearMonth: dayjs().format("YYYYMM"),
      },
    };

    return API.graphql({
      query: createConsultation,
      variables,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }

  async get(id: string) {
    const response: any = await API.graphql(
      graphqlOperation(getConsultation, {
        id,
      })
    );
    return response.data.getConsultation;
  }

  async getByBookingId(
    bookingId: string
  ): Promise<Consultation | undefined | null> {
    const response: {
      data: ConsultationByBookingIdQuery;
    } = (await API.graphql(
      graphqlOperation(consultationByBookingId, {
        bookingId,
      })
    )) as any;

    const items = response.data.consultationByBookingId?.items;

    if (items?.length) {
      return items[0];
    } else {
      return undefined;
    }
  }

  async listByShopId(shopId: string) {
    const variables: ConsultationByShopIdQueryVariables = {
      shopId,
      filter: {
        status: {
          ne: ConsultationStatus.NOT_STARTED,
        },
      },
      sortDirection: ModelSortDirection.DESC,
    };
    const response: any = await API.graphql(
      graphqlOperation(consultationByShopId, variables)
    );

    const data = response.data.consultationByShopId.items;

    return data;
  }

  async listByShopIdByMonth(shopId: string, yearMonth: string) {
    const from = dayjs(yearMonth).startOf("month").format("YYYY-MM-DD");
    const to = dayjs(yearMonth).endOf("month").format("YYYY-MM-DD");

    const variables: ConsultationByShopIdQueryVariables = {
      shopId,
      date: {
        between: [from, to],
      },
      filter: {
        status: {
          ne: ConsultationStatus.NOT_STARTED,
        },
      },
      sortDirection: ModelSortDirection.DESC,
    };
    const response: any = await API.graphql(
      graphqlOperation(consultationByShopId, variables)
    );

    const data = response.data.consultationByShopId.items;

    return data;
  }

  async listByDesignerIdThisMonth(designerId: string): Promise<Consultation[]> {
    const from = dayjs().startOf("month").format("YYYY-MM-DD");
    const to = dayjs().endOf("month").format("YYYY-MM-DD");

    const variables: ConsultationByDesignerIdQueryVariables = {
      designerId,
      date: {
        between: [from, to],
      },
      limit: 1000,
    };

    const response: any = await API.graphql(
      graphqlOperation(consultationByDesignerId, variables)
    );
    return response.data.consultationByDesignerId.items.filter(
      (item: Consultation) => !item._deleted
    );
  }

  async update(input: UpdateConsultationInput) {
    if (!input._version) {
      throw new Error("Missing _version");
    }
    return API.graphql({
      query: updateConsultation,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }

  async invokeNaverOpenAPI(input: InvokeNaverOpenAPIInput) {
    const invokeNaverOpenAPIMutation = /* GraphQL */ `
      mutation InvokeNaverOpenAPI($input: InvokeNaverOpenAPIInput!) {
        invokeNaverOpenAPI(input: $input) {
          success
          reason
        }
      }
    `;

    return API.graphql(
      graphqlOperation(invokeNaverOpenAPIMutation, {
        input,
      })
    );
  }
}
