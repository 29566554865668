import { API, graphqlOperation } from "aws-amplify";
import dayjs from "dayjs";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { Badge, Col, Form, Row, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import BookingAPI from "../../../api/booking";
import { useAppSelector } from "../../../app/hooks";
import {
  fetchBookingById,
  refreshBookings,
  selectBooking,
} from "../../../features/booking";
import {
  selectShowPaymentModal,
  setShowPaymentModal,
} from "../../../features/common";

import {
  Booking,
  BookingOrigin,
  BookingStatus,
  NaverPaymentMoment,
  NaverPaymentStatus,
  PayBookingInput,
  PaymentStatus,
} from "../../../graphql/API";

export default function PaymentModal() {
  const dispatch = useDispatch();

  const booking = useAppSelector(selectBooking);
  const show = useAppSelector(selectShowPaymentModal);

  const handleClose = () => dispatch(setShowPaymentModal(false));

  const [bookingOnEdit, setBookingOnEdit] = useState<Booking>();

  const bookingAPI = new BookingAPI();

  useEffect(() => {
    if (!show) {
      return;
    }

    if (!booking) {
      return;
    }

    if (booking.paymentStatus === PaymentStatus.PAID) {
      setBookingOnEdit({
        ...booking,
      });
    } else {
      const initialDeposit =
        booking.initialDeposit ??
        booking.naverPayments?.find(
          (item) =>
            item.moment === NaverPaymentMoment.PRE &&
            item.status === NaverPaymentStatus.COMPLETED
        )?.amount ??
        0;

      const paidByCardAmount = (booking.finalPrice ?? 0) - initialDeposit;

      setBookingOnEdit({
        ...booking,
        initialDeposit,
        paidByCardAmount,
      });
    }
  }, [booking, show]);

  async function handleSaveChanges() {
    if (!bookingOnEdit) {
      return;
    }

    if (!booking) {
      return;
    }

    const {
      paidByCardAmount,
      paidInCashAmount,
      paidByPrepaidPassAmount,
      paidByNaverPayAmount,
      initialDeposit,
      finalPrice,
      paymentMemo,
    } = bookingOnEdit;

    const sumAmount =
      (paidByCardAmount ?? 0) +
      (paidInCashAmount ?? 0) +
      (initialDeposit ?? 0) +
      (paidByPrepaidPassAmount ?? 0) +
      (paidByNaverPayAmount ?? 0);

    if (
      (booking.paidByPrepaidPassAmount ?? 0) > 0 &&
      (paidByPrepaidPassAmount ?? 0) !== (booking.paidByPrepaidPassAmount ?? 0)
    ) {
      toast.warn(
        "정액권을 사용한 경우 세부 결제 금액을 변경할 수 없습니다. IT 관리자에게 문의하세요."
      );
      return;
    }

    if (finalPrice !== sumAmount) {
      toast.warn("결제 수단별 합계와 서비스 총액이 맞지 않습니다.");
      return;
    }

    const input: PayBookingInput = {
      paidByCardAmount,
      paidInCashAmount,
      paidByPrepaidPassAmount,
      paymentMemo,
    };

    try {
      const payBooking = /* GraphQL */ `
        mutation PayBooking($id: ID!, $input: PayBookingInput!) {
          payBooking(id: $id, input: $input) {
            success
            reason
          }
        }
      `;

      await API.graphql(
        graphqlOperation(payBooking, {
          id: booking.id,
          input,
        })
      );

      if (
        booking.origin === BookingOrigin.MANUAL &&
        booking.bookingStatus !== BookingStatus.COMPLETED
      ) {
        bookingAPI.updateBooking({
          id: booking.id,
          bookingStatus: BookingStatus.COMPLETED,
          _version: booking._version,
        });
      }

      toast.success(
        `${booking.customer?.name || ""} 고객님의 결제 내역을 저장했습니다.`
      );

      dispatch(fetchBookingById(booking.id) as any);

      dispatch(refreshBookings() as any);
    } catch (response: any) {
      toast.error(response.errors[0].message);
    }
  }

  if (!booking || !bookingOnEdit) {
    return <></>;
  }

  return (
    <>
      <Modal
        fullscreen={isMobile ? true : undefined}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {booking.customer?.name || ""} 고객님 | {booking.designerName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    예약 일시
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      plaintext
                      readOnly
                      value={`${bookingOnEdit.date} ${bookingOnEdit.time}`}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    체크인
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      plaintext
                      readOnly
                      value={`${
                        bookingOnEdit.checkedInAt
                          ? dayjs(bookingOnEdit.checkedInAt).format("HH:mm")
                          : "-"
                      }`}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table>
                  <tbody>
                    {(booking.options ?? []).map((option, index) => {
                      return (
                        <tr key={"S" + index}>
                          <td>{option.categoryName}</td>
                          <td>{option.name}</td>
                          <td>{numeral(option.price).format("0,0")}</td>
                          <td>
                            {option.discount ? `${option.discount} 할인` : null}
                          </td>
                        </tr>
                      );
                    })}
                    {(booking.productOptions ?? []).map((option, index) => {
                      return (
                        <tr key={"P" + index}>
                          <td>{option.product?.brandName}</td>
                          <td>
                            {option.name} * {option.quantity}
                          </td>
                          <td>{numeral(option.price).format("0,0")}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>합계</td>
                      <td></td>
                      <td>
                        <strong>
                          {numeral(booking.finalPrice).format("0,0")}
                        </strong>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table>
                  <tbody>
                    <tr>
                      <th>예약금</th>
                      <td>
                        <Form.Control
                          readOnly
                          value={bookingOnEdit.initialDeposit ?? 0}
                        />
                      </td>
                      <th>N 페이</th>
                      <td>
                        <Form.Control
                          value={bookingOnEdit.paidByNaverPayAmount ?? 0}
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>카드</th>
                      <td>
                        <Form.Control
                          value={bookingOnEdit.paidByCardAmount ?? 0}
                          onChange={(event) => {
                            setBookingOnEdit({
                              ...bookingOnEdit,
                              paidByCardAmount: Number(event.target.value),
                            });
                          }}
                        />
                      </td>
                      <th>현금</th>
                      <td>
                        <Form.Control
                          value={bookingOnEdit.paidInCashAmount ?? 0}
                          onChange={(event) => {
                            setBookingOnEdit({
                              ...bookingOnEdit,
                              paidInCashAmount: Number(event.target.value),
                            });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>정액권</th>
                      <td>
                        <Form.Control
                          value={bookingOnEdit.paidByPrepaidPassAmount ?? 0}
                          onChange={(event) => {
                            setBookingOnEdit({
                              ...bookingOnEdit,
                              paidByPrepaidPassAmount: Number(
                                event.target.value
                              ),
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <Form.Group className="mb-3" controlId="modalForm.memo">
                  <Form.Label>결제 메모</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    value={bookingOnEdit.paymentMemo ?? ""}
                    placeholder="결제 특이사항이 있을 때 남겨 주세요."
                    onChange={(event) => {
                      setBookingOnEdit({
                        ...bookingOnEdit,
                        paymentMemo: event.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            닫기
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            매출 등록
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
