import Default from "./Default";
import HairColor from "./HairColor";
import "./index.module.scss";

export default function SingleSelectionFromCategorizedStringArray({
  constants,
  inputValue,
  inputFieldName,
  onClick,
  gender,
  recommendations,
}: {
  constants: {
    name: string;
    items: string[];
    video?: any;
  }[];
  inputValue: string;
  inputFieldName: string;
  onClick: any;
  gender?: "female" | "male" | null;
  recommendations?: string[];
}) {
  const isHairColor = [
    "virginHairColor",
    "hairColor",
    "hairColorType",
  ].includes(inputFieldName);

  return isHairColor ? (
    <HairColor
      constants={constants}
      inputValue={inputValue}
      inputFieldName={inputFieldName}
      onClick={onClick}
    />
  ) : (
    <Default
      constants={constants}
      inputValue={inputValue}
      inputFieldName={inputFieldName}
      onClick={onClick}
      gender={gender}
      recommendations={recommendations}
    />
  );
}
