import { API, graphqlOperation } from "aws-amplify";
import dayjs from "dayjs";
import {
  Feedback,
  FeedbackByDesignerIdQueryVariables,
  ModelSortDirection,
} from "../../graphql/API";

import { feedbackByDesignerId } from "../../graphql/queries";

export default class FeedbackAPI {
  async listByDesignerId(designerId: string): Promise<Feedback[]> {
    const variables: FeedbackByDesignerIdQueryVariables = {
      designerId,
      sortDirection: ModelSortDirection.DESC,
    };
    const response: any = await API.graphql(
      graphqlOperation(feedbackByDesignerId, variables)
    );

    const data = response.data.feedbackByDesignerId.items;

    return data;
  }

  async listByDesignerIdThisMonth(designerId: string): Promise<Feedback[]> {
    const from = dayjs().startOf("month").format("YYYY-MM-DD");
    const to = dayjs().endOf("month").format("YYYY-MM-DD");
    const variables: FeedbackByDesignerIdQueryVariables = {
      designerId,
      date: {
        between: [from, to],
      },
    };
    const response: any = await API.graphql(
      graphqlOperation(feedbackByDesignerId, variables)
    );

    const data = response.data.feedbackByDesignerId.items;

    return data;
  }
}
