import { API } from "aws-amplify";
import { CreateBrandInput, DeleteBrandInput } from "../../graphql/API";
import { createBrand, deleteBrand } from "../../graphql/mutations";
import { brandByShopId } from "../../graphql/queries";

export default class BrandAPI {
  async listBrand(shopId: string) {
    const response: any = await API.graphql({
      query: brandByShopId,
      variables: { shopId },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return response.data.brandByShopId.items;
  }

  async createBrand(input: CreateBrandInput) {
    return API.graphql({
      query: createBrand,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }

  async deleteBrand(input: DeleteBrandInput) {
    return API.graphql({
      query: deleteBrand,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }
}
