import { API, graphqlOperation } from "aws-amplify";
import { CreateShopInput, Shop, UpdateShopInput } from "../../graphql/API";
import { createShop } from "../../graphql/mutations";
import { shopByName } from "../../graphql/queries";

const getShop = /* GraphQL */ `
  query GetShop($id: ID!) {
    getShop(id: $id) {
      id
      name
      naverBusinessId
      hasLocker
      bookingQRS3Key
      usePreSelfDiagnosis
      useFeedbackRequest
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

const updateShop = /* GraphQL */ `
  mutation UpdateShop(
    $input: UpdateShopInput!
    $condition: ModelShopConditionInput
  ) {
    updateShop(input: $input, condition: $condition) {
      id
      name
      naverBusinessId
      hasLocker
      bookingQRS3Key
      creatorId
      usePreSelfDiagnosis
      useFeedbackRequest
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export default class ShopAPI {
  async get(id: string) {
    const response: any = await API.graphql(
      graphqlOperation(getShop, {
        id,
      })
    );

    return response.data.getShop;
  }
  async getShopByName(name: string) {
    const response: any = await API.graphql(
      graphqlOperation(shopByName, {
        name,
      })
    );

    if (response.data.shopByName.items.length === 0) {
      return undefined;
    }

    return response.data.shopByName.items[0];
  }
  async create(input: CreateShopInput) {
    if (!input.creatorId) {
      throw new Error("Missing creatorId");
    }
    const result: any = await API.graphql({
      query: createShop,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    return result.data.createShop;
  }
  async update(input: UpdateShopInput) {
    if (!input._version) {
      throw new Error("Missing _version");
    }
    return API.graphql({
      query: updateShop,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }
}
