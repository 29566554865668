import { Table } from "react-bootstrap";

export default function Privacy() {
  return (
    <div style={{ wordBreak: "keep-all" }}>
      <p>
        주식회사 퓨처뷰티(이하 ‘회사’라 한다)는 개인정보 보호법 제30조에 따라
        정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게
        처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을
        수립·공개합니다.
      </p>
      <h5>제1조(개인정보의 처리목적)</h5>
      회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
      개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이
      변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
      <ol>
        <li>
          홈페이지 회원 가입 및 관리 회원 가입의사 확인, 회원자격 유지․관리,
          서비스 부정이용 방지 및 각종 고지․통지, 고충처리 등을 목적으로
          개인정보를 처리합니다.
        </li>
        <li>
          서비스 제공 컨설테이션 솔루션, CRM 기능, ERP 기능 서비스 제공 등을
          목적으로 개인정보를 처리합니다.
        </li>
        <li>
          민원처리 민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락․통지,
          처리결과 통보 등의 목적으로 개인정보를 처리합니다.
        </li>
      </ol>
      <h5>제2조(개인정보의 처리 및 보유기간)</h5>
      <p>
        ① 회사는 법령에 따른 개인정보 보유․이용기간 또는 정보주체로부터
        개인정보를 수집시에 동의받은 개인정보 보유․이용기간 내에서 개인정보를
        처리․보유합니다.
        <br />② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
      </p>
      <h6>
        1. 홈페이지 회원 가입 및 관리: 홈페이지 탈퇴시까지 다만, 다음의 사유에
        해당하는 경우에는 해당 사유 종료시까지
      </h6>
      <ol>
        <li>
          관계 법령 위반에 따른 수사․조사 등이 진행중인 경우에는 해당 수사․조사
          종료시까지
        </li>
        <li>
          홈페이지 이용에 따른 채권․채무관계 잔존시에는 해당 채권․채무관계
          정산시까지
        </li>
      </ol>
      <h6>
        2. 서비스 제공: 서비스 제공 완료시까지 다만, 다음의 사유에 해당하는
        경우에는 해당 기간 종료시까지
      </h6>
      <ol>
        <li>
          「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시․광고,
          계약내용 및 이행 등 거래에 관한 기록
          <ul>
            <li>표시․광고에 관한 기록 : 6개월</li>
            <li>계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년</li>
            <li>소비자 불만 또는 분쟁처리에 관한 기록 : 3년</li>
          </ul>
        </li>
        <li>
          「통신비밀보호법」제41조에 따른 통신사실확인자료 보관
          <ul>
            <li>
              가입자 전기통신일시, 개시․종료시간, 상대방 가입자번호, 사용도수,
              발신기지국 위치추적자료 : 1년
            </li>
            <li>컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월</li>
          </ul>
        </li>
      </ol>
      <h5>제3조(개인정보의 제3자 제공)</h5>
      <p>
        회사는 원칙적으로 정보주체의 동의 없이 개인정보를 제3자에게 제공하지
        않으며, 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만
        처리하고, 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법
        제17조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
      </p>
      <h5>제4조(개인정보처리의 위탁)</h5>
      <ol>
        <li>
          회사는 원활한 개인정보 업무처리를 위하여 개인정보 처리업무를 제3자에게
          개인정보 처리업무를 위탁할 수 있습니다.
        </li>
        <li>
          회사는 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적
          외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에
          대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에
          명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
        </li>
        <li>
          위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보
          처리방침을 통하여 공개하도록 하겠습니다.
          <Table style={{ marginTop: 10 }}>
            <thead>
              <tr>
                <th>수탁업체</th>
                <th>위탁업무 내용</th>
                <th>개인정보의 보유 및 이용기간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>아마존웹서비시즈코리아(유)</td>
                <td>온라인 인프라 관리</td>
                <td rowSpan={2} className="align-middle">
                  회원 탈퇴시 혹은 위탁 계약 종료시까지
                </td>
              </tr>
              <tr>
                <td>네이버클라우드 주식회사</td>
                <td>알림톡 및 LMS/SMS 발송</td>
              </tr>
            </tbody>
          </Table>
        </li>
      </ol>
      <h5>제5조(정보주체의 권리․의무 및 행사방법)</h5>
      <ol>
        <li>
          정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를
          행사할 수 있습니다.
          <ol>
            <li>개인정보 열람요구</li>
            <li>오류 등이 있을 경우 정정 요구</li>
            <li>삭제요구</li>
            <li>처리정지 요구</li>
          </ol>
        </li>
        <li>
          제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편,
          모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체없이
          조치하겠습니다.
        </li>
        <li>
          정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는
          회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나
          제공하지 않습니다.
        </li>
        <li>
          제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등
          대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙
          별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
        </li>
        <li>
          정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가 처리하고 있는
          정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.
        </li>
      </ol>
      <h5>제6조(처리하는 개인정보 항목)</h5>
      <p>회사는 다음의 개인정보 항목을 처리하고 있습니다.</p>
      <ol>
        <li>
          회원 가입 및 관리
          <ul>
            <li>
              고객 회원
              <ul>
                <li>아이디, 비밀번호, 전화번호, 이메일</li>
                <li>거래 내역, 결제정보(회원권 및 멤버십 내역)</li>
                <li>필요 시 - 성명, 생년월일, 주소, 나이</li>
              </ul>
            </li>
            <li>
              사업자 회원
              <ul>
                <li>아이디, 비밀번호, 전화번호</li>
                <li>필요 시 - 사업자 명의</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          서비스 제공
          <ul>
            <li>시술 관련 정보: 예약 시간, 시술 항목 등 관련 기록</li>
            <li>고객 시술 전후 사진(얼굴분석용 사진 포함)</li>
            <li>두피 및 모발 사진</li>
            <li>컨설테이션 진행에 따른 진단 결과</li>
            <li>시술 관련 모발 두피, 두상 상태</li>
          </ul>
        </li>
        <li>
          결제 및 정산
          <ul>
            <li>거래내역, 결제정보, 은행 계좌번호</li>
          </ul>
        </li>
        <li>
          인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어
          수집될 수 있습니다.
          <ul>
            <li>
              IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등
            </li>
          </ul>
        </li>
      </ol>
      <h5>제7조(개인정보의 파기)</h5>
      <ol>
        <li>
          회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
          불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
        </li>
        <li>
          정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이
          달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야
          하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나
          보관장소를 달리하여 보존합니다.
        </li>
        <li>
          개인정보 파기의 절차 및 방법은 다음과 같습니다.
          <ol>
            <li>
              파기절차 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의
              개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
            </li>
            <li>
              파기방법 회사는 전자적 파일 형태로 기록․저장된 개인정보는 기록을
              재생할 수 없도록 로우레밸포멧(Low Level Format) 등의 방법을
              이용하여 파기하며, 종이 문서에 기록․저장된 개인정보는 분쇄기로
              분쇄하거나 소각하여 파기합니다.
            </li>
          </ol>
        </li>
      </ol>
      <h5>제8조(개인정보의 안전성 확보조치)</h5>
      회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
      <ol>
        <li>관리적 조치 : 내부관리계획 수립․시행, 정기적 직원 교육 등</li>
        <li>
          기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템
          설치, 고유식별정보 등의 암호화, 보안프로그램 설치
        </li>
        <li>물리적 조치 : 전산실, 자료보관실 등의 접근통제</li>
      </ol>
      <h5>제9조(개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)</h5>
      <ol>
        <li>
          회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를
          저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
        </li>
        <li>
          쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터
          브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의
          하드디스크에 저장되기도 합니다.
          <ul>
            <li>
              가. 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에
              대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여
              이용자에게 최적화된 정보 제공을 위해 사용됩니다.
            </li>
            <li>
              나. 쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구&gt;인터넷
              옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수
              있습니다.
            </li>
            <li>
              다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할
              수 있습니다.
            </li>
          </ul>
        </li>
      </ol>
      <h5>제10조(개인정보 보호책임자)</h5>
      <ol>
        <li>
          회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와
          관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이
          개인정보 보호책임자를 지정하고 있습니다.
          <ul>
            <li>
              개인정보 보호책임자
              <ul>
                <li>성명 : 김지민</li>
                <li>직책 : 최고기술책임자</li>
                <li>
                  연락처 : jimin.kim@futurebeauty.co
                  <p>※ 개인정보 보호 담당부서로 연결됩니다.</p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든
          개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보
          보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의
          문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
        </li>
      </ol>
      <h5>제11조(개인정보 열람청구)</h5>
      <p>
        정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의
        부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게
        처리되도록 노력하겠습니다.
        <ul>
          <li>
            개인정보 열람청구 접수․처리 부서
            <ul>
              <li>부서명 : 경영지원</li>
              <li>담당자 : 조재한</li>
              <li>연락처 : jaehan.cho@futurebeauty.co</li>
            </ul>
          </li>
        </ul>
      </p>
      <h5>제12조(권익침해 구제방법)</h5>
      <p>
        정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을
        문의하실 수 있습니다.
      </p>
      <p>
        &lt;아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보
        불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이
        필요하시면 문의하여 주시기 바랍니다&gt;
        <ul>
          <li>
            개인정보 침해신고센터 (한국인터넷진흥원 운영)
            <ul>
              <li>소관업무 : 개인정보 침해사실 신고, 상담 신청</li>
              <li>홈페이지 : privacy.kisa.or.kr</li>
              <li>전화 : (국번없이) 118</li>
              <li>
                주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층
                개인정보침해신고센터
              </li>
            </ul>
          </li>
          <li>
            개인정보 분쟁조정위원회
            <ul>
              <li>
                소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
              </li>
              <li>홈페이지 : www.kopico.go.kr</li>
              <li>전화 : (국번없이) 1833-6972</li>
              <li>
                주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층
              </li>
            </ul>
          </li>
          <li>대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)</li>
          <li>경찰청 사이버안전국 : 182 (http://cyberbureau.police.go.kr)</li>
        </ul>
      </p>
    </div>
  );
}
