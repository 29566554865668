import { Button, Col, Row } from "react-bootstrap";
import styles from "./index.module.scss";

export default function SingleSelectionFromStringArray({
  constants,
  inputValue,
  inputFieldName,
  onClick,
  translationTable,
}: {
  constants: string[];
  inputValue: string;
  inputFieldName: string;
  onClick: any;
  translationTable?: { [key: string]: string };
}) {
  let md = 4;

  if (constants.length === 4) {
    md = 3;
  }

  let xs = 6;

  if (constants.length === 3) {
    xs = 4;
  }

  return (
    <Row>
      {constants.map((item: any) => (
        <Col md={md} xs={xs} key={item} className="d-grid">
          <Button
            variant={inputValue === item ? "active" : "inactive"}
            onClick={(event) => {
              if (inputFieldName === "hairCare") {
                onClick(item, undefined, inputFieldName);
              } else {
                onClick(item, inputValue, inputFieldName);
              }
              event.currentTarget.blur();
            }}
          >
            {translationTable ? translationTable[item] : item}
          </Button>
        </Col>
      ))}
    </Row>
  );
}
