import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentShopId } from "../../features/common";
import { fetchBrandsByShopId, selectBrands } from "../../features/brand";
import { CreateBrandInput } from "../../graphql/API";
import BrandRow from "./Row";
import BrandAPI from "../../api/brand";

export default function Brand() {
  const currentShopId = useAppSelector(selectCurrentShopId);
  const dispatch = useDispatch();
  const brands = useAppSelector(selectBrands);

  const brandAPI = new BrandAPI();

  const defaultInput = () => {
    return {
      name: "",
      shopId: currentShopId ?? "",
    };
  };

  const [input, setInput] = useState<CreateBrandInput>(defaultInput());

  useEffect(() => {
    if (!currentShopId) {
      return;
    }

    dispatch(fetchBrandsByShopId(currentShopId) as any);
  }, [currentShopId]);

  function validate() {
    if (!input) {
      return false;
    }

    const errors: string[] = [];

    const { name } = input;

    if (!name) {
      errors.push(`이름은 필수 항목입니다.`);
    }

    errors.forEach((error) => {
      toast.error(error);
    });

    return errors.length === 0;
  }

  async function createRecord() {
    if (!validate()) {
      return;
    }

    await brandAPI.createBrand({
      ...input,
      shopId: currentShopId!,
    });

    dispatch(fetchBrandsByShopId(currentShopId!) as any);

    setInput(defaultInput());
  }

  async function deleteRecord(id: string, _version: number) {
    if (!confirm("삭제하시겠습니까?")) {
      return;
    }

    await brandAPI.deleteBrand({ id, _version });

    dispatch(fetchBrandsByShopId(currentShopId!) as any);
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h4>브랜드 설정</h4>
            <hr />
            <Table>
              <thead>
                <tr>
                  <th>브랜드 이름</th>
                  <th>등록일</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {brands.map((item) => {
                  return (
                    <BrandRow
                      key={item.id}
                      item={item}
                      deleteRecord={deleteRecord}
                    />
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                이름
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  value={input?.name}
                  onChange={(event) => {
                    setInput({
                      ...input,
                      name: event.target.value,
                    });
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Button size="sm" onClick={createRecord}>
              등록
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
