import { API, graphqlOperation } from "aws-amplify";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Badge, Col, Container, Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { addDay, selectDate, subtractDay } from "../../features/booking";
import { selectCurrentShopId } from "../../features/common";
import { Booking, BookingStatus, ServiceStatus } from "../../graphql/API";

const bookingByShopId = /* GraphQL */ `
  query BookingByShopId(
    $shopId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByShopId(
      shopId: $shopId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        time
        customer {
          name
        }
        customerId
        designerName
        optionName
        shopId
        naverBookingNumber
        requestMessage
        designerMemo
        createdAt
        checkedInAt
        drink
        checkInRequests
        serviceStatus
        bookingStatus
      }
      nextToken
      startedAt
    }
  }
`;

export default function SchedulePanel() {
  const dispatch = useDispatch();
  const currentShopId = useAppSelector(selectCurrentShopId);
  const date = useAppSelector(selectDate);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [timeSeries, setTimeSeries] = useState<string[]>([]);
  const [designerNames, setDesignerNames] = useState<string[]>([]);

  useEffect(() => {
    if (!currentShopId) {
      return;
    }

    let timeVariable = dayjs().set("hour", 9).set("minute", 0);

    const temp = [];
    for (let i = 0; i < 12; i += 1) {
      temp.push(timeVariable.format("HH:mm"));
      timeVariable = timeVariable.add(1, "hour");
    }

    setTimeSeries(temp);

    fetchBookings();
  }, [currentShopId, date]);

  async function fetchBookings() {
    const result: any = await API.graphql(
      graphqlOperation(bookingByShopId, {
        shopId: currentShopId,
        date: {
          eq: date,
        },
      })
    );

    let bookings = result.data.bookingByShopId.items;

    bookings.sort((a: Booking, b: Booking) => {
      return a.time.localeCompare(b.time);
    });

    setBookings(bookings);

    const designerNames: string[] = Array.from(
      new Set(
        bookings.map((booking: Booking) => {
          return booking.designerName?.split(" ")[0];
        })
      )
    );

    setDesignerNames(designerNames);
  }

  return (
    <>
      <Container>
        <Row>
          <Col sm={3}></Col>
          <Col sm={6}>
            <div className="text-center">
              <button
                className="btn btn-outline-primary btn-sm"
                onClick={() => {
                  dispatch(subtractDay() as any);
                }}
              >
                이전
              </button>{" "}
              <strong>{date}</strong>{" "}
              <button
                className="btn btn-outline-primary btn-sm"
                onClick={() => {
                  dispatch(addDay() as any);
                }}
              >
                다음
              </button>
            </div>
          </Col>
          <Col sm={3}></Col>
        </Row>
        <Row>
          <Col>
            <Table responsive>
              <colgroup>
                <col
                  width={`${Math.floor(100 / (designerNames.length + 1))}%`}
                ></col>
                {designerNames.map((item) => (
                  <col
                    key={item}
                    width={`${Math.floor(100 / (designerNames.length + 1))}%`}
                  ></col>
                ))}
              </colgroup>
              <thead>
                <tr>
                  <th>-</th>
                  {designerNames.map((designerName) => {
                    return <th key={designerName}>{designerName}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {timeSeries.map((time) => {
                  const timeMatchedBookings = bookings.filter(
                    (item) => item.time === time
                  );
                  return (
                    <tr key={time}>
                      <td>{time}</td>
                      {designerNames.map((designerName) => {
                        const designerBookings = timeMatchedBookings.filter(
                          (item) =>
                            item.designerName?.split(" ")[0] === designerName
                        );

                        return designerBookings.length ? (
                          <td key={`${time}${designerName}`}>
                            {designerBookings.map((designerBooking) => {
                              return (
                                <div key={designerBooking.id}>
                                  <strong
                                    style={
                                      designerBooking.bookingStatus ===
                                      BookingStatus.CANCELED
                                        ? {
                                            color: "gray",
                                            textDecoration: "line-through",
                                          }
                                        : {}
                                    }
                                  >
                                    {designerBooking.customer?.name}
                                  </strong>
                                  <br />

                                  {designerBooking?.optionName
                                    ?.split(",")
                                    .map((item) => {
                                      return (
                                        <div
                                          key={item}
                                          style={
                                            designerBooking.bookingStatus ===
                                            BookingStatus.CANCELED
                                              ? {
                                                  color: "gray",
                                                  textDecoration:
                                                    "line-through",
                                                }
                                              : {}
                                          }
                                        >
                                          {item}
                                        </div>
                                      );
                                    })}
                                  {designerBooking.bookingStatus ===
                                  BookingStatus.COMPLETED ? (
                                    <Badge bg="success">완료</Badge>
                                  ) : null}

                                  {designerBooking.bookingStatus ===
                                  BookingStatus.CONFIRMED ? (
                                    <Badge bg="primary">확정</Badge>
                                  ) : null}

                                  {designerBooking.serviceStatus ===
                                  ServiceStatus.IN_PROGRESS ? (
                                    <>
                                      <Badge bg={"info"}>시술 중</Badge>
                                    </>
                                  ) : null}
                                </div>
                              );
                            })}
                          </td>
                        ) : (
                          <td
                            key={`${time}${designerName}`}
                            style={{ background: "#f3f3f3" }}
                          ></td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}
