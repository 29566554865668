import numeral from "numeral";
import { Table } from "react-bootstrap";
import { Booking } from "../../../graphql/API";

export default function SalesStatisticsTable({
  bookings,
}: {
  bookings: Booking[];
}) {
  const init = {
    paymentSum: 0,
    paymentCount: 0,
  };

  const amountSumReduce = (
    accumulator: typeof init,
    currentValue: Booking,
    payment: keyof Booking
  ) => {
    return {
      paymentSum:
        accumulator.paymentSum +
        (currentValue && Number(currentValue[payment])
          ? Number(currentValue[payment])
          : 0),
      paymentCount:
        accumulator.paymentCount +
        (currentValue && currentValue[payment] ? 1 : 0),
    };
  };

  const paidByCardAmountSum = bookings.reduce(
    (accumulator, currentValue) =>
      amountSumReduce(accumulator, currentValue, "paidByCardAmount"),
    init
  );

  const paidInCashAmountSum = bookings.reduce(
    (accumulator, currentValue) =>
      amountSumReduce(accumulator, currentValue, "paidInCashAmount"),
    init
  );

  const paidByNaverPayAmountSum = bookings.reduce(
    (accumulator, currentValue) =>
      amountSumReduce(accumulator, currentValue, "paidByNaverPayAmount"),
    init
  );

  const initialDepositSum = bookings.reduce(
    (accumulator, currentValue) =>
      amountSumReduce(accumulator, currentValue, "initialDeposit"),
    init
  );

  // 성수점 등 예약금 10,000원이 있는 경우 네이버 결제에 합산해 준다.
  paidByNaverPayAmountSum.paymentSum += initialDepositSum.paymentSum;

  const paidByPrepaidPassAmountSum = bookings.reduce(
    (accumulator, currentValue) =>
      amountSumReduce(accumulator, currentValue, "paidByPrepaidPassAmount"),
    init
  );

  const allPaymentSum = {
    paySum:
      paidByCardAmountSum.paymentSum +
      paidByNaverPayAmountSum.paymentSum +
      paidInCashAmountSum.paymentSum +
      paidByPrepaidPassAmountSum.paymentSum,
    paymentCount:
      paidByCardAmountSum.paymentCount +
      paidByNaverPayAmountSum.paymentCount +
      paidInCashAmountSum.paymentCount +
      paidByPrepaidPassAmountSum.paymentCount,
    actualSum:
      paidByCardAmountSum.paymentSum +
      paidByNaverPayAmountSum.paymentSum +
      paidInCashAmountSum.paymentSum,
  };

  return (
    <Table striped responsive>
      <thead>
        <tr>
          <th colSpan={2}>합계</th>
          <th rowSpan={2}>실매출</th>
          <th colSpan={2}>카드</th>
          <th colSpan={2}>N Pay</th>
          <th colSpan={2}>현금</th>
          <th colSpan={2}>정액권</th>
        </tr>
        <tr>
          <td>금액</td>
          <td>건수</td>
          <td>금액</td>
          <td>건수</td>
          <td>금액</td>
          <td>건수</td>
          <td>금액</td>
          <td>건수</td>
          <td>금액</td>
          <td>건수</td>
        </tr>
      </thead>
      <tbody>
        <td className="text-end">
          {numeral(allPaymentSum.paySum ?? 0).format("0,0")}원
        </td>
        <td className="text-end">{allPaymentSum.paymentCount}건</td>
        <td className="text-end">
          {numeral(allPaymentSum.actualSum ?? 0).format("0,0")}원
        </td>
        <td className="text-end">
          {numeral(paidByCardAmountSum.paymentSum ?? 0).format("0,0")}원
        </td>
        <td className="text-end">{paidByCardAmountSum.paymentCount}건</td>
        <td className="text-end">
          {numeral(paidByNaverPayAmountSum.paymentSum ?? 0).format("0,0")}원
        </td>
        <td className="text-end">{paidByNaverPayAmountSum.paymentCount}건</td>
        <td className="text-end">
          {numeral(paidInCashAmountSum.paymentSum ?? 0).format("0,0")}원
        </td>
        <td className="text-end">{paidInCashAmountSum.paymentCount}건</td>
        <td className="text-end">
          {numeral(paidByPrepaidPassAmountSum.paymentSum ?? 0).format("0,0")}원
        </td>
        <td className="text-end">
          {paidByPrepaidPassAmountSum.paymentCount}건
        </td>
      </tbody>
    </Table>
  );
}
