import { API } from "aws-amplify";
import {
  CreateConsultationSolutionPresetInput,
  ConsultationSolutionPreset,
  UpdateConsultationSolutionPresetInput,
} from "../../graphql/API";
import {
  createConsultationSolutionPreset,
  updateConsultationSolutionPreset,
} from "../../graphql/mutations";
import { listConsultationSolutionPresets } from "../../graphql/queries";

export default class ConsultationSolutionPresetAPI {
  async list(): Promise<ConsultationSolutionPreset[]> {
    const response: any = await API.graphql({
      query: listConsultationSolutionPresets,
      variables: {},
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return response.data.listConsultationSolutionPresets.items.filter(
      (item: ConsultationSolutionPreset) => !item._deleted
    );
  }

  async create(input: CreateConsultationSolutionPresetInput) {
    return API.graphql({
      query: createConsultationSolutionPreset,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }

  async update(input: UpdateConsultationSolutionPresetInput) {
    return API.graphql({
      query: updateConsultationSolutionPreset,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }
}
