import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { isMobile } from "react-device-detect";
import SignatureCanvas from "react-signature-canvas";

import { Alert } from "react-bootstrap";
import { Booking } from "../../../graphql/API";
import { fetchBookingById } from "../../../features/booking";
import { useDispatch } from "react-redux";
import BookingAPI from "../../../api/booking";
import BookingService from "../../../services/booking";
import dayjs from "dayjs";
import { useAppSelector } from "../../../app/hooks";
import { selectCurrentShop } from "../../../features/common";

type Props = {
  show: boolean;
  setShow: any;
  booking: Booking;
};

export default function PortraitRightsSignatureModal({
  show,
  setShow,
  booking,
}: Props) {
  const dispatch = useDispatch();

  const currentShop = useAppSelector(selectCurrentShop);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const signatureRef = useRef<SignatureCanvas>();

  const bookingAPI = new BookingAPI();

  const bookingService = new BookingService(bookingAPI);

  useEffect(() => {}, []);

  async function updateSignature() {
    if (!signatureRef.current) {
      return;
    }

    const dataUrl = signatureRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png");

    await bookingService.updateBookingAsPortraitRightsSigned(
      booking.id,
      dataUrl,
      booking._version
    );

    dispatch(fetchBookingById(booking.id) as any);

    handleClose();
  }

  return (
    <>
      <Modal
        fullscreen={isMobile ? true : undefined}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>초상권 사용 동의서</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {currentShop?.name}에 헤어 시술로 방문하신 고객∙모델 본인의 동의
            아래 담당 디자이너가 시술 과정 혹은 결과에 대한 사진/동영상 촬영을
            진행하는 경우가 있을 수 있습니다.
          </p>
          <p>
            이에 따라 본인은 촬영한 본인의 초상사진저작물과 관련하여 다음 매체의
            개인∙관련 계정에 소개 될 수 있고 그 저작물을 사용하는 데에
            동의합니다.
          </p>
          <ul>
            <li>가. 헤어샵 홍보</li>
            <ul>
              <li>사용매체 : 사진/영상매체(sns), 뉴미디어, 홍보 인쇄물 등</li>
              <li>사용형식 : 홍보용 매체 이미지, 영상 삽입 및 업로드</li>
            </ul>
            <li>나. 나나로그 맞춤 스타일 추천</li>
            <ul>
              <li>사용매체 : 나나로그 소프트웨어</li>
              <li>사용형식 : 이미지, 영상 삽입 및 업로드</li>
            </ul>
          </ul>
          <p>
            저작물의 저작권 및 저작인접권은 초상권자와 사용자 모두에게 귀속되고,
            사용자는 저작물을 이용하여 2차 제작물 및 편집 제작물로 활용할 권리를
            가집니다. 권리자도 본인을 위한 홍보 목적으로 본 저작물을 활용할 수
            있습니다.
          </p>
          <Alert>
            권리자 정보
            <ul>
              <li>성함: {booking.customer?.name}</li>
              <li>연락처: {booking.customer?.phoneNumber}</li>
              <li>동의일시: {dayjs().format("YYYY-MM-DD HH:mm")}</li>
            </ul>
          </Alert>
          <div className="SignatureCanvasContainer">
            <SignatureCanvas
              ref={(ref) => {
                signatureRef.current = ref ?? undefined;
              }}
              penColor="black"
              backgroundColor="#f8f8f8"
              canvasProps={{
                width: 400,
                height: 200,
                className: "SignatureCanvas",
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            닫기
          </Button>

          <Button
            variant="secondary"
            onClick={() => {
              signatureRef.current?.clear();
            }}
          >
            다시 그리기
          </Button>
          <Button variant="primary" onClick={updateSignature}>
            서명 완료
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
