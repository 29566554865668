import { API, graphqlOperation } from "aws-amplify";
import {
  CreateShopUserApplicationInput,
  ShopUserApplication,
  ShopUserApplicationByShopIdQueryVariables,
  UpdateShopUserApplicationInput,
} from "../../graphql/API";
import {
  createShopUserApplication,
  updateShopUserApplication,
} from "../../graphql/mutations";
import {
  getShopUserApplication,
  shopUserApplicationByShopId,
} from "../../graphql/queries";

export default class ShopUserApplicationAPI {
  async get(id: string) {
    const response: any = await API.graphql(
      graphqlOperation(getShopUserApplication, {
        id,
      })
    );

    return response.data.getShopUserApplication;
  }
  async listByShopIdWithUserId(
    shopId: string,
    userId: string
  ): Promise<ShopUserApplication> {
    const variables: ShopUserApplicationByShopIdQueryVariables = {
      shopId,
      filter: {
        userId: {
          eq: userId,
        },
      },
    };
    const response: any = await API.graphql(
      graphqlOperation(shopUserApplicationByShopId, variables)
    );

    const items = response.data.shopUserApplicationByShopId.items;

    return items.length > 0 ? items[0] : undefined;
  }
  async create(
    input: CreateShopUserApplicationInput
  ): Promise<ShopUserApplication> {
    const response: any = await API.graphql(
      graphqlOperation(createShopUserApplication, {
        input,
      })
    );

    return response.data.createShopUserApplication;
  }
  async update(input: UpdateShopUserApplicationInput) {
    if (!input._version) {
      throw new Error("Missing _version");
    }
    return API.graphql(
      graphqlOperation(updateShopUserApplication, {
        input,
      })
    );
  }
}
