import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import App from "../../App";
import BookingList from "../../components/Booking";
import CustomerList from "../../components/Customer";
import DailyClosing from "../../components/DailyClosing";
import Designer from "../../components/Designer";
import DiscountPreset from "../../components/DiscountPreset";
import PrepaidPassPreset from "../../components/PrepaidPassPreset";
import SalesStatus from "../../components/SalesStatus";
import SchedulePanel from "../../components/SchedulePanel";
import ServiceOption from "../../components/ServiceOption";
import Brand from "../../components/Brand";
import Product from "../../components/Product";
import NotFound404 from "../../components/NotFound404";
import Feedback from "../../components/Feedback";
import Settings from "../../components/Settings";
import SubmitShopUserApplication from "../../components/SubmitShopUserApplication";
import SubmitNewShopRegistration from "../../components/SubmitNewShopRegistration";
import WaitingForApproval from "../../components/WaitingForApproval";
import ConsultationList from "../../components/Consultation";
import Dashboard from "../../components/Dashboard";
import FAQ from "../../components/FAQ";
import SelfRegular from "../../components/SelfRegular";
import SelfNew from "../../components/SelfNew";

export default function RouteList() {
  let location = useLocation();

  useEffect(() => {
    initializeGA();
  }, []);

  useEffect(() => {
    trackPageView();
  }, [location]);

  function initializeGA() {
    if (process.env.REACT_APP_STAGE !== "prod") {
      return;
    }

    const TRACKING_ID = "G-V8WXYEHK1B";

    ReactGA.initialize(TRACKING_ID);
  }

  function trackPageView() {
    if (process.env.REACT_APP_STAGE !== "prod") {
      return;
    }

    ReactGA.set({ page: location.pathname });
    ReactGA.send("pageview");
  }

  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<BookingList />} />
        <Route path="customer" element={<CustomerList />} />
        <Route path="schedule" element={<SchedulePanel />} />
        <Route path="service-option" element={<ServiceOption />} />
        <Route path="discount-preset" element={<DiscountPreset />} />
        <Route path="prepaid-pass-preset" element={<PrepaidPassPreset />} />
        <Route path="designer" element={<Designer />} />
        <Route path="daily-closing" element={<DailyClosing />} />
        <Route path="sales-status" element={<SalesStatus />} />
        <Route path="brand" element={<Brand />} />
        <Route path="product" element={<Product />} />
        <Route path="feedback" element={<Feedback />} />
        <Route path="settings" element={<Settings />} />
        <Route path="consultation" element={<ConsultationList />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="self-new/:id" element={<SelfNew />} />
        <Route path="self-regular/:id" element={<SelfRegular />} />

        <Route path="*" element={<NotFound404 />} />
      </Route>
      <Route
        path="/submit-shop-user-application"
        element={<SubmitShopUserApplication />}
      />
      <Route
        path="/submit-new-shop-registration"
        element={<SubmitNewShopRegistration />}
      />
      <Route path="/waiting-for-approval" element={<WaitingForApproval />} />
      <Route path="/faq" element={<FAQ />} />
    </Routes>
  );
}
