import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { isMobile } from "react-device-detect";
import SignatureCanvas from "react-signature-canvas";
import CustomerAPI from "../../../api/customer";
import { useAppSelector, useWindowSize } from "../../../app/hooks";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { fetchCustomerById, selectCustomer } from "../../../features/customer";
import { useDispatch } from "react-redux";

type Props = {
  show: boolean;
  setShow: any;
};

export default function SignatureModal({ show, setShow }: Props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const customer = useAppSelector(selectCustomer);
  const signatureRef = useRef<SignatureCanvas>();
  const { width = window.innerWidth } = useWindowSize();

  const customerAPI = new CustomerAPI();

  useEffect(() => {}, []);

  async function updateSignature() {
    if (!signatureRef.current) {
      return;
    }

    if (!customer) {
      return;
    }

    const dataUrl = signatureRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png");

    await customerAPI.updateCustomer({
      id: customer.id,
      signature: dataUrl,
      _version: customer._version,
    });

    enqueueSnackbar("서명을 저장했습니다.", { variant: "success" });

    dispatch(fetchCustomerById(customer?.id!) as any);
  }

  return (
    <>
      <Modal
        fullscreen={isMobile ? true : undefined}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{customer?.name} 고객님</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="SignatureCanvasContainer">
            <SignatureCanvas
              ref={(ref) => {
                signatureRef.current = ref ?? undefined;
              }}
              penColor="black"
              backgroundColor="#f8f8f8"
              canvasProps={{
                width: width - 50,
                height: 200,
                className: "SignatureCanvas",
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{ marginRight: 1 }}
          >
            닫기
          </Button>

          <Button
            variant="outlined"
            onClick={() => {
              signatureRef.current?.clear();
            }}
            sx={{ marginRight: 1 }}
          >
            다시 그리기
          </Button>
          <Button variant="contained" onClick={updateSignature}>
            서명 완료
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
