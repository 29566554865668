import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "aws-amplify";
import { RootState } from "../../app/store";
import { DiscountPreset } from "../../graphql/API";
import { discountPresetByShopId } from "../../graphql/queries";

export interface DiscountPresetState {
  discountPresets: DiscountPreset[];
  shopId?: string;
}

const initialState: DiscountPresetState = {
  discountPresets: [],
  shopId: undefined,
};

const fetchDiscountPresetsByShopId = createAsyncThunk(
  "discountPresets/fetchDiscountPresetsByShopId",
  async (shopId: string) => {
    const response: any = await API.graphql(
      graphqlOperation(discountPresetByShopId, {
        shopId,
      })
    );
    return response.data.discountPresetByShopId.items;
  }
);

export const discountPresetSlice = createSlice({
  name: "discountPreset",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchDiscountPresetsByShopId.fulfilled,
      (state, { payload }: { payload: DiscountPreset[] }) => {
        state.discountPresets = payload.filter((item) => !item._deleted);

        if (payload.length > 0) {
          state.shopId = payload[0].shopId;
        }
      }
    );
  },
});

export const selectDiscountPresets = (state: RootState) =>
  state.discountPreset.discountPresets;

export const selectShopId = (state: RootState) => state.discountPreset.shopId;

export { fetchDiscountPresetsByShopId };

export default discountPresetSlice.reducer;
