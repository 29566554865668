import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentShopId } from "../../features/common";
import {
  fetchDesignersByShopId,
  selectDesigners,
} from "../../features/designer";

export default function Designer() {
  const dispatch = useDispatch();

  const currentShopId = useAppSelector(selectCurrentShopId);
  const designers = useAppSelector(selectDesigners);

  useEffect(() => {
    if (!currentShopId) {
      return;
    }

    dispatch(fetchDesignersByShopId(currentShopId) as any);
  }, [currentShopId]);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h4>디자이너 설정</h4>
            <hr />
            <Table>
              <thead>
                <tr>
                  <th>디자이너 이름</th>
                  <th>네이버 노출 이름</th>
                  <th>등록일</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {designers.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>{item.naverName}</td>
                      <td>{dayjs(item.createdAt).format("YYYY-MM-DD")}</td>
                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}
