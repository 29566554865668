import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "aws-amplify";
import { RootState } from "../../app/store";
import { PrepaidPassPreset } from "../../graphql/API";
import { prepaidPassPresetByShopId } from "../../graphql/queries";

export interface PrepaidPassPresetState {
  prepaidPassPresets: PrepaidPassPreset[];
  shopId?: string;
}

const initialState: PrepaidPassPresetState = {
  prepaidPassPresets: [],
  shopId: undefined,
};

const fetchPrepaidPassPresetsByShopId = createAsyncThunk(
  "prepaidPassPresets/fetchPrepaidPassPresetsByShopId",
  async (shopId: string) => {
    const response: any = await API.graphql(
      graphqlOperation(prepaidPassPresetByShopId, {
        shopId,
      })
    );
    return response.data.prepaidPassPresetByShopId.items;
  }
);

export const prepaidPassPresetSlice = createSlice({
  name: "prepaidPassPreset",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchPrepaidPassPresetsByShopId.fulfilled,
      (state, { payload }: { payload: PrepaidPassPreset[] }) => {
        state.prepaidPassPresets = payload.filter((item) => !item._deleted);

        if (payload.length > 0) {
          state.shopId = payload[0].shopId;
        }
      }
    );
  },
});

export const selectPrepaidPassPresets = (state: RootState) =>
  state.prepaidPassPreset.prepaidPassPresets;

export const selectShopId = (state: RootState) =>
  state.prepaidPassPreset.shopId;

export { fetchPrepaidPassPresetsByShopId };

export default prepaidPassPresetSlice.reducer;
