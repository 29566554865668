import { Switch } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import BackendAPI from "../../../api/backend-api";
import ConsultationAPI from "../../../api/consultation";
import consultationConstants from "../../../app/constants/consultation";
import { useAppSelector } from "../../../app/hooks";
import {
  fetchBookingByConsultationId,
  refreshBookings,
} from "../../../features/booking";
import {
  selectShowUpdateConsultationModal,
  setShowUpdateConsultationModal,
} from "../../../features/common";
import {
  fetchConsultationById,
  fetchConsultationsByShopId,
  selectConsultation,
  selectStudyMode,
  setStudyMode,
  updateConsultation,
} from "../../../features/consultation";
import { UpdateConsultationInput } from "../../../graphql/API";
import ConsultationService from "../../../services/consultation";
import ImageDetailModal from "../ImageDetailModal";
import StepDivider from "../Shared/StepDivider";
import StepOne from "../Shared/StepOne";
import StepThree from "../Shared/StepThree";
import StepTwo from "../Shared/StepTwo";

export default function ConsultationUpdateModal() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const consultationAPI = new ConsultationAPI();
  const backendAPI = new BackendAPI();

  const consultationService = new ConsultationService(
    consultationAPI,
    backendAPI
  );

  const consultation = useAppSelector(selectConsultation);

  const show = useAppSelector(selectShowUpdateConsultationModal);

  const studyMode = useAppSelector(selectStudyMode);

  const stepOneRef = useRef<HTMLDivElement>(null);
  const stepTwoRef = useRef<HTMLDivElement>(null);
  const stepThreeRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(false);

  const handleClose = () => dispatch(setShowUpdateConsultationModal(false));

  useEffect(() => {
    if (consultation) {
      const newState = {
        ...consultation,
      } as any;

      if (!consultation.modelVersion || consultation.modelVersion === 1) {
        if (consultation.faceShape && !consultation.faceShapes) {
          newState.faceShapes = [consultation.faceShape];
        }
      } else if (consultation.modelVersion === 2) {
        if (
          consultation.colorfulnessOfHair &&
          !consultation.colorfulnessesOfHair
        ) {
          newState.colorfulnessesOfHair = [consultation.colorfulnessOfHair];
        }
        if (
          consultation.hairRecommendation?.hairVolume &&
          !consultation.hairRecommendation.hairVolumes
        ) {
          newState.hairRecommendation = {
            ...consultation.hairRecommendation,
            hairVolumes: [consultation.hairRecommendation.hairVolume],
          };
        }
        if (
          consultation.hairRecommendation?.haircut &&
          !consultation.hairRecommendation.haircuts
        ) {
          newState.hairRecommendation.haircuts = [
            consultation.hairRecommendation.haircut,
          ];
        }
      }

      setInput(newState);

      if (consultation.bookingId) {
        dispatch(fetchBookingByConsultationId(consultation.bookingId) as any);
      }
    }
  }, [consultation]);

  const [input, setInput] = useState<UpdateConsultationInput>();

  async function handleSaveChanges() {
    if (!consultation || !input) {
      return;
    }

    setLoading(true);

    try {
      await dispatch(updateConsultation({ consultation, input }) as any);

      enqueueSnackbar("저장했습니다.", {
        variant: "success",
      });
    } catch (error: any) {
      if (error.errors?.length > 0) {
        toast.warn("오류가 발생했습니다.");
        toast.warn(error.errors[0].message);
      } else {
        toast.warn(error.message);
      }
    } finally {
      setLoading(false);

      dispatch(fetchConsultationsByShopId(consultation.shopId) as any);

      setTimeout(() => {
        if (consultation.bookingId) {
          dispatch(refreshBookings() as any);
        }
      }, 3000);
    }
  }

  function toggleSelection(
    selected: string,
    inputArray: any,
    inputFieldName: string
  ) {
    if (!input) {
      return;
    }

    if (consultationConstants.singleValueFieldNames.includes(inputFieldName)) {
      setInput({
        ...input,
        [inputFieldName]: selected,
      });
      return;
    }

    const existingIndex =
      inputArray?.findIndex((item: any) => item === selected) ?? -1;

    const existingArray = [...(inputArray ?? [])];

    if (existingIndex > -1) {
      existingArray.splice(existingIndex, 1);
    } else {
      existingArray.push(selected);
    }
    setInput({
      ...input,
      [inputFieldName]: existingArray,
    });
  }

  async function sendConsultationSolution() {
    if (!consultation) {
      return;
    }

    await handleSaveChanges();

    if (!consultation.solution && !input?.solution) {
      toast.warn("솔루션을 입력해 주세요.");
      return;
    }

    try {
      if (!consultation.solutionSentAt) {
        await consultationService.sendSolution(consultation);
      }

      dispatch(fetchConsultationById(consultation.id) as any);
    } catch (error: any) {
      toast.warn("전송 중 문제가 발생했어요.");
      toast.warn(error.message);
    }
  }

  function onStudyModeChange(event: any) {
    dispatch(setStudyMode(event.currentTarget.checked));
  }

  if (!show) {
    return <></>;
  }

  if (!input) {
    return <>Loading...</>;
  }

  return (
    <>
      <Modal fullscreen={true} show={show} onHide={handleClose}>
        <Modal.Body style={{ padding: 0 }}>
          <Form style={{ maxWidth: 1024, margin: "0 auto" }}>
            <div ref={stepOneRef}>
              <StepOne
                input={input}
                setInput={setInput}
                toggleSelection={toggleSelection}
                consultation={consultation}
              />
            </div>
            <StepDivider />
            <div ref={stepTwoRef} className="modal-padding-top">
              <StepTwo
                input={input}
                setInput={setInput}
                toggleSelection={toggleSelection}
              />
            </div>
            <StepDivider />
            <div ref={stepThreeRef} className="modal-padding-top">
              <StepThree input={input} setInput={setInput} />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="me-auto">
            스터디 모드
            <Switch
              inputProps={{ "aria-label": "스터디 모드" }}
              checked={studyMode}
              onChange={onStudyModeChange}
            />
          </div>
          <h5 className="me-auto" style={{ marginLeft: 48 }}>
            {consultation?.customer.name} 고객님
          </h5>
          <Button
            variant="outline-secondary"
            onClick={() => {
              if (stepOneRef.current) {
                stepOneRef.current.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            S1
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => {
              if (stepTwoRef.current) {
                stepTwoRef.current.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            S2
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => {
              if (stepThreeRef.current) {
                stepThreeRef.current.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            S3
          </Button>
          {!consultation?.solutionSentAt && (
            <Button
              disabled={loading}
              variant="outline-primary"
              onClick={sendConsultationSolution}
            >
              저장 &amp; 전송
            </Button>
          )}
          <Button variant="secondary" onClick={handleClose}>
            닫기
          </Button>
          <Button
            variant="primary"
            disabled={loading}
            onClick={handleSaveChanges}
          >
            저장
          </Button>
        </Modal.Footer>
      </Modal>
      <ImageDetailModal />
    </>
  );
}
