const recentVisitTimestamp = "recentVisitTimestamp";
const justSignedUp = "justSignedUp";

export default class BrowserService {
  static setRecentVisitTimestamp() {
    localStorage.setItem(recentVisitTimestamp, String(Date.now()));
  }
  static getRecentVisitTimestamp() {
    return localStorage.getItem(recentVisitTimestamp);
  }
  static setJustSignedUp() {
    sessionStorage.setItem(justSignedUp, "true");
  }
  static getJustSignedUp() {
    return sessionStorage.getItem(justSignedUp);
  }
  static removeJustSignedUp() {
    sessionStorage.removeItem(justSignedUp);
  }
}
