import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentShopId } from "../../features/common";
import {
  fetchPrepaidPassPresetsByShopId,
  selectPrepaidPassPresets,
} from "../../features/prepaid-pass-preset";
import { CreatePrepaidPassPresetInput } from "../../graphql/API";
import {
  createPrepaidPassPreset,
  deletePrepaidPassPreset,
} from "../../graphql/mutations";
import PrepaidPassPresetRow from "./Row";

export default function PrepaidPassPreset() {
  const currentShopId = useAppSelector(selectCurrentShopId);
  const dispatch = useDispatch();
  const prepaidPassPresets = useAppSelector(selectPrepaidPassPresets);

  const defaultInput = () => {
    return {
      name: "",
      shopId: currentShopId ?? "",
      availableAmount: 0,
      price: 0,
    };
  };

  const [input, setInput] = useState<CreatePrepaidPassPresetInput>(
    defaultInput()
  );

  useEffect(() => {
    if (!currentShopId) {
      return;
    }

    dispatch(fetchPrepaidPassPresetsByShopId(currentShopId) as any);
  }, [currentShopId]);

  function validate() {
    if (!input) {
      return false;
    }

    const errors: string[] = [];

    const { name, price, availableAmount } = input;

    if (!name) {
      errors.push(`정액권 이름은 필수 항목입니다.`);
    }
    if (typeof price !== "number" || price === 0) {
      errors.push("가격은 필수 항목입니다.");
    }
    if (typeof availableAmount !== "number" || availableAmount === 0) {
      errors.push("사용 금액은 필수 항목입니다.");
    }
    if (price > availableAmount) {
      errors.push("사용 금액은 가격과 같거나 커야 합니다.");
    }

    errors.forEach((error) => {
      toast.error(error);
    });

    return errors.length === 0;
  }

  async function createPass() {
    if (!validate()) {
      return;
    }

    const result: any = await API.graphql(
      graphqlOperation(createPrepaidPassPreset, {
        input: {
          ...input,
          shopId: currentShopId!,
        },
      })
    );

    dispatch(fetchPrepaidPassPresetsByShopId(currentShopId!) as any);

    setInput(defaultInput());
  }

  async function deletePass(id: string, _version: number) {
    if (!confirm("삭제하시겠습니까?")) {
      return;
    }
    const result: any = await API.graphql(
      graphqlOperation(deletePrepaidPassPreset, {
        input: {
          id,
          _version,
        },
      })
    );

    dispatch(fetchPrepaidPassPresetsByShopId(currentShopId!) as any);
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h4>정액권 설정</h4>
            <hr />
            <Table>
              <thead>
                <tr>
                  <th>정액권 이름</th>
                  <th>가격</th>
                  <th>사용 금액</th>
                  <th>등록일</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {prepaidPassPresets.map((item) => {
                  return (
                    <PrepaidPassPresetRow
                      key={item.id}
                      item={item}
                      deletePass={deletePass}
                    />
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                이름
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  value={input?.name}
                  onChange={(event) => {
                    setInput({
                      ...input,
                      name: event.target.value,
                    });
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                가격
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  value={input?.price}
                  onChange={(event) => {
                    setInput({
                      ...input,
                      price: Number(event.target.value),
                    });
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                사용 금액
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  value={input?.availableAmount}
                  onChange={(event) => {
                    setInput({
                      ...input,
                      availableAmount: Number(event.target.value),
                    });
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Button size="sm" onClick={createPass}>
              등록
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
