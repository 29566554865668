import { Amplify, Auth, I18n } from "aws-amplify";
import { AmazonAIPredictionsProvider } from "@aws-amplify/predictions";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { LicenseInfo } from "@mui/x-license-pro";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ko from "dayjs/locale/ko";
import ReactGA from "react-ga4";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";

import { koKR } from "@mui/x-date-pickers";
import { useAppSelector } from "./app/hooks";
import {
  selectUserGroups,
  setUserAttributes,
  setUserGroups,
} from "./features/common";
import awsExports from "./aws-exports";

import "react-toastify/dist/ReactToastify.css";
import { withAuthenticator } from "@aws-amplify/ui-react";

import "@aws-amplify/ui-react/styles.css";
import { CognitoUserAmplify } from "@aws-amplify/ui";
import { fetchShops } from "./features/shop";
import ConsultationCreateWithoutBookingModal from "./components/Consultation/CreateWithoutBookingModal";
import ConsultationUpdateModal from "./components/Consultation/UpdateModal";
import Footer from "./components/Shared/Footer";
import "./App.scss";
import CustomerCreateModal from "./components/Customer/CreateModal";
import SignUpEntrance from "./components/SignUpEntrance";
import Bottom from "./components/Shared/Bottom";
import AppBar from "./components/Shared/AppBar";
import dayjs from "dayjs";
import { Box } from "@mui/system";
import { Alert, Button, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import BrowserService from "./services/browser";
import BookingUpdateModal from "./components/Booking/UpdateModal";
import CustomerModal from "./components/Customer/Modal";

LicenseInfo.setLicenseKey(
  "83ff619ab4395586d80ff2c926baf2d6Tz01MjA5MCxFPTE2OTY3NDQ0NTYxNDQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

function getCookieDomain() {
  const hostTokens = location.hostname.split(".");

  let domain = "localhost";
  if (hostTokens.length > 2) {
    domain = "." + hostTokens.slice(-2).join(".");
  } else if (hostTokens.length === 2) {
    domain = "." + hostTokens.join(".");
  }
  return domain;
}

const secure = location.protocol === "https:";

const cookieStorage = {
  domain: getCookieDomain(),
  secure,
  path: "/",
  expires: 365,
};

Amplify.configure({
  ...awsExports,
  Auth: { cookieStorage },
});
Amplify.addPluggable(new AmazonAIPredictionsProvider());

const krDict = {
  kr: {
    "Account recovery requires verified contact information":
      "계정 복구를 위해 연락처 확인이 필요합니다",
    "Back to Sign In": "로그인으로 돌아가기",
    "Change Password": "비밀번호 변경하기",
    Changing: "변경중",
    Code: "코드",
    "Confirm Password": "비밀번호 재확인",
    "Confirm Sign Up": "회원가입 확인",
    "Confirm SMS Code": "휴대폰 본인 확인",
    "Confirm TOTP Code": "TOTP 인증번호 확인",
    Confirm: "확인",
    "Confirmation Code": "인증번호",
    Confirming: "확인중",
    "Create a new account": "회원가입",
    "Create Account": "회원가입",
    "Creating Account": "회원가입중",
    "Dismiss alert": "알림 무시",
    Email: "이메일",
    "Enter your code": "인증번호를 입력해주세요",
    "Enter your email": "이메일 입력",
    "Enter your phone number": "전화번호 입력",
    "Enter your username": "아이디를 입력해주세요",
    "Hide password": "비밀번호 숨기기",
    Loading: "로딩중",
    "New password": "새 비밀번호",
    Password: "비밀번호",
    "Phone Number": "전화번호",
    "Resend Code": "인증번호 재전송",
    "Reset your password": "비밀번호 재설정",
    "Reset your Password": "비밀번호 재설정",
    "Send code": "인증코드 보내기",
    "Send Code": "코드 전송",
    Sending: "전송중",
    "Setup TOTP": "TOTP 설정하기",
    "Show password": "비밀번호 보이기",
    "Sign in to your account": "로그인",
    "Sign In with Amazon": "Amazon 로그인",
    "Sign In with Apple": "Apple 로그인",
    "Sign In with Facebook": "Facebook 로그인",
    "Sign In with Google": "Google 로그인",
    "Sign in": "로그인",
    "Sign In": "로그인",
    "Signing in": "로그인중",
    Skip: "다음에 하기",
    Submit: "확인",
    Submitting: "확인중",
    Username: "아이디",
    "Verify Contact": "연락처 확인",
    Verify: "인증",

    // Additional translations provided by customers
    Birthdate: "생년월일",
    "Family Name": "성",
    "Forgot your password?": "비밀번호를 잊으셨나요?",
    "Given Name": "이름",
    Name: "성함",
    Nickname: "닉네임",
    "Preferred Username": "닉네임",
    Profile: "프로필",
    "Reset Password": "비밀번호 재설정",
    Website: "웹사이트",
  },
};

I18n.putVocabularies(krDict);
I18n.setLanguage("kr");

function App({ signOut, user }: any) {
  const dispatch = useDispatch();

  const groups = useAppSelector(selectUserGroups);

  const userWithType: CognitoUserAmplify = user;

  useEffect(() => {
    dispatchFetchShops();

    if (process.env.REACT_APP_STAGE !== "prod") {
      window.document.title = `테스트 | 나나로그`;
    }

    BrowserService.setRecentVisitTimestamp();

    Auth.updateUserAttributes(user, {
      "custom:last_appearance": dayjs().format("YYYY-MM-DD HH:mm:ss KST"),
    });
  }, []);

  useEffect(() => {
    fetchCognitoUser(user);

    ReactGA.set({
      userId: user.attributes.sub,
    });
  }, [user]);

  async function fetchCognitoUser(user: any) {
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];

    const param = groups ?? [];

    dispatch(setUserAttributes(user.attributes));
    dispatch(setUserGroups(param));

    if (!param.includes("Admin") && !user.attributes["custom:shop_id"]) {
      setTimeout(() => {
        refreshUser(false);
      }, 60 * 1000);
    }
  }

  async function refreshUser(showToast = true) {
    if (showToast) {
      toast.info("사용자 권한을 새로고침합니다.");
    }

    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });

    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];

    const param = groups ?? [];

    dispatch(setUserGroups(param));
  }

  async function dispatchFetchShops() {
    dispatch(fetchShops() as any);
  }

  if (!userWithType.attributes?.email_verified) {
    return (
      <Container>
        <Row>
          <Col className="text-center" style={{ paddingTop: 20 }}>
            <Alert>이메일 인증 후 사용하실 수 있습니다.</Alert>
            <div>
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  refreshUser();
                }}
              >
                새로고침
              </Button>{" "}
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  signOut();
                }}
              >
                로그아웃
              </Button>
            </div>
            <ToastContainer position="top-center" />
          </Col>
        </Row>
      </Container>
    );
  }

  if (!groups.includes("Designer") && !groups.includes("Admin")) {
    return (
      <>
        <SignUpEntrance />
      </>
    );
  }

  return (
    <div className="App">
      <AppBar user={user} signOut={signOut} />
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={ko}
        localeText={
          koKR.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <Outlet />
        <Container>
          <Row>
            <Col>
              <ConsultationCreateWithoutBookingModal />
              <CustomerModal />
              <ConsultationUpdateModal />
              <BookingUpdateModal />
              <CustomerCreateModal />
            </Col>
          </Row>
        </Container>
      </LocalizationProvider>
      <Footer />
      <Bottom />
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        autoClose={3000}
      />
    </div>
  );
}

const videoSize = 50;

function Header() {
  const navigate = useNavigate();
  const GREETING_MESSAGE_KEY = "GREETING_MESSAGE_KEY";

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    handleFirstVisit();

    return () => {
      closeSnackbar(GREETING_MESSAGE_KEY);
    };
  }, []);

  function handleFirstVisit() {
    const recentVisitTimestamp = BrowserService.getRecentVisitTimestamp();

    if (!recentVisitTimestamp) {
      enqueueSnackbar("처음 방문하셨나요? 회원가입은 3분이면 충분해요.", {
        key: GREETING_MESSAGE_KEY,
        variant: "success",
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        autoHideDuration: 10000,
        action: () => {
          return (
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                closeSnackbar(GREETING_MESSAGE_KEY);
              }}
            >
              Ok
            </Button>
          );
        },
      });
    }
  }

  return (
    <>
      <div style={{ marginBottom: 30, paddingLeft: 15, paddingRight: 15 }}>
        <Box>
          <div style={{ fontWeight: "bold" }}>나나로그 솔루션</div>
        </Box>
        <Row>
          <Col>
            <img
              src="/images/icons/nanalog-app-logo-no-space.png"
              alt="나나로그 로고"
              style={{ height: 50 }}
            />
          </Col>
          <Col className="text-end">
            <video
              width={videoSize}
              height={videoSize}
              autoPlay
              loop
              muted
              webkit-playsinline
              playsInline
            >
              <source src="/videos/heart.mp4" type="video/mp4" />
            </video>
          </Col>
        </Row>
        <Box sx={{ marginTop: 1, wordBreak: "keep-all" }}>
          <Alert severity="info">
            [나나로그 솔루션]은 나나로그 키오스크를 포함하여 미용실 단위로
            나나로그의 디지털 솔루션을 적용할 때 적합하게 구성되어 있습니다.
            헤어 디자이너 단위에서 나나로그 솔루션에 관심이 있으신 경우 먼저
            [모미 for 헤어디자이너] 앱을 사용해 보세요.
            <div>
              <IconButton
                onClick={() => {
                  window.open(
                    "https://apps.apple.com/kr/app/%EB%AA%A8%EB%AF%B8-for-%ED%97%A4%EC%96%B4%EB%94%94%EC%9E%90%EC%9D%B4%EB%84%88/id6444328723",
                    "_blank"
                  );
                }}
              >
                <AppleIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=co.nanalog.nanalogdesigner",
                    "_blank"
                  );
                }}
              >
                <AndroidIcon />
              </IconButton>
            </div>
          </Alert>
        </Box>
        <Box sx={{ textAlign: "center", marginTop: 2 }}>
          <Button
            size="small"
            variant="outlined"
            sx={{ marginRight: 1 }}
            onClick={() => {
              window.open("https://www.nanalog.co");
            }}
          >
            서비스 소개
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              navigate("/faq");
            }}
          >
            자주 묻는 질문
          </Button>
        </Box>
      </div>
    </>
  );
}

export default withAuthenticator(App, {
  components: {
    Header,
    Footer: () => (
      <>
        <Footer />
      </>
    ),
  },
});
