import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useNavigate } from "react-router-dom";

type SelfDiagnosisContent = {
  title: string;
  body: JSX.Element;
};

const contents: SelfDiagnosisContent[] = [
  {
    title: "나나로그 솔루션은 무료인가요?",
    body: (
      <div>
        <p>
          네, 현재 나나로그 솔루션은 무료로 제공하고 있습니다. 알림톡/MMS 발송
          등 실비 또한 나나로그에서 부담하고 있습니다.
        </p>
        <p>
          추후 서비스가 고도화됨에 따라 요금제가 마련되고 일부 기능을 사용하기
          위해서는 요금제 가입이 필요한 시점이 올 수 있습니다. 이때 충분한
          시간을 두고 미리 안내해 드릴 예정이며, 합리적인 요금제를 마련하여
          고객님들께 안정적인 서비스를 제공할 수 있도록 노력하겠습니다.
        </p>
      </div>
    ),
  },
  {
    title: "어떤 형태의 미용실에 적합한가요?",
    body: (
      <div>
        <p>
          1인샵부터 소형 및 중대형 살롱, 1명의 헤어디자이너까지 모두 사용
          가능합니다.
          <br />
          매장 상황에 맞게 제공하는 서비스 중 필요한 서비스만 선택하여 사용할 수
          있습니다.
        </p>
      </div>
    ),
  },
  {
    title: "사용하고 싶으면 어떻게 해야 하나요?",
    body: (
      <div>
        회원가입 후 이메일 인증을 받고 나면 계정이 생성됩니다. 오른쪽 상단
        아이콘 설정 기능을 통해 원하는 기능만 활성화할 수 있습니다.
      </div>
    ),
  },
  {
    title: "궁금한 점이나 사용 중 문제가 생기면 어디로 연락하면 되나요?",
    body: (
      <div>
        왼쪽 하단의 채팅 아이콘을 누르시면, 솔루션 관리자에게 도움을 요청하실 수
        있습니다.
      </div>
    ),
  },
  {
    title: "구동 환경이 궁금해요. 태블릿이 필요한가요?",
    body: (
      <div>
        <p>
          나나로그 솔루션은 웹 브라우저로 접속할 수 있다면 어느 기기에서나
          이용할 수 있어요.
        </p>
        <p>
          PC, 모바일, 태블릿 모두 가능하며, 모바일/태블릿 환경에서는 홈 화면에
          바로가기를 추가해서 사용하면 편리해요.
          <ul>
            <li>
              <a
                href="https://support.apple.com/ko-kr/guide/iphone/iph42ab2f3a7/ios#iph4f9a47bbc"
                target="_blank"
              >
                iOS
              </a>
            </li>
          </ul>
        </p>
        <ul>
          <li>
            키오스크 기능 사용을 원하실 경우, 입구에 배치할 여분의 태블릿이 1대
            필요합니다. 대부분의 태블릿 사이즈를 지원하며 레이아웃이 맞지 않는
            경우 기기를 알려주시면 우선 조치해 드립니다.
          </li>
          <li>
            헤어 컨설테이션 기능 또한 모바일에서 이용할 수 있으나 태블릿을 이용
            시 좀 더 원활하게 고객과 소통하며 진행하실 수 있습니다.
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: "매장 단위로만 가입할 수 있나요?",
    body: (
      <div>
        <p>디자이너 개인으로도 사용하실 수 있습니다.</p>
        <p>
          디자이너 개인 또한 이메일 인증 후 가입 유형에서 매장 운영주를
          선택하시고 가입 완료하셔서 사용하시면 됩니다.
          <br />
          매장명은 편의상 [나나로그 나나]와 같은 매장명 + 디자이너명 조합으로
          만들어 주시면 좋습니다.
        </p>
      </div>
    ),
  },
  {
    title: "입력한 정보는 어떻게 보호되나요?",
    body: (
      <div>
        나나로그는 개인정보 관련 약관과 법규에 따라 사용자의 동의를 우선적으로
        받고 있으며, 동의 없이 정보를 유출하거나 이용하지 않습니다.
      </div>
    ),
  },
  {
    title: "기존 프로그램을 사용하고 있어도 쓸 수 있나요?",
    body: (
      <div>
        나나로그 솔루션은 "고객과의 커뮤니케이션"을 보다 원활하게 도와주는
        솔루션으로써, 사용하시는 예약 플랫폼이나 CRM 프로그램은 그대로 쓰시면서
        사용하실 수 있습니다.
      </div>
    ),
  },
  {
    title: "솔루션 사용 교육을 받을 수 있나요?",
    body: (
      <div>
        info@futurebeauty.co 로 매장명과 담당자 명, 연락처를 남겨주시면 별도로
        연락드리도록 하겠습니다.
      </div>
    ),
  },
  {
    title: "셀프 진단이 무엇인가요?",
    body: (
      <div>
        <p>
          셀프 진단은 고객이 헤어샵에 방문하기 전 스스로 헤어 관리 루틴과 고민을
          작성하는 것을 도와주는 기능입니다.
        </p>
        <p>
          고객은 셀프 진단 작성 요청 메시지 수신 후 버튼을 터치하면 열리는
          웹페이지를 통해 쉽게 입력하고 제출할 수 있습니다.
        </p>
      </div>
    ),
  },
  {
    title:
      "셀프 진단을 발송하려면 나나로그 솔루션에 예약 정보를 등록해야 하나요?",
    body: (
      <div>
        <p>
          네, 맞습니다. 셀프 진단을 보내려면 고객명, 전화번호, 예약 시간을 직접
          등록해 주시면 됩니다.
        </p>
        <p>* 현재 예약 정보 자동 연동을 위해 플랫폼사와 협의 중에 있습니다.</p>
        <p>
          &lt;예약 등록 영상&gt;
          <br />
          <video muted width={"100%"} style={{ maxWidth: 222 }} controls>
            <source
              src="/videos/tutorial-create-booking.mp4"
              type="video/mp4"
            />
          </video>
        </p>
      </div>
    ),
  },
  {
    title: "셀프 진단 작성 요청은 언제 어떻게 발송되나요?",
    body: (
      <div>
        셀프 진단은 현재 알림톡 형태로 고객에게 발송하고 있습니다.
        <br />
        예약 시간 24시간 전에 발송하고 있으며, 매장 설정에서 셀프 진단 기능을
        활성화한 경우에만 자동 발송하고 있습니다.
        <ul>
          <li>
            24시간이 남지 않은 예약이 등록되는 경우 시스템 스케줄러가 인식하는
            시점에 바로 발송합니다. (최대 10분 이내)
          </li>
          <li>
            야간/심야에 예약이 등록되는 경우 바로 보내지 않고 대기하다가 오전
            7시부터 발송합니다.
          </li>
          <li>
            현재 시점보다 앞선 시간으로 예약을 등록하는 경우 발송되지 않습니다.
          </li>
        </ul>
      </div>
    ),
  },
  {
    title:
      "플랫폼을 통해 접수한 예약 고객에게 셀프 진단 작성 요청을 발송해도 개인정보 취급방침에 위배되지 않나요?",
    body: (
      <div>
        <p>네, 그렇습니다.</p>
        <p>
          일반적으로 고객이 플랫폼을 통해 헤어샵에 예약할 때 크게 두 곳에
          개인정보를 제공하는 것에 동의하게 됩니다. 한 곳은 예약 관리 솔루션, 또
          한 곳은 실제 서비스를 제공하는 업체입니다.
        </p>
        <p>
          고객이 업체에 개인정보를 제공할 때 일반적으로 사업자 회원과 예약
          이용자의 원활한 거래 진행, (중략) 혜택 및 맞춤 서비스 제공 등을 위해
          이용할 수 있도록 명시되어 있으므로 원활한 서비스 제공을 위해 셀프 진단
          작성을 요청하는 것은 정상적인 활동입니다.
        </p>
      </div>
    ),
  },
];

export default function FAQ() {
  const navigate = useNavigate();

  return (
    <Container sx={{ padding: 2 }}>
      <Link to="/">
        <img
          src="/images/icons/nanalog-app-logo-no-space.png"
          alt="나나로그 로고"
          style={{ height: 30 }}
        />
      </Link>
      <Grid container sx={{ marginTop: 1, marginBottom: 1 }}>
        <Grid item xs={6}>
          <Typography variant="h6">자주 묻는 질문</Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "right" }}>
          <Button
            onClick={() => {
              navigate("/");
            }}
          >
            처음으로 돌아가기
          </Button>
        </Grid>
      </Grid>

      {contents.map((content, index) => (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
          >
            <Typography>{content.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color={"#2b2b2b"} fontSize="14px">
              {content.body}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      <Box sx={{ textAlign: "center", marginTop: 2 }}>
        <Button
          onClick={() => {
            navigate("/");
          }}
        >
          처음으로 돌아가기
        </Button>
      </Box>
    </Container>
  );
}
