import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { Badge, Col, Form, Row, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CustomerAPI from "../../../api/customer";
import { useAppSelector } from "../../../app/hooks";
import {
  selectCurrentShopId,
  selectShowCreateCustomerModal,
  setShowCreateCustomerModal,
} from "../../../features/common";
import {
  emptyCreateCustomerInput,
  emptyNewCustomer,
  selectCreateCustomerInput,
  setCreateCustomerInput,
  setNewCustomer,
} from "../../../features/customer";

export default function CustomerCreateModal() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const show = useAppSelector(selectShowCreateCustomerModal);
  const createCustomerInput = useAppSelector(selectCreateCustomerInput);

  const handleClose = () => dispatch(setShowCreateCustomerModal(false));

  const currentShopId = useAppSelector(selectCurrentShopId);

  useEffect(() => {
    if (!show) {
      dispatch(emptyCreateCustomerInput());
    }

    return () => {
      dispatch(emptyNewCustomer());
    };
  }, [show]);

  useEffect(() => {
    if (!currentShopId) {
      return;
    }
  }, [currentShopId]);

  async function handleSaveChanges() {
    const { name, phoneNumber } = createCustomerInput;

    if (!name) {
      toast.warn("이름을 입력하세요.");
      return;
    }

    if (!phoneNumber) {
      toast.warn("전화번호를 입력하세요.");
      return;
    }

    const validPhoneNumber = /^[0-9]{3}([0-9]{3}|[0-9]{4})[0-9]{4}$/gm.test(
      phoneNumber
    );

    if (!validPhoneNumber) {
      toast.warn("전화번호 양식을 확인하세요.");
      return;
    }

    const customerAPI = new CustomerAPI();

    const result = await customerAPI.listCustomersByNameAndPhone(
      name,
      phoneNumber,
      currentShopId!
    );

    if (result.length > 0) {
      toast.warn("이미 등록된 고객입니다.");
      return;
    }

    const shopIds = [currentShopId!];

    const createResult = await customerAPI.createCustomer({
      name,
      phoneNumber,
      shopId: currentShopId!,
      shopIds,
      shopIdsString: JSON.stringify(shopIds),
    });

    if (createResult.errors && createResult.errors.length > 0) {
      toast.warn("오류가 발생했습니다.");
      toast.warn(createResult.errors[0].message);
    } else {
      enqueueSnackbar("고객 정보를 등록했습니다.", { variant: "success" });

      dispatch(setNewCustomer(createResult.data?.createCustomer!));

      dispatch(setShowCreateCustomerModal(false));
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>고객 등록</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    고객명
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={createCustomerInput.name}
                      onChange={(event) => {
                        dispatch(
                          setCreateCustomerInput({
                            ...createCustomerInput,
                            name: event.target.value,
                          })
                        );
                      }}
                      autoFocus
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    전화번호
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={createCustomerInput.phoneNumber ?? ""}
                      onChange={(event) => {
                        dispatch(
                          setCreateCustomerInput({
                            ...createCustomerInput,
                            phoneNumber: event.target.value,
                          })
                        );
                      }}
                      autoComplete="off"
                      type="tel"
                    />
                    <div>- 없이 01012341234 형식으로 입력하세요.</div>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            닫기
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            고객 등록
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
