import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useAppSelector } from "../../app/hooks";
import { fetchBrandsByShopId, selectBrands } from "../../features/brand";
import { selectCurrentShopId } from "../../features/common";
import { fetchProductsByShopId, selectProducts } from "../../features/product";
import { Brand, CreateProductInput } from "../../graphql/API";
import { createProduct, deleteProduct } from "../../graphql/mutations";
import ProductRow from "./Row";

export default function Product() {
  const currentShopId = useAppSelector(selectCurrentShopId);
  const dispatch = useDispatch();
  const brands = useAppSelector(selectBrands);
  const products = useAppSelector(selectProducts);

  const defaultInput = (): CreateProductInput => {
    return {
      name: "",
      shopId: currentShopId ?? "",
      brandId: "",
      brandName: "",
      costPrice: 0,
      sellingPrice: 0,
    };
  };

  const [input, setInput] = useState<CreateProductInput>(defaultInput());
  const [selecedBrand, setSelectedBrand] = useState<Brand>();

  useEffect(() => {
    if (!currentShopId) {
      return;
    }

    dispatch(fetchProductsByShopId(currentShopId) as any);
    dispatch(fetchBrandsByShopId(currentShopId) as any);
  }, [currentShopId]);

  useEffect(() => {
    if (selecedBrand) {
      setInput({
        ...input,
        brandId: selecedBrand.id,
        brandName: selecedBrand.name,
      });
    } else {
      setInput({
        ...input,
        brandId: "",
        brandName: "",
      });
    }
  }, [selecedBrand]);

  function validate() {
    if (!input) {
      return false;
    }

    const errors: string[] = [];

    const { name, brandId, brandName, costPrice, sellingPrice } = input;

    if (!name) {
      errors.push(`이름은 필수 항목입니다.`);
    }

    if (!brandId || !brandName) {
      errors.push(`브랜드는 필수 항목입니다.`);
    }

    if (costPrice !== 0 && !costPrice) {
      errors.push(`매입 가격은 필수 항목입니다.`);
    }

    if (sellingPrice !== 0 && !sellingPrice) {
      errors.push(`판매 가격은 필수 항목입니다.`);
    }

    errors.forEach((error) => {
      toast.error(error);
    });

    return errors.length === 0;
  }

  async function createRecord() {
    if (!validate()) {
      return;
    }

    const result: any = await API.graphql({
      query: createProduct,
      variables: {
        input: {
          ...input,
          shopId: currentShopId!,
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    dispatch(fetchProductsByShopId(currentShopId!) as any);

    setInput(defaultInput());
  }

  async function deleteRecord(id: string, _version: number) {
    if (!confirm("삭제하시겠습니까?")) {
      return;
    }
    const result: any = await API.graphql(
      graphqlOperation(deleteProduct, {
        input: {
          id,
          _version,
        },
      })
    );

    dispatch(fetchProductsByShopId(currentShopId!) as any);
  }

  function onBrandItemChange(id: string) {
    setSelectedBrand(brands.find((brand) => brand.id === id));
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h4>상품 설정</h4>
            <hr />
            <Table>
              <thead>
                <tr>
                  <th>브랜드</th>
                  <th>상품 이름</th>
                  <th>매입 가격</th>
                  <th>판매 가격</th>
                  <th>등록일</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {products.map((item) => {
                  return (
                    <ProductRow
                      key={item.id}
                      item={item}
                      deleteRecord={deleteRecord}
                    />
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                브랜드
              </Form.Label>
              <Col sm="8">
                <Form.Select
                  onChange={(event) => {
                    if (event.target.value) {
                      onBrandItemChange(event.target.value);
                    } else {
                      setSelectedBrand(undefined);
                    }
                  }}
                >
                  <option value="">-선택-</option>
                  {brands.map((brand) => {
                    return (
                      <option key={brand.id} value={brand.id}>
                        {brand.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                이름
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  value={input?.name}
                  onChange={(event) => {
                    setInput({
                      ...input,
                      name: event.target.value,
                    });
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                매입 가격
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  value={input?.costPrice}
                  onChange={(event) => {
                    setInput({
                      ...input,
                      costPrice: event.target.value
                        ? Number(event.target.value)
                        : 0,
                    });
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                판매 가격
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  value={input?.sellingPrice}
                  onChange={(event) => {
                    setInput({
                      ...input,
                      sellingPrice: event.target.value
                        ? Number(event.target.value)
                        : 0,
                    });
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Button size="sm" onClick={createRecord}>
              등록
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
