import { useState, useEffect } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { Chip } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import consultationConstants from "../../../../app/constants/consultation";
import {
  ConsultationSolutionPreset,
  CreateConsultationInput,
  UpdateConsultationInput,
} from "../../../../graphql/API";
import SingleSelectionFromCategorizedStringArray from "../SingleSelectionFromCategorizedStringArray";
import SubTitle from "../SubTitle";
import stylesStepTwo from "../StepTwo/StepTwo.module.scss";
import styles from "./index.module.scss";
import QuestionTitle from "../QuestionTitle";
import SingleSelectionFromStringArray from "../SingleSelectionFromStringArray";
import { ConsultationMode } from "../../../../models";
import APIGateway from "../../../../api/api-gateway";
import { useAppSelector } from "../../../../app/hooks";
import { selectStudyMode } from "../../../../features/consultation";
import MultiSelectionFromCategorizedStringArray from "../MultiSelectionFromCategorizedStringArray";

function PresetButtonList({
  presets,
  activePresets,
  togglePreset,
  fieldName,
}: {
  presets: ConsultationSolutionPreset[];
  activePresets: string[];
  togglePreset: (preset: ConsultationSolutionPreset, key: string) => void;
  fieldName: string;
}) {
  return (
    <div>
      {presets
        .filter((preset) => preset.category === fieldName)
        .map((preset) => {
          return (
            <Button
              size="sm"
              variant={
                activePresets.includes(preset.id)
                  ? "success"
                  : "outline-success"
              }
              onClick={() => {
                togglePreset(preset, fieldName);
              }}
              style={{ marginRight: 5, marginBottom: 2 }}
            >
              {preset.sentence}
            </Button>
          );
        })}
    </div>
  );
}

function Recommendation({ label }: { label: string }) {
  return (
    <Chip
      color="default"
      variant="outlined"
      style={{ marginRight: 5, marginBottom: 5 }}
      label={label}
    ></Chip>
  );
}

export default function StepThree({
  input,
  setInput,
}: {
  input: CreateConsultationInput | UpdateConsultationInput;
  setInput: any;
}) {
  const fullMode = input.mode === ConsultationMode.FULL || !input.mode;
  const cutMode = input.mode === ConsultationMode.CUT;
  const colorMode = input.mode === ConsultationMode.COLOR;
  const permMode = input.mode === ConsultationMode.PERM;

  const studyMode = useAppSelector(selectStudyMode);

  const [isMobile, setIsMobile] = useState(false);
  const [presets, setPresets] = useState<ConsultationSolutionPreset[]>([]);
  const [activePresets, setActivePresets] = useState<string[]>([]);

  const apiGateway = new APIGateway();

  useEffect(() => {
    function detectMobileDevice(agent: any) {
      const mobileRegex = [/Android/i, /iPhone/i, /iPod/i, /BlackBerry/i];
      return mobileRegex.some((mobile) => agent.match(mobile));
    }

    const mobile = detectMobileDevice(window.navigator.userAgent);
    setIsMobile(mobile);

    fetchSolutionPresets();
  }, []);

  async function fetchSolutionPresets() {
    const presets = await apiGateway.consultationSolutionPreset.list();
    setPresets(presets);
  }

  function togglePreset(preset: ConsultationSolutionPreset, key: string) {
    let newActivePresets: string[] = [];
    if (activePresets.includes(preset.id)) {
      newActivePresets = activePresets.filter((id) => id !== preset.id);
    } else {
      newActivePresets = [...activePresets, preset.id];
    }

    setActivePresets(newActivePresets);

    const newSentence = presets
      .filter(
        (item) => item.category === key && newActivePresets.includes(item.id)
      )
      .map((item) => item.sentence)
      .join(" & ");

    setInput({
      ...input,
      solution: {
        ...(input?.solution ?? {}),
        [key]: newSentence,
      },
    });
  }

  function toggleSelection(
    selected: string,
    inputArray: any,
    inputFieldName: string
  ) {
    if (!input) {
      return;
    }

    if (!inputArray) {
      setInput({
        ...input,
        hairRecommendation: {
          ...(input.hairRecommendation ?? {}),
          [inputFieldName]: selected,
        },
      });
    } else {
      if (inputArray.includes(selected)) {
        setInput({
          ...input,
          hairRecommendation: {
            ...(input.hairRecommendation ?? {}),
            [inputFieldName]: inputArray.filter((item: string) => {
              return item !== selected;
            }),
          },
        });
      } else {
        setInput({
          ...input,
          hairRecommendation: {
            ...(input.hairRecommendation ?? {}),
            [inputFieldName]: [...inputArray, selected],
          },
        });
      }
    }
  }

  const gender: "female" | "male" =
    (input?.gender?.toLowerCase() as any) ?? "female";

  const bangsRecommendations: string[] = [];
  const volumeRecommendations: string[] = [];
  const haircutsRecommendations: string[] = [];

  const faceShapeList = consultationConstants.faceShape.filter((item) =>
    input.faceShapes?.includes(item.name)
  );

  faceShapeList.forEach((item) => {
    item.recommendationValues?.forEach((solution) => {
      if (solution.category === "bangs") {
        solution.values.forEach((value) => {
          bangsRecommendations.push(value);
        });
      } else if (solution.category === "hairVolumes") {
        solution.values.forEach((value) => {
          volumeRecommendations.push(value);
        });
      } else if (solution.category === "haircuts") {
        solution.values.forEach((value) => {
          haircutsRecommendations.push(value);
        });
      }
    });
  });

  return (
    <Container className={styles.StepContainer}>
      <Row className="bg-white sticky-header">
        <Col>
          <SubTitle prefix="STEP 3" title="디자이너 진단 솔루션" />
        </Col>
      </Row>
      <Row
        className={
          fullMode || cutMode || permMode
            ? styles.QuestionBoxMobilePadding
            : "d-none"
        }
      >
        <Col>
          <Form.Group as={Row} className={stylesStepTwo.FirstQuestionBox}>
            <QuestionTitle title="앞머리" />
            <Col className={stylesStepTwo.ButtonBox}>
              <SingleSelectionFromCategorizedStringArray
                constants={
                  consultationConstants.hairRecommendation.bangs[gender]
                }
                inputValue={input?.hairRecommendation?.bangs ?? ""}
                inputFieldName="bangs"
                onClick={toggleSelection}
                recommendations={bangsRecommendations}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row
        className={
          fullMode || cutMode || permMode
            ? styles.QuestionBoxMobilePadding
            : "d-none"
        }
      >
        <Col>
          <Form.Group as={Row} className={stylesStepTwo.QuestionBox}>
            <QuestionTitle title="길이" />
            <Col className={stylesStepTwo.ButtonBox}>
              <SingleSelectionFromCategorizedStringArray
                constants={
                  consultationConstants.hairRecommendation.hairLength[gender]
                }
                inputValue={input?.hairRecommendation?.hairLength ?? ""}
                inputFieldName="hairLength"
                onClick={toggleSelection}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row
        className={
          fullMode || cutMode || permMode
            ? styles.QuestionBoxMobilePadding
            : "d-none"
        }
      >
        <Col>
          <Form.Group as={Row} className={stylesStepTwo.QuestionBox}>
            <QuestionTitle title="볼륨" />
            <Col className={stylesStepTwo.ButtonBox}>
              <MultiSelectionFromCategorizedStringArray
                constants={
                  consultationConstants.hairRecommendation.hairVolume[gender]
                }
                inputArray={input?.hairRecommendation?.hairVolumes ?? []}
                inputFieldName="hairVolumes"
                onClick={toggleSelection}
                recommendations={volumeRecommendations}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row
        className={
          fullMode || cutMode || permMode
            ? styles.QuestionBoxMobilePadding
            : "d-none"
        }
      >
        <Col>
          <Form.Group as={Row} className={stylesStepTwo.QuestionBox}>
            <QuestionTitle title="커트" />
            <Col className={stylesStepTwo.ButtonBox}>
              <MultiSelectionFromCategorizedStringArray
                constants={
                  consultationConstants.hairRecommendation.haircut[
                    gender
                  ] as any
                }
                inputArray={input?.hairRecommendation?.haircuts ?? []}
                inputFieldName="haircuts"
                onClick={toggleSelection}
                recommendations={haircutsRecommendations}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row
        className={
          fullMode || colorMode ? styles.QuestionBoxMobilePadding : "d-none"
        }
      >
        <Col>
          <Form.Group
            as={Row}
            className={
              colorMode
                ? stylesStepTwo.FirstQuestionBox
                : stylesStepTwo.QuestionBox
            }
          >
            <QuestionTitle title="컬러메뉴" />
            <Col className={stylesStepTwo.ButtonBox}>
              <SingleSelectionFromCategorizedStringArray
                constants={
                  consultationConstants.hairRecommendation.hairColorMenu
                }
                inputValue={input?.hairRecommendation?.hairColorMenu ?? ""}
                inputFieldName="hairColorMenu"
                onClick={toggleSelection}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row
        className={
          fullMode || colorMode ? styles.QuestionBoxMobilePadding : "d-none"
        }
      >
        <Col>
          <Form.Group as={Row} className={stylesStepTwo.QuestionBox}>
            <QuestionTitle title="컬러타입" />
            <Col className={stylesStepTwo.ButtonBox}>
              <SingleSelectionFromCategorizedStringArray
                constants={
                  consultationConstants.hairRecommendation.hairColorType
                }
                inputValue={input?.hairRecommendation?.hairColorType ?? ""}
                inputFieldName="hairColorType"
                onClick={toggleSelection}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row
        className={
          fullMode || permMode ? styles.QuestionBoxMobilePadding : "d-none"
        }
      >
        <Col>
          <Form.Group as={Row} className={stylesStepTwo.QuestionBox}>
            <QuestionTitle title="펌" />
            <Col className={stylesStepTwo.ButtonBox}>
              <SingleSelectionFromCategorizedStringArray
                constants={
                  consultationConstants.hairRecommendation.hairFirm[gender]
                }
                inputValue={input?.hairRecommendation?.hairFirm ?? ""}
                inputFieldName="hairFirm"
                onClick={toggleSelection}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row
        className={
          fullMode || colorMode || permMode
            ? styles.QuestionBoxMobilePadding
            : "d-none"
        }
      >
        <Col>
          <Form.Group as={Row} className={stylesStepTwo.QuestionBox}>
            <QuestionTitle title="케어" />
            <Col className={stylesStepTwo.ButtonBox}>
              <SingleSelectionFromStringArray
                constants={consultationConstants.hairRecommendation.hairCare}
                inputValue={input?.hairRecommendation?.hairCare ?? ""}
                inputFieldName="hairCare"
                onClick={toggleSelection}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className={styles.SolutionArea}>
            <div className={styles.SolutionTitle}>Solution</div>
            <div className={styles.SolutionContent}>
              <div>Today’s Note</div>
              <div className={styles.SolutionQuestionFirstGroup}>
                <div>
                  <span>얼굴형 특징</span>
                  {(input.faceShapes?.length ?? 0) > 0 && (
                    <ul>
                      {input.faceShapes?.map((faceShape) => {
                        const info = consultationConstants.faceShape.find(
                          (item) => item.name === faceShape
                        );
                        if (!info) {
                          return "";
                        }
                        return (
                          <li key={faceShape}>
                            {info.title}{" "}
                            <span style={{ color: "rgba(120, 119, 116, 1)" }}>
                              {info.description}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  <div>
                    <input
                      placeholder="Step 2 얼굴형이 자동 입력됩니다. 추가 안내 사항만 적어 주세요."
                      value={input?.solution?.face ?? ""}
                      onChange={(event) => {
                        setInput({
                          ...input,
                          solution: {
                            ...(input?.solution ?? {}),
                            face: event.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
                <PresetButtonList
                  presets={presets}
                  activePresets={activePresets}
                  togglePreset={togglePreset}
                  fieldName="face"
                />
                {(input?.frontHeadShape || input?.sideHeadShape) && (
                  <div>
                    <span>두상 특징</span>
                    <ul style={{ marginBottom: 30 }}>
                      {input.frontHeadShape && (
                        <li>
                          {
                            consultationConstants.frontHeadShape.find(
                              (item) => item.name === input.frontHeadShape
                            )?.description
                          }
                        </li>
                      )}
                      {input.sideHeadShape && (
                        <li>
                          {
                            consultationConstants.sideHeadShape.find(
                              (item) => item.name === input.sideHeadShape
                            )?.description
                          }
                        </li>
                      )}
                    </ul>
                  </div>
                )}
                <div>
                  <span>모발 상태</span>
                  <div className={styles.HairStatusChips}>
                    {input.damageDegreeOfHair && (
                      <Recommendation
                        label={`손상도: ${input.damageDegreeOfHair}`}
                      />
                    )}
                    {input.amountOfHair && (
                      <Recommendation label={`모량: ${input.amountOfHair}`} />
                    )}
                    {input.curlDegreeOfHair && (
                      <Recommendation
                        label={`모질: ${input.curlDegreeOfHair}`}
                      />
                    )}
                    {input.thicknessOfHair && (
                      <Recommendation
                        label={`모발 굵기: ${input.thicknessOfHair}`}
                      />
                    )}
                  </div>
                  <input
                    placeholder="Step 2 모발 상태가 자동 입력됩니다. 추가 안내 사항만 적어 주세요."
                    value={input?.solution?.hair ?? ""}
                    onChange={(event) => {
                      setInput({
                        ...input,
                        solution: {
                          ...(input?.solution ?? {}),
                          hair: event.target.value,
                        },
                      });
                    }}
                  />
                </div>
                <PresetButtonList
                  presets={presets}
                  activePresets={activePresets}
                  togglePreset={togglePreset}
                  fieldName="hair"
                />
                <div>
                  <span>니즈</span>
                  <input
                    placeholder="고객의 니즈"
                    value={input?.solution?.needs ?? ""}
                    onChange={(event) => {
                      setInput({
                        ...input,
                        solution: {
                          ...(input?.solution ?? {}),
                          needs: event.target.value,
                        },
                      });
                    }}
                  />
                </div>
                <PresetButtonList
                  presets={presets}
                  activePresets={activePresets}
                  togglePreset={togglePreset}
                  fieldName="needs"
                />
              </div>
              <div className={styles.SolutaionQuestionLastGroup}>
                <div className={styles.SolutaionQuestionLastGroupQuestionBox}>
                  <span>오늘 헤어 시술 포인트</span>
                  <div>
                    <textarea
                      placeholder="오늘 헤어 시술의 포인트 (시술 디자인,
            어떤 부분을 신경써서 시술했는지)"
                      value={input?.solution?.point ?? ""}
                      onChange={(event) => {
                        setInput({
                          ...input,
                          solution: {
                            ...(input?.solution ?? {}),
                            point: event.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <PresetButtonList
                    presets={presets}
                    activePresets={activePresets}
                    togglePreset={togglePreset}
                    fieldName="point"
                  />
                </div>
                <div className={styles.SolutaionQuestionLastGroupQuestionBox}>
                  <span>관리 방법</span>
                  <div>
                    <textarea
                      placeholder="시술 후 홈케어할 때 팁 (샴푸 방법, 드라이방법, 제품 추천 등)"
                      value={input?.solution?.aftercareTips ?? ""}
                      onChange={(event) => {
                        setInput({
                          ...input,
                          solution: {
                            ...(input?.solution ?? {}),
                            aftercareTips: event.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <PresetButtonList
                    presets={presets}
                    activePresets={activePresets}
                    togglePreset={togglePreset}
                    fieldName="aftercareTips"
                  />
                </div>
                <div className={styles.SolutaionQuestionLastGroupQuestionBox}>
                  <span>스타일링 방법</span>
                  <div>
                    <textarea
                      placeholder="드라이 및 스타일링 할 때의 팁 (드라이 할 때 연출방법, 제품 추천 등)"
                      value={input?.solution?.stylingTips ?? ""}
                      onChange={(event) => {
                        setInput({
                          ...input,
                          solution: {
                            ...(input?.solution ?? {}),
                            stylingTips: event.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <PresetButtonList
                    presets={presets}
                    activePresets={activePresets}
                    togglePreset={togglePreset}
                    fieldName="stylingTips"
                  />
                </div>
                <div className={styles.SolutaionQuestionLastGroupQuestionBox}>
                  <span>주의사항</span>
                  <div>
                    <textarea
                      placeholder="주의사항! (24시간 샴푸 금지, 머리 묶지 않기, 모자 금지 등)"
                      value={input?.solution?.precautions ?? ""}
                      onChange={(event) => {
                        setInput({
                          ...input,
                          solution: {
                            ...(input?.solution ?? {}),
                            precautions: event.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <PresetButtonList
                    presets={presets}
                    activePresets={activePresets}
                    togglePreset={togglePreset}
                    fieldName="precautions"
                  />
                </div>
                <div className={styles.SolutaionQuestionLastGroupQuestionBox}>
                  <span>추천 시술</span>
                  <div className={styles.HairRecommendationChips}>
                    {input?.hairRecommendation?.bangs && (
                      <Recommendation
                        label={`앞머리: ${input?.hairRecommendation?.bangs}`}
                      />
                    )}
                    {input?.hairRecommendation?.hairLength && (
                      <Recommendation
                        label={`길이: ${input?.hairRecommendation?.hairLength}`}
                      />
                    )}
                    {!input?.hairRecommendation?.hairVolumes &&
                      input?.hairRecommendation?.hairVolume && (
                        <Recommendation
                          label={`볼륨: ${input?.hairRecommendation?.hairVolume}`}
                        />
                      )}
                    {!input?.hairRecommendation?.haircuts &&
                      input?.hairRecommendation?.haircut && (
                        <Recommendation
                          label={`커트: ${input?.hairRecommendation?.haircut}`}
                        />
                      )}
                    {(input?.hairRecommendation?.hairVolumes?.length ?? 0) >
                      0 && (
                      <Recommendation
                        label={`볼륨: ${input?.hairRecommendation?.hairVolumes?.join(
                          ", "
                        )}`}
                      />
                    )}
                    {(input?.hairRecommendation?.haircuts?.length ?? 0) > 0 && (
                      <Recommendation
                        label={`커트: ${input?.hairRecommendation?.haircuts?.join(
                          ", "
                        )}`}
                      />
                    )}
                    {input?.hairRecommendation?.hairColorMenu && (
                      <Recommendation
                        label={`컬러 메뉴: ${input?.hairRecommendation?.hairColorMenu}`}
                      />
                    )}
                    {input?.hairRecommendation?.hairColorType && (
                      <Chip
                        label={`컬러 타입: ${input?.hairRecommendation?.hairColorType}`}
                        style={{
                          backgroundColor: (
                            consultationConstants.hairRecommendation
                              .hairColorTypeHash as any
                          )[input?.hairRecommendation?.hairColorType],
                          marginRight: 5,
                          marginBottom: 5,
                          color: "white",
                        }}
                      />
                    )}
                    {input?.hairRecommendation?.hairFirm && (
                      <Recommendation
                        label={`펌: ${input?.hairRecommendation?.hairFirm}`}
                      />
                    )}
                    {input?.hairRecommendation?.hairCare && (
                      <Recommendation
                        label={`케어: ${input?.hairRecommendation?.hairCare}`}
                      />
                    )}
                  </div>
                  <div>
                    <textarea
                      placeholder="추천 시술은 Step 3 진단 솔루션을 선택하면 자동으로 입력됩니다. 추가 안내 사항만 적어 주세요."
                      value={input?.solution?.recommendedTreatment ?? ""}
                      onChange={(event) => {
                        setInput({
                          ...input,
                          solution: {
                            ...(input?.solution ?? {}),
                            recommendedTreatment: event.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <PresetButtonList
                    presets={presets}
                    activePresets={activePresets}
                    togglePreset={togglePreset}
                    fieldName="recommendedTreatment"
                  />
                </div>
                <div className={styles.SolutaionQuestionLastGroupQuestionBox}>
                  <span>참고 링크</span>
                  <div>
                    <input
                      placeholder="https://..."
                      value={input?.solution?.link ?? ""}
                      onChange={(event) => {
                        setInput({
                          ...input,
                          solution: {
                            ...(input?.solution ?? {}),
                            link: event.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.SolutaionQuestionLastGroupQuestionBox}>
                  <span>다음 방문 일자</span>
                  <div>
                    <DesktopDatePicker
                      value={
                        input?.solution?.nextVisitDate
                          ? dayjs(input?.solution?.nextVisitDate)
                          : null
                      }
                      inputFormat="YYYY-MM-DD"
                      toolbarFormat="MM월 DD일"
                      onChange={(newValue) => {
                        if (!newValue) {
                          return;
                        }
                        setInput({
                          ...input,
                          solution: {
                            ...(input?.solution ?? {}),
                            nextVisitDate: newValue.format("YYYY-MM-DD"),
                          },
                        });
                      }}
                      onAccept={(newValue) => {
                        if (!newValue) {
                          return;
                        }
                        setInput({
                          ...input,
                          solution: {
                            ...(input?.solution ?? {}),
                            nextVisitDate: newValue.format("YYYY-MM-DD"),
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                    <div style={{ width: "70%" }}></div>
                  </div>
                </div>
              </div>

              <div>다음 예약에 뵙겠습니다. 감사합니다.</div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
