import React, { useEffect, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import { selectBooking } from "../../../features/booking";
import {
  fetchSelfDiagnosisById,
  resetSelfDiagnosis,
  selectSelfDiagnosis,
  selectSelfDiagnosisPhotoUrls,
} from "../../../features/self-diagnosis";

type Props = {
  show: boolean;
  setShow: any;
};

export default function SelfDiagnosisV2Modal({ show, setShow }: Props) {
  const dispatch = useDispatch();

  const booking = useAppSelector(selectBooking);

  const selfDiagnosis = useAppSelector(selectSelfDiagnosis);
  const photoUrls = useAppSelector(selectSelfDiagnosisPhotoUrls);

  const handleClose = () => {
    setShow(false);
    dispatch(resetSelfDiagnosis());
  };

  useEffect(() => {
    if (!booking) {
      return;
    }

    if (!booking.hasSelfDiagnosis) {
      return;
    }

    dispatch(fetchSelfDiagnosisById(booking.id) as any);
  }, [booking?.id]);

  if (!booking || !selfDiagnosis) {
    return <></>;
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} scrollable>
        <Modal.Header closeButton>
          <Modal.Title>셀프 진단</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Table>
                  <colgroup>
                    <col width="25%"></col>
                    <col width="25%"></col>
                    <col width="25%"></col>
                    <col width="25%"></col>
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>고객명</th>
                      <td>
                        {booking.customer?.name || ""} | {booking.designerName}
                      </td>
                      <th>예약 일시</th>
                      <td>
                        {booking.date} {booking.time}
                      </td>
                    </tr>
                    <tr>
                      <th>성별</th>
                      <td>{selfDiagnosis.gender}</td>
                      <th>방문 경로</th>
                      <td>{selfDiagnosis.inflowChannel}</td>
                    </tr>
                    <tr>
                      <th>샴푸 제품</th>
                      <td>{selfDiagnosis.productsForShampooing?.join(", ")}</td>
                      <th>드라이 및 세팅</th>
                      <td>{selfDiagnosis.dry ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <th>스타일링 제품</th>
                      <td>{selfDiagnosis.productsForStyling?.join(", ")}</td>
                      <th>관심 시술</th>
                      <td>
                        {selfDiagnosis.treatmentsInterestedIn?.join(", ")}
                      </td>
                    </tr>
                    <tr>
                      <th>방문 주기</th>
                      <td>{selfDiagnosis.frequencyOfVisits}</td>
                      <th>모발/두피 고민</th>
                      <td>{selfDiagnosis.concerns?.join(", ")}</td>
                    </tr>
                    <tr>
                      <th>의상 스타일</th>
                      <td colSpan={3}>
                        {selfDiagnosis.outfitStyles?.join(", ")}
                      </td>
                    </tr>
                    <tr>
                      <th>블랙 염색 여부</th>
                      <td>{selfDiagnosis.blackDyedHair ? "Yes" : "No"}</td>
                      <th>탈색 여부</th>
                      <td>{selfDiagnosis.bleachedHair ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <th>최근 염색 시기</th>
                      <td>{selfDiagnosis.recentDyeing}</td>
                      <th>최근 펌 시기</th>
                      <td>{selfDiagnosis.recentPerm}</td>
                    </tr>
                    <tr>
                      <th>원하는 스타일 사진</th>
                      <td colSpan={3}>
                        <div>
                          {photoUrls?.map((photoUrl) => {
                            return (
                              <img
                                src={photoUrl}
                                key={photoUrl}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: 250,
                                  objectFit: "contain",
                                  marginRight: 10,
                                }}
                              />
                            );
                          })}
                          {!photoUrls?.length && "없음"}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
