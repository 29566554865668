/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type PayBookingInput = {
  paidByCardAmount?: number | null,
  paidInCashAmount?: number | null,
  paidByPrepaidPassAmount?: number | null,
  paymentMemo?: string | null,
};

export type MutationResponse = {
  __typename: "MutationResponse",
  success: boolean,
  reason?: string | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type InvokeNaverOpenAPIInput = {
  templateCode: string,
  consultationId?: string | null,
  bookingId?: string | null,
  shopId?: string | null,
  force?: boolean | null,
};

export type CreateDiscountPresetInput = {
  id?: string | null,
  shopId: string,
  name: string,
  amount?: number | null,
  rate?: number | null,
  type: DiscountMode,
  enabled: boolean,
  _version?: number | null,
};

export enum DiscountMode {
  AMOUNT = "AMOUNT",
  RATE = "RATE",
}


export type ModelDiscountPresetConditionInput = {
  shopId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  rate?: ModelFloatInput | null,
  type?: ModelDiscountModeInput | null,
  enabled?: ModelBooleanInput | null,
  and?: Array< ModelDiscountPresetConditionInput | null > | null,
  or?: Array< ModelDiscountPresetConditionInput | null > | null,
  not?: ModelDiscountPresetConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelDiscountModeInput = {
  eq?: DiscountMode | null,
  ne?: DiscountMode | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type DiscountPreset = {
  __typename: "DiscountPreset",
  id: string,
  shopId: string,
  name: string,
  amount?: number | null,
  rate?: number | null,
  type: DiscountMode,
  enabled: boolean,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateDiscountPresetInput = {
  id: string,
  shopId?: string | null,
  name?: string | null,
  amount?: number | null,
  rate?: number | null,
  type?: DiscountMode | null,
  enabled?: boolean | null,
  _version?: number | null,
};

export type DeleteDiscountPresetInput = {
  id: string,
  _version?: number | null,
};

export type CreateDesignerInput = {
  id?: string | null,
  shopId: string,
  name: string,
  naverName?: string | null,
  businessId?: string | null,
  bizItemId?: string | null,
  phoneNumber?: string | null,
  imageUrl?: string | null,
  s3Key?: string | null,
  userId?: string | null,
  youtubeUrl?: string | null,
  instagramUrl?: string | null,
  inactive?: boolean | null,
  _version?: number | null,
};

export type ModelDesignerConditionInput = {
  shopId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  naverName?: ModelStringInput | null,
  businessId?: ModelStringInput | null,
  bizItemId?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  s3Key?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  youtubeUrl?: ModelStringInput | null,
  instagramUrl?: ModelStringInput | null,
  inactive?: ModelBooleanInput | null,
  and?: Array< ModelDesignerConditionInput | null > | null,
  or?: Array< ModelDesignerConditionInput | null > | null,
  not?: ModelDesignerConditionInput | null,
};

export type Designer = {
  __typename: "Designer",
  id: string,
  shopId: string,
  name: string,
  naverName?: string | null,
  businessId?: string | null,
  bizItemId?: string | null,
  phoneNumber?: string | null,
  imageUrl?: string | null,
  s3Key?: string | null,
  userId?: string | null,
  youtubeUrl?: string | null,
  instagramUrl?: string | null,
  inactive?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateDesignerInput = {
  id: string,
  shopId?: string | null,
  name?: string | null,
  naverName?: string | null,
  businessId?: string | null,
  bizItemId?: string | null,
  phoneNumber?: string | null,
  imageUrl?: string | null,
  s3Key?: string | null,
  userId?: string | null,
  youtubeUrl?: string | null,
  instagramUrl?: string | null,
  inactive?: boolean | null,
  _version?: number | null,
};

export type DeleteDesignerInput = {
  id: string,
  _version?: number | null,
};

export type CreateFeedbackInput = {
  bookingId: string,
  category: FeedbackCategory,
  shopId: string,
  shopName: string,
  designerName: string,
  designerId: string,
  date: string,
  satisfaction: number,
  comment?: string | null,
  supportMessage?: string | null,
  modelVersion: number,
  _version?: number | null,
};

export enum FeedbackCategory {
  SERVICE = "SERVICE",
}


export type ModelFeedbackConditionInput = {
  category?: ModelFeedbackCategoryInput | null,
  shopId?: ModelIDInput | null,
  shopName?: ModelStringInput | null,
  designerName?: ModelStringInput | null,
  designerId?: ModelIDInput | null,
  date?: ModelStringInput | null,
  satisfaction?: ModelIntInput | null,
  comment?: ModelStringInput | null,
  supportMessage?: ModelStringInput | null,
  modelVersion?: ModelIntInput | null,
  and?: Array< ModelFeedbackConditionInput | null > | null,
  or?: Array< ModelFeedbackConditionInput | null > | null,
  not?: ModelFeedbackConditionInput | null,
};

export type ModelFeedbackCategoryInput = {
  eq?: FeedbackCategory | null,
  ne?: FeedbackCategory | null,
};

export type Feedback = {
  __typename: "Feedback",
  bookingId: string,
  category: FeedbackCategory,
  shopId: string,
  shopName: string,
  designerName: string,
  designerId: string,
  date: string,
  satisfaction: number,
  comment?: string | null,
  supportMessage?: string | null,
  modelVersion: number,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateFeedbackInput = {
  bookingId: string,
  category?: FeedbackCategory | null,
  shopId?: string | null,
  shopName?: string | null,
  designerName?: string | null,
  designerId?: string | null,
  date?: string | null,
  satisfaction?: number | null,
  comment?: string | null,
  supportMessage?: string | null,
  modelVersion?: number | null,
  _version?: number | null,
};

export type DeleteFeedbackInput = {
  bookingId: string,
  _version?: number | null,
};

export type CreateRegularCustomerNoteInput = {
  bookingId: string,
  shopId: string,
  shopName?: string | null,
  customerId: string,
  customerName: string,
  levelOfStyleChange: string,
  nextSchedule?: string | null,
  comment?: string | null,
  productsInterestedIn?: string | null,
  hasPhotos?: boolean | null,
  photos?: Array< NestedPhotoInput > | null,
  modelVersion: number,
  _version?: number | null,
};

export type NestedPhotoInput = {
  s3Key: string,
  displayOrder: number,
};

export type ModelRegularCustomerNoteConditionInput = {
  shopId?: ModelIDInput | null,
  shopName?: ModelStringInput | null,
  customerId?: ModelIDInput | null,
  customerName?: ModelStringInput | null,
  levelOfStyleChange?: ModelStringInput | null,
  nextSchedule?: ModelStringInput | null,
  comment?: ModelStringInput | null,
  productsInterestedIn?: ModelStringInput | null,
  hasPhotos?: ModelBooleanInput | null,
  modelVersion?: ModelIntInput | null,
  and?: Array< ModelRegularCustomerNoteConditionInput | null > | null,
  or?: Array< ModelRegularCustomerNoteConditionInput | null > | null,
  not?: ModelRegularCustomerNoteConditionInput | null,
};

export type RegularCustomerNote = {
  __typename: "RegularCustomerNote",
  bookingId: string,
  shopId: string,
  shopName?: string | null,
  customerId: string,
  customerName: string,
  levelOfStyleChange: string,
  nextSchedule?: string | null,
  comment?: string | null,
  productsInterestedIn?: string | null,
  hasPhotos?: boolean | null,
  photos?:  Array<NestedPhoto > | null,
  modelVersion: number,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type NestedPhoto = {
  __typename: "NestedPhoto",
  s3Key: string,
  displayOrder: number,
};

export type UpdateRegularCustomerNoteInput = {
  bookingId: string,
  shopId?: string | null,
  shopName?: string | null,
  customerId?: string | null,
  customerName?: string | null,
  levelOfStyleChange?: string | null,
  nextSchedule?: string | null,
  comment?: string | null,
  productsInterestedIn?: string | null,
  hasPhotos?: boolean | null,
  photos?: Array< NestedPhotoInput > | null,
  modelVersion?: number | null,
  _version?: number | null,
};

export type DeleteRegularCustomerNoteInput = {
  bookingId: string,
  _version?: number | null,
};

export type CreateUserPushTokenRequestInput = {
  id?: string | null,
  token: string,
  owner?: string | null,
  _version?: number | null,
};

export type ModelUserPushTokenRequestConditionInput = {
  token?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelUserPushTokenRequestConditionInput | null > | null,
  or?: Array< ModelUserPushTokenRequestConditionInput | null > | null,
  not?: ModelUserPushTokenRequestConditionInput | null,
};

export type UserPushTokenRequest = {
  __typename: "UserPushTokenRequest",
  id: string,
  token: string,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateUserPushTokenRequestInput = {
  id: string,
  token?: string | null,
  owner?: string | null,
  _version?: number | null,
};

export type DeleteUserPushTokenRequestInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserPushTokenInput = {
  token: string,
  owner?: string | null,
  _version?: number | null,
};

export type ModelUserPushTokenConditionInput = {
  owner?: ModelStringInput | null,
  and?: Array< ModelUserPushTokenConditionInput | null > | null,
  or?: Array< ModelUserPushTokenConditionInput | null > | null,
  not?: ModelUserPushTokenConditionInput | null,
};

export type UserPushToken = {
  __typename: "UserPushToken",
  token: string,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateUserPushTokenInput = {
  token: string,
  owner?: string | null,
  _version?: number | null,
};

export type DeleteUserPushTokenInput = {
  token: string,
  _version?: number | null,
};

export type CreateCustomerV2Input = {
  id?: string | null,
  owner?: string | null,
  name: string,
  phoneNumber: string,
  lastFourPhoneNumbers: string,
  firstVisitDate?: string | null,
  occupation?: string | null,
  drink?: string | null,
  visitCount?: number | null,
  selfCount?: number | null,
  regularCount?: number | null,
  caringCount?: number | null,
  comment?: string | null,
  modelVersion?: number | null,
  cut?: boolean | null,
  perm?: boolean | null,
  color?: boolean | null,
  clinic?: boolean | null,
  gender?: string | null,
  _version?: number | null,
};

export type ModelCustomerV2ConditionInput = {
  owner?: ModelStringInput | null,
  name?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  lastFourPhoneNumbers?: ModelStringInput | null,
  firstVisitDate?: ModelStringInput | null,
  occupation?: ModelStringInput | null,
  drink?: ModelStringInput | null,
  visitCount?: ModelIntInput | null,
  selfCount?: ModelIntInput | null,
  regularCount?: ModelIntInput | null,
  caringCount?: ModelIntInput | null,
  comment?: ModelStringInput | null,
  modelVersion?: ModelIntInput | null,
  cut?: ModelBooleanInput | null,
  perm?: ModelBooleanInput | null,
  color?: ModelBooleanInput | null,
  clinic?: ModelBooleanInput | null,
  gender?: ModelStringInput | null,
  and?: Array< ModelCustomerV2ConditionInput | null > | null,
  or?: Array< ModelCustomerV2ConditionInput | null > | null,
  not?: ModelCustomerV2ConditionInput | null,
};

export type CustomerV2 = {
  __typename: "CustomerV2",
  id: string,
  owner?: string | null,
  name: string,
  phoneNumber: string,
  lastFourPhoneNumbers: string,
  firstVisitDate?: string | null,
  occupation?: string | null,
  drink?: string | null,
  visitCount?: number | null,
  selfCount?: number | null,
  regularCount?: number | null,
  caringCount?: number | null,
  comment?: string | null,
  modelVersion?: number | null,
  cut?: boolean | null,
  perm?: boolean | null,
  color?: boolean | null,
  clinic?: boolean | null,
  gender?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateCustomerV2Input = {
  id: string,
  owner?: string | null,
  name?: string | null,
  phoneNumber?: string | null,
  lastFourPhoneNumbers?: string | null,
  firstVisitDate?: string | null,
  occupation?: string | null,
  drink?: string | null,
  visitCount?: number | null,
  selfCount?: number | null,
  regularCount?: number | null,
  caringCount?: number | null,
  comment?: string | null,
  modelVersion?: number | null,
  cut?: boolean | null,
  perm?: boolean | null,
  color?: boolean | null,
  clinic?: boolean | null,
  gender?: string | null,
  _version?: number | null,
};

export type DeleteCustomerV2Input = {
  id: string,
  _version?: number | null,
};

export type CreateCaringInput = {
  id?: string | null,
  owner?: string | null,
  shopName: string,
  designerName: string,
  name: string,
  phoneNumber: string,
  yearMonth: string,
  date: string,
  sentAt?: string | null,
  timestamp: number,
  pointOfToday: string,
  aftercareTips?: string | null,
  stylingTips?: string | null,
  precautions?: string | null,
  serviceRecommendation?: string | null,
  nextVisitDate?: string | null,
  modelVersion?: number | null,
  customerId?: string | null,
  _version?: number | null,
};

export type ModelCaringConditionInput = {
  owner?: ModelStringInput | null,
  shopName?: ModelStringInput | null,
  designerName?: ModelStringInput | null,
  name?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  yearMonth?: ModelStringInput | null,
  date?: ModelStringInput | null,
  sentAt?: ModelStringInput | null,
  timestamp?: ModelIntInput | null,
  pointOfToday?: ModelStringInput | null,
  aftercareTips?: ModelStringInput | null,
  stylingTips?: ModelStringInput | null,
  precautions?: ModelStringInput | null,
  serviceRecommendation?: ModelStringInput | null,
  nextVisitDate?: ModelStringInput | null,
  modelVersion?: ModelIntInput | null,
  customerId?: ModelIDInput | null,
  and?: Array< ModelCaringConditionInput | null > | null,
  or?: Array< ModelCaringConditionInput | null > | null,
  not?: ModelCaringConditionInput | null,
};

export type Caring = {
  __typename: "Caring",
  id: string,
  owner?: string | null,
  shopName: string,
  designerName: string,
  name: string,
  phoneNumber: string,
  yearMonth: string,
  date: string,
  sentAt?: string | null,
  timestamp: number,
  pointOfToday: string,
  aftercareTips?: string | null,
  stylingTips?: string | null,
  precautions?: string | null,
  serviceRecommendation?: string | null,
  nextVisitDate?: string | null,
  modelVersion?: number | null,
  customerId?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateCaringInput = {
  id: string,
  owner?: string | null,
  shopName?: string | null,
  designerName?: string | null,
  name?: string | null,
  phoneNumber?: string | null,
  yearMonth?: string | null,
  date?: string | null,
  sentAt?: string | null,
  timestamp?: number | null,
  pointOfToday?: string | null,
  aftercareTips?: string | null,
  stylingTips?: string | null,
  precautions?: string | null,
  serviceRecommendation?: string | null,
  nextVisitDate?: string | null,
  modelVersion?: number | null,
  customerId?: string | null,
  _version?: number | null,
};

export type DeleteCaringInput = {
  id: string,
  _version?: number | null,
};

export type CreateSelfDiagnosisRequestInput = {
  id?: string | null,
  owner?: string | null,
  shopName: string,
  designerName: string,
  name: string,
  phoneNumber: string,
  yearMonth: string,
  date?: string | null,
  sentAt?: string | null,
  writtenAt?: string | null,
  timestamp?: number | null,
  modelVersion?: number | null,
  customerId?: string | null,
  _version?: number | null,
};

export type ModelSelfDiagnosisRequestConditionInput = {
  owner?: ModelStringInput | null,
  shopName?: ModelStringInput | null,
  designerName?: ModelStringInput | null,
  name?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  yearMonth?: ModelStringInput | null,
  date?: ModelStringInput | null,
  sentAt?: ModelStringInput | null,
  writtenAt?: ModelStringInput | null,
  timestamp?: ModelIntInput | null,
  modelVersion?: ModelIntInput | null,
  customerId?: ModelIDInput | null,
  and?: Array< ModelSelfDiagnosisRequestConditionInput | null > | null,
  or?: Array< ModelSelfDiagnosisRequestConditionInput | null > | null,
  not?: ModelSelfDiagnosisRequestConditionInput | null,
};

export type SelfDiagnosisRequest = {
  __typename: "SelfDiagnosisRequest",
  id: string,
  owner?: string | null,
  shopName: string,
  designerName: string,
  name: string,
  phoneNumber: string,
  yearMonth: string,
  date?: string | null,
  sentAt?: string | null,
  writtenAt?: string | null,
  timestamp?: number | null,
  modelVersion?: number | null,
  customerId?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateSelfDiagnosisRequestInput = {
  id: string,
  owner?: string | null,
  shopName?: string | null,
  designerName?: string | null,
  name?: string | null,
  phoneNumber?: string | null,
  yearMonth?: string | null,
  date?: string | null,
  sentAt?: string | null,
  writtenAt?: string | null,
  timestamp?: number | null,
  modelVersion?: number | null,
  customerId?: string | null,
  _version?: number | null,
};

export type DeleteSelfDiagnosisRequestInput = {
  id: string,
  _version?: number | null,
};

export type CreateSelfDiagnosisContentInput = {
  requestId: string,
  shopName: string,
  designerName: string,
  name: string,
  yearMonth: string,
  date?: string | null,
  inflowChannel?: string | null,
  gender?: ConsultationGender | null,
  dry?: boolean | null,
  concerns?: Array< string > | null,
  frequencyOfVisits?: string | null,
  outfitStyles?: Array< string > | null,
  treatmentsInterestedIn?: Array< string > | null,
  productsForShampooing?: Array< string > | null,
  productsForStyling?: Array< string > | null,
  recentDyeing?: string | null,
  recentPerm?: string | null,
  hasPhotos?: boolean | null,
  photos?: Array< NestedPhotoInput > | null,
  blackDyedHair?: boolean | null,
  bleachedHair?: boolean | null,
  modelVersion?: number | null,
  customerId?: string | null,
  _version?: number | null,
};

export enum ConsultationGender {
  MALE = "MALE",
  FEMALE = "FEMALE",
}


export type ModelSelfDiagnosisContentConditionInput = {
  shopName?: ModelStringInput | null,
  designerName?: ModelStringInput | null,
  name?: ModelStringInput | null,
  yearMonth?: ModelStringInput | null,
  date?: ModelStringInput | null,
  inflowChannel?: ModelStringInput | null,
  gender?: ModelConsultationGenderInput | null,
  dry?: ModelBooleanInput | null,
  concerns?: ModelStringInput | null,
  frequencyOfVisits?: ModelStringInput | null,
  outfitStyles?: ModelStringInput | null,
  treatmentsInterestedIn?: ModelStringInput | null,
  productsForShampooing?: ModelStringInput | null,
  productsForStyling?: ModelStringInput | null,
  recentDyeing?: ModelStringInput | null,
  recentPerm?: ModelStringInput | null,
  hasPhotos?: ModelBooleanInput | null,
  blackDyedHair?: ModelBooleanInput | null,
  bleachedHair?: ModelBooleanInput | null,
  modelVersion?: ModelIntInput | null,
  customerId?: ModelIDInput | null,
  and?: Array< ModelSelfDiagnosisContentConditionInput | null > | null,
  or?: Array< ModelSelfDiagnosisContentConditionInput | null > | null,
  not?: ModelSelfDiagnosisContentConditionInput | null,
};

export type ModelConsultationGenderInput = {
  eq?: ConsultationGender | null,
  ne?: ConsultationGender | null,
};

export type SelfDiagnosisContent = {
  __typename: "SelfDiagnosisContent",
  requestId: string,
  shopName: string,
  designerName: string,
  name: string,
  yearMonth: string,
  date?: string | null,
  inflowChannel?: string | null,
  gender?: ConsultationGender | null,
  dry?: boolean | null,
  concerns?: Array< string > | null,
  frequencyOfVisits?: string | null,
  outfitStyles?: Array< string > | null,
  treatmentsInterestedIn?: Array< string > | null,
  productsForShampooing?: Array< string > | null,
  productsForStyling?: Array< string > | null,
  recentDyeing?: string | null,
  recentPerm?: string | null,
  hasPhotos?: boolean | null,
  photos?:  Array<NestedPhoto > | null,
  blackDyedHair?: boolean | null,
  bleachedHair?: boolean | null,
  modelVersion?: number | null,
  customerId?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateSelfDiagnosisContentInput = {
  requestId: string,
  shopName?: string | null,
  designerName?: string | null,
  name?: string | null,
  yearMonth?: string | null,
  date?: string | null,
  inflowChannel?: string | null,
  gender?: ConsultationGender | null,
  dry?: boolean | null,
  concerns?: Array< string > | null,
  frequencyOfVisits?: string | null,
  outfitStyles?: Array< string > | null,
  treatmentsInterestedIn?: Array< string > | null,
  productsForShampooing?: Array< string > | null,
  productsForStyling?: Array< string > | null,
  recentDyeing?: string | null,
  recentPerm?: string | null,
  hasPhotos?: boolean | null,
  photos?: Array< NestedPhotoInput > | null,
  blackDyedHair?: boolean | null,
  bleachedHair?: boolean | null,
  modelVersion?: number | null,
  customerId?: string | null,
  _version?: number | null,
};

export type DeleteSelfDiagnosisContentInput = {
  requestId: string,
  _version?: number | null,
};

export type CreateRegularCustomerQuestionnaireRequestInput = {
  id?: string | null,
  owner?: string | null,
  shopName: string,
  designerName: string,
  name: string,
  phoneNumber: string,
  yearMonth: string,
  date: string,
  sentAt?: string | null,
  writtenAt?: string | null,
  timestamp: number,
  modelVersion?: number | null,
  customerId?: string | null,
  _version?: number | null,
};

export type ModelRegularCustomerQuestionnaireRequestConditionInput = {
  owner?: ModelStringInput | null,
  shopName?: ModelStringInput | null,
  designerName?: ModelStringInput | null,
  name?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  yearMonth?: ModelStringInput | null,
  date?: ModelStringInput | null,
  sentAt?: ModelStringInput | null,
  writtenAt?: ModelStringInput | null,
  timestamp?: ModelIntInput | null,
  modelVersion?: ModelIntInput | null,
  customerId?: ModelIDInput | null,
  and?: Array< ModelRegularCustomerQuestionnaireRequestConditionInput | null > | null,
  or?: Array< ModelRegularCustomerQuestionnaireRequestConditionInput | null > | null,
  not?: ModelRegularCustomerQuestionnaireRequestConditionInput | null,
};

export type RegularCustomerQuestionnaireRequest = {
  __typename: "RegularCustomerQuestionnaireRequest",
  id: string,
  owner?: string | null,
  shopName: string,
  designerName: string,
  name: string,
  phoneNumber: string,
  yearMonth: string,
  date: string,
  sentAt?: string | null,
  writtenAt?: string | null,
  timestamp: number,
  modelVersion?: number | null,
  customerId?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateRegularCustomerQuestionnaireRequestInput = {
  id: string,
  owner?: string | null,
  shopName?: string | null,
  designerName?: string | null,
  name?: string | null,
  phoneNumber?: string | null,
  yearMonth?: string | null,
  date?: string | null,
  sentAt?: string | null,
  writtenAt?: string | null,
  timestamp?: number | null,
  modelVersion?: number | null,
  customerId?: string | null,
  _version?: number | null,
};

export type DeleteRegularCustomerQuestionnaireRequestInput = {
  id: string,
  _version?: number | null,
};

export type CreateRegularCustomerQuestionnaireContentInput = {
  requestId: string,
  shopName: string,
  designerName: string,
  name: string,
  yearMonth: string,
  date: string,
  levelOfStyleChange: string,
  nextSchedule?: string | null,
  comment?: string | null,
  productsInterestedIn?: string | null,
  treatmentsInterestedIn?: string | null,
  hasPhotos?: boolean | null,
  photos?: Array< NestedPhotoInput > | null,
  timestamp: number,
  modelVersion: number,
  customerId?: string | null,
  _version?: number | null,
};

export type ModelRegularCustomerQuestionnaireContentConditionInput = {
  shopName?: ModelStringInput | null,
  designerName?: ModelStringInput | null,
  name?: ModelStringInput | null,
  yearMonth?: ModelStringInput | null,
  date?: ModelStringInput | null,
  levelOfStyleChange?: ModelStringInput | null,
  nextSchedule?: ModelStringInput | null,
  comment?: ModelStringInput | null,
  productsInterestedIn?: ModelStringInput | null,
  treatmentsInterestedIn?: ModelStringInput | null,
  hasPhotos?: ModelBooleanInput | null,
  timestamp?: ModelIntInput | null,
  modelVersion?: ModelIntInput | null,
  customerId?: ModelIDInput | null,
  and?: Array< ModelRegularCustomerQuestionnaireContentConditionInput | null > | null,
  or?: Array< ModelRegularCustomerQuestionnaireContentConditionInput | null > | null,
  not?: ModelRegularCustomerQuestionnaireContentConditionInput | null,
};

export type RegularCustomerQuestionnaireContent = {
  __typename: "RegularCustomerQuestionnaireContent",
  requestId: string,
  shopName: string,
  designerName: string,
  name: string,
  yearMonth: string,
  date: string,
  levelOfStyleChange: string,
  nextSchedule?: string | null,
  comment?: string | null,
  productsInterestedIn?: string | null,
  treatmentsInterestedIn?: string | null,
  hasPhotos?: boolean | null,
  photos?:  Array<NestedPhoto > | null,
  timestamp: number,
  modelVersion: number,
  customerId?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateRegularCustomerQuestionnaireContentInput = {
  requestId: string,
  shopName?: string | null,
  designerName?: string | null,
  name?: string | null,
  yearMonth?: string | null,
  date?: string | null,
  levelOfStyleChange?: string | null,
  nextSchedule?: string | null,
  comment?: string | null,
  productsInterestedIn?: string | null,
  treatmentsInterestedIn?: string | null,
  hasPhotos?: boolean | null,
  photos?: Array< NestedPhotoInput > | null,
  timestamp?: number | null,
  modelVersion?: number | null,
  customerId?: string | null,
  _version?: number | null,
};

export type DeleteRegularCustomerQuestionnaireContentInput = {
  requestId: string,
  _version?: number | null,
};

export type CreateUserActivitySnapshotInput = {
  id?: string | null,
  owner?: string | null,
  dailyLogCount?: number | null,
  selfDiagnosisCount?: number | null,
  regularCustomerQuestionnaireCount?: number | null,
  caringCount?: number | null,
  modelVersion?: number | null,
  _version?: number | null,
};

export type ModelUserActivitySnapshotConditionInput = {
  owner?: ModelStringInput | null,
  dailyLogCount?: ModelIntInput | null,
  selfDiagnosisCount?: ModelIntInput | null,
  regularCustomerQuestionnaireCount?: ModelIntInput | null,
  caringCount?: ModelIntInput | null,
  modelVersion?: ModelIntInput | null,
  and?: Array< ModelUserActivitySnapshotConditionInput | null > | null,
  or?: Array< ModelUserActivitySnapshotConditionInput | null > | null,
  not?: ModelUserActivitySnapshotConditionInput | null,
};

export type UserActivitySnapshot = {
  __typename: "UserActivitySnapshot",
  id: string,
  owner?: string | null,
  dailyLogCount?: number | null,
  selfDiagnosisCount?: number | null,
  regularCustomerQuestionnaireCount?: number | null,
  caringCount?: number | null,
  modelVersion?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateUserActivitySnapshotInput = {
  id: string,
  owner?: string | null,
  dailyLogCount?: number | null,
  selfDiagnosisCount?: number | null,
  regularCustomerQuestionnaireCount?: number | null,
  caringCount?: number | null,
  modelVersion?: number | null,
  _version?: number | null,
};

export type DeleteUserActivitySnapshotInput = {
  id: string,
  _version?: number | null,
};

export type CreateDailyLogInput = {
  id?: string | null,
  owner?: string | null,
  shopName?: string | null,
  designerName?: string | null,
  yearMonth: string,
  date: string,
  timestamp: number,
  averageConsultationMinutes?: number | null,
  serviceRecommendationCount?: number | null,
  productRecommendationCount?: number | null,
  prepaidTicketRecommendationCount?: number | null,
  stretch?: boolean | null,
  drinkingWater?: boolean | null,
  comment?: string | null,
  modelVersion?: number | null,
  _version?: number | null,
};

export type ModelDailyLogConditionInput = {
  owner?: ModelStringInput | null,
  shopName?: ModelStringInput | null,
  designerName?: ModelStringInput | null,
  yearMonth?: ModelStringInput | null,
  date?: ModelStringInput | null,
  timestamp?: ModelIntInput | null,
  averageConsultationMinutes?: ModelIntInput | null,
  serviceRecommendationCount?: ModelIntInput | null,
  productRecommendationCount?: ModelIntInput | null,
  prepaidTicketRecommendationCount?: ModelIntInput | null,
  stretch?: ModelBooleanInput | null,
  drinkingWater?: ModelBooleanInput | null,
  comment?: ModelStringInput | null,
  modelVersion?: ModelIntInput | null,
  and?: Array< ModelDailyLogConditionInput | null > | null,
  or?: Array< ModelDailyLogConditionInput | null > | null,
  not?: ModelDailyLogConditionInput | null,
};

export type DailyLog = {
  __typename: "DailyLog",
  id: string,
  owner?: string | null,
  shopName?: string | null,
  designerName?: string | null,
  yearMonth: string,
  date: string,
  timestamp: number,
  averageConsultationMinutes?: number | null,
  serviceRecommendationCount?: number | null,
  productRecommendationCount?: number | null,
  prepaidTicketRecommendationCount?: number | null,
  stretch?: boolean | null,
  drinkingWater?: boolean | null,
  comment?: string | null,
  modelVersion?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateDailyLogInput = {
  id: string,
  owner?: string | null,
  shopName?: string | null,
  designerName?: string | null,
  yearMonth?: string | null,
  date?: string | null,
  timestamp?: number | null,
  averageConsultationMinutes?: number | null,
  serviceRecommendationCount?: number | null,
  productRecommendationCount?: number | null,
  prepaidTicketRecommendationCount?: number | null,
  stretch?: boolean | null,
  drinkingWater?: boolean | null,
  comment?: string | null,
  modelVersion?: number | null,
  _version?: number | null,
};

export type DeleteDailyLogInput = {
  id: string,
  _version?: number | null,
};

export type CreateSelfDiagnosisInput = {
  bookingId: string,
  customerId: string,
  customerName: string,
  yearMonth: string,
  date: string,
  shopId: string,
  shopName: string,
  designerId: string,
  designerName: string,
  inflowChannel?: string | null,
  gender?: ConsultationGender | null,
  dry?: boolean | null,
  concerns?: Array< string > | null,
  frequencyOfVisits?: string | null,
  outfitStyles?: Array< string > | null,
  treatmentsInterestedIn?: Array< string > | null,
  productsForShampooing?: Array< string > | null,
  productsForStyling?: Array< string > | null,
  recentDyeing?: string | null,
  recentPerm?: string | null,
  hasPhotos?: boolean | null,
  photos?: Array< NestedPhotoInput > | null,
  blackDyedHair?: boolean | null,
  bleachedHair?: boolean | null,
  modelVersion?: number | null,
  _version?: number | null,
};

export type ModelSelfDiagnosisConditionInput = {
  customerId?: ModelIDInput | null,
  customerName?: ModelStringInput | null,
  yearMonth?: ModelStringInput | null,
  date?: ModelStringInput | null,
  shopId?: ModelIDInput | null,
  shopName?: ModelStringInput | null,
  designerId?: ModelIDInput | null,
  designerName?: ModelStringInput | null,
  inflowChannel?: ModelStringInput | null,
  gender?: ModelConsultationGenderInput | null,
  dry?: ModelBooleanInput | null,
  concerns?: ModelStringInput | null,
  frequencyOfVisits?: ModelStringInput | null,
  outfitStyles?: ModelStringInput | null,
  treatmentsInterestedIn?: ModelStringInput | null,
  productsForShampooing?: ModelStringInput | null,
  productsForStyling?: ModelStringInput | null,
  recentDyeing?: ModelStringInput | null,
  recentPerm?: ModelStringInput | null,
  hasPhotos?: ModelBooleanInput | null,
  blackDyedHair?: ModelBooleanInput | null,
  bleachedHair?: ModelBooleanInput | null,
  modelVersion?: ModelIntInput | null,
  and?: Array< ModelSelfDiagnosisConditionInput | null > | null,
  or?: Array< ModelSelfDiagnosisConditionInput | null > | null,
  not?: ModelSelfDiagnosisConditionInput | null,
};

export type SelfDiagnosis = {
  __typename: "SelfDiagnosis",
  bookingId: string,
  customerId: string,
  customerName: string,
  yearMonth: string,
  date: string,
  shopId: string,
  shopName: string,
  designerId: string,
  designerName: string,
  inflowChannel?: string | null,
  gender?: ConsultationGender | null,
  dry?: boolean | null,
  concerns?: Array< string > | null,
  frequencyOfVisits?: string | null,
  outfitStyles?: Array< string > | null,
  treatmentsInterestedIn?: Array< string > | null,
  productsForShampooing?: Array< string > | null,
  productsForStyling?: Array< string > | null,
  recentDyeing?: string | null,
  recentPerm?: string | null,
  hasPhotos?: boolean | null,
  photos?:  Array<NestedPhoto > | null,
  blackDyedHair?: boolean | null,
  bleachedHair?: boolean | null,
  modelVersion?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateSelfDiagnosisInput = {
  bookingId: string,
  customerId?: string | null,
  customerName?: string | null,
  yearMonth?: string | null,
  date?: string | null,
  shopId?: string | null,
  shopName?: string | null,
  designerId?: string | null,
  designerName?: string | null,
  inflowChannel?: string | null,
  gender?: ConsultationGender | null,
  dry?: boolean | null,
  concerns?: Array< string > | null,
  frequencyOfVisits?: string | null,
  outfitStyles?: Array< string > | null,
  treatmentsInterestedIn?: Array< string > | null,
  productsForShampooing?: Array< string > | null,
  productsForStyling?: Array< string > | null,
  recentDyeing?: string | null,
  recentPerm?: string | null,
  hasPhotos?: boolean | null,
  photos?: Array< NestedPhotoInput > | null,
  blackDyedHair?: boolean | null,
  bleachedHair?: boolean | null,
  modelVersion?: number | null,
  _version?: number | null,
};

export type DeleteSelfDiagnosisInput = {
  bookingId: string,
  _version?: number | null,
};

export type CreateConsultationInput = {
  id?: string | null,
  bookingId?: string | null,
  customer: NestedCustomerInput,
  customerId: string,
  name?: string | null,
  yearMonth?: string | null,
  date?: string | null,
  shopId: string,
  status: ConsultationStatus,
  selfDiagnosisStatus: ConsultationSelfDiagnosisStatus,
  mode?: ConsultationMode | null,
  inflowChannel?: string | null,
  gender?: ConsultationGender | null,
  dry?: boolean | null,
  concerns?: Array< string > | null,
  frequencyOfVisits?: string | null,
  outfitStyles?: Array< string > | null,
  treatmentsInterestedIn?: Array< string > | null,
  productsForShampooing?: Array< string > | null,
  productsForStyling?: Array< string > | null,
  neckLength?: string | null,
  neckThickness?: string | null,
  shoulderWidth?: string | null,
  shoulderShape?: string | null,
  hairColor?: string | null,
  virginHairColor?: string | null,
  colorfulnessOfHair?: string | null,
  colorfulnessesOfHair?: Array< string > | null,
  recentDyeing?: string | null,
  recentFirm?: string | null,
  damageDegreeOfHair?: string | null,
  amountOfHair?: string | null,
  thicknessOfHair?: string | null,
  curlDegreeOfHair?: string | null,
  faceShape?: string | null,
  faceShapes?: Array< string > | null,
  frontHeadShape?: string | null,
  sideHeadShape?: string | null,
  solution?: SolutionInput | null,
  solutionSentAt?: string | null,
  hairRecommendation?: HairRecommendationInput | null,
  products?: Array< SolutionProductInput > | null,
  shopName?: string | null,
  designerName?: string | null,
  designerId?: string | null,
  hasPhotos?: boolean | null,
  photos?: Array< NestedPhotoInput > | null,
  modelVersion?: number | null,
  _version?: number | null,
};

export type NestedCustomerInput = {
  phoneNumber?: string | null,
  name?: string | null,
  id?: string | null,
  shopIds?: Array< string > | null,
};

export enum ConsultationStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE",
}


export enum ConsultationSelfDiagnosisStatus {
  NOT_SENT = "NOT_SENT",
  SENT = "SENT",
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE",
}


export enum ConsultationMode {
  FULL = "FULL",
  CUT = "CUT",
  COLOR = "COLOR",
  PERM = "PERM",
}


export type SolutionInput = {
  face?: string | null,
  hair?: string | null,
  needs?: string | null,
  point?: string | null,
  aftercareTips?: string | null,
  stylingTips?: string | null,
  precautions?: string | null,
  recommendedTreatment?: string | null,
  nextVisitDate?: string | null,
  link?: string | null,
};

export type HairRecommendationInput = {
  bangs?: string | null,
  hairLength?: string | null,
  hairVolume?: string | null,
  hairVolumes?: Array< string > | null,
  haircut?: string | null,
  haircuts?: Array< string > | null,
  hairColorMenu?: string | null,
  hairColorType?: string | null,
  hairFirm?: string | null,
  hairCare?: string | null,
};

export type SolutionProductInput = {
  title: string,
  url: string,
  imageFilePath: string,
  description?: string | null,
};

export type ModelConsultationConditionInput = {
  bookingId?: ModelIDInput | null,
  customerId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  yearMonth?: ModelStringInput | null,
  date?: ModelStringInput | null,
  shopId?: ModelIDInput | null,
  status?: ModelConsultationStatusInput | null,
  selfDiagnosisStatus?: ModelConsultationSelfDiagnosisStatusInput | null,
  mode?: ModelConsultationModeInput | null,
  inflowChannel?: ModelStringInput | null,
  gender?: ModelConsultationGenderInput | null,
  dry?: ModelBooleanInput | null,
  concerns?: ModelStringInput | null,
  frequencyOfVisits?: ModelStringInput | null,
  outfitStyles?: ModelStringInput | null,
  treatmentsInterestedIn?: ModelStringInput | null,
  productsForShampooing?: ModelStringInput | null,
  productsForStyling?: ModelStringInput | null,
  neckLength?: ModelStringInput | null,
  neckThickness?: ModelStringInput | null,
  shoulderWidth?: ModelStringInput | null,
  shoulderShape?: ModelStringInput | null,
  hairColor?: ModelStringInput | null,
  virginHairColor?: ModelStringInput | null,
  colorfulnessOfHair?: ModelStringInput | null,
  colorfulnessesOfHair?: ModelStringInput | null,
  recentDyeing?: ModelStringInput | null,
  recentFirm?: ModelStringInput | null,
  damageDegreeOfHair?: ModelStringInput | null,
  amountOfHair?: ModelStringInput | null,
  thicknessOfHair?: ModelStringInput | null,
  curlDegreeOfHair?: ModelStringInput | null,
  faceShape?: ModelStringInput | null,
  faceShapes?: ModelStringInput | null,
  frontHeadShape?: ModelStringInput | null,
  sideHeadShape?: ModelStringInput | null,
  solutionSentAt?: ModelStringInput | null,
  shopName?: ModelStringInput | null,
  designerName?: ModelStringInput | null,
  designerId?: ModelIDInput | null,
  hasPhotos?: ModelBooleanInput | null,
  modelVersion?: ModelIntInput | null,
  and?: Array< ModelConsultationConditionInput | null > | null,
  or?: Array< ModelConsultationConditionInput | null > | null,
  not?: ModelConsultationConditionInput | null,
};

export type ModelConsultationStatusInput = {
  eq?: ConsultationStatus | null,
  ne?: ConsultationStatus | null,
};

export type ModelConsultationSelfDiagnosisStatusInput = {
  eq?: ConsultationSelfDiagnosisStatus | null,
  ne?: ConsultationSelfDiagnosisStatus | null,
};

export type ModelConsultationModeInput = {
  eq?: ConsultationMode | null,
  ne?: ConsultationMode | null,
};

export type Consultation = {
  __typename: "Consultation",
  id: string,
  bookingId?: string | null,
  customer: NestedCustomer,
  customerId: string,
  name?: string | null,
  yearMonth?: string | null,
  date?: string | null,
  shopId: string,
  status: ConsultationStatus,
  selfDiagnosisStatus: ConsultationSelfDiagnosisStatus,
  mode?: ConsultationMode | null,
  inflowChannel?: string | null,
  gender?: ConsultationGender | null,
  dry?: boolean | null,
  concerns?: Array< string > | null,
  frequencyOfVisits?: string | null,
  outfitStyles?: Array< string > | null,
  treatmentsInterestedIn?: Array< string > | null,
  productsForShampooing?: Array< string > | null,
  productsForStyling?: Array< string > | null,
  neckLength?: string | null,
  neckThickness?: string | null,
  shoulderWidth?: string | null,
  shoulderShape?: string | null,
  hairColor?: string | null,
  virginHairColor?: string | null,
  colorfulnessOfHair?: string | null,
  colorfulnessesOfHair?: Array< string > | null,
  recentDyeing?: string | null,
  recentFirm?: string | null,
  damageDegreeOfHair?: string | null,
  amountOfHair?: string | null,
  thicknessOfHair?: string | null,
  curlDegreeOfHair?: string | null,
  faceShape?: string | null,
  faceShapes?: Array< string > | null,
  frontHeadShape?: string | null,
  sideHeadShape?: string | null,
  solution?: Solution | null,
  solutionSentAt?: string | null,
  hairRecommendation?: HairRecommendation | null,
  products?:  Array<SolutionProduct > | null,
  shopName?: string | null,
  designerName?: string | null,
  designerId?: string | null,
  hasPhotos?: boolean | null,
  photos?:  Array<NestedPhoto > | null,
  modelVersion?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type NestedCustomer = {
  __typename: "NestedCustomer",
  phoneNumber?: string | null,
  name?: string | null,
  id?: string | null,
  shopIds?: Array< string > | null,
};

export type Solution = {
  __typename: "Solution",
  face?: string | null,
  hair?: string | null,
  needs?: string | null,
  point?: string | null,
  aftercareTips?: string | null,
  stylingTips?: string | null,
  precautions?: string | null,
  recommendedTreatment?: string | null,
  nextVisitDate?: string | null,
  link?: string | null,
};

export type HairRecommendation = {
  __typename: "HairRecommendation",
  bangs?: string | null,
  hairLength?: string | null,
  hairVolume?: string | null,
  hairVolumes?: Array< string > | null,
  haircut?: string | null,
  haircuts?: Array< string > | null,
  hairColorMenu?: string | null,
  hairColorType?: string | null,
  hairFirm?: string | null,
  hairCare?: string | null,
};

export type SolutionProduct = {
  __typename: "SolutionProduct",
  title: string,
  url: string,
  imageFilePath: string,
  description?: string | null,
};

export type UpdateConsultationInput = {
  id: string,
  bookingId?: string | null,
  customer?: NestedCustomerInput | null,
  customerId?: string | null,
  name?: string | null,
  yearMonth?: string | null,
  date?: string | null,
  shopId?: string | null,
  status?: ConsultationStatus | null,
  selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
  mode?: ConsultationMode | null,
  inflowChannel?: string | null,
  gender?: ConsultationGender | null,
  dry?: boolean | null,
  concerns?: Array< string > | null,
  frequencyOfVisits?: string | null,
  outfitStyles?: Array< string > | null,
  treatmentsInterestedIn?: Array< string > | null,
  productsForShampooing?: Array< string > | null,
  productsForStyling?: Array< string > | null,
  neckLength?: string | null,
  neckThickness?: string | null,
  shoulderWidth?: string | null,
  shoulderShape?: string | null,
  hairColor?: string | null,
  virginHairColor?: string | null,
  colorfulnessOfHair?: string | null,
  colorfulnessesOfHair?: Array< string > | null,
  recentDyeing?: string | null,
  recentFirm?: string | null,
  damageDegreeOfHair?: string | null,
  amountOfHair?: string | null,
  thicknessOfHair?: string | null,
  curlDegreeOfHair?: string | null,
  faceShape?: string | null,
  faceShapes?: Array< string > | null,
  frontHeadShape?: string | null,
  sideHeadShape?: string | null,
  solution?: SolutionInput | null,
  solutionSentAt?: string | null,
  hairRecommendation?: HairRecommendationInput | null,
  products?: Array< SolutionProductInput > | null,
  shopName?: string | null,
  designerName?: string | null,
  designerId?: string | null,
  hasPhotos?: boolean | null,
  photos?: Array< NestedPhotoInput > | null,
  modelVersion?: number | null,
  _version?: number | null,
};

export type DeleteConsultationInput = {
  id: string,
  _version?: number | null,
};

export type CreateAnonymousPhotoInput = {
  id?: string | null,
  bookingId?: string | null,
  consultationId?: string | null,
  requestId?: string | null,
  s3Key: string,
  displayOrder: number,
  category: AnonymousPhotoCategory,
  _version?: number | null,
};

export enum AnonymousPhotoCategory {
  TREATMENT_COMPLETION = "TREATMENT_COMPLETION",
  STYLE_INTERESTED_IN = "STYLE_INTERESTED_IN",
}


export type ModelAnonymousPhotoConditionInput = {
  bookingId?: ModelIDInput | null,
  consultationId?: ModelIDInput | null,
  requestId?: ModelIDInput | null,
  s3Key?: ModelStringInput | null,
  displayOrder?: ModelIntInput | null,
  category?: ModelAnonymousPhotoCategoryInput | null,
  and?: Array< ModelAnonymousPhotoConditionInput | null > | null,
  or?: Array< ModelAnonymousPhotoConditionInput | null > | null,
  not?: ModelAnonymousPhotoConditionInput | null,
};

export type ModelAnonymousPhotoCategoryInput = {
  eq?: AnonymousPhotoCategory | null,
  ne?: AnonymousPhotoCategory | null,
};

export type AnonymousPhoto = {
  __typename: "AnonymousPhoto",
  id: string,
  bookingId?: string | null,
  consultationId?: string | null,
  requestId?: string | null,
  s3Key: string,
  displayOrder: number,
  category: AnonymousPhotoCategory,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAnonymousPhotoInput = {
  id: string,
  bookingId?: string | null,
  consultationId?: string | null,
  requestId?: string | null,
  s3Key?: string | null,
  displayOrder?: number | null,
  category?: AnonymousPhotoCategory | null,
  _version?: number | null,
};

export type DeleteAnonymousPhotoInput = {
  id: string,
  _version?: number | null,
};

export type CreatePhotoInput = {
  id?: string | null,
  bookingId?: string | null,
  s3Key: string,
  displayOrder: number,
  identityId: string,
  description?: string | null,
  category: PhotoCategory,
  owner?: string | null,
  _version?: number | null,
};

export enum PhotoCategory {
  TREATMENT_PROCESS = "TREATMENT_PROCESS",
  TREATMENT_COMPLETION = "TREATMENT_COMPLETION",
}


export type ModelPhotoConditionInput = {
  bookingId?: ModelIDInput | null,
  s3Key?: ModelStringInput | null,
  displayOrder?: ModelIntInput | null,
  identityId?: ModelStringInput | null,
  description?: ModelStringInput | null,
  category?: ModelPhotoCategoryInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelPhotoConditionInput | null > | null,
  or?: Array< ModelPhotoConditionInput | null > | null,
  not?: ModelPhotoConditionInput | null,
};

export type ModelPhotoCategoryInput = {
  eq?: PhotoCategory | null,
  ne?: PhotoCategory | null,
};

export type Photo = {
  __typename: "Photo",
  id: string,
  bookingId?: string | null,
  s3Key: string,
  displayOrder: number,
  identityId: string,
  description?: string | null,
  category: PhotoCategory,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdatePhotoInput = {
  id: string,
  bookingId?: string | null,
  s3Key?: string | null,
  displayOrder?: number | null,
  identityId?: string | null,
  description?: string | null,
  category?: PhotoCategory | null,
  owner?: string | null,
  _version?: number | null,
};

export type DeletePhotoInput = {
  id: string,
  _version?: number | null,
};

export type CreateCheckInInput = {
  bookingId: string,
  shopId: string,
  checkInRequests?: Array< string > | null,
  drink?: string | null,
  modelVersion: number,
  _version?: number | null,
};

export type ModelCheckInConditionInput = {
  shopId?: ModelIDInput | null,
  checkInRequests?: ModelStringInput | null,
  drink?: ModelStringInput | null,
  modelVersion?: ModelIntInput | null,
  and?: Array< ModelCheckInConditionInput | null > | null,
  or?: Array< ModelCheckInConditionInput | null > | null,
  not?: ModelCheckInConditionInput | null,
};

export type CheckIn = {
  __typename: "CheckIn",
  bookingId: string,
  shopId: string,
  checkInRequests?: Array< string > | null,
  drink?: string | null,
  modelVersion: number,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateCheckInInput = {
  bookingId: string,
  shopId?: string | null,
  checkInRequests?: Array< string > | null,
  drink?: string | null,
  modelVersion?: number | null,
  _version?: number | null,
};

export type DeleteCheckInInput = {
  bookingId: string,
  _version?: number | null,
};

export type CreateBookingInput = {
  id?: string | null,
  yearMonth?: string | null,
  date: string,
  time: string,
  customer?: NestedCustomerInput | null,
  customerId: string,
  name?: string | null,
  phoneNumber?: string | null,
  consultationId?: string | null,
  consultation?: NestedConsultationInput | null,
  hasSelfDiagnosis?: boolean | null,
  hasRegularCustomerNote?: boolean | null,
  designerName?: string | null,
  designerId?: string | null,
  optionName?: string | null,
  shopId: string,
  shopName?: string | null,
  naverBookingNumber?: string | null,
  bookingStatus?: BookingStatus | null,
  serviceStatus?: ServiceStatus | null,
  paymentStatus?: PaymentStatus | null,
  requestMessage?: string | null,
  designerMemo?: string | null,
  naverSnapshot?: NaverSnapshotInput | null,
  naverOptions?: Array< NaverServiceOptionInput > | null,
  naverPayments?: Array< NaverPaymentInput > | null,
  initialPrice?: number | null,
  finalPrice?: number | null,
  totalServiceSales?: number | null,
  totalProductSales?: number | null,
  initialDeposit?: number | null,
  paidAmount?: number | null,
  paidInCashAmount?: number | null,
  paidByCardAmount?: number | null,
  paidByPrepaidPassAmount?: number | null,
  paidByNaverPayAmount?: number | null,
  paidAt?: string | null,
  paymentMemo?: string | null,
  checkInRequests?: Array< string > | null,
  drink?: string | null,
  checkedInAt?: string | null,
  origin?: BookingOrigin | null,
  options?: Array< BookingServiceOptionInput > | null,
  productOptions?: Array< BookingProductOptionInput > | null,
  signature?: string | null,
  signedAt?: string | null,
  recentNaverDataReflection?: string | null,
  selfDiagnosisSentAt?: string | null,
  feedbackRequestSentAt?: string | null,
  hasFeedback?: boolean | null,
  hasPhotos?: boolean | null,
  photos?: Array< NestedPhotoInput > | null,
  hasDesignCompletionPhotos?: boolean | null,
  designCompletionPhotos?: Array< NestedPhotoInput > | null,
  signatureOfPortraitRights?: string | null,
  signedAtOfPortraitRights?: string | null,
  _version?: number | null,
};

export type NestedConsultationInput = {
  status?: ConsultationStatus | null,
  selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
  solutionSentAt?: string | null,
  inflowChannel?: string | null,
  hasPhotos?: boolean | null,
};

export enum BookingStatus {
  CONFIRMED = "CONFIRMED",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
}


export enum ServiceStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE",
}


export enum PaymentStatus {
  NOT_PAID = "NOT_PAID",
  PAID = "PAID",
}


export type NaverSnapshotInput = {
  price: number,
  bizItemPrice: number,
  onsitePrice?: number | null,
  email?: string | null,
  isOnsitePayment?: boolean | null,
};

export type NaverServiceOptionInput = {
  categoryName: string,
  name: string,
  originalName?: string | null,
  desc?: string | null,
  price?: number | null,
};

export type NaverPaymentInput = {
  method?: NaverPaymentMethod | null,
  moment?: NaverPaymentMoment | null,
  provider?: NaverPaymentProvider | null,
  paymentId: number,
  bookingId: number,
  amount: number,
  providerDiscountAmount?: number | null,
  regDateTime?: string | null,
  status: NaverPaymentStatus,
  statusHistories?: Array< NaverPaymentStatusHistoryInput > | null,
};

export enum NaverPaymentMethod {
  CREDIT_CARD = "CREDIT_CARD",
  POINT = "POINT",
  BANK_TRANSFER = "BANK_TRANSFER",
  BANK_DEPOSIT = "BANK_DEPOSIT",
  ETC = "ETC",
  NAVER_PAY_MONEY = "NAVER_PAY_MONEY",
  NAVER_PAY_BANK = "NAVER_PAY_BANK",
  NAVER_PAY_POINT = "NAVER_PAY_POINT",
}


export enum NaverPaymentMoment {
  PRE = "PRE",
  POST = "POST",
}


export enum NaverPaymentProvider {
  NAVER_PAY = "NAVER_PAY",
}


export enum NaverPaymentStatus {
  REGISTERED = "REGISTERED",
  REQUESTED = "REQUESTED",
  DEPOSIT_WAITING = "DEPOSIT_WAITING",
  CHARGED = "CHARGED",
  PAID = "PAID",
  REFUNDED = "REFUNDED",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
}


export type NaverPaymentStatusHistoryInput = {
  status: NaverPaymentStatus,
  dateTime?: string | null,
};

export enum BookingOrigin {
  NAVER = "NAVER",
  MANUAL = "MANUAL",
}


export type BookingServiceOptionInput = {
  categoryName: string,
  name: string,
  originalPrice: number,
  discount?: number | null,
  discountRate?: number | null,
  price: number,
  discountPreset?: DiscountPresetSnapshotInput | null,
};

export type DiscountPresetSnapshotInput = {
  id?: string | null,
  shopId?: string | null,
  name?: string | null,
  amount?: number | null,
  rate?: number | null,
  type?: DiscountMode | null,
};

export type BookingProductOptionInput = {
  name: string,
  price: number,
  quantity: number,
  product?: ProductSnapshotInput | null,
};

export type ProductSnapshotInput = {
  id?: string | null,
  name?: string | null,
  shopId?: string | null,
  brandId?: string | null,
  brandName?: string | null,
  costPrice?: number | null,
  sellingPrice?: number | null,
};

export type ModelBookingConditionInput = {
  yearMonth?: ModelStringInput | null,
  date?: ModelStringInput | null,
  time?: ModelStringInput | null,
  customerId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  consultationId?: ModelIDInput | null,
  hasSelfDiagnosis?: ModelBooleanInput | null,
  hasRegularCustomerNote?: ModelBooleanInput | null,
  designerName?: ModelStringInput | null,
  designerId?: ModelIDInput | null,
  optionName?: ModelStringInput | null,
  shopId?: ModelIDInput | null,
  shopName?: ModelStringInput | null,
  naverBookingNumber?: ModelStringInput | null,
  bookingStatus?: ModelBookingStatusInput | null,
  serviceStatus?: ModelServiceStatusInput | null,
  paymentStatus?: ModelPaymentStatusInput | null,
  requestMessage?: ModelStringInput | null,
  designerMemo?: ModelStringInput | null,
  initialPrice?: ModelIntInput | null,
  finalPrice?: ModelIntInput | null,
  totalServiceSales?: ModelIntInput | null,
  totalProductSales?: ModelIntInput | null,
  initialDeposit?: ModelIntInput | null,
  paidAmount?: ModelIntInput | null,
  paidInCashAmount?: ModelIntInput | null,
  paidByCardAmount?: ModelIntInput | null,
  paidByPrepaidPassAmount?: ModelIntInput | null,
  paidByNaverPayAmount?: ModelIntInput | null,
  paidAt?: ModelStringInput | null,
  paymentMemo?: ModelStringInput | null,
  checkInRequests?: ModelStringInput | null,
  drink?: ModelStringInput | null,
  checkedInAt?: ModelStringInput | null,
  origin?: ModelBookingOriginInput | null,
  signature?: ModelStringInput | null,
  signedAt?: ModelStringInput | null,
  recentNaverDataReflection?: ModelStringInput | null,
  selfDiagnosisSentAt?: ModelStringInput | null,
  feedbackRequestSentAt?: ModelStringInput | null,
  hasFeedback?: ModelBooleanInput | null,
  hasPhotos?: ModelBooleanInput | null,
  hasDesignCompletionPhotos?: ModelBooleanInput | null,
  signatureOfPortraitRights?: ModelStringInput | null,
  signedAtOfPortraitRights?: ModelStringInput | null,
  and?: Array< ModelBookingConditionInput | null > | null,
  or?: Array< ModelBookingConditionInput | null > | null,
  not?: ModelBookingConditionInput | null,
};

export type ModelBookingStatusInput = {
  eq?: BookingStatus | null,
  ne?: BookingStatus | null,
};

export type ModelServiceStatusInput = {
  eq?: ServiceStatus | null,
  ne?: ServiceStatus | null,
};

export type ModelPaymentStatusInput = {
  eq?: PaymentStatus | null,
  ne?: PaymentStatus | null,
};

export type ModelBookingOriginInput = {
  eq?: BookingOrigin | null,
  ne?: BookingOrigin | null,
};

export type Booking = {
  __typename: "Booking",
  id: string,
  yearMonth?: string | null,
  date: string,
  time: string,
  customer?: NestedCustomer | null,
  customerId: string,
  name?: string | null,
  phoneNumber?: string | null,
  consultationId?: string | null,
  consultation?: NestedConsultation | null,
  hasSelfDiagnosis?: boolean | null,
  hasRegularCustomerNote?: boolean | null,
  designerName?: string | null,
  designerId?: string | null,
  optionName?: string | null,
  shopId: string,
  shopName?: string | null,
  naverBookingNumber?: string | null,
  bookingStatus?: BookingStatus | null,
  serviceStatus?: ServiceStatus | null,
  paymentStatus?: PaymentStatus | null,
  requestMessage?: string | null,
  designerMemo?: string | null,
  naverSnapshot?: NaverSnapshot | null,
  naverOptions?:  Array<NaverServiceOption > | null,
  naverPayments?:  Array<NaverPayment > | null,
  initialPrice?: number | null,
  finalPrice?: number | null,
  totalServiceSales?: number | null,
  totalProductSales?: number | null,
  initialDeposit?: number | null,
  paidAmount?: number | null,
  paidInCashAmount?: number | null,
  paidByCardAmount?: number | null,
  paidByPrepaidPassAmount?: number | null,
  paidByNaverPayAmount?: number | null,
  paidAt?: string | null,
  paymentMemo?: string | null,
  checkInRequests?: Array< string > | null,
  drink?: string | null,
  checkedInAt?: string | null,
  origin?: BookingOrigin | null,
  options?:  Array<BookingServiceOption > | null,
  productOptions?:  Array<BookingProductOption > | null,
  signature?: string | null,
  signedAt?: string | null,
  recentNaverDataReflection?: string | null,
  selfDiagnosisSentAt?: string | null,
  feedbackRequestSentAt?: string | null,
  hasFeedback?: boolean | null,
  hasPhotos?: boolean | null,
  photos?:  Array<NestedPhoto > | null,
  hasDesignCompletionPhotos?: boolean | null,
  designCompletionPhotos?:  Array<NestedPhoto > | null,
  signatureOfPortraitRights?: string | null,
  signedAtOfPortraitRights?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type NestedConsultation = {
  __typename: "NestedConsultation",
  status?: ConsultationStatus | null,
  selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
  solutionSentAt?: string | null,
  inflowChannel?: string | null,
  hasPhotos?: boolean | null,
};

export type NaverSnapshot = {
  __typename: "NaverSnapshot",
  price: number,
  bizItemPrice: number,
  onsitePrice?: number | null,
  email?: string | null,
  isOnsitePayment?: boolean | null,
};

export type NaverServiceOption = {
  __typename: "NaverServiceOption",
  categoryName: string,
  name: string,
  originalName?: string | null,
  desc?: string | null,
  price?: number | null,
};

export type NaverPayment = {
  __typename: "NaverPayment",
  method?: NaverPaymentMethod | null,
  moment?: NaverPaymentMoment | null,
  provider?: NaverPaymentProvider | null,
  paymentId: number,
  bookingId: number,
  amount: number,
  providerDiscountAmount?: number | null,
  regDateTime?: string | null,
  status: NaverPaymentStatus,
  statusHistories?:  Array<NaverPaymentStatusHistory > | null,
};

export type NaverPaymentStatusHistory = {
  __typename: "NaverPaymentStatusHistory",
  status: NaverPaymentStatus,
  dateTime?: string | null,
};

export type BookingServiceOption = {
  __typename: "BookingServiceOption",
  categoryName: string,
  name: string,
  originalPrice: number,
  discount?: number | null,
  discountRate?: number | null,
  price: number,
  discountPreset?: DiscountPresetSnapshot | null,
};

export type DiscountPresetSnapshot = {
  __typename: "DiscountPresetSnapshot",
  id?: string | null,
  shopId?: string | null,
  name?: string | null,
  amount?: number | null,
  rate?: number | null,
  type?: DiscountMode | null,
};

export type BookingProductOption = {
  __typename: "BookingProductOption",
  name: string,
  price: number,
  quantity: number,
  product?: ProductSnapshot | null,
};

export type ProductSnapshot = {
  __typename: "ProductSnapshot",
  id?: string | null,
  name?: string | null,
  shopId?: string | null,
  brandId?: string | null,
  brandName?: string | null,
  costPrice?: number | null,
  sellingPrice?: number | null,
};

export type UpdateBookingInput = {
  id: string,
  yearMonth?: string | null,
  date?: string | null,
  time?: string | null,
  customer?: NestedCustomerInput | null,
  customerId?: string | null,
  name?: string | null,
  phoneNumber?: string | null,
  consultationId?: string | null,
  consultation?: NestedConsultationInput | null,
  hasSelfDiagnosis?: boolean | null,
  hasRegularCustomerNote?: boolean | null,
  designerName?: string | null,
  designerId?: string | null,
  optionName?: string | null,
  shopId?: string | null,
  shopName?: string | null,
  naverBookingNumber?: string | null,
  bookingStatus?: BookingStatus | null,
  serviceStatus?: ServiceStatus | null,
  paymentStatus?: PaymentStatus | null,
  requestMessage?: string | null,
  designerMemo?: string | null,
  naverSnapshot?: NaverSnapshotInput | null,
  naverOptions?: Array< NaverServiceOptionInput > | null,
  naverPayments?: Array< NaverPaymentInput > | null,
  initialPrice?: number | null,
  finalPrice?: number | null,
  totalServiceSales?: number | null,
  totalProductSales?: number | null,
  initialDeposit?: number | null,
  paidAmount?: number | null,
  paidInCashAmount?: number | null,
  paidByCardAmount?: number | null,
  paidByPrepaidPassAmount?: number | null,
  paidByNaverPayAmount?: number | null,
  paidAt?: string | null,
  paymentMemo?: string | null,
  checkInRequests?: Array< string > | null,
  drink?: string | null,
  checkedInAt?: string | null,
  origin?: BookingOrigin | null,
  options?: Array< BookingServiceOptionInput > | null,
  productOptions?: Array< BookingProductOptionInput > | null,
  signature?: string | null,
  signedAt?: string | null,
  recentNaverDataReflection?: string | null,
  selfDiagnosisSentAt?: string | null,
  feedbackRequestSentAt?: string | null,
  hasFeedback?: boolean | null,
  hasPhotos?: boolean | null,
  photos?: Array< NestedPhotoInput > | null,
  hasDesignCompletionPhotos?: boolean | null,
  designCompletionPhotos?: Array< NestedPhotoInput > | null,
  signatureOfPortraitRights?: string | null,
  signedAtOfPortraitRights?: string | null,
  _version?: number | null,
};

export type DeleteBookingInput = {
  id: string,
  _version?: number | null,
};

export type CreateCustomerInput = {
  id?: string | null,
  name: string,
  phoneNumber?: string | null,
  lastFourPhoneNumbers?: string | null,
  firstVisitDate?: string | null,
  occupation?: string | null,
  drink?: string | null,
  dress?: string | null,
  trouble?: string | null,
  memo?: string | null,
  bookingCount?: number | null,
  consultationCount?: number | null,
  consultationDoneCount?: number | null,
  signature?: string | null,
  totalRevenue?: number | null,
  vehicleNumber?: string | null,
  shopId: string,
  shopIds?: Array< string > | null,
  shopIdsString?: string | null,
  createdAt?: string | null,
  prepaidPassBalance?: number | null,
  _version?: number | null,
};

export type ModelCustomerConditionInput = {
  name?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  lastFourPhoneNumbers?: ModelStringInput | null,
  firstVisitDate?: ModelStringInput | null,
  occupation?: ModelStringInput | null,
  drink?: ModelStringInput | null,
  dress?: ModelStringInput | null,
  trouble?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  bookingCount?: ModelIntInput | null,
  consultationCount?: ModelIntInput | null,
  consultationDoneCount?: ModelIntInput | null,
  signature?: ModelStringInput | null,
  totalRevenue?: ModelIntInput | null,
  vehicleNumber?: ModelStringInput | null,
  shopId?: ModelIDInput | null,
  shopIds?: ModelStringInput | null,
  shopIdsString?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  prepaidPassBalance?: ModelIntInput | null,
  and?: Array< ModelCustomerConditionInput | null > | null,
  or?: Array< ModelCustomerConditionInput | null > | null,
  not?: ModelCustomerConditionInput | null,
};

export type Customer = {
  __typename: "Customer",
  id: string,
  name: string,
  phoneNumber?: string | null,
  lastFourPhoneNumbers?: string | null,
  firstVisitDate?: string | null,
  occupation?: string | null,
  drink?: string | null,
  dress?: string | null,
  trouble?: string | null,
  memo?: string | null,
  bookingCount?: number | null,
  consultationCount?: number | null,
  consultationDoneCount?: number | null,
  signature?: string | null,
  totalRevenue?: number | null,
  vehicleNumber?: string | null,
  shopId: string,
  shopIds?: Array< string > | null,
  shopIdsString?: string | null,
  createdAt?: string | null,
  prepaidPassBalance?: number | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateCustomerInput = {
  id: string,
  name?: string | null,
  phoneNumber?: string | null,
  lastFourPhoneNumbers?: string | null,
  firstVisitDate?: string | null,
  occupation?: string | null,
  drink?: string | null,
  dress?: string | null,
  trouble?: string | null,
  memo?: string | null,
  bookingCount?: number | null,
  consultationCount?: number | null,
  consultationDoneCount?: number | null,
  signature?: string | null,
  totalRevenue?: number | null,
  vehicleNumber?: string | null,
  shopId?: string | null,
  shopIds?: Array< string > | null,
  shopIdsString?: string | null,
  createdAt?: string | null,
  prepaidPassBalance?: number | null,
  _version?: number | null,
};

export type DeleteCustomerInput = {
  id: string,
  _version?: number | null,
};

export type CreateShopInput = {
  id?: string | null,
  name: string,
  naverBusinessId?: string | null,
  hasLocker?: boolean | null,
  bookingQRS3Key?: string | null,
  creatorId?: string | null,
  usePreSelfDiagnosis?: boolean | null,
  useFeedbackRequest?: boolean | null,
  owner?: string | null,
  ownerName?: string | null,
  ownerPhoneNumber?: string | null,
  internPhoneNumber?: string | null,
  _version?: number | null,
};

export type ModelShopConditionInput = {
  name?: ModelStringInput | null,
  naverBusinessId?: ModelStringInput | null,
  hasLocker?: ModelBooleanInput | null,
  bookingQRS3Key?: ModelStringInput | null,
  creatorId?: ModelIDInput | null,
  usePreSelfDiagnosis?: ModelBooleanInput | null,
  useFeedbackRequest?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  ownerName?: ModelStringInput | null,
  ownerPhoneNumber?: ModelStringInput | null,
  internPhoneNumber?: ModelStringInput | null,
  and?: Array< ModelShopConditionInput | null > | null,
  or?: Array< ModelShopConditionInput | null > | null,
  not?: ModelShopConditionInput | null,
};

export type Shop = {
  __typename: "Shop",
  id: string,
  name: string,
  naverBusinessId?: string | null,
  hasLocker?: boolean | null,
  bookingQRS3Key?: string | null,
  creatorId?: string | null,
  usePreSelfDiagnosis?: boolean | null,
  useFeedbackRequest?: boolean | null,
  owner?: string | null,
  ownerName?: string | null,
  ownerPhoneNumber?: string | null,
  internPhoneNumber?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateShopInput = {
  id: string,
  name?: string | null,
  naverBusinessId?: string | null,
  hasLocker?: boolean | null,
  bookingQRS3Key?: string | null,
  creatorId?: string | null,
  usePreSelfDiagnosis?: boolean | null,
  useFeedbackRequest?: boolean | null,
  owner?: string | null,
  ownerName?: string | null,
  ownerPhoneNumber?: string | null,
  internPhoneNumber?: string | null,
  _version?: number | null,
};

export type DeleteShopInput = {
  id: string,
  _version?: number | null,
};

export type CreateShopServiceDrinkInput = {
  id?: string | null,
  name: string,
  shopId: string,
  outOfStock?: boolean | null,
  displayOrder: number,
  _version?: number | null,
};

export type ModelShopServiceDrinkConditionInput = {
  name?: ModelStringInput | null,
  shopId?: ModelIDInput | null,
  outOfStock?: ModelBooleanInput | null,
  displayOrder?: ModelIntInput | null,
  and?: Array< ModelShopServiceDrinkConditionInput | null > | null,
  or?: Array< ModelShopServiceDrinkConditionInput | null > | null,
  not?: ModelShopServiceDrinkConditionInput | null,
};

export type ShopServiceDrink = {
  __typename: "ShopServiceDrink",
  id: string,
  name: string,
  shopId: string,
  outOfStock?: boolean | null,
  displayOrder: number,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateShopServiceDrinkInput = {
  id: string,
  name?: string | null,
  shopId?: string | null,
  outOfStock?: boolean | null,
  displayOrder?: number | null,
  _version?: number | null,
};

export type DeleteShopServiceDrinkInput = {
  id: string,
  _version?: number | null,
};

export type CreateShopUserApplicationInput = {
  id?: string | null,
  shopId: string,
  userId: string,
  phoneNumber: string,
  completed: boolean,
  name: string,
  email: string,
  _version?: number | null,
};

export type ModelShopUserApplicationConditionInput = {
  shopId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  phoneNumber?: ModelStringInput | null,
  completed?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  and?: Array< ModelShopUserApplicationConditionInput | null > | null,
  or?: Array< ModelShopUserApplicationConditionInput | null > | null,
  not?: ModelShopUserApplicationConditionInput | null,
};

export type ShopUserApplication = {
  __typename: "ShopUserApplication",
  id: string,
  shopId: string,
  userId: string,
  phoneNumber: string,
  completed: boolean,
  name: string,
  email: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateShopUserApplicationInput = {
  id: string,
  shopId?: string | null,
  userId?: string | null,
  phoneNumber?: string | null,
  completed?: boolean | null,
  name?: string | null,
  email?: string | null,
  _version?: number | null,
};

export type DeleteShopUserApplicationInput = {
  id: string,
  _version?: number | null,
};

export type CreateShopUserRemovalRequestInput = {
  designerId: string,
  shopId: string,
  userId: string,
  completed: boolean,
  owner?: string | null,
  _version?: number | null,
};

export type ModelShopUserRemovalRequestConditionInput = {
  shopId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  completed?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelShopUserRemovalRequestConditionInput | null > | null,
  or?: Array< ModelShopUserRemovalRequestConditionInput | null > | null,
  not?: ModelShopUserRemovalRequestConditionInput | null,
};

export type ShopUserRemovalRequest = {
  __typename: "ShopUserRemovalRequest",
  designerId: string,
  shopId: string,
  userId: string,
  completed: boolean,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateShopUserRemovalRequestInput = {
  designerId: string,
  shopId?: string | null,
  userId?: string | null,
  completed?: boolean | null,
  owner?: string | null,
  _version?: number | null,
};

export type DeleteShopUserRemovalRequestInput = {
  designerId: string,
  _version?: number | null,
};

export type CreateServiceOptionInput = {
  shopId: string,
  categories: Array< ServiceOptionCategoryInput >,
  _version?: number | null,
};

export type ServiceOptionCategoryInput = {
  name: string,
  options: Array< ServiceOptionItemInput >,
};

export type ServiceOptionItemInput = {
  name: string,
  price?: number | null,
};

export type ModelServiceOptionConditionInput = {
  and?: Array< ModelServiceOptionConditionInput | null > | null,
  or?: Array< ModelServiceOptionConditionInput | null > | null,
  not?: ModelServiceOptionConditionInput | null,
};

export type ServiceOption = {
  __typename: "ServiceOption",
  shopId: string,
  categories:  Array<ServiceOptionCategory >,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ServiceOptionCategory = {
  __typename: "ServiceOptionCategory",
  name: string,
  options:  Array<ServiceOptionItem >,
};

export type ServiceOptionItem = {
  __typename: "ServiceOptionItem",
  name: string,
  price?: number | null,
};

export type UpdateServiceOptionInput = {
  shopId: string,
  categories?: Array< ServiceOptionCategoryInput > | null,
  _version?: number | null,
};

export type DeleteServiceOptionInput = {
  shopId: string,
  _version?: number | null,
};

export type CreatePrepaidPassPresetInput = {
  id?: string | null,
  shopId: string,
  name: string,
  price: number,
  availableAmount: number,
  _version?: number | null,
};

export type ModelPrepaidPassPresetConditionInput = {
  shopId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  price?: ModelIntInput | null,
  availableAmount?: ModelIntInput | null,
  and?: Array< ModelPrepaidPassPresetConditionInput | null > | null,
  or?: Array< ModelPrepaidPassPresetConditionInput | null > | null,
  not?: ModelPrepaidPassPresetConditionInput | null,
};

export type PrepaidPassPreset = {
  __typename: "PrepaidPassPreset",
  id: string,
  shopId: string,
  name: string,
  price: number,
  availableAmount: number,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdatePrepaidPassPresetInput = {
  id: string,
  shopId?: string | null,
  name?: string | null,
  price?: number | null,
  availableAmount?: number | null,
  _version?: number | null,
};

export type DeletePrepaidPassPresetInput = {
  id: string,
  _version?: number | null,
};

export type CreatePrepaidPassHistoryInput = {
  id?: string | null,
  customerId: string,
  shopId: string,
  amount: number,
  bookingId?: string | null,
  type: PrepaidPassRecordType,
  description?: string | null,
  referenceId?: string | null,
  _version?: number | null,
};

export enum PrepaidPassRecordType {
  EARN = "EARN",
  SPEND = "SPEND",
}


export type ModelPrepaidPassHistoryConditionInput = {
  customerId?: ModelIDInput | null,
  shopId?: ModelIDInput | null,
  amount?: ModelIntInput | null,
  bookingId?: ModelIDInput | null,
  type?: ModelPrepaidPassRecordTypeInput | null,
  description?: ModelStringInput | null,
  referenceId?: ModelIDInput | null,
  and?: Array< ModelPrepaidPassHistoryConditionInput | null > | null,
  or?: Array< ModelPrepaidPassHistoryConditionInput | null > | null,
  not?: ModelPrepaidPassHistoryConditionInput | null,
};

export type ModelPrepaidPassRecordTypeInput = {
  eq?: PrepaidPassRecordType | null,
  ne?: PrepaidPassRecordType | null,
};

export type PrepaidPassHistory = {
  __typename: "PrepaidPassHistory",
  id: string,
  customerId: string,
  shopId: string,
  amount: number,
  bookingId?: string | null,
  type: PrepaidPassRecordType,
  description?: string | null,
  referenceId?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdatePrepaidPassHistoryInput = {
  id: string,
  customerId?: string | null,
  shopId?: string | null,
  amount?: number | null,
  bookingId?: string | null,
  type?: PrepaidPassRecordType | null,
  description?: string | null,
  referenceId?: string | null,
  _version?: number | null,
};

export type DeletePrepaidPassHistoryInput = {
  id: string,
  _version?: number | null,
};

export type CreateBrandInput = {
  id?: string | null,
  shopId: string,
  name: string,
  _version?: number | null,
};

export type ModelBrandConditionInput = {
  shopId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelBrandConditionInput | null > | null,
  or?: Array< ModelBrandConditionInput | null > | null,
  not?: ModelBrandConditionInput | null,
};

export type Brand = {
  __typename: "Brand",
  id: string,
  shopId: string,
  name: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateBrandInput = {
  id: string,
  shopId?: string | null,
  name?: string | null,
  _version?: number | null,
};

export type DeleteBrandInput = {
  id: string,
  _version?: number | null,
};

export type CreateProductInput = {
  id?: string | null,
  name: string,
  shopId: string,
  brandId: string,
  brandName: string,
  costPrice: number,
  sellingPrice: number,
  _version?: number | null,
};

export type ModelProductConditionInput = {
  name?: ModelStringInput | null,
  shopId?: ModelIDInput | null,
  brandId?: ModelIDInput | null,
  brandName?: ModelStringInput | null,
  costPrice?: ModelIntInput | null,
  sellingPrice?: ModelIntInput | null,
  and?: Array< ModelProductConditionInput | null > | null,
  or?: Array< ModelProductConditionInput | null > | null,
  not?: ModelProductConditionInput | null,
};

export type Product = {
  __typename: "Product",
  id: string,
  name: string,
  shopId: string,
  brandId: string,
  brandName: string,
  costPrice: number,
  sellingPrice: number,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateProductInput = {
  id: string,
  name?: string | null,
  shopId?: string | null,
  brandId?: string | null,
  brandName?: string | null,
  costPrice?: number | null,
  sellingPrice?: number | null,
  _version?: number | null,
};

export type DeleteProductInput = {
  id: string,
  _version?: number | null,
};

export type CreateConsultationSolutionPresetInput = {
  id?: string | null,
  category: string,
  sentence: string,
  priority: number,
  _version?: number | null,
};

export type ModelConsultationSolutionPresetConditionInput = {
  category?: ModelStringInput | null,
  sentence?: ModelStringInput | null,
  priority?: ModelIntInput | null,
  and?: Array< ModelConsultationSolutionPresetConditionInput | null > | null,
  or?: Array< ModelConsultationSolutionPresetConditionInput | null > | null,
  not?: ModelConsultationSolutionPresetConditionInput | null,
};

export type ConsultationSolutionPreset = {
  __typename: "ConsultationSolutionPreset",
  id: string,
  category: string,
  sentence: string,
  priority: number,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateConsultationSolutionPresetInput = {
  id: string,
  category?: string | null,
  sentence?: string | null,
  priority?: number | null,
  _version?: number | null,
};

export type DeleteConsultationSolutionPresetInput = {
  id: string,
  _version?: number | null,
};

export type CreateCoupangProductInput = {
  id?: string | null,
  src: string,
  name?: string | null,
  description?: string | null,
  _version?: number | null,
};

export type ModelCoupangProductConditionInput = {
  src?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelCoupangProductConditionInput | null > | null,
  or?: Array< ModelCoupangProductConditionInput | null > | null,
  not?: ModelCoupangProductConditionInput | null,
};

export type CoupangProduct = {
  __typename: "CoupangProduct",
  id: string,
  src: string,
  name?: string | null,
  description?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateCoupangProductInput = {
  id: string,
  src?: string | null,
  name?: string | null,
  description?: string | null,
  _version?: number | null,
};

export type DeleteCoupangProductInput = {
  id: string,
  _version?: number | null,
};

export type CreateGeneralProductInput = {
  id?: string | null,
  link: string,
  name: string,
  brandName: string,
  description?: string | null,
  imageUrl?: string | null,
  _version?: number | null,
};

export type ModelGeneralProductConditionInput = {
  link?: ModelStringInput | null,
  name?: ModelStringInput | null,
  brandName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  and?: Array< ModelGeneralProductConditionInput | null > | null,
  or?: Array< ModelGeneralProductConditionInput | null > | null,
  not?: ModelGeneralProductConditionInput | null,
};

export type GeneralProduct = {
  __typename: "GeneralProduct",
  id: string,
  link: string,
  name: string,
  brandName: string,
  description?: string | null,
  imageUrl?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateGeneralProductInput = {
  id: string,
  link?: string | null,
  name?: string | null,
  brandName?: string | null,
  description?: string | null,
  imageUrl?: string | null,
  _version?: number | null,
};

export type DeleteGeneralProductInput = {
  id: string,
  _version?: number | null,
};

export type CreateEventInput = {
  id?: string | null,
  hostName: string,
  speakerName?: string | null,
  name: string,
  description?: string | null,
  city: string,
  venue?: string | null,
  link?: string | null,
  yearMonth: string,
  startDate: string,
  endDate?: string | null,
  target?: string | null,
  _version?: number | null,
};

export type ModelEventConditionInput = {
  hostName?: ModelStringInput | null,
  speakerName?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  city?: ModelStringInput | null,
  venue?: ModelStringInput | null,
  link?: ModelStringInput | null,
  yearMonth?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  target?: ModelStringInput | null,
  and?: Array< ModelEventConditionInput | null > | null,
  or?: Array< ModelEventConditionInput | null > | null,
  not?: ModelEventConditionInput | null,
};

export type Event = {
  __typename: "Event",
  id: string,
  hostName: string,
  speakerName?: string | null,
  name: string,
  description?: string | null,
  city: string,
  venue?: string | null,
  link?: string | null,
  yearMonth: string,
  startDate: string,
  endDate?: string | null,
  target?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateEventInput = {
  id: string,
  hostName?: string | null,
  speakerName?: string | null,
  name?: string | null,
  description?: string | null,
  city?: string | null,
  venue?: string | null,
  link?: string | null,
  yearMonth?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  target?: string | null,
  _version?: number | null,
};

export type DeleteEventInput = {
  id: string,
  _version?: number | null,
};

export type CreateMutationResponseInput = {
  success: boolean,
  reason?: string | null,
  id?: string | null,
  _version?: number | null,
};

export type ModelMutationResponseConditionInput = {
  success?: ModelBooleanInput | null,
  reason?: ModelStringInput | null,
  and?: Array< ModelMutationResponseConditionInput | null > | null,
  or?: Array< ModelMutationResponseConditionInput | null > | null,
  not?: ModelMutationResponseConditionInput | null,
};

export type UpdateMutationResponseInput = {
  success?: boolean | null,
  reason?: string | null,
  id: string,
  _version?: number | null,
};

export type DeleteMutationResponseInput = {
  id: string,
  _version?: number | null,
};

export type ModelDiscountPresetFilterInput = {
  id?: ModelIDInput | null,
  shopId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  rate?: ModelFloatInput | null,
  type?: ModelDiscountModeInput | null,
  enabled?: ModelBooleanInput | null,
  and?: Array< ModelDiscountPresetFilterInput | null > | null,
  or?: Array< ModelDiscountPresetFilterInput | null > | null,
  not?: ModelDiscountPresetFilterInput | null,
};

export type ModelDiscountPresetConnection = {
  __typename: "ModelDiscountPresetConnection",
  items:  Array<DiscountPreset | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelDesignerFilterInput = {
  id?: ModelIDInput | null,
  shopId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  naverName?: ModelStringInput | null,
  businessId?: ModelStringInput | null,
  bizItemId?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  s3Key?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  youtubeUrl?: ModelStringInput | null,
  instagramUrl?: ModelStringInput | null,
  inactive?: ModelBooleanInput | null,
  and?: Array< ModelDesignerFilterInput | null > | null,
  or?: Array< ModelDesignerFilterInput | null > | null,
  not?: ModelDesignerFilterInput | null,
};

export type ModelDesignerConnection = {
  __typename: "ModelDesignerConnection",
  items:  Array<Designer | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelFeedbackFilterInput = {
  bookingId?: ModelIDInput | null,
  category?: ModelFeedbackCategoryInput | null,
  shopId?: ModelIDInput | null,
  shopName?: ModelStringInput | null,
  designerName?: ModelStringInput | null,
  designerId?: ModelIDInput | null,
  date?: ModelStringInput | null,
  satisfaction?: ModelIntInput | null,
  comment?: ModelStringInput | null,
  supportMessage?: ModelStringInput | null,
  modelVersion?: ModelIntInput | null,
  and?: Array< ModelFeedbackFilterInput | null > | null,
  or?: Array< ModelFeedbackFilterInput | null > | null,
  not?: ModelFeedbackFilterInput | null,
};

export type ModelFeedbackConnection = {
  __typename: "ModelFeedbackConnection",
  items:  Array<Feedback | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelRegularCustomerNoteFilterInput = {
  bookingId?: ModelIDInput | null,
  shopId?: ModelIDInput | null,
  shopName?: ModelStringInput | null,
  customerId?: ModelIDInput | null,
  customerName?: ModelStringInput | null,
  levelOfStyleChange?: ModelStringInput | null,
  nextSchedule?: ModelStringInput | null,
  comment?: ModelStringInput | null,
  productsInterestedIn?: ModelStringInput | null,
  hasPhotos?: ModelBooleanInput | null,
  modelVersion?: ModelIntInput | null,
  and?: Array< ModelRegularCustomerNoteFilterInput | null > | null,
  or?: Array< ModelRegularCustomerNoteFilterInput | null > | null,
  not?: ModelRegularCustomerNoteFilterInput | null,
};

export type ModelRegularCustomerNoteConnection = {
  __typename: "ModelRegularCustomerNoteConnection",
  items:  Array<RegularCustomerNote | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserPushTokenRequestFilterInput = {
  id?: ModelIDInput | null,
  token?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelUserPushTokenRequestFilterInput | null > | null,
  or?: Array< ModelUserPushTokenRequestFilterInput | null > | null,
  not?: ModelUserPushTokenRequestFilterInput | null,
};

export type ModelUserPushTokenRequestConnection = {
  __typename: "ModelUserPushTokenRequestConnection",
  items:  Array<UserPushTokenRequest | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserPushTokenFilterInput = {
  token?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelUserPushTokenFilterInput | null > | null,
  or?: Array< ModelUserPushTokenFilterInput | null > | null,
  not?: ModelUserPushTokenFilterInput | null,
};

export type ModelUserPushTokenConnection = {
  __typename: "ModelUserPushTokenConnection",
  items:  Array<UserPushToken | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCustomerV2FilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  name?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  lastFourPhoneNumbers?: ModelStringInput | null,
  firstVisitDate?: ModelStringInput | null,
  occupation?: ModelStringInput | null,
  drink?: ModelStringInput | null,
  visitCount?: ModelIntInput | null,
  selfCount?: ModelIntInput | null,
  regularCount?: ModelIntInput | null,
  caringCount?: ModelIntInput | null,
  comment?: ModelStringInput | null,
  modelVersion?: ModelIntInput | null,
  cut?: ModelBooleanInput | null,
  perm?: ModelBooleanInput | null,
  color?: ModelBooleanInput | null,
  clinic?: ModelBooleanInput | null,
  gender?: ModelStringInput | null,
  and?: Array< ModelCustomerV2FilterInput | null > | null,
  or?: Array< ModelCustomerV2FilterInput | null > | null,
  not?: ModelCustomerV2FilterInput | null,
};

export type ModelCustomerV2Connection = {
  __typename: "ModelCustomerV2Connection",
  items:  Array<CustomerV2 | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCaringFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  shopName?: ModelStringInput | null,
  designerName?: ModelStringInput | null,
  name?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  yearMonth?: ModelStringInput | null,
  date?: ModelStringInput | null,
  sentAt?: ModelStringInput | null,
  timestamp?: ModelIntInput | null,
  pointOfToday?: ModelStringInput | null,
  aftercareTips?: ModelStringInput | null,
  stylingTips?: ModelStringInput | null,
  precautions?: ModelStringInput | null,
  serviceRecommendation?: ModelStringInput | null,
  nextVisitDate?: ModelStringInput | null,
  modelVersion?: ModelIntInput | null,
  customerId?: ModelIDInput | null,
  and?: Array< ModelCaringFilterInput | null > | null,
  or?: Array< ModelCaringFilterInput | null > | null,
  not?: ModelCaringFilterInput | null,
};

export type ModelCaringConnection = {
  __typename: "ModelCaringConnection",
  items:  Array<Caring | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelSelfDiagnosisRequestFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  shopName?: ModelStringInput | null,
  designerName?: ModelStringInput | null,
  name?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  yearMonth?: ModelStringInput | null,
  date?: ModelStringInput | null,
  sentAt?: ModelStringInput | null,
  writtenAt?: ModelStringInput | null,
  timestamp?: ModelIntInput | null,
  modelVersion?: ModelIntInput | null,
  customerId?: ModelIDInput | null,
  and?: Array< ModelSelfDiagnosisRequestFilterInput | null > | null,
  or?: Array< ModelSelfDiagnosisRequestFilterInput | null > | null,
  not?: ModelSelfDiagnosisRequestFilterInput | null,
};

export type ModelSelfDiagnosisRequestConnection = {
  __typename: "ModelSelfDiagnosisRequestConnection",
  items:  Array<SelfDiagnosisRequest | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSelfDiagnosisContentFilterInput = {
  requestId?: ModelIDInput | null,
  shopName?: ModelStringInput | null,
  designerName?: ModelStringInput | null,
  name?: ModelStringInput | null,
  yearMonth?: ModelStringInput | null,
  date?: ModelStringInput | null,
  inflowChannel?: ModelStringInput | null,
  gender?: ModelConsultationGenderInput | null,
  dry?: ModelBooleanInput | null,
  concerns?: ModelStringInput | null,
  frequencyOfVisits?: ModelStringInput | null,
  outfitStyles?: ModelStringInput | null,
  treatmentsInterestedIn?: ModelStringInput | null,
  productsForShampooing?: ModelStringInput | null,
  productsForStyling?: ModelStringInput | null,
  recentDyeing?: ModelStringInput | null,
  recentPerm?: ModelStringInput | null,
  hasPhotos?: ModelBooleanInput | null,
  blackDyedHair?: ModelBooleanInput | null,
  bleachedHair?: ModelBooleanInput | null,
  modelVersion?: ModelIntInput | null,
  customerId?: ModelIDInput | null,
  and?: Array< ModelSelfDiagnosisContentFilterInput | null > | null,
  or?: Array< ModelSelfDiagnosisContentFilterInput | null > | null,
  not?: ModelSelfDiagnosisContentFilterInput | null,
};

export type ModelSelfDiagnosisContentConnection = {
  __typename: "ModelSelfDiagnosisContentConnection",
  items:  Array<SelfDiagnosisContent | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelRegularCustomerQuestionnaireRequestFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  shopName?: ModelStringInput | null,
  designerName?: ModelStringInput | null,
  name?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  yearMonth?: ModelStringInput | null,
  date?: ModelStringInput | null,
  sentAt?: ModelStringInput | null,
  writtenAt?: ModelStringInput | null,
  timestamp?: ModelIntInput | null,
  modelVersion?: ModelIntInput | null,
  customerId?: ModelIDInput | null,
  and?: Array< ModelRegularCustomerQuestionnaireRequestFilterInput | null > | null,
  or?: Array< ModelRegularCustomerQuestionnaireRequestFilterInput | null > | null,
  not?: ModelRegularCustomerQuestionnaireRequestFilterInput | null,
};

export type ModelRegularCustomerQuestionnaireRequestConnection = {
  __typename: "ModelRegularCustomerQuestionnaireRequestConnection",
  items:  Array<RegularCustomerQuestionnaireRequest | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelRegularCustomerQuestionnaireContentFilterInput = {
  requestId?: ModelIDInput | null,
  shopName?: ModelStringInput | null,
  designerName?: ModelStringInput | null,
  name?: ModelStringInput | null,
  yearMonth?: ModelStringInput | null,
  date?: ModelStringInput | null,
  levelOfStyleChange?: ModelStringInput | null,
  nextSchedule?: ModelStringInput | null,
  comment?: ModelStringInput | null,
  productsInterestedIn?: ModelStringInput | null,
  treatmentsInterestedIn?: ModelStringInput | null,
  hasPhotos?: ModelBooleanInput | null,
  timestamp?: ModelIntInput | null,
  modelVersion?: ModelIntInput | null,
  customerId?: ModelIDInput | null,
  and?: Array< ModelRegularCustomerQuestionnaireContentFilterInput | null > | null,
  or?: Array< ModelRegularCustomerQuestionnaireContentFilterInput | null > | null,
  not?: ModelRegularCustomerQuestionnaireContentFilterInput | null,
};

export type ModelRegularCustomerQuestionnaireContentConnection = {
  __typename: "ModelRegularCustomerQuestionnaireContentConnection",
  items:  Array<RegularCustomerQuestionnaireContent | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserActivitySnapshotFilterInput = {
  id?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  dailyLogCount?: ModelIntInput | null,
  selfDiagnosisCount?: ModelIntInput | null,
  regularCustomerQuestionnaireCount?: ModelIntInput | null,
  caringCount?: ModelIntInput | null,
  modelVersion?: ModelIntInput | null,
  and?: Array< ModelUserActivitySnapshotFilterInput | null > | null,
  or?: Array< ModelUserActivitySnapshotFilterInput | null > | null,
  not?: ModelUserActivitySnapshotFilterInput | null,
};

export type ModelUserActivitySnapshotConnection = {
  __typename: "ModelUserActivitySnapshotConnection",
  items:  Array<UserActivitySnapshot | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDailyLogFilterInput = {
  id?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  shopName?: ModelStringInput | null,
  designerName?: ModelStringInput | null,
  yearMonth?: ModelStringInput | null,
  date?: ModelStringInput | null,
  timestamp?: ModelIntInput | null,
  averageConsultationMinutes?: ModelIntInput | null,
  serviceRecommendationCount?: ModelIntInput | null,
  productRecommendationCount?: ModelIntInput | null,
  prepaidTicketRecommendationCount?: ModelIntInput | null,
  stretch?: ModelBooleanInput | null,
  drinkingWater?: ModelBooleanInput | null,
  comment?: ModelStringInput | null,
  modelVersion?: ModelIntInput | null,
  and?: Array< ModelDailyLogFilterInput | null > | null,
  or?: Array< ModelDailyLogFilterInput | null > | null,
  not?: ModelDailyLogFilterInput | null,
};

export type ModelDailyLogConnection = {
  __typename: "ModelDailyLogConnection",
  items:  Array<DailyLog | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSelfDiagnosisFilterInput = {
  bookingId?: ModelIDInput | null,
  customerId?: ModelIDInput | null,
  customerName?: ModelStringInput | null,
  yearMonth?: ModelStringInput | null,
  date?: ModelStringInput | null,
  shopId?: ModelIDInput | null,
  shopName?: ModelStringInput | null,
  designerId?: ModelIDInput | null,
  designerName?: ModelStringInput | null,
  inflowChannel?: ModelStringInput | null,
  gender?: ModelConsultationGenderInput | null,
  dry?: ModelBooleanInput | null,
  concerns?: ModelStringInput | null,
  frequencyOfVisits?: ModelStringInput | null,
  outfitStyles?: ModelStringInput | null,
  treatmentsInterestedIn?: ModelStringInput | null,
  productsForShampooing?: ModelStringInput | null,
  productsForStyling?: ModelStringInput | null,
  recentDyeing?: ModelStringInput | null,
  recentPerm?: ModelStringInput | null,
  hasPhotos?: ModelBooleanInput | null,
  blackDyedHair?: ModelBooleanInput | null,
  bleachedHair?: ModelBooleanInput | null,
  modelVersion?: ModelIntInput | null,
  and?: Array< ModelSelfDiagnosisFilterInput | null > | null,
  or?: Array< ModelSelfDiagnosisFilterInput | null > | null,
  not?: ModelSelfDiagnosisFilterInput | null,
};

export type ModelSelfDiagnosisConnection = {
  __typename: "ModelSelfDiagnosisConnection",
  items:  Array<SelfDiagnosis | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelConsultationFilterInput = {
  id?: ModelIDInput | null,
  bookingId?: ModelIDInput | null,
  customerId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  yearMonth?: ModelStringInput | null,
  date?: ModelStringInput | null,
  shopId?: ModelIDInput | null,
  status?: ModelConsultationStatusInput | null,
  selfDiagnosisStatus?: ModelConsultationSelfDiagnosisStatusInput | null,
  mode?: ModelConsultationModeInput | null,
  inflowChannel?: ModelStringInput | null,
  gender?: ModelConsultationGenderInput | null,
  dry?: ModelBooleanInput | null,
  concerns?: ModelStringInput | null,
  frequencyOfVisits?: ModelStringInput | null,
  outfitStyles?: ModelStringInput | null,
  treatmentsInterestedIn?: ModelStringInput | null,
  productsForShampooing?: ModelStringInput | null,
  productsForStyling?: ModelStringInput | null,
  neckLength?: ModelStringInput | null,
  neckThickness?: ModelStringInput | null,
  shoulderWidth?: ModelStringInput | null,
  shoulderShape?: ModelStringInput | null,
  hairColor?: ModelStringInput | null,
  virginHairColor?: ModelStringInput | null,
  colorfulnessOfHair?: ModelStringInput | null,
  colorfulnessesOfHair?: ModelStringInput | null,
  recentDyeing?: ModelStringInput | null,
  recentFirm?: ModelStringInput | null,
  damageDegreeOfHair?: ModelStringInput | null,
  amountOfHair?: ModelStringInput | null,
  thicknessOfHair?: ModelStringInput | null,
  curlDegreeOfHair?: ModelStringInput | null,
  faceShape?: ModelStringInput | null,
  faceShapes?: ModelStringInput | null,
  frontHeadShape?: ModelStringInput | null,
  sideHeadShape?: ModelStringInput | null,
  solutionSentAt?: ModelStringInput | null,
  shopName?: ModelStringInput | null,
  designerName?: ModelStringInput | null,
  designerId?: ModelIDInput | null,
  hasPhotos?: ModelBooleanInput | null,
  modelVersion?: ModelIntInput | null,
  and?: Array< ModelConsultationFilterInput | null > | null,
  or?: Array< ModelConsultationFilterInput | null > | null,
  not?: ModelConsultationFilterInput | null,
};

export type ModelConsultationConnection = {
  __typename: "ModelConsultationConnection",
  items:  Array<Consultation | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAnonymousPhotoFilterInput = {
  id?: ModelIDInput | null,
  bookingId?: ModelIDInput | null,
  consultationId?: ModelIDInput | null,
  requestId?: ModelIDInput | null,
  s3Key?: ModelStringInput | null,
  displayOrder?: ModelIntInput | null,
  category?: ModelAnonymousPhotoCategoryInput | null,
  and?: Array< ModelAnonymousPhotoFilterInput | null > | null,
  or?: Array< ModelAnonymousPhotoFilterInput | null > | null,
  not?: ModelAnonymousPhotoFilterInput | null,
};

export type ModelAnonymousPhotoConnection = {
  __typename: "ModelAnonymousPhotoConnection",
  items:  Array<AnonymousPhoto | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPhotoFilterInput = {
  id?: ModelIDInput | null,
  bookingId?: ModelIDInput | null,
  s3Key?: ModelStringInput | null,
  displayOrder?: ModelIntInput | null,
  identityId?: ModelStringInput | null,
  description?: ModelStringInput | null,
  category?: ModelPhotoCategoryInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelPhotoFilterInput | null > | null,
  or?: Array< ModelPhotoFilterInput | null > | null,
  not?: ModelPhotoFilterInput | null,
};

export type ModelPhotoConnection = {
  __typename: "ModelPhotoConnection",
  items:  Array<Photo | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCheckInFilterInput = {
  bookingId?: ModelIDInput | null,
  shopId?: ModelIDInput | null,
  checkInRequests?: ModelStringInput | null,
  drink?: ModelStringInput | null,
  modelVersion?: ModelIntInput | null,
  and?: Array< ModelCheckInFilterInput | null > | null,
  or?: Array< ModelCheckInFilterInput | null > | null,
  not?: ModelCheckInFilterInput | null,
};

export type ModelCheckInConnection = {
  __typename: "ModelCheckInConnection",
  items:  Array<CheckIn | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelBookingFilterInput = {
  id?: ModelIDInput | null,
  yearMonth?: ModelStringInput | null,
  date?: ModelStringInput | null,
  time?: ModelStringInput | null,
  customerId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  consultationId?: ModelIDInput | null,
  hasSelfDiagnosis?: ModelBooleanInput | null,
  hasRegularCustomerNote?: ModelBooleanInput | null,
  designerName?: ModelStringInput | null,
  designerId?: ModelIDInput | null,
  optionName?: ModelStringInput | null,
  shopId?: ModelIDInput | null,
  shopName?: ModelStringInput | null,
  naverBookingNumber?: ModelStringInput | null,
  bookingStatus?: ModelBookingStatusInput | null,
  serviceStatus?: ModelServiceStatusInput | null,
  paymentStatus?: ModelPaymentStatusInput | null,
  requestMessage?: ModelStringInput | null,
  designerMemo?: ModelStringInput | null,
  initialPrice?: ModelIntInput | null,
  finalPrice?: ModelIntInput | null,
  totalServiceSales?: ModelIntInput | null,
  totalProductSales?: ModelIntInput | null,
  initialDeposit?: ModelIntInput | null,
  paidAmount?: ModelIntInput | null,
  paidInCashAmount?: ModelIntInput | null,
  paidByCardAmount?: ModelIntInput | null,
  paidByPrepaidPassAmount?: ModelIntInput | null,
  paidByNaverPayAmount?: ModelIntInput | null,
  paidAt?: ModelStringInput | null,
  paymentMemo?: ModelStringInput | null,
  checkInRequests?: ModelStringInput | null,
  drink?: ModelStringInput | null,
  checkedInAt?: ModelStringInput | null,
  origin?: ModelBookingOriginInput | null,
  signature?: ModelStringInput | null,
  signedAt?: ModelStringInput | null,
  recentNaverDataReflection?: ModelStringInput | null,
  selfDiagnosisSentAt?: ModelStringInput | null,
  feedbackRequestSentAt?: ModelStringInput | null,
  hasFeedback?: ModelBooleanInput | null,
  hasPhotos?: ModelBooleanInput | null,
  hasDesignCompletionPhotos?: ModelBooleanInput | null,
  signatureOfPortraitRights?: ModelStringInput | null,
  signedAtOfPortraitRights?: ModelStringInput | null,
  and?: Array< ModelBookingFilterInput | null > | null,
  or?: Array< ModelBookingFilterInput | null > | null,
  not?: ModelBookingFilterInput | null,
};

export type ModelBookingConnection = {
  __typename: "ModelBookingConnection",
  items:  Array<Booking | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCustomerFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  lastFourPhoneNumbers?: ModelStringInput | null,
  firstVisitDate?: ModelStringInput | null,
  occupation?: ModelStringInput | null,
  drink?: ModelStringInput | null,
  dress?: ModelStringInput | null,
  trouble?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  bookingCount?: ModelIntInput | null,
  consultationCount?: ModelIntInput | null,
  consultationDoneCount?: ModelIntInput | null,
  signature?: ModelStringInput | null,
  totalRevenue?: ModelIntInput | null,
  vehicleNumber?: ModelStringInput | null,
  shopId?: ModelIDInput | null,
  shopIds?: ModelStringInput | null,
  shopIdsString?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  prepaidPassBalance?: ModelIntInput | null,
  and?: Array< ModelCustomerFilterInput | null > | null,
  or?: Array< ModelCustomerFilterInput | null > | null,
  not?: ModelCustomerFilterInput | null,
};

export type ModelCustomerConnection = {
  __typename: "ModelCustomerConnection",
  items:  Array<Customer | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelShopFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  naverBusinessId?: ModelStringInput | null,
  hasLocker?: ModelBooleanInput | null,
  bookingQRS3Key?: ModelStringInput | null,
  creatorId?: ModelIDInput | null,
  usePreSelfDiagnosis?: ModelBooleanInput | null,
  useFeedbackRequest?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  ownerName?: ModelStringInput | null,
  ownerPhoneNumber?: ModelStringInput | null,
  internPhoneNumber?: ModelStringInput | null,
  and?: Array< ModelShopFilterInput | null > | null,
  or?: Array< ModelShopFilterInput | null > | null,
  not?: ModelShopFilterInput | null,
};

export type ModelShopConnection = {
  __typename: "ModelShopConnection",
  items:  Array<Shop | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelShopServiceDrinkFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  shopId?: ModelIDInput | null,
  outOfStock?: ModelBooleanInput | null,
  displayOrder?: ModelIntInput | null,
  and?: Array< ModelShopServiceDrinkFilterInput | null > | null,
  or?: Array< ModelShopServiceDrinkFilterInput | null > | null,
  not?: ModelShopServiceDrinkFilterInput | null,
};

export type ModelShopServiceDrinkConnection = {
  __typename: "ModelShopServiceDrinkConnection",
  items:  Array<ShopServiceDrink | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelShopUserApplicationFilterInput = {
  id?: ModelIDInput | null,
  shopId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  phoneNumber?: ModelStringInput | null,
  completed?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  and?: Array< ModelShopUserApplicationFilterInput | null > | null,
  or?: Array< ModelShopUserApplicationFilterInput | null > | null,
  not?: ModelShopUserApplicationFilterInput | null,
};

export type ModelShopUserApplicationConnection = {
  __typename: "ModelShopUserApplicationConnection",
  items:  Array<ShopUserApplication | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelShopUserRemovalRequestFilterInput = {
  designerId?: ModelIDInput | null,
  shopId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  completed?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelShopUserRemovalRequestFilterInput | null > | null,
  or?: Array< ModelShopUserRemovalRequestFilterInput | null > | null,
  not?: ModelShopUserRemovalRequestFilterInput | null,
};

export type ModelShopUserRemovalRequestConnection = {
  __typename: "ModelShopUserRemovalRequestConnection",
  items:  Array<ShopUserRemovalRequest | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelServiceOptionFilterInput = {
  shopId?: ModelIDInput | null,
  and?: Array< ModelServiceOptionFilterInput | null > | null,
  or?: Array< ModelServiceOptionFilterInput | null > | null,
  not?: ModelServiceOptionFilterInput | null,
};

export type ModelServiceOptionConnection = {
  __typename: "ModelServiceOptionConnection",
  items:  Array<ServiceOption | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPrepaidPassPresetFilterInput = {
  id?: ModelIDInput | null,
  shopId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  price?: ModelIntInput | null,
  availableAmount?: ModelIntInput | null,
  and?: Array< ModelPrepaidPassPresetFilterInput | null > | null,
  or?: Array< ModelPrepaidPassPresetFilterInput | null > | null,
  not?: ModelPrepaidPassPresetFilterInput | null,
};

export type ModelPrepaidPassPresetConnection = {
  __typename: "ModelPrepaidPassPresetConnection",
  items:  Array<PrepaidPassPreset | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPrepaidPassHistoryFilterInput = {
  id?: ModelIDInput | null,
  customerId?: ModelIDInput | null,
  shopId?: ModelIDInput | null,
  amount?: ModelIntInput | null,
  bookingId?: ModelIDInput | null,
  type?: ModelPrepaidPassRecordTypeInput | null,
  description?: ModelStringInput | null,
  referenceId?: ModelIDInput | null,
  and?: Array< ModelPrepaidPassHistoryFilterInput | null > | null,
  or?: Array< ModelPrepaidPassHistoryFilterInput | null > | null,
  not?: ModelPrepaidPassHistoryFilterInput | null,
};

export type ModelPrepaidPassHistoryConnection = {
  __typename: "ModelPrepaidPassHistoryConnection",
  items:  Array<PrepaidPassHistory | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelBrandFilterInput = {
  id?: ModelIDInput | null,
  shopId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelBrandFilterInput | null > | null,
  or?: Array< ModelBrandFilterInput | null > | null,
  not?: ModelBrandFilterInput | null,
};

export type ModelBrandConnection = {
  __typename: "ModelBrandConnection",
  items:  Array<Brand | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelProductFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  shopId?: ModelIDInput | null,
  brandId?: ModelIDInput | null,
  brandName?: ModelStringInput | null,
  costPrice?: ModelIntInput | null,
  sellingPrice?: ModelIntInput | null,
  and?: Array< ModelProductFilterInput | null > | null,
  or?: Array< ModelProductFilterInput | null > | null,
  not?: ModelProductFilterInput | null,
};

export type ModelProductConnection = {
  __typename: "ModelProductConnection",
  items:  Array<Product | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelConsultationSolutionPresetFilterInput = {
  id?: ModelIDInput | null,
  category?: ModelStringInput | null,
  sentence?: ModelStringInput | null,
  priority?: ModelIntInput | null,
  and?: Array< ModelConsultationSolutionPresetFilterInput | null > | null,
  or?: Array< ModelConsultationSolutionPresetFilterInput | null > | null,
  not?: ModelConsultationSolutionPresetFilterInput | null,
};

export type ModelConsultationSolutionPresetConnection = {
  __typename: "ModelConsultationSolutionPresetConnection",
  items:  Array<ConsultationSolutionPreset | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCoupangProductFilterInput = {
  id?: ModelIDInput | null,
  src?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelCoupangProductFilterInput | null > | null,
  or?: Array< ModelCoupangProductFilterInput | null > | null,
  not?: ModelCoupangProductFilterInput | null,
};

export type ModelCoupangProductConnection = {
  __typename: "ModelCoupangProductConnection",
  items:  Array<CoupangProduct | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelGeneralProductFilterInput = {
  id?: ModelIDInput | null,
  link?: ModelStringInput | null,
  name?: ModelStringInput | null,
  brandName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  and?: Array< ModelGeneralProductFilterInput | null > | null,
  or?: Array< ModelGeneralProductFilterInput | null > | null,
  not?: ModelGeneralProductFilterInput | null,
};

export type ModelGeneralProductConnection = {
  __typename: "ModelGeneralProductConnection",
  items:  Array<GeneralProduct | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelEventFilterInput = {
  id?: ModelIDInput | null,
  hostName?: ModelStringInput | null,
  speakerName?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  city?: ModelStringInput | null,
  venue?: ModelStringInput | null,
  link?: ModelStringInput | null,
  yearMonth?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  target?: ModelStringInput | null,
  and?: Array< ModelEventFilterInput | null > | null,
  or?: Array< ModelEventFilterInput | null > | null,
  not?: ModelEventFilterInput | null,
};

export type ModelEventConnection = {
  __typename: "ModelEventConnection",
  items:  Array<Event | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMutationResponseFilterInput = {
  success?: ModelBooleanInput | null,
  reason?: ModelStringInput | null,
  and?: Array< ModelMutationResponseFilterInput | null > | null,
  or?: Array< ModelMutationResponseFilterInput | null > | null,
  not?: ModelMutationResponseFilterInput | null,
};

export type ModelMutationResponseConnection = {
  __typename: "ModelMutationResponseConnection",
  items:  Array<MutationResponse | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSubscriptionDiscountPresetFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  shopId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  amount?: ModelSubscriptionIntInput | null,
  rate?: ModelSubscriptionFloatInput | null,
  type?: ModelSubscriptionStringInput | null,
  enabled?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionDiscountPresetFilterInput | null > | null,
  or?: Array< ModelSubscriptionDiscountPresetFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionDesignerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  shopId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  naverName?: ModelSubscriptionStringInput | null,
  businessId?: ModelSubscriptionStringInput | null,
  bizItemId?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  imageUrl?: ModelSubscriptionStringInput | null,
  s3Key?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  youtubeUrl?: ModelSubscriptionStringInput | null,
  instagramUrl?: ModelSubscriptionStringInput | null,
  inactive?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionDesignerFilterInput | null > | null,
  or?: Array< ModelSubscriptionDesignerFilterInput | null > | null,
};

export type ModelSubscriptionFeedbackFilterInput = {
  bookingId?: ModelSubscriptionIDInput | null,
  category?: ModelSubscriptionStringInput | null,
  shopId?: ModelSubscriptionIDInput | null,
  shopName?: ModelSubscriptionStringInput | null,
  designerName?: ModelSubscriptionStringInput | null,
  designerId?: ModelSubscriptionIDInput | null,
  date?: ModelSubscriptionStringInput | null,
  satisfaction?: ModelSubscriptionIntInput | null,
  comment?: ModelSubscriptionStringInput | null,
  supportMessage?: ModelSubscriptionStringInput | null,
  modelVersion?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionFeedbackFilterInput | null > | null,
  or?: Array< ModelSubscriptionFeedbackFilterInput | null > | null,
};

export type ModelSubscriptionRegularCustomerNoteFilterInput = {
  bookingId?: ModelSubscriptionIDInput | null,
  shopId?: ModelSubscriptionIDInput | null,
  shopName?: ModelSubscriptionStringInput | null,
  customerId?: ModelSubscriptionIDInput | null,
  customerName?: ModelSubscriptionStringInput | null,
  levelOfStyleChange?: ModelSubscriptionStringInput | null,
  nextSchedule?: ModelSubscriptionStringInput | null,
  comment?: ModelSubscriptionStringInput | null,
  productsInterestedIn?: ModelSubscriptionStringInput | null,
  hasPhotos?: ModelSubscriptionBooleanInput | null,
  modelVersion?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionRegularCustomerNoteFilterInput | null > | null,
  or?: Array< ModelSubscriptionRegularCustomerNoteFilterInput | null > | null,
};

export type ModelSubscriptionUserPushTokenRequestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  token?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserPushTokenRequestFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserPushTokenRequestFilterInput | null > | null,
};

export type ModelSubscriptionUserPushTokenFilterInput = {
  token?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserPushTokenFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserPushTokenFilterInput | null > | null,
};

export type ModelSubscriptionCustomerV2FilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  lastFourPhoneNumbers?: ModelSubscriptionStringInput | null,
  firstVisitDate?: ModelSubscriptionStringInput | null,
  occupation?: ModelSubscriptionStringInput | null,
  drink?: ModelSubscriptionStringInput | null,
  visitCount?: ModelSubscriptionIntInput | null,
  selfCount?: ModelSubscriptionIntInput | null,
  regularCount?: ModelSubscriptionIntInput | null,
  caringCount?: ModelSubscriptionIntInput | null,
  comment?: ModelSubscriptionStringInput | null,
  modelVersion?: ModelSubscriptionIntInput | null,
  cut?: ModelSubscriptionBooleanInput | null,
  perm?: ModelSubscriptionBooleanInput | null,
  color?: ModelSubscriptionBooleanInput | null,
  clinic?: ModelSubscriptionBooleanInput | null,
  gender?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCustomerV2FilterInput | null > | null,
  or?: Array< ModelSubscriptionCustomerV2FilterInput | null > | null,
};

export type ModelSubscriptionCaringFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  shopName?: ModelSubscriptionStringInput | null,
  designerName?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  yearMonth?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  sentAt?: ModelSubscriptionStringInput | null,
  timestamp?: ModelSubscriptionIntInput | null,
  pointOfToday?: ModelSubscriptionStringInput | null,
  aftercareTips?: ModelSubscriptionStringInput | null,
  stylingTips?: ModelSubscriptionStringInput | null,
  precautions?: ModelSubscriptionStringInput | null,
  serviceRecommendation?: ModelSubscriptionStringInput | null,
  nextVisitDate?: ModelSubscriptionStringInput | null,
  modelVersion?: ModelSubscriptionIntInput | null,
  customerId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionCaringFilterInput | null > | null,
  or?: Array< ModelSubscriptionCaringFilterInput | null > | null,
};

export type ModelSubscriptionSelfDiagnosisRequestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  shopName?: ModelSubscriptionStringInput | null,
  designerName?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  yearMonth?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  sentAt?: ModelSubscriptionStringInput | null,
  writtenAt?: ModelSubscriptionStringInput | null,
  timestamp?: ModelSubscriptionIntInput | null,
  modelVersion?: ModelSubscriptionIntInput | null,
  customerId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionSelfDiagnosisRequestFilterInput | null > | null,
  or?: Array< ModelSubscriptionSelfDiagnosisRequestFilterInput | null > | null,
};

export type ModelSubscriptionSelfDiagnosisContentFilterInput = {
  requestId?: ModelSubscriptionIDInput | null,
  shopName?: ModelSubscriptionStringInput | null,
  designerName?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  yearMonth?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  inflowChannel?: ModelSubscriptionStringInput | null,
  gender?: ModelSubscriptionStringInput | null,
  dry?: ModelSubscriptionBooleanInput | null,
  concerns?: ModelSubscriptionStringInput | null,
  frequencyOfVisits?: ModelSubscriptionStringInput | null,
  outfitStyles?: ModelSubscriptionStringInput | null,
  treatmentsInterestedIn?: ModelSubscriptionStringInput | null,
  productsForShampooing?: ModelSubscriptionStringInput | null,
  productsForStyling?: ModelSubscriptionStringInput | null,
  recentDyeing?: ModelSubscriptionStringInput | null,
  recentPerm?: ModelSubscriptionStringInput | null,
  hasPhotos?: ModelSubscriptionBooleanInput | null,
  blackDyedHair?: ModelSubscriptionBooleanInput | null,
  bleachedHair?: ModelSubscriptionBooleanInput | null,
  modelVersion?: ModelSubscriptionIntInput | null,
  customerId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionSelfDiagnosisContentFilterInput | null > | null,
  or?: Array< ModelSubscriptionSelfDiagnosisContentFilterInput | null > | null,
};

export type ModelSubscriptionRegularCustomerQuestionnaireRequestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  shopName?: ModelSubscriptionStringInput | null,
  designerName?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  yearMonth?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  sentAt?: ModelSubscriptionStringInput | null,
  writtenAt?: ModelSubscriptionStringInput | null,
  timestamp?: ModelSubscriptionIntInput | null,
  modelVersion?: ModelSubscriptionIntInput | null,
  customerId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionRegularCustomerQuestionnaireRequestFilterInput | null > | null,
  or?: Array< ModelSubscriptionRegularCustomerQuestionnaireRequestFilterInput | null > | null,
};

export type ModelSubscriptionRegularCustomerQuestionnaireContentFilterInput = {
  requestId?: ModelSubscriptionIDInput | null,
  shopName?: ModelSubscriptionStringInput | null,
  designerName?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  yearMonth?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  levelOfStyleChange?: ModelSubscriptionStringInput | null,
  nextSchedule?: ModelSubscriptionStringInput | null,
  comment?: ModelSubscriptionStringInput | null,
  productsInterestedIn?: ModelSubscriptionStringInput | null,
  treatmentsInterestedIn?: ModelSubscriptionStringInput | null,
  hasPhotos?: ModelSubscriptionBooleanInput | null,
  timestamp?: ModelSubscriptionIntInput | null,
  modelVersion?: ModelSubscriptionIntInput | null,
  customerId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionRegularCustomerQuestionnaireContentFilterInput | null > | null,
  or?: Array< ModelSubscriptionRegularCustomerQuestionnaireContentFilterInput | null > | null,
};

export type ModelSubscriptionUserActivitySnapshotFilterInput = {
  id?: ModelSubscriptionStringInput | null,
  dailyLogCount?: ModelSubscriptionIntInput | null,
  selfDiagnosisCount?: ModelSubscriptionIntInput | null,
  regularCustomerQuestionnaireCount?: ModelSubscriptionIntInput | null,
  caringCount?: ModelSubscriptionIntInput | null,
  modelVersion?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionUserActivitySnapshotFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserActivitySnapshotFilterInput | null > | null,
};

export type ModelSubscriptionDailyLogFilterInput = {
  id?: ModelSubscriptionStringInput | null,
  shopName?: ModelSubscriptionStringInput | null,
  designerName?: ModelSubscriptionStringInput | null,
  yearMonth?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  timestamp?: ModelSubscriptionIntInput | null,
  averageConsultationMinutes?: ModelSubscriptionIntInput | null,
  serviceRecommendationCount?: ModelSubscriptionIntInput | null,
  productRecommendationCount?: ModelSubscriptionIntInput | null,
  prepaidTicketRecommendationCount?: ModelSubscriptionIntInput | null,
  stretch?: ModelSubscriptionBooleanInput | null,
  drinkingWater?: ModelSubscriptionBooleanInput | null,
  comment?: ModelSubscriptionStringInput | null,
  modelVersion?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionDailyLogFilterInput | null > | null,
  or?: Array< ModelSubscriptionDailyLogFilterInput | null > | null,
};

export type ModelSubscriptionSelfDiagnosisFilterInput = {
  bookingId?: ModelSubscriptionIDInput | null,
  customerId?: ModelSubscriptionIDInput | null,
  customerName?: ModelSubscriptionStringInput | null,
  yearMonth?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  shopId?: ModelSubscriptionIDInput | null,
  shopName?: ModelSubscriptionStringInput | null,
  designerId?: ModelSubscriptionIDInput | null,
  designerName?: ModelSubscriptionStringInput | null,
  inflowChannel?: ModelSubscriptionStringInput | null,
  gender?: ModelSubscriptionStringInput | null,
  dry?: ModelSubscriptionBooleanInput | null,
  concerns?: ModelSubscriptionStringInput | null,
  frequencyOfVisits?: ModelSubscriptionStringInput | null,
  outfitStyles?: ModelSubscriptionStringInput | null,
  treatmentsInterestedIn?: ModelSubscriptionStringInput | null,
  productsForShampooing?: ModelSubscriptionStringInput | null,
  productsForStyling?: ModelSubscriptionStringInput | null,
  recentDyeing?: ModelSubscriptionStringInput | null,
  recentPerm?: ModelSubscriptionStringInput | null,
  hasPhotos?: ModelSubscriptionBooleanInput | null,
  blackDyedHair?: ModelSubscriptionBooleanInput | null,
  bleachedHair?: ModelSubscriptionBooleanInput | null,
  modelVersion?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionSelfDiagnosisFilterInput | null > | null,
  or?: Array< ModelSubscriptionSelfDiagnosisFilterInput | null > | null,
};

export type ModelSubscriptionConsultationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  bookingId?: ModelSubscriptionIDInput | null,
  customerId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  yearMonth?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  shopId?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  selfDiagnosisStatus?: ModelSubscriptionStringInput | null,
  mode?: ModelSubscriptionStringInput | null,
  inflowChannel?: ModelSubscriptionStringInput | null,
  gender?: ModelSubscriptionStringInput | null,
  dry?: ModelSubscriptionBooleanInput | null,
  concerns?: ModelSubscriptionStringInput | null,
  frequencyOfVisits?: ModelSubscriptionStringInput | null,
  outfitStyles?: ModelSubscriptionStringInput | null,
  treatmentsInterestedIn?: ModelSubscriptionStringInput | null,
  productsForShampooing?: ModelSubscriptionStringInput | null,
  productsForStyling?: ModelSubscriptionStringInput | null,
  neckLength?: ModelSubscriptionStringInput | null,
  neckThickness?: ModelSubscriptionStringInput | null,
  shoulderWidth?: ModelSubscriptionStringInput | null,
  shoulderShape?: ModelSubscriptionStringInput | null,
  hairColor?: ModelSubscriptionStringInput | null,
  virginHairColor?: ModelSubscriptionStringInput | null,
  colorfulnessOfHair?: ModelSubscriptionStringInput | null,
  colorfulnessesOfHair?: ModelSubscriptionStringInput | null,
  recentDyeing?: ModelSubscriptionStringInput | null,
  recentFirm?: ModelSubscriptionStringInput | null,
  damageDegreeOfHair?: ModelSubscriptionStringInput | null,
  amountOfHair?: ModelSubscriptionStringInput | null,
  thicknessOfHair?: ModelSubscriptionStringInput | null,
  curlDegreeOfHair?: ModelSubscriptionStringInput | null,
  faceShape?: ModelSubscriptionStringInput | null,
  faceShapes?: ModelSubscriptionStringInput | null,
  frontHeadShape?: ModelSubscriptionStringInput | null,
  sideHeadShape?: ModelSubscriptionStringInput | null,
  solutionSentAt?: ModelSubscriptionStringInput | null,
  shopName?: ModelSubscriptionStringInput | null,
  designerName?: ModelSubscriptionStringInput | null,
  designerId?: ModelSubscriptionIDInput | null,
  hasPhotos?: ModelSubscriptionBooleanInput | null,
  modelVersion?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionConsultationFilterInput | null > | null,
  or?: Array< ModelSubscriptionConsultationFilterInput | null > | null,
};

export type ModelSubscriptionAnonymousPhotoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  bookingId?: ModelSubscriptionIDInput | null,
  consultationId?: ModelSubscriptionIDInput | null,
  requestId?: ModelSubscriptionIDInput | null,
  s3Key?: ModelSubscriptionStringInput | null,
  displayOrder?: ModelSubscriptionIntInput | null,
  category?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAnonymousPhotoFilterInput | null > | null,
  or?: Array< ModelSubscriptionAnonymousPhotoFilterInput | null > | null,
};

export type ModelSubscriptionPhotoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  bookingId?: ModelSubscriptionIDInput | null,
  s3Key?: ModelSubscriptionStringInput | null,
  displayOrder?: ModelSubscriptionIntInput | null,
  identityId?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  category?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPhotoFilterInput | null > | null,
  or?: Array< ModelSubscriptionPhotoFilterInput | null > | null,
};

export type ModelSubscriptionCheckInFilterInput = {
  bookingId?: ModelSubscriptionIDInput | null,
  shopId?: ModelSubscriptionIDInput | null,
  checkInRequests?: ModelSubscriptionStringInput | null,
  drink?: ModelSubscriptionStringInput | null,
  modelVersion?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionCheckInFilterInput | null > | null,
  or?: Array< ModelSubscriptionCheckInFilterInput | null > | null,
};

export type ModelSubscriptionBookingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  yearMonth?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  time?: ModelSubscriptionStringInput | null,
  customerId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  consultationId?: ModelSubscriptionIDInput | null,
  hasSelfDiagnosis?: ModelSubscriptionBooleanInput | null,
  hasRegularCustomerNote?: ModelSubscriptionBooleanInput | null,
  designerName?: ModelSubscriptionStringInput | null,
  designerId?: ModelSubscriptionIDInput | null,
  optionName?: ModelSubscriptionStringInput | null,
  shopId?: ModelSubscriptionIDInput | null,
  shopName?: ModelSubscriptionStringInput | null,
  naverBookingNumber?: ModelSubscriptionStringInput | null,
  bookingStatus?: ModelSubscriptionStringInput | null,
  serviceStatus?: ModelSubscriptionStringInput | null,
  paymentStatus?: ModelSubscriptionStringInput | null,
  requestMessage?: ModelSubscriptionStringInput | null,
  designerMemo?: ModelSubscriptionStringInput | null,
  initialPrice?: ModelSubscriptionIntInput | null,
  finalPrice?: ModelSubscriptionIntInput | null,
  totalServiceSales?: ModelSubscriptionIntInput | null,
  totalProductSales?: ModelSubscriptionIntInput | null,
  initialDeposit?: ModelSubscriptionIntInput | null,
  paidAmount?: ModelSubscriptionIntInput | null,
  paidInCashAmount?: ModelSubscriptionIntInput | null,
  paidByCardAmount?: ModelSubscriptionIntInput | null,
  paidByPrepaidPassAmount?: ModelSubscriptionIntInput | null,
  paidByNaverPayAmount?: ModelSubscriptionIntInput | null,
  paidAt?: ModelSubscriptionStringInput | null,
  paymentMemo?: ModelSubscriptionStringInput | null,
  checkInRequests?: ModelSubscriptionStringInput | null,
  drink?: ModelSubscriptionStringInput | null,
  checkedInAt?: ModelSubscriptionStringInput | null,
  origin?: ModelSubscriptionStringInput | null,
  signature?: ModelSubscriptionStringInput | null,
  signedAt?: ModelSubscriptionStringInput | null,
  recentNaverDataReflection?: ModelSubscriptionStringInput | null,
  selfDiagnosisSentAt?: ModelSubscriptionStringInput | null,
  feedbackRequestSentAt?: ModelSubscriptionStringInput | null,
  hasFeedback?: ModelSubscriptionBooleanInput | null,
  hasPhotos?: ModelSubscriptionBooleanInput | null,
  hasDesignCompletionPhotos?: ModelSubscriptionBooleanInput | null,
  signatureOfPortraitRights?: ModelSubscriptionStringInput | null,
  signedAtOfPortraitRights?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBookingFilterInput | null > | null,
  or?: Array< ModelSubscriptionBookingFilterInput | null > | null,
};

export type ModelSubscriptionCustomerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  lastFourPhoneNumbers?: ModelSubscriptionStringInput | null,
  firstVisitDate?: ModelSubscriptionStringInput | null,
  occupation?: ModelSubscriptionStringInput | null,
  drink?: ModelSubscriptionStringInput | null,
  dress?: ModelSubscriptionStringInput | null,
  trouble?: ModelSubscriptionStringInput | null,
  memo?: ModelSubscriptionStringInput | null,
  bookingCount?: ModelSubscriptionIntInput | null,
  consultationCount?: ModelSubscriptionIntInput | null,
  consultationDoneCount?: ModelSubscriptionIntInput | null,
  signature?: ModelSubscriptionStringInput | null,
  totalRevenue?: ModelSubscriptionIntInput | null,
  vehicleNumber?: ModelSubscriptionStringInput | null,
  shopId?: ModelSubscriptionIDInput | null,
  shopIds?: ModelSubscriptionStringInput | null,
  shopIdsString?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  prepaidPassBalance?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionCustomerFilterInput | null > | null,
  or?: Array< ModelSubscriptionCustomerFilterInput | null > | null,
};

export type ModelSubscriptionShopFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  naverBusinessId?: ModelSubscriptionStringInput | null,
  hasLocker?: ModelSubscriptionBooleanInput | null,
  bookingQRS3Key?: ModelSubscriptionStringInput | null,
  creatorId?: ModelSubscriptionIDInput | null,
  usePreSelfDiagnosis?: ModelSubscriptionBooleanInput | null,
  useFeedbackRequest?: ModelSubscriptionBooleanInput | null,
  ownerName?: ModelSubscriptionStringInput | null,
  ownerPhoneNumber?: ModelSubscriptionStringInput | null,
  internPhoneNumber?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionShopFilterInput | null > | null,
  or?: Array< ModelSubscriptionShopFilterInput | null > | null,
};

export type ModelSubscriptionShopServiceDrinkFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  shopId?: ModelSubscriptionIDInput | null,
  outOfStock?: ModelSubscriptionBooleanInput | null,
  displayOrder?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionShopServiceDrinkFilterInput | null > | null,
  or?: Array< ModelSubscriptionShopServiceDrinkFilterInput | null > | null,
};

export type ModelSubscriptionShopUserApplicationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  shopId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  completed?: ModelSubscriptionBooleanInput | null,
  name?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionShopUserApplicationFilterInput | null > | null,
  or?: Array< ModelSubscriptionShopUserApplicationFilterInput | null > | null,
};

export type ModelSubscriptionShopUserRemovalRequestFilterInput = {
  designerId?: ModelSubscriptionIDInput | null,
  shopId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  completed?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionShopUserRemovalRequestFilterInput | null > | null,
  or?: Array< ModelSubscriptionShopUserRemovalRequestFilterInput | null > | null,
};

export type ModelSubscriptionServiceOptionFilterInput = {
  shopId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionServiceOptionFilterInput | null > | null,
  or?: Array< ModelSubscriptionServiceOptionFilterInput | null > | null,
};

export type ModelSubscriptionPrepaidPassPresetFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  shopId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionIntInput | null,
  availableAmount?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionPrepaidPassPresetFilterInput | null > | null,
  or?: Array< ModelSubscriptionPrepaidPassPresetFilterInput | null > | null,
};

export type ModelSubscriptionPrepaidPassHistoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  customerId?: ModelSubscriptionIDInput | null,
  shopId?: ModelSubscriptionIDInput | null,
  amount?: ModelSubscriptionIntInput | null,
  bookingId?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  referenceId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionPrepaidPassHistoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionPrepaidPassHistoryFilterInput | null > | null,
};

export type ModelSubscriptionBrandFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  shopId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBrandFilterInput | null > | null,
  or?: Array< ModelSubscriptionBrandFilterInput | null > | null,
};

export type ModelSubscriptionProductFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  shopId?: ModelSubscriptionIDInput | null,
  brandId?: ModelSubscriptionIDInput | null,
  brandName?: ModelSubscriptionStringInput | null,
  costPrice?: ModelSubscriptionIntInput | null,
  sellingPrice?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionProductFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductFilterInput | null > | null,
};

export type ModelSubscriptionConsultationSolutionPresetFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  category?: ModelSubscriptionStringInput | null,
  sentence?: ModelSubscriptionStringInput | null,
  priority?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionConsultationSolutionPresetFilterInput | null > | null,
  or?: Array< ModelSubscriptionConsultationSolutionPresetFilterInput | null > | null,
};

export type ModelSubscriptionCoupangProductFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  src?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCoupangProductFilterInput | null > | null,
  or?: Array< ModelSubscriptionCoupangProductFilterInput | null > | null,
};

export type ModelSubscriptionGeneralProductFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  link?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  brandName?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  imageUrl?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGeneralProductFilterInput | null > | null,
  or?: Array< ModelSubscriptionGeneralProductFilterInput | null > | null,
};

export type ModelSubscriptionEventFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  hostName?: ModelSubscriptionStringInput | null,
  speakerName?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  venue?: ModelSubscriptionStringInput | null,
  link?: ModelSubscriptionStringInput | null,
  yearMonth?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  target?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEventFilterInput | null > | null,
  or?: Array< ModelSubscriptionEventFilterInput | null > | null,
};

export type ModelSubscriptionMutationResponseFilterInput = {
  success?: ModelSubscriptionBooleanInput | null,
  reason?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMutationResponseFilterInput | null > | null,
  or?: Array< ModelSubscriptionMutationResponseFilterInput | null > | null,
};

export type PayBookingMutationVariables = {
  id: string,
  input: PayBookingInput,
};

export type PayBookingMutation = {
  payBooking?:  {
    __typename: "MutationResponse",
    success: boolean,
    reason?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type InvokeNaverOpenAPIMutationVariables = {
  input: InvokeNaverOpenAPIInput,
};

export type InvokeNaverOpenAPIMutation = {
  invokeNaverOpenAPI?:  {
    __typename: "MutationResponse",
    success: boolean,
    reason?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDiscountPresetMutationVariables = {
  input: CreateDiscountPresetInput,
  condition?: ModelDiscountPresetConditionInput | null,
};

export type CreateDiscountPresetMutation = {
  createDiscountPreset?:  {
    __typename: "DiscountPreset",
    id: string,
    shopId: string,
    name: string,
    amount?: number | null,
    rate?: number | null,
    type: DiscountMode,
    enabled: boolean,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDiscountPresetMutationVariables = {
  input: UpdateDiscountPresetInput,
  condition?: ModelDiscountPresetConditionInput | null,
};

export type UpdateDiscountPresetMutation = {
  updateDiscountPreset?:  {
    __typename: "DiscountPreset",
    id: string,
    shopId: string,
    name: string,
    amount?: number | null,
    rate?: number | null,
    type: DiscountMode,
    enabled: boolean,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDiscountPresetMutationVariables = {
  input: DeleteDiscountPresetInput,
  condition?: ModelDiscountPresetConditionInput | null,
};

export type DeleteDiscountPresetMutation = {
  deleteDiscountPreset?:  {
    __typename: "DiscountPreset",
    id: string,
    shopId: string,
    name: string,
    amount?: number | null,
    rate?: number | null,
    type: DiscountMode,
    enabled: boolean,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDesignerMutationVariables = {
  input: CreateDesignerInput,
  condition?: ModelDesignerConditionInput | null,
};

export type CreateDesignerMutation = {
  createDesigner?:  {
    __typename: "Designer",
    id: string,
    shopId: string,
    name: string,
    naverName?: string | null,
    businessId?: string | null,
    bizItemId?: string | null,
    phoneNumber?: string | null,
    imageUrl?: string | null,
    s3Key?: string | null,
    userId?: string | null,
    youtubeUrl?: string | null,
    instagramUrl?: string | null,
    inactive?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDesignerMutationVariables = {
  input: UpdateDesignerInput,
  condition?: ModelDesignerConditionInput | null,
};

export type UpdateDesignerMutation = {
  updateDesigner?:  {
    __typename: "Designer",
    id: string,
    shopId: string,
    name: string,
    naverName?: string | null,
    businessId?: string | null,
    bizItemId?: string | null,
    phoneNumber?: string | null,
    imageUrl?: string | null,
    s3Key?: string | null,
    userId?: string | null,
    youtubeUrl?: string | null,
    instagramUrl?: string | null,
    inactive?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDesignerMutationVariables = {
  input: DeleteDesignerInput,
  condition?: ModelDesignerConditionInput | null,
};

export type DeleteDesignerMutation = {
  deleteDesigner?:  {
    __typename: "Designer",
    id: string,
    shopId: string,
    name: string,
    naverName?: string | null,
    businessId?: string | null,
    bizItemId?: string | null,
    phoneNumber?: string | null,
    imageUrl?: string | null,
    s3Key?: string | null,
    userId?: string | null,
    youtubeUrl?: string | null,
    instagramUrl?: string | null,
    inactive?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateFeedbackMutationVariables = {
  input: CreateFeedbackInput,
  condition?: ModelFeedbackConditionInput | null,
};

export type CreateFeedbackMutation = {
  createFeedback?:  {
    __typename: "Feedback",
    bookingId: string,
    category: FeedbackCategory,
    shopId: string,
    shopName: string,
    designerName: string,
    designerId: string,
    date: string,
    satisfaction: number,
    comment?: string | null,
    supportMessage?: string | null,
    modelVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFeedbackMutationVariables = {
  input: UpdateFeedbackInput,
  condition?: ModelFeedbackConditionInput | null,
};

export type UpdateFeedbackMutation = {
  updateFeedback?:  {
    __typename: "Feedback",
    bookingId: string,
    category: FeedbackCategory,
    shopId: string,
    shopName: string,
    designerName: string,
    designerId: string,
    date: string,
    satisfaction: number,
    comment?: string | null,
    supportMessage?: string | null,
    modelVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFeedbackMutationVariables = {
  input: DeleteFeedbackInput,
  condition?: ModelFeedbackConditionInput | null,
};

export type DeleteFeedbackMutation = {
  deleteFeedback?:  {
    __typename: "Feedback",
    bookingId: string,
    category: FeedbackCategory,
    shopId: string,
    shopName: string,
    designerName: string,
    designerId: string,
    date: string,
    satisfaction: number,
    comment?: string | null,
    supportMessage?: string | null,
    modelVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateRegularCustomerNoteMutationVariables = {
  input: CreateRegularCustomerNoteInput,
  condition?: ModelRegularCustomerNoteConditionInput | null,
};

export type CreateRegularCustomerNoteMutation = {
  createRegularCustomerNote?:  {
    __typename: "RegularCustomerNote",
    bookingId: string,
    shopId: string,
    shopName?: string | null,
    customerId: string,
    customerName: string,
    levelOfStyleChange: string,
    nextSchedule?: string | null,
    comment?: string | null,
    productsInterestedIn?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    modelVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateRegularCustomerNoteMutationVariables = {
  input: UpdateRegularCustomerNoteInput,
  condition?: ModelRegularCustomerNoteConditionInput | null,
};

export type UpdateRegularCustomerNoteMutation = {
  updateRegularCustomerNote?:  {
    __typename: "RegularCustomerNote",
    bookingId: string,
    shopId: string,
    shopName?: string | null,
    customerId: string,
    customerName: string,
    levelOfStyleChange: string,
    nextSchedule?: string | null,
    comment?: string | null,
    productsInterestedIn?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    modelVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteRegularCustomerNoteMutationVariables = {
  input: DeleteRegularCustomerNoteInput,
  condition?: ModelRegularCustomerNoteConditionInput | null,
};

export type DeleteRegularCustomerNoteMutation = {
  deleteRegularCustomerNote?:  {
    __typename: "RegularCustomerNote",
    bookingId: string,
    shopId: string,
    shopName?: string | null,
    customerId: string,
    customerName: string,
    levelOfStyleChange: string,
    nextSchedule?: string | null,
    comment?: string | null,
    productsInterestedIn?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    modelVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserPushTokenRequestMutationVariables = {
  input: CreateUserPushTokenRequestInput,
  condition?: ModelUserPushTokenRequestConditionInput | null,
};

export type CreateUserPushTokenRequestMutation = {
  createUserPushTokenRequest?:  {
    __typename: "UserPushTokenRequest",
    id: string,
    token: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserPushTokenRequestMutationVariables = {
  input: UpdateUserPushTokenRequestInput,
  condition?: ModelUserPushTokenRequestConditionInput | null,
};

export type UpdateUserPushTokenRequestMutation = {
  updateUserPushTokenRequest?:  {
    __typename: "UserPushTokenRequest",
    id: string,
    token: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserPushTokenRequestMutationVariables = {
  input: DeleteUserPushTokenRequestInput,
  condition?: ModelUserPushTokenRequestConditionInput | null,
};

export type DeleteUserPushTokenRequestMutation = {
  deleteUserPushTokenRequest?:  {
    __typename: "UserPushTokenRequest",
    id: string,
    token: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserPushTokenMutationVariables = {
  input: CreateUserPushTokenInput,
  condition?: ModelUserPushTokenConditionInput | null,
};

export type CreateUserPushTokenMutation = {
  createUserPushToken?:  {
    __typename: "UserPushToken",
    token: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserPushTokenMutationVariables = {
  input: UpdateUserPushTokenInput,
  condition?: ModelUserPushTokenConditionInput | null,
};

export type UpdateUserPushTokenMutation = {
  updateUserPushToken?:  {
    __typename: "UserPushToken",
    token: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserPushTokenMutationVariables = {
  input: DeleteUserPushTokenInput,
  condition?: ModelUserPushTokenConditionInput | null,
};

export type DeleteUserPushTokenMutation = {
  deleteUserPushToken?:  {
    __typename: "UserPushToken",
    token: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCustomerV2MutationVariables = {
  input: CreateCustomerV2Input,
  condition?: ModelCustomerV2ConditionInput | null,
};

export type CreateCustomerV2Mutation = {
  createCustomerV2?:  {
    __typename: "CustomerV2",
    id: string,
    owner?: string | null,
    name: string,
    phoneNumber: string,
    lastFourPhoneNumbers: string,
    firstVisitDate?: string | null,
    occupation?: string | null,
    drink?: string | null,
    visitCount?: number | null,
    selfCount?: number | null,
    regularCount?: number | null,
    caringCount?: number | null,
    comment?: string | null,
    modelVersion?: number | null,
    cut?: boolean | null,
    perm?: boolean | null,
    color?: boolean | null,
    clinic?: boolean | null,
    gender?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCustomerV2MutationVariables = {
  input: UpdateCustomerV2Input,
  condition?: ModelCustomerV2ConditionInput | null,
};

export type UpdateCustomerV2Mutation = {
  updateCustomerV2?:  {
    __typename: "CustomerV2",
    id: string,
    owner?: string | null,
    name: string,
    phoneNumber: string,
    lastFourPhoneNumbers: string,
    firstVisitDate?: string | null,
    occupation?: string | null,
    drink?: string | null,
    visitCount?: number | null,
    selfCount?: number | null,
    regularCount?: number | null,
    caringCount?: number | null,
    comment?: string | null,
    modelVersion?: number | null,
    cut?: boolean | null,
    perm?: boolean | null,
    color?: boolean | null,
    clinic?: boolean | null,
    gender?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCustomerV2MutationVariables = {
  input: DeleteCustomerV2Input,
  condition?: ModelCustomerV2ConditionInput | null,
};

export type DeleteCustomerV2Mutation = {
  deleteCustomerV2?:  {
    __typename: "CustomerV2",
    id: string,
    owner?: string | null,
    name: string,
    phoneNumber: string,
    lastFourPhoneNumbers: string,
    firstVisitDate?: string | null,
    occupation?: string | null,
    drink?: string | null,
    visitCount?: number | null,
    selfCount?: number | null,
    regularCount?: number | null,
    caringCount?: number | null,
    comment?: string | null,
    modelVersion?: number | null,
    cut?: boolean | null,
    perm?: boolean | null,
    color?: boolean | null,
    clinic?: boolean | null,
    gender?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCaringMutationVariables = {
  input: CreateCaringInput,
  condition?: ModelCaringConditionInput | null,
};

export type CreateCaringMutation = {
  createCaring?:  {
    __typename: "Caring",
    id: string,
    owner?: string | null,
    shopName: string,
    designerName: string,
    name: string,
    phoneNumber: string,
    yearMonth: string,
    date: string,
    sentAt?: string | null,
    timestamp: number,
    pointOfToday: string,
    aftercareTips?: string | null,
    stylingTips?: string | null,
    precautions?: string | null,
    serviceRecommendation?: string | null,
    nextVisitDate?: string | null,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCaringMutationVariables = {
  input: UpdateCaringInput,
  condition?: ModelCaringConditionInput | null,
};

export type UpdateCaringMutation = {
  updateCaring?:  {
    __typename: "Caring",
    id: string,
    owner?: string | null,
    shopName: string,
    designerName: string,
    name: string,
    phoneNumber: string,
    yearMonth: string,
    date: string,
    sentAt?: string | null,
    timestamp: number,
    pointOfToday: string,
    aftercareTips?: string | null,
    stylingTips?: string | null,
    precautions?: string | null,
    serviceRecommendation?: string | null,
    nextVisitDate?: string | null,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCaringMutationVariables = {
  input: DeleteCaringInput,
  condition?: ModelCaringConditionInput | null,
};

export type DeleteCaringMutation = {
  deleteCaring?:  {
    __typename: "Caring",
    id: string,
    owner?: string | null,
    shopName: string,
    designerName: string,
    name: string,
    phoneNumber: string,
    yearMonth: string,
    date: string,
    sentAt?: string | null,
    timestamp: number,
    pointOfToday: string,
    aftercareTips?: string | null,
    stylingTips?: string | null,
    precautions?: string | null,
    serviceRecommendation?: string | null,
    nextVisitDate?: string | null,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSelfDiagnosisRequestMutationVariables = {
  input: CreateSelfDiagnosisRequestInput,
  condition?: ModelSelfDiagnosisRequestConditionInput | null,
};

export type CreateSelfDiagnosisRequestMutation = {
  createSelfDiagnosisRequest?:  {
    __typename: "SelfDiagnosisRequest",
    id: string,
    owner?: string | null,
    shopName: string,
    designerName: string,
    name: string,
    phoneNumber: string,
    yearMonth: string,
    date?: string | null,
    sentAt?: string | null,
    writtenAt?: string | null,
    timestamp?: number | null,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSelfDiagnosisRequestMutationVariables = {
  input: UpdateSelfDiagnosisRequestInput,
  condition?: ModelSelfDiagnosisRequestConditionInput | null,
};

export type UpdateSelfDiagnosisRequestMutation = {
  updateSelfDiagnosisRequest?:  {
    __typename: "SelfDiagnosisRequest",
    id: string,
    owner?: string | null,
    shopName: string,
    designerName: string,
    name: string,
    phoneNumber: string,
    yearMonth: string,
    date?: string | null,
    sentAt?: string | null,
    writtenAt?: string | null,
    timestamp?: number | null,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSelfDiagnosisRequestMutationVariables = {
  input: DeleteSelfDiagnosisRequestInput,
  condition?: ModelSelfDiagnosisRequestConditionInput | null,
};

export type DeleteSelfDiagnosisRequestMutation = {
  deleteSelfDiagnosisRequest?:  {
    __typename: "SelfDiagnosisRequest",
    id: string,
    owner?: string | null,
    shopName: string,
    designerName: string,
    name: string,
    phoneNumber: string,
    yearMonth: string,
    date?: string | null,
    sentAt?: string | null,
    writtenAt?: string | null,
    timestamp?: number | null,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSelfDiagnosisContentMutationVariables = {
  input: CreateSelfDiagnosisContentInput,
  condition?: ModelSelfDiagnosisContentConditionInput | null,
};

export type CreateSelfDiagnosisContentMutation = {
  createSelfDiagnosisContent?:  {
    __typename: "SelfDiagnosisContent",
    requestId: string,
    shopName: string,
    designerName: string,
    name: string,
    yearMonth: string,
    date?: string | null,
    inflowChannel?: string | null,
    gender?: ConsultationGender | null,
    dry?: boolean | null,
    concerns?: Array< string > | null,
    frequencyOfVisits?: string | null,
    outfitStyles?: Array< string > | null,
    treatmentsInterestedIn?: Array< string > | null,
    productsForShampooing?: Array< string > | null,
    productsForStyling?: Array< string > | null,
    recentDyeing?: string | null,
    recentPerm?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    blackDyedHair?: boolean | null,
    bleachedHair?: boolean | null,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSelfDiagnosisContentMutationVariables = {
  input: UpdateSelfDiagnosisContentInput,
  condition?: ModelSelfDiagnosisContentConditionInput | null,
};

export type UpdateSelfDiagnosisContentMutation = {
  updateSelfDiagnosisContent?:  {
    __typename: "SelfDiagnosisContent",
    requestId: string,
    shopName: string,
    designerName: string,
    name: string,
    yearMonth: string,
    date?: string | null,
    inflowChannel?: string | null,
    gender?: ConsultationGender | null,
    dry?: boolean | null,
    concerns?: Array< string > | null,
    frequencyOfVisits?: string | null,
    outfitStyles?: Array< string > | null,
    treatmentsInterestedIn?: Array< string > | null,
    productsForShampooing?: Array< string > | null,
    productsForStyling?: Array< string > | null,
    recentDyeing?: string | null,
    recentPerm?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    blackDyedHair?: boolean | null,
    bleachedHair?: boolean | null,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSelfDiagnosisContentMutationVariables = {
  input: DeleteSelfDiagnosisContentInput,
  condition?: ModelSelfDiagnosisContentConditionInput | null,
};

export type DeleteSelfDiagnosisContentMutation = {
  deleteSelfDiagnosisContent?:  {
    __typename: "SelfDiagnosisContent",
    requestId: string,
    shopName: string,
    designerName: string,
    name: string,
    yearMonth: string,
    date?: string | null,
    inflowChannel?: string | null,
    gender?: ConsultationGender | null,
    dry?: boolean | null,
    concerns?: Array< string > | null,
    frequencyOfVisits?: string | null,
    outfitStyles?: Array< string > | null,
    treatmentsInterestedIn?: Array< string > | null,
    productsForShampooing?: Array< string > | null,
    productsForStyling?: Array< string > | null,
    recentDyeing?: string | null,
    recentPerm?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    blackDyedHair?: boolean | null,
    bleachedHair?: boolean | null,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateRegularCustomerQuestionnaireRequestMutationVariables = {
  input: CreateRegularCustomerQuestionnaireRequestInput,
  condition?: ModelRegularCustomerQuestionnaireRequestConditionInput | null,
};

export type CreateRegularCustomerQuestionnaireRequestMutation = {
  createRegularCustomerQuestionnaireRequest?:  {
    __typename: "RegularCustomerQuestionnaireRequest",
    id: string,
    owner?: string | null,
    shopName: string,
    designerName: string,
    name: string,
    phoneNumber: string,
    yearMonth: string,
    date: string,
    sentAt?: string | null,
    writtenAt?: string | null,
    timestamp: number,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateRegularCustomerQuestionnaireRequestMutationVariables = {
  input: UpdateRegularCustomerQuestionnaireRequestInput,
  condition?: ModelRegularCustomerQuestionnaireRequestConditionInput | null,
};

export type UpdateRegularCustomerQuestionnaireRequestMutation = {
  updateRegularCustomerQuestionnaireRequest?:  {
    __typename: "RegularCustomerQuestionnaireRequest",
    id: string,
    owner?: string | null,
    shopName: string,
    designerName: string,
    name: string,
    phoneNumber: string,
    yearMonth: string,
    date: string,
    sentAt?: string | null,
    writtenAt?: string | null,
    timestamp: number,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteRegularCustomerQuestionnaireRequestMutationVariables = {
  input: DeleteRegularCustomerQuestionnaireRequestInput,
  condition?: ModelRegularCustomerQuestionnaireRequestConditionInput | null,
};

export type DeleteRegularCustomerQuestionnaireRequestMutation = {
  deleteRegularCustomerQuestionnaireRequest?:  {
    __typename: "RegularCustomerQuestionnaireRequest",
    id: string,
    owner?: string | null,
    shopName: string,
    designerName: string,
    name: string,
    phoneNumber: string,
    yearMonth: string,
    date: string,
    sentAt?: string | null,
    writtenAt?: string | null,
    timestamp: number,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateRegularCustomerQuestionnaireContentMutationVariables = {
  input: CreateRegularCustomerQuestionnaireContentInput,
  condition?: ModelRegularCustomerQuestionnaireContentConditionInput | null,
};

export type CreateRegularCustomerQuestionnaireContentMutation = {
  createRegularCustomerQuestionnaireContent?:  {
    __typename: "RegularCustomerQuestionnaireContent",
    requestId: string,
    shopName: string,
    designerName: string,
    name: string,
    yearMonth: string,
    date: string,
    levelOfStyleChange: string,
    nextSchedule?: string | null,
    comment?: string | null,
    productsInterestedIn?: string | null,
    treatmentsInterestedIn?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    timestamp: number,
    modelVersion: number,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateRegularCustomerQuestionnaireContentMutationVariables = {
  input: UpdateRegularCustomerQuestionnaireContentInput,
  condition?: ModelRegularCustomerQuestionnaireContentConditionInput | null,
};

export type UpdateRegularCustomerQuestionnaireContentMutation = {
  updateRegularCustomerQuestionnaireContent?:  {
    __typename: "RegularCustomerQuestionnaireContent",
    requestId: string,
    shopName: string,
    designerName: string,
    name: string,
    yearMonth: string,
    date: string,
    levelOfStyleChange: string,
    nextSchedule?: string | null,
    comment?: string | null,
    productsInterestedIn?: string | null,
    treatmentsInterestedIn?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    timestamp: number,
    modelVersion: number,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteRegularCustomerQuestionnaireContentMutationVariables = {
  input: DeleteRegularCustomerQuestionnaireContentInput,
  condition?: ModelRegularCustomerQuestionnaireContentConditionInput | null,
};

export type DeleteRegularCustomerQuestionnaireContentMutation = {
  deleteRegularCustomerQuestionnaireContent?:  {
    __typename: "RegularCustomerQuestionnaireContent",
    requestId: string,
    shopName: string,
    designerName: string,
    name: string,
    yearMonth: string,
    date: string,
    levelOfStyleChange: string,
    nextSchedule?: string | null,
    comment?: string | null,
    productsInterestedIn?: string | null,
    treatmentsInterestedIn?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    timestamp: number,
    modelVersion: number,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserActivitySnapshotMutationVariables = {
  input: CreateUserActivitySnapshotInput,
  condition?: ModelUserActivitySnapshotConditionInput | null,
};

export type CreateUserActivitySnapshotMutation = {
  createUserActivitySnapshot?:  {
    __typename: "UserActivitySnapshot",
    id: string,
    owner?: string | null,
    dailyLogCount?: number | null,
    selfDiagnosisCount?: number | null,
    regularCustomerQuestionnaireCount?: number | null,
    caringCount?: number | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserActivitySnapshotMutationVariables = {
  input: UpdateUserActivitySnapshotInput,
  condition?: ModelUserActivitySnapshotConditionInput | null,
};

export type UpdateUserActivitySnapshotMutation = {
  updateUserActivitySnapshot?:  {
    __typename: "UserActivitySnapshot",
    id: string,
    owner?: string | null,
    dailyLogCount?: number | null,
    selfDiagnosisCount?: number | null,
    regularCustomerQuestionnaireCount?: number | null,
    caringCount?: number | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserActivitySnapshotMutationVariables = {
  input: DeleteUserActivitySnapshotInput,
  condition?: ModelUserActivitySnapshotConditionInput | null,
};

export type DeleteUserActivitySnapshotMutation = {
  deleteUserActivitySnapshot?:  {
    __typename: "UserActivitySnapshot",
    id: string,
    owner?: string | null,
    dailyLogCount?: number | null,
    selfDiagnosisCount?: number | null,
    regularCustomerQuestionnaireCount?: number | null,
    caringCount?: number | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDailyLogMutationVariables = {
  input: CreateDailyLogInput,
  condition?: ModelDailyLogConditionInput | null,
};

export type CreateDailyLogMutation = {
  createDailyLog?:  {
    __typename: "DailyLog",
    id: string,
    owner?: string | null,
    shopName?: string | null,
    designerName?: string | null,
    yearMonth: string,
    date: string,
    timestamp: number,
    averageConsultationMinutes?: number | null,
    serviceRecommendationCount?: number | null,
    productRecommendationCount?: number | null,
    prepaidTicketRecommendationCount?: number | null,
    stretch?: boolean | null,
    drinkingWater?: boolean | null,
    comment?: string | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDailyLogMutationVariables = {
  input: UpdateDailyLogInput,
  condition?: ModelDailyLogConditionInput | null,
};

export type UpdateDailyLogMutation = {
  updateDailyLog?:  {
    __typename: "DailyLog",
    id: string,
    owner?: string | null,
    shopName?: string | null,
    designerName?: string | null,
    yearMonth: string,
    date: string,
    timestamp: number,
    averageConsultationMinutes?: number | null,
    serviceRecommendationCount?: number | null,
    productRecommendationCount?: number | null,
    prepaidTicketRecommendationCount?: number | null,
    stretch?: boolean | null,
    drinkingWater?: boolean | null,
    comment?: string | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDailyLogMutationVariables = {
  input: DeleteDailyLogInput,
  condition?: ModelDailyLogConditionInput | null,
};

export type DeleteDailyLogMutation = {
  deleteDailyLog?:  {
    __typename: "DailyLog",
    id: string,
    owner?: string | null,
    shopName?: string | null,
    designerName?: string | null,
    yearMonth: string,
    date: string,
    timestamp: number,
    averageConsultationMinutes?: number | null,
    serviceRecommendationCount?: number | null,
    productRecommendationCount?: number | null,
    prepaidTicketRecommendationCount?: number | null,
    stretch?: boolean | null,
    drinkingWater?: boolean | null,
    comment?: string | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSelfDiagnosisMutationVariables = {
  input: CreateSelfDiagnosisInput,
  condition?: ModelSelfDiagnosisConditionInput | null,
};

export type CreateSelfDiagnosisMutation = {
  createSelfDiagnosis?:  {
    __typename: "SelfDiagnosis",
    bookingId: string,
    customerId: string,
    customerName: string,
    yearMonth: string,
    date: string,
    shopId: string,
    shopName: string,
    designerId: string,
    designerName: string,
    inflowChannel?: string | null,
    gender?: ConsultationGender | null,
    dry?: boolean | null,
    concerns?: Array< string > | null,
    frequencyOfVisits?: string | null,
    outfitStyles?: Array< string > | null,
    treatmentsInterestedIn?: Array< string > | null,
    productsForShampooing?: Array< string > | null,
    productsForStyling?: Array< string > | null,
    recentDyeing?: string | null,
    recentPerm?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    blackDyedHair?: boolean | null,
    bleachedHair?: boolean | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSelfDiagnosisMutationVariables = {
  input: UpdateSelfDiagnosisInput,
  condition?: ModelSelfDiagnosisConditionInput | null,
};

export type UpdateSelfDiagnosisMutation = {
  updateSelfDiagnosis?:  {
    __typename: "SelfDiagnosis",
    bookingId: string,
    customerId: string,
    customerName: string,
    yearMonth: string,
    date: string,
    shopId: string,
    shopName: string,
    designerId: string,
    designerName: string,
    inflowChannel?: string | null,
    gender?: ConsultationGender | null,
    dry?: boolean | null,
    concerns?: Array< string > | null,
    frequencyOfVisits?: string | null,
    outfitStyles?: Array< string > | null,
    treatmentsInterestedIn?: Array< string > | null,
    productsForShampooing?: Array< string > | null,
    productsForStyling?: Array< string > | null,
    recentDyeing?: string | null,
    recentPerm?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    blackDyedHair?: boolean | null,
    bleachedHair?: boolean | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSelfDiagnosisMutationVariables = {
  input: DeleteSelfDiagnosisInput,
  condition?: ModelSelfDiagnosisConditionInput | null,
};

export type DeleteSelfDiagnosisMutation = {
  deleteSelfDiagnosis?:  {
    __typename: "SelfDiagnosis",
    bookingId: string,
    customerId: string,
    customerName: string,
    yearMonth: string,
    date: string,
    shopId: string,
    shopName: string,
    designerId: string,
    designerName: string,
    inflowChannel?: string | null,
    gender?: ConsultationGender | null,
    dry?: boolean | null,
    concerns?: Array< string > | null,
    frequencyOfVisits?: string | null,
    outfitStyles?: Array< string > | null,
    treatmentsInterestedIn?: Array< string > | null,
    productsForShampooing?: Array< string > | null,
    productsForStyling?: Array< string > | null,
    recentDyeing?: string | null,
    recentPerm?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    blackDyedHair?: boolean | null,
    bleachedHair?: boolean | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateConsultationMutationVariables = {
  input: CreateConsultationInput,
  condition?: ModelConsultationConditionInput | null,
};

export type CreateConsultationMutation = {
  createConsultation?:  {
    __typename: "Consultation",
    id: string,
    bookingId?: string | null,
    customer:  {
      __typename: "NestedCustomer",
      phoneNumber?: string | null,
      name?: string | null,
      id?: string | null,
      shopIds?: Array< string > | null,
    },
    customerId: string,
    name?: string | null,
    yearMonth?: string | null,
    date?: string | null,
    shopId: string,
    status: ConsultationStatus,
    selfDiagnosisStatus: ConsultationSelfDiagnosisStatus,
    mode?: ConsultationMode | null,
    inflowChannel?: string | null,
    gender?: ConsultationGender | null,
    dry?: boolean | null,
    concerns?: Array< string > | null,
    frequencyOfVisits?: string | null,
    outfitStyles?: Array< string > | null,
    treatmentsInterestedIn?: Array< string > | null,
    productsForShampooing?: Array< string > | null,
    productsForStyling?: Array< string > | null,
    neckLength?: string | null,
    neckThickness?: string | null,
    shoulderWidth?: string | null,
    shoulderShape?: string | null,
    hairColor?: string | null,
    virginHairColor?: string | null,
    colorfulnessOfHair?: string | null,
    colorfulnessesOfHair?: Array< string > | null,
    recentDyeing?: string | null,
    recentFirm?: string | null,
    damageDegreeOfHair?: string | null,
    amountOfHair?: string | null,
    thicknessOfHair?: string | null,
    curlDegreeOfHair?: string | null,
    faceShape?: string | null,
    faceShapes?: Array< string > | null,
    frontHeadShape?: string | null,
    sideHeadShape?: string | null,
    solution?:  {
      __typename: "Solution",
      face?: string | null,
      hair?: string | null,
      needs?: string | null,
      point?: string | null,
      aftercareTips?: string | null,
      stylingTips?: string | null,
      precautions?: string | null,
      recommendedTreatment?: string | null,
      nextVisitDate?: string | null,
      link?: string | null,
    } | null,
    solutionSentAt?: string | null,
    hairRecommendation?:  {
      __typename: "HairRecommendation",
      bangs?: string | null,
      hairLength?: string | null,
      hairVolume?: string | null,
      hairVolumes?: Array< string > | null,
      haircut?: string | null,
      haircuts?: Array< string > | null,
      hairColorMenu?: string | null,
      hairColorType?: string | null,
      hairFirm?: string | null,
      hairCare?: string | null,
    } | null,
    products?:  Array< {
      __typename: "SolutionProduct",
      title: string,
      url: string,
      imageFilePath: string,
      description?: string | null,
    } > | null,
    shopName?: string | null,
    designerName?: string | null,
    designerId?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateConsultationMutationVariables = {
  input: UpdateConsultationInput,
  condition?: ModelConsultationConditionInput | null,
};

export type UpdateConsultationMutation = {
  updateConsultation?:  {
    __typename: "Consultation",
    id: string,
    bookingId?: string | null,
    customer:  {
      __typename: "NestedCustomer",
      phoneNumber?: string | null,
      name?: string | null,
      id?: string | null,
      shopIds?: Array< string > | null,
    },
    customerId: string,
    name?: string | null,
    yearMonth?: string | null,
    date?: string | null,
    shopId: string,
    status: ConsultationStatus,
    selfDiagnosisStatus: ConsultationSelfDiagnosisStatus,
    mode?: ConsultationMode | null,
    inflowChannel?: string | null,
    gender?: ConsultationGender | null,
    dry?: boolean | null,
    concerns?: Array< string > | null,
    frequencyOfVisits?: string | null,
    outfitStyles?: Array< string > | null,
    treatmentsInterestedIn?: Array< string > | null,
    productsForShampooing?: Array< string > | null,
    productsForStyling?: Array< string > | null,
    neckLength?: string | null,
    neckThickness?: string | null,
    shoulderWidth?: string | null,
    shoulderShape?: string | null,
    hairColor?: string | null,
    virginHairColor?: string | null,
    colorfulnessOfHair?: string | null,
    colorfulnessesOfHair?: Array< string > | null,
    recentDyeing?: string | null,
    recentFirm?: string | null,
    damageDegreeOfHair?: string | null,
    amountOfHair?: string | null,
    thicknessOfHair?: string | null,
    curlDegreeOfHair?: string | null,
    faceShape?: string | null,
    faceShapes?: Array< string > | null,
    frontHeadShape?: string | null,
    sideHeadShape?: string | null,
    solution?:  {
      __typename: "Solution",
      face?: string | null,
      hair?: string | null,
      needs?: string | null,
      point?: string | null,
      aftercareTips?: string | null,
      stylingTips?: string | null,
      precautions?: string | null,
      recommendedTreatment?: string | null,
      nextVisitDate?: string | null,
      link?: string | null,
    } | null,
    solutionSentAt?: string | null,
    hairRecommendation?:  {
      __typename: "HairRecommendation",
      bangs?: string | null,
      hairLength?: string | null,
      hairVolume?: string | null,
      hairVolumes?: Array< string > | null,
      haircut?: string | null,
      haircuts?: Array< string > | null,
      hairColorMenu?: string | null,
      hairColorType?: string | null,
      hairFirm?: string | null,
      hairCare?: string | null,
    } | null,
    products?:  Array< {
      __typename: "SolutionProduct",
      title: string,
      url: string,
      imageFilePath: string,
      description?: string | null,
    } > | null,
    shopName?: string | null,
    designerName?: string | null,
    designerId?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteConsultationMutationVariables = {
  input: DeleteConsultationInput,
  condition?: ModelConsultationConditionInput | null,
};

export type DeleteConsultationMutation = {
  deleteConsultation?:  {
    __typename: "Consultation",
    id: string,
    bookingId?: string | null,
    customer:  {
      __typename: "NestedCustomer",
      phoneNumber?: string | null,
      name?: string | null,
      id?: string | null,
      shopIds?: Array< string > | null,
    },
    customerId: string,
    name?: string | null,
    yearMonth?: string | null,
    date?: string | null,
    shopId: string,
    status: ConsultationStatus,
    selfDiagnosisStatus: ConsultationSelfDiagnosisStatus,
    mode?: ConsultationMode | null,
    inflowChannel?: string | null,
    gender?: ConsultationGender | null,
    dry?: boolean | null,
    concerns?: Array< string > | null,
    frequencyOfVisits?: string | null,
    outfitStyles?: Array< string > | null,
    treatmentsInterestedIn?: Array< string > | null,
    productsForShampooing?: Array< string > | null,
    productsForStyling?: Array< string > | null,
    neckLength?: string | null,
    neckThickness?: string | null,
    shoulderWidth?: string | null,
    shoulderShape?: string | null,
    hairColor?: string | null,
    virginHairColor?: string | null,
    colorfulnessOfHair?: string | null,
    colorfulnessesOfHair?: Array< string > | null,
    recentDyeing?: string | null,
    recentFirm?: string | null,
    damageDegreeOfHair?: string | null,
    amountOfHair?: string | null,
    thicknessOfHair?: string | null,
    curlDegreeOfHair?: string | null,
    faceShape?: string | null,
    faceShapes?: Array< string > | null,
    frontHeadShape?: string | null,
    sideHeadShape?: string | null,
    solution?:  {
      __typename: "Solution",
      face?: string | null,
      hair?: string | null,
      needs?: string | null,
      point?: string | null,
      aftercareTips?: string | null,
      stylingTips?: string | null,
      precautions?: string | null,
      recommendedTreatment?: string | null,
      nextVisitDate?: string | null,
      link?: string | null,
    } | null,
    solutionSentAt?: string | null,
    hairRecommendation?:  {
      __typename: "HairRecommendation",
      bangs?: string | null,
      hairLength?: string | null,
      hairVolume?: string | null,
      hairVolumes?: Array< string > | null,
      haircut?: string | null,
      haircuts?: Array< string > | null,
      hairColorMenu?: string | null,
      hairColorType?: string | null,
      hairFirm?: string | null,
      hairCare?: string | null,
    } | null,
    products?:  Array< {
      __typename: "SolutionProduct",
      title: string,
      url: string,
      imageFilePath: string,
      description?: string | null,
    } > | null,
    shopName?: string | null,
    designerName?: string | null,
    designerId?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAnonymousPhotoMutationVariables = {
  input: CreateAnonymousPhotoInput,
  condition?: ModelAnonymousPhotoConditionInput | null,
};

export type CreateAnonymousPhotoMutation = {
  createAnonymousPhoto?:  {
    __typename: "AnonymousPhoto",
    id: string,
    bookingId?: string | null,
    consultationId?: string | null,
    requestId?: string | null,
    s3Key: string,
    displayOrder: number,
    category: AnonymousPhotoCategory,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAnonymousPhotoMutationVariables = {
  input: UpdateAnonymousPhotoInput,
  condition?: ModelAnonymousPhotoConditionInput | null,
};

export type UpdateAnonymousPhotoMutation = {
  updateAnonymousPhoto?:  {
    __typename: "AnonymousPhoto",
    id: string,
    bookingId?: string | null,
    consultationId?: string | null,
    requestId?: string | null,
    s3Key: string,
    displayOrder: number,
    category: AnonymousPhotoCategory,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAnonymousPhotoMutationVariables = {
  input: DeleteAnonymousPhotoInput,
  condition?: ModelAnonymousPhotoConditionInput | null,
};

export type DeleteAnonymousPhotoMutation = {
  deleteAnonymousPhoto?:  {
    __typename: "AnonymousPhoto",
    id: string,
    bookingId?: string | null,
    consultationId?: string | null,
    requestId?: string | null,
    s3Key: string,
    displayOrder: number,
    category: AnonymousPhotoCategory,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePhotoMutationVariables = {
  input: CreatePhotoInput,
  condition?: ModelPhotoConditionInput | null,
};

export type CreatePhotoMutation = {
  createPhoto?:  {
    __typename: "Photo",
    id: string,
    bookingId?: string | null,
    s3Key: string,
    displayOrder: number,
    identityId: string,
    description?: string | null,
    category: PhotoCategory,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePhotoMutationVariables = {
  input: UpdatePhotoInput,
  condition?: ModelPhotoConditionInput | null,
};

export type UpdatePhotoMutation = {
  updatePhoto?:  {
    __typename: "Photo",
    id: string,
    bookingId?: string | null,
    s3Key: string,
    displayOrder: number,
    identityId: string,
    description?: string | null,
    category: PhotoCategory,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePhotoMutationVariables = {
  input: DeletePhotoInput,
  condition?: ModelPhotoConditionInput | null,
};

export type DeletePhotoMutation = {
  deletePhoto?:  {
    __typename: "Photo",
    id: string,
    bookingId?: string | null,
    s3Key: string,
    displayOrder: number,
    identityId: string,
    description?: string | null,
    category: PhotoCategory,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCheckInMutationVariables = {
  input: CreateCheckInInput,
  condition?: ModelCheckInConditionInput | null,
};

export type CreateCheckInMutation = {
  createCheckIn?:  {
    __typename: "CheckIn",
    bookingId: string,
    shopId: string,
    checkInRequests?: Array< string > | null,
    drink?: string | null,
    modelVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCheckInMutationVariables = {
  input: UpdateCheckInInput,
  condition?: ModelCheckInConditionInput | null,
};

export type UpdateCheckInMutation = {
  updateCheckIn?:  {
    __typename: "CheckIn",
    bookingId: string,
    shopId: string,
    checkInRequests?: Array< string > | null,
    drink?: string | null,
    modelVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCheckInMutationVariables = {
  input: DeleteCheckInInput,
  condition?: ModelCheckInConditionInput | null,
};

export type DeleteCheckInMutation = {
  deleteCheckIn?:  {
    __typename: "CheckIn",
    bookingId: string,
    shopId: string,
    checkInRequests?: Array< string > | null,
    drink?: string | null,
    modelVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateBookingMutationVariables = {
  input: CreateBookingInput,
  condition?: ModelBookingConditionInput | null,
};

export type CreateBookingMutation = {
  createBooking?:  {
    __typename: "Booking",
    id: string,
    yearMonth?: string | null,
    date: string,
    time: string,
    customer?:  {
      __typename: "NestedCustomer",
      phoneNumber?: string | null,
      name?: string | null,
      id?: string | null,
      shopIds?: Array< string > | null,
    } | null,
    customerId: string,
    name?: string | null,
    phoneNumber?: string | null,
    consultationId?: string | null,
    consultation?:  {
      __typename: "NestedConsultation",
      status?: ConsultationStatus | null,
      selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
      solutionSentAt?: string | null,
      inflowChannel?: string | null,
      hasPhotos?: boolean | null,
    } | null,
    hasSelfDiagnosis?: boolean | null,
    hasRegularCustomerNote?: boolean | null,
    designerName?: string | null,
    designerId?: string | null,
    optionName?: string | null,
    shopId: string,
    shopName?: string | null,
    naverBookingNumber?: string | null,
    bookingStatus?: BookingStatus | null,
    serviceStatus?: ServiceStatus | null,
    paymentStatus?: PaymentStatus | null,
    requestMessage?: string | null,
    designerMemo?: string | null,
    naverSnapshot?:  {
      __typename: "NaverSnapshot",
      price: number,
      bizItemPrice: number,
      onsitePrice?: number | null,
      email?: string | null,
      isOnsitePayment?: boolean | null,
    } | null,
    naverOptions?:  Array< {
      __typename: "NaverServiceOption",
      categoryName: string,
      name: string,
      originalName?: string | null,
      desc?: string | null,
      price?: number | null,
    } > | null,
    naverPayments?:  Array< {
      __typename: "NaverPayment",
      method?: NaverPaymentMethod | null,
      moment?: NaverPaymentMoment | null,
      provider?: NaverPaymentProvider | null,
      paymentId: number,
      bookingId: number,
      amount: number,
      providerDiscountAmount?: number | null,
      regDateTime?: string | null,
      status: NaverPaymentStatus,
      statusHistories?:  Array< {
        __typename: "NaverPaymentStatusHistory",
        status: NaverPaymentStatus,
        dateTime?: string | null,
      } > | null,
    } > | null,
    initialPrice?: number | null,
    finalPrice?: number | null,
    totalServiceSales?: number | null,
    totalProductSales?: number | null,
    initialDeposit?: number | null,
    paidAmount?: number | null,
    paidInCashAmount?: number | null,
    paidByCardAmount?: number | null,
    paidByPrepaidPassAmount?: number | null,
    paidByNaverPayAmount?: number | null,
    paidAt?: string | null,
    paymentMemo?: string | null,
    checkInRequests?: Array< string > | null,
    drink?: string | null,
    checkedInAt?: string | null,
    origin?: BookingOrigin | null,
    options?:  Array< {
      __typename: "BookingServiceOption",
      categoryName: string,
      name: string,
      originalPrice: number,
      discount?: number | null,
      discountRate?: number | null,
      price: number,
      discountPreset?:  {
        __typename: "DiscountPresetSnapshot",
        id?: string | null,
        shopId?: string | null,
        name?: string | null,
        amount?: number | null,
        rate?: number | null,
        type?: DiscountMode | null,
      } | null,
    } > | null,
    productOptions?:  Array< {
      __typename: "BookingProductOption",
      name: string,
      price: number,
      quantity: number,
      product?:  {
        __typename: "ProductSnapshot",
        id?: string | null,
        name?: string | null,
        shopId?: string | null,
        brandId?: string | null,
        brandName?: string | null,
        costPrice?: number | null,
        sellingPrice?: number | null,
      } | null,
    } > | null,
    signature?: string | null,
    signedAt?: string | null,
    recentNaverDataReflection?: string | null,
    selfDiagnosisSentAt?: string | null,
    feedbackRequestSentAt?: string | null,
    hasFeedback?: boolean | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    hasDesignCompletionPhotos?: boolean | null,
    designCompletionPhotos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    signatureOfPortraitRights?: string | null,
    signedAtOfPortraitRights?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateBookingMutationVariables = {
  input: UpdateBookingInput,
  condition?: ModelBookingConditionInput | null,
};

export type UpdateBookingMutation = {
  updateBooking?:  {
    __typename: "Booking",
    id: string,
    yearMonth?: string | null,
    date: string,
    time: string,
    customer?:  {
      __typename: "NestedCustomer",
      phoneNumber?: string | null,
      name?: string | null,
      id?: string | null,
      shopIds?: Array< string > | null,
    } | null,
    customerId: string,
    name?: string | null,
    phoneNumber?: string | null,
    consultationId?: string | null,
    consultation?:  {
      __typename: "NestedConsultation",
      status?: ConsultationStatus | null,
      selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
      solutionSentAt?: string | null,
      inflowChannel?: string | null,
      hasPhotos?: boolean | null,
    } | null,
    hasSelfDiagnosis?: boolean | null,
    hasRegularCustomerNote?: boolean | null,
    designerName?: string | null,
    designerId?: string | null,
    optionName?: string | null,
    shopId: string,
    shopName?: string | null,
    naverBookingNumber?: string | null,
    bookingStatus?: BookingStatus | null,
    serviceStatus?: ServiceStatus | null,
    paymentStatus?: PaymentStatus | null,
    requestMessage?: string | null,
    designerMemo?: string | null,
    naverSnapshot?:  {
      __typename: "NaverSnapshot",
      price: number,
      bizItemPrice: number,
      onsitePrice?: number | null,
      email?: string | null,
      isOnsitePayment?: boolean | null,
    } | null,
    naverOptions?:  Array< {
      __typename: "NaverServiceOption",
      categoryName: string,
      name: string,
      originalName?: string | null,
      desc?: string | null,
      price?: number | null,
    } > | null,
    naverPayments?:  Array< {
      __typename: "NaverPayment",
      method?: NaverPaymentMethod | null,
      moment?: NaverPaymentMoment | null,
      provider?: NaverPaymentProvider | null,
      paymentId: number,
      bookingId: number,
      amount: number,
      providerDiscountAmount?: number | null,
      regDateTime?: string | null,
      status: NaverPaymentStatus,
      statusHistories?:  Array< {
        __typename: "NaverPaymentStatusHistory",
        status: NaverPaymentStatus,
        dateTime?: string | null,
      } > | null,
    } > | null,
    initialPrice?: number | null,
    finalPrice?: number | null,
    totalServiceSales?: number | null,
    totalProductSales?: number | null,
    initialDeposit?: number | null,
    paidAmount?: number | null,
    paidInCashAmount?: number | null,
    paidByCardAmount?: number | null,
    paidByPrepaidPassAmount?: number | null,
    paidByNaverPayAmount?: number | null,
    paidAt?: string | null,
    paymentMemo?: string | null,
    checkInRequests?: Array< string > | null,
    drink?: string | null,
    checkedInAt?: string | null,
    origin?: BookingOrigin | null,
    options?:  Array< {
      __typename: "BookingServiceOption",
      categoryName: string,
      name: string,
      originalPrice: number,
      discount?: number | null,
      discountRate?: number | null,
      price: number,
      discountPreset?:  {
        __typename: "DiscountPresetSnapshot",
        id?: string | null,
        shopId?: string | null,
        name?: string | null,
        amount?: number | null,
        rate?: number | null,
        type?: DiscountMode | null,
      } | null,
    } > | null,
    productOptions?:  Array< {
      __typename: "BookingProductOption",
      name: string,
      price: number,
      quantity: number,
      product?:  {
        __typename: "ProductSnapshot",
        id?: string | null,
        name?: string | null,
        shopId?: string | null,
        brandId?: string | null,
        brandName?: string | null,
        costPrice?: number | null,
        sellingPrice?: number | null,
      } | null,
    } > | null,
    signature?: string | null,
    signedAt?: string | null,
    recentNaverDataReflection?: string | null,
    selfDiagnosisSentAt?: string | null,
    feedbackRequestSentAt?: string | null,
    hasFeedback?: boolean | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    hasDesignCompletionPhotos?: boolean | null,
    designCompletionPhotos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    signatureOfPortraitRights?: string | null,
    signedAtOfPortraitRights?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteBookingMutationVariables = {
  input: DeleteBookingInput,
  condition?: ModelBookingConditionInput | null,
};

export type DeleteBookingMutation = {
  deleteBooking?:  {
    __typename: "Booking",
    id: string,
    yearMonth?: string | null,
    date: string,
    time: string,
    customer?:  {
      __typename: "NestedCustomer",
      phoneNumber?: string | null,
      name?: string | null,
      id?: string | null,
      shopIds?: Array< string > | null,
    } | null,
    customerId: string,
    name?: string | null,
    phoneNumber?: string | null,
    consultationId?: string | null,
    consultation?:  {
      __typename: "NestedConsultation",
      status?: ConsultationStatus | null,
      selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
      solutionSentAt?: string | null,
      inflowChannel?: string | null,
      hasPhotos?: boolean | null,
    } | null,
    hasSelfDiagnosis?: boolean | null,
    hasRegularCustomerNote?: boolean | null,
    designerName?: string | null,
    designerId?: string | null,
    optionName?: string | null,
    shopId: string,
    shopName?: string | null,
    naverBookingNumber?: string | null,
    bookingStatus?: BookingStatus | null,
    serviceStatus?: ServiceStatus | null,
    paymentStatus?: PaymentStatus | null,
    requestMessage?: string | null,
    designerMemo?: string | null,
    naverSnapshot?:  {
      __typename: "NaverSnapshot",
      price: number,
      bizItemPrice: number,
      onsitePrice?: number | null,
      email?: string | null,
      isOnsitePayment?: boolean | null,
    } | null,
    naverOptions?:  Array< {
      __typename: "NaverServiceOption",
      categoryName: string,
      name: string,
      originalName?: string | null,
      desc?: string | null,
      price?: number | null,
    } > | null,
    naverPayments?:  Array< {
      __typename: "NaverPayment",
      method?: NaverPaymentMethod | null,
      moment?: NaverPaymentMoment | null,
      provider?: NaverPaymentProvider | null,
      paymentId: number,
      bookingId: number,
      amount: number,
      providerDiscountAmount?: number | null,
      regDateTime?: string | null,
      status: NaverPaymentStatus,
      statusHistories?:  Array< {
        __typename: "NaverPaymentStatusHistory",
        status: NaverPaymentStatus,
        dateTime?: string | null,
      } > | null,
    } > | null,
    initialPrice?: number | null,
    finalPrice?: number | null,
    totalServiceSales?: number | null,
    totalProductSales?: number | null,
    initialDeposit?: number | null,
    paidAmount?: number | null,
    paidInCashAmount?: number | null,
    paidByCardAmount?: number | null,
    paidByPrepaidPassAmount?: number | null,
    paidByNaverPayAmount?: number | null,
    paidAt?: string | null,
    paymentMemo?: string | null,
    checkInRequests?: Array< string > | null,
    drink?: string | null,
    checkedInAt?: string | null,
    origin?: BookingOrigin | null,
    options?:  Array< {
      __typename: "BookingServiceOption",
      categoryName: string,
      name: string,
      originalPrice: number,
      discount?: number | null,
      discountRate?: number | null,
      price: number,
      discountPreset?:  {
        __typename: "DiscountPresetSnapshot",
        id?: string | null,
        shopId?: string | null,
        name?: string | null,
        amount?: number | null,
        rate?: number | null,
        type?: DiscountMode | null,
      } | null,
    } > | null,
    productOptions?:  Array< {
      __typename: "BookingProductOption",
      name: string,
      price: number,
      quantity: number,
      product?:  {
        __typename: "ProductSnapshot",
        id?: string | null,
        name?: string | null,
        shopId?: string | null,
        brandId?: string | null,
        brandName?: string | null,
        costPrice?: number | null,
        sellingPrice?: number | null,
      } | null,
    } > | null,
    signature?: string | null,
    signedAt?: string | null,
    recentNaverDataReflection?: string | null,
    selfDiagnosisSentAt?: string | null,
    feedbackRequestSentAt?: string | null,
    hasFeedback?: boolean | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    hasDesignCompletionPhotos?: boolean | null,
    designCompletionPhotos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    signatureOfPortraitRights?: string | null,
    signedAtOfPortraitRights?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCustomerMutationVariables = {
  input: CreateCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type CreateCustomerMutation = {
  createCustomer?:  {
    __typename: "Customer",
    id: string,
    name: string,
    phoneNumber?: string | null,
    lastFourPhoneNumbers?: string | null,
    firstVisitDate?: string | null,
    occupation?: string | null,
    drink?: string | null,
    dress?: string | null,
    trouble?: string | null,
    memo?: string | null,
    bookingCount?: number | null,
    consultationCount?: number | null,
    consultationDoneCount?: number | null,
    signature?: string | null,
    totalRevenue?: number | null,
    vehicleNumber?: string | null,
    shopId: string,
    shopIds?: Array< string > | null,
    shopIdsString?: string | null,
    createdAt?: string | null,
    prepaidPassBalance?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCustomerMutationVariables = {
  input: UpdateCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type UpdateCustomerMutation = {
  updateCustomer?:  {
    __typename: "Customer",
    id: string,
    name: string,
    phoneNumber?: string | null,
    lastFourPhoneNumbers?: string | null,
    firstVisitDate?: string | null,
    occupation?: string | null,
    drink?: string | null,
    dress?: string | null,
    trouble?: string | null,
    memo?: string | null,
    bookingCount?: number | null,
    consultationCount?: number | null,
    consultationDoneCount?: number | null,
    signature?: string | null,
    totalRevenue?: number | null,
    vehicleNumber?: string | null,
    shopId: string,
    shopIds?: Array< string > | null,
    shopIdsString?: string | null,
    createdAt?: string | null,
    prepaidPassBalance?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCustomerMutationVariables = {
  input: DeleteCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type DeleteCustomerMutation = {
  deleteCustomer?:  {
    __typename: "Customer",
    id: string,
    name: string,
    phoneNumber?: string | null,
    lastFourPhoneNumbers?: string | null,
    firstVisitDate?: string | null,
    occupation?: string | null,
    drink?: string | null,
    dress?: string | null,
    trouble?: string | null,
    memo?: string | null,
    bookingCount?: number | null,
    consultationCount?: number | null,
    consultationDoneCount?: number | null,
    signature?: string | null,
    totalRevenue?: number | null,
    vehicleNumber?: string | null,
    shopId: string,
    shopIds?: Array< string > | null,
    shopIdsString?: string | null,
    createdAt?: string | null,
    prepaidPassBalance?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateShopMutationVariables = {
  input: CreateShopInput,
  condition?: ModelShopConditionInput | null,
};

export type CreateShopMutation = {
  createShop?:  {
    __typename: "Shop",
    id: string,
    name: string,
    naverBusinessId?: string | null,
    hasLocker?: boolean | null,
    bookingQRS3Key?: string | null,
    creatorId?: string | null,
    usePreSelfDiagnosis?: boolean | null,
    useFeedbackRequest?: boolean | null,
    owner?: string | null,
    ownerName?: string | null,
    ownerPhoneNumber?: string | null,
    internPhoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateShopMutationVariables = {
  input: UpdateShopInput,
  condition?: ModelShopConditionInput | null,
};

export type UpdateShopMutation = {
  updateShop?:  {
    __typename: "Shop",
    id: string,
    name: string,
    naverBusinessId?: string | null,
    hasLocker?: boolean | null,
    bookingQRS3Key?: string | null,
    creatorId?: string | null,
    usePreSelfDiagnosis?: boolean | null,
    useFeedbackRequest?: boolean | null,
    owner?: string | null,
    ownerName?: string | null,
    ownerPhoneNumber?: string | null,
    internPhoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteShopMutationVariables = {
  input: DeleteShopInput,
  condition?: ModelShopConditionInput | null,
};

export type DeleteShopMutation = {
  deleteShop?:  {
    __typename: "Shop",
    id: string,
    name: string,
    naverBusinessId?: string | null,
    hasLocker?: boolean | null,
    bookingQRS3Key?: string | null,
    creatorId?: string | null,
    usePreSelfDiagnosis?: boolean | null,
    useFeedbackRequest?: boolean | null,
    owner?: string | null,
    ownerName?: string | null,
    ownerPhoneNumber?: string | null,
    internPhoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateShopServiceDrinkMutationVariables = {
  input: CreateShopServiceDrinkInput,
  condition?: ModelShopServiceDrinkConditionInput | null,
};

export type CreateShopServiceDrinkMutation = {
  createShopServiceDrink?:  {
    __typename: "ShopServiceDrink",
    id: string,
    name: string,
    shopId: string,
    outOfStock?: boolean | null,
    displayOrder: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateShopServiceDrinkMutationVariables = {
  input: UpdateShopServiceDrinkInput,
  condition?: ModelShopServiceDrinkConditionInput | null,
};

export type UpdateShopServiceDrinkMutation = {
  updateShopServiceDrink?:  {
    __typename: "ShopServiceDrink",
    id: string,
    name: string,
    shopId: string,
    outOfStock?: boolean | null,
    displayOrder: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteShopServiceDrinkMutationVariables = {
  input: DeleteShopServiceDrinkInput,
  condition?: ModelShopServiceDrinkConditionInput | null,
};

export type DeleteShopServiceDrinkMutation = {
  deleteShopServiceDrink?:  {
    __typename: "ShopServiceDrink",
    id: string,
    name: string,
    shopId: string,
    outOfStock?: boolean | null,
    displayOrder: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateShopUserApplicationMutationVariables = {
  input: CreateShopUserApplicationInput,
  condition?: ModelShopUserApplicationConditionInput | null,
};

export type CreateShopUserApplicationMutation = {
  createShopUserApplication?:  {
    __typename: "ShopUserApplication",
    id: string,
    shopId: string,
    userId: string,
    phoneNumber: string,
    completed: boolean,
    name: string,
    email: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateShopUserApplicationMutationVariables = {
  input: UpdateShopUserApplicationInput,
  condition?: ModelShopUserApplicationConditionInput | null,
};

export type UpdateShopUserApplicationMutation = {
  updateShopUserApplication?:  {
    __typename: "ShopUserApplication",
    id: string,
    shopId: string,
    userId: string,
    phoneNumber: string,
    completed: boolean,
    name: string,
    email: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteShopUserApplicationMutationVariables = {
  input: DeleteShopUserApplicationInput,
  condition?: ModelShopUserApplicationConditionInput | null,
};

export type DeleteShopUserApplicationMutation = {
  deleteShopUserApplication?:  {
    __typename: "ShopUserApplication",
    id: string,
    shopId: string,
    userId: string,
    phoneNumber: string,
    completed: boolean,
    name: string,
    email: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateShopUserRemovalRequestMutationVariables = {
  input: CreateShopUserRemovalRequestInput,
  condition?: ModelShopUserRemovalRequestConditionInput | null,
};

export type CreateShopUserRemovalRequestMutation = {
  createShopUserRemovalRequest?:  {
    __typename: "ShopUserRemovalRequest",
    designerId: string,
    shopId: string,
    userId: string,
    completed: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateShopUserRemovalRequestMutationVariables = {
  input: UpdateShopUserRemovalRequestInput,
  condition?: ModelShopUserRemovalRequestConditionInput | null,
};

export type UpdateShopUserRemovalRequestMutation = {
  updateShopUserRemovalRequest?:  {
    __typename: "ShopUserRemovalRequest",
    designerId: string,
    shopId: string,
    userId: string,
    completed: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteShopUserRemovalRequestMutationVariables = {
  input: DeleteShopUserRemovalRequestInput,
  condition?: ModelShopUserRemovalRequestConditionInput | null,
};

export type DeleteShopUserRemovalRequestMutation = {
  deleteShopUserRemovalRequest?:  {
    __typename: "ShopUserRemovalRequest",
    designerId: string,
    shopId: string,
    userId: string,
    completed: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateServiceOptionMutationVariables = {
  input: CreateServiceOptionInput,
  condition?: ModelServiceOptionConditionInput | null,
};

export type CreateServiceOptionMutation = {
  createServiceOption?:  {
    __typename: "ServiceOption",
    shopId: string,
    categories:  Array< {
      __typename: "ServiceOptionCategory",
      name: string,
      options:  Array< {
        __typename: "ServiceOptionItem",
        name: string,
        price?: number | null,
      } >,
    } >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateServiceOptionMutationVariables = {
  input: UpdateServiceOptionInput,
  condition?: ModelServiceOptionConditionInput | null,
};

export type UpdateServiceOptionMutation = {
  updateServiceOption?:  {
    __typename: "ServiceOption",
    shopId: string,
    categories:  Array< {
      __typename: "ServiceOptionCategory",
      name: string,
      options:  Array< {
        __typename: "ServiceOptionItem",
        name: string,
        price?: number | null,
      } >,
    } >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteServiceOptionMutationVariables = {
  input: DeleteServiceOptionInput,
  condition?: ModelServiceOptionConditionInput | null,
};

export type DeleteServiceOptionMutation = {
  deleteServiceOption?:  {
    __typename: "ServiceOption",
    shopId: string,
    categories:  Array< {
      __typename: "ServiceOptionCategory",
      name: string,
      options:  Array< {
        __typename: "ServiceOptionItem",
        name: string,
        price?: number | null,
      } >,
    } >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePrepaidPassPresetMutationVariables = {
  input: CreatePrepaidPassPresetInput,
  condition?: ModelPrepaidPassPresetConditionInput | null,
};

export type CreatePrepaidPassPresetMutation = {
  createPrepaidPassPreset?:  {
    __typename: "PrepaidPassPreset",
    id: string,
    shopId: string,
    name: string,
    price: number,
    availableAmount: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePrepaidPassPresetMutationVariables = {
  input: UpdatePrepaidPassPresetInput,
  condition?: ModelPrepaidPassPresetConditionInput | null,
};

export type UpdatePrepaidPassPresetMutation = {
  updatePrepaidPassPreset?:  {
    __typename: "PrepaidPassPreset",
    id: string,
    shopId: string,
    name: string,
    price: number,
    availableAmount: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePrepaidPassPresetMutationVariables = {
  input: DeletePrepaidPassPresetInput,
  condition?: ModelPrepaidPassPresetConditionInput | null,
};

export type DeletePrepaidPassPresetMutation = {
  deletePrepaidPassPreset?:  {
    __typename: "PrepaidPassPreset",
    id: string,
    shopId: string,
    name: string,
    price: number,
    availableAmount: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePrepaidPassHistoryMutationVariables = {
  input: CreatePrepaidPassHistoryInput,
  condition?: ModelPrepaidPassHistoryConditionInput | null,
};

export type CreatePrepaidPassHistoryMutation = {
  createPrepaidPassHistory?:  {
    __typename: "PrepaidPassHistory",
    id: string,
    customerId: string,
    shopId: string,
    amount: number,
    bookingId?: string | null,
    type: PrepaidPassRecordType,
    description?: string | null,
    referenceId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePrepaidPassHistoryMutationVariables = {
  input: UpdatePrepaidPassHistoryInput,
  condition?: ModelPrepaidPassHistoryConditionInput | null,
};

export type UpdatePrepaidPassHistoryMutation = {
  updatePrepaidPassHistory?:  {
    __typename: "PrepaidPassHistory",
    id: string,
    customerId: string,
    shopId: string,
    amount: number,
    bookingId?: string | null,
    type: PrepaidPassRecordType,
    description?: string | null,
    referenceId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePrepaidPassHistoryMutationVariables = {
  input: DeletePrepaidPassHistoryInput,
  condition?: ModelPrepaidPassHistoryConditionInput | null,
};

export type DeletePrepaidPassHistoryMutation = {
  deletePrepaidPassHistory?:  {
    __typename: "PrepaidPassHistory",
    id: string,
    customerId: string,
    shopId: string,
    amount: number,
    bookingId?: string | null,
    type: PrepaidPassRecordType,
    description?: string | null,
    referenceId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateBrandMutationVariables = {
  input: CreateBrandInput,
  condition?: ModelBrandConditionInput | null,
};

export type CreateBrandMutation = {
  createBrand?:  {
    __typename: "Brand",
    id: string,
    shopId: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateBrandMutationVariables = {
  input: UpdateBrandInput,
  condition?: ModelBrandConditionInput | null,
};

export type UpdateBrandMutation = {
  updateBrand?:  {
    __typename: "Brand",
    id: string,
    shopId: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteBrandMutationVariables = {
  input: DeleteBrandInput,
  condition?: ModelBrandConditionInput | null,
};

export type DeleteBrandMutation = {
  deleteBrand?:  {
    __typename: "Brand",
    id: string,
    shopId: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateProductMutationVariables = {
  input: CreateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type CreateProductMutation = {
  createProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    shopId: string,
    brandId: string,
    brandName: string,
    costPrice: number,
    sellingPrice: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateProductMutationVariables = {
  input: UpdateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type UpdateProductMutation = {
  updateProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    shopId: string,
    brandId: string,
    brandName: string,
    costPrice: number,
    sellingPrice: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteProductMutationVariables = {
  input: DeleteProductInput,
  condition?: ModelProductConditionInput | null,
};

export type DeleteProductMutation = {
  deleteProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    shopId: string,
    brandId: string,
    brandName: string,
    costPrice: number,
    sellingPrice: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateConsultationSolutionPresetMutationVariables = {
  input: CreateConsultationSolutionPresetInput,
  condition?: ModelConsultationSolutionPresetConditionInput | null,
};

export type CreateConsultationSolutionPresetMutation = {
  createConsultationSolutionPreset?:  {
    __typename: "ConsultationSolutionPreset",
    id: string,
    category: string,
    sentence: string,
    priority: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateConsultationSolutionPresetMutationVariables = {
  input: UpdateConsultationSolutionPresetInput,
  condition?: ModelConsultationSolutionPresetConditionInput | null,
};

export type UpdateConsultationSolutionPresetMutation = {
  updateConsultationSolutionPreset?:  {
    __typename: "ConsultationSolutionPreset",
    id: string,
    category: string,
    sentence: string,
    priority: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteConsultationSolutionPresetMutationVariables = {
  input: DeleteConsultationSolutionPresetInput,
  condition?: ModelConsultationSolutionPresetConditionInput | null,
};

export type DeleteConsultationSolutionPresetMutation = {
  deleteConsultationSolutionPreset?:  {
    __typename: "ConsultationSolutionPreset",
    id: string,
    category: string,
    sentence: string,
    priority: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCoupangProductMutationVariables = {
  input: CreateCoupangProductInput,
  condition?: ModelCoupangProductConditionInput | null,
};

export type CreateCoupangProductMutation = {
  createCoupangProduct?:  {
    __typename: "CoupangProduct",
    id: string,
    src: string,
    name?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCoupangProductMutationVariables = {
  input: UpdateCoupangProductInput,
  condition?: ModelCoupangProductConditionInput | null,
};

export type UpdateCoupangProductMutation = {
  updateCoupangProduct?:  {
    __typename: "CoupangProduct",
    id: string,
    src: string,
    name?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCoupangProductMutationVariables = {
  input: DeleteCoupangProductInput,
  condition?: ModelCoupangProductConditionInput | null,
};

export type DeleteCoupangProductMutation = {
  deleteCoupangProduct?:  {
    __typename: "CoupangProduct",
    id: string,
    src: string,
    name?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateGeneralProductMutationVariables = {
  input: CreateGeneralProductInput,
  condition?: ModelGeneralProductConditionInput | null,
};

export type CreateGeneralProductMutation = {
  createGeneralProduct?:  {
    __typename: "GeneralProduct",
    id: string,
    link: string,
    name: string,
    brandName: string,
    description?: string | null,
    imageUrl?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateGeneralProductMutationVariables = {
  input: UpdateGeneralProductInput,
  condition?: ModelGeneralProductConditionInput | null,
};

export type UpdateGeneralProductMutation = {
  updateGeneralProduct?:  {
    __typename: "GeneralProduct",
    id: string,
    link: string,
    name: string,
    brandName: string,
    description?: string | null,
    imageUrl?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteGeneralProductMutationVariables = {
  input: DeleteGeneralProductInput,
  condition?: ModelGeneralProductConditionInput | null,
};

export type DeleteGeneralProductMutation = {
  deleteGeneralProduct?:  {
    __typename: "GeneralProduct",
    id: string,
    link: string,
    name: string,
    brandName: string,
    description?: string | null,
    imageUrl?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateEventMutationVariables = {
  input: CreateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type CreateEventMutation = {
  createEvent?:  {
    __typename: "Event",
    id: string,
    hostName: string,
    speakerName?: string | null,
    name: string,
    description?: string | null,
    city: string,
    venue?: string | null,
    link?: string | null,
    yearMonth: string,
    startDate: string,
    endDate?: string | null,
    target?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateEventMutationVariables = {
  input: UpdateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type UpdateEventMutation = {
  updateEvent?:  {
    __typename: "Event",
    id: string,
    hostName: string,
    speakerName?: string | null,
    name: string,
    description?: string | null,
    city: string,
    venue?: string | null,
    link?: string | null,
    yearMonth: string,
    startDate: string,
    endDate?: string | null,
    target?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteEventMutationVariables = {
  input: DeleteEventInput,
  condition?: ModelEventConditionInput | null,
};

export type DeleteEventMutation = {
  deleteEvent?:  {
    __typename: "Event",
    id: string,
    hostName: string,
    speakerName?: string | null,
    name: string,
    description?: string | null,
    city: string,
    venue?: string | null,
    link?: string | null,
    yearMonth: string,
    startDate: string,
    endDate?: string | null,
    target?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMutationResponseMutationVariables = {
  input: CreateMutationResponseInput,
  condition?: ModelMutationResponseConditionInput | null,
};

export type CreateMutationResponseMutation = {
  createMutationResponse?:  {
    __typename: "MutationResponse",
    success: boolean,
    reason?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMutationResponseMutationVariables = {
  input: UpdateMutationResponseInput,
  condition?: ModelMutationResponseConditionInput | null,
};

export type UpdateMutationResponseMutation = {
  updateMutationResponse?:  {
    __typename: "MutationResponse",
    success: boolean,
    reason?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMutationResponseMutationVariables = {
  input: DeleteMutationResponseInput,
  condition?: ModelMutationResponseConditionInput | null,
};

export type DeleteMutationResponseMutation = {
  deleteMutationResponse?:  {
    __typename: "MutationResponse",
    success: boolean,
    reason?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ApproveShopUserApplicationMutationVariables = {
  id: string,
};

export type ApproveShopUserApplicationMutation = {
  approveShopUserApplication?:  {
    __typename: "MutationResponse",
    success: boolean,
    reason?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetDiscountPresetQueryVariables = {
  id: string,
};

export type GetDiscountPresetQuery = {
  getDiscountPreset?:  {
    __typename: "DiscountPreset",
    id: string,
    shopId: string,
    name: string,
    amount?: number | null,
    rate?: number | null,
    type: DiscountMode,
    enabled: boolean,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDiscountPresetsQueryVariables = {
  filter?: ModelDiscountPresetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDiscountPresetsQuery = {
  listDiscountPresets?:  {
    __typename: "ModelDiscountPresetConnection",
    items:  Array< {
      __typename: "DiscountPreset",
      id: string,
      shopId: string,
      name: string,
      amount?: number | null,
      rate?: number | null,
      type: DiscountMode,
      enabled: boolean,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDiscountPresetsQueryVariables = {
  filter?: ModelDiscountPresetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDiscountPresetsQuery = {
  syncDiscountPresets?:  {
    __typename: "ModelDiscountPresetConnection",
    items:  Array< {
      __typename: "DiscountPreset",
      id: string,
      shopId: string,
      name: string,
      amount?: number | null,
      rate?: number | null,
      type: DiscountMode,
      enabled: boolean,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DiscountPresetByShopIdQueryVariables = {
  shopId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDiscountPresetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DiscountPresetByShopIdQuery = {
  discountPresetByShopId?:  {
    __typename: "ModelDiscountPresetConnection",
    items:  Array< {
      __typename: "DiscountPreset",
      id: string,
      shopId: string,
      name: string,
      amount?: number | null,
      rate?: number | null,
      type: DiscountMode,
      enabled: boolean,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDesignerQueryVariables = {
  id: string,
};

export type GetDesignerQuery = {
  getDesigner?:  {
    __typename: "Designer",
    id: string,
    shopId: string,
    name: string,
    naverName?: string | null,
    businessId?: string | null,
    bizItemId?: string | null,
    phoneNumber?: string | null,
    imageUrl?: string | null,
    s3Key?: string | null,
    userId?: string | null,
    youtubeUrl?: string | null,
    instagramUrl?: string | null,
    inactive?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDesignersQueryVariables = {
  filter?: ModelDesignerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDesignersQuery = {
  listDesigners?:  {
    __typename: "ModelDesignerConnection",
    items:  Array< {
      __typename: "Designer",
      id: string,
      shopId: string,
      name: string,
      naverName?: string | null,
      businessId?: string | null,
      bizItemId?: string | null,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      s3Key?: string | null,
      userId?: string | null,
      youtubeUrl?: string | null,
      instagramUrl?: string | null,
      inactive?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDesignersQueryVariables = {
  filter?: ModelDesignerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDesignersQuery = {
  syncDesigners?:  {
    __typename: "ModelDesignerConnection",
    items:  Array< {
      __typename: "Designer",
      id: string,
      shopId: string,
      name: string,
      naverName?: string | null,
      businessId?: string | null,
      bizItemId?: string | null,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      s3Key?: string | null,
      userId?: string | null,
      youtubeUrl?: string | null,
      instagramUrl?: string | null,
      inactive?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DesignerByShopIdQueryVariables = {
  shopId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDesignerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DesignerByShopIdQuery = {
  designerByShopId?:  {
    __typename: "ModelDesignerConnection",
    items:  Array< {
      __typename: "Designer",
      id: string,
      shopId: string,
      name: string,
      naverName?: string | null,
      businessId?: string | null,
      bizItemId?: string | null,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      s3Key?: string | null,
      userId?: string | null,
      youtubeUrl?: string | null,
      instagramUrl?: string | null,
      inactive?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DesignerByBusinessIdQueryVariables = {
  businessId: string,
  bizItemId?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDesignerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DesignerByBusinessIdQuery = {
  designerByBusinessId?:  {
    __typename: "ModelDesignerConnection",
    items:  Array< {
      __typename: "Designer",
      id: string,
      shopId: string,
      name: string,
      naverName?: string | null,
      businessId?: string | null,
      bizItemId?: string | null,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      s3Key?: string | null,
      userId?: string | null,
      youtubeUrl?: string | null,
      instagramUrl?: string | null,
      inactive?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DesignerByBizItemIdQueryVariables = {
  bizItemId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDesignerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DesignerByBizItemIdQuery = {
  designerByBizItemId?:  {
    __typename: "ModelDesignerConnection",
    items:  Array< {
      __typename: "Designer",
      id: string,
      shopId: string,
      name: string,
      naverName?: string | null,
      businessId?: string | null,
      bizItemId?: string | null,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      s3Key?: string | null,
      userId?: string | null,
      youtubeUrl?: string | null,
      instagramUrl?: string | null,
      inactive?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DesignerByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDesignerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DesignerByUserIdQuery = {
  designerByUserId?:  {
    __typename: "ModelDesignerConnection",
    items:  Array< {
      __typename: "Designer",
      id: string,
      shopId: string,
      name: string,
      naverName?: string | null,
      businessId?: string | null,
      bizItemId?: string | null,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      s3Key?: string | null,
      userId?: string | null,
      youtubeUrl?: string | null,
      instagramUrl?: string | null,
      inactive?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFeedbackQueryVariables = {
  bookingId: string,
};

export type GetFeedbackQuery = {
  getFeedback?:  {
    __typename: "Feedback",
    bookingId: string,
    category: FeedbackCategory,
    shopId: string,
    shopName: string,
    designerName: string,
    designerId: string,
    date: string,
    satisfaction: number,
    comment?: string | null,
    supportMessage?: string | null,
    modelVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFeedbacksQueryVariables = {
  bookingId?: string | null,
  filter?: ModelFeedbackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListFeedbacksQuery = {
  listFeedbacks?:  {
    __typename: "ModelFeedbackConnection",
    items:  Array< {
      __typename: "Feedback",
      bookingId: string,
      category: FeedbackCategory,
      shopId: string,
      shopName: string,
      designerName: string,
      designerId: string,
      date: string,
      satisfaction: number,
      comment?: string | null,
      supportMessage?: string | null,
      modelVersion: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFeedbacksQueryVariables = {
  filter?: ModelFeedbackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFeedbacksQuery = {
  syncFeedbacks?:  {
    __typename: "ModelFeedbackConnection",
    items:  Array< {
      __typename: "Feedback",
      bookingId: string,
      category: FeedbackCategory,
      shopId: string,
      shopName: string,
      designerName: string,
      designerId: string,
      date: string,
      satisfaction: number,
      comment?: string | null,
      supportMessage?: string | null,
      modelVersion: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type FeedbackByShopIdQueryVariables = {
  shopId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeedbackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FeedbackByShopIdQuery = {
  feedbackByShopId?:  {
    __typename: "ModelFeedbackConnection",
    items:  Array< {
      __typename: "Feedback",
      bookingId: string,
      category: FeedbackCategory,
      shopId: string,
      shopName: string,
      designerName: string,
      designerId: string,
      date: string,
      satisfaction: number,
      comment?: string | null,
      supportMessage?: string | null,
      modelVersion: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type FeedbackByDesignerNameQueryVariables = {
  designerName: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeedbackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FeedbackByDesignerNameQuery = {
  feedbackByDesignerName?:  {
    __typename: "ModelFeedbackConnection",
    items:  Array< {
      __typename: "Feedback",
      bookingId: string,
      category: FeedbackCategory,
      shopId: string,
      shopName: string,
      designerName: string,
      designerId: string,
      date: string,
      satisfaction: number,
      comment?: string | null,
      supportMessage?: string | null,
      modelVersion: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type FeedbackByDesignerIdQueryVariables = {
  designerId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeedbackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FeedbackByDesignerIdQuery = {
  feedbackByDesignerId?:  {
    __typename: "ModelFeedbackConnection",
    items:  Array< {
      __typename: "Feedback",
      bookingId: string,
      category: FeedbackCategory,
      shopId: string,
      shopName: string,
      designerName: string,
      designerId: string,
      date: string,
      satisfaction: number,
      comment?: string | null,
      supportMessage?: string | null,
      modelVersion: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type FeedbackByDateQueryVariables = {
  date: string,
  designerName?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeedbackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FeedbackByDateQuery = {
  feedbackByDate?:  {
    __typename: "ModelFeedbackConnection",
    items:  Array< {
      __typename: "Feedback",
      bookingId: string,
      category: FeedbackCategory,
      shopId: string,
      shopName: string,
      designerName: string,
      designerId: string,
      date: string,
      satisfaction: number,
      comment?: string | null,
      supportMessage?: string | null,
      modelVersion: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetRegularCustomerNoteQueryVariables = {
  bookingId: string,
};

export type GetRegularCustomerNoteQuery = {
  getRegularCustomerNote?:  {
    __typename: "RegularCustomerNote",
    bookingId: string,
    shopId: string,
    shopName?: string | null,
    customerId: string,
    customerName: string,
    levelOfStyleChange: string,
    nextSchedule?: string | null,
    comment?: string | null,
    productsInterestedIn?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    modelVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListRegularCustomerNotesQueryVariables = {
  bookingId?: string | null,
  filter?: ModelRegularCustomerNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListRegularCustomerNotesQuery = {
  listRegularCustomerNotes?:  {
    __typename: "ModelRegularCustomerNoteConnection",
    items:  Array< {
      __typename: "RegularCustomerNote",
      bookingId: string,
      shopId: string,
      shopName?: string | null,
      customerId: string,
      customerName: string,
      levelOfStyleChange: string,
      nextSchedule?: string | null,
      comment?: string | null,
      productsInterestedIn?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      modelVersion: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncRegularCustomerNotesQueryVariables = {
  filter?: ModelRegularCustomerNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncRegularCustomerNotesQuery = {
  syncRegularCustomerNotes?:  {
    __typename: "ModelRegularCustomerNoteConnection",
    items:  Array< {
      __typename: "RegularCustomerNote",
      bookingId: string,
      shopId: string,
      shopName?: string | null,
      customerId: string,
      customerName: string,
      levelOfStyleChange: string,
      nextSchedule?: string | null,
      comment?: string | null,
      productsInterestedIn?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      modelVersion: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserPushTokenRequestQueryVariables = {
  id: string,
};

export type GetUserPushTokenRequestQuery = {
  getUserPushTokenRequest?:  {
    __typename: "UserPushTokenRequest",
    id: string,
    token: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserPushTokenRequestsQueryVariables = {
  filter?: ModelUserPushTokenRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserPushTokenRequestsQuery = {
  listUserPushTokenRequests?:  {
    __typename: "ModelUserPushTokenRequestConnection",
    items:  Array< {
      __typename: "UserPushTokenRequest",
      id: string,
      token: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserPushTokenRequestsQueryVariables = {
  filter?: ModelUserPushTokenRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserPushTokenRequestsQuery = {
  syncUserPushTokenRequests?:  {
    __typename: "ModelUserPushTokenRequestConnection",
    items:  Array< {
      __typename: "UserPushTokenRequest",
      id: string,
      token: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type UserPushTokenRequestByOwnerQueryVariables = {
  owner: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserPushTokenRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserPushTokenRequestByOwnerQuery = {
  userPushTokenRequestByOwner?:  {
    __typename: "ModelUserPushTokenRequestConnection",
    items:  Array< {
      __typename: "UserPushTokenRequest",
      id: string,
      token: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserPushTokenQueryVariables = {
  token: string,
};

export type GetUserPushTokenQuery = {
  getUserPushToken?:  {
    __typename: "UserPushToken",
    token: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserPushTokensQueryVariables = {
  token?: string | null,
  filter?: ModelUserPushTokenFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserPushTokensQuery = {
  listUserPushTokens?:  {
    __typename: "ModelUserPushTokenConnection",
    items:  Array< {
      __typename: "UserPushToken",
      token: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserPushTokensQueryVariables = {
  filter?: ModelUserPushTokenFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserPushTokensQuery = {
  syncUserPushTokens?:  {
    __typename: "ModelUserPushTokenConnection",
    items:  Array< {
      __typename: "UserPushToken",
      token: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type UserPushTokenByOwnerQueryVariables = {
  owner: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserPushTokenFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserPushTokenByOwnerQuery = {
  userPushTokenByOwner?:  {
    __typename: "ModelUserPushTokenConnection",
    items:  Array< {
      __typename: "UserPushToken",
      token: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCustomerV2QueryVariables = {
  id: string,
};

export type GetCustomerV2Query = {
  getCustomerV2?:  {
    __typename: "CustomerV2",
    id: string,
    owner?: string | null,
    name: string,
    phoneNumber: string,
    lastFourPhoneNumbers: string,
    firstVisitDate?: string | null,
    occupation?: string | null,
    drink?: string | null,
    visitCount?: number | null,
    selfCount?: number | null,
    regularCount?: number | null,
    caringCount?: number | null,
    comment?: string | null,
    modelVersion?: number | null,
    cut?: boolean | null,
    perm?: boolean | null,
    color?: boolean | null,
    clinic?: boolean | null,
    gender?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCustomerV2sQueryVariables = {
  filter?: ModelCustomerV2FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomerV2sQuery = {
  listCustomerV2s?:  {
    __typename: "ModelCustomerV2Connection",
    items:  Array< {
      __typename: "CustomerV2",
      id: string,
      owner?: string | null,
      name: string,
      phoneNumber: string,
      lastFourPhoneNumbers: string,
      firstVisitDate?: string | null,
      occupation?: string | null,
      drink?: string | null,
      visitCount?: number | null,
      selfCount?: number | null,
      regularCount?: number | null,
      caringCount?: number | null,
      comment?: string | null,
      modelVersion?: number | null,
      cut?: boolean | null,
      perm?: boolean | null,
      color?: boolean | null,
      clinic?: boolean | null,
      gender?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCustomerV2sQueryVariables = {
  filter?: ModelCustomerV2FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCustomerV2sQuery = {
  syncCustomerV2s?:  {
    __typename: "ModelCustomerV2Connection",
    items:  Array< {
      __typename: "CustomerV2",
      id: string,
      owner?: string | null,
      name: string,
      phoneNumber: string,
      lastFourPhoneNumbers: string,
      firstVisitDate?: string | null,
      occupation?: string | null,
      drink?: string | null,
      visitCount?: number | null,
      selfCount?: number | null,
      regularCount?: number | null,
      caringCount?: number | null,
      comment?: string | null,
      modelVersion?: number | null,
      cut?: boolean | null,
      perm?: boolean | null,
      color?: boolean | null,
      clinic?: boolean | null,
      gender?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CustomerV2ByOwnerQueryVariables = {
  owner: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomerV2FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomerV2ByOwnerQuery = {
  customerV2ByOwner?:  {
    __typename: "ModelCustomerV2Connection",
    items:  Array< {
      __typename: "CustomerV2",
      id: string,
      owner?: string | null,
      name: string,
      phoneNumber: string,
      lastFourPhoneNumbers: string,
      firstVisitDate?: string | null,
      occupation?: string | null,
      drink?: string | null,
      visitCount?: number | null,
      selfCount?: number | null,
      regularCount?: number | null,
      caringCount?: number | null,
      comment?: string | null,
      modelVersion?: number | null,
      cut?: boolean | null,
      perm?: boolean | null,
      color?: boolean | null,
      clinic?: boolean | null,
      gender?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CustomerV2ByNameQueryVariables = {
  name: string,
  phoneNumber?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomerV2FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomerV2ByNameQuery = {
  customerV2ByName?:  {
    __typename: "ModelCustomerV2Connection",
    items:  Array< {
      __typename: "CustomerV2",
      id: string,
      owner?: string | null,
      name: string,
      phoneNumber: string,
      lastFourPhoneNumbers: string,
      firstVisitDate?: string | null,
      occupation?: string | null,
      drink?: string | null,
      visitCount?: number | null,
      selfCount?: number | null,
      regularCount?: number | null,
      caringCount?: number | null,
      comment?: string | null,
      modelVersion?: number | null,
      cut?: boolean | null,
      perm?: boolean | null,
      color?: boolean | null,
      clinic?: boolean | null,
      gender?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CustomerV2ByPhoneNumberQueryVariables = {
  phoneNumber: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomerV2FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomerV2ByPhoneNumberQuery = {
  customerV2ByPhoneNumber?:  {
    __typename: "ModelCustomerV2Connection",
    items:  Array< {
      __typename: "CustomerV2",
      id: string,
      owner?: string | null,
      name: string,
      phoneNumber: string,
      lastFourPhoneNumbers: string,
      firstVisitDate?: string | null,
      occupation?: string | null,
      drink?: string | null,
      visitCount?: number | null,
      selfCount?: number | null,
      regularCount?: number | null,
      caringCount?: number | null,
      comment?: string | null,
      modelVersion?: number | null,
      cut?: boolean | null,
      perm?: boolean | null,
      color?: boolean | null,
      clinic?: boolean | null,
      gender?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CustomerV2ByLastFourPhoneNumbersQueryVariables = {
  lastFourPhoneNumbers: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomerV2FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomerV2ByLastFourPhoneNumbersQuery = {
  customerV2ByLastFourPhoneNumbers?:  {
    __typename: "ModelCustomerV2Connection",
    items:  Array< {
      __typename: "CustomerV2",
      id: string,
      owner?: string | null,
      name: string,
      phoneNumber: string,
      lastFourPhoneNumbers: string,
      firstVisitDate?: string | null,
      occupation?: string | null,
      drink?: string | null,
      visitCount?: number | null,
      selfCount?: number | null,
      regularCount?: number | null,
      caringCount?: number | null,
      comment?: string | null,
      modelVersion?: number | null,
      cut?: boolean | null,
      perm?: boolean | null,
      color?: boolean | null,
      clinic?: boolean | null,
      gender?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCaringQueryVariables = {
  id: string,
};

export type GetCaringQuery = {
  getCaring?:  {
    __typename: "Caring",
    id: string,
    owner?: string | null,
    shopName: string,
    designerName: string,
    name: string,
    phoneNumber: string,
    yearMonth: string,
    date: string,
    sentAt?: string | null,
    timestamp: number,
    pointOfToday: string,
    aftercareTips?: string | null,
    stylingTips?: string | null,
    precautions?: string | null,
    serviceRecommendation?: string | null,
    nextVisitDate?: string | null,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCaringsQueryVariables = {
  filter?: ModelCaringFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCaringsQuery = {
  listCarings?:  {
    __typename: "ModelCaringConnection",
    items:  Array< {
      __typename: "Caring",
      id: string,
      owner?: string | null,
      shopName: string,
      designerName: string,
      name: string,
      phoneNumber: string,
      yearMonth: string,
      date: string,
      sentAt?: string | null,
      timestamp: number,
      pointOfToday: string,
      aftercareTips?: string | null,
      stylingTips?: string | null,
      precautions?: string | null,
      serviceRecommendation?: string | null,
      nextVisitDate?: string | null,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCaringsQueryVariables = {
  filter?: ModelCaringFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCaringsQuery = {
  syncCarings?:  {
    __typename: "ModelCaringConnection",
    items:  Array< {
      __typename: "Caring",
      id: string,
      owner?: string | null,
      shopName: string,
      designerName: string,
      name: string,
      phoneNumber: string,
      yearMonth: string,
      date: string,
      sentAt?: string | null,
      timestamp: number,
      pointOfToday: string,
      aftercareTips?: string | null,
      stylingTips?: string | null,
      precautions?: string | null,
      serviceRecommendation?: string | null,
      nextVisitDate?: string | null,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CaringByOwnerAndYearMonthQueryVariables = {
  owner: string,
  yearMonth?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCaringFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CaringByOwnerAndYearMonthQuery = {
  caringByOwnerAndYearMonth?:  {
    __typename: "ModelCaringConnection",
    items:  Array< {
      __typename: "Caring",
      id: string,
      owner?: string | null,
      shopName: string,
      designerName: string,
      name: string,
      phoneNumber: string,
      yearMonth: string,
      date: string,
      sentAt?: string | null,
      timestamp: number,
      pointOfToday: string,
      aftercareTips?: string | null,
      stylingTips?: string | null,
      precautions?: string | null,
      serviceRecommendation?: string | null,
      nextVisitDate?: string | null,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CaringByOwnerAndTimestampQueryVariables = {
  owner: string,
  timestamp?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCaringFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CaringByOwnerAndTimestampQuery = {
  caringByOwnerAndTimestamp?:  {
    __typename: "ModelCaringConnection",
    items:  Array< {
      __typename: "Caring",
      id: string,
      owner?: string | null,
      shopName: string,
      designerName: string,
      name: string,
      phoneNumber: string,
      yearMonth: string,
      date: string,
      sentAt?: string | null,
      timestamp: number,
      pointOfToday: string,
      aftercareTips?: string | null,
      stylingTips?: string | null,
      precautions?: string | null,
      serviceRecommendation?: string | null,
      nextVisitDate?: string | null,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CaringByYearMonthQueryVariables = {
  yearMonth: string,
  timestamp?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCaringFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CaringByYearMonthQuery = {
  caringByYearMonth?:  {
    __typename: "ModelCaringConnection",
    items:  Array< {
      __typename: "Caring",
      id: string,
      owner?: string | null,
      shopName: string,
      designerName: string,
      name: string,
      phoneNumber: string,
      yearMonth: string,
      date: string,
      sentAt?: string | null,
      timestamp: number,
      pointOfToday: string,
      aftercareTips?: string | null,
      stylingTips?: string | null,
      precautions?: string | null,
      serviceRecommendation?: string | null,
      nextVisitDate?: string | null,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CaringByDateQueryVariables = {
  date: string,
  designerName?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCaringFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CaringByDateQuery = {
  caringByDate?:  {
    __typename: "ModelCaringConnection",
    items:  Array< {
      __typename: "Caring",
      id: string,
      owner?: string | null,
      shopName: string,
      designerName: string,
      name: string,
      phoneNumber: string,
      yearMonth: string,
      date: string,
      sentAt?: string | null,
      timestamp: number,
      pointOfToday: string,
      aftercareTips?: string | null,
      stylingTips?: string | null,
      precautions?: string | null,
      serviceRecommendation?: string | null,
      nextVisitDate?: string | null,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CaringByCustomerIdQueryVariables = {
  customerId: string,
  timestamp?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCaringFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CaringByCustomerIdQuery = {
  caringByCustomerId?:  {
    __typename: "ModelCaringConnection",
    items:  Array< {
      __typename: "Caring",
      id: string,
      owner?: string | null,
      shopName: string,
      designerName: string,
      name: string,
      phoneNumber: string,
      yearMonth: string,
      date: string,
      sentAt?: string | null,
      timestamp: number,
      pointOfToday: string,
      aftercareTips?: string | null,
      stylingTips?: string | null,
      precautions?: string | null,
      serviceRecommendation?: string | null,
      nextVisitDate?: string | null,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSelfDiagnosisRequestQueryVariables = {
  id: string,
};

export type GetSelfDiagnosisRequestQuery = {
  getSelfDiagnosisRequest?:  {
    __typename: "SelfDiagnosisRequest",
    id: string,
    owner?: string | null,
    shopName: string,
    designerName: string,
    name: string,
    phoneNumber: string,
    yearMonth: string,
    date?: string | null,
    sentAt?: string | null,
    writtenAt?: string | null,
    timestamp?: number | null,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSelfDiagnosisRequestsQueryVariables = {
  filter?: ModelSelfDiagnosisRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSelfDiagnosisRequestsQuery = {
  listSelfDiagnosisRequests?:  {
    __typename: "ModelSelfDiagnosisRequestConnection",
    items:  Array< {
      __typename: "SelfDiagnosisRequest",
      id: string,
      owner?: string | null,
      shopName: string,
      designerName: string,
      name: string,
      phoneNumber: string,
      yearMonth: string,
      date?: string | null,
      sentAt?: string | null,
      writtenAt?: string | null,
      timestamp?: number | null,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSelfDiagnosisRequestsQueryVariables = {
  filter?: ModelSelfDiagnosisRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSelfDiagnosisRequestsQuery = {
  syncSelfDiagnosisRequests?:  {
    __typename: "ModelSelfDiagnosisRequestConnection",
    items:  Array< {
      __typename: "SelfDiagnosisRequest",
      id: string,
      owner?: string | null,
      shopName: string,
      designerName: string,
      name: string,
      phoneNumber: string,
      yearMonth: string,
      date?: string | null,
      sentAt?: string | null,
      writtenAt?: string | null,
      timestamp?: number | null,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SelfDiagnosisRequestByOwnerQueryVariables = {
  owner: string,
  yearMonth?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSelfDiagnosisRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SelfDiagnosisRequestByOwnerQuery = {
  selfDiagnosisRequestByOwner?:  {
    __typename: "ModelSelfDiagnosisRequestConnection",
    items:  Array< {
      __typename: "SelfDiagnosisRequest",
      id: string,
      owner?: string | null,
      shopName: string,
      designerName: string,
      name: string,
      phoneNumber: string,
      yearMonth: string,
      date?: string | null,
      sentAt?: string | null,
      writtenAt?: string | null,
      timestamp?: number | null,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SelfDiagnosisRequestByOwnerAndTimestampQueryVariables = {
  owner: string,
  timestamp?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSelfDiagnosisRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SelfDiagnosisRequestByOwnerAndTimestampQuery = {
  selfDiagnosisRequestByOwnerAndTimestamp?:  {
    __typename: "ModelSelfDiagnosisRequestConnection",
    items:  Array< {
      __typename: "SelfDiagnosisRequest",
      id: string,
      owner?: string | null,
      shopName: string,
      designerName: string,
      name: string,
      phoneNumber: string,
      yearMonth: string,
      date?: string | null,
      sentAt?: string | null,
      writtenAt?: string | null,
      timestamp?: number | null,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SelfDiagnosisRequestByYearMonthAndTimestampQueryVariables = {
  yearMonth: string,
  timestamp?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSelfDiagnosisRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SelfDiagnosisRequestByYearMonthAndTimestampQuery = {
  selfDiagnosisRequestByYearMonthAndTimestamp?:  {
    __typename: "ModelSelfDiagnosisRequestConnection",
    items:  Array< {
      __typename: "SelfDiagnosisRequest",
      id: string,
      owner?: string | null,
      shopName: string,
      designerName: string,
      name: string,
      phoneNumber: string,
      yearMonth: string,
      date?: string | null,
      sentAt?: string | null,
      writtenAt?: string | null,
      timestamp?: number | null,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SelfDiagnosisRequestByDateQueryVariables = {
  date: string,
  designerName?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSelfDiagnosisRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SelfDiagnosisRequestByDateQuery = {
  selfDiagnosisRequestByDate?:  {
    __typename: "ModelSelfDiagnosisRequestConnection",
    items:  Array< {
      __typename: "SelfDiagnosisRequest",
      id: string,
      owner?: string | null,
      shopName: string,
      designerName: string,
      name: string,
      phoneNumber: string,
      yearMonth: string,
      date?: string | null,
      sentAt?: string | null,
      writtenAt?: string | null,
      timestamp?: number | null,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SelfDiagnosisRequestByCustomerIdQueryVariables = {
  customerId: string,
  timestamp?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSelfDiagnosisRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SelfDiagnosisRequestByCustomerIdQuery = {
  selfDiagnosisRequestByCustomerId?:  {
    __typename: "ModelSelfDiagnosisRequestConnection",
    items:  Array< {
      __typename: "SelfDiagnosisRequest",
      id: string,
      owner?: string | null,
      shopName: string,
      designerName: string,
      name: string,
      phoneNumber: string,
      yearMonth: string,
      date?: string | null,
      sentAt?: string | null,
      writtenAt?: string | null,
      timestamp?: number | null,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSelfDiagnosisContentQueryVariables = {
  requestId: string,
};

export type GetSelfDiagnosisContentQuery = {
  getSelfDiagnosisContent?:  {
    __typename: "SelfDiagnosisContent",
    requestId: string,
    shopName: string,
    designerName: string,
    name: string,
    yearMonth: string,
    date?: string | null,
    inflowChannel?: string | null,
    gender?: ConsultationGender | null,
    dry?: boolean | null,
    concerns?: Array< string > | null,
    frequencyOfVisits?: string | null,
    outfitStyles?: Array< string > | null,
    treatmentsInterestedIn?: Array< string > | null,
    productsForShampooing?: Array< string > | null,
    productsForStyling?: Array< string > | null,
    recentDyeing?: string | null,
    recentPerm?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    blackDyedHair?: boolean | null,
    bleachedHair?: boolean | null,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSelfDiagnosisContentsQueryVariables = {
  requestId?: string | null,
  filter?: ModelSelfDiagnosisContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSelfDiagnosisContentsQuery = {
  listSelfDiagnosisContents?:  {
    __typename: "ModelSelfDiagnosisContentConnection",
    items:  Array< {
      __typename: "SelfDiagnosisContent",
      requestId: string,
      shopName: string,
      designerName: string,
      name: string,
      yearMonth: string,
      date?: string | null,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      recentDyeing?: string | null,
      recentPerm?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      blackDyedHair?: boolean | null,
      bleachedHair?: boolean | null,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSelfDiagnosisContentsQueryVariables = {
  filter?: ModelSelfDiagnosisContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSelfDiagnosisContentsQuery = {
  syncSelfDiagnosisContents?:  {
    __typename: "ModelSelfDiagnosisContentConnection",
    items:  Array< {
      __typename: "SelfDiagnosisContent",
      requestId: string,
      shopName: string,
      designerName: string,
      name: string,
      yearMonth: string,
      date?: string | null,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      recentDyeing?: string | null,
      recentPerm?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      blackDyedHair?: boolean | null,
      bleachedHair?: boolean | null,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SelfDiagnosisContentByYearMonthQueryVariables = {
  yearMonth: string,
  designerName?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSelfDiagnosisContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SelfDiagnosisContentByYearMonthQuery = {
  selfDiagnosisContentByYearMonth?:  {
    __typename: "ModelSelfDiagnosisContentConnection",
    items:  Array< {
      __typename: "SelfDiagnosisContent",
      requestId: string,
      shopName: string,
      designerName: string,
      name: string,
      yearMonth: string,
      date?: string | null,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      recentDyeing?: string | null,
      recentPerm?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      blackDyedHair?: boolean | null,
      bleachedHair?: boolean | null,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SelfDiagnosisContentByDateQueryVariables = {
  date: string,
  designerName?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSelfDiagnosisContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SelfDiagnosisContentByDateQuery = {
  selfDiagnosisContentByDate?:  {
    __typename: "ModelSelfDiagnosisContentConnection",
    items:  Array< {
      __typename: "SelfDiagnosisContent",
      requestId: string,
      shopName: string,
      designerName: string,
      name: string,
      yearMonth: string,
      date?: string | null,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      recentDyeing?: string | null,
      recentPerm?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      blackDyedHair?: boolean | null,
      bleachedHair?: boolean | null,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SelfDiagnosisContentByCustomerIdQueryVariables = {
  customerId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSelfDiagnosisContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SelfDiagnosisContentByCustomerIdQuery = {
  selfDiagnosisContentByCustomerId?:  {
    __typename: "ModelSelfDiagnosisContentConnection",
    items:  Array< {
      __typename: "SelfDiagnosisContent",
      requestId: string,
      shopName: string,
      designerName: string,
      name: string,
      yearMonth: string,
      date?: string | null,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      recentDyeing?: string | null,
      recentPerm?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      blackDyedHair?: boolean | null,
      bleachedHair?: boolean | null,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetRegularCustomerQuestionnaireRequestQueryVariables = {
  id: string,
};

export type GetRegularCustomerQuestionnaireRequestQuery = {
  getRegularCustomerQuestionnaireRequest?:  {
    __typename: "RegularCustomerQuestionnaireRequest",
    id: string,
    owner?: string | null,
    shopName: string,
    designerName: string,
    name: string,
    phoneNumber: string,
    yearMonth: string,
    date: string,
    sentAt?: string | null,
    writtenAt?: string | null,
    timestamp: number,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListRegularCustomerQuestionnaireRequestsQueryVariables = {
  filter?: ModelRegularCustomerQuestionnaireRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRegularCustomerQuestionnaireRequestsQuery = {
  listRegularCustomerQuestionnaireRequests?:  {
    __typename: "ModelRegularCustomerQuestionnaireRequestConnection",
    items:  Array< {
      __typename: "RegularCustomerQuestionnaireRequest",
      id: string,
      owner?: string | null,
      shopName: string,
      designerName: string,
      name: string,
      phoneNumber: string,
      yearMonth: string,
      date: string,
      sentAt?: string | null,
      writtenAt?: string | null,
      timestamp: number,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncRegularCustomerQuestionnaireRequestsQueryVariables = {
  filter?: ModelRegularCustomerQuestionnaireRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncRegularCustomerQuestionnaireRequestsQuery = {
  syncRegularCustomerQuestionnaireRequests?:  {
    __typename: "ModelRegularCustomerQuestionnaireRequestConnection",
    items:  Array< {
      __typename: "RegularCustomerQuestionnaireRequest",
      id: string,
      owner?: string | null,
      shopName: string,
      designerName: string,
      name: string,
      phoneNumber: string,
      yearMonth: string,
      date: string,
      sentAt?: string | null,
      writtenAt?: string | null,
      timestamp: number,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type RegularCustomerQuestionnaireRequestByOwnerQueryVariables = {
  owner: string,
  yearMonth?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRegularCustomerQuestionnaireRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RegularCustomerQuestionnaireRequestByOwnerQuery = {
  regularCustomerQuestionnaireRequestByOwner?:  {
    __typename: "ModelRegularCustomerQuestionnaireRequestConnection",
    items:  Array< {
      __typename: "RegularCustomerQuestionnaireRequest",
      id: string,
      owner?: string | null,
      shopName: string,
      designerName: string,
      name: string,
      phoneNumber: string,
      yearMonth: string,
      date: string,
      sentAt?: string | null,
      writtenAt?: string | null,
      timestamp: number,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type RegularCustomerQuestionnaireRequestByOwnerAndTimestampQueryVariables = {
  owner: string,
  timestamp?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRegularCustomerQuestionnaireRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RegularCustomerQuestionnaireRequestByOwnerAndTimestampQuery = {
  regularCustomerQuestionnaireRequestByOwnerAndTimestamp?:  {
    __typename: "ModelRegularCustomerQuestionnaireRequestConnection",
    items:  Array< {
      __typename: "RegularCustomerQuestionnaireRequest",
      id: string,
      owner?: string | null,
      shopName: string,
      designerName: string,
      name: string,
      phoneNumber: string,
      yearMonth: string,
      date: string,
      sentAt?: string | null,
      writtenAt?: string | null,
      timestamp: number,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type RegularCustomerQuestionnaireRequestByYearMonthAndTimestampQueryVariables = {
  yearMonth: string,
  timestamp?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRegularCustomerQuestionnaireRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RegularCustomerQuestionnaireRequestByYearMonthAndTimestampQuery = {
  regularCustomerQuestionnaireRequestByYearMonthAndTimestamp?:  {
    __typename: "ModelRegularCustomerQuestionnaireRequestConnection",
    items:  Array< {
      __typename: "RegularCustomerQuestionnaireRequest",
      id: string,
      owner?: string | null,
      shopName: string,
      designerName: string,
      name: string,
      phoneNumber: string,
      yearMonth: string,
      date: string,
      sentAt?: string | null,
      writtenAt?: string | null,
      timestamp: number,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type RegularCustomerQuestionnaireRequestByDateQueryVariables = {
  date: string,
  timestamp?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRegularCustomerQuestionnaireRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RegularCustomerQuestionnaireRequestByDateQuery = {
  regularCustomerQuestionnaireRequestByDate?:  {
    __typename: "ModelRegularCustomerQuestionnaireRequestConnection",
    items:  Array< {
      __typename: "RegularCustomerQuestionnaireRequest",
      id: string,
      owner?: string | null,
      shopName: string,
      designerName: string,
      name: string,
      phoneNumber: string,
      yearMonth: string,
      date: string,
      sentAt?: string | null,
      writtenAt?: string | null,
      timestamp: number,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type RegularCustomerQuestionnaireRequestByCustomerIdQueryVariables = {
  customerId: string,
  timestamp?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRegularCustomerQuestionnaireRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RegularCustomerQuestionnaireRequestByCustomerIdQuery = {
  regularCustomerQuestionnaireRequestByCustomerId?:  {
    __typename: "ModelRegularCustomerQuestionnaireRequestConnection",
    items:  Array< {
      __typename: "RegularCustomerQuestionnaireRequest",
      id: string,
      owner?: string | null,
      shopName: string,
      designerName: string,
      name: string,
      phoneNumber: string,
      yearMonth: string,
      date: string,
      sentAt?: string | null,
      writtenAt?: string | null,
      timestamp: number,
      modelVersion?: number | null,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetRegularCustomerQuestionnaireContentQueryVariables = {
  requestId: string,
};

export type GetRegularCustomerQuestionnaireContentQuery = {
  getRegularCustomerQuestionnaireContent?:  {
    __typename: "RegularCustomerQuestionnaireContent",
    requestId: string,
    shopName: string,
    designerName: string,
    name: string,
    yearMonth: string,
    date: string,
    levelOfStyleChange: string,
    nextSchedule?: string | null,
    comment?: string | null,
    productsInterestedIn?: string | null,
    treatmentsInterestedIn?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    timestamp: number,
    modelVersion: number,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListRegularCustomerQuestionnaireContentsQueryVariables = {
  requestId?: string | null,
  filter?: ModelRegularCustomerQuestionnaireContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListRegularCustomerQuestionnaireContentsQuery = {
  listRegularCustomerQuestionnaireContents?:  {
    __typename: "ModelRegularCustomerQuestionnaireContentConnection",
    items:  Array< {
      __typename: "RegularCustomerQuestionnaireContent",
      requestId: string,
      shopName: string,
      designerName: string,
      name: string,
      yearMonth: string,
      date: string,
      levelOfStyleChange: string,
      nextSchedule?: string | null,
      comment?: string | null,
      productsInterestedIn?: string | null,
      treatmentsInterestedIn?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      timestamp: number,
      modelVersion: number,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncRegularCustomerQuestionnaireContentsQueryVariables = {
  filter?: ModelRegularCustomerQuestionnaireContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncRegularCustomerQuestionnaireContentsQuery = {
  syncRegularCustomerQuestionnaireContents?:  {
    __typename: "ModelRegularCustomerQuestionnaireContentConnection",
    items:  Array< {
      __typename: "RegularCustomerQuestionnaireContent",
      requestId: string,
      shopName: string,
      designerName: string,
      name: string,
      yearMonth: string,
      date: string,
      levelOfStyleChange: string,
      nextSchedule?: string | null,
      comment?: string | null,
      productsInterestedIn?: string | null,
      treatmentsInterestedIn?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      timestamp: number,
      modelVersion: number,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type RegularCustomerQuestionnaireContentByYearMonthQueryVariables = {
  yearMonth: string,
  timestamp?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRegularCustomerQuestionnaireContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RegularCustomerQuestionnaireContentByYearMonthQuery = {
  regularCustomerQuestionnaireContentByYearMonth?:  {
    __typename: "ModelRegularCustomerQuestionnaireContentConnection",
    items:  Array< {
      __typename: "RegularCustomerQuestionnaireContent",
      requestId: string,
      shopName: string,
      designerName: string,
      name: string,
      yearMonth: string,
      date: string,
      levelOfStyleChange: string,
      nextSchedule?: string | null,
      comment?: string | null,
      productsInterestedIn?: string | null,
      treatmentsInterestedIn?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      timestamp: number,
      modelVersion: number,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type RegularCustomerQuestionnaireContentByDateQueryVariables = {
  date: string,
  timestamp?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRegularCustomerQuestionnaireContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RegularCustomerQuestionnaireContentByDateQuery = {
  regularCustomerQuestionnaireContentByDate?:  {
    __typename: "ModelRegularCustomerQuestionnaireContentConnection",
    items:  Array< {
      __typename: "RegularCustomerQuestionnaireContent",
      requestId: string,
      shopName: string,
      designerName: string,
      name: string,
      yearMonth: string,
      date: string,
      levelOfStyleChange: string,
      nextSchedule?: string | null,
      comment?: string | null,
      productsInterestedIn?: string | null,
      treatmentsInterestedIn?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      timestamp: number,
      modelVersion: number,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type RegularCustomerQuestionnaireContentByCustomerIdQueryVariables = {
  customerId: string,
  timestamp?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRegularCustomerQuestionnaireContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RegularCustomerQuestionnaireContentByCustomerIdQuery = {
  regularCustomerQuestionnaireContentByCustomerId?:  {
    __typename: "ModelRegularCustomerQuestionnaireContentConnection",
    items:  Array< {
      __typename: "RegularCustomerQuestionnaireContent",
      requestId: string,
      shopName: string,
      designerName: string,
      name: string,
      yearMonth: string,
      date: string,
      levelOfStyleChange: string,
      nextSchedule?: string | null,
      comment?: string | null,
      productsInterestedIn?: string | null,
      treatmentsInterestedIn?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      timestamp: number,
      modelVersion: number,
      customerId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserActivitySnapshotQueryVariables = {
  id: string,
};

export type GetUserActivitySnapshotQuery = {
  getUserActivitySnapshot?:  {
    __typename: "UserActivitySnapshot",
    id: string,
    owner?: string | null,
    dailyLogCount?: number | null,
    selfDiagnosisCount?: number | null,
    regularCustomerQuestionnaireCount?: number | null,
    caringCount?: number | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserActivitySnapshotsQueryVariables = {
  id?: string | null,
  filter?: ModelUserActivitySnapshotFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserActivitySnapshotsQuery = {
  listUserActivitySnapshots?:  {
    __typename: "ModelUserActivitySnapshotConnection",
    items:  Array< {
      __typename: "UserActivitySnapshot",
      id: string,
      owner?: string | null,
      dailyLogCount?: number | null,
      selfDiagnosisCount?: number | null,
      regularCustomerQuestionnaireCount?: number | null,
      caringCount?: number | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserActivitySnapshotsQueryVariables = {
  filter?: ModelUserActivitySnapshotFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserActivitySnapshotsQuery = {
  syncUserActivitySnapshots?:  {
    __typename: "ModelUserActivitySnapshotConnection",
    items:  Array< {
      __typename: "UserActivitySnapshot",
      id: string,
      owner?: string | null,
      dailyLogCount?: number | null,
      selfDiagnosisCount?: number | null,
      regularCustomerQuestionnaireCount?: number | null,
      caringCount?: number | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDailyLogQueryVariables = {
  id: string,
};

export type GetDailyLogQuery = {
  getDailyLog?:  {
    __typename: "DailyLog",
    id: string,
    owner?: string | null,
    shopName?: string | null,
    designerName?: string | null,
    yearMonth: string,
    date: string,
    timestamp: number,
    averageConsultationMinutes?: number | null,
    serviceRecommendationCount?: number | null,
    productRecommendationCount?: number | null,
    prepaidTicketRecommendationCount?: number | null,
    stretch?: boolean | null,
    drinkingWater?: boolean | null,
    comment?: string | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDailyLogsQueryVariables = {
  id?: string | null,
  filter?: ModelDailyLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDailyLogsQuery = {
  listDailyLogs?:  {
    __typename: "ModelDailyLogConnection",
    items:  Array< {
      __typename: "DailyLog",
      id: string,
      owner?: string | null,
      shopName?: string | null,
      designerName?: string | null,
      yearMonth: string,
      date: string,
      timestamp: number,
      averageConsultationMinutes?: number | null,
      serviceRecommendationCount?: number | null,
      productRecommendationCount?: number | null,
      prepaidTicketRecommendationCount?: number | null,
      stretch?: boolean | null,
      drinkingWater?: boolean | null,
      comment?: string | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDailyLogsQueryVariables = {
  filter?: ModelDailyLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDailyLogsQuery = {
  syncDailyLogs?:  {
    __typename: "ModelDailyLogConnection",
    items:  Array< {
      __typename: "DailyLog",
      id: string,
      owner?: string | null,
      shopName?: string | null,
      designerName?: string | null,
      yearMonth: string,
      date: string,
      timestamp: number,
      averageConsultationMinutes?: number | null,
      serviceRecommendationCount?: number | null,
      productRecommendationCount?: number | null,
      prepaidTicketRecommendationCount?: number | null,
      stretch?: boolean | null,
      drinkingWater?: boolean | null,
      comment?: string | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DailyLogByOwnerAndYearMonthQueryVariables = {
  owner: string,
  yearMonth?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDailyLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DailyLogByOwnerAndYearMonthQuery = {
  dailyLogByOwnerAndYearMonth?:  {
    __typename: "ModelDailyLogConnection",
    items:  Array< {
      __typename: "DailyLog",
      id: string,
      owner?: string | null,
      shopName?: string | null,
      designerName?: string | null,
      yearMonth: string,
      date: string,
      timestamp: number,
      averageConsultationMinutes?: number | null,
      serviceRecommendationCount?: number | null,
      productRecommendationCount?: number | null,
      prepaidTicketRecommendationCount?: number | null,
      stretch?: boolean | null,
      drinkingWater?: boolean | null,
      comment?: string | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DailyLogByOwnerAndTimestampQueryVariables = {
  owner: string,
  timestamp?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDailyLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DailyLogByOwnerAndTimestampQuery = {
  dailyLogByOwnerAndTimestamp?:  {
    __typename: "ModelDailyLogConnection",
    items:  Array< {
      __typename: "DailyLog",
      id: string,
      owner?: string | null,
      shopName?: string | null,
      designerName?: string | null,
      yearMonth: string,
      date: string,
      timestamp: number,
      averageConsultationMinutes?: number | null,
      serviceRecommendationCount?: number | null,
      productRecommendationCount?: number | null,
      prepaidTicketRecommendationCount?: number | null,
      stretch?: boolean | null,
      drinkingWater?: boolean | null,
      comment?: string | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DailyLogByYearMonthAndTimestampQueryVariables = {
  yearMonth: string,
  timestamp?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDailyLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DailyLogByYearMonthAndTimestampQuery = {
  dailyLogByYearMonthAndTimestamp?:  {
    __typename: "ModelDailyLogConnection",
    items:  Array< {
      __typename: "DailyLog",
      id: string,
      owner?: string | null,
      shopName?: string | null,
      designerName?: string | null,
      yearMonth: string,
      date: string,
      timestamp: number,
      averageConsultationMinutes?: number | null,
      serviceRecommendationCount?: number | null,
      productRecommendationCount?: number | null,
      prepaidTicketRecommendationCount?: number | null,
      stretch?: boolean | null,
      drinkingWater?: boolean | null,
      comment?: string | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DailyLogByDateQueryVariables = {
  date: string,
  timestamp?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDailyLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DailyLogByDateQuery = {
  dailyLogByDate?:  {
    __typename: "ModelDailyLogConnection",
    items:  Array< {
      __typename: "DailyLog",
      id: string,
      owner?: string | null,
      shopName?: string | null,
      designerName?: string | null,
      yearMonth: string,
      date: string,
      timestamp: number,
      averageConsultationMinutes?: number | null,
      serviceRecommendationCount?: number | null,
      productRecommendationCount?: number | null,
      prepaidTicketRecommendationCount?: number | null,
      stretch?: boolean | null,
      drinkingWater?: boolean | null,
      comment?: string | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSelfDiagnosisQueryVariables = {
  bookingId: string,
};

export type GetSelfDiagnosisQuery = {
  getSelfDiagnosis?:  {
    __typename: "SelfDiagnosis",
    bookingId: string,
    customerId: string,
    customerName: string,
    yearMonth: string,
    date: string,
    shopId: string,
    shopName: string,
    designerId: string,
    designerName: string,
    inflowChannel?: string | null,
    gender?: ConsultationGender | null,
    dry?: boolean | null,
    concerns?: Array< string > | null,
    frequencyOfVisits?: string | null,
    outfitStyles?: Array< string > | null,
    treatmentsInterestedIn?: Array< string > | null,
    productsForShampooing?: Array< string > | null,
    productsForStyling?: Array< string > | null,
    recentDyeing?: string | null,
    recentPerm?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    blackDyedHair?: boolean | null,
    bleachedHair?: boolean | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSelfDiagnosesQueryVariables = {
  bookingId?: string | null,
  filter?: ModelSelfDiagnosisFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSelfDiagnosesQuery = {
  listSelfDiagnoses?:  {
    __typename: "ModelSelfDiagnosisConnection",
    items:  Array< {
      __typename: "SelfDiagnosis",
      bookingId: string,
      customerId: string,
      customerName: string,
      yearMonth: string,
      date: string,
      shopId: string,
      shopName: string,
      designerId: string,
      designerName: string,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      recentDyeing?: string | null,
      recentPerm?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      blackDyedHair?: boolean | null,
      bleachedHair?: boolean | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSelfDiagnosesQueryVariables = {
  filter?: ModelSelfDiagnosisFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSelfDiagnosesQuery = {
  syncSelfDiagnoses?:  {
    __typename: "ModelSelfDiagnosisConnection",
    items:  Array< {
      __typename: "SelfDiagnosis",
      bookingId: string,
      customerId: string,
      customerName: string,
      yearMonth: string,
      date: string,
      shopId: string,
      shopName: string,
      designerId: string,
      designerName: string,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      recentDyeing?: string | null,
      recentPerm?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      blackDyedHair?: boolean | null,
      bleachedHair?: boolean | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SelfDiagnosisByCustomerIdQueryVariables = {
  customerId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSelfDiagnosisFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SelfDiagnosisByCustomerIdQuery = {
  selfDiagnosisByCustomerId?:  {
    __typename: "ModelSelfDiagnosisConnection",
    items:  Array< {
      __typename: "SelfDiagnosis",
      bookingId: string,
      customerId: string,
      customerName: string,
      yearMonth: string,
      date: string,
      shopId: string,
      shopName: string,
      designerId: string,
      designerName: string,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      recentDyeing?: string | null,
      recentPerm?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      blackDyedHair?: boolean | null,
      bleachedHair?: boolean | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SelfDiagnosisByYearMonthAndShopIdQueryVariables = {
  yearMonth: string,
  shopId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSelfDiagnosisFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SelfDiagnosisByYearMonthAndShopIdQuery = {
  selfDiagnosisByYearMonthAndShopId?:  {
    __typename: "ModelSelfDiagnosisConnection",
    items:  Array< {
      __typename: "SelfDiagnosis",
      bookingId: string,
      customerId: string,
      customerName: string,
      yearMonth: string,
      date: string,
      shopId: string,
      shopName: string,
      designerId: string,
      designerName: string,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      recentDyeing?: string | null,
      recentPerm?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      blackDyedHair?: boolean | null,
      bleachedHair?: boolean | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SelfDiagnosisByDateQueryVariables = {
  date: string,
  designerName?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSelfDiagnosisFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SelfDiagnosisByDateQuery = {
  selfDiagnosisByDate?:  {
    __typename: "ModelSelfDiagnosisConnection",
    items:  Array< {
      __typename: "SelfDiagnosis",
      bookingId: string,
      customerId: string,
      customerName: string,
      yearMonth: string,
      date: string,
      shopId: string,
      shopName: string,
      designerId: string,
      designerName: string,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      recentDyeing?: string | null,
      recentPerm?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      blackDyedHair?: boolean | null,
      bleachedHair?: boolean | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SelfDiagnosisByShopIdQueryVariables = {
  shopId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSelfDiagnosisFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SelfDiagnosisByShopIdQuery = {
  selfDiagnosisByShopId?:  {
    __typename: "ModelSelfDiagnosisConnection",
    items:  Array< {
      __typename: "SelfDiagnosis",
      bookingId: string,
      customerId: string,
      customerName: string,
      yearMonth: string,
      date: string,
      shopId: string,
      shopName: string,
      designerId: string,
      designerName: string,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      recentDyeing?: string | null,
      recentPerm?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      blackDyedHair?: boolean | null,
      bleachedHair?: boolean | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SelfDiagnosisByDesignerIdQueryVariables = {
  designerId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSelfDiagnosisFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SelfDiagnosisByDesignerIdQuery = {
  selfDiagnosisByDesignerId?:  {
    __typename: "ModelSelfDiagnosisConnection",
    items:  Array< {
      __typename: "SelfDiagnosis",
      bookingId: string,
      customerId: string,
      customerName: string,
      yearMonth: string,
      date: string,
      shopId: string,
      shopName: string,
      designerId: string,
      designerName: string,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      recentDyeing?: string | null,
      recentPerm?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      blackDyedHair?: boolean | null,
      bleachedHair?: boolean | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetConsultationQueryVariables = {
  id: string,
};

export type GetConsultationQuery = {
  getConsultation?:  {
    __typename: "Consultation",
    id: string,
    bookingId?: string | null,
    customer:  {
      __typename: "NestedCustomer",
      phoneNumber?: string | null,
      name?: string | null,
      id?: string | null,
      shopIds?: Array< string > | null,
    },
    customerId: string,
    name?: string | null,
    yearMonth?: string | null,
    date?: string | null,
    shopId: string,
    status: ConsultationStatus,
    selfDiagnosisStatus: ConsultationSelfDiagnosisStatus,
    mode?: ConsultationMode | null,
    inflowChannel?: string | null,
    gender?: ConsultationGender | null,
    dry?: boolean | null,
    concerns?: Array< string > | null,
    frequencyOfVisits?: string | null,
    outfitStyles?: Array< string > | null,
    treatmentsInterestedIn?: Array< string > | null,
    productsForShampooing?: Array< string > | null,
    productsForStyling?: Array< string > | null,
    neckLength?: string | null,
    neckThickness?: string | null,
    shoulderWidth?: string | null,
    shoulderShape?: string | null,
    hairColor?: string | null,
    virginHairColor?: string | null,
    colorfulnessOfHair?: string | null,
    colorfulnessesOfHair?: Array< string > | null,
    recentDyeing?: string | null,
    recentFirm?: string | null,
    damageDegreeOfHair?: string | null,
    amountOfHair?: string | null,
    thicknessOfHair?: string | null,
    curlDegreeOfHair?: string | null,
    faceShape?: string | null,
    faceShapes?: Array< string > | null,
    frontHeadShape?: string | null,
    sideHeadShape?: string | null,
    solution?:  {
      __typename: "Solution",
      face?: string | null,
      hair?: string | null,
      needs?: string | null,
      point?: string | null,
      aftercareTips?: string | null,
      stylingTips?: string | null,
      precautions?: string | null,
      recommendedTreatment?: string | null,
      nextVisitDate?: string | null,
      link?: string | null,
    } | null,
    solutionSentAt?: string | null,
    hairRecommendation?:  {
      __typename: "HairRecommendation",
      bangs?: string | null,
      hairLength?: string | null,
      hairVolume?: string | null,
      hairVolumes?: Array< string > | null,
      haircut?: string | null,
      haircuts?: Array< string > | null,
      hairColorMenu?: string | null,
      hairColorType?: string | null,
      hairFirm?: string | null,
      hairCare?: string | null,
    } | null,
    products?:  Array< {
      __typename: "SolutionProduct",
      title: string,
      url: string,
      imageFilePath: string,
      description?: string | null,
    } > | null,
    shopName?: string | null,
    designerName?: string | null,
    designerId?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListConsultationsQueryVariables = {
  filter?: ModelConsultationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConsultationsQuery = {
  listConsultations?:  {
    __typename: "ModelConsultationConnection",
    items:  Array< {
      __typename: "Consultation",
      id: string,
      bookingId?: string | null,
      customer:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      },
      customerId: string,
      name?: string | null,
      yearMonth?: string | null,
      date?: string | null,
      shopId: string,
      status: ConsultationStatus,
      selfDiagnosisStatus: ConsultationSelfDiagnosisStatus,
      mode?: ConsultationMode | null,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      neckLength?: string | null,
      neckThickness?: string | null,
      shoulderWidth?: string | null,
      shoulderShape?: string | null,
      hairColor?: string | null,
      virginHairColor?: string | null,
      colorfulnessOfHair?: string | null,
      colorfulnessesOfHair?: Array< string > | null,
      recentDyeing?: string | null,
      recentFirm?: string | null,
      damageDegreeOfHair?: string | null,
      amountOfHair?: string | null,
      thicknessOfHair?: string | null,
      curlDegreeOfHair?: string | null,
      faceShape?: string | null,
      faceShapes?: Array< string > | null,
      frontHeadShape?: string | null,
      sideHeadShape?: string | null,
      solution?:  {
        __typename: "Solution",
        face?: string | null,
        hair?: string | null,
        needs?: string | null,
        point?: string | null,
        aftercareTips?: string | null,
        stylingTips?: string | null,
        precautions?: string | null,
        recommendedTreatment?: string | null,
        nextVisitDate?: string | null,
        link?: string | null,
      } | null,
      solutionSentAt?: string | null,
      hairRecommendation?:  {
        __typename: "HairRecommendation",
        bangs?: string | null,
        hairLength?: string | null,
        hairVolume?: string | null,
        hairVolumes?: Array< string > | null,
        haircut?: string | null,
        haircuts?: Array< string > | null,
        hairColorMenu?: string | null,
        hairColorType?: string | null,
        hairFirm?: string | null,
        hairCare?: string | null,
      } | null,
      products?:  Array< {
        __typename: "SolutionProduct",
        title: string,
        url: string,
        imageFilePath: string,
        description?: string | null,
      } > | null,
      shopName?: string | null,
      designerName?: string | null,
      designerId?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncConsultationsQueryVariables = {
  filter?: ModelConsultationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncConsultationsQuery = {
  syncConsultations?:  {
    __typename: "ModelConsultationConnection",
    items:  Array< {
      __typename: "Consultation",
      id: string,
      bookingId?: string | null,
      customer:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      },
      customerId: string,
      name?: string | null,
      yearMonth?: string | null,
      date?: string | null,
      shopId: string,
      status: ConsultationStatus,
      selfDiagnosisStatus: ConsultationSelfDiagnosisStatus,
      mode?: ConsultationMode | null,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      neckLength?: string | null,
      neckThickness?: string | null,
      shoulderWidth?: string | null,
      shoulderShape?: string | null,
      hairColor?: string | null,
      virginHairColor?: string | null,
      colorfulnessOfHair?: string | null,
      colorfulnessesOfHair?: Array< string > | null,
      recentDyeing?: string | null,
      recentFirm?: string | null,
      damageDegreeOfHair?: string | null,
      amountOfHair?: string | null,
      thicknessOfHair?: string | null,
      curlDegreeOfHair?: string | null,
      faceShape?: string | null,
      faceShapes?: Array< string > | null,
      frontHeadShape?: string | null,
      sideHeadShape?: string | null,
      solution?:  {
        __typename: "Solution",
        face?: string | null,
        hair?: string | null,
        needs?: string | null,
        point?: string | null,
        aftercareTips?: string | null,
        stylingTips?: string | null,
        precautions?: string | null,
        recommendedTreatment?: string | null,
        nextVisitDate?: string | null,
        link?: string | null,
      } | null,
      solutionSentAt?: string | null,
      hairRecommendation?:  {
        __typename: "HairRecommendation",
        bangs?: string | null,
        hairLength?: string | null,
        hairVolume?: string | null,
        hairVolumes?: Array< string > | null,
        haircut?: string | null,
        haircuts?: Array< string > | null,
        hairColorMenu?: string | null,
        hairColorType?: string | null,
        hairFirm?: string | null,
        hairCare?: string | null,
      } | null,
      products?:  Array< {
        __typename: "SolutionProduct",
        title: string,
        url: string,
        imageFilePath: string,
        description?: string | null,
      } > | null,
      shopName?: string | null,
      designerName?: string | null,
      designerId?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ConsultationByBookingIdQueryVariables = {
  bookingId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConsultationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConsultationByBookingIdQuery = {
  consultationByBookingId?:  {
    __typename: "ModelConsultationConnection",
    items:  Array< {
      __typename: "Consultation",
      id: string,
      bookingId?: string | null,
      customer:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      },
      customerId: string,
      name?: string | null,
      yearMonth?: string | null,
      date?: string | null,
      shopId: string,
      status: ConsultationStatus,
      selfDiagnosisStatus: ConsultationSelfDiagnosisStatus,
      mode?: ConsultationMode | null,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      neckLength?: string | null,
      neckThickness?: string | null,
      shoulderWidth?: string | null,
      shoulderShape?: string | null,
      hairColor?: string | null,
      virginHairColor?: string | null,
      colorfulnessOfHair?: string | null,
      colorfulnessesOfHair?: Array< string > | null,
      recentDyeing?: string | null,
      recentFirm?: string | null,
      damageDegreeOfHair?: string | null,
      amountOfHair?: string | null,
      thicknessOfHair?: string | null,
      curlDegreeOfHair?: string | null,
      faceShape?: string | null,
      faceShapes?: Array< string > | null,
      frontHeadShape?: string | null,
      sideHeadShape?: string | null,
      solution?:  {
        __typename: "Solution",
        face?: string | null,
        hair?: string | null,
        needs?: string | null,
        point?: string | null,
        aftercareTips?: string | null,
        stylingTips?: string | null,
        precautions?: string | null,
        recommendedTreatment?: string | null,
        nextVisitDate?: string | null,
        link?: string | null,
      } | null,
      solutionSentAt?: string | null,
      hairRecommendation?:  {
        __typename: "HairRecommendation",
        bangs?: string | null,
        hairLength?: string | null,
        hairVolume?: string | null,
        hairVolumes?: Array< string > | null,
        haircut?: string | null,
        haircuts?: Array< string > | null,
        hairColorMenu?: string | null,
        hairColorType?: string | null,
        hairFirm?: string | null,
        hairCare?: string | null,
      } | null,
      products?:  Array< {
        __typename: "SolutionProduct",
        title: string,
        url: string,
        imageFilePath: string,
        description?: string | null,
      } > | null,
      shopName?: string | null,
      designerName?: string | null,
      designerId?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ConsultationByCustomerIdQueryVariables = {
  customerId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConsultationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConsultationByCustomerIdQuery = {
  consultationByCustomerId?:  {
    __typename: "ModelConsultationConnection",
    items:  Array< {
      __typename: "Consultation",
      id: string,
      bookingId?: string | null,
      customer:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      },
      customerId: string,
      name?: string | null,
      yearMonth?: string | null,
      date?: string | null,
      shopId: string,
      status: ConsultationStatus,
      selfDiagnosisStatus: ConsultationSelfDiagnosisStatus,
      mode?: ConsultationMode | null,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      neckLength?: string | null,
      neckThickness?: string | null,
      shoulderWidth?: string | null,
      shoulderShape?: string | null,
      hairColor?: string | null,
      virginHairColor?: string | null,
      colorfulnessOfHair?: string | null,
      colorfulnessesOfHair?: Array< string > | null,
      recentDyeing?: string | null,
      recentFirm?: string | null,
      damageDegreeOfHair?: string | null,
      amountOfHair?: string | null,
      thicknessOfHair?: string | null,
      curlDegreeOfHair?: string | null,
      faceShape?: string | null,
      faceShapes?: Array< string > | null,
      frontHeadShape?: string | null,
      sideHeadShape?: string | null,
      solution?:  {
        __typename: "Solution",
        face?: string | null,
        hair?: string | null,
        needs?: string | null,
        point?: string | null,
        aftercareTips?: string | null,
        stylingTips?: string | null,
        precautions?: string | null,
        recommendedTreatment?: string | null,
        nextVisitDate?: string | null,
        link?: string | null,
      } | null,
      solutionSentAt?: string | null,
      hairRecommendation?:  {
        __typename: "HairRecommendation",
        bangs?: string | null,
        hairLength?: string | null,
        hairVolume?: string | null,
        hairVolumes?: Array< string > | null,
        haircut?: string | null,
        haircuts?: Array< string > | null,
        hairColorMenu?: string | null,
        hairColorType?: string | null,
        hairFirm?: string | null,
        hairCare?: string | null,
      } | null,
      products?:  Array< {
        __typename: "SolutionProduct",
        title: string,
        url: string,
        imageFilePath: string,
        description?: string | null,
      } > | null,
      shopName?: string | null,
      designerName?: string | null,
      designerId?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ConsultationByNameQueryVariables = {
  name: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConsultationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConsultationByNameQuery = {
  consultationByName?:  {
    __typename: "ModelConsultationConnection",
    items:  Array< {
      __typename: "Consultation",
      id: string,
      bookingId?: string | null,
      customer:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      },
      customerId: string,
      name?: string | null,
      yearMonth?: string | null,
      date?: string | null,
      shopId: string,
      status: ConsultationStatus,
      selfDiagnosisStatus: ConsultationSelfDiagnosisStatus,
      mode?: ConsultationMode | null,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      neckLength?: string | null,
      neckThickness?: string | null,
      shoulderWidth?: string | null,
      shoulderShape?: string | null,
      hairColor?: string | null,
      virginHairColor?: string | null,
      colorfulnessOfHair?: string | null,
      colorfulnessesOfHair?: Array< string > | null,
      recentDyeing?: string | null,
      recentFirm?: string | null,
      damageDegreeOfHair?: string | null,
      amountOfHair?: string | null,
      thicknessOfHair?: string | null,
      curlDegreeOfHair?: string | null,
      faceShape?: string | null,
      faceShapes?: Array< string > | null,
      frontHeadShape?: string | null,
      sideHeadShape?: string | null,
      solution?:  {
        __typename: "Solution",
        face?: string | null,
        hair?: string | null,
        needs?: string | null,
        point?: string | null,
        aftercareTips?: string | null,
        stylingTips?: string | null,
        precautions?: string | null,
        recommendedTreatment?: string | null,
        nextVisitDate?: string | null,
        link?: string | null,
      } | null,
      solutionSentAt?: string | null,
      hairRecommendation?:  {
        __typename: "HairRecommendation",
        bangs?: string | null,
        hairLength?: string | null,
        hairVolume?: string | null,
        hairVolumes?: Array< string > | null,
        haircut?: string | null,
        haircuts?: Array< string > | null,
        hairColorMenu?: string | null,
        hairColorType?: string | null,
        hairFirm?: string | null,
        hairCare?: string | null,
      } | null,
      products?:  Array< {
        __typename: "SolutionProduct",
        title: string,
        url: string,
        imageFilePath: string,
        description?: string | null,
      } > | null,
      shopName?: string | null,
      designerName?: string | null,
      designerId?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ConsultationByYearMonthAndShopIdQueryVariables = {
  yearMonth: string,
  shopId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConsultationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConsultationByYearMonthAndShopIdQuery = {
  consultationByYearMonthAndShopId?:  {
    __typename: "ModelConsultationConnection",
    items:  Array< {
      __typename: "Consultation",
      id: string,
      bookingId?: string | null,
      customer:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      },
      customerId: string,
      name?: string | null,
      yearMonth?: string | null,
      date?: string | null,
      shopId: string,
      status: ConsultationStatus,
      selfDiagnosisStatus: ConsultationSelfDiagnosisStatus,
      mode?: ConsultationMode | null,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      neckLength?: string | null,
      neckThickness?: string | null,
      shoulderWidth?: string | null,
      shoulderShape?: string | null,
      hairColor?: string | null,
      virginHairColor?: string | null,
      colorfulnessOfHair?: string | null,
      colorfulnessesOfHair?: Array< string > | null,
      recentDyeing?: string | null,
      recentFirm?: string | null,
      damageDegreeOfHair?: string | null,
      amountOfHair?: string | null,
      thicknessOfHair?: string | null,
      curlDegreeOfHair?: string | null,
      faceShape?: string | null,
      faceShapes?: Array< string > | null,
      frontHeadShape?: string | null,
      sideHeadShape?: string | null,
      solution?:  {
        __typename: "Solution",
        face?: string | null,
        hair?: string | null,
        needs?: string | null,
        point?: string | null,
        aftercareTips?: string | null,
        stylingTips?: string | null,
        precautions?: string | null,
        recommendedTreatment?: string | null,
        nextVisitDate?: string | null,
        link?: string | null,
      } | null,
      solutionSentAt?: string | null,
      hairRecommendation?:  {
        __typename: "HairRecommendation",
        bangs?: string | null,
        hairLength?: string | null,
        hairVolume?: string | null,
        hairVolumes?: Array< string > | null,
        haircut?: string | null,
        haircuts?: Array< string > | null,
        hairColorMenu?: string | null,
        hairColorType?: string | null,
        hairFirm?: string | null,
        hairCare?: string | null,
      } | null,
      products?:  Array< {
        __typename: "SolutionProduct",
        title: string,
        url: string,
        imageFilePath: string,
        description?: string | null,
      } > | null,
      shopName?: string | null,
      designerName?: string | null,
      designerId?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ConsultationByYearMonthAndDesignerIdQueryVariables = {
  yearMonth: string,
  designerId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConsultationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConsultationByYearMonthAndDesignerIdQuery = {
  consultationByYearMonthAndDesignerId?:  {
    __typename: "ModelConsultationConnection",
    items:  Array< {
      __typename: "Consultation",
      id: string,
      bookingId?: string | null,
      customer:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      },
      customerId: string,
      name?: string | null,
      yearMonth?: string | null,
      date?: string | null,
      shopId: string,
      status: ConsultationStatus,
      selfDiagnosisStatus: ConsultationSelfDiagnosisStatus,
      mode?: ConsultationMode | null,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      neckLength?: string | null,
      neckThickness?: string | null,
      shoulderWidth?: string | null,
      shoulderShape?: string | null,
      hairColor?: string | null,
      virginHairColor?: string | null,
      colorfulnessOfHair?: string | null,
      colorfulnessesOfHair?: Array< string > | null,
      recentDyeing?: string | null,
      recentFirm?: string | null,
      damageDegreeOfHair?: string | null,
      amountOfHair?: string | null,
      thicknessOfHair?: string | null,
      curlDegreeOfHair?: string | null,
      faceShape?: string | null,
      faceShapes?: Array< string > | null,
      frontHeadShape?: string | null,
      sideHeadShape?: string | null,
      solution?:  {
        __typename: "Solution",
        face?: string | null,
        hair?: string | null,
        needs?: string | null,
        point?: string | null,
        aftercareTips?: string | null,
        stylingTips?: string | null,
        precautions?: string | null,
        recommendedTreatment?: string | null,
        nextVisitDate?: string | null,
        link?: string | null,
      } | null,
      solutionSentAt?: string | null,
      hairRecommendation?:  {
        __typename: "HairRecommendation",
        bangs?: string | null,
        hairLength?: string | null,
        hairVolume?: string | null,
        hairVolumes?: Array< string > | null,
        haircut?: string | null,
        haircuts?: Array< string > | null,
        hairColorMenu?: string | null,
        hairColorType?: string | null,
        hairFirm?: string | null,
        hairCare?: string | null,
      } | null,
      products?:  Array< {
        __typename: "SolutionProduct",
        title: string,
        url: string,
        imageFilePath: string,
        description?: string | null,
      } > | null,
      shopName?: string | null,
      designerName?: string | null,
      designerId?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ConsultationByDateQueryVariables = {
  date: string,
  designerName?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConsultationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConsultationByDateQuery = {
  consultationByDate?:  {
    __typename: "ModelConsultationConnection",
    items:  Array< {
      __typename: "Consultation",
      id: string,
      bookingId?: string | null,
      customer:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      },
      customerId: string,
      name?: string | null,
      yearMonth?: string | null,
      date?: string | null,
      shopId: string,
      status: ConsultationStatus,
      selfDiagnosisStatus: ConsultationSelfDiagnosisStatus,
      mode?: ConsultationMode | null,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      neckLength?: string | null,
      neckThickness?: string | null,
      shoulderWidth?: string | null,
      shoulderShape?: string | null,
      hairColor?: string | null,
      virginHairColor?: string | null,
      colorfulnessOfHair?: string | null,
      colorfulnessesOfHair?: Array< string > | null,
      recentDyeing?: string | null,
      recentFirm?: string | null,
      damageDegreeOfHair?: string | null,
      amountOfHair?: string | null,
      thicknessOfHair?: string | null,
      curlDegreeOfHair?: string | null,
      faceShape?: string | null,
      faceShapes?: Array< string > | null,
      frontHeadShape?: string | null,
      sideHeadShape?: string | null,
      solution?:  {
        __typename: "Solution",
        face?: string | null,
        hair?: string | null,
        needs?: string | null,
        point?: string | null,
        aftercareTips?: string | null,
        stylingTips?: string | null,
        precautions?: string | null,
        recommendedTreatment?: string | null,
        nextVisitDate?: string | null,
        link?: string | null,
      } | null,
      solutionSentAt?: string | null,
      hairRecommendation?:  {
        __typename: "HairRecommendation",
        bangs?: string | null,
        hairLength?: string | null,
        hairVolume?: string | null,
        hairVolumes?: Array< string > | null,
        haircut?: string | null,
        haircuts?: Array< string > | null,
        hairColorMenu?: string | null,
        hairColorType?: string | null,
        hairFirm?: string | null,
        hairCare?: string | null,
      } | null,
      products?:  Array< {
        __typename: "SolutionProduct",
        title: string,
        url: string,
        imageFilePath: string,
        description?: string | null,
      } > | null,
      shopName?: string | null,
      designerName?: string | null,
      designerId?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ConsultationByShopIdQueryVariables = {
  shopId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConsultationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConsultationByShopIdQuery = {
  consultationByShopId?:  {
    __typename: "ModelConsultationConnection",
    items:  Array< {
      __typename: "Consultation",
      id: string,
      bookingId?: string | null,
      customer:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      },
      customerId: string,
      name?: string | null,
      yearMonth?: string | null,
      date?: string | null,
      shopId: string,
      status: ConsultationStatus,
      selfDiagnosisStatus: ConsultationSelfDiagnosisStatus,
      mode?: ConsultationMode | null,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      neckLength?: string | null,
      neckThickness?: string | null,
      shoulderWidth?: string | null,
      shoulderShape?: string | null,
      hairColor?: string | null,
      virginHairColor?: string | null,
      colorfulnessOfHair?: string | null,
      colorfulnessesOfHair?: Array< string > | null,
      recentDyeing?: string | null,
      recentFirm?: string | null,
      damageDegreeOfHair?: string | null,
      amountOfHair?: string | null,
      thicknessOfHair?: string | null,
      curlDegreeOfHair?: string | null,
      faceShape?: string | null,
      faceShapes?: Array< string > | null,
      frontHeadShape?: string | null,
      sideHeadShape?: string | null,
      solution?:  {
        __typename: "Solution",
        face?: string | null,
        hair?: string | null,
        needs?: string | null,
        point?: string | null,
        aftercareTips?: string | null,
        stylingTips?: string | null,
        precautions?: string | null,
        recommendedTreatment?: string | null,
        nextVisitDate?: string | null,
        link?: string | null,
      } | null,
      solutionSentAt?: string | null,
      hairRecommendation?:  {
        __typename: "HairRecommendation",
        bangs?: string | null,
        hairLength?: string | null,
        hairVolume?: string | null,
        hairVolumes?: Array< string > | null,
        haircut?: string | null,
        haircuts?: Array< string > | null,
        hairColorMenu?: string | null,
        hairColorType?: string | null,
        hairFirm?: string | null,
        hairCare?: string | null,
      } | null,
      products?:  Array< {
        __typename: "SolutionProduct",
        title: string,
        url: string,
        imageFilePath: string,
        description?: string | null,
      } > | null,
      shopName?: string | null,
      designerName?: string | null,
      designerId?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ConsultationByDesignerIdQueryVariables = {
  designerId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConsultationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConsultationByDesignerIdQuery = {
  consultationByDesignerId?:  {
    __typename: "ModelConsultationConnection",
    items:  Array< {
      __typename: "Consultation",
      id: string,
      bookingId?: string | null,
      customer:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      },
      customerId: string,
      name?: string | null,
      yearMonth?: string | null,
      date?: string | null,
      shopId: string,
      status: ConsultationStatus,
      selfDiagnosisStatus: ConsultationSelfDiagnosisStatus,
      mode?: ConsultationMode | null,
      inflowChannel?: string | null,
      gender?: ConsultationGender | null,
      dry?: boolean | null,
      concerns?: Array< string > | null,
      frequencyOfVisits?: string | null,
      outfitStyles?: Array< string > | null,
      treatmentsInterestedIn?: Array< string > | null,
      productsForShampooing?: Array< string > | null,
      productsForStyling?: Array< string > | null,
      neckLength?: string | null,
      neckThickness?: string | null,
      shoulderWidth?: string | null,
      shoulderShape?: string | null,
      hairColor?: string | null,
      virginHairColor?: string | null,
      colorfulnessOfHair?: string | null,
      colorfulnessesOfHair?: Array< string > | null,
      recentDyeing?: string | null,
      recentFirm?: string | null,
      damageDegreeOfHair?: string | null,
      amountOfHair?: string | null,
      thicknessOfHair?: string | null,
      curlDegreeOfHair?: string | null,
      faceShape?: string | null,
      faceShapes?: Array< string > | null,
      frontHeadShape?: string | null,
      sideHeadShape?: string | null,
      solution?:  {
        __typename: "Solution",
        face?: string | null,
        hair?: string | null,
        needs?: string | null,
        point?: string | null,
        aftercareTips?: string | null,
        stylingTips?: string | null,
        precautions?: string | null,
        recommendedTreatment?: string | null,
        nextVisitDate?: string | null,
        link?: string | null,
      } | null,
      solutionSentAt?: string | null,
      hairRecommendation?:  {
        __typename: "HairRecommendation",
        bangs?: string | null,
        hairLength?: string | null,
        hairVolume?: string | null,
        hairVolumes?: Array< string > | null,
        haircut?: string | null,
        haircuts?: Array< string > | null,
        hairColorMenu?: string | null,
        hairColorType?: string | null,
        hairFirm?: string | null,
        hairCare?: string | null,
      } | null,
      products?:  Array< {
        __typename: "SolutionProduct",
        title: string,
        url: string,
        imageFilePath: string,
        description?: string | null,
      } > | null,
      shopName?: string | null,
      designerName?: string | null,
      designerId?: string | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      modelVersion?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAnonymousPhotoQueryVariables = {
  id: string,
};

export type GetAnonymousPhotoQuery = {
  getAnonymousPhoto?:  {
    __typename: "AnonymousPhoto",
    id: string,
    bookingId?: string | null,
    consultationId?: string | null,
    requestId?: string | null,
    s3Key: string,
    displayOrder: number,
    category: AnonymousPhotoCategory,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAnonymousPhotosQueryVariables = {
  filter?: ModelAnonymousPhotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAnonymousPhotosQuery = {
  listAnonymousPhotos?:  {
    __typename: "ModelAnonymousPhotoConnection",
    items:  Array< {
      __typename: "AnonymousPhoto",
      id: string,
      bookingId?: string | null,
      consultationId?: string | null,
      requestId?: string | null,
      s3Key: string,
      displayOrder: number,
      category: AnonymousPhotoCategory,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAnonymousPhotosQueryVariables = {
  filter?: ModelAnonymousPhotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAnonymousPhotosQuery = {
  syncAnonymousPhotos?:  {
    __typename: "ModelAnonymousPhotoConnection",
    items:  Array< {
      __typename: "AnonymousPhoto",
      id: string,
      bookingId?: string | null,
      consultationId?: string | null,
      requestId?: string | null,
      s3Key: string,
      displayOrder: number,
      category: AnonymousPhotoCategory,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AnonymousPhotoByBookingIdQueryVariables = {
  bookingId: string,
  displayOrder?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAnonymousPhotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AnonymousPhotoByBookingIdQuery = {
  anonymousPhotoByBookingId?:  {
    __typename: "ModelAnonymousPhotoConnection",
    items:  Array< {
      __typename: "AnonymousPhoto",
      id: string,
      bookingId?: string | null,
      consultationId?: string | null,
      requestId?: string | null,
      s3Key: string,
      displayOrder: number,
      category: AnonymousPhotoCategory,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AnonymousPhotoByConsultationIdQueryVariables = {
  consultationId: string,
  displayOrder?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAnonymousPhotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AnonymousPhotoByConsultationIdQuery = {
  anonymousPhotoByConsultationId?:  {
    __typename: "ModelAnonymousPhotoConnection",
    items:  Array< {
      __typename: "AnonymousPhoto",
      id: string,
      bookingId?: string | null,
      consultationId?: string | null,
      requestId?: string | null,
      s3Key: string,
      displayOrder: number,
      category: AnonymousPhotoCategory,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AnonymousPhotoByRequestIdQueryVariables = {
  requestId: string,
  displayOrder?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAnonymousPhotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AnonymousPhotoByRequestIdQuery = {
  anonymousPhotoByRequestId?:  {
    __typename: "ModelAnonymousPhotoConnection",
    items:  Array< {
      __typename: "AnonymousPhoto",
      id: string,
      bookingId?: string | null,
      consultationId?: string | null,
      requestId?: string | null,
      s3Key: string,
      displayOrder: number,
      category: AnonymousPhotoCategory,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPhotoQueryVariables = {
  id: string,
};

export type GetPhotoQuery = {
  getPhoto?:  {
    __typename: "Photo",
    id: string,
    bookingId?: string | null,
    s3Key: string,
    displayOrder: number,
    identityId: string,
    description?: string | null,
    category: PhotoCategory,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPhotosQueryVariables = {
  filter?: ModelPhotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPhotosQuery = {
  listPhotos?:  {
    __typename: "ModelPhotoConnection",
    items:  Array< {
      __typename: "Photo",
      id: string,
      bookingId?: string | null,
      s3Key: string,
      displayOrder: number,
      identityId: string,
      description?: string | null,
      category: PhotoCategory,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPhotosQueryVariables = {
  filter?: ModelPhotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPhotosQuery = {
  syncPhotos?:  {
    __typename: "ModelPhotoConnection",
    items:  Array< {
      __typename: "Photo",
      id: string,
      bookingId?: string | null,
      s3Key: string,
      displayOrder: number,
      identityId: string,
      description?: string | null,
      category: PhotoCategory,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PhotoByBookingIdQueryVariables = {
  bookingId: string,
  displayOrder?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPhotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PhotoByBookingIdQuery = {
  photoByBookingId?:  {
    __typename: "ModelPhotoConnection",
    items:  Array< {
      __typename: "Photo",
      id: string,
      bookingId?: string | null,
      s3Key: string,
      displayOrder: number,
      identityId: string,
      description?: string | null,
      category: PhotoCategory,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCheckInQueryVariables = {
  bookingId: string,
};

export type GetCheckInQuery = {
  getCheckIn?:  {
    __typename: "CheckIn",
    bookingId: string,
    shopId: string,
    checkInRequests?: Array< string > | null,
    drink?: string | null,
    modelVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCheckInsQueryVariables = {
  bookingId?: string | null,
  filter?: ModelCheckInFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCheckInsQuery = {
  listCheckIns?:  {
    __typename: "ModelCheckInConnection",
    items:  Array< {
      __typename: "CheckIn",
      bookingId: string,
      shopId: string,
      checkInRequests?: Array< string > | null,
      drink?: string | null,
      modelVersion: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCheckInsQueryVariables = {
  filter?: ModelCheckInFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCheckInsQuery = {
  syncCheckIns?:  {
    __typename: "ModelCheckInConnection",
    items:  Array< {
      __typename: "CheckIn",
      bookingId: string,
      shopId: string,
      checkInRequests?: Array< string > | null,
      drink?: string | null,
      modelVersion: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CheckInByShopIdQueryVariables = {
  shopId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCheckInFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CheckInByShopIdQuery = {
  checkInByShopId?:  {
    __typename: "ModelCheckInConnection",
    items:  Array< {
      __typename: "CheckIn",
      bookingId: string,
      shopId: string,
      checkInRequests?: Array< string > | null,
      drink?: string | null,
      modelVersion: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBookingQueryVariables = {
  id: string,
};

export type GetBookingQuery = {
  getBooking?:  {
    __typename: "Booking",
    id: string,
    yearMonth?: string | null,
    date: string,
    time: string,
    customer?:  {
      __typename: "NestedCustomer",
      phoneNumber?: string | null,
      name?: string | null,
      id?: string | null,
      shopIds?: Array< string > | null,
    } | null,
    customerId: string,
    name?: string | null,
    phoneNumber?: string | null,
    consultationId?: string | null,
    consultation?:  {
      __typename: "NestedConsultation",
      status?: ConsultationStatus | null,
      selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
      solutionSentAt?: string | null,
      inflowChannel?: string | null,
      hasPhotos?: boolean | null,
    } | null,
    hasSelfDiagnosis?: boolean | null,
    hasRegularCustomerNote?: boolean | null,
    designerName?: string | null,
    designerId?: string | null,
    optionName?: string | null,
    shopId: string,
    shopName?: string | null,
    naverBookingNumber?: string | null,
    bookingStatus?: BookingStatus | null,
    serviceStatus?: ServiceStatus | null,
    paymentStatus?: PaymentStatus | null,
    requestMessage?: string | null,
    designerMemo?: string | null,
    naverSnapshot?:  {
      __typename: "NaverSnapshot",
      price: number,
      bizItemPrice: number,
      onsitePrice?: number | null,
      email?: string | null,
      isOnsitePayment?: boolean | null,
    } | null,
    naverOptions?:  Array< {
      __typename: "NaverServiceOption",
      categoryName: string,
      name: string,
      originalName?: string | null,
      desc?: string | null,
      price?: number | null,
    } > | null,
    naverPayments?:  Array< {
      __typename: "NaverPayment",
      method?: NaverPaymentMethod | null,
      moment?: NaverPaymentMoment | null,
      provider?: NaverPaymentProvider | null,
      paymentId: number,
      bookingId: number,
      amount: number,
      providerDiscountAmount?: number | null,
      regDateTime?: string | null,
      status: NaverPaymentStatus,
      statusHistories?:  Array< {
        __typename: "NaverPaymentStatusHistory",
        status: NaverPaymentStatus,
        dateTime?: string | null,
      } > | null,
    } > | null,
    initialPrice?: number | null,
    finalPrice?: number | null,
    totalServiceSales?: number | null,
    totalProductSales?: number | null,
    initialDeposit?: number | null,
    paidAmount?: number | null,
    paidInCashAmount?: number | null,
    paidByCardAmount?: number | null,
    paidByPrepaidPassAmount?: number | null,
    paidByNaverPayAmount?: number | null,
    paidAt?: string | null,
    paymentMemo?: string | null,
    checkInRequests?: Array< string > | null,
    drink?: string | null,
    checkedInAt?: string | null,
    origin?: BookingOrigin | null,
    options?:  Array< {
      __typename: "BookingServiceOption",
      categoryName: string,
      name: string,
      originalPrice: number,
      discount?: number | null,
      discountRate?: number | null,
      price: number,
      discountPreset?:  {
        __typename: "DiscountPresetSnapshot",
        id?: string | null,
        shopId?: string | null,
        name?: string | null,
        amount?: number | null,
        rate?: number | null,
        type?: DiscountMode | null,
      } | null,
    } > | null,
    productOptions?:  Array< {
      __typename: "BookingProductOption",
      name: string,
      price: number,
      quantity: number,
      product?:  {
        __typename: "ProductSnapshot",
        id?: string | null,
        name?: string | null,
        shopId?: string | null,
        brandId?: string | null,
        brandName?: string | null,
        costPrice?: number | null,
        sellingPrice?: number | null,
      } | null,
    } > | null,
    signature?: string | null,
    signedAt?: string | null,
    recentNaverDataReflection?: string | null,
    selfDiagnosisSentAt?: string | null,
    feedbackRequestSentAt?: string | null,
    hasFeedback?: boolean | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    hasDesignCompletionPhotos?: boolean | null,
    designCompletionPhotos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    signatureOfPortraitRights?: string | null,
    signedAtOfPortraitRights?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListBookingsQueryVariables = {
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBookingsQuery = {
  listBookings?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      yearMonth?: string | null,
      date: string,
      time: string,
      customer?:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      } | null,
      customerId: string,
      name?: string | null,
      phoneNumber?: string | null,
      consultationId?: string | null,
      consultation?:  {
        __typename: "NestedConsultation",
        status?: ConsultationStatus | null,
        selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
        solutionSentAt?: string | null,
        inflowChannel?: string | null,
        hasPhotos?: boolean | null,
      } | null,
      hasSelfDiagnosis?: boolean | null,
      hasRegularCustomerNote?: boolean | null,
      designerName?: string | null,
      designerId?: string | null,
      optionName?: string | null,
      shopId: string,
      shopName?: string | null,
      naverBookingNumber?: string | null,
      bookingStatus?: BookingStatus | null,
      serviceStatus?: ServiceStatus | null,
      paymentStatus?: PaymentStatus | null,
      requestMessage?: string | null,
      designerMemo?: string | null,
      naverSnapshot?:  {
        __typename: "NaverSnapshot",
        price: number,
        bizItemPrice: number,
        onsitePrice?: number | null,
        email?: string | null,
        isOnsitePayment?: boolean | null,
      } | null,
      naverOptions?:  Array< {
        __typename: "NaverServiceOption",
        categoryName: string,
        name: string,
        originalName?: string | null,
        desc?: string | null,
        price?: number | null,
      } > | null,
      naverPayments?:  Array< {
        __typename: "NaverPayment",
        method?: NaverPaymentMethod | null,
        moment?: NaverPaymentMoment | null,
        provider?: NaverPaymentProvider | null,
        paymentId: number,
        bookingId: number,
        amount: number,
        providerDiscountAmount?: number | null,
        regDateTime?: string | null,
        status: NaverPaymentStatus,
        statusHistories?:  Array< {
          __typename: "NaverPaymentStatusHistory",
          status: NaverPaymentStatus,
          dateTime?: string | null,
        } > | null,
      } > | null,
      initialPrice?: number | null,
      finalPrice?: number | null,
      totalServiceSales?: number | null,
      totalProductSales?: number | null,
      initialDeposit?: number | null,
      paidAmount?: number | null,
      paidInCashAmount?: number | null,
      paidByCardAmount?: number | null,
      paidByPrepaidPassAmount?: number | null,
      paidByNaverPayAmount?: number | null,
      paidAt?: string | null,
      paymentMemo?: string | null,
      checkInRequests?: Array< string > | null,
      drink?: string | null,
      checkedInAt?: string | null,
      origin?: BookingOrigin | null,
      options?:  Array< {
        __typename: "BookingServiceOption",
        categoryName: string,
        name: string,
        originalPrice: number,
        discount?: number | null,
        discountRate?: number | null,
        price: number,
        discountPreset?:  {
          __typename: "DiscountPresetSnapshot",
          id?: string | null,
          shopId?: string | null,
          name?: string | null,
          amount?: number | null,
          rate?: number | null,
          type?: DiscountMode | null,
        } | null,
      } > | null,
      productOptions?:  Array< {
        __typename: "BookingProductOption",
        name: string,
        price: number,
        quantity: number,
        product?:  {
          __typename: "ProductSnapshot",
          id?: string | null,
          name?: string | null,
          shopId?: string | null,
          brandId?: string | null,
          brandName?: string | null,
          costPrice?: number | null,
          sellingPrice?: number | null,
        } | null,
      } > | null,
      signature?: string | null,
      signedAt?: string | null,
      recentNaverDataReflection?: string | null,
      selfDiagnosisSentAt?: string | null,
      feedbackRequestSentAt?: string | null,
      hasFeedback?: boolean | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      hasDesignCompletionPhotos?: boolean | null,
      designCompletionPhotos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      signatureOfPortraitRights?: string | null,
      signedAtOfPortraitRights?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBookingsQueryVariables = {
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBookingsQuery = {
  syncBookings?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      yearMonth?: string | null,
      date: string,
      time: string,
      customer?:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      } | null,
      customerId: string,
      name?: string | null,
      phoneNumber?: string | null,
      consultationId?: string | null,
      consultation?:  {
        __typename: "NestedConsultation",
        status?: ConsultationStatus | null,
        selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
        solutionSentAt?: string | null,
        inflowChannel?: string | null,
        hasPhotos?: boolean | null,
      } | null,
      hasSelfDiagnosis?: boolean | null,
      hasRegularCustomerNote?: boolean | null,
      designerName?: string | null,
      designerId?: string | null,
      optionName?: string | null,
      shopId: string,
      shopName?: string | null,
      naverBookingNumber?: string | null,
      bookingStatus?: BookingStatus | null,
      serviceStatus?: ServiceStatus | null,
      paymentStatus?: PaymentStatus | null,
      requestMessage?: string | null,
      designerMemo?: string | null,
      naverSnapshot?:  {
        __typename: "NaverSnapshot",
        price: number,
        bizItemPrice: number,
        onsitePrice?: number | null,
        email?: string | null,
        isOnsitePayment?: boolean | null,
      } | null,
      naverOptions?:  Array< {
        __typename: "NaverServiceOption",
        categoryName: string,
        name: string,
        originalName?: string | null,
        desc?: string | null,
        price?: number | null,
      } > | null,
      naverPayments?:  Array< {
        __typename: "NaverPayment",
        method?: NaverPaymentMethod | null,
        moment?: NaverPaymentMoment | null,
        provider?: NaverPaymentProvider | null,
        paymentId: number,
        bookingId: number,
        amount: number,
        providerDiscountAmount?: number | null,
        regDateTime?: string | null,
        status: NaverPaymentStatus,
        statusHistories?:  Array< {
          __typename: "NaverPaymentStatusHistory",
          status: NaverPaymentStatus,
          dateTime?: string | null,
        } > | null,
      } > | null,
      initialPrice?: number | null,
      finalPrice?: number | null,
      totalServiceSales?: number | null,
      totalProductSales?: number | null,
      initialDeposit?: number | null,
      paidAmount?: number | null,
      paidInCashAmount?: number | null,
      paidByCardAmount?: number | null,
      paidByPrepaidPassAmount?: number | null,
      paidByNaverPayAmount?: number | null,
      paidAt?: string | null,
      paymentMemo?: string | null,
      checkInRequests?: Array< string > | null,
      drink?: string | null,
      checkedInAt?: string | null,
      origin?: BookingOrigin | null,
      options?:  Array< {
        __typename: "BookingServiceOption",
        categoryName: string,
        name: string,
        originalPrice: number,
        discount?: number | null,
        discountRate?: number | null,
        price: number,
        discountPreset?:  {
          __typename: "DiscountPresetSnapshot",
          id?: string | null,
          shopId?: string | null,
          name?: string | null,
          amount?: number | null,
          rate?: number | null,
          type?: DiscountMode | null,
        } | null,
      } > | null,
      productOptions?:  Array< {
        __typename: "BookingProductOption",
        name: string,
        price: number,
        quantity: number,
        product?:  {
          __typename: "ProductSnapshot",
          id?: string | null,
          name?: string | null,
          shopId?: string | null,
          brandId?: string | null,
          brandName?: string | null,
          costPrice?: number | null,
          sellingPrice?: number | null,
        } | null,
      } > | null,
      signature?: string | null,
      signedAt?: string | null,
      recentNaverDataReflection?: string | null,
      selfDiagnosisSentAt?: string | null,
      feedbackRequestSentAt?: string | null,
      hasFeedback?: boolean | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      hasDesignCompletionPhotos?: boolean | null,
      designCompletionPhotos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      signatureOfPortraitRights?: string | null,
      signedAtOfPortraitRights?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BookingByYearMonthQueryVariables = {
  yearMonth: string,
  shopId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingByYearMonthQuery = {
  bookingByYearMonth?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      yearMonth?: string | null,
      date: string,
      time: string,
      customer?:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      } | null,
      customerId: string,
      name?: string | null,
      phoneNumber?: string | null,
      consultationId?: string | null,
      consultation?:  {
        __typename: "NestedConsultation",
        status?: ConsultationStatus | null,
        selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
        solutionSentAt?: string | null,
        inflowChannel?: string | null,
        hasPhotos?: boolean | null,
      } | null,
      hasSelfDiagnosis?: boolean | null,
      hasRegularCustomerNote?: boolean | null,
      designerName?: string | null,
      designerId?: string | null,
      optionName?: string | null,
      shopId: string,
      shopName?: string | null,
      naverBookingNumber?: string | null,
      bookingStatus?: BookingStatus | null,
      serviceStatus?: ServiceStatus | null,
      paymentStatus?: PaymentStatus | null,
      requestMessage?: string | null,
      designerMemo?: string | null,
      naverSnapshot?:  {
        __typename: "NaverSnapshot",
        price: number,
        bizItemPrice: number,
        onsitePrice?: number | null,
        email?: string | null,
        isOnsitePayment?: boolean | null,
      } | null,
      naverOptions?:  Array< {
        __typename: "NaverServiceOption",
        categoryName: string,
        name: string,
        originalName?: string | null,
        desc?: string | null,
        price?: number | null,
      } > | null,
      naverPayments?:  Array< {
        __typename: "NaverPayment",
        method?: NaverPaymentMethod | null,
        moment?: NaverPaymentMoment | null,
        provider?: NaverPaymentProvider | null,
        paymentId: number,
        bookingId: number,
        amount: number,
        providerDiscountAmount?: number | null,
        regDateTime?: string | null,
        status: NaverPaymentStatus,
        statusHistories?:  Array< {
          __typename: "NaverPaymentStatusHistory",
          status: NaverPaymentStatus,
          dateTime?: string | null,
        } > | null,
      } > | null,
      initialPrice?: number | null,
      finalPrice?: number | null,
      totalServiceSales?: number | null,
      totalProductSales?: number | null,
      initialDeposit?: number | null,
      paidAmount?: number | null,
      paidInCashAmount?: number | null,
      paidByCardAmount?: number | null,
      paidByPrepaidPassAmount?: number | null,
      paidByNaverPayAmount?: number | null,
      paidAt?: string | null,
      paymentMemo?: string | null,
      checkInRequests?: Array< string > | null,
      drink?: string | null,
      checkedInAt?: string | null,
      origin?: BookingOrigin | null,
      options?:  Array< {
        __typename: "BookingServiceOption",
        categoryName: string,
        name: string,
        originalPrice: number,
        discount?: number | null,
        discountRate?: number | null,
        price: number,
        discountPreset?:  {
          __typename: "DiscountPresetSnapshot",
          id?: string | null,
          shopId?: string | null,
          name?: string | null,
          amount?: number | null,
          rate?: number | null,
          type?: DiscountMode | null,
        } | null,
      } > | null,
      productOptions?:  Array< {
        __typename: "BookingProductOption",
        name: string,
        price: number,
        quantity: number,
        product?:  {
          __typename: "ProductSnapshot",
          id?: string | null,
          name?: string | null,
          shopId?: string | null,
          brandId?: string | null,
          brandName?: string | null,
          costPrice?: number | null,
          sellingPrice?: number | null,
        } | null,
      } > | null,
      signature?: string | null,
      signedAt?: string | null,
      recentNaverDataReflection?: string | null,
      selfDiagnosisSentAt?: string | null,
      feedbackRequestSentAt?: string | null,
      hasFeedback?: boolean | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      hasDesignCompletionPhotos?: boolean | null,
      designCompletionPhotos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      signatureOfPortraitRights?: string | null,
      signedAtOfPortraitRights?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BookingByDateQueryVariables = {
  date: string,
  time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingByDateQuery = {
  bookingByDate?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      yearMonth?: string | null,
      date: string,
      time: string,
      customer?:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      } | null,
      customerId: string,
      name?: string | null,
      phoneNumber?: string | null,
      consultationId?: string | null,
      consultation?:  {
        __typename: "NestedConsultation",
        status?: ConsultationStatus | null,
        selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
        solutionSentAt?: string | null,
        inflowChannel?: string | null,
        hasPhotos?: boolean | null,
      } | null,
      hasSelfDiagnosis?: boolean | null,
      hasRegularCustomerNote?: boolean | null,
      designerName?: string | null,
      designerId?: string | null,
      optionName?: string | null,
      shopId: string,
      shopName?: string | null,
      naverBookingNumber?: string | null,
      bookingStatus?: BookingStatus | null,
      serviceStatus?: ServiceStatus | null,
      paymentStatus?: PaymentStatus | null,
      requestMessage?: string | null,
      designerMemo?: string | null,
      naverSnapshot?:  {
        __typename: "NaverSnapshot",
        price: number,
        bizItemPrice: number,
        onsitePrice?: number | null,
        email?: string | null,
        isOnsitePayment?: boolean | null,
      } | null,
      naverOptions?:  Array< {
        __typename: "NaverServiceOption",
        categoryName: string,
        name: string,
        originalName?: string | null,
        desc?: string | null,
        price?: number | null,
      } > | null,
      naverPayments?:  Array< {
        __typename: "NaverPayment",
        method?: NaverPaymentMethod | null,
        moment?: NaverPaymentMoment | null,
        provider?: NaverPaymentProvider | null,
        paymentId: number,
        bookingId: number,
        amount: number,
        providerDiscountAmount?: number | null,
        regDateTime?: string | null,
        status: NaverPaymentStatus,
        statusHistories?:  Array< {
          __typename: "NaverPaymentStatusHistory",
          status: NaverPaymentStatus,
          dateTime?: string | null,
        } > | null,
      } > | null,
      initialPrice?: number | null,
      finalPrice?: number | null,
      totalServiceSales?: number | null,
      totalProductSales?: number | null,
      initialDeposit?: number | null,
      paidAmount?: number | null,
      paidInCashAmount?: number | null,
      paidByCardAmount?: number | null,
      paidByPrepaidPassAmount?: number | null,
      paidByNaverPayAmount?: number | null,
      paidAt?: string | null,
      paymentMemo?: string | null,
      checkInRequests?: Array< string > | null,
      drink?: string | null,
      checkedInAt?: string | null,
      origin?: BookingOrigin | null,
      options?:  Array< {
        __typename: "BookingServiceOption",
        categoryName: string,
        name: string,
        originalPrice: number,
        discount?: number | null,
        discountRate?: number | null,
        price: number,
        discountPreset?:  {
          __typename: "DiscountPresetSnapshot",
          id?: string | null,
          shopId?: string | null,
          name?: string | null,
          amount?: number | null,
          rate?: number | null,
          type?: DiscountMode | null,
        } | null,
      } > | null,
      productOptions?:  Array< {
        __typename: "BookingProductOption",
        name: string,
        price: number,
        quantity: number,
        product?:  {
          __typename: "ProductSnapshot",
          id?: string | null,
          name?: string | null,
          shopId?: string | null,
          brandId?: string | null,
          brandName?: string | null,
          costPrice?: number | null,
          sellingPrice?: number | null,
        } | null,
      } > | null,
      signature?: string | null,
      signedAt?: string | null,
      recentNaverDataReflection?: string | null,
      selfDiagnosisSentAt?: string | null,
      feedbackRequestSentAt?: string | null,
      hasFeedback?: boolean | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      hasDesignCompletionPhotos?: boolean | null,
      designCompletionPhotos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      signatureOfPortraitRights?: string | null,
      signedAtOfPortraitRights?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BookingByCustomerIdQueryVariables = {
  customerId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingByCustomerIdQuery = {
  bookingByCustomerId?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      yearMonth?: string | null,
      date: string,
      time: string,
      customer?:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      } | null,
      customerId: string,
      name?: string | null,
      phoneNumber?: string | null,
      consultationId?: string | null,
      consultation?:  {
        __typename: "NestedConsultation",
        status?: ConsultationStatus | null,
        selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
        solutionSentAt?: string | null,
        inflowChannel?: string | null,
        hasPhotos?: boolean | null,
      } | null,
      hasSelfDiagnosis?: boolean | null,
      hasRegularCustomerNote?: boolean | null,
      designerName?: string | null,
      designerId?: string | null,
      optionName?: string | null,
      shopId: string,
      shopName?: string | null,
      naverBookingNumber?: string | null,
      bookingStatus?: BookingStatus | null,
      serviceStatus?: ServiceStatus | null,
      paymentStatus?: PaymentStatus | null,
      requestMessage?: string | null,
      designerMemo?: string | null,
      naverSnapshot?:  {
        __typename: "NaverSnapshot",
        price: number,
        bizItemPrice: number,
        onsitePrice?: number | null,
        email?: string | null,
        isOnsitePayment?: boolean | null,
      } | null,
      naverOptions?:  Array< {
        __typename: "NaverServiceOption",
        categoryName: string,
        name: string,
        originalName?: string | null,
        desc?: string | null,
        price?: number | null,
      } > | null,
      naverPayments?:  Array< {
        __typename: "NaverPayment",
        method?: NaverPaymentMethod | null,
        moment?: NaverPaymentMoment | null,
        provider?: NaverPaymentProvider | null,
        paymentId: number,
        bookingId: number,
        amount: number,
        providerDiscountAmount?: number | null,
        regDateTime?: string | null,
        status: NaverPaymentStatus,
        statusHistories?:  Array< {
          __typename: "NaverPaymentStatusHistory",
          status: NaverPaymentStatus,
          dateTime?: string | null,
        } > | null,
      } > | null,
      initialPrice?: number | null,
      finalPrice?: number | null,
      totalServiceSales?: number | null,
      totalProductSales?: number | null,
      initialDeposit?: number | null,
      paidAmount?: number | null,
      paidInCashAmount?: number | null,
      paidByCardAmount?: number | null,
      paidByPrepaidPassAmount?: number | null,
      paidByNaverPayAmount?: number | null,
      paidAt?: string | null,
      paymentMemo?: string | null,
      checkInRequests?: Array< string > | null,
      drink?: string | null,
      checkedInAt?: string | null,
      origin?: BookingOrigin | null,
      options?:  Array< {
        __typename: "BookingServiceOption",
        categoryName: string,
        name: string,
        originalPrice: number,
        discount?: number | null,
        discountRate?: number | null,
        price: number,
        discountPreset?:  {
          __typename: "DiscountPresetSnapshot",
          id?: string | null,
          shopId?: string | null,
          name?: string | null,
          amount?: number | null,
          rate?: number | null,
          type?: DiscountMode | null,
        } | null,
      } > | null,
      productOptions?:  Array< {
        __typename: "BookingProductOption",
        name: string,
        price: number,
        quantity: number,
        product?:  {
          __typename: "ProductSnapshot",
          id?: string | null,
          name?: string | null,
          shopId?: string | null,
          brandId?: string | null,
          brandName?: string | null,
          costPrice?: number | null,
          sellingPrice?: number | null,
        } | null,
      } > | null,
      signature?: string | null,
      signedAt?: string | null,
      recentNaverDataReflection?: string | null,
      selfDiagnosisSentAt?: string | null,
      feedbackRequestSentAt?: string | null,
      hasFeedback?: boolean | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      hasDesignCompletionPhotos?: boolean | null,
      designCompletionPhotos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      signatureOfPortraitRights?: string | null,
      signedAtOfPortraitRights?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BookingByNameQueryVariables = {
  name: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingByNameQuery = {
  bookingByName?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      yearMonth?: string | null,
      date: string,
      time: string,
      customer?:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      } | null,
      customerId: string,
      name?: string | null,
      phoneNumber?: string | null,
      consultationId?: string | null,
      consultation?:  {
        __typename: "NestedConsultation",
        status?: ConsultationStatus | null,
        selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
        solutionSentAt?: string | null,
        inflowChannel?: string | null,
        hasPhotos?: boolean | null,
      } | null,
      hasSelfDiagnosis?: boolean | null,
      hasRegularCustomerNote?: boolean | null,
      designerName?: string | null,
      designerId?: string | null,
      optionName?: string | null,
      shopId: string,
      shopName?: string | null,
      naverBookingNumber?: string | null,
      bookingStatus?: BookingStatus | null,
      serviceStatus?: ServiceStatus | null,
      paymentStatus?: PaymentStatus | null,
      requestMessage?: string | null,
      designerMemo?: string | null,
      naverSnapshot?:  {
        __typename: "NaverSnapshot",
        price: number,
        bizItemPrice: number,
        onsitePrice?: number | null,
        email?: string | null,
        isOnsitePayment?: boolean | null,
      } | null,
      naverOptions?:  Array< {
        __typename: "NaverServiceOption",
        categoryName: string,
        name: string,
        originalName?: string | null,
        desc?: string | null,
        price?: number | null,
      } > | null,
      naverPayments?:  Array< {
        __typename: "NaverPayment",
        method?: NaverPaymentMethod | null,
        moment?: NaverPaymentMoment | null,
        provider?: NaverPaymentProvider | null,
        paymentId: number,
        bookingId: number,
        amount: number,
        providerDiscountAmount?: number | null,
        regDateTime?: string | null,
        status: NaverPaymentStatus,
        statusHistories?:  Array< {
          __typename: "NaverPaymentStatusHistory",
          status: NaverPaymentStatus,
          dateTime?: string | null,
        } > | null,
      } > | null,
      initialPrice?: number | null,
      finalPrice?: number | null,
      totalServiceSales?: number | null,
      totalProductSales?: number | null,
      initialDeposit?: number | null,
      paidAmount?: number | null,
      paidInCashAmount?: number | null,
      paidByCardAmount?: number | null,
      paidByPrepaidPassAmount?: number | null,
      paidByNaverPayAmount?: number | null,
      paidAt?: string | null,
      paymentMemo?: string | null,
      checkInRequests?: Array< string > | null,
      drink?: string | null,
      checkedInAt?: string | null,
      origin?: BookingOrigin | null,
      options?:  Array< {
        __typename: "BookingServiceOption",
        categoryName: string,
        name: string,
        originalPrice: number,
        discount?: number | null,
        discountRate?: number | null,
        price: number,
        discountPreset?:  {
          __typename: "DiscountPresetSnapshot",
          id?: string | null,
          shopId?: string | null,
          name?: string | null,
          amount?: number | null,
          rate?: number | null,
          type?: DiscountMode | null,
        } | null,
      } > | null,
      productOptions?:  Array< {
        __typename: "BookingProductOption",
        name: string,
        price: number,
        quantity: number,
        product?:  {
          __typename: "ProductSnapshot",
          id?: string | null,
          name?: string | null,
          shopId?: string | null,
          brandId?: string | null,
          brandName?: string | null,
          costPrice?: number | null,
          sellingPrice?: number | null,
        } | null,
      } > | null,
      signature?: string | null,
      signedAt?: string | null,
      recentNaverDataReflection?: string | null,
      selfDiagnosisSentAt?: string | null,
      feedbackRequestSentAt?: string | null,
      hasFeedback?: boolean | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      hasDesignCompletionPhotos?: boolean | null,
      designCompletionPhotos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      signatureOfPortraitRights?: string | null,
      signedAtOfPortraitRights?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BookingByPhoneNumberQueryVariables = {
  phoneNumber: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingByPhoneNumberQuery = {
  bookingByPhoneNumber?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      yearMonth?: string | null,
      date: string,
      time: string,
      customer?:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      } | null,
      customerId: string,
      name?: string | null,
      phoneNumber?: string | null,
      consultationId?: string | null,
      consultation?:  {
        __typename: "NestedConsultation",
        status?: ConsultationStatus | null,
        selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
        solutionSentAt?: string | null,
        inflowChannel?: string | null,
        hasPhotos?: boolean | null,
      } | null,
      hasSelfDiagnosis?: boolean | null,
      hasRegularCustomerNote?: boolean | null,
      designerName?: string | null,
      designerId?: string | null,
      optionName?: string | null,
      shopId: string,
      shopName?: string | null,
      naverBookingNumber?: string | null,
      bookingStatus?: BookingStatus | null,
      serviceStatus?: ServiceStatus | null,
      paymentStatus?: PaymentStatus | null,
      requestMessage?: string | null,
      designerMemo?: string | null,
      naverSnapshot?:  {
        __typename: "NaverSnapshot",
        price: number,
        bizItemPrice: number,
        onsitePrice?: number | null,
        email?: string | null,
        isOnsitePayment?: boolean | null,
      } | null,
      naverOptions?:  Array< {
        __typename: "NaverServiceOption",
        categoryName: string,
        name: string,
        originalName?: string | null,
        desc?: string | null,
        price?: number | null,
      } > | null,
      naverPayments?:  Array< {
        __typename: "NaverPayment",
        method?: NaverPaymentMethod | null,
        moment?: NaverPaymentMoment | null,
        provider?: NaverPaymentProvider | null,
        paymentId: number,
        bookingId: number,
        amount: number,
        providerDiscountAmount?: number | null,
        regDateTime?: string | null,
        status: NaverPaymentStatus,
        statusHistories?:  Array< {
          __typename: "NaverPaymentStatusHistory",
          status: NaverPaymentStatus,
          dateTime?: string | null,
        } > | null,
      } > | null,
      initialPrice?: number | null,
      finalPrice?: number | null,
      totalServiceSales?: number | null,
      totalProductSales?: number | null,
      initialDeposit?: number | null,
      paidAmount?: number | null,
      paidInCashAmount?: number | null,
      paidByCardAmount?: number | null,
      paidByPrepaidPassAmount?: number | null,
      paidByNaverPayAmount?: number | null,
      paidAt?: string | null,
      paymentMemo?: string | null,
      checkInRequests?: Array< string > | null,
      drink?: string | null,
      checkedInAt?: string | null,
      origin?: BookingOrigin | null,
      options?:  Array< {
        __typename: "BookingServiceOption",
        categoryName: string,
        name: string,
        originalPrice: number,
        discount?: number | null,
        discountRate?: number | null,
        price: number,
        discountPreset?:  {
          __typename: "DiscountPresetSnapshot",
          id?: string | null,
          shopId?: string | null,
          name?: string | null,
          amount?: number | null,
          rate?: number | null,
          type?: DiscountMode | null,
        } | null,
      } > | null,
      productOptions?:  Array< {
        __typename: "BookingProductOption",
        name: string,
        price: number,
        quantity: number,
        product?:  {
          __typename: "ProductSnapshot",
          id?: string | null,
          name?: string | null,
          shopId?: string | null,
          brandId?: string | null,
          brandName?: string | null,
          costPrice?: number | null,
          sellingPrice?: number | null,
        } | null,
      } > | null,
      signature?: string | null,
      signedAt?: string | null,
      recentNaverDataReflection?: string | null,
      selfDiagnosisSentAt?: string | null,
      feedbackRequestSentAt?: string | null,
      hasFeedback?: boolean | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      hasDesignCompletionPhotos?: boolean | null,
      designCompletionPhotos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      signatureOfPortraitRights?: string | null,
      signedAtOfPortraitRights?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BookingByConsultationIdQueryVariables = {
  consultationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingByConsultationIdQuery = {
  bookingByConsultationId?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      yearMonth?: string | null,
      date: string,
      time: string,
      customer?:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      } | null,
      customerId: string,
      name?: string | null,
      phoneNumber?: string | null,
      consultationId?: string | null,
      consultation?:  {
        __typename: "NestedConsultation",
        status?: ConsultationStatus | null,
        selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
        solutionSentAt?: string | null,
        inflowChannel?: string | null,
        hasPhotos?: boolean | null,
      } | null,
      hasSelfDiagnosis?: boolean | null,
      hasRegularCustomerNote?: boolean | null,
      designerName?: string | null,
      designerId?: string | null,
      optionName?: string | null,
      shopId: string,
      shopName?: string | null,
      naverBookingNumber?: string | null,
      bookingStatus?: BookingStatus | null,
      serviceStatus?: ServiceStatus | null,
      paymentStatus?: PaymentStatus | null,
      requestMessage?: string | null,
      designerMemo?: string | null,
      naverSnapshot?:  {
        __typename: "NaverSnapshot",
        price: number,
        bizItemPrice: number,
        onsitePrice?: number | null,
        email?: string | null,
        isOnsitePayment?: boolean | null,
      } | null,
      naverOptions?:  Array< {
        __typename: "NaverServiceOption",
        categoryName: string,
        name: string,
        originalName?: string | null,
        desc?: string | null,
        price?: number | null,
      } > | null,
      naverPayments?:  Array< {
        __typename: "NaverPayment",
        method?: NaverPaymentMethod | null,
        moment?: NaverPaymentMoment | null,
        provider?: NaverPaymentProvider | null,
        paymentId: number,
        bookingId: number,
        amount: number,
        providerDiscountAmount?: number | null,
        regDateTime?: string | null,
        status: NaverPaymentStatus,
        statusHistories?:  Array< {
          __typename: "NaverPaymentStatusHistory",
          status: NaverPaymentStatus,
          dateTime?: string | null,
        } > | null,
      } > | null,
      initialPrice?: number | null,
      finalPrice?: number | null,
      totalServiceSales?: number | null,
      totalProductSales?: number | null,
      initialDeposit?: number | null,
      paidAmount?: number | null,
      paidInCashAmount?: number | null,
      paidByCardAmount?: number | null,
      paidByPrepaidPassAmount?: number | null,
      paidByNaverPayAmount?: number | null,
      paidAt?: string | null,
      paymentMemo?: string | null,
      checkInRequests?: Array< string > | null,
      drink?: string | null,
      checkedInAt?: string | null,
      origin?: BookingOrigin | null,
      options?:  Array< {
        __typename: "BookingServiceOption",
        categoryName: string,
        name: string,
        originalPrice: number,
        discount?: number | null,
        discountRate?: number | null,
        price: number,
        discountPreset?:  {
          __typename: "DiscountPresetSnapshot",
          id?: string | null,
          shopId?: string | null,
          name?: string | null,
          amount?: number | null,
          rate?: number | null,
          type?: DiscountMode | null,
        } | null,
      } > | null,
      productOptions?:  Array< {
        __typename: "BookingProductOption",
        name: string,
        price: number,
        quantity: number,
        product?:  {
          __typename: "ProductSnapshot",
          id?: string | null,
          name?: string | null,
          shopId?: string | null,
          brandId?: string | null,
          brandName?: string | null,
          costPrice?: number | null,
          sellingPrice?: number | null,
        } | null,
      } > | null,
      signature?: string | null,
      signedAt?: string | null,
      recentNaverDataReflection?: string | null,
      selfDiagnosisSentAt?: string | null,
      feedbackRequestSentAt?: string | null,
      hasFeedback?: boolean | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      hasDesignCompletionPhotos?: boolean | null,
      designCompletionPhotos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      signatureOfPortraitRights?: string | null,
      signedAtOfPortraitRights?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BookingByDesignerNameQueryVariables = {
  designerName: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingByDesignerNameQuery = {
  bookingByDesignerName?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      yearMonth?: string | null,
      date: string,
      time: string,
      customer?:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      } | null,
      customerId: string,
      name?: string | null,
      phoneNumber?: string | null,
      consultationId?: string | null,
      consultation?:  {
        __typename: "NestedConsultation",
        status?: ConsultationStatus | null,
        selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
        solutionSentAt?: string | null,
        inflowChannel?: string | null,
        hasPhotos?: boolean | null,
      } | null,
      hasSelfDiagnosis?: boolean | null,
      hasRegularCustomerNote?: boolean | null,
      designerName?: string | null,
      designerId?: string | null,
      optionName?: string | null,
      shopId: string,
      shopName?: string | null,
      naverBookingNumber?: string | null,
      bookingStatus?: BookingStatus | null,
      serviceStatus?: ServiceStatus | null,
      paymentStatus?: PaymentStatus | null,
      requestMessage?: string | null,
      designerMemo?: string | null,
      naverSnapshot?:  {
        __typename: "NaverSnapshot",
        price: number,
        bizItemPrice: number,
        onsitePrice?: number | null,
        email?: string | null,
        isOnsitePayment?: boolean | null,
      } | null,
      naverOptions?:  Array< {
        __typename: "NaverServiceOption",
        categoryName: string,
        name: string,
        originalName?: string | null,
        desc?: string | null,
        price?: number | null,
      } > | null,
      naverPayments?:  Array< {
        __typename: "NaverPayment",
        method?: NaverPaymentMethod | null,
        moment?: NaverPaymentMoment | null,
        provider?: NaverPaymentProvider | null,
        paymentId: number,
        bookingId: number,
        amount: number,
        providerDiscountAmount?: number | null,
        regDateTime?: string | null,
        status: NaverPaymentStatus,
        statusHistories?:  Array< {
          __typename: "NaverPaymentStatusHistory",
          status: NaverPaymentStatus,
          dateTime?: string | null,
        } > | null,
      } > | null,
      initialPrice?: number | null,
      finalPrice?: number | null,
      totalServiceSales?: number | null,
      totalProductSales?: number | null,
      initialDeposit?: number | null,
      paidAmount?: number | null,
      paidInCashAmount?: number | null,
      paidByCardAmount?: number | null,
      paidByPrepaidPassAmount?: number | null,
      paidByNaverPayAmount?: number | null,
      paidAt?: string | null,
      paymentMemo?: string | null,
      checkInRequests?: Array< string > | null,
      drink?: string | null,
      checkedInAt?: string | null,
      origin?: BookingOrigin | null,
      options?:  Array< {
        __typename: "BookingServiceOption",
        categoryName: string,
        name: string,
        originalPrice: number,
        discount?: number | null,
        discountRate?: number | null,
        price: number,
        discountPreset?:  {
          __typename: "DiscountPresetSnapshot",
          id?: string | null,
          shopId?: string | null,
          name?: string | null,
          amount?: number | null,
          rate?: number | null,
          type?: DiscountMode | null,
        } | null,
      } > | null,
      productOptions?:  Array< {
        __typename: "BookingProductOption",
        name: string,
        price: number,
        quantity: number,
        product?:  {
          __typename: "ProductSnapshot",
          id?: string | null,
          name?: string | null,
          shopId?: string | null,
          brandId?: string | null,
          brandName?: string | null,
          costPrice?: number | null,
          sellingPrice?: number | null,
        } | null,
      } > | null,
      signature?: string | null,
      signedAt?: string | null,
      recentNaverDataReflection?: string | null,
      selfDiagnosisSentAt?: string | null,
      feedbackRequestSentAt?: string | null,
      hasFeedback?: boolean | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      hasDesignCompletionPhotos?: boolean | null,
      designCompletionPhotos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      signatureOfPortraitRights?: string | null,
      signedAtOfPortraitRights?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BookingByDesignerIdQueryVariables = {
  designerId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingByDesignerIdQuery = {
  bookingByDesignerId?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      yearMonth?: string | null,
      date: string,
      time: string,
      customer?:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      } | null,
      customerId: string,
      name?: string | null,
      phoneNumber?: string | null,
      consultationId?: string | null,
      consultation?:  {
        __typename: "NestedConsultation",
        status?: ConsultationStatus | null,
        selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
        solutionSentAt?: string | null,
        inflowChannel?: string | null,
        hasPhotos?: boolean | null,
      } | null,
      hasSelfDiagnosis?: boolean | null,
      hasRegularCustomerNote?: boolean | null,
      designerName?: string | null,
      designerId?: string | null,
      optionName?: string | null,
      shopId: string,
      shopName?: string | null,
      naverBookingNumber?: string | null,
      bookingStatus?: BookingStatus | null,
      serviceStatus?: ServiceStatus | null,
      paymentStatus?: PaymentStatus | null,
      requestMessage?: string | null,
      designerMemo?: string | null,
      naverSnapshot?:  {
        __typename: "NaverSnapshot",
        price: number,
        bizItemPrice: number,
        onsitePrice?: number | null,
        email?: string | null,
        isOnsitePayment?: boolean | null,
      } | null,
      naverOptions?:  Array< {
        __typename: "NaverServiceOption",
        categoryName: string,
        name: string,
        originalName?: string | null,
        desc?: string | null,
        price?: number | null,
      } > | null,
      naverPayments?:  Array< {
        __typename: "NaverPayment",
        method?: NaverPaymentMethod | null,
        moment?: NaverPaymentMoment | null,
        provider?: NaverPaymentProvider | null,
        paymentId: number,
        bookingId: number,
        amount: number,
        providerDiscountAmount?: number | null,
        regDateTime?: string | null,
        status: NaverPaymentStatus,
        statusHistories?:  Array< {
          __typename: "NaverPaymentStatusHistory",
          status: NaverPaymentStatus,
          dateTime?: string | null,
        } > | null,
      } > | null,
      initialPrice?: number | null,
      finalPrice?: number | null,
      totalServiceSales?: number | null,
      totalProductSales?: number | null,
      initialDeposit?: number | null,
      paidAmount?: number | null,
      paidInCashAmount?: number | null,
      paidByCardAmount?: number | null,
      paidByPrepaidPassAmount?: number | null,
      paidByNaverPayAmount?: number | null,
      paidAt?: string | null,
      paymentMemo?: string | null,
      checkInRequests?: Array< string > | null,
      drink?: string | null,
      checkedInAt?: string | null,
      origin?: BookingOrigin | null,
      options?:  Array< {
        __typename: "BookingServiceOption",
        categoryName: string,
        name: string,
        originalPrice: number,
        discount?: number | null,
        discountRate?: number | null,
        price: number,
        discountPreset?:  {
          __typename: "DiscountPresetSnapshot",
          id?: string | null,
          shopId?: string | null,
          name?: string | null,
          amount?: number | null,
          rate?: number | null,
          type?: DiscountMode | null,
        } | null,
      } > | null,
      productOptions?:  Array< {
        __typename: "BookingProductOption",
        name: string,
        price: number,
        quantity: number,
        product?:  {
          __typename: "ProductSnapshot",
          id?: string | null,
          name?: string | null,
          shopId?: string | null,
          brandId?: string | null,
          brandName?: string | null,
          costPrice?: number | null,
          sellingPrice?: number | null,
        } | null,
      } > | null,
      signature?: string | null,
      signedAt?: string | null,
      recentNaverDataReflection?: string | null,
      selfDiagnosisSentAt?: string | null,
      feedbackRequestSentAt?: string | null,
      hasFeedback?: boolean | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      hasDesignCompletionPhotos?: boolean | null,
      designCompletionPhotos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      signatureOfPortraitRights?: string | null,
      signedAtOfPortraitRights?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BookingByShopIdQueryVariables = {
  shopId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingByShopIdQuery = {
  bookingByShopId?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      yearMonth?: string | null,
      date: string,
      time: string,
      customer?:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      } | null,
      customerId: string,
      name?: string | null,
      phoneNumber?: string | null,
      consultationId?: string | null,
      consultation?:  {
        __typename: "NestedConsultation",
        status?: ConsultationStatus | null,
        selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
        solutionSentAt?: string | null,
        inflowChannel?: string | null,
        hasPhotos?: boolean | null,
      } | null,
      hasSelfDiagnosis?: boolean | null,
      hasRegularCustomerNote?: boolean | null,
      designerName?: string | null,
      designerId?: string | null,
      optionName?: string | null,
      shopId: string,
      shopName?: string | null,
      naverBookingNumber?: string | null,
      bookingStatus?: BookingStatus | null,
      serviceStatus?: ServiceStatus | null,
      paymentStatus?: PaymentStatus | null,
      requestMessage?: string | null,
      designerMemo?: string | null,
      naverSnapshot?:  {
        __typename: "NaverSnapshot",
        price: number,
        bizItemPrice: number,
        onsitePrice?: number | null,
        email?: string | null,
        isOnsitePayment?: boolean | null,
      } | null,
      naverOptions?:  Array< {
        __typename: "NaverServiceOption",
        categoryName: string,
        name: string,
        originalName?: string | null,
        desc?: string | null,
        price?: number | null,
      } > | null,
      naverPayments?:  Array< {
        __typename: "NaverPayment",
        method?: NaverPaymentMethod | null,
        moment?: NaverPaymentMoment | null,
        provider?: NaverPaymentProvider | null,
        paymentId: number,
        bookingId: number,
        amount: number,
        providerDiscountAmount?: number | null,
        regDateTime?: string | null,
        status: NaverPaymentStatus,
        statusHistories?:  Array< {
          __typename: "NaverPaymentStatusHistory",
          status: NaverPaymentStatus,
          dateTime?: string | null,
        } > | null,
      } > | null,
      initialPrice?: number | null,
      finalPrice?: number | null,
      totalServiceSales?: number | null,
      totalProductSales?: number | null,
      initialDeposit?: number | null,
      paidAmount?: number | null,
      paidInCashAmount?: number | null,
      paidByCardAmount?: number | null,
      paidByPrepaidPassAmount?: number | null,
      paidByNaverPayAmount?: number | null,
      paidAt?: string | null,
      paymentMemo?: string | null,
      checkInRequests?: Array< string > | null,
      drink?: string | null,
      checkedInAt?: string | null,
      origin?: BookingOrigin | null,
      options?:  Array< {
        __typename: "BookingServiceOption",
        categoryName: string,
        name: string,
        originalPrice: number,
        discount?: number | null,
        discountRate?: number | null,
        price: number,
        discountPreset?:  {
          __typename: "DiscountPresetSnapshot",
          id?: string | null,
          shopId?: string | null,
          name?: string | null,
          amount?: number | null,
          rate?: number | null,
          type?: DiscountMode | null,
        } | null,
      } > | null,
      productOptions?:  Array< {
        __typename: "BookingProductOption",
        name: string,
        price: number,
        quantity: number,
        product?:  {
          __typename: "ProductSnapshot",
          id?: string | null,
          name?: string | null,
          shopId?: string | null,
          brandId?: string | null,
          brandName?: string | null,
          costPrice?: number | null,
          sellingPrice?: number | null,
        } | null,
      } > | null,
      signature?: string | null,
      signedAt?: string | null,
      recentNaverDataReflection?: string | null,
      selfDiagnosisSentAt?: string | null,
      feedbackRequestSentAt?: string | null,
      hasFeedback?: boolean | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      hasDesignCompletionPhotos?: boolean | null,
      designCompletionPhotos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      signatureOfPortraitRights?: string | null,
      signedAtOfPortraitRights?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BookingByNaverBookingNumberQueryVariables = {
  naverBookingNumber: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingByNaverBookingNumberQuery = {
  bookingByNaverBookingNumber?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      yearMonth?: string | null,
      date: string,
      time: string,
      customer?:  {
        __typename: "NestedCustomer",
        phoneNumber?: string | null,
        name?: string | null,
        id?: string | null,
        shopIds?: Array< string > | null,
      } | null,
      customerId: string,
      name?: string | null,
      phoneNumber?: string | null,
      consultationId?: string | null,
      consultation?:  {
        __typename: "NestedConsultation",
        status?: ConsultationStatus | null,
        selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
        solutionSentAt?: string | null,
        inflowChannel?: string | null,
        hasPhotos?: boolean | null,
      } | null,
      hasSelfDiagnosis?: boolean | null,
      hasRegularCustomerNote?: boolean | null,
      designerName?: string | null,
      designerId?: string | null,
      optionName?: string | null,
      shopId: string,
      shopName?: string | null,
      naverBookingNumber?: string | null,
      bookingStatus?: BookingStatus | null,
      serviceStatus?: ServiceStatus | null,
      paymentStatus?: PaymentStatus | null,
      requestMessage?: string | null,
      designerMemo?: string | null,
      naverSnapshot?:  {
        __typename: "NaverSnapshot",
        price: number,
        bizItemPrice: number,
        onsitePrice?: number | null,
        email?: string | null,
        isOnsitePayment?: boolean | null,
      } | null,
      naverOptions?:  Array< {
        __typename: "NaverServiceOption",
        categoryName: string,
        name: string,
        originalName?: string | null,
        desc?: string | null,
        price?: number | null,
      } > | null,
      naverPayments?:  Array< {
        __typename: "NaverPayment",
        method?: NaverPaymentMethod | null,
        moment?: NaverPaymentMoment | null,
        provider?: NaverPaymentProvider | null,
        paymentId: number,
        bookingId: number,
        amount: number,
        providerDiscountAmount?: number | null,
        regDateTime?: string | null,
        status: NaverPaymentStatus,
        statusHistories?:  Array< {
          __typename: "NaverPaymentStatusHistory",
          status: NaverPaymentStatus,
          dateTime?: string | null,
        } > | null,
      } > | null,
      initialPrice?: number | null,
      finalPrice?: number | null,
      totalServiceSales?: number | null,
      totalProductSales?: number | null,
      initialDeposit?: number | null,
      paidAmount?: number | null,
      paidInCashAmount?: number | null,
      paidByCardAmount?: number | null,
      paidByPrepaidPassAmount?: number | null,
      paidByNaverPayAmount?: number | null,
      paidAt?: string | null,
      paymentMemo?: string | null,
      checkInRequests?: Array< string > | null,
      drink?: string | null,
      checkedInAt?: string | null,
      origin?: BookingOrigin | null,
      options?:  Array< {
        __typename: "BookingServiceOption",
        categoryName: string,
        name: string,
        originalPrice: number,
        discount?: number | null,
        discountRate?: number | null,
        price: number,
        discountPreset?:  {
          __typename: "DiscountPresetSnapshot",
          id?: string | null,
          shopId?: string | null,
          name?: string | null,
          amount?: number | null,
          rate?: number | null,
          type?: DiscountMode | null,
        } | null,
      } > | null,
      productOptions?:  Array< {
        __typename: "BookingProductOption",
        name: string,
        price: number,
        quantity: number,
        product?:  {
          __typename: "ProductSnapshot",
          id?: string | null,
          name?: string | null,
          shopId?: string | null,
          brandId?: string | null,
          brandName?: string | null,
          costPrice?: number | null,
          sellingPrice?: number | null,
        } | null,
      } > | null,
      signature?: string | null,
      signedAt?: string | null,
      recentNaverDataReflection?: string | null,
      selfDiagnosisSentAt?: string | null,
      feedbackRequestSentAt?: string | null,
      hasFeedback?: boolean | null,
      hasPhotos?: boolean | null,
      photos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      hasDesignCompletionPhotos?: boolean | null,
      designCompletionPhotos?:  Array< {
        __typename: "NestedPhoto",
        s3Key: string,
        displayOrder: number,
      } > | null,
      signatureOfPortraitRights?: string | null,
      signedAtOfPortraitRights?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCustomerQueryVariables = {
  id: string,
};

export type GetCustomerQuery = {
  getCustomer?:  {
    __typename: "Customer",
    id: string,
    name: string,
    phoneNumber?: string | null,
    lastFourPhoneNumbers?: string | null,
    firstVisitDate?: string | null,
    occupation?: string | null,
    drink?: string | null,
    dress?: string | null,
    trouble?: string | null,
    memo?: string | null,
    bookingCount?: number | null,
    consultationCount?: number | null,
    consultationDoneCount?: number | null,
    signature?: string | null,
    totalRevenue?: number | null,
    vehicleNumber?: string | null,
    shopId: string,
    shopIds?: Array< string > | null,
    shopIdsString?: string | null,
    createdAt?: string | null,
    prepaidPassBalance?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCustomersQueryVariables = {
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomersQuery = {
  listCustomers?:  {
    __typename: "ModelCustomerConnection",
    items:  Array< {
      __typename: "Customer",
      id: string,
      name: string,
      phoneNumber?: string | null,
      lastFourPhoneNumbers?: string | null,
      firstVisitDate?: string | null,
      occupation?: string | null,
      drink?: string | null,
      dress?: string | null,
      trouble?: string | null,
      memo?: string | null,
      bookingCount?: number | null,
      consultationCount?: number | null,
      consultationDoneCount?: number | null,
      signature?: string | null,
      totalRevenue?: number | null,
      vehicleNumber?: string | null,
      shopId: string,
      shopIds?: Array< string > | null,
      shopIdsString?: string | null,
      createdAt?: string | null,
      prepaidPassBalance?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCustomersQueryVariables = {
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCustomersQuery = {
  syncCustomers?:  {
    __typename: "ModelCustomerConnection",
    items:  Array< {
      __typename: "Customer",
      id: string,
      name: string,
      phoneNumber?: string | null,
      lastFourPhoneNumbers?: string | null,
      firstVisitDate?: string | null,
      occupation?: string | null,
      drink?: string | null,
      dress?: string | null,
      trouble?: string | null,
      memo?: string | null,
      bookingCount?: number | null,
      consultationCount?: number | null,
      consultationDoneCount?: number | null,
      signature?: string | null,
      totalRevenue?: number | null,
      vehicleNumber?: string | null,
      shopId: string,
      shopIds?: Array< string > | null,
      shopIdsString?: string | null,
      createdAt?: string | null,
      prepaidPassBalance?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CustomerByNameAndPhoneQueryVariables = {
  name: string,
  phoneNumber?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomerByNameAndPhoneQuery = {
  customerByNameAndPhone?:  {
    __typename: "ModelCustomerConnection",
    items:  Array< {
      __typename: "Customer",
      id: string,
      name: string,
      phoneNumber?: string | null,
      lastFourPhoneNumbers?: string | null,
      firstVisitDate?: string | null,
      occupation?: string | null,
      drink?: string | null,
      dress?: string | null,
      trouble?: string | null,
      memo?: string | null,
      bookingCount?: number | null,
      consultationCount?: number | null,
      consultationDoneCount?: number | null,
      signature?: string | null,
      totalRevenue?: number | null,
      vehicleNumber?: string | null,
      shopId: string,
      shopIds?: Array< string > | null,
      shopIdsString?: string | null,
      createdAt?: string | null,
      prepaidPassBalance?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CustomerByShopIdAndNameQueryVariables = {
  shopId: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomerByShopIdAndNameQuery = {
  customerByShopIdAndName?:  {
    __typename: "ModelCustomerConnection",
    items:  Array< {
      __typename: "Customer",
      id: string,
      name: string,
      phoneNumber?: string | null,
      lastFourPhoneNumbers?: string | null,
      firstVisitDate?: string | null,
      occupation?: string | null,
      drink?: string | null,
      dress?: string | null,
      trouble?: string | null,
      memo?: string | null,
      bookingCount?: number | null,
      consultationCount?: number | null,
      consultationDoneCount?: number | null,
      signature?: string | null,
      totalRevenue?: number | null,
      vehicleNumber?: string | null,
      shopId: string,
      shopIds?: Array< string > | null,
      shopIdsString?: string | null,
      createdAt?: string | null,
      prepaidPassBalance?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CustomerByShopIdAndPhoneNumberQueryVariables = {
  shopId: string,
  phoneNumber?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomerByShopIdAndPhoneNumberQuery = {
  customerByShopIdAndPhoneNumber?:  {
    __typename: "ModelCustomerConnection",
    items:  Array< {
      __typename: "Customer",
      id: string,
      name: string,
      phoneNumber?: string | null,
      lastFourPhoneNumbers?: string | null,
      firstVisitDate?: string | null,
      occupation?: string | null,
      drink?: string | null,
      dress?: string | null,
      trouble?: string | null,
      memo?: string | null,
      bookingCount?: number | null,
      consultationCount?: number | null,
      consultationDoneCount?: number | null,
      signature?: string | null,
      totalRevenue?: number | null,
      vehicleNumber?: string | null,
      shopId: string,
      shopIds?: Array< string > | null,
      shopIdsString?: string | null,
      createdAt?: string | null,
      prepaidPassBalance?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CustomerByShopIdAndLastFourPhoneNumbersQueryVariables = {
  shopId: string,
  lastFourPhoneNumbers?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomerByShopIdAndLastFourPhoneNumbersQuery = {
  customerByShopIdAndLastFourPhoneNumbers?:  {
    __typename: "ModelCustomerConnection",
    items:  Array< {
      __typename: "Customer",
      id: string,
      name: string,
      phoneNumber?: string | null,
      lastFourPhoneNumbers?: string | null,
      firstVisitDate?: string | null,
      occupation?: string | null,
      drink?: string | null,
      dress?: string | null,
      trouble?: string | null,
      memo?: string | null,
      bookingCount?: number | null,
      consultationCount?: number | null,
      consultationDoneCount?: number | null,
      signature?: string | null,
      totalRevenue?: number | null,
      vehicleNumber?: string | null,
      shopId: string,
      shopIds?: Array< string > | null,
      shopIdsString?: string | null,
      createdAt?: string | null,
      prepaidPassBalance?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetShopQueryVariables = {
  id: string,
};

export type GetShopQuery = {
  getShop?:  {
    __typename: "Shop",
    id: string,
    name: string,
    naverBusinessId?: string | null,
    hasLocker?: boolean | null,
    bookingQRS3Key?: string | null,
    creatorId?: string | null,
    usePreSelfDiagnosis?: boolean | null,
    useFeedbackRequest?: boolean | null,
    owner?: string | null,
    ownerName?: string | null,
    ownerPhoneNumber?: string | null,
    internPhoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListShopsQueryVariables = {
  filter?: ModelShopFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShopsQuery = {
  listShops?:  {
    __typename: "ModelShopConnection",
    items:  Array< {
      __typename: "Shop",
      id: string,
      name: string,
      naverBusinessId?: string | null,
      hasLocker?: boolean | null,
      bookingQRS3Key?: string | null,
      creatorId?: string | null,
      usePreSelfDiagnosis?: boolean | null,
      useFeedbackRequest?: boolean | null,
      owner?: string | null,
      ownerName?: string | null,
      ownerPhoneNumber?: string | null,
      internPhoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncShopsQueryVariables = {
  filter?: ModelShopFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncShopsQuery = {
  syncShops?:  {
    __typename: "ModelShopConnection",
    items:  Array< {
      __typename: "Shop",
      id: string,
      name: string,
      naverBusinessId?: string | null,
      hasLocker?: boolean | null,
      bookingQRS3Key?: string | null,
      creatorId?: string | null,
      usePreSelfDiagnosis?: boolean | null,
      useFeedbackRequest?: boolean | null,
      owner?: string | null,
      ownerName?: string | null,
      ownerPhoneNumber?: string | null,
      internPhoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ShopByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShopFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShopByNameQuery = {
  shopByName?:  {
    __typename: "ModelShopConnection",
    items:  Array< {
      __typename: "Shop",
      id: string,
      name: string,
      naverBusinessId?: string | null,
      hasLocker?: boolean | null,
      bookingQRS3Key?: string | null,
      creatorId?: string | null,
      usePreSelfDiagnosis?: boolean | null,
      useFeedbackRequest?: boolean | null,
      owner?: string | null,
      ownerName?: string | null,
      ownerPhoneNumber?: string | null,
      internPhoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ShopByNaverBusinessIdQueryVariables = {
  naverBusinessId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShopFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShopByNaverBusinessIdQuery = {
  shopByNaverBusinessId?:  {
    __typename: "ModelShopConnection",
    items:  Array< {
      __typename: "Shop",
      id: string,
      name: string,
      naverBusinessId?: string | null,
      hasLocker?: boolean | null,
      bookingQRS3Key?: string | null,
      creatorId?: string | null,
      usePreSelfDiagnosis?: boolean | null,
      useFeedbackRequest?: boolean | null,
      owner?: string | null,
      ownerName?: string | null,
      ownerPhoneNumber?: string | null,
      internPhoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ShopByCreatorIdQueryVariables = {
  creatorId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShopFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShopByCreatorIdQuery = {
  shopByCreatorId?:  {
    __typename: "ModelShopConnection",
    items:  Array< {
      __typename: "Shop",
      id: string,
      name: string,
      naverBusinessId?: string | null,
      hasLocker?: boolean | null,
      bookingQRS3Key?: string | null,
      creatorId?: string | null,
      usePreSelfDiagnosis?: boolean | null,
      useFeedbackRequest?: boolean | null,
      owner?: string | null,
      ownerName?: string | null,
      ownerPhoneNumber?: string | null,
      internPhoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetShopServiceDrinkQueryVariables = {
  id: string,
};

export type GetShopServiceDrinkQuery = {
  getShopServiceDrink?:  {
    __typename: "ShopServiceDrink",
    id: string,
    name: string,
    shopId: string,
    outOfStock?: boolean | null,
    displayOrder: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListShopServiceDrinksQueryVariables = {
  filter?: ModelShopServiceDrinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShopServiceDrinksQuery = {
  listShopServiceDrinks?:  {
    __typename: "ModelShopServiceDrinkConnection",
    items:  Array< {
      __typename: "ShopServiceDrink",
      id: string,
      name: string,
      shopId: string,
      outOfStock?: boolean | null,
      displayOrder: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncShopServiceDrinksQueryVariables = {
  filter?: ModelShopServiceDrinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncShopServiceDrinksQuery = {
  syncShopServiceDrinks?:  {
    __typename: "ModelShopServiceDrinkConnection",
    items:  Array< {
      __typename: "ShopServiceDrink",
      id: string,
      name: string,
      shopId: string,
      outOfStock?: boolean | null,
      displayOrder: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ShopServiceDrinkByShopIdQueryVariables = {
  shopId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShopServiceDrinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShopServiceDrinkByShopIdQuery = {
  shopServiceDrinkByShopId?:  {
    __typename: "ModelShopServiceDrinkConnection",
    items:  Array< {
      __typename: "ShopServiceDrink",
      id: string,
      name: string,
      shopId: string,
      outOfStock?: boolean | null,
      displayOrder: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetShopUserApplicationQueryVariables = {
  id: string,
};

export type GetShopUserApplicationQuery = {
  getShopUserApplication?:  {
    __typename: "ShopUserApplication",
    id: string,
    shopId: string,
    userId: string,
    phoneNumber: string,
    completed: boolean,
    name: string,
    email: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListShopUserApplicationsQueryVariables = {
  filter?: ModelShopUserApplicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShopUserApplicationsQuery = {
  listShopUserApplications?:  {
    __typename: "ModelShopUserApplicationConnection",
    items:  Array< {
      __typename: "ShopUserApplication",
      id: string,
      shopId: string,
      userId: string,
      phoneNumber: string,
      completed: boolean,
      name: string,
      email: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncShopUserApplicationsQueryVariables = {
  filter?: ModelShopUserApplicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncShopUserApplicationsQuery = {
  syncShopUserApplications?:  {
    __typename: "ModelShopUserApplicationConnection",
    items:  Array< {
      __typename: "ShopUserApplication",
      id: string,
      shopId: string,
      userId: string,
      phoneNumber: string,
      completed: boolean,
      name: string,
      email: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ShopUserApplicationByShopIdQueryVariables = {
  shopId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShopUserApplicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShopUserApplicationByShopIdQuery = {
  shopUserApplicationByShopId?:  {
    __typename: "ModelShopUserApplicationConnection",
    items:  Array< {
      __typename: "ShopUserApplication",
      id: string,
      shopId: string,
      userId: string,
      phoneNumber: string,
      completed: boolean,
      name: string,
      email: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ShopUserApplicationByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShopUserApplicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShopUserApplicationByUserIdQuery = {
  shopUserApplicationByUserId?:  {
    __typename: "ModelShopUserApplicationConnection",
    items:  Array< {
      __typename: "ShopUserApplication",
      id: string,
      shopId: string,
      userId: string,
      phoneNumber: string,
      completed: boolean,
      name: string,
      email: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetShopUserRemovalRequestQueryVariables = {
  designerId: string,
};

export type GetShopUserRemovalRequestQuery = {
  getShopUserRemovalRequest?:  {
    __typename: "ShopUserRemovalRequest",
    designerId: string,
    shopId: string,
    userId: string,
    completed: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListShopUserRemovalRequestsQueryVariables = {
  designerId?: string | null,
  filter?: ModelShopUserRemovalRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListShopUserRemovalRequestsQuery = {
  listShopUserRemovalRequests?:  {
    __typename: "ModelShopUserRemovalRequestConnection",
    items:  Array< {
      __typename: "ShopUserRemovalRequest",
      designerId: string,
      shopId: string,
      userId: string,
      completed: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncShopUserRemovalRequestsQueryVariables = {
  filter?: ModelShopUserRemovalRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncShopUserRemovalRequestsQuery = {
  syncShopUserRemovalRequests?:  {
    __typename: "ModelShopUserRemovalRequestConnection",
    items:  Array< {
      __typename: "ShopUserRemovalRequest",
      designerId: string,
      shopId: string,
      userId: string,
      completed: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ShopUserRemovalRequestByShopIdQueryVariables = {
  shopId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShopUserRemovalRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShopUserRemovalRequestByShopIdQuery = {
  shopUserRemovalRequestByShopId?:  {
    __typename: "ModelShopUserRemovalRequestConnection",
    items:  Array< {
      __typename: "ShopUserRemovalRequest",
      designerId: string,
      shopId: string,
      userId: string,
      completed: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetServiceOptionQueryVariables = {
  shopId: string,
};

export type GetServiceOptionQuery = {
  getServiceOption?:  {
    __typename: "ServiceOption",
    shopId: string,
    categories:  Array< {
      __typename: "ServiceOptionCategory",
      name: string,
      options:  Array< {
        __typename: "ServiceOptionItem",
        name: string,
        price?: number | null,
      } >,
    } >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListServiceOptionsQueryVariables = {
  shopId?: string | null,
  filter?: ModelServiceOptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListServiceOptionsQuery = {
  listServiceOptions?:  {
    __typename: "ModelServiceOptionConnection",
    items:  Array< {
      __typename: "ServiceOption",
      shopId: string,
      categories:  Array< {
        __typename: "ServiceOptionCategory",
        name: string,
        options:  Array< {
          __typename: "ServiceOptionItem",
          name: string,
          price?: number | null,
        } >,
      } >,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncServiceOptionsQueryVariables = {
  filter?: ModelServiceOptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncServiceOptionsQuery = {
  syncServiceOptions?:  {
    __typename: "ModelServiceOptionConnection",
    items:  Array< {
      __typename: "ServiceOption",
      shopId: string,
      categories:  Array< {
        __typename: "ServiceOptionCategory",
        name: string,
        options:  Array< {
          __typename: "ServiceOptionItem",
          name: string,
          price?: number | null,
        } >,
      } >,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPrepaidPassPresetQueryVariables = {
  id: string,
};

export type GetPrepaidPassPresetQuery = {
  getPrepaidPassPreset?:  {
    __typename: "PrepaidPassPreset",
    id: string,
    shopId: string,
    name: string,
    price: number,
    availableAmount: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPrepaidPassPresetsQueryVariables = {
  filter?: ModelPrepaidPassPresetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPrepaidPassPresetsQuery = {
  listPrepaidPassPresets?:  {
    __typename: "ModelPrepaidPassPresetConnection",
    items:  Array< {
      __typename: "PrepaidPassPreset",
      id: string,
      shopId: string,
      name: string,
      price: number,
      availableAmount: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPrepaidPassPresetsQueryVariables = {
  filter?: ModelPrepaidPassPresetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPrepaidPassPresetsQuery = {
  syncPrepaidPassPresets?:  {
    __typename: "ModelPrepaidPassPresetConnection",
    items:  Array< {
      __typename: "PrepaidPassPreset",
      id: string,
      shopId: string,
      name: string,
      price: number,
      availableAmount: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PrepaidPassPresetByShopIdQueryVariables = {
  shopId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPrepaidPassPresetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PrepaidPassPresetByShopIdQuery = {
  prepaidPassPresetByShopId?:  {
    __typename: "ModelPrepaidPassPresetConnection",
    items:  Array< {
      __typename: "PrepaidPassPreset",
      id: string,
      shopId: string,
      name: string,
      price: number,
      availableAmount: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPrepaidPassHistoryQueryVariables = {
  id: string,
};

export type GetPrepaidPassHistoryQuery = {
  getPrepaidPassHistory?:  {
    __typename: "PrepaidPassHistory",
    id: string,
    customerId: string,
    shopId: string,
    amount: number,
    bookingId?: string | null,
    type: PrepaidPassRecordType,
    description?: string | null,
    referenceId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPrepaidPassHistoriesQueryVariables = {
  filter?: ModelPrepaidPassHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPrepaidPassHistoriesQuery = {
  listPrepaidPassHistories?:  {
    __typename: "ModelPrepaidPassHistoryConnection",
    items:  Array< {
      __typename: "PrepaidPassHistory",
      id: string,
      customerId: string,
      shopId: string,
      amount: number,
      bookingId?: string | null,
      type: PrepaidPassRecordType,
      description?: string | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPrepaidPassHistoriesQueryVariables = {
  filter?: ModelPrepaidPassHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPrepaidPassHistoriesQuery = {
  syncPrepaidPassHistories?:  {
    __typename: "ModelPrepaidPassHistoryConnection",
    items:  Array< {
      __typename: "PrepaidPassHistory",
      id: string,
      customerId: string,
      shopId: string,
      amount: number,
      bookingId?: string | null,
      type: PrepaidPassRecordType,
      description?: string | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PrepaidPassHistoryByCustomerIdQueryVariables = {
  customerId: string,
  shopId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPrepaidPassHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PrepaidPassHistoryByCustomerIdQuery = {
  prepaidPassHistoryByCustomerId?:  {
    __typename: "ModelPrepaidPassHistoryConnection",
    items:  Array< {
      __typename: "PrepaidPassHistory",
      id: string,
      customerId: string,
      shopId: string,
      amount: number,
      bookingId?: string | null,
      type: PrepaidPassRecordType,
      description?: string | null,
      referenceId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBrandQueryVariables = {
  id: string,
};

export type GetBrandQuery = {
  getBrand?:  {
    __typename: "Brand",
    id: string,
    shopId: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListBrandsQueryVariables = {
  filter?: ModelBrandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBrandsQuery = {
  listBrands?:  {
    __typename: "ModelBrandConnection",
    items:  Array< {
      __typename: "Brand",
      id: string,
      shopId: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBrandsQueryVariables = {
  filter?: ModelBrandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBrandsQuery = {
  syncBrands?:  {
    __typename: "ModelBrandConnection",
    items:  Array< {
      __typename: "Brand",
      id: string,
      shopId: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BrandByShopIdQueryVariables = {
  shopId: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBrandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BrandByShopIdQuery = {
  brandByShopId?:  {
    __typename: "ModelBrandConnection",
    items:  Array< {
      __typename: "Brand",
      id: string,
      shopId: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetProductQueryVariables = {
  id: string,
};

export type GetProductQuery = {
  getProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    shopId: string,
    brandId: string,
    brandName: string,
    costPrice: number,
    sellingPrice: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListProductsQueryVariables = {
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsQuery = {
  listProducts?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      name: string,
      shopId: string,
      brandId: string,
      brandName: string,
      costPrice: number,
      sellingPrice: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncProductsQueryVariables = {
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncProductsQuery = {
  syncProducts?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      name: string,
      shopId: string,
      brandId: string,
      brandName: string,
      costPrice: number,
      sellingPrice: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ProductByShopIdQueryVariables = {
  shopId: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductByShopIdQuery = {
  productByShopId?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      name: string,
      shopId: string,
      brandId: string,
      brandName: string,
      costPrice: number,
      sellingPrice: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetConsultationSolutionPresetQueryVariables = {
  id: string,
};

export type GetConsultationSolutionPresetQuery = {
  getConsultationSolutionPreset?:  {
    __typename: "ConsultationSolutionPreset",
    id: string,
    category: string,
    sentence: string,
    priority: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListConsultationSolutionPresetsQueryVariables = {
  filter?: ModelConsultationSolutionPresetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConsultationSolutionPresetsQuery = {
  listConsultationSolutionPresets?:  {
    __typename: "ModelConsultationSolutionPresetConnection",
    items:  Array< {
      __typename: "ConsultationSolutionPreset",
      id: string,
      category: string,
      sentence: string,
      priority: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncConsultationSolutionPresetsQueryVariables = {
  filter?: ModelConsultationSolutionPresetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncConsultationSolutionPresetsQuery = {
  syncConsultationSolutionPresets?:  {
    __typename: "ModelConsultationSolutionPresetConnection",
    items:  Array< {
      __typename: "ConsultationSolutionPreset",
      id: string,
      category: string,
      sentence: string,
      priority: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ConsultationSolutionPresetByCategoryQueryVariables = {
  category: string,
  sentence?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConsultationSolutionPresetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConsultationSolutionPresetByCategoryQuery = {
  consultationSolutionPresetByCategory?:  {
    __typename: "ModelConsultationSolutionPresetConnection",
    items:  Array< {
      __typename: "ConsultationSolutionPreset",
      id: string,
      category: string,
      sentence: string,
      priority: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCoupangProductQueryVariables = {
  id: string,
};

export type GetCoupangProductQuery = {
  getCoupangProduct?:  {
    __typename: "CoupangProduct",
    id: string,
    src: string,
    name?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCoupangProductsQueryVariables = {
  filter?: ModelCoupangProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCoupangProductsQuery = {
  listCoupangProducts?:  {
    __typename: "ModelCoupangProductConnection",
    items:  Array< {
      __typename: "CoupangProduct",
      id: string,
      src: string,
      name?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCoupangProductsQueryVariables = {
  filter?: ModelCoupangProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCoupangProductsQuery = {
  syncCoupangProducts?:  {
    __typename: "ModelCoupangProductConnection",
    items:  Array< {
      __typename: "CoupangProduct",
      id: string,
      src: string,
      name?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetGeneralProductQueryVariables = {
  id: string,
};

export type GetGeneralProductQuery = {
  getGeneralProduct?:  {
    __typename: "GeneralProduct",
    id: string,
    link: string,
    name: string,
    brandName: string,
    description?: string | null,
    imageUrl?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListGeneralProductsQueryVariables = {
  filter?: ModelGeneralProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGeneralProductsQuery = {
  listGeneralProducts?:  {
    __typename: "ModelGeneralProductConnection",
    items:  Array< {
      __typename: "GeneralProduct",
      id: string,
      link: string,
      name: string,
      brandName: string,
      description?: string | null,
      imageUrl?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncGeneralProductsQueryVariables = {
  filter?: ModelGeneralProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncGeneralProductsQuery = {
  syncGeneralProducts?:  {
    __typename: "ModelGeneralProductConnection",
    items:  Array< {
      __typename: "GeneralProduct",
      id: string,
      link: string,
      name: string,
      brandName: string,
      description?: string | null,
      imageUrl?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetEventQueryVariables = {
  id: string,
};

export type GetEventQuery = {
  getEvent?:  {
    __typename: "Event",
    id: string,
    hostName: string,
    speakerName?: string | null,
    name: string,
    description?: string | null,
    city: string,
    venue?: string | null,
    link?: string | null,
    yearMonth: string,
    startDate: string,
    endDate?: string | null,
    target?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListEventsQueryVariables = {
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsQuery = {
  listEvents?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      hostName: string,
      speakerName?: string | null,
      name: string,
      description?: string | null,
      city: string,
      venue?: string | null,
      link?: string | null,
      yearMonth: string,
      startDate: string,
      endDate?: string | null,
      target?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncEventsQueryVariables = {
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncEventsQuery = {
  syncEvents?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      hostName: string,
      speakerName?: string | null,
      name: string,
      description?: string | null,
      city: string,
      venue?: string | null,
      link?: string | null,
      yearMonth: string,
      startDate: string,
      endDate?: string | null,
      target?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type EventByYearMonthQueryVariables = {
  yearMonth: string,
  startDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventByYearMonthQuery = {
  eventByYearMonth?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      hostName: string,
      speakerName?: string | null,
      name: string,
      description?: string | null,
      city: string,
      venue?: string | null,
      link?: string | null,
      yearMonth: string,
      startDate: string,
      endDate?: string | null,
      target?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMutationResponseQueryVariables = {
  id: string,
};

export type GetMutationResponseQuery = {
  getMutationResponse?:  {
    __typename: "MutationResponse",
    success: boolean,
    reason?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMutationResponsesQueryVariables = {
  filter?: ModelMutationResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMutationResponsesQuery = {
  listMutationResponses?:  {
    __typename: "ModelMutationResponseConnection",
    items:  Array< {
      __typename: "MutationResponse",
      success: boolean,
      reason?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMutationResponsesQueryVariables = {
  filter?: ModelMutationResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMutationResponsesQuery = {
  syncMutationResponses?:  {
    __typename: "ModelMutationResponseConnection",
    items:  Array< {
      __typename: "MutationResponse",
      success: boolean,
      reason?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateDiscountPresetSubscriptionVariables = {
  filter?: ModelSubscriptionDiscountPresetFilterInput | null,
};

export type OnCreateDiscountPresetSubscription = {
  onCreateDiscountPreset?:  {
    __typename: "DiscountPreset",
    id: string,
    shopId: string,
    name: string,
    amount?: number | null,
    rate?: number | null,
    type: DiscountMode,
    enabled: boolean,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDiscountPresetSubscriptionVariables = {
  filter?: ModelSubscriptionDiscountPresetFilterInput | null,
};

export type OnUpdateDiscountPresetSubscription = {
  onUpdateDiscountPreset?:  {
    __typename: "DiscountPreset",
    id: string,
    shopId: string,
    name: string,
    amount?: number | null,
    rate?: number | null,
    type: DiscountMode,
    enabled: boolean,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDiscountPresetSubscriptionVariables = {
  filter?: ModelSubscriptionDiscountPresetFilterInput | null,
};

export type OnDeleteDiscountPresetSubscription = {
  onDeleteDiscountPreset?:  {
    __typename: "DiscountPreset",
    id: string,
    shopId: string,
    name: string,
    amount?: number | null,
    rate?: number | null,
    type: DiscountMode,
    enabled: boolean,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDesignerSubscriptionVariables = {
  filter?: ModelSubscriptionDesignerFilterInput | null,
};

export type OnCreateDesignerSubscription = {
  onCreateDesigner?:  {
    __typename: "Designer",
    id: string,
    shopId: string,
    name: string,
    naverName?: string | null,
    businessId?: string | null,
    bizItemId?: string | null,
    phoneNumber?: string | null,
    imageUrl?: string | null,
    s3Key?: string | null,
    userId?: string | null,
    youtubeUrl?: string | null,
    instagramUrl?: string | null,
    inactive?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDesignerSubscriptionVariables = {
  filter?: ModelSubscriptionDesignerFilterInput | null,
};

export type OnUpdateDesignerSubscription = {
  onUpdateDesigner?:  {
    __typename: "Designer",
    id: string,
    shopId: string,
    name: string,
    naverName?: string | null,
    businessId?: string | null,
    bizItemId?: string | null,
    phoneNumber?: string | null,
    imageUrl?: string | null,
    s3Key?: string | null,
    userId?: string | null,
    youtubeUrl?: string | null,
    instagramUrl?: string | null,
    inactive?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDesignerSubscriptionVariables = {
  filter?: ModelSubscriptionDesignerFilterInput | null,
};

export type OnDeleteDesignerSubscription = {
  onDeleteDesigner?:  {
    __typename: "Designer",
    id: string,
    shopId: string,
    name: string,
    naverName?: string | null,
    businessId?: string | null,
    bizItemId?: string | null,
    phoneNumber?: string | null,
    imageUrl?: string | null,
    s3Key?: string | null,
    userId?: string | null,
    youtubeUrl?: string | null,
    instagramUrl?: string | null,
    inactive?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateFeedbackSubscriptionVariables = {
  filter?: ModelSubscriptionFeedbackFilterInput | null,
};

export type OnCreateFeedbackSubscription = {
  onCreateFeedback?:  {
    __typename: "Feedback",
    bookingId: string,
    category: FeedbackCategory,
    shopId: string,
    shopName: string,
    designerName: string,
    designerId: string,
    date: string,
    satisfaction: number,
    comment?: string | null,
    supportMessage?: string | null,
    modelVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateFeedbackSubscriptionVariables = {
  filter?: ModelSubscriptionFeedbackFilterInput | null,
};

export type OnUpdateFeedbackSubscription = {
  onUpdateFeedback?:  {
    __typename: "Feedback",
    bookingId: string,
    category: FeedbackCategory,
    shopId: string,
    shopName: string,
    designerName: string,
    designerId: string,
    date: string,
    satisfaction: number,
    comment?: string | null,
    supportMessage?: string | null,
    modelVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteFeedbackSubscriptionVariables = {
  filter?: ModelSubscriptionFeedbackFilterInput | null,
};

export type OnDeleteFeedbackSubscription = {
  onDeleteFeedback?:  {
    __typename: "Feedback",
    bookingId: string,
    category: FeedbackCategory,
    shopId: string,
    shopName: string,
    designerName: string,
    designerId: string,
    date: string,
    satisfaction: number,
    comment?: string | null,
    supportMessage?: string | null,
    modelVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateRegularCustomerNoteSubscriptionVariables = {
  filter?: ModelSubscriptionRegularCustomerNoteFilterInput | null,
};

export type OnCreateRegularCustomerNoteSubscription = {
  onCreateRegularCustomerNote?:  {
    __typename: "RegularCustomerNote",
    bookingId: string,
    shopId: string,
    shopName?: string | null,
    customerId: string,
    customerName: string,
    levelOfStyleChange: string,
    nextSchedule?: string | null,
    comment?: string | null,
    productsInterestedIn?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    modelVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateRegularCustomerNoteSubscriptionVariables = {
  filter?: ModelSubscriptionRegularCustomerNoteFilterInput | null,
};

export type OnUpdateRegularCustomerNoteSubscription = {
  onUpdateRegularCustomerNote?:  {
    __typename: "RegularCustomerNote",
    bookingId: string,
    shopId: string,
    shopName?: string | null,
    customerId: string,
    customerName: string,
    levelOfStyleChange: string,
    nextSchedule?: string | null,
    comment?: string | null,
    productsInterestedIn?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    modelVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteRegularCustomerNoteSubscriptionVariables = {
  filter?: ModelSubscriptionRegularCustomerNoteFilterInput | null,
};

export type OnDeleteRegularCustomerNoteSubscription = {
  onDeleteRegularCustomerNote?:  {
    __typename: "RegularCustomerNote",
    bookingId: string,
    shopId: string,
    shopName?: string | null,
    customerId: string,
    customerName: string,
    levelOfStyleChange: string,
    nextSchedule?: string | null,
    comment?: string | null,
    productsInterestedIn?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    modelVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserPushTokenRequestSubscriptionVariables = {
  filter?: ModelSubscriptionUserPushTokenRequestFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserPushTokenRequestSubscription = {
  onCreateUserPushTokenRequest?:  {
    __typename: "UserPushTokenRequest",
    id: string,
    token: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserPushTokenRequestSubscriptionVariables = {
  filter?: ModelSubscriptionUserPushTokenRequestFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserPushTokenRequestSubscription = {
  onUpdateUserPushTokenRequest?:  {
    __typename: "UserPushTokenRequest",
    id: string,
    token: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserPushTokenRequestSubscriptionVariables = {
  filter?: ModelSubscriptionUserPushTokenRequestFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserPushTokenRequestSubscription = {
  onDeleteUserPushTokenRequest?:  {
    __typename: "UserPushTokenRequest",
    id: string,
    token: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserPushTokenSubscriptionVariables = {
  filter?: ModelSubscriptionUserPushTokenFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserPushTokenSubscription = {
  onCreateUserPushToken?:  {
    __typename: "UserPushToken",
    token: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserPushTokenSubscriptionVariables = {
  filter?: ModelSubscriptionUserPushTokenFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserPushTokenSubscription = {
  onUpdateUserPushToken?:  {
    __typename: "UserPushToken",
    token: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserPushTokenSubscriptionVariables = {
  filter?: ModelSubscriptionUserPushTokenFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserPushTokenSubscription = {
  onDeleteUserPushToken?:  {
    __typename: "UserPushToken",
    token: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCustomerV2SubscriptionVariables = {
  filter?: ModelSubscriptionCustomerV2FilterInput | null,
  owner?: string | null,
};

export type OnCreateCustomerV2Subscription = {
  onCreateCustomerV2?:  {
    __typename: "CustomerV2",
    id: string,
    owner?: string | null,
    name: string,
    phoneNumber: string,
    lastFourPhoneNumbers: string,
    firstVisitDate?: string | null,
    occupation?: string | null,
    drink?: string | null,
    visitCount?: number | null,
    selfCount?: number | null,
    regularCount?: number | null,
    caringCount?: number | null,
    comment?: string | null,
    modelVersion?: number | null,
    cut?: boolean | null,
    perm?: boolean | null,
    color?: boolean | null,
    clinic?: boolean | null,
    gender?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCustomerV2SubscriptionVariables = {
  filter?: ModelSubscriptionCustomerV2FilterInput | null,
  owner?: string | null,
};

export type OnUpdateCustomerV2Subscription = {
  onUpdateCustomerV2?:  {
    __typename: "CustomerV2",
    id: string,
    owner?: string | null,
    name: string,
    phoneNumber: string,
    lastFourPhoneNumbers: string,
    firstVisitDate?: string | null,
    occupation?: string | null,
    drink?: string | null,
    visitCount?: number | null,
    selfCount?: number | null,
    regularCount?: number | null,
    caringCount?: number | null,
    comment?: string | null,
    modelVersion?: number | null,
    cut?: boolean | null,
    perm?: boolean | null,
    color?: boolean | null,
    clinic?: boolean | null,
    gender?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCustomerV2SubscriptionVariables = {
  filter?: ModelSubscriptionCustomerV2FilterInput | null,
  owner?: string | null,
};

export type OnDeleteCustomerV2Subscription = {
  onDeleteCustomerV2?:  {
    __typename: "CustomerV2",
    id: string,
    owner?: string | null,
    name: string,
    phoneNumber: string,
    lastFourPhoneNumbers: string,
    firstVisitDate?: string | null,
    occupation?: string | null,
    drink?: string | null,
    visitCount?: number | null,
    selfCount?: number | null,
    regularCount?: number | null,
    caringCount?: number | null,
    comment?: string | null,
    modelVersion?: number | null,
    cut?: boolean | null,
    perm?: boolean | null,
    color?: boolean | null,
    clinic?: boolean | null,
    gender?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCaringSubscriptionVariables = {
  filter?: ModelSubscriptionCaringFilterInput | null,
  owner?: string | null,
};

export type OnCreateCaringSubscription = {
  onCreateCaring?:  {
    __typename: "Caring",
    id: string,
    owner?: string | null,
    shopName: string,
    designerName: string,
    name: string,
    phoneNumber: string,
    yearMonth: string,
    date: string,
    sentAt?: string | null,
    timestamp: number,
    pointOfToday: string,
    aftercareTips?: string | null,
    stylingTips?: string | null,
    precautions?: string | null,
    serviceRecommendation?: string | null,
    nextVisitDate?: string | null,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCaringSubscriptionVariables = {
  filter?: ModelSubscriptionCaringFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCaringSubscription = {
  onUpdateCaring?:  {
    __typename: "Caring",
    id: string,
    owner?: string | null,
    shopName: string,
    designerName: string,
    name: string,
    phoneNumber: string,
    yearMonth: string,
    date: string,
    sentAt?: string | null,
    timestamp: number,
    pointOfToday: string,
    aftercareTips?: string | null,
    stylingTips?: string | null,
    precautions?: string | null,
    serviceRecommendation?: string | null,
    nextVisitDate?: string | null,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCaringSubscriptionVariables = {
  filter?: ModelSubscriptionCaringFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCaringSubscription = {
  onDeleteCaring?:  {
    __typename: "Caring",
    id: string,
    owner?: string | null,
    shopName: string,
    designerName: string,
    name: string,
    phoneNumber: string,
    yearMonth: string,
    date: string,
    sentAt?: string | null,
    timestamp: number,
    pointOfToday: string,
    aftercareTips?: string | null,
    stylingTips?: string | null,
    precautions?: string | null,
    serviceRecommendation?: string | null,
    nextVisitDate?: string | null,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSelfDiagnosisRequestSubscriptionVariables = {
  filter?: ModelSubscriptionSelfDiagnosisRequestFilterInput | null,
  owner?: string | null,
};

export type OnCreateSelfDiagnosisRequestSubscription = {
  onCreateSelfDiagnosisRequest?:  {
    __typename: "SelfDiagnosisRequest",
    id: string,
    owner?: string | null,
    shopName: string,
    designerName: string,
    name: string,
    phoneNumber: string,
    yearMonth: string,
    date?: string | null,
    sentAt?: string | null,
    writtenAt?: string | null,
    timestamp?: number | null,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSelfDiagnosisRequestSubscriptionVariables = {
  filter?: ModelSubscriptionSelfDiagnosisRequestFilterInput | null,
  owner?: string | null,
};

export type OnUpdateSelfDiagnosisRequestSubscription = {
  onUpdateSelfDiagnosisRequest?:  {
    __typename: "SelfDiagnosisRequest",
    id: string,
    owner?: string | null,
    shopName: string,
    designerName: string,
    name: string,
    phoneNumber: string,
    yearMonth: string,
    date?: string | null,
    sentAt?: string | null,
    writtenAt?: string | null,
    timestamp?: number | null,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSelfDiagnosisRequestSubscriptionVariables = {
  filter?: ModelSubscriptionSelfDiagnosisRequestFilterInput | null,
  owner?: string | null,
};

export type OnDeleteSelfDiagnosisRequestSubscription = {
  onDeleteSelfDiagnosisRequest?:  {
    __typename: "SelfDiagnosisRequest",
    id: string,
    owner?: string | null,
    shopName: string,
    designerName: string,
    name: string,
    phoneNumber: string,
    yearMonth: string,
    date?: string | null,
    sentAt?: string | null,
    writtenAt?: string | null,
    timestamp?: number | null,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSelfDiagnosisContentSubscriptionVariables = {
  filter?: ModelSubscriptionSelfDiagnosisContentFilterInput | null,
};

export type OnCreateSelfDiagnosisContentSubscription = {
  onCreateSelfDiagnosisContent?:  {
    __typename: "SelfDiagnosisContent",
    requestId: string,
    shopName: string,
    designerName: string,
    name: string,
    yearMonth: string,
    date?: string | null,
    inflowChannel?: string | null,
    gender?: ConsultationGender | null,
    dry?: boolean | null,
    concerns?: Array< string > | null,
    frequencyOfVisits?: string | null,
    outfitStyles?: Array< string > | null,
    treatmentsInterestedIn?: Array< string > | null,
    productsForShampooing?: Array< string > | null,
    productsForStyling?: Array< string > | null,
    recentDyeing?: string | null,
    recentPerm?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    blackDyedHair?: boolean | null,
    bleachedHair?: boolean | null,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSelfDiagnosisContentSubscriptionVariables = {
  filter?: ModelSubscriptionSelfDiagnosisContentFilterInput | null,
};

export type OnUpdateSelfDiagnosisContentSubscription = {
  onUpdateSelfDiagnosisContent?:  {
    __typename: "SelfDiagnosisContent",
    requestId: string,
    shopName: string,
    designerName: string,
    name: string,
    yearMonth: string,
    date?: string | null,
    inflowChannel?: string | null,
    gender?: ConsultationGender | null,
    dry?: boolean | null,
    concerns?: Array< string > | null,
    frequencyOfVisits?: string | null,
    outfitStyles?: Array< string > | null,
    treatmentsInterestedIn?: Array< string > | null,
    productsForShampooing?: Array< string > | null,
    productsForStyling?: Array< string > | null,
    recentDyeing?: string | null,
    recentPerm?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    blackDyedHair?: boolean | null,
    bleachedHair?: boolean | null,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSelfDiagnosisContentSubscriptionVariables = {
  filter?: ModelSubscriptionSelfDiagnosisContentFilterInput | null,
};

export type OnDeleteSelfDiagnosisContentSubscription = {
  onDeleteSelfDiagnosisContent?:  {
    __typename: "SelfDiagnosisContent",
    requestId: string,
    shopName: string,
    designerName: string,
    name: string,
    yearMonth: string,
    date?: string | null,
    inflowChannel?: string | null,
    gender?: ConsultationGender | null,
    dry?: boolean | null,
    concerns?: Array< string > | null,
    frequencyOfVisits?: string | null,
    outfitStyles?: Array< string > | null,
    treatmentsInterestedIn?: Array< string > | null,
    productsForShampooing?: Array< string > | null,
    productsForStyling?: Array< string > | null,
    recentDyeing?: string | null,
    recentPerm?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    blackDyedHair?: boolean | null,
    bleachedHair?: boolean | null,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateRegularCustomerQuestionnaireRequestSubscriptionVariables = {
  filter?: ModelSubscriptionRegularCustomerQuestionnaireRequestFilterInput | null,
  owner?: string | null,
};

export type OnCreateRegularCustomerQuestionnaireRequestSubscription = {
  onCreateRegularCustomerQuestionnaireRequest?:  {
    __typename: "RegularCustomerQuestionnaireRequest",
    id: string,
    owner?: string | null,
    shopName: string,
    designerName: string,
    name: string,
    phoneNumber: string,
    yearMonth: string,
    date: string,
    sentAt?: string | null,
    writtenAt?: string | null,
    timestamp: number,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateRegularCustomerQuestionnaireRequestSubscriptionVariables = {
  filter?: ModelSubscriptionRegularCustomerQuestionnaireRequestFilterInput | null,
  owner?: string | null,
};

export type OnUpdateRegularCustomerQuestionnaireRequestSubscription = {
  onUpdateRegularCustomerQuestionnaireRequest?:  {
    __typename: "RegularCustomerQuestionnaireRequest",
    id: string,
    owner?: string | null,
    shopName: string,
    designerName: string,
    name: string,
    phoneNumber: string,
    yearMonth: string,
    date: string,
    sentAt?: string | null,
    writtenAt?: string | null,
    timestamp: number,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteRegularCustomerQuestionnaireRequestSubscriptionVariables = {
  filter?: ModelSubscriptionRegularCustomerQuestionnaireRequestFilterInput | null,
  owner?: string | null,
};

export type OnDeleteRegularCustomerQuestionnaireRequestSubscription = {
  onDeleteRegularCustomerQuestionnaireRequest?:  {
    __typename: "RegularCustomerQuestionnaireRequest",
    id: string,
    owner?: string | null,
    shopName: string,
    designerName: string,
    name: string,
    phoneNumber: string,
    yearMonth: string,
    date: string,
    sentAt?: string | null,
    writtenAt?: string | null,
    timestamp: number,
    modelVersion?: number | null,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateRegularCustomerQuestionnaireContentSubscriptionVariables = {
  filter?: ModelSubscriptionRegularCustomerQuestionnaireContentFilterInput | null,
};

export type OnCreateRegularCustomerQuestionnaireContentSubscription = {
  onCreateRegularCustomerQuestionnaireContent?:  {
    __typename: "RegularCustomerQuestionnaireContent",
    requestId: string,
    shopName: string,
    designerName: string,
    name: string,
    yearMonth: string,
    date: string,
    levelOfStyleChange: string,
    nextSchedule?: string | null,
    comment?: string | null,
    productsInterestedIn?: string | null,
    treatmentsInterestedIn?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    timestamp: number,
    modelVersion: number,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateRegularCustomerQuestionnaireContentSubscriptionVariables = {
  filter?: ModelSubscriptionRegularCustomerQuestionnaireContentFilterInput | null,
};

export type OnUpdateRegularCustomerQuestionnaireContentSubscription = {
  onUpdateRegularCustomerQuestionnaireContent?:  {
    __typename: "RegularCustomerQuestionnaireContent",
    requestId: string,
    shopName: string,
    designerName: string,
    name: string,
    yearMonth: string,
    date: string,
    levelOfStyleChange: string,
    nextSchedule?: string | null,
    comment?: string | null,
    productsInterestedIn?: string | null,
    treatmentsInterestedIn?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    timestamp: number,
    modelVersion: number,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteRegularCustomerQuestionnaireContentSubscriptionVariables = {
  filter?: ModelSubscriptionRegularCustomerQuestionnaireContentFilterInput | null,
};

export type OnDeleteRegularCustomerQuestionnaireContentSubscription = {
  onDeleteRegularCustomerQuestionnaireContent?:  {
    __typename: "RegularCustomerQuestionnaireContent",
    requestId: string,
    shopName: string,
    designerName: string,
    name: string,
    yearMonth: string,
    date: string,
    levelOfStyleChange: string,
    nextSchedule?: string | null,
    comment?: string | null,
    productsInterestedIn?: string | null,
    treatmentsInterestedIn?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    timestamp: number,
    modelVersion: number,
    customerId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserActivitySnapshotSubscriptionVariables = {
  filter?: ModelSubscriptionUserActivitySnapshotFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserActivitySnapshotSubscription = {
  onCreateUserActivitySnapshot?:  {
    __typename: "UserActivitySnapshot",
    id: string,
    owner?: string | null,
    dailyLogCount?: number | null,
    selfDiagnosisCount?: number | null,
    regularCustomerQuestionnaireCount?: number | null,
    caringCount?: number | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserActivitySnapshotSubscriptionVariables = {
  filter?: ModelSubscriptionUserActivitySnapshotFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserActivitySnapshotSubscription = {
  onUpdateUserActivitySnapshot?:  {
    __typename: "UserActivitySnapshot",
    id: string,
    owner?: string | null,
    dailyLogCount?: number | null,
    selfDiagnosisCount?: number | null,
    regularCustomerQuestionnaireCount?: number | null,
    caringCount?: number | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserActivitySnapshotSubscriptionVariables = {
  filter?: ModelSubscriptionUserActivitySnapshotFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserActivitySnapshotSubscription = {
  onDeleteUserActivitySnapshot?:  {
    __typename: "UserActivitySnapshot",
    id: string,
    owner?: string | null,
    dailyLogCount?: number | null,
    selfDiagnosisCount?: number | null,
    regularCustomerQuestionnaireCount?: number | null,
    caringCount?: number | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDailyLogSubscriptionVariables = {
  filter?: ModelSubscriptionDailyLogFilterInput | null,
  owner?: string | null,
};

export type OnCreateDailyLogSubscription = {
  onCreateDailyLog?:  {
    __typename: "DailyLog",
    id: string,
    owner?: string | null,
    shopName?: string | null,
    designerName?: string | null,
    yearMonth: string,
    date: string,
    timestamp: number,
    averageConsultationMinutes?: number | null,
    serviceRecommendationCount?: number | null,
    productRecommendationCount?: number | null,
    prepaidTicketRecommendationCount?: number | null,
    stretch?: boolean | null,
    drinkingWater?: boolean | null,
    comment?: string | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDailyLogSubscriptionVariables = {
  filter?: ModelSubscriptionDailyLogFilterInput | null,
  owner?: string | null,
};

export type OnUpdateDailyLogSubscription = {
  onUpdateDailyLog?:  {
    __typename: "DailyLog",
    id: string,
    owner?: string | null,
    shopName?: string | null,
    designerName?: string | null,
    yearMonth: string,
    date: string,
    timestamp: number,
    averageConsultationMinutes?: number | null,
    serviceRecommendationCount?: number | null,
    productRecommendationCount?: number | null,
    prepaidTicketRecommendationCount?: number | null,
    stretch?: boolean | null,
    drinkingWater?: boolean | null,
    comment?: string | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDailyLogSubscriptionVariables = {
  filter?: ModelSubscriptionDailyLogFilterInput | null,
  owner?: string | null,
};

export type OnDeleteDailyLogSubscription = {
  onDeleteDailyLog?:  {
    __typename: "DailyLog",
    id: string,
    owner?: string | null,
    shopName?: string | null,
    designerName?: string | null,
    yearMonth: string,
    date: string,
    timestamp: number,
    averageConsultationMinutes?: number | null,
    serviceRecommendationCount?: number | null,
    productRecommendationCount?: number | null,
    prepaidTicketRecommendationCount?: number | null,
    stretch?: boolean | null,
    drinkingWater?: boolean | null,
    comment?: string | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSelfDiagnosisSubscriptionVariables = {
  filter?: ModelSubscriptionSelfDiagnosisFilterInput | null,
};

export type OnCreateSelfDiagnosisSubscription = {
  onCreateSelfDiagnosis?:  {
    __typename: "SelfDiagnosis",
    bookingId: string,
    customerId: string,
    customerName: string,
    yearMonth: string,
    date: string,
    shopId: string,
    shopName: string,
    designerId: string,
    designerName: string,
    inflowChannel?: string | null,
    gender?: ConsultationGender | null,
    dry?: boolean | null,
    concerns?: Array< string > | null,
    frequencyOfVisits?: string | null,
    outfitStyles?: Array< string > | null,
    treatmentsInterestedIn?: Array< string > | null,
    productsForShampooing?: Array< string > | null,
    productsForStyling?: Array< string > | null,
    recentDyeing?: string | null,
    recentPerm?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    blackDyedHair?: boolean | null,
    bleachedHair?: boolean | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSelfDiagnosisSubscriptionVariables = {
  filter?: ModelSubscriptionSelfDiagnosisFilterInput | null,
};

export type OnUpdateSelfDiagnosisSubscription = {
  onUpdateSelfDiagnosis?:  {
    __typename: "SelfDiagnosis",
    bookingId: string,
    customerId: string,
    customerName: string,
    yearMonth: string,
    date: string,
    shopId: string,
    shopName: string,
    designerId: string,
    designerName: string,
    inflowChannel?: string | null,
    gender?: ConsultationGender | null,
    dry?: boolean | null,
    concerns?: Array< string > | null,
    frequencyOfVisits?: string | null,
    outfitStyles?: Array< string > | null,
    treatmentsInterestedIn?: Array< string > | null,
    productsForShampooing?: Array< string > | null,
    productsForStyling?: Array< string > | null,
    recentDyeing?: string | null,
    recentPerm?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    blackDyedHair?: boolean | null,
    bleachedHair?: boolean | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSelfDiagnosisSubscriptionVariables = {
  filter?: ModelSubscriptionSelfDiagnosisFilterInput | null,
};

export type OnDeleteSelfDiagnosisSubscription = {
  onDeleteSelfDiagnosis?:  {
    __typename: "SelfDiagnosis",
    bookingId: string,
    customerId: string,
    customerName: string,
    yearMonth: string,
    date: string,
    shopId: string,
    shopName: string,
    designerId: string,
    designerName: string,
    inflowChannel?: string | null,
    gender?: ConsultationGender | null,
    dry?: boolean | null,
    concerns?: Array< string > | null,
    frequencyOfVisits?: string | null,
    outfitStyles?: Array< string > | null,
    treatmentsInterestedIn?: Array< string > | null,
    productsForShampooing?: Array< string > | null,
    productsForStyling?: Array< string > | null,
    recentDyeing?: string | null,
    recentPerm?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    blackDyedHair?: boolean | null,
    bleachedHair?: boolean | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateConsultationSubscriptionVariables = {
  filter?: ModelSubscriptionConsultationFilterInput | null,
};

export type OnCreateConsultationSubscription = {
  onCreateConsultation?:  {
    __typename: "Consultation",
    id: string,
    bookingId?: string | null,
    customer:  {
      __typename: "NestedCustomer",
      phoneNumber?: string | null,
      name?: string | null,
      id?: string | null,
      shopIds?: Array< string > | null,
    },
    customerId: string,
    name?: string | null,
    yearMonth?: string | null,
    date?: string | null,
    shopId: string,
    status: ConsultationStatus,
    selfDiagnosisStatus: ConsultationSelfDiagnosisStatus,
    mode?: ConsultationMode | null,
    inflowChannel?: string | null,
    gender?: ConsultationGender | null,
    dry?: boolean | null,
    concerns?: Array< string > | null,
    frequencyOfVisits?: string | null,
    outfitStyles?: Array< string > | null,
    treatmentsInterestedIn?: Array< string > | null,
    productsForShampooing?: Array< string > | null,
    productsForStyling?: Array< string > | null,
    neckLength?: string | null,
    neckThickness?: string | null,
    shoulderWidth?: string | null,
    shoulderShape?: string | null,
    hairColor?: string | null,
    virginHairColor?: string | null,
    colorfulnessOfHair?: string | null,
    colorfulnessesOfHair?: Array< string > | null,
    recentDyeing?: string | null,
    recentFirm?: string | null,
    damageDegreeOfHair?: string | null,
    amountOfHair?: string | null,
    thicknessOfHair?: string | null,
    curlDegreeOfHair?: string | null,
    faceShape?: string | null,
    faceShapes?: Array< string > | null,
    frontHeadShape?: string | null,
    sideHeadShape?: string | null,
    solution?:  {
      __typename: "Solution",
      face?: string | null,
      hair?: string | null,
      needs?: string | null,
      point?: string | null,
      aftercareTips?: string | null,
      stylingTips?: string | null,
      precautions?: string | null,
      recommendedTreatment?: string | null,
      nextVisitDate?: string | null,
      link?: string | null,
    } | null,
    solutionSentAt?: string | null,
    hairRecommendation?:  {
      __typename: "HairRecommendation",
      bangs?: string | null,
      hairLength?: string | null,
      hairVolume?: string | null,
      hairVolumes?: Array< string > | null,
      haircut?: string | null,
      haircuts?: Array< string > | null,
      hairColorMenu?: string | null,
      hairColorType?: string | null,
      hairFirm?: string | null,
      hairCare?: string | null,
    } | null,
    products?:  Array< {
      __typename: "SolutionProduct",
      title: string,
      url: string,
      imageFilePath: string,
      description?: string | null,
    } > | null,
    shopName?: string | null,
    designerName?: string | null,
    designerId?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateConsultationSubscriptionVariables = {
  filter?: ModelSubscriptionConsultationFilterInput | null,
};

export type OnUpdateConsultationSubscription = {
  onUpdateConsultation?:  {
    __typename: "Consultation",
    id: string,
    bookingId?: string | null,
    customer:  {
      __typename: "NestedCustomer",
      phoneNumber?: string | null,
      name?: string | null,
      id?: string | null,
      shopIds?: Array< string > | null,
    },
    customerId: string,
    name?: string | null,
    yearMonth?: string | null,
    date?: string | null,
    shopId: string,
    status: ConsultationStatus,
    selfDiagnosisStatus: ConsultationSelfDiagnosisStatus,
    mode?: ConsultationMode | null,
    inflowChannel?: string | null,
    gender?: ConsultationGender | null,
    dry?: boolean | null,
    concerns?: Array< string > | null,
    frequencyOfVisits?: string | null,
    outfitStyles?: Array< string > | null,
    treatmentsInterestedIn?: Array< string > | null,
    productsForShampooing?: Array< string > | null,
    productsForStyling?: Array< string > | null,
    neckLength?: string | null,
    neckThickness?: string | null,
    shoulderWidth?: string | null,
    shoulderShape?: string | null,
    hairColor?: string | null,
    virginHairColor?: string | null,
    colorfulnessOfHair?: string | null,
    colorfulnessesOfHair?: Array< string > | null,
    recentDyeing?: string | null,
    recentFirm?: string | null,
    damageDegreeOfHair?: string | null,
    amountOfHair?: string | null,
    thicknessOfHair?: string | null,
    curlDegreeOfHair?: string | null,
    faceShape?: string | null,
    faceShapes?: Array< string > | null,
    frontHeadShape?: string | null,
    sideHeadShape?: string | null,
    solution?:  {
      __typename: "Solution",
      face?: string | null,
      hair?: string | null,
      needs?: string | null,
      point?: string | null,
      aftercareTips?: string | null,
      stylingTips?: string | null,
      precautions?: string | null,
      recommendedTreatment?: string | null,
      nextVisitDate?: string | null,
      link?: string | null,
    } | null,
    solutionSentAt?: string | null,
    hairRecommendation?:  {
      __typename: "HairRecommendation",
      bangs?: string | null,
      hairLength?: string | null,
      hairVolume?: string | null,
      hairVolumes?: Array< string > | null,
      haircut?: string | null,
      haircuts?: Array< string > | null,
      hairColorMenu?: string | null,
      hairColorType?: string | null,
      hairFirm?: string | null,
      hairCare?: string | null,
    } | null,
    products?:  Array< {
      __typename: "SolutionProduct",
      title: string,
      url: string,
      imageFilePath: string,
      description?: string | null,
    } > | null,
    shopName?: string | null,
    designerName?: string | null,
    designerId?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteConsultationSubscriptionVariables = {
  filter?: ModelSubscriptionConsultationFilterInput | null,
};

export type OnDeleteConsultationSubscription = {
  onDeleteConsultation?:  {
    __typename: "Consultation",
    id: string,
    bookingId?: string | null,
    customer:  {
      __typename: "NestedCustomer",
      phoneNumber?: string | null,
      name?: string | null,
      id?: string | null,
      shopIds?: Array< string > | null,
    },
    customerId: string,
    name?: string | null,
    yearMonth?: string | null,
    date?: string | null,
    shopId: string,
    status: ConsultationStatus,
    selfDiagnosisStatus: ConsultationSelfDiagnosisStatus,
    mode?: ConsultationMode | null,
    inflowChannel?: string | null,
    gender?: ConsultationGender | null,
    dry?: boolean | null,
    concerns?: Array< string > | null,
    frequencyOfVisits?: string | null,
    outfitStyles?: Array< string > | null,
    treatmentsInterestedIn?: Array< string > | null,
    productsForShampooing?: Array< string > | null,
    productsForStyling?: Array< string > | null,
    neckLength?: string | null,
    neckThickness?: string | null,
    shoulderWidth?: string | null,
    shoulderShape?: string | null,
    hairColor?: string | null,
    virginHairColor?: string | null,
    colorfulnessOfHair?: string | null,
    colorfulnessesOfHair?: Array< string > | null,
    recentDyeing?: string | null,
    recentFirm?: string | null,
    damageDegreeOfHair?: string | null,
    amountOfHair?: string | null,
    thicknessOfHair?: string | null,
    curlDegreeOfHair?: string | null,
    faceShape?: string | null,
    faceShapes?: Array< string > | null,
    frontHeadShape?: string | null,
    sideHeadShape?: string | null,
    solution?:  {
      __typename: "Solution",
      face?: string | null,
      hair?: string | null,
      needs?: string | null,
      point?: string | null,
      aftercareTips?: string | null,
      stylingTips?: string | null,
      precautions?: string | null,
      recommendedTreatment?: string | null,
      nextVisitDate?: string | null,
      link?: string | null,
    } | null,
    solutionSentAt?: string | null,
    hairRecommendation?:  {
      __typename: "HairRecommendation",
      bangs?: string | null,
      hairLength?: string | null,
      hairVolume?: string | null,
      hairVolumes?: Array< string > | null,
      haircut?: string | null,
      haircuts?: Array< string > | null,
      hairColorMenu?: string | null,
      hairColorType?: string | null,
      hairFirm?: string | null,
      hairCare?: string | null,
    } | null,
    products?:  Array< {
      __typename: "SolutionProduct",
      title: string,
      url: string,
      imageFilePath: string,
      description?: string | null,
    } > | null,
    shopName?: string | null,
    designerName?: string | null,
    designerId?: string | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    modelVersion?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAnonymousPhotoSubscriptionVariables = {
  filter?: ModelSubscriptionAnonymousPhotoFilterInput | null,
};

export type OnCreateAnonymousPhotoSubscription = {
  onCreateAnonymousPhoto?:  {
    __typename: "AnonymousPhoto",
    id: string,
    bookingId?: string | null,
    consultationId?: string | null,
    requestId?: string | null,
    s3Key: string,
    displayOrder: number,
    category: AnonymousPhotoCategory,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAnonymousPhotoSubscriptionVariables = {
  filter?: ModelSubscriptionAnonymousPhotoFilterInput | null,
};

export type OnUpdateAnonymousPhotoSubscription = {
  onUpdateAnonymousPhoto?:  {
    __typename: "AnonymousPhoto",
    id: string,
    bookingId?: string | null,
    consultationId?: string | null,
    requestId?: string | null,
    s3Key: string,
    displayOrder: number,
    category: AnonymousPhotoCategory,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAnonymousPhotoSubscriptionVariables = {
  filter?: ModelSubscriptionAnonymousPhotoFilterInput | null,
};

export type OnDeleteAnonymousPhotoSubscription = {
  onDeleteAnonymousPhoto?:  {
    __typename: "AnonymousPhoto",
    id: string,
    bookingId?: string | null,
    consultationId?: string | null,
    requestId?: string | null,
    s3Key: string,
    displayOrder: number,
    category: AnonymousPhotoCategory,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePhotoSubscriptionVariables = {
  filter?: ModelSubscriptionPhotoFilterInput | null,
  owner?: string | null,
};

export type OnCreatePhotoSubscription = {
  onCreatePhoto?:  {
    __typename: "Photo",
    id: string,
    bookingId?: string | null,
    s3Key: string,
    displayOrder: number,
    identityId: string,
    description?: string | null,
    category: PhotoCategory,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePhotoSubscriptionVariables = {
  filter?: ModelSubscriptionPhotoFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePhotoSubscription = {
  onUpdatePhoto?:  {
    __typename: "Photo",
    id: string,
    bookingId?: string | null,
    s3Key: string,
    displayOrder: number,
    identityId: string,
    description?: string | null,
    category: PhotoCategory,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePhotoSubscriptionVariables = {
  filter?: ModelSubscriptionPhotoFilterInput | null,
  owner?: string | null,
};

export type OnDeletePhotoSubscription = {
  onDeletePhoto?:  {
    __typename: "Photo",
    id: string,
    bookingId?: string | null,
    s3Key: string,
    displayOrder: number,
    identityId: string,
    description?: string | null,
    category: PhotoCategory,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCheckInSubscriptionVariables = {
  filter?: ModelSubscriptionCheckInFilterInput | null,
};

export type OnCreateCheckInSubscription = {
  onCreateCheckIn?:  {
    __typename: "CheckIn",
    bookingId: string,
    shopId: string,
    checkInRequests?: Array< string > | null,
    drink?: string | null,
    modelVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCheckInSubscriptionVariables = {
  filter?: ModelSubscriptionCheckInFilterInput | null,
};

export type OnUpdateCheckInSubscription = {
  onUpdateCheckIn?:  {
    __typename: "CheckIn",
    bookingId: string,
    shopId: string,
    checkInRequests?: Array< string > | null,
    drink?: string | null,
    modelVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCheckInSubscriptionVariables = {
  filter?: ModelSubscriptionCheckInFilterInput | null,
};

export type OnDeleteCheckInSubscription = {
  onDeleteCheckIn?:  {
    __typename: "CheckIn",
    bookingId: string,
    shopId: string,
    checkInRequests?: Array< string > | null,
    drink?: string | null,
    modelVersion: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateBookingSubscriptionVariables = {
  filter?: ModelSubscriptionBookingFilterInput | null,
};

export type OnCreateBookingSubscription = {
  onCreateBooking?:  {
    __typename: "Booking",
    id: string,
    yearMonth?: string | null,
    date: string,
    time: string,
    customer?:  {
      __typename: "NestedCustomer",
      phoneNumber?: string | null,
      name?: string | null,
      id?: string | null,
      shopIds?: Array< string > | null,
    } | null,
    customerId: string,
    name?: string | null,
    phoneNumber?: string | null,
    consultationId?: string | null,
    consultation?:  {
      __typename: "NestedConsultation",
      status?: ConsultationStatus | null,
      selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
      solutionSentAt?: string | null,
      inflowChannel?: string | null,
      hasPhotos?: boolean | null,
    } | null,
    hasSelfDiagnosis?: boolean | null,
    hasRegularCustomerNote?: boolean | null,
    designerName?: string | null,
    designerId?: string | null,
    optionName?: string | null,
    shopId: string,
    shopName?: string | null,
    naverBookingNumber?: string | null,
    bookingStatus?: BookingStatus | null,
    serviceStatus?: ServiceStatus | null,
    paymentStatus?: PaymentStatus | null,
    requestMessage?: string | null,
    designerMemo?: string | null,
    naverSnapshot?:  {
      __typename: "NaverSnapshot",
      price: number,
      bizItemPrice: number,
      onsitePrice?: number | null,
      email?: string | null,
      isOnsitePayment?: boolean | null,
    } | null,
    naverOptions?:  Array< {
      __typename: "NaverServiceOption",
      categoryName: string,
      name: string,
      originalName?: string | null,
      desc?: string | null,
      price?: number | null,
    } > | null,
    naverPayments?:  Array< {
      __typename: "NaverPayment",
      method?: NaverPaymentMethod | null,
      moment?: NaverPaymentMoment | null,
      provider?: NaverPaymentProvider | null,
      paymentId: number,
      bookingId: number,
      amount: number,
      providerDiscountAmount?: number | null,
      regDateTime?: string | null,
      status: NaverPaymentStatus,
      statusHistories?:  Array< {
        __typename: "NaverPaymentStatusHistory",
        status: NaverPaymentStatus,
        dateTime?: string | null,
      } > | null,
    } > | null,
    initialPrice?: number | null,
    finalPrice?: number | null,
    totalServiceSales?: number | null,
    totalProductSales?: number | null,
    initialDeposit?: number | null,
    paidAmount?: number | null,
    paidInCashAmount?: number | null,
    paidByCardAmount?: number | null,
    paidByPrepaidPassAmount?: number | null,
    paidByNaverPayAmount?: number | null,
    paidAt?: string | null,
    paymentMemo?: string | null,
    checkInRequests?: Array< string > | null,
    drink?: string | null,
    checkedInAt?: string | null,
    origin?: BookingOrigin | null,
    options?:  Array< {
      __typename: "BookingServiceOption",
      categoryName: string,
      name: string,
      originalPrice: number,
      discount?: number | null,
      discountRate?: number | null,
      price: number,
      discountPreset?:  {
        __typename: "DiscountPresetSnapshot",
        id?: string | null,
        shopId?: string | null,
        name?: string | null,
        amount?: number | null,
        rate?: number | null,
        type?: DiscountMode | null,
      } | null,
    } > | null,
    productOptions?:  Array< {
      __typename: "BookingProductOption",
      name: string,
      price: number,
      quantity: number,
      product?:  {
        __typename: "ProductSnapshot",
        id?: string | null,
        name?: string | null,
        shopId?: string | null,
        brandId?: string | null,
        brandName?: string | null,
        costPrice?: number | null,
        sellingPrice?: number | null,
      } | null,
    } > | null,
    signature?: string | null,
    signedAt?: string | null,
    recentNaverDataReflection?: string | null,
    selfDiagnosisSentAt?: string | null,
    feedbackRequestSentAt?: string | null,
    hasFeedback?: boolean | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    hasDesignCompletionPhotos?: boolean | null,
    designCompletionPhotos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    signatureOfPortraitRights?: string | null,
    signedAtOfPortraitRights?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateBookingSubscriptionVariables = {
  filter?: ModelSubscriptionBookingFilterInput | null,
};

export type OnUpdateBookingSubscription = {
  onUpdateBooking?:  {
    __typename: "Booking",
    id: string,
    yearMonth?: string | null,
    date: string,
    time: string,
    customer?:  {
      __typename: "NestedCustomer",
      phoneNumber?: string | null,
      name?: string | null,
      id?: string | null,
      shopIds?: Array< string > | null,
    } | null,
    customerId: string,
    name?: string | null,
    phoneNumber?: string | null,
    consultationId?: string | null,
    consultation?:  {
      __typename: "NestedConsultation",
      status?: ConsultationStatus | null,
      selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
      solutionSentAt?: string | null,
      inflowChannel?: string | null,
      hasPhotos?: boolean | null,
    } | null,
    hasSelfDiagnosis?: boolean | null,
    hasRegularCustomerNote?: boolean | null,
    designerName?: string | null,
    designerId?: string | null,
    optionName?: string | null,
    shopId: string,
    shopName?: string | null,
    naverBookingNumber?: string | null,
    bookingStatus?: BookingStatus | null,
    serviceStatus?: ServiceStatus | null,
    paymentStatus?: PaymentStatus | null,
    requestMessage?: string | null,
    designerMemo?: string | null,
    naverSnapshot?:  {
      __typename: "NaverSnapshot",
      price: number,
      bizItemPrice: number,
      onsitePrice?: number | null,
      email?: string | null,
      isOnsitePayment?: boolean | null,
    } | null,
    naverOptions?:  Array< {
      __typename: "NaverServiceOption",
      categoryName: string,
      name: string,
      originalName?: string | null,
      desc?: string | null,
      price?: number | null,
    } > | null,
    naverPayments?:  Array< {
      __typename: "NaverPayment",
      method?: NaverPaymentMethod | null,
      moment?: NaverPaymentMoment | null,
      provider?: NaverPaymentProvider | null,
      paymentId: number,
      bookingId: number,
      amount: number,
      providerDiscountAmount?: number | null,
      regDateTime?: string | null,
      status: NaverPaymentStatus,
      statusHistories?:  Array< {
        __typename: "NaverPaymentStatusHistory",
        status: NaverPaymentStatus,
        dateTime?: string | null,
      } > | null,
    } > | null,
    initialPrice?: number | null,
    finalPrice?: number | null,
    totalServiceSales?: number | null,
    totalProductSales?: number | null,
    initialDeposit?: number | null,
    paidAmount?: number | null,
    paidInCashAmount?: number | null,
    paidByCardAmount?: number | null,
    paidByPrepaidPassAmount?: number | null,
    paidByNaverPayAmount?: number | null,
    paidAt?: string | null,
    paymentMemo?: string | null,
    checkInRequests?: Array< string > | null,
    drink?: string | null,
    checkedInAt?: string | null,
    origin?: BookingOrigin | null,
    options?:  Array< {
      __typename: "BookingServiceOption",
      categoryName: string,
      name: string,
      originalPrice: number,
      discount?: number | null,
      discountRate?: number | null,
      price: number,
      discountPreset?:  {
        __typename: "DiscountPresetSnapshot",
        id?: string | null,
        shopId?: string | null,
        name?: string | null,
        amount?: number | null,
        rate?: number | null,
        type?: DiscountMode | null,
      } | null,
    } > | null,
    productOptions?:  Array< {
      __typename: "BookingProductOption",
      name: string,
      price: number,
      quantity: number,
      product?:  {
        __typename: "ProductSnapshot",
        id?: string | null,
        name?: string | null,
        shopId?: string | null,
        brandId?: string | null,
        brandName?: string | null,
        costPrice?: number | null,
        sellingPrice?: number | null,
      } | null,
    } > | null,
    signature?: string | null,
    signedAt?: string | null,
    recentNaverDataReflection?: string | null,
    selfDiagnosisSentAt?: string | null,
    feedbackRequestSentAt?: string | null,
    hasFeedback?: boolean | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    hasDesignCompletionPhotos?: boolean | null,
    designCompletionPhotos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    signatureOfPortraitRights?: string | null,
    signedAtOfPortraitRights?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteBookingSubscriptionVariables = {
  filter?: ModelSubscriptionBookingFilterInput | null,
};

export type OnDeleteBookingSubscription = {
  onDeleteBooking?:  {
    __typename: "Booking",
    id: string,
    yearMonth?: string | null,
    date: string,
    time: string,
    customer?:  {
      __typename: "NestedCustomer",
      phoneNumber?: string | null,
      name?: string | null,
      id?: string | null,
      shopIds?: Array< string > | null,
    } | null,
    customerId: string,
    name?: string | null,
    phoneNumber?: string | null,
    consultationId?: string | null,
    consultation?:  {
      __typename: "NestedConsultation",
      status?: ConsultationStatus | null,
      selfDiagnosisStatus?: ConsultationSelfDiagnosisStatus | null,
      solutionSentAt?: string | null,
      inflowChannel?: string | null,
      hasPhotos?: boolean | null,
    } | null,
    hasSelfDiagnosis?: boolean | null,
    hasRegularCustomerNote?: boolean | null,
    designerName?: string | null,
    designerId?: string | null,
    optionName?: string | null,
    shopId: string,
    shopName?: string | null,
    naverBookingNumber?: string | null,
    bookingStatus?: BookingStatus | null,
    serviceStatus?: ServiceStatus | null,
    paymentStatus?: PaymentStatus | null,
    requestMessage?: string | null,
    designerMemo?: string | null,
    naverSnapshot?:  {
      __typename: "NaverSnapshot",
      price: number,
      bizItemPrice: number,
      onsitePrice?: number | null,
      email?: string | null,
      isOnsitePayment?: boolean | null,
    } | null,
    naverOptions?:  Array< {
      __typename: "NaverServiceOption",
      categoryName: string,
      name: string,
      originalName?: string | null,
      desc?: string | null,
      price?: number | null,
    } > | null,
    naverPayments?:  Array< {
      __typename: "NaverPayment",
      method?: NaverPaymentMethod | null,
      moment?: NaverPaymentMoment | null,
      provider?: NaverPaymentProvider | null,
      paymentId: number,
      bookingId: number,
      amount: number,
      providerDiscountAmount?: number | null,
      regDateTime?: string | null,
      status: NaverPaymentStatus,
      statusHistories?:  Array< {
        __typename: "NaverPaymentStatusHistory",
        status: NaverPaymentStatus,
        dateTime?: string | null,
      } > | null,
    } > | null,
    initialPrice?: number | null,
    finalPrice?: number | null,
    totalServiceSales?: number | null,
    totalProductSales?: number | null,
    initialDeposit?: number | null,
    paidAmount?: number | null,
    paidInCashAmount?: number | null,
    paidByCardAmount?: number | null,
    paidByPrepaidPassAmount?: number | null,
    paidByNaverPayAmount?: number | null,
    paidAt?: string | null,
    paymentMemo?: string | null,
    checkInRequests?: Array< string > | null,
    drink?: string | null,
    checkedInAt?: string | null,
    origin?: BookingOrigin | null,
    options?:  Array< {
      __typename: "BookingServiceOption",
      categoryName: string,
      name: string,
      originalPrice: number,
      discount?: number | null,
      discountRate?: number | null,
      price: number,
      discountPreset?:  {
        __typename: "DiscountPresetSnapshot",
        id?: string | null,
        shopId?: string | null,
        name?: string | null,
        amount?: number | null,
        rate?: number | null,
        type?: DiscountMode | null,
      } | null,
    } > | null,
    productOptions?:  Array< {
      __typename: "BookingProductOption",
      name: string,
      price: number,
      quantity: number,
      product?:  {
        __typename: "ProductSnapshot",
        id?: string | null,
        name?: string | null,
        shopId?: string | null,
        brandId?: string | null,
        brandName?: string | null,
        costPrice?: number | null,
        sellingPrice?: number | null,
      } | null,
    } > | null,
    signature?: string | null,
    signedAt?: string | null,
    recentNaverDataReflection?: string | null,
    selfDiagnosisSentAt?: string | null,
    feedbackRequestSentAt?: string | null,
    hasFeedback?: boolean | null,
    hasPhotos?: boolean | null,
    photos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    hasDesignCompletionPhotos?: boolean | null,
    designCompletionPhotos?:  Array< {
      __typename: "NestedPhoto",
      s3Key: string,
      displayOrder: number,
    } > | null,
    signatureOfPortraitRights?: string | null,
    signedAtOfPortraitRights?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCustomerSubscriptionVariables = {
  filter?: ModelSubscriptionCustomerFilterInput | null,
};

export type OnCreateCustomerSubscription = {
  onCreateCustomer?:  {
    __typename: "Customer",
    id: string,
    name: string,
    phoneNumber?: string | null,
    lastFourPhoneNumbers?: string | null,
    firstVisitDate?: string | null,
    occupation?: string | null,
    drink?: string | null,
    dress?: string | null,
    trouble?: string | null,
    memo?: string | null,
    bookingCount?: number | null,
    consultationCount?: number | null,
    consultationDoneCount?: number | null,
    signature?: string | null,
    totalRevenue?: number | null,
    vehicleNumber?: string | null,
    shopId: string,
    shopIds?: Array< string > | null,
    shopIdsString?: string | null,
    createdAt?: string | null,
    prepaidPassBalance?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCustomerSubscriptionVariables = {
  filter?: ModelSubscriptionCustomerFilterInput | null,
};

export type OnUpdateCustomerSubscription = {
  onUpdateCustomer?:  {
    __typename: "Customer",
    id: string,
    name: string,
    phoneNumber?: string | null,
    lastFourPhoneNumbers?: string | null,
    firstVisitDate?: string | null,
    occupation?: string | null,
    drink?: string | null,
    dress?: string | null,
    trouble?: string | null,
    memo?: string | null,
    bookingCount?: number | null,
    consultationCount?: number | null,
    consultationDoneCount?: number | null,
    signature?: string | null,
    totalRevenue?: number | null,
    vehicleNumber?: string | null,
    shopId: string,
    shopIds?: Array< string > | null,
    shopIdsString?: string | null,
    createdAt?: string | null,
    prepaidPassBalance?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCustomerSubscriptionVariables = {
  filter?: ModelSubscriptionCustomerFilterInput | null,
};

export type OnDeleteCustomerSubscription = {
  onDeleteCustomer?:  {
    __typename: "Customer",
    id: string,
    name: string,
    phoneNumber?: string | null,
    lastFourPhoneNumbers?: string | null,
    firstVisitDate?: string | null,
    occupation?: string | null,
    drink?: string | null,
    dress?: string | null,
    trouble?: string | null,
    memo?: string | null,
    bookingCount?: number | null,
    consultationCount?: number | null,
    consultationDoneCount?: number | null,
    signature?: string | null,
    totalRevenue?: number | null,
    vehicleNumber?: string | null,
    shopId: string,
    shopIds?: Array< string > | null,
    shopIdsString?: string | null,
    createdAt?: string | null,
    prepaidPassBalance?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateShopSubscriptionVariables = {
  filter?: ModelSubscriptionShopFilterInput | null,
  owner?: string | null,
};

export type OnCreateShopSubscription = {
  onCreateShop?:  {
    __typename: "Shop",
    id: string,
    name: string,
    naverBusinessId?: string | null,
    hasLocker?: boolean | null,
    bookingQRS3Key?: string | null,
    creatorId?: string | null,
    usePreSelfDiagnosis?: boolean | null,
    useFeedbackRequest?: boolean | null,
    owner?: string | null,
    ownerName?: string | null,
    ownerPhoneNumber?: string | null,
    internPhoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateShopSubscriptionVariables = {
  filter?: ModelSubscriptionShopFilterInput | null,
  owner?: string | null,
};

export type OnUpdateShopSubscription = {
  onUpdateShop?:  {
    __typename: "Shop",
    id: string,
    name: string,
    naverBusinessId?: string | null,
    hasLocker?: boolean | null,
    bookingQRS3Key?: string | null,
    creatorId?: string | null,
    usePreSelfDiagnosis?: boolean | null,
    useFeedbackRequest?: boolean | null,
    owner?: string | null,
    ownerName?: string | null,
    ownerPhoneNumber?: string | null,
    internPhoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteShopSubscriptionVariables = {
  filter?: ModelSubscriptionShopFilterInput | null,
  owner?: string | null,
};

export type OnDeleteShopSubscription = {
  onDeleteShop?:  {
    __typename: "Shop",
    id: string,
    name: string,
    naverBusinessId?: string | null,
    hasLocker?: boolean | null,
    bookingQRS3Key?: string | null,
    creatorId?: string | null,
    usePreSelfDiagnosis?: boolean | null,
    useFeedbackRequest?: boolean | null,
    owner?: string | null,
    ownerName?: string | null,
    ownerPhoneNumber?: string | null,
    internPhoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateShopServiceDrinkSubscriptionVariables = {
  filter?: ModelSubscriptionShopServiceDrinkFilterInput | null,
};

export type OnCreateShopServiceDrinkSubscription = {
  onCreateShopServiceDrink?:  {
    __typename: "ShopServiceDrink",
    id: string,
    name: string,
    shopId: string,
    outOfStock?: boolean | null,
    displayOrder: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateShopServiceDrinkSubscriptionVariables = {
  filter?: ModelSubscriptionShopServiceDrinkFilterInput | null,
};

export type OnUpdateShopServiceDrinkSubscription = {
  onUpdateShopServiceDrink?:  {
    __typename: "ShopServiceDrink",
    id: string,
    name: string,
    shopId: string,
    outOfStock?: boolean | null,
    displayOrder: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteShopServiceDrinkSubscriptionVariables = {
  filter?: ModelSubscriptionShopServiceDrinkFilterInput | null,
};

export type OnDeleteShopServiceDrinkSubscription = {
  onDeleteShopServiceDrink?:  {
    __typename: "ShopServiceDrink",
    id: string,
    name: string,
    shopId: string,
    outOfStock?: boolean | null,
    displayOrder: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateShopUserApplicationSubscriptionVariables = {
  filter?: ModelSubscriptionShopUserApplicationFilterInput | null,
};

export type OnCreateShopUserApplicationSubscription = {
  onCreateShopUserApplication?:  {
    __typename: "ShopUserApplication",
    id: string,
    shopId: string,
    userId: string,
    phoneNumber: string,
    completed: boolean,
    name: string,
    email: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateShopUserApplicationSubscriptionVariables = {
  filter?: ModelSubscriptionShopUserApplicationFilterInput | null,
};

export type OnUpdateShopUserApplicationSubscription = {
  onUpdateShopUserApplication?:  {
    __typename: "ShopUserApplication",
    id: string,
    shopId: string,
    userId: string,
    phoneNumber: string,
    completed: boolean,
    name: string,
    email: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteShopUserApplicationSubscriptionVariables = {
  filter?: ModelSubscriptionShopUserApplicationFilterInput | null,
};

export type OnDeleteShopUserApplicationSubscription = {
  onDeleteShopUserApplication?:  {
    __typename: "ShopUserApplication",
    id: string,
    shopId: string,
    userId: string,
    phoneNumber: string,
    completed: boolean,
    name: string,
    email: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateShopUserRemovalRequestSubscriptionVariables = {
  filter?: ModelSubscriptionShopUserRemovalRequestFilterInput | null,
  owner?: string | null,
};

export type OnCreateShopUserRemovalRequestSubscription = {
  onCreateShopUserRemovalRequest?:  {
    __typename: "ShopUserRemovalRequest",
    designerId: string,
    shopId: string,
    userId: string,
    completed: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateShopUserRemovalRequestSubscriptionVariables = {
  filter?: ModelSubscriptionShopUserRemovalRequestFilterInput | null,
  owner?: string | null,
};

export type OnUpdateShopUserRemovalRequestSubscription = {
  onUpdateShopUserRemovalRequest?:  {
    __typename: "ShopUserRemovalRequest",
    designerId: string,
    shopId: string,
    userId: string,
    completed: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteShopUserRemovalRequestSubscriptionVariables = {
  filter?: ModelSubscriptionShopUserRemovalRequestFilterInput | null,
  owner?: string | null,
};

export type OnDeleteShopUserRemovalRequestSubscription = {
  onDeleteShopUserRemovalRequest?:  {
    __typename: "ShopUserRemovalRequest",
    designerId: string,
    shopId: string,
    userId: string,
    completed: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateServiceOptionSubscriptionVariables = {
  filter?: ModelSubscriptionServiceOptionFilterInput | null,
};

export type OnCreateServiceOptionSubscription = {
  onCreateServiceOption?:  {
    __typename: "ServiceOption",
    shopId: string,
    categories:  Array< {
      __typename: "ServiceOptionCategory",
      name: string,
      options:  Array< {
        __typename: "ServiceOptionItem",
        name: string,
        price?: number | null,
      } >,
    } >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateServiceOptionSubscriptionVariables = {
  filter?: ModelSubscriptionServiceOptionFilterInput | null,
};

export type OnUpdateServiceOptionSubscription = {
  onUpdateServiceOption?:  {
    __typename: "ServiceOption",
    shopId: string,
    categories:  Array< {
      __typename: "ServiceOptionCategory",
      name: string,
      options:  Array< {
        __typename: "ServiceOptionItem",
        name: string,
        price?: number | null,
      } >,
    } >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteServiceOptionSubscriptionVariables = {
  filter?: ModelSubscriptionServiceOptionFilterInput | null,
};

export type OnDeleteServiceOptionSubscription = {
  onDeleteServiceOption?:  {
    __typename: "ServiceOption",
    shopId: string,
    categories:  Array< {
      __typename: "ServiceOptionCategory",
      name: string,
      options:  Array< {
        __typename: "ServiceOptionItem",
        name: string,
        price?: number | null,
      } >,
    } >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePrepaidPassPresetSubscriptionVariables = {
  filter?: ModelSubscriptionPrepaidPassPresetFilterInput | null,
};

export type OnCreatePrepaidPassPresetSubscription = {
  onCreatePrepaidPassPreset?:  {
    __typename: "PrepaidPassPreset",
    id: string,
    shopId: string,
    name: string,
    price: number,
    availableAmount: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePrepaidPassPresetSubscriptionVariables = {
  filter?: ModelSubscriptionPrepaidPassPresetFilterInput | null,
};

export type OnUpdatePrepaidPassPresetSubscription = {
  onUpdatePrepaidPassPreset?:  {
    __typename: "PrepaidPassPreset",
    id: string,
    shopId: string,
    name: string,
    price: number,
    availableAmount: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePrepaidPassPresetSubscriptionVariables = {
  filter?: ModelSubscriptionPrepaidPassPresetFilterInput | null,
};

export type OnDeletePrepaidPassPresetSubscription = {
  onDeletePrepaidPassPreset?:  {
    __typename: "PrepaidPassPreset",
    id: string,
    shopId: string,
    name: string,
    price: number,
    availableAmount: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePrepaidPassHistorySubscriptionVariables = {
  filter?: ModelSubscriptionPrepaidPassHistoryFilterInput | null,
};

export type OnCreatePrepaidPassHistorySubscription = {
  onCreatePrepaidPassHistory?:  {
    __typename: "PrepaidPassHistory",
    id: string,
    customerId: string,
    shopId: string,
    amount: number,
    bookingId?: string | null,
    type: PrepaidPassRecordType,
    description?: string | null,
    referenceId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePrepaidPassHistorySubscriptionVariables = {
  filter?: ModelSubscriptionPrepaidPassHistoryFilterInput | null,
};

export type OnUpdatePrepaidPassHistorySubscription = {
  onUpdatePrepaidPassHistory?:  {
    __typename: "PrepaidPassHistory",
    id: string,
    customerId: string,
    shopId: string,
    amount: number,
    bookingId?: string | null,
    type: PrepaidPassRecordType,
    description?: string | null,
    referenceId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePrepaidPassHistorySubscriptionVariables = {
  filter?: ModelSubscriptionPrepaidPassHistoryFilterInput | null,
};

export type OnDeletePrepaidPassHistorySubscription = {
  onDeletePrepaidPassHistory?:  {
    __typename: "PrepaidPassHistory",
    id: string,
    customerId: string,
    shopId: string,
    amount: number,
    bookingId?: string | null,
    type: PrepaidPassRecordType,
    description?: string | null,
    referenceId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateBrandSubscriptionVariables = {
  filter?: ModelSubscriptionBrandFilterInput | null,
};

export type OnCreateBrandSubscription = {
  onCreateBrand?:  {
    __typename: "Brand",
    id: string,
    shopId: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateBrandSubscriptionVariables = {
  filter?: ModelSubscriptionBrandFilterInput | null,
};

export type OnUpdateBrandSubscription = {
  onUpdateBrand?:  {
    __typename: "Brand",
    id: string,
    shopId: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteBrandSubscriptionVariables = {
  filter?: ModelSubscriptionBrandFilterInput | null,
};

export type OnDeleteBrandSubscription = {
  onDeleteBrand?:  {
    __typename: "Brand",
    id: string,
    shopId: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
};

export type OnCreateProductSubscription = {
  onCreateProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    shopId: string,
    brandId: string,
    brandName: string,
    costPrice: number,
    sellingPrice: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
};

export type OnUpdateProductSubscription = {
  onUpdateProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    shopId: string,
    brandId: string,
    brandName: string,
    costPrice: number,
    sellingPrice: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
};

export type OnDeleteProductSubscription = {
  onDeleteProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    shopId: string,
    brandId: string,
    brandName: string,
    costPrice: number,
    sellingPrice: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateConsultationSolutionPresetSubscriptionVariables = {
  filter?: ModelSubscriptionConsultationSolutionPresetFilterInput | null,
};

export type OnCreateConsultationSolutionPresetSubscription = {
  onCreateConsultationSolutionPreset?:  {
    __typename: "ConsultationSolutionPreset",
    id: string,
    category: string,
    sentence: string,
    priority: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateConsultationSolutionPresetSubscriptionVariables = {
  filter?: ModelSubscriptionConsultationSolutionPresetFilterInput | null,
};

export type OnUpdateConsultationSolutionPresetSubscription = {
  onUpdateConsultationSolutionPreset?:  {
    __typename: "ConsultationSolutionPreset",
    id: string,
    category: string,
    sentence: string,
    priority: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteConsultationSolutionPresetSubscriptionVariables = {
  filter?: ModelSubscriptionConsultationSolutionPresetFilterInput | null,
};

export type OnDeleteConsultationSolutionPresetSubscription = {
  onDeleteConsultationSolutionPreset?:  {
    __typename: "ConsultationSolutionPreset",
    id: string,
    category: string,
    sentence: string,
    priority: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCoupangProductSubscriptionVariables = {
  filter?: ModelSubscriptionCoupangProductFilterInput | null,
};

export type OnCreateCoupangProductSubscription = {
  onCreateCoupangProduct?:  {
    __typename: "CoupangProduct",
    id: string,
    src: string,
    name?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCoupangProductSubscriptionVariables = {
  filter?: ModelSubscriptionCoupangProductFilterInput | null,
};

export type OnUpdateCoupangProductSubscription = {
  onUpdateCoupangProduct?:  {
    __typename: "CoupangProduct",
    id: string,
    src: string,
    name?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCoupangProductSubscriptionVariables = {
  filter?: ModelSubscriptionCoupangProductFilterInput | null,
};

export type OnDeleteCoupangProductSubscription = {
  onDeleteCoupangProduct?:  {
    __typename: "CoupangProduct",
    id: string,
    src: string,
    name?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateGeneralProductSubscriptionVariables = {
  filter?: ModelSubscriptionGeneralProductFilterInput | null,
};

export type OnCreateGeneralProductSubscription = {
  onCreateGeneralProduct?:  {
    __typename: "GeneralProduct",
    id: string,
    link: string,
    name: string,
    brandName: string,
    description?: string | null,
    imageUrl?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateGeneralProductSubscriptionVariables = {
  filter?: ModelSubscriptionGeneralProductFilterInput | null,
};

export type OnUpdateGeneralProductSubscription = {
  onUpdateGeneralProduct?:  {
    __typename: "GeneralProduct",
    id: string,
    link: string,
    name: string,
    brandName: string,
    description?: string | null,
    imageUrl?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteGeneralProductSubscriptionVariables = {
  filter?: ModelSubscriptionGeneralProductFilterInput | null,
};

export type OnDeleteGeneralProductSubscription = {
  onDeleteGeneralProduct?:  {
    __typename: "GeneralProduct",
    id: string,
    link: string,
    name: string,
    brandName: string,
    description?: string | null,
    imageUrl?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateEventSubscriptionVariables = {
  filter?: ModelSubscriptionEventFilterInput | null,
};

export type OnCreateEventSubscription = {
  onCreateEvent?:  {
    __typename: "Event",
    id: string,
    hostName: string,
    speakerName?: string | null,
    name: string,
    description?: string | null,
    city: string,
    venue?: string | null,
    link?: string | null,
    yearMonth: string,
    startDate: string,
    endDate?: string | null,
    target?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateEventSubscriptionVariables = {
  filter?: ModelSubscriptionEventFilterInput | null,
};

export type OnUpdateEventSubscription = {
  onUpdateEvent?:  {
    __typename: "Event",
    id: string,
    hostName: string,
    speakerName?: string | null,
    name: string,
    description?: string | null,
    city: string,
    venue?: string | null,
    link?: string | null,
    yearMonth: string,
    startDate: string,
    endDate?: string | null,
    target?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteEventSubscriptionVariables = {
  filter?: ModelSubscriptionEventFilterInput | null,
};

export type OnDeleteEventSubscription = {
  onDeleteEvent?:  {
    __typename: "Event",
    id: string,
    hostName: string,
    speakerName?: string | null,
    name: string,
    description?: string | null,
    city: string,
    venue?: string | null,
    link?: string | null,
    yearMonth: string,
    startDate: string,
    endDate?: string | null,
    target?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateMutationResponseSubscriptionVariables = {
  filter?: ModelSubscriptionMutationResponseFilterInput | null,
};

export type OnCreateMutationResponseSubscription = {
  onCreateMutationResponse?:  {
    __typename: "MutationResponse",
    success: boolean,
    reason?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMutationResponseSubscriptionVariables = {
  filter?: ModelSubscriptionMutationResponseFilterInput | null,
};

export type OnUpdateMutationResponseSubscription = {
  onUpdateMutationResponse?:  {
    __typename: "MutationResponse",
    success: boolean,
    reason?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMutationResponseSubscriptionVariables = {
  filter?: ModelSubscriptionMutationResponseFilterInput | null,
};

export type OnDeleteMutationResponseSubscription = {
  onDeleteMutationResponse?:  {
    __typename: "MutationResponse",
    success: boolean,
    reason?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
