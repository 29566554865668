import { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentShopId } from "../../features/common";
import {
  addDay,
  fetchBookingsForDailyClosing,
  selectBookingsForDailyClosing,
  selectDate,
  subtractDay,
} from "../../features/booking";
import numeral from "numeral";

export default function DailyClosing() {
  const dispatch = useDispatch();

  const bookingsForDailyClosing = useAppSelector(selectBookingsForDailyClosing);
  const date = useAppSelector(selectDate);
  const currentShopId = useAppSelector(selectCurrentShopId);
  const [designerNames, setDesignerNames] = useState<string[]>([]);

  useEffect(() => {
    if (!currentShopId) {
      return;
    }

    fetchBookings(currentShopId);
  }, [currentShopId, date]);

  async function fetchBookings(shopId: string) {
    dispatch(
      fetchBookingsForDailyClosing({
        shopId,
        date,
      }) as any
    );
  }

  useEffect(() => {
    const list = Array.from(
      new Set(
        bookingsForDailyClosing.map((booking) => {
          return booking.designerName?.split(" ")[0] ?? "";
        })
      )
    );

    setDesignerNames(list);
  }, [bookingsForDailyClosing]);

  return (
    <Container>
      <Row>
        <Col>
          <h4>일 마감</h4>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col></Col>
        <Col sm={6}>
          <div className="text-center">
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={() => {
                dispatch(subtractDay() as any);
              }}
            >
              이전
            </button>{" "}
            <strong>{date}</strong>{" "}
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={() => {
                dispatch(addDay() as any);
              }}
            >
              다음
            </button>
          </div>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col>
          {designerNames.map((designerName) => {
            const designerBookings = bookingsForDailyClosing.filter((booking) =>
              booking.designerName?.startsWith(designerName)
            );
            return (
              <div key={designerName}>
                <h6>{designerName}</h6>
                <Table striped>
                  <colgroup>
                    <col width="15%"></col>
                    <col width="25%"></col>
                    <col width="15%"></col>
                    <col width="15%"></col>
                    <col width="15%"></col>
                    <col width="15%"></col>
                  </colgroup>
                  <thead>
                    <th>고객명</th>
                    <th>서비스</th>
                    <th>카드</th>
                    <th>N Pay</th>
                    <th>현금</th>
                    <th>정액권</th>
                  </thead>
                  <tbody>
                    {designerBookings.map((booking) => {
                      return (
                        <tr key={booking.id}>
                          <td>{booking.customer?.name ?? ""}</td>
                          <td>
                            {booking.options?.map((item, index) => {
                              return (
                                <div key={`${item.name}${index}`}>
                                  {item.name}
                                </div>
                              );
                            })}
                            {booking.productOptions?.map((item, index) => {
                              return (
                                <div key={`${item.name}${index}`}>
                                  {item.name}
                                </div>
                              );
                            })}
                          </td>
                          <td className="text-end">
                            {numeral(booking.paidByCardAmount ?? 0).format(
                              "0,0"
                            )}
                          </td>
                          <td className="text-end">
                            {numeral(
                              (booking.paidByNaverPayAmount ?? 0) +
                                (booking.initialDeposit ?? 0)
                            ).format("0,0")}
                          </td>
                          <td className="text-end">
                            {numeral(booking.paidInCashAmount ?? 0).format(
                              "0,0"
                            )}
                          </td>
                          <td className="text-end">
                            {numeral(
                              booking.paidByPrepaidPassAmount ?? 0
                            ).format("0,0")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>

                <Table>
                  <colgroup>
                    <col width="25%"></col>
                    <col width="15%"></col>
                    <col width="15%"></col>
                    <col width="15%"></col>
                    <col width="15%"></col>
                    <col width="15%"></col>
                  </colgroup>
                  <thead>
                    <th></th>
                    <th>총액</th>
                    <th>카드</th>
                    <th>N Pay</th>
                    <th>현금</th>
                    <th>정액권</th>
                  </thead>
                  <tbody>
                    <tr>
                      <th>고객매출계</th>
                      <td className="text-end">
                        {numeral(
                          designerBookings
                            .map((booking) => booking.finalPrice ?? 0)
                            .reduce((a, b) => a + b, 0) ?? 0
                        ).format("0,0")}
                      </td>
                      <td className="text-end">
                        {numeral(
                          designerBookings
                            .map((booking) => booking.paidByCardAmount ?? 0)
                            .reduce((a, b) => a + b, 0) ?? 0
                        ).format("0,0")}
                      </td>
                      <td className="text-end">
                        {numeral(
                          designerBookings
                            .map(
                              (booking) =>
                                (booking.paidByNaverPayAmount ?? 0) +
                                (booking.initialDeposit ?? 0)
                            )
                            .reduce((a, b) => a + b, 0) ?? 0
                        ).format("0,0")}
                      </td>
                      <td className="text-end">
                        {numeral(
                          designerBookings
                            .map((booking) => booking.paidInCashAmount ?? 0)
                            .reduce((a, b) => a + b, 0) ?? 0
                        ).format("0,0")}
                      </td>
                      <td className="text-right">
                        {numeral(
                          designerBookings
                            .map(
                              (booking) => booking.paidByPrepaidPassAmount ?? 0
                            )
                            .reduce((a, b) => a + b, 0) ?? 0
                        ).format("0,0")}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <hr />
              </div>
            );
          })}
        </Col>
      </Row>
    </Container>
  );
}
