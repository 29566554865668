import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Switch from "@mui/material/Switch";
import consultationConstants from "../../../app/constants/consultation";
import { useAppSelector } from "../../../app/hooks";
import {
  fetchBookingById,
  refreshBookings,
  selectBooking,
} from "../../../features/booking";
import {
  selectCurrentShop,
  selectCurrentShopId,
  selectShowCreateConsultationModal,
  setShowCreateConsultationModal,
} from "../../../features/common";

import {
  Booking,
  ConsultationGender,
  ConsultationSelfDiagnosisStatus,
  ConsultationStatus,
  CreateConsultationInput,
} from "../../../graphql/API";
import { ConsultationMode } from "../../../models";
import StepDivider from "../Shared/StepDivider";
import StepOne from "../Shared/StepOne";
import StepThree from "../Shared/StepThree";
import StepTwo from "../Shared/StepTwo";
import { selectStudyMode, setStudyMode } from "../../../features/consultation";
import GA from "../../../api/ga";
import APIGateway from "../../../api/api-gateway";

export default function ConsultationCreateModal() {
  const dispatch = useDispatch();

  const booking = useAppSelector(selectBooking);

  const show = useAppSelector(selectShowCreateConsultationModal);

  const stepOneRef = useRef<HTMLDivElement>(null);
  const stepTwoRef = useRef<HTMLDivElement>(null);
  const stepThreeRef = useRef<HTMLDivElement>(null);

  const handleClose = () => dispatch(setShowCreateConsultationModal(false));

  const currentShopId = useAppSelector(selectCurrentShopId);

  const currentShop = useAppSelector(selectCurrentShop);

  const studyMode = useAppSelector(selectStudyMode);

  const [creating, setCreating] = useState(false);

  const apiGateway = new APIGateway();

  useEffect(() => {
    if (!show) {
      setInput(defaultInput);
    }
  }, [show]);

  useEffect(() => {
    if (!currentShopId) {
      return;
    }
  }, [currentShopId]);

  useEffect(() => {
    if (!show) {
      return;
    }
    if (!booking) {
      return;
    }
    initialize(booking);
  }, [booking, show]);

  async function initialize(booking: Booking) {
    const selfDiagnosis = await apiGateway.selfDiagnosis.get(booking.id);

    if (!selfDiagnosis) {
      return;
    }

    const {
      gender,
      inflowChannel,
      productsForShampooing,
      dry,
      productsForStyling,
      treatmentsInterestedIn,
      frequencyOfVisits,
      concerns,
      outfitStyles,
      recentDyeing,
      recentPerm,
      hasPhotos,
      photos,
    } = selfDiagnosis;

    setInput({
      ...input,
      gender,
      inflowChannel,
      productsForShampooing,
      dry,
      productsForStyling,
      treatmentsInterestedIn,
      frequencyOfVisits,
      concerns,
      outfitStyles,
      recentDyeing,
      recentFirm: recentPerm,
      hasPhotos,
      photos,
    });
  }

  const defaultInput: CreateConsultationInput = {
    gender: ConsultationGender.FEMALE,
    customer: {},
    customerId: "",
    status: ConsultationStatus.IN_PROGRESS,
    selfDiagnosisStatus: ConsultationSelfDiagnosisStatus.NOT_SENT,
    shopId: currentShopId ?? "",
    mode: ConsultationMode.FULL,
  };

  const [input, setInput] = useState<CreateConsultationInput>(defaultInput);

  async function handleSaveChanges() {
    if (!booking) {
      return;
    }

    const exists = await apiGateway.consultation.getByBookingId(booking.id);

    if (exists) {
      toast.warn("이미 컨설테이션이 등록된 예약입니다.");
      return;
    }

    try {
      setCreating(true);

      await apiGateway.consultation.create({
        ...input,
        bookingId: booking.id,
        customerId: booking.customerId,
        customer: booking.customer ?? {},
        name: booking.customer?.name,
        shopId: booking.shopId,
        shopName: currentShop?.name,
        designerName: booking.designerName,
        designerId: booking.designerId,
      });

      toast.success(
        `${booking.customer?.name} 고객님의 컨설테이션을 등록했습니다.`
      );

      GA.event("컨설테이션", "컨설테이션 등록", currentShop?.name);

      setTimeout(() => {
        dispatch(fetchBookingById(booking.id) as any);
      }, 1000);

      setTimeout(() => {
        dispatch(fetchBookingById(booking.id) as any);
      }, 2000);

      setTimeout(() => {
        dispatch(fetchBookingById(booking.id) as any);
        dispatch(refreshBookings() as any);
      }, 3000);

      handleClose();
    } catch (error: any) {
      if (error.errors?.length > 0) {
        toast.warn("오류가 발생했습니다.");
        toast.warn(error.errors[0].message);
        return;
      }
    } finally {
      setCreating(false);
    }
  }

  function toggleSelection(
    selected: string,
    inputArray: any,
    inputFieldName: string
  ) {
    if (!input) {
      return;
    }

    if (consultationConstants.singleValueFieldNames.includes(inputFieldName)) {
      setInput({
        ...input,
        [inputFieldName]: selected,
      });
      return;
    }

    const existingIndex =
      inputArray?.findIndex((item: any) => item === selected) ?? -1;

    const existingArray = [...(inputArray ?? [])];

    if (existingIndex > -1) {
      existingArray.splice(existingIndex, 1);
    } else {
      existingArray.push(selected);
    }
    setInput({
      ...input,
      [inputFieldName]: existingArray,
    });
  }

  function onStudyModeChange(event: any) {
    dispatch(setStudyMode(event.currentTarget.checked));
  }

  return (
    <>
      <Modal fullscreen={true} show={show} onHide={handleClose}>
        <Modal.Body style={{ padding: 0 }}>
          <Form style={{ maxWidth: 1024, margin: "0 auto" }}>
            <div ref={stepOneRef}>
              <StepOne
                input={input}
                setInput={setInput}
                toggleSelection={toggleSelection}
              />
            </div>
            <StepDivider />
            <div ref={stepTwoRef} className="modal-padding-top">
              <StepTwo
                input={input}
                setInput={setInput}
                toggleSelection={toggleSelection}
              />
            </div>
            <StepDivider />
            <div ref={stepThreeRef} className="modal-padding-top">
              <StepThree input={input} setInput={setInput} />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="me-auto">
            스터디 모드
            <Switch
              inputProps={{ "aria-label": "스터디 모드" }}
              checked={studyMode}
              onChange={onStudyModeChange}
            />
          </div>
          <Button
            variant="outline-secondary"
            onClick={() => {
              if (stepOneRef.current) {
                stepOneRef.current.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            S1
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => {
              if (stepTwoRef.current) {
                stepTwoRef.current.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            S2
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => {
              if (stepThreeRef.current) {
                stepThreeRef.current.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            S3
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            닫기
          </Button>
          <Button
            variant="primary"
            disabled={creating}
            onClick={handleSaveChanges}
          >
            등록
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
