import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function SignUpEntrance() {
  const navigate = useNavigate();

  return (
    <Container style={{ paddingLeft: 30, paddingRight: 30, marginTop: 80 }}>
      <Row>
        <Col>
          <h2 className="question-title">
            안녕하세요.
            <br />
            가입 유형을 선택해 주세요.
          </h2>
        </Col>
      </Row>
      <Row style={{ marginTop: 70 }}>
        <Col xs={6} className="text-center d-grid">
          <Button
            variant="outline-primary"
            onClick={() => {
              navigate("/submit-shop-user-application");
            }}
          >
            <img
              src="/images/icons/hair-designer.png"
              style={{ width: 50, height: 50, marginTop: 12 }}
            />
            <div style={{ padding: 10 }}>헤어 디자이너</div>
          </Button>
        </Col>
        <Col xs={6} className="text-center d-grid">
          <Button
            variant="outline-primary"
            onClick={() => {
              navigate("/submit-new-shop-registration");
            }}
          >
            <img
              src="/images/icons/hair-salon.png"
              style={{ width: 50, height: 50, marginTop: 12 }}
            />
            <div style={{ padding: 10 }}>매장 운영주</div>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginTop: 30 }}>
          현재 매장에서 나나로그를 사용하고 있다면 헤어 디자이너 가입을, 처음
          사용하고자 한다면 매장 운영주 가입을 선택해 주세요.
        </Col>
      </Row>
    </Container>
  );
}
