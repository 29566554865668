import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import ShopAPI from "../../api/shop";
import { RootState } from "../../app/store";
import { Shop, UpdateShopInput } from "../../graphql/API";

const listShops = /* GraphQL */ `
  query ListShops(
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        naverBusinessId
        hasLocker
        bookingQRS3Key
        usePreSelfDiagnosis
        useFeedbackRequest
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export interface ShopState {
  shops: Shop[];
  currentShop?: Shop;
}

const initialState: ShopState = {
  shops: [],
  currentShop: undefined,
};

const shopAPI = new ShopAPI();

const fetchShops = createAsyncThunk("shops/fetchShops", async () => {
  const response: any = await API.graphql({
    query: listShops,
  });
  return response.data.listShops.items;
});

const updateShop = createAsyncThunk(
  "shops/updateShop",
  async (input: UpdateShopInput) => {
    await shopAPI.update(input);

    const shop = await shopAPI.get(input.id);

    return shop;
  }
);

export const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setCurrentShop: (state, action: PayloadAction<Shop>) => {
      state.currentShop = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchShops.fulfilled,
      (state, { payload }: { payload: Shop[] }) => {
        state.shops = payload
          .filter((item) => !item._deleted)
          .sort((a, b) => {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          });
      }
    );

    builder.addCase(
      updateShop.fulfilled,
      (state, { payload }: { payload: Shop }) => {
        state.currentShop = payload;
      }
    );
  },
});

export const selectShops = (state: RootState) => state.shop.shops;

export const { setCurrentShop } = shopSlice.actions;

export { fetchShops, updateShop };

export default shopSlice.reducer;
