import { API, Auth, Storage } from "aws-amplify";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { Badge, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

import { Booking, Photo, UpdatePhotoInput } from "../../../graphql/API";
import { useAppSelector } from "../../../app/hooks";
import {
  emptyBooking,
  fetchBookingById,
  refreshBookings,
  selectBooking,
} from "../../../features/booking";
import { useDispatch } from "react-redux";
import {
  selectShowUpdateBookingModal,
  setShowCreateConsultationModal,
  setShowUpdateBookingModal,
  setShowUpdateConsultationModal,
} from "../../../features/common";
import {
  fetchConsultationById,
  selectConsultation,
} from "../../../features/consultation";
import { photoByBookingId } from "../../../graphql/queries";
import PhotoViewerModal from "../PhotoViewerModal";
import ConsultationService from "../../../services/consultation";
import { PhotoCategory } from "../../../models";
import PortraitRightsSignatureModal from "../PortraitRightsSignatureModal";
import APIGateway from "../../../api/api-gateway";
import DesignCompletionPhotoViewerModal from "../DesignCompletionPhotoViewerModal";
import DateTimeRow from "./DateTimeRow";
import BookingStatusRow from "./BookingStatusRow";
import styles from "./index.module.scss";
import { useSnackbar } from "notistack";
import ServiceOptionRow from "./ServiceOptionRow";
import { Button } from "@mui/material";

export default function BookingUpdateModal() {
  const show = useAppSelector(selectShowUpdateBookingModal);
  const handleClose = () => dispatch(setShowUpdateBookingModal(false));

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const apiGateway = new APIGateway();

  const booking = useAppSelector(selectBooking);
  const consultation = useAppSelector(selectConsultation);

  const [bookingOnEdit, setBookingOnEdit] = useState(booking);

  const [photos, setPhotos] = useState<Photo[]>([]);
  const [designCompletionPhotos, setDesignCompletionPhotos] = useState<Photo[]>(
    []
  );
  const [photoInputs, setPhotoInputs] = useState<UpdatePhotoInput[]>([]);

  const [uploading, setUploading] = useState(false);
  const [uploadingDesignCompletionPhoto, setUploadingDesignCompletionPhoto] =
    useState(false);
  const [showPhotoViewerModal, setShowPhotoViewerModal] = useState(false);
  const [
    showDesignCompletionPhotoViewerModal,
    setShowDesignCompletionPhotoViewerModal,
  ] = useState(false);
  const [
    showSolutionSendingConfirmationModal,
    setShowSolutionSendingConfirmationModal,
  ] = useState(false);
  const [
    showPortraitRightsSignatureModal,
    setShowPortraitRightsSignatureModal,
  ] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(emptyBooking());
    };
  }, []);

  useEffect(() => {
    if (!booking) {
      return;
    }

    setBookingOnEdit(booking);

    if (booking.consultationId) {
      dispatch(fetchConsultationById(booking.consultationId!) as any);
    }

    fetchPhotos(booking.id);
    fetchDesignCompletionPhotos(booking.id);
  }, [booking]);

  useEffect(() => {
    if (photos.length > 0 && booking && !booking.hasPhotos) {
      updateBookingHasPhotos(booking);
    }
  }, [photos]);

  useEffect(() => {
    if (
      designCompletionPhotos.length > 0 &&
      booking &&
      !booking.hasDesignCompletionPhotos
    ) {
      updateBookingHasDesignCompletionPhotos(booking);
    }
  }, [designCompletionPhotos]);

  async function updateBookingHasPhotos(booking: Booking) {
    await apiGateway.booking.updateBooking({
      id: booking.id,
      hasPhotos: true,
      _version: booking._version,
    });
  }

  async function updateBookingHasDesignCompletionPhotos(booking: Booking) {
    await apiGateway.booking.updateBooking({
      id: booking.id,
      hasDesignCompletionPhotos: true,
      _version: booking._version,
    });
  }

  async function fetchPhotos(bookingId: string) {
    const result: any = await API.graphql({
      query: photoByBookingId,
      variables: {
        bookingId,
        sortDirection: "ASC",
        filter: {
          category: {
            eq: PhotoCategory.TREATMENT_PROCESS,
          },
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    const photos = [];

    const items = result.data.photoByBookingId.items;

    setPhotoInputs(
      items.map((item: Photo) => {
        const { id, description, _version } = item;

        return {
          id,
          description,
          _version,
        };
      })
    );

    for (let i = 0; i < items.length; i += 1) {
      const item = {
        ...items[i],
      };

      const identityId = item.identityId;

      const signedURL = await Storage.get(item.s3Key, {
        level: "protected",
        identityId,
      });

      item.url = signedURL;

      photos.push(item);
    }

    setPhotos(photos);
  }

  async function fetchDesignCompletionPhotos(bookingId: string) {
    const result: any = await API.graphql({
      query: photoByBookingId,
      variables: {
        bookingId,
        sortDirection: "ASC",
        filter: {
          category: {
            eq: PhotoCategory.TREATMENT_COMPLETION,
          },
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    const photos = [];

    const items = result.data.photoByBookingId.items;

    for (let i = 0; i < items.length; i += 1) {
      const item = {
        ...items[i],
      };

      const identityId = item.identityId;

      const signedURL = await Storage.get(item.s3Key, {
        level: "protected",
        identityId,
      });

      item.url = signedURL;

      photos.push(item);
    }

    setDesignCompletionPhotos(photos);
  }

  async function handleSaveChanges() {
    if (!bookingOnEdit) {
      return;
    }

    const bookingId = booking?.id!;

    const { designerMemo, _version } = bookingOnEdit;

    await apiGateway.booking.updateBooking({
      id: bookingId,
      designerMemo,
      _version,
    });

    const message = `시술 메모를 저장했습니다.`;

    enqueueSnackbar(message, { variant: "success" });

    dispatch(fetchBookingById(bookingId) as any);
  }

  async function handleOpenOnNaver() {
    const shop = await apiGateway.shop.get(booking?.shopId!);

    const url =
      "https://partner.booking.naver.com/bizes/" +
      shop?.naverBusinessId +
      "/booking-list-view/bookings/" +
      bookingOnEdit?.naverBookingNumber;

    window.open(url);
  }

  function startConsultation() {
    dispatch(setShowCreateConsultationModal(true) as any);
  }

  function showUpdateConsultationModal() {
    dispatch(fetchConsultationById(booking?.consultationId!) as any);
    dispatch(setShowUpdateConsultationModal(true) as any);
  }

  async function askSendConsultationSolutionConfirmation() {
    if (!booking?.consultationId) {
      return;
    }

    dispatch(fetchConsultationById(booking?.consultationId!) as any);

    setShowSolutionSendingConfirmationModal(true);
  }

  async function sendConsultationSolution(force = false) {
    if (!booking?.consultationId) {
      return;
    }

    if (!consultation?.solution) {
      toast.warn("솔루션을 입력해 주세요.");
      return;
    }

    try {
      const consultationService = new ConsultationService(
        apiGateway.consultation,
        apiGateway.backend
      );

      await consultationService.sendSolution(consultation, force);

      setShowSolutionSendingConfirmationModal(false);

      dispatch(fetchConsultationById(booking?.consultationId!) as any);
      dispatch(fetchBookingById(booking?.id) as any);
      dispatch(refreshBookings() as any);
    } catch (error: any) {
      toast.warn("전송 중 문제가 발생했어요.");
      toast.warn(error.message);
    }
  }

  async function updatePhotoRecord(id: string) {
    const input = photoInputs.find((item) => item.id === id);

    await apiGateway.photo.update(input!);

    toast.success("사진 설명을 등록했어요.");
  }

  async function uploadFile(file: any, displayOrder: number) {
    const bookingId = booking?.id!;

    const fileNameSlices = file.name.split(".");

    const fileNameExtension = fileNameSlices[fileNameSlices.length - 1];

    const fileName =
      dayjs().format("YYYYMMDD") + "/" + uuid() + "." + fileNameExtension;

    const user = await Auth.currentAuthenticatedUser();

    const { identityId } = await Auth.currentUserCredentials();

    const result = await Storage.put(fileName, file, {
      level: "protected",
      metadata: { bookingId, owner: user.username },
    });

    const s3Key = result.key;

    await apiGateway.photo.create({
      category: PhotoCategory.TREATMENT_PROCESS,
      s3Key,
      bookingId,
      displayOrder,
      identityId,
    });

    fetchPhotos(bookingId);
  }

  async function onFileInputChange(event: any) {
    setUploading(true);

    try {
      let files = [];
      for (var i = 0; i < event?.target?.files.length; i++) {
        files.push(event.target.files.item(i));
      }
      await Promise.all(
        files.map((f, index) => uploadFile(f, photos.length + index))
      );
    } catch (error: any) {
      toast.warn(error.message);
    } finally {
      setUploading(false);
    }
  }

  async function uploadDesignCompletionFile(file: any, displayOrder: number) {
    const bookingId = booking?.id!;

    const fileNameSlices = file.name.split(".");

    const fileNameExtension = fileNameSlices[fileNameSlices.length - 1];

    const fileName =
      dayjs().format("YYYYMMDD") + "/" + uuid() + "." + fileNameExtension;

    const user = await Auth.currentAuthenticatedUser();

    const { identityId } = await Auth.currentUserCredentials();

    const result = await Storage.put(fileName, file, {
      level: "protected",
      metadata: { bookingId, owner: user.username },
    });

    const s3Key = result.key;

    await apiGateway.photo.create({
      category: PhotoCategory.TREATMENT_COMPLETION,
      s3Key,
      bookingId,
      displayOrder,
      identityId,
    });

    fetchDesignCompletionPhotos(bookingId);
  }

  async function onDesignCompletionFileInputChange(event: any) {
    setUploadingDesignCompletionPhoto(true);

    try {
      let files = [];
      for (var i = 0; i < event?.target?.files.length; i++) {
        files.push(event.target.files.item(i));
      }
      await Promise.all(
        files.map((f, index) =>
          uploadDesignCompletionFile(f, designCompletionPhotos.length + index)
        )
      );
    } catch (error: any) {
      toast.warn(error.message);
    } finally {
      setUploadingDesignCompletionPhoto(false);
    }
  }

  if (!booking || !bookingOnEdit) {
    return <></>;
  }

  return (
    <>
      <Modal
        fullscreen={isMobile ? true : undefined}
        show={show}
        onHide={handleClose}
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>예약 정보</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.UpdateModal}>
          <Table>
            <colgroup>
              <col width={"20%"} />
              <col width={"30%"} />
              <col width={"20%"} />
              <col width={"30%"} />
            </colgroup>
            <tbody>
              <DateTimeRow />
              <tr>
                <th>성함</th>
                <td>{booking.customer?.name || ""}</td>
                <th>담당</th>
                <td>{booking.designerName}</td>
              </tr>
              <BookingStatusRow />
              <tr>
                <th>체크인</th>
                <td>
                  {bookingOnEdit.checkedInAt
                    ? dayjs(bookingOnEdit.checkedInAt).format("HH:mm")
                    : "-"}
                  {booking.drink ? <div>{booking.drink}</div> : null}
                  {booking.checkInRequests?.map((item: any) => (
                    <Badge style={{ marginRight: 2 }}>{item}</Badge>
                  ))}
                </td>
                <th></th>
                <td></td>
              </tr>
              <tr>
                <th>예약 요청</th>
                <td colSpan={3}>{bookingOnEdit.requestMessage || "-"}</td>
              </tr>
              <tr>
                <th>컨설테이션</th>
                <td colSpan={3}>
                  {booking.consultationId ? (
                    <>
                      <Button
                        size="small"
                        variant={"outlined"}
                        onClick={showUpdateConsultationModal}
                        sx={{ marginRight: 1 }}
                      >
                        확인/수정
                      </Button>
                      <Button
                        variant={
                          consultation?.solutionSentAt
                            ? "outlined"
                            : "contained"
                        }
                        size="small"
                        sx={{ marginRight: 1 }}
                        onClick={askSendConsultationSolutionConfirmation}
                      >
                        발송
                      </Button>
                      {consultation?.solutionSentAt ? (
                        <Button
                          size="small"
                          onClick={() => {
                            const hostName =
                              process.env.REACT_APP_STAGE === "prod"
                                ? "app.nanalogapp.co"
                                : "dev-app.nanalogapp.co";

                            const url = `https://${hostName}/solution/${consultation?.id}`;
                            window.open(url);
                          }}
                        >
                          결과지
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          onClick={() => {
                            const hostName =
                              process.env.REACT_APP_STAGE === "prod"
                                ? "app.nanalogapp.co"
                                : "dev-app.nanalogapp.co";

                            const url = `https://${hostName}/solution/${booking.consultationId}`;
                            window.open(url);
                          }}
                        >
                          결과지 미리 보기
                        </Button>
                      )}
                      {consultation?.solutionSentAt && (
                        <div>
                          <small>
                            발송일시{" "}
                            {dayjs(consultation.solutionSentAt).format(
                              "YYYY-MM-DD HH:mm"
                            )}
                          </small>
                        </div>
                      )}
                    </>
                  ) : (
                    <Button
                      size="small"
                      variant="contained"
                      onClick={startConsultation}
                    >
                      컨설테이션 시작
                    </Button>
                  )}
                </td>
              </tr>
              <ServiceOptionRow />
              <tr>
                <th>초상권 사용 동의</th>
                <td>
                  <Button
                    size="small"
                    variant="outlined"
                    disabled={!!booking.signatureOfPortraitRights}
                    onClick={() => {
                      setShowPortraitRightsSignatureModal(true);
                    }}
                  >
                    {booking.signatureOfPortraitRights ? "완료" : "서명"}
                  </Button>
                  {booking.signatureOfPortraitRights ? (
                    <img
                      src={booking.signatureOfPortraitRights}
                      style={{ marginTop: 10, maxHeight: 40 }}
                    />
                  ) : null}
                </td>
                <th></th>
                <td></td>
              </tr>
              <tr>
                <th>초상권 동의 사진</th>
                <td colSpan={3}>
                  {designCompletionPhotos.length > 0 && (
                    <Row style={{ marginBottom: 5 }}>
                      {designCompletionPhotos.map((photo) => {
                        return (
                          <Col
                            xs={12}
                            sm={6}
                            key={photo.id}
                            className="float-start"
                          >
                            <img
                              src={(photo as any).url}
                              style={{ maxWidth: 100, maxHeight: 100 }}
                              onClick={() => {
                                setShowDesignCompletionPhotoViewerModal(true);
                              }}
                            ></img>
                          </Col>
                        );
                      })}
                    </Row>
                  )}
                  <div>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() =>
                        document
                          .getElementById(
                            "add-design-completion-image-file-input"
                          )
                          ?.click()
                      }
                      disabled={uploadingDesignCompletionPhoto}
                    >
                      {uploadingDesignCompletionPhoto
                        ? "업로드 중..."
                        : "사진 등록"}
                    </Button>
                    <input
                      id="add-design-completion-image-file-input"
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={onDesignCompletionFileInputChange}
                      style={{ display: "none" }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>시술 메모</th>
                <td colSpan={3}>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    value={bookingOnEdit.designerMemo ?? ""}
                    style={{ fontSize: 12 }}
                    onChange={(event) => {
                      setBookingOnEdit({
                        ...bookingOnEdit,
                        designerMemo: event.target.value,
                      });
                    }}
                  />
                  <div className="text-end" style={{ paddingTop: 5 }}>
                    <Button
                      variant="contained"
                      size="small"
                      color="info"
                      onClick={handleSaveChanges}
                    >
                      저장
                    </Button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>시술 사진</th>
                <td colSpan={3}>
                  {photos.length > 0 && (
                    <Row style={{ marginBottom: 5 }}>
                      {photos.map((photo) => {
                        return (
                          <Col
                            xs={12}
                            sm={6}
                            key={photo.id}
                            className="float-start"
                          >
                            <img
                              src={(photo as any).url}
                              style={{ maxWidth: 100, maxHeight: 100 }}
                              onClick={() => {
                                setShowPhotoViewerModal(true);
                              }}
                            ></img>
                            <InputGroup>
                              <Form.Control
                                placeholder="사진 설명"
                                style={{ fontSize: 12 }}
                                value={
                                  photoInputs.find(
                                    (item) => item.id === photo.id
                                  )?.description ?? ""
                                }
                                onChange={(event) => {
                                  const newInputs = [...photoInputs];

                                  const targetIndex = newInputs.findIndex(
                                    (item) => item.id === photo.id
                                  );

                                  newInputs[targetIndex].description =
                                    event.target.value;

                                  setPhotoInputs(newInputs);
                                }}
                              />
                              <Button
                                size="small"
                                variant="outlined"
                                onClick={() => {
                                  updatePhotoRecord(photo.id);
                                }}
                              >
                                저장
                              </Button>
                            </InputGroup>
                          </Col>
                        );
                      })}
                    </Row>
                  )}
                  <div>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() =>
                        document.getElementById("add-image-file-input")?.click()
                      }
                      disabled={uploading}
                    >
                      {uploading ? "업로드 중..." : "사진 등록"}
                    </Button>
                    <input
                      id="add-image-file-input"
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={onFileInputChange}
                      style={{ display: "none" }}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
          <PhotoViewerModal
            show={showPhotoViewerModal}
            setShow={setShowPhotoViewerModal}
            photos={photos}
          />
          <DesignCompletionPhotoViewerModal
            show={showDesignCompletionPhotoViewerModal}
            setShow={setShowDesignCompletionPhotoViewerModal}
            photos={designCompletionPhotos}
          />

          <PortraitRightsSignatureModal
            show={showPortraitRightsSignatureModal}
            setShow={setShowPortraitRightsSignatureModal}
            booking={booking}
          />
          <Modal
            show={showSolutionSendingConfirmationModal}
            onHide={() => {
              setShowSolutionSendingConfirmationModal(false);
            }}
            size="sm"
          >
            <Modal.Header closeButton>
              <Modal.Title>결과지 전송</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {consultation?.solutionSentAt
                ? "이미 결과지를 전송했습니다. 다시 전송할까요?"
                : "결과지를 전송할까요?"}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outlined"
                onClick={() => {
                  setShowSolutionSendingConfirmationModal(false);
                }}
              >
                닫기
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  sendConsultationSolution(!!consultation?.solutionSentAt);
                }}
              >
                전송
              </Button>
            </Modal.Footer>
          </Modal>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} variant="outlined">
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
