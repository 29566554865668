import BackendAPI from "./backend-api";
import BookingAPI from "./booking";
import ConsultationAPI from "./consultation";
import ConsultationSolutionPresetAPI from "./consultation-solution-preset";
import FeedbackAPI from "./feedback";
import PhotoAPI from "./photo";
import RegularCustomerNoteAPI from "./regular-customer-note";
import SelfDiagnosisAPI from "./self-diagnosis";
import ServiceOptionAPI from "./service-option";
import ShopAPI from "./shop";

export default class APIGateway {
  consultation: ConsultationAPI = new ConsultationAPI();
  consultationSolutionPreset: ConsultationSolutionPresetAPI =
    new ConsultationSolutionPresetAPI();
  shop: ShopAPI = new ShopAPI();
  booking: BookingAPI = new BookingAPI();
  photo: PhotoAPI = new PhotoAPI();
  feedback: FeedbackAPI = new FeedbackAPI();
  regularCustomerNote: RegularCustomerNoteAPI = new RegularCustomerNoteAPI();
  selfDiagnosis: SelfDiagnosisAPI = new SelfDiagnosisAPI();
  serviceOption: ServiceOptionAPI = new ServiceOptionAPI();
  backend: BackendAPI = new BackendAPI();
}
