import { Chip } from "@mui/material";
import { bookingStatusKorean } from "../../../app/translation/enum-to-korean";
import { BookingStatus } from "../../../models";

export default function BookingStatusBadge({
  bookingStatus,
}: {
  bookingStatus: BookingStatus | null | undefined;
}) {
  if (!bookingStatus) {
    return <></>;
  }

  let statusVariant: "filled" | "outlined" = "filled";

  if (bookingStatus === BookingStatus.COMPLETED) {
    statusVariant = "filled";
  } else if (bookingStatus === BookingStatus.CONFIRMED) {
    statusVariant = "outlined";
  }

  return (
    <Chip
      variant={statusVariant}
      size="small"
      color={"success"}
      label={bookingStatusKorean[bookingStatus]}
    ></Chip>
  );
}
