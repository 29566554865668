import { Button, Col, Row } from "react-bootstrap";
import consultationConstants from "../../../../../app/constants/consultation";
import CategorySubTitle from "../../CategorySubTitle";
import ActiveIcon from "../../ActiveIcon";
import styles from "./index.module.scss";

export default function HairColor({
  constants,
  inputValue,
  inputFieldName,
  onClick,
}: {
  constants: {
    name: string;
    items: string[];
    video?: { [key: string]: string };
  }[];
  inputValue: string;
  inputFieldName: string;
  onClick: any;
}) {
  const alreadyShownCategory = new Set();

  const hairColorHash = ["hairColor", "virginHairColor"].includes(
    inputFieldName
  )
    ? consultationConstants.hairColorHash
    : consultationConstants.hairRecommendation.hairColorTypeHash;

  return (
    <Row
      className={inputFieldName === "hairColorType" ? "" : styles.HairColorBox}
    >
      {constants.map((category, index) => {
        const categoryName = alreadyShownCategory.has(category.name)
          ? ""
          : category.name;
        alreadyShownCategory.add(category.name);

        let finalCategoryName = categoryName;

        if (categoryName === "버진헤어 컬러") {
          finalCategoryName = "(버진)헤어 명도";
        }

        return (
          <Col key={category.name + index} md={4} xs={6}>
            {categoryName || inputFieldName != "hairColorType" ? (
              <CategorySubTitle title={finalCategoryName} />
            ) : null}
            {
              <>
                {category.items.map((item) => {
                  let backgroundColor = "";

                  const key = category.name ? category.name + "_" + item : item;

                  backgroundColor = (hairColorHash as any)[key];

                  const active = inputValue === key;

                  return (
                    <div className="d-grid position-relative" key={key}>
                      {active && <ActiveIcon />}
                      <Button
                        variant={
                          active
                            ? "active-of-custom-color"
                            : "inactive-of-custom-color"
                        }
                        style={{
                          ...(backgroundColor && {
                            background: backgroundColor,
                          }),
                          border: active ? "2px solid #000000" : "",
                        }}
                        onClick={() => {
                          const selectedValue = category.name
                            ? category.name + "_" + item
                            : item;
                          onClick(selectedValue, undefined, inputFieldName);
                        }}
                      >
                        {item}
                      </Button>
                    </div>
                  );
                })}

                {index + 1 === constants.length &&
                  inputFieldName === "colorfulnessOfHair" && (
                    <>
                      <div className="d-grid position-relative">
                        {!inputValue && <ActiveIcon />}
                        <Button
                          variant={
                            !inputValue
                              ? "active-of-custom-color"
                              : "inactive-of-custom-color"
                          }
                          style={{
                            background: "#d2d2d2",
                            border: !inputValue ? "2px solid #000000" : "",
                          }}
                          onClick={() => {
                            const selectedValue = "";
                            onClick(selectedValue, undefined, inputFieldName);
                          }}
                        >
                          해당없음
                        </Button>
                      </div>
                    </>
                  )}
              </>
            }
          </Col>
        );
      })}
    </Row>
  );
}
