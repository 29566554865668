import { useEffect, useState } from "react";
import styles from "./index.module.scss";
export default function SubTitle({
  prefix,
  title,
}: {
  prefix: string;
  title: string;
}) {
  return (
    <div>
      <div
        style={{
          fontFamily: "Pretendard",
          fontWeight: "bold",
          fontSize: 22,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        className={styles.QuestionBoxMobilePadding}
      >
        <span className={styles.StepNumber}>{prefix}&nbsp;</span>&nbsp;
        <span className={styles.StepTitle}>{title}</span>
      </div>

      <div
        style={{
          height: 1,
          background: "rgba(0, 0, 0, 0.4)",
          marginTop: 6,
        }}
      ></div>
    </div>
  );
}
