import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "aws-amplify";
import dayjs from "dayjs";
import APIGateway from "../../api/api-gateway";
import { RootState } from "../../app/store";
import {
  Consultation,
  ModelSortDirection,
  UpdateConsultationInput,
} from "../../graphql/API";
import { consultationByCustomerId } from "../../graphql/queries";
import ConsultationService from "../../services/consultation";

export interface ConsultationState {
  yearMonth: string;
  consultation?: Consultation;
  consultations?: Consultation[];
  recentConsultations: Consultation[];
  imageList?: string[];
  studyMode: boolean;
}

const initialState: ConsultationState = {
  yearMonth: dayjs().format("YYYY-MM"),
  consultation: undefined,
  consultations: [],
  recentConsultations: [],
  imageList: [],
  studyMode: false,
};

const apiGateway = new APIGateway();

const consultationService = new ConsultationService(
  apiGateway.consultation,
  apiGateway.backend
);

const fetchConsultationById = createAsyncThunk(
  "consultations/fetchConsultationById",
  async (id: string) => {
    return apiGateway.consultation.get(id);
  }
);

const fetchConsultationsByCustomerId = createAsyncThunk(
  "consultations/fetchConsultationsByCustomerId",
  async (customerId: string) => {
    const response: any = await API.graphql(
      graphqlOperation(consultationByCustomerId, {
        customerId,
        sortDirection: ModelSortDirection.DESC,
      })
    );

    return response.data.consultationByCustomerId.items;
  }
);

const fetchConsultationsByShopId = createAsyncThunk(
  "consultations/fetchConsultationsByShopId",
  async (shopId: string, { getState }) => {
    const state = getState() as RootState;
    const data = await apiGateway.consultation.listByShopIdByMonth(
      shopId,
      state.consultation.yearMonth
    );

    return data;
  }
);

const updateConsultation = createAsyncThunk(
  "consultations/update",
  async ({
    consultation,
    input,
  }: {
    consultation: Consultation;
    input: UpdateConsultationInput;
  }) => {
    await consultationService.update(consultation, input);

    return apiGateway.consultation.get(consultation.id);
  }
);

export const consultationSlice = createSlice({
  name: "consultation",
  initialState,
  reducers: {
    setConsultationImageList: (state, action: PayloadAction<string[]>) => {
      state.imageList = action.payload;
    },
    resetConsultation: (state) => {
      state.consultation = undefined;
    },
    setStudyMode: (state, action: PayloadAction<boolean>) => {
      state.studyMode = action.payload;
    },
    setYearMonth: (state, action: PayloadAction<string>) => {
      state.yearMonth = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConsultationById.fulfilled, (state, { payload }) => {
      state.consultation = payload;
    });

    builder.addCase(
      fetchConsultationsByCustomerId.fulfilled,
      (state, { payload }) => {
        state.recentConsultations = payload;
      }
    );

    builder.addCase(
      fetchConsultationsByShopId.fulfilled,
      (state, { payload }) => {
        state.consultations = payload;
      }
    );

    builder.addCase(updateConsultation.fulfilled, (state, { payload }) => {
      state.consultation = payload;
    });
  },
});

export const selectConsultation = (state: RootState) =>
  state.consultation.consultation;

export const selectRecentConsultations = (state: RootState) =>
  state.consultation.recentConsultations;

export const selectConsultations = (state: RootState) =>
  state.consultation.consultations;

export const selectConsultationImageList = (state: RootState) =>
  state.consultation.imageList;

export const selectStudyMode = (state: RootState) =>
  state.consultation.studyMode;

export {
  fetchConsultationById,
  fetchConsultationsByCustomerId,
  fetchConsultationsByShopId,
  updateConsultation,
};

export const {
  setConsultationImageList,
  resetConsultation,
  setStudyMode,
  setYearMonth,
} = consultationSlice.actions;

export default consultationSlice.reducer;
