import { Storage } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Badge, Col, Form, Row, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import { selectBooking, selectShopId } from "../../../features/booking";
import {
  fetchConsultationById,
  resetConsultation,
  selectConsultation,
} from "../../../features/consultation";
import { NestedPhoto } from "../../../graphql/API";

type Props = {
  show: boolean;
  setShow: any;
};

export default function SelfDiagnosisModal({ show, setShow }: Props) {
  const dispatch = useDispatch();

  const shopId = useAppSelector(selectShopId);

  const booking = useAppSelector(selectBooking);

  const consultation = useAppSelector(selectConsultation);

  const [photoUrls, setPhotoUrls] = useState<string[]>([]);

  const handleClose = () => setShow(false);

  useEffect(() => {
    if (!booking) {
      return;
    }

    if (!shopId) {
      return;
    }

    if (!booking.consultationId) {
      return;
    }

    dispatch(fetchConsultationById(booking.consultationId) as any);

    return () => {
      dispatch(resetConsultation());
    };
  }, [booking]);

  useEffect(() => {
    if (consultation && consultation.photos && consultation.photos.length) {
      makeUpPhotoUrls(consultation.photos);
    } else {
      setPhotoUrls([]);
    }
  }, [consultation]);

  async function makeUpPhotoUrls(photos: NestedPhoto[]) {
    const urls = [];

    for (let i = 0; i < photos.length; i += 1) {
      const signedURL = await Storage.get(photos[i].s3Key);

      urls.push(signedURL);
    }
    setPhotoUrls(urls);
  }

  if (!booking || !consultation) {
    return <></>;
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>셀프 진단</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Table>
                  <colgroup>
                    <col width="25%"></col>
                    <col width="25%"></col>
                    <col width="25%"></col>
                    <col width="25%"></col>
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>고객명</th>
                      <td>
                        {booking.customer?.name || ""} | {booking.designerName}
                      </td>
                      <th>예약 일시</th>
                      <td>
                        {booking.date} {booking.time}
                      </td>
                    </tr>
                    <tr>
                      <th>성별</th>
                      <td>{consultation.gender}</td>
                      <th>방문 경로</th>
                      <td>{consultation.inflowChannel}</td>
                    </tr>
                    <tr>
                      <th>샴푸 제품</th>
                      <td>{consultation.productsForShampooing?.join(", ")}</td>
                      <th>드라이 및 세팅</th>
                      <td>{consultation.dry ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <th>스타일링 제품</th>
                      <td>{consultation.productsForStyling?.join(", ")}</td>
                      <th>관심 시술</th>
                      <td>{consultation.treatmentsInterestedIn?.join(", ")}</td>
                    </tr>
                    <tr>
                      <th>방문 주기</th>
                      <td>{consultation.frequencyOfVisits}</td>
                      <th>모발/두피 고민</th>
                      <td>{consultation.concerns?.join(", ")}</td>
                    </tr>
                    <tr>
                      <th>의상 스타일</th>
                      <td colSpan={3}>
                        {consultation.outfitStyles?.join(", ")}
                      </td>
                    </tr>
                    <tr>
                      <th>최근 염색 시기</th>
                      <td>{consultation.recentDyeing}</td>
                      <th>최근 펌 시기</th>
                      <td>{consultation.recentFirm}</td>
                    </tr>
                    <tr>
                      <th>원하는 스타일 사진</th>
                      <td colSpan={3}>
                        <div>
                          {photoUrls.map((photoUrl) => {
                            return (
                              <img
                                src={photoUrl}
                                key={photoUrl}
                                style={{
                                  maxWidth: 250,
                                  maxHeight: 250,
                                  objectFit: "contain",
                                  marginRight: 10,
                                }}
                              />
                            );
                          })}
                          {!consultation.photos?.length && "없음"}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
