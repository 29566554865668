import { Storage } from "aws-amplify";
import { Row, Col, Form, Button, Alert, Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import consultationConstants from "../../../../app/constants/consultation";
import { useAppSelector } from "../../../../app/hooks";
import {
  setConsultationTermsId,
  setShowConsultationTermsModal,
} from "../../../../features/common";
import { ConsultationGender } from "../../../../models";
import MultiSelectionFromCategorizedStringArray from "../MultiSelectionFromCategorizedStringArray";
import MultiSelectionFromStringArray from "../MultiSelectionFromStringArray";
import QuestionTitle from "../QuestionTitle";
import SubTitle from "../SubTitle";
import styles from "./index.module.scss";
import { isMobile } from "react-device-detect";
import { Consultation, NestedPhoto } from "../../../../graphql/API";
import { useEffect, useState } from "react";
import ImageDetailModal from "../../ImageDetailModal";
import SingleSelectionFromStringArray from "../SingleSelectionFromStringArray";
import { ConsultationMode } from "../../../../models";
import { selectStudyMode } from "../../../../features/consultation";
import PageHeader from "../PageHeader";

export default function StepOne({
  input,
  setInput,
  toggleSelection,
  consultation,
}: {
  input: any;
  setInput: any;
  toggleSelection: any;
  consultation?: Consultation;
}) {
  const dispatch = useDispatch();

  const studyMode = useAppSelector(selectStudyMode);

  const [photoUrls, setPhotoUrls] = useState<string[]>([]);

  useEffect(() => {
    if (consultation && consultation.photos && consultation.photos.length) {
      makeUpPhotoUrls(consultation.photos);
    } else {
      setPhotoUrls([]);
    }
  }, [consultation]);

  async function makeUpPhotoUrls(photos: NestedPhoto[]) {
    const urls = [];

    for (let i = 0; i < photos.length; i += 1) {
      const signedURL = await Storage.get(photos[i].s3Key);

      urls.push(signedURL);
    }
    setPhotoUrls(urls);
  }

  function onClickShowConsultationTermsModal(index: number) {
    dispatch(setConsultationTermsId(index));
    dispatch(setShowConsultationTermsModal(true));
  }

  function isMobileWidth(): boolean {
    const width = document.body.offsetWidth;
    if (width > 700) return false;
    else return true;
  }

  const fullMode = input.mode === ConsultationMode.FULL || !input.mode;
  const cutMode = input.mode === ConsultationMode.CUT;
  const colorMode = input.mode === ConsultationMode.COLOR;
  const permMode = input.mode === ConsultationMode.PERM;

  return (
    <>
      <Container className={styles.StepContainer}>
        <PageHeader title="헤어 컨설테이션" displayAgreements={true} />
        {studyMode && (
          <Row>
            <Col className={styles.ModeBox}>
              <Alert
                variant="light"
                style={{ border: "solid 1px rgba(33, 37, 41, 0.5)" }}
              >
                <h4 className={styles.Title}>
                  원하시는 진행 모드를 선택해 주세요.
                </h4>
                <ul>
                  <li>
                    <code>전체</code> 모드는 모든 문항을 진행하여 소요 시간이
                    가장 길지만 종합적인 상담을 진행할 수 있습니다.
                  </li>
                  <li>
                    <code>컷</code>, <code>컬러</code>, <code>펌</code> 등 개별
                    모드를 선택하시면 해당 시술에 관련 있는 문항만 진행하여
                    효과적으로 진행할 수 있습니다.
                  </li>
                </ul>
              </Alert>
            </Col>
          </Row>
        )}
        <Row className={styles.QuestionBoxMobilePadding}>
          <Col>
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle
                title="모드를 선택해 주세요."
                displayQuestionIcon={true}
              />
              <Col className={styles.ButtonBox}>
                <SingleSelectionFromStringArray
                  constants={consultationConstants.mode}
                  inputValue={input.mode || ConsultationMode.FULL}
                  inputFieldName="mode"
                  onClick={toggleSelection}
                  translationTable={{
                    FULL: "전체",
                    CUT: "컷",
                    COLOR: "컬러",
                    PERM: "펌",
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Container>
      <Container className={styles.StepContainer}>
        <Row className="bg-white sticky-header">
          <Col>
            <SubTitle
              prefix="STEP 1"
              title="헤어 컨설테이션을 위한 헤어 루틴과 고민"
            />
          </Col>
        </Row>

        <Row className={styles.QuestionBoxMobilePadding}>
          <Col>
            <Form.Group className={styles.FirstQuestionBox}>
              <QuestionTitle
                title={"고객님의 성별을 선택해주세요."}
                displayQuestionIcon={true}
              />
              <Col className={styles.ButtonBox}>
                <Row>
                  <Col xs={6} md={4} className="d-grid">
                    <Button
                      variant={
                        input.gender === ConsultationGender.MALE
                          ? "active"
                          : "inactive"
                      }
                      onClick={(event) => {
                        setInput({
                          ...input,
                          gender: ConsultationGender.MALE,
                        });
                        event.currentTarget.blur();
                      }}
                    >
                      남성
                    </Button>
                  </Col>
                  <Col xs={6} md={4} className="d-grid">
                    <Button
                      variant={
                        input.gender === ConsultationGender.FEMALE
                          ? "active"
                          : "inactive"
                      }
                      onClick={(event) => {
                        setInput({
                          ...input,
                          gender: ConsultationGender.FEMALE,
                        });
                        event.currentTarget.blur();
                      }}
                    >
                      여성
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row className={styles.QuestionBoxMobilePadding}>
          <Col>
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle
                title={
                  (!isMobile ? "평소 " : "") +
                  "샴푸 하실 때 사용하시는 제품을 알려주세요."
                }
                multiSelection={true}
                displayQuestionIcon={true}
              />
              <Col className={styles.ButtonBox}>
                <MultiSelectionFromStringArray
                  constants={consultationConstants.productsForShampooing}
                  inputArray={input.productsForShampooing ?? []}
                  inputFieldName="productsForShampooing"
                  onClick={toggleSelection}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row className={styles.QuestionBoxMobilePadding}>
          <Col>
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle
                title={
                  (!isMobile ? "평소 " : "") + "드라이 및 세팅을 하시나요?"
                }
                displayQuestionIcon={true}
              />
              <Col className={styles.ButtonBox}>
                <Row>
                  <Col xs={6} md={4} className="d-grid">
                    <Button
                      variant={input.dry === true ? "active" : "inactive"}
                      onClick={(event) => {
                        setInput({
                          ...input,
                          dry: true,
                        });
                        event.currentTarget.blur();
                      }}
                    >
                      예
                    </Button>
                  </Col>
                  <Col xs={6} md={4} className="d-grid">
                    <Button
                      variant={input.dry === false ? "active" : "inactive"}
                      onClick={(event) => {
                        setInput({
                          ...input,
                          dry: false,
                        });
                        event.currentTarget.blur();
                      }}
                    >
                      아니오
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row className={styles.QuestionBoxMobilePadding}>
          <Col>
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle
                title={
                  (!isMobile ? "평소 " : "") +
                  "스타일링을 하실 때 사용하시는 제품을 알려주세요."
                }
                multiSelection={true}
                displayQuestionIcon={true}
              />

              <Col className={styles.ButtonBox}>
                <MultiSelectionFromStringArray
                  constants={consultationConstants.productsForStyling}
                  inputArray={input.productsForStyling ?? []}
                  inputFieldName="productsForStyling"
                  onClick={toggleSelection}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row className={styles.QuestionBoxMobilePadding}>
          <Col>
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle
                title={
                  (!isMobile ? "평소 " : "") +
                  "관심을 가지고 계신 헤어 시술의 종류를 알려주세요."
                }
                multiSelection={true}
                displayQuestionIcon={true}
              />
              <Col className={styles.ButtonBox}>
                <MultiSelectionFromCategorizedStringArray
                  constants={consultationConstants.treatmentsInterestedIn}
                  inputArray={input.treatmentsInterestedIn ?? []}
                  inputFieldName="treatmentsInterestedIn"
                  onClick={toggleSelection}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row className={styles.QuestionBoxMobilePadding}>
          <Col>
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle
                title={"미용실 방문 주기를 알려주세요."}
                displayQuestionIcon={true}
              />
              <Col className={styles.ButtonBox}>
                <MultiSelectionFromCategorizedStringArray
                  constants={consultationConstants.frequencyOfVisits}
                  inputArray={[input.frequencyOfVisits ?? ""]}
                  inputFieldName="frequencyOfVisits"
                  onClick={toggleSelection}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row className={styles.QuestionBoxMobilePadding}>
          <Col>
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle
                title={"고객님의 모발 및 두피 고민은 무엇인가요?"}
                multiSelection={true}
                displayQuestionIcon={true}
              />
              <Col className={styles.ButtonBox}>
                <MultiSelectionFromCategorizedStringArray
                  constants={consultationConstants.concerns}
                  inputArray={input.concerns ?? []}
                  inputFieldName="concerns"
                  onClick={toggleSelection}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row className={styles.QuestionBoxMobilePadding}>
          <Col>
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle
                title={"평소 즐겨입는 스타일을 알려주세요."}
                multiSelection={true}
                displayQuestionIcon={true}
              />
              <Col className={styles.ButtonBox}>
                <MultiSelectionFromStringArray
                  constants={consultationConstants.outfitStyles}
                  inputArray={input.outfitStyles ?? []}
                  inputFieldName="outfitStyles"
                  onClick={toggleSelection}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row
          className={
            fullMode || colorMode || permMode
              ? styles.QuestionBoxMobilePadding
              : "d-none"
          }
        >
          <Col>
            <Form.Group
              className={
                permMode ? styles.FirstQuestionBox : styles.QuestionBox
              }
            >
              <QuestionTitle
                title="최근 염색 시기(해당 시)"
                displayQuestionIcon={true}
              />
              <Col className={styles.ButtonBox}>
                <SingleSelectionFromStringArray
                  constants={consultationConstants.recentDyeing}
                  inputValue={input.recentDyeing}
                  inputFieldName="recentDyeing"
                  onClick={toggleSelection}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row
          className={
            fullMode || permMode ? styles.QuestionBoxMobilePadding : "d-none"
          }
        >
          <Col>
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle
                title="최근 펌 시기(해당 시)"
                displayQuestionIcon={true}
              />
              <Col className={styles.ButtonBox}>
                <SingleSelectionFromStringArray
                  constants={consultationConstants.recentFirm}
                  inputValue={input.recentFirm}
                  inputFieldName="recentFirm"
                  onClick={toggleSelection}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        {consultation && consultation.photos && consultation.photos.length > 0 && (
          <Row className={styles.QuestionBoxMobilePadding}>
            <Col>
              <Form.Group className={styles.QuestionBox}>
                <QuestionTitle title={"원하는 스타일 사진"} />
                <div>
                  {photoUrls.map((photoUrl) => {
                    return (
                      <img
                        src={photoUrl}
                        key={photoUrl}
                        style={{
                          width: 160,
                          height: 240,
                          objectFit: "contain",
                          marginRight: 10,
                        }}
                      />
                    );
                  })}
                </div>
              </Form.Group>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}
