import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentShopId } from "../../features/common";
import {
  fetchDiscountPresetsByShopId,
  selectDiscountPresets,
} from "../../features/discount-preset";
import { CreateDiscountPresetInput } from "../../graphql/API";
import {
  createDiscountPreset,
  deleteDiscountPreset,
} from "../../graphql/mutations";
import { DiscountMode } from "../../models";

export default function DiscountPreset() {
  const currentShopId = useAppSelector(selectCurrentShopId);
  const dispatch = useDispatch();
  const discountPresets = useAppSelector(selectDiscountPresets);

  const [discountInput, setDiscountInput] = useState<CreateDiscountPresetInput>(
    {
      name: "",
      shopId: currentShopId ?? "",
      type: DiscountMode.AMOUNT,
      enabled: true,
    }
  );

  useEffect(() => {
    if (!currentShopId) {
      return;
    }

    dispatch(fetchDiscountPresetsByShopId(currentShopId) as any);
  }, [currentShopId]);

  async function createDiscount() {
    const result: any = await API.graphql(
      graphqlOperation(createDiscountPreset, {
        input: {
          ...discountInput,
          shopId: currentShopId!,
        },
      })
    );

    dispatch(fetchDiscountPresetsByShopId(currentShopId!) as any);
  }

  async function deleteDiscount(id: string, _version: number) {
    const result: any = await API.graphql(
      graphqlOperation(deleteDiscountPreset, {
        input: {
          id,
          _version,
        },
      })
    );

    dispatch(fetchDiscountPresetsByShopId(currentShopId!) as any);
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Table>
              <thead>
                <tr>
                  <th>할인 이름</th>
                  <th>유형</th>
                  <th>금액/비율</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {discountPresets.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>{item.type}</td>
                      <td>
                        {item.type === DiscountMode.AMOUNT
                          ? item.amount
                          : item.rate}
                      </td>
                      <td>
                        <Button
                          size="sm"
                          onClick={() => {
                            deleteDiscount(item.id, item._version);
                          }}
                        >
                          삭제
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                할인 이름
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  value={discountInput?.name}
                  onChange={(event) => {
                    setDiscountInput({
                      ...discountInput,
                      name: event.target.value,
                    });
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                할인 유형
              </Form.Label>
              <Col sm="8">
                <ButtonGroup>
                  <Button
                    variant={
                      discountInput?.type === DiscountMode.AMOUNT
                        ? "primary"
                        : "outline-secondary"
                    }
                    size="sm"
                    onClick={() => {
                      setDiscountInput({
                        ...discountInput,
                        type: DiscountMode.AMOUNT,
                      });
                    }}
                  >
                    금액
                  </Button>

                  <Button
                    variant={
                      discountInput?.type === DiscountMode.RATE
                        ? "primary"
                        : "outline-secondary"
                    }
                    size="sm"
                    onClick={() => {
                      setDiscountInput({
                        ...discountInput,
                        type: DiscountMode.RATE,
                      });
                    }}
                  >
                    비율(%)
                  </Button>
                </ButtonGroup>
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                할인{discountInput?.type === DiscountMode.AMOUNT ? "액" : "율"}
              </Form.Label>
              <Col sm="8">
                {discountInput?.type === DiscountMode.AMOUNT ? (
                  <Form.Control
                    value={discountInput?.amount?.toString()}
                    onChange={(event) => {
                      setDiscountInput({
                        ...discountInput,
                        amount: Number(event.target.value),
                      });
                    }}
                  />
                ) : (
                  <Form.Control
                    value={discountInput?.rate?.toString()}
                    onChange={(event) => {
                      setDiscountInput({
                        ...discountInput,
                        rate: Number(event.target.value),
                      });
                    }}
                  />
                )}
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Button size="sm" onClick={createDiscount}>
              등록
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
