import { Button } from "react-bootstrap";
import YouTubeIcon from "@mui/icons-material/YouTube";
import styles from "./index.module.scss";

export default function VideoButton({ category, item }: any) {
  return (
    <div className={styles.VideoBox}>
      <Button
        size="sm"
        variant="outline-success"
        onClick={() => {
          if (!category.video) {
            return;
          }
          window.open(category.video[item]);
        }}
      >
        <YouTubeIcon color="success" /> {item}
      </Button>
    </div>
  );
}
