import { API, graphqlOperation } from "aws-amplify";
import {
  CreatePhotoInput,
  CreatePhotoMutationVariables,
  Photo,
  UpdatePhotoInput,
} from "../../graphql/API";
import { createPhoto, updatePhoto } from "../../graphql/mutations";
import { getPhoto, shopByName } from "../../graphql/queries";

export default class PhotoAPI {
  async get(id: string) {
    const response: any = await API.graphql(
      graphqlOperation(getPhoto, {
        id,
      })
    );

    return response.data.getPhoto;
  }
  async getPhotoByName(name: string) {
    const response: any = await API.graphql(
      graphqlOperation(shopByName, {
        name,
      })
    );

    if (response.data.shopByName.items.length === 0) {
      return undefined;
    }

    return response.data.shopByName.items[0];
  }
  async create(input: CreatePhotoInput) {
    const variables: CreatePhotoMutationVariables = {
      input,
    };

    return API.graphql({
      query: createPhoto,
      variables,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }
  async update(input: UpdatePhotoInput) {
    if (!input._version) {
      throw new Error("Missing _version");
    }
    return API.graphql({
      query: updatePhoto,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }
}
