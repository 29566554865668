import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import DesignerAPI from "../../api/designer";
import { RootState } from "../../app/store";
import { Designer } from "../../graphql/API";

const designerAPI = new DesignerAPI();

export interface DesignerState {
  designers: Designer[];
  shopId?: string;
}

const initialState: DesignerState = {
  designers: [],
  shopId: undefined,
};

const fetchDesignersByShopId = createAsyncThunk(
  "designers/fetchDesignersByShopId",
  async (shopId: string) => {
    return designerAPI.listByShopId(shopId);
  }
);

export const designerSlice = createSlice({
  name: "designer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchDesignersByShopId.fulfilled,
      (state, { payload }: { payload: Designer[] }) => {
        state.designers = payload
          .filter((item) => !item._deleted)
          .filter((item) => !item.inactive);

        if (payload.length > 0) {
          state.shopId = payload[0].shopId;
        }
      }
    );
  },
});

export const selectDesigners = (state: RootState) => state.designer.designers;

export const selectShopId = (state: RootState) => state.designer.shopId;

export { fetchDesignersByShopId };

export default designerSlice.reducer;
