import styles from "./index.module.scss";

export default function StepDivider() {
  return (
    <div className={styles.Section}>
      <div className={styles.Box}>
        Hair consultation based on NaNalog algorithm
      </div>
    </div>
  );
}
