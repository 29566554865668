import ReactGA from "react-ga4";

export default class GA {
  static event(
    category: string,
    action: string,
    label?: string,
    value?: number
  ) {
    if (process.env.NODE_ENV === "production") {
      ReactGA.event({
        category,
        action,
        label,
        value,
      });
    } else {
      console.log("GA.event", category, action, label, value);
    }
  }
}
