import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchBookingById } from "../../features/booking";
import SelfDiagnosisV2Modal from "../Booking/SelfDiagnosisV2Modal";

export default function SelfNew() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const [showSelfDiagnosisV2Modal, setShowSelfDiagnosisV2Modal] =
    useState(false);

  useEffect(() => {
    if (!id) {
      return;
    }

    initialize(id);
  }, [id]);

  async function initialize(id: string) {
    dispatch(fetchBookingById(id) as any);
    setShowSelfDiagnosisV2Modal(true);
  }

  return (
    <Container>
      {showSelfDiagnosisV2Modal && (
        <SelfDiagnosisV2Modal
          show={showSelfDiagnosisV2Modal}
          setShow={setShowSelfDiagnosisV2Modal}
        />
      )}
    </Container>
  );
}
