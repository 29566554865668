import { Auth } from "aws-amplify";
import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import BackendAPI from "../../api/backend-api";
import ShopAPI from "../../api/shop";
import { setUserGroups } from "../../features/common";
import { fetchShops } from "../../features/shop";
import BrowserService from "../../services/browser";

export default function SubmitNewShopRegistration() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState<string>("");
  const [ownerName, setOwnerName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  async function onNewShopRegistrationClick() {
    if (!name) {
      toast.warn("매장 이름을 입력해주세요.");
      return;
    }

    if (!ownerName) {
      toast.warn("대표 디자이너 이름을 입력해주세요.");
      return;
    }

    if (!phoneNumber) {
      toast.warn("휴대전화번호를 입력해주세요.");
      return;
    }

    const trimmedName = name.trim();

    const shopAPI = new ShopAPI();

    const shop = await shopAPI.getShopByName(trimmedName);

    if (shop) {
      toast.warn(`${trimmedName}은 이미 등록된 매장입니다.`);
      return;
    }

    const user = await Auth.currentAuthenticatedUser();

    const newShop = await shopAPI.create({
      name: trimmedName,
      creatorId: user.attributes.sub,
      ownerName,
      ownerPhoneNumber: phoneNumber,
    });

    if (newShop) {
      toast.success(`${trimmedName} 매장이 등록되었습니다.`);

      dispatch(fetchShops() as any);

      toast.info(`환경 설정이 완료될 때까지 잠시만 기다려 주세요.`);

      BrowserService.setJustSignedUp();

      // 백엔드 트리거가 디자이너, 매장 관리자 Role 자동 부여
      setTimeout(() => {
        refreshUser();

        new BackendAPI().notifyNewShopRegistration(newShop.id);
      }, 2000);

      setTimeout(() => {
        refreshUser();
      }, 2000 + 4000);

      setTimeout(() => {
        refreshUser();
      }, 2000 + 4000 + 8000);
    }

    return;
  }

  async function refreshUser() {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });

    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];

    const param = groups ?? [];

    dispatch(setUserGroups(param));

    if (param.includes("Owner")) {
      navigate("/");
    }
  }

  return (
    <Container style={{ paddingLeft: 30, paddingRight: 30, marginTop: 80 }}>
      <Row>
        <Col>
          <h2 className="question-title">매장 운영주 가입</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <div>매장을 대표하여 처음 가입합니다.</div>
          <div>
            <div style={{ paddingTop: 20 }}>
              <div style={{ marginBottom: 10 }}>
                <Form.Control
                  placeholder="매장 이름"
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <Form.Control
                  placeholder="운영주(대표 디자이너) 이름"
                  value={ownerName}
                  onChange={(event) => {
                    setOwnerName(event.target.value);
                  }}
                />
              </div>
              <div style={{ marginBottom: 20 }}>
                <Form.Control
                  placeholder="운영주(대표 디자이너) 휴대전화 ex. 01012345678"
                  value={phoneNumber}
                  onChange={(event) => {
                    setPhoneNumber(event.target.value);
                  }}
                />
              </div>
              <div className="text-center d-grid">
                <Button onClick={onNewShopRegistrationClick}>매장 등록</Button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" />
        </Col>
      </Row>
    </Container>
  );
}
