import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "aws-amplify";
import { RootState } from "../../app/store";
import {
  RegularCustomerNote,
  ModelSortDirection,
  GetRegularCustomerNoteQueryVariables,
} from "../../graphql/API";
import { getRegularCustomerNote } from "../../graphql/queries";

export interface RegularCustomerNoteState {
  regularCustomerNote?: RegularCustomerNote;
}

const initialState: RegularCustomerNoteState = {
  regularCustomerNote: undefined,
};

const fetchRegularCustomerNoteById = createAsyncThunk(
  "regularCustomerNotes/fetchRegularCustomerNoteById",
  async (bookingId: string) => {
    const variables: GetRegularCustomerNoteQueryVariables = {
      bookingId,
    };

    const response: any = await API.graphql(
      graphqlOperation(getRegularCustomerNote, variables)
    );
    return response.data.getRegularCustomerNote;
  }
);

export const regularCustomerNoteSlice = createSlice({
  name: "regularCustomerNote",
  initialState,
  reducers: {
    resetRegularCustomerNote: (state) => {
      state.regularCustomerNote = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchRegularCustomerNoteById.fulfilled,
      (state, { payload }) => {
        state.regularCustomerNote = payload;
      }
    );
  },
});

export const selectRegularCustomerNote = (state: RootState) =>
  state.regularCustomerNote.regularCustomerNote;

export { fetchRegularCustomerNoteById };

export const { resetRegularCustomerNote } = regularCustomerNoteSlice.actions;

export default regularCustomerNoteSlice.reducer;
