import { Auth } from "aws-amplify";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { setUserGroups } from "../../features/common";
import BrowserService from "../../services/browser";

export default function WaitingForApproval() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tryCount = useRef(0);
  const [count, setCount] = useState<number>(59);

  useEffect(() => {
    const countDown = setInterval(() => {
      setCount((prev) => prev - 1);
    }, 1000);

    if (count === 1) {
      tryCount.current += 1;
      setCount(59 + tryCount.current * 10);
      refreshUser();
    }

    return () => clearInterval(countDown);
  }, [count]);

  async function refreshUser() {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });

    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];

    const param = groups ?? [];

    dispatch(setUserGroups(param));

    if (param.includes("Designer")) {
      BrowserService.setJustSignedUp();

      navigate("/");
    } else {
      toast.warn("승인 대기 중입니다.");
    }
  }

  return (
    <Container style={{ paddingLeft: 30, paddingRight: 30, marginTop: 80 }}>
      <Row>
        <Col>
          <h2 className="question-title">
            가입 신청이 완료되었습니다.
            <br />
            요청이 승인될 때까지 기다려 주세요.
          </h2>
        </Col>
      </Row>
      <Row>
        <Col className="d-grid" style={{ marginTop: 30 }}>
          <Button onClick={refreshUser}>승인 상태 새로고침</Button>
          <ToastContainer position="top-center" />
        </Col>
      </Row>
    </Container>
  );
}
