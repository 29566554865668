import { API, graphqlOperation } from "aws-amplify";
import { SelfDiagnosis } from "../../graphql/API";
import { getSelfDiagnosis } from "../../graphql/queries";

export default class SelfDiagnosisAPI {
  async get(bookingId: string): Promise<SelfDiagnosis> {
    const response: any = await API.graphql(
      graphqlOperation(getSelfDiagnosis, {
        bookingId,
      })
    );

    return response.data.getSelfDiagnosis;
  }
}
