import { Storage } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import { selectBooking } from "../../../features/booking";
import {
  fetchRegularCustomerNoteById,
  resetRegularCustomerNote,
  selectRegularCustomerNote,
} from "../../../features/regular-customer-note";
import { NestedPhoto } from "../../../graphql/API";

type Props = {
  show: boolean;
  setShow: any;
};

export default function RegularCustomerNoteModal({ show, setShow }: Props) {
  const dispatch = useDispatch();

  const booking = useAppSelector(selectBooking);

  const regularCustomerNote = useAppSelector(selectRegularCustomerNote);

  const [photoUrls, setPhotoUrls] = useState<string[]>([]);

  const handleClose = () => {
    setPhotoUrls([]);
    setShow(false);
  };

  useEffect(() => {
    if (!booking) {
      return;
    }

    if (!booking.hasRegularCustomerNote) {
      return;
    }

    dispatch(fetchRegularCustomerNoteById(booking.id) as any);

    return () => {
      dispatch(resetRegularCustomerNote());
    };
  }, [booking]);

  useEffect(() => {
    if (
      regularCustomerNote &&
      regularCustomerNote.photos &&
      regularCustomerNote.photos.length
    ) {
      makeUpPhotoUrls(regularCustomerNote.photos);
    } else {
      setPhotoUrls([]);
    }
  }, [regularCustomerNote]);

  async function makeUpPhotoUrls(photos: NestedPhoto[]) {
    const urls = [];

    for (let i = 0; i < photos.length; i += 1) {
      const signedURL = await Storage.get(photos[i].s3Key);

      urls.push(signedURL);
    }
    setPhotoUrls(urls);
  }

  if (!booking || !regularCustomerNote) {
    return <></>;
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} scrollable>
        <Modal.Header closeButton>
          <Modal.Title>재방문 고객 사전 방문 노트</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Table>
                  <colgroup>
                    <col width="25%"></col>
                    <col width="25%"></col>
                    <col width="25%"></col>
                    <col width="25%"></col>
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>고객명</th>
                      <td>
                        {booking.customer?.name || ""} | {booking.designerName}
                      </td>
                      <th>예약 일시</th>
                      <td>
                        {booking.date} {booking.time}
                      </td>
                    </tr>
                    <tr>
                      <th>스타일 변화 정도</th>
                      <td>{regularCustomerNote.levelOfStyleChange}</td>
                      <th>시술 후 일정</th>
                      <td>{regularCustomerNote.nextSchedule}</td>
                    </tr>
                    <tr>
                      <th>코멘트</th>
                      <td colSpan={3}>
                        {regularCustomerNote.comment || "없음"}
                      </td>
                    </tr>
                    <tr>
                      <th>원하는 스타일 사진</th>
                      <td colSpan={3}>
                        <div>
                          {photoUrls.map((photoUrl) => {
                            return (
                              <img
                                src={photoUrl}
                                key={photoUrl}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: 250,
                                  objectFit: "contain",
                                  marginRight: 10,
                                }}
                              />
                            );
                          })}
                          {!regularCustomerNote.photos?.length && "없음"}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
