import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "aws-amplify";
import { RootState } from "../../app/store";
import { Product } from "../../graphql/API";
import { productByShopId } from "../../graphql/queries";

export interface ProductState {
  products: Product[];
  shopId?: string;
}

const initialState: ProductState = {
  products: [],
  shopId: undefined,
};

const fetchProductsByShopId = createAsyncThunk(
  "products/fetchProductsByShopId",
  async (shopId: string) => {
    const response: any = await API.graphql({
      query: productByShopId,
      variables: { shopId },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return response.data.productByShopId.items;
  }
);

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchProductsByShopId.fulfilled,
      (state, { payload }: { payload: Product[] }) => {
        state.products = payload.filter((item) => !item._deleted);

        if (payload.length > 0) {
          state.shopId = payload[0].shopId;
        }
      }
    );
  },
});

export const selectProducts = (state: RootState) => state.product.products;

export const selectShopId = (state: RootState) => state.product.shopId;

export { fetchProductsByShopId };

export default productSlice.reducer;
