import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import common from "../features/common/";
import booking from "../features/booking/";
import discountPreset from "../features/discount-preset";
import prepaidPassPreset from "../features/prepaid-pass-preset";
import designer from "../features/designer";
import consultation from "../features/consultation";
import brand from "../features/brand";
import product from "../features/product";
import shop from "../features/shop";
import customer from "../features/customer";
import regularCustomerNote from "../features/regular-customer-note";
import selfDiagnosis from "../features/self-diagnosis";

export const store = configureStore({
  reducer: {
    common,
    booking,
    discountPreset,
    prepaidPassPreset,
    designer,
    consultation,
    brand,
    product,
    shop,
    customer,
    regularCustomerNote,
    selfDiagnosis,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
