import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { isMobile } from "react-device-detect";

import { Photo } from "../../../graphql/API";
import { useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";

type Props = {
  show: boolean;
  setShow: any;
  photos: Photo[];
};

export default function DesignCompletionPhotoViewerModal({
  show,
  setShow,
  photos,
}: Props) {
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {}, []);

  return (
    <>
      <Modal
        fullscreen={isMobile ? true : undefined}
        show={show}
        size="xl"
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>초상권 동의 사진</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {photos.length > 0 && (
            <Row style={{ marginBottom: 5 }}>
              {photos.map((photo) => {
                return (
                  <Col xs={12} sm={6} key={photo.id} className="float-start">
                    <img
                      src={(photo as any).url}
                      style={{ maxWidth: "100%" }}
                    ></img>
                    <div>{photo.description}</div>
                  </Col>
                );
              })}
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
