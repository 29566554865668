import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import {
  selectConsultationTermsId,
  selectShowConsultationTermsModal,
  setShowConsultationTermsModal,
} from "../../../features/common";
import Privacy from "./Privacy";
import Terms from "./Terms";

export default function ConsultationTermsModal() {
  const dispatch = useDispatch();
  const show = useAppSelector(selectShowConsultationTermsModal);
  const termIndex = Number(useAppSelector(selectConsultationTermsId));
  const handleClose = () => dispatch(setShowConsultationTermsModal(false));

  let title = "";

  if (termIndex === 0) {
    title = "서비스 이용 약관";
  } else if (termIndex === 2) {
    title = "개인정보 처리방침";
  }

  return (
    <>
      <Modal
        fullscreen={"xxl-down"}
        size="lg"
        show={show}
        onHide={handleClose}
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 20 }}>
          {termIndex === 0 && <Terms />}
          {termIndex === 2 && <Privacy />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            확인
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
