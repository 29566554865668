import { Box, Button, Stack } from "@mui/material";
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { Table, Badge } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { bookingStatusKorean } from "../../../app/translation/enum-to-korean";
import { fetchBookingById } from "../../../features/booking";
import { setShowUpdateBookingModal } from "../../../features/common";
import { ModelSortDirection } from "../../../graphql/API";
import { Booking, BookingServiceOption, BookingStatus } from "../../../models";
import BookingStatusBadge from "../../Booking/StatusBadge";

const bookingByCustomerId = /* GraphQL */ `
  query BookingByCustomerId(
    $customerId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByCustomerId(
      customerId: $customerId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        time
        customer {
          name
          phoneNumber
        }
        customerId
        designerName
        optionName
        options {
          name
        }
        shopId
        naverBookingNumber
        bookingStatus
        requestMessage
        designerMemo
        initialPrice
        finalPrice
        initialDeposit
        paidAmount
        paymentMemo
        checkInRequests
        drink
        checkedInAt
        origin
        createdAt
        _version
      }
      nextToken
      startedAt
    }
  }
`;
export default function RecentBookingList({
  customer,
  ignoreBookingId,
}: {
  customer: any;
  ignoreBookingId?: string;
}) {
  const dispatch = useDispatch();
  const [bookings, setBookings] = useState<Booking[]>([]);

  useEffect(() => {
    fetchBookingsByCustomer();
  }, [customer]);

  async function fetchBookingsByCustomer() {
    const result: any = await API.graphql(
      graphqlOperation(bookingByCustomerId, {
        customerId: customer.id,
        sortDirection: ModelSortDirection.DESC,
      })
    );

    const bookings: Booking[] = result.data.bookingByCustomerId.items;

    if (ignoreBookingId) {
      setBookings(bookings.filter((b) => b.id !== ignoreBookingId));
    } else {
      setBookings(bookings);
    }
  }

  const columns: GridColumns<Booking> = [
    {
      field: "date",
      headerName: "날짜",
    },
    {
      field: "designerName",
      headerName: "담당",
      maxWidth: 60,
    },
    {
      field: "options",
      headerName: "시술",
      flex: 1,
      renderCell: (params: GridRenderCellParams<BookingServiceOption[]>) => {
        return <>{params.value?.map((item) => item.name).join(", ")}</>;
      },
    },
    {
      field: "requestMessage",
      headerName: "예약메시지",
      flex: 1,
    },
    {
      field: "id",
      headerName: "열기",
      renderCell: (params) => {
        return (
          <Button
            size="small"
            sx={{ minWidth: 30 }}
            onClick={() => {
              dispatch(fetchBookingById(params.value) as any);
              dispatch(setShowUpdateBookingModal(true) as any);
            }}
          >
            열기
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <h5>최근 예약</h5>
      <Box>
        <DataGridPro
          columns={columns}
          rows={bookings}
          autoHeight={true}
          hideFooter={true}
          components={{
            NoRowsOverlay: () => (
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center"
                sx={{ textAlign: "center" }}
              >
                예약이 없습니다.
              </Stack>
            ),
          }}
        />
      </Box>
    </>
  );
}
