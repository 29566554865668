import styles from "./index.module.scss";

export default function ActiveIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={"position-absolute " + styles.ActiveButtonVisual}
    >
      <circle
        cx="12"
        cy="12"
        r="11"
        fill="white"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M6.20691 12.1379L10.069 16L17.7931 8.27588"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
